@import "../../../../less/variables";
@import "../../../../less/mixins";

.template__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4*@offset-lg;
  background-color: @color-white;
  padding: 0 @site-padding;
  position: relative;
  border-bottom: 1px solid @color-pale-blue;

  &__info {
    display: flex;
    align-items: center;
    color: @template-create-header-color;
    flex: 0 1 100%;

    &.process-start {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > label {
        color: @color-secondary-copy;
        font-size: @font-size-xs;
        text-transform: uppercase;
        margin-bottom: @offset-xs;
      }
    }

    .choose-icon {
      flex: 0 1 @thumbnail-big-size;
      line-height: @thumbnail-big-size;
      height: @thumbnail-big-size;
      width: @thumbnail-big-size;
      min-width: @thumbnail-big-size;
      overflow: hidden;
      border: 2px dashed @template-create-header-color;
      border-radius: @thumbnail-big-border-radius;
      display: block;
      margin-right: @offset-md;
      cursor: pointer;

      &:hover {
        border-color: @color-blue;

        i {
          color: @color-blue;
        }
      }

      i {
        display: block;
        height: @thumbnail-big-size;
        font-size: @template-create-header-icon-choose-size;
        margin-left: 6px;
        padding-top: 9px;
      }

      &--active {
        margin: 0 @offset-md 0 0;
        width: @thumbnail-big-size;
        min-width: @thumbnail-big-size;
        line-height: @thumbnail-big-size;
        height: @thumbnail-big-size;
        border-radius: @thumbnail-big-border-radius;
        vertical-align: middle;
        cursor: pointer;
        color: @color-main-copy;
        display: block;

        i {
          display: block;
          text-align: center;
          margin: 0 auto;
          font-size: 24px;

          &.icon-Magnifi-Glass2 {
            font-size: 22px;
          }

          &.icon-Mens {
            font-size: 26px;
          }

          &::before {
            margin: 0;
          }
        }
      }
    }

    input {
      background: transparent;
      color: @color-main-copy;
      font-family: @font-family-regular;
      font-size: @template-create-header-title-size;
      border: none;
      width: 100%;
      margin: -5px 10px 0 -3px;
      padding: 1px 13px 0 3px;
      .no-focus();

      &::-ms-clear {
        display: none;
      }

      &:hover {
        border: 1px @input-border-color solid;
        border-radius: 4px;
        padding-left: 2px;
        cursor: pointer;
      }

      &:focus {
        cursor: inherit;
        padding-left: 3px;

        &:hover {
          border: none;
        }
      }
    }
  }

  &__btns {
    flex: 0 1 auto;
    white-space: nowrap;

    .btn-switcher {
      border: 1px solid #cdcdcd;
      color: #cdcdcd;
      background: transparent;
      margin-left: 10px;
    }
    button.btn-template-edit:not(.disabled){
      background-color: @color-green !important;
      color: @color-white !important;
      &:hover{
        background-color: @color-light-green !important;
        color: @color-white !important;
      }
    }
  }

  & > .errors {
    color: @color-red;
    font-size: @font-size-sm;
    padding: 2px 0 0 52px;
    position: absolute;
    top: 41px;
    left: 34px;

    &.process-start {
      padding: @offset-sm @site-padding;
    }
  }
}
