@import "../../../../less/variables";
@import "../../../../less/mixins";

.team-creation-screen {
  .right-screen {
    flex: 0 1 50%;

    &.step1,
    &.step5 {
      background-color: #80c0e5;
    }
    &.step2 {
      background-color: #79dfbc;
    }
    &.step3 {
      background-color: #f3c376;
    }
    &.step4 {
      background-color: #c0a4ee;
    }
    &.step6 {
      background-color: #e5dc4e;
    }

    .screen {
      width: 100%;
      max-width: 310px;
      margin: 0 auto;

      header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin: 2*@offset-lg 0;

        a {
          color: @color-white;
          font-size: @font-size-sm;
          font-family: @font-family-semibold;
        }

        .btn-switcher {
          font-size: @font-size-md;
        }
      }
    }
  }
}
