/* Primary colors */
/* Font-family */
/* Secondary colors */
/* Primaty offsets */
/* Font-sizes */
/* Thumbnail */
/* Forms */
/* Input sizes */
/* Z-indexes */
/*
 * FROM /modules/home/less/variables
 */
/* Site menu */
/* Tasks main */
/* Tasks list */
/*
 * FROM /modules/auth/less/variables
 */
/*
 * FROM /modules/processes/less/variables
 */
.inline-flex {
  display: inline-flex;
  align-items: center;
}
.native-size {
  padding: 0;
  margin: 0;
}
.input-border {
  border: 2px #cacaca solid;
  border-radius: 4px;
}
.select-filter-mode .handler {
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}
.select-filter-mode .handler:hover {
  color: #2e9df0;
  border-color: #2e9df0;
}
.select-filter-mode .handler i {
  font-size: 16px;
}
.select-filter-mode .handler i.icon-common_arrow_down {
  font-size: 4px !important;
  margin-left: 5px !important;
}
.drop-element {
  z-index: 1100;
}
.drop-element.drop-open .dropdown-menu--filter-mode {
  display: block;
}
.drop-element .dropdown-menu--filter-mode {
  display: none;
  min-width: 90px;
  background: #fff;
  border: 1px #cacaca solid;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}
.drop-element .dropdown-menu--filter-mode li.separated {
  border-top: 1px solid #e3e3e3;
}
.drop-element .dropdown-menu--filter-mode li a {
  font-size: 14px;
  color: #222;
  padding: 8px 12px 8px 25px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 105px;
}
.drop-element .dropdown-menu--filter-mode li a.active {
  padding-left: 8px;
}
.drop-element .dropdown-menu--filter-mode li a.active i {
  display: block;
}
.drop-element .dropdown-menu--filter-mode li a i {
  display: none;
  font-size: 8px;
  color: #4abc3b;
  margin-right: 6px;
}
.drop-element .dropdown-menu--filter-mode li a:hover {
  color: #2e9df0;
}
.drop-element .dropdown-menu--filter-mode li a.active i {
  color: #4abc3b;
}
.select-tasks-group .handler {
  font-family: 'Roboto-Medium', Arial;
  color: #9d9d9d;
  text-decoration: none;
  position: relative;
  border: 1px #cacaca solid;
  cursor: pointer;
  background-color: transparent;
  border-radius: 16px;
  transition: all 0.2s ease;
  padding: 5px 8px;
  height: 32px;
  display: flex;
  align-items: center;
}
.select-tasks-group .handler:hover {
  color: #2e9df0;
  border-color: #2e9df0;
}
.select-tasks-group .handler i {
  font-size: 17px;
}
.select-tasks-group .handler i.icon-common_arrow_down {
  font-size: 4px !important;
  margin-left: 5px !important;
}
.drop-element {
  z-index: 1100;
}
.drop-element.drop-open .dropdown-menu--tasks-group {
  display: block;
}
.drop-element .dropdown-menu--tasks-group {
  display: none;
  min-width: 90px;
  background: #fff;
  border: 1px #cacaca solid;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}
.drop-element .dropdown-menu--tasks-group li.separated {
  border-top: 1px solid #e3e3e3;
}
.drop-element .dropdown-menu--tasks-group li a {
  font-size: 14px;
  color: #222;
  padding: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 105px;
}
.drop-element .dropdown-menu--tasks-group li a i {
  color: #959595;
  font-size: 17px;
  margin-right: 12px;
}
.drop-element .dropdown-menu--tasks-group li a:hover {
  color: #2e9df0;
}
.drop-element .dropdown-menu--tasks-group li a.active i {
  color: #4abc3b;
}
.task-duedate-section-header {
  padding: 12px 12px 12px 16px;
  font-size: 14px;
  font-family: 'Roboto-Medium', Arial;
}
.task-duedate-section-header > span {
  color: #a0a4a6;
  font-size: 12px;
  font-family: 'Roboto-Regular', Arial;
}
.task-duedate-section-header.bg-gray {
  background-color: #fafafa;
}
.task-duedate-section-header.bg-red {
  background-color: #fff7f7 !important;
}
.task-process-section-header {
  padding: 0 12px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.task-process-section-header.bg-gray {
  background-color: #fafafa;
}
.task-process-section-header > .first-row {
  display: inline-flex;
  align-items: center;
  flex: 1 1 auto;
}
.task-process-section-header > .first-row > .avatar-small {
  margin-right: 12px;
  color: #40484c;
  min-width: 24px;
}
.task-process-section-header > .first-row > .avatar-small i {
  font-size: 14px;
  display: block;
}
.task-process-section-header > .first-row > .avatar-small i.icon-Mens,
.task-process-section-header > .first-row > .avatar-small i.icon-Magnifi-Glass2 {
  font-size: 12px;
}
.task-process-section-header > .first-row > .avatar-small.default {
  background-color: #ccc;
}
.task-process-section-header > .first-row > a {
  color: #222;
  text-decoration: none;
  font-size: 14px;
  position: relative;
  font-family: 'Roboto-Regular', Arial;
  display: inline-flex;
  max-width: 100%;
}
.task-process-section-header > .first-row > a > span.process-label > a {
  color: #222;
}
.task-process-section-header > .first-row > a > span {
  font-family: 'Roboto-Medium', Arial;
}
.task-process-section-header > .first-row > a:hover {
  color: #2e9df0;
}
.task-process-section-header > .first-row > a:hover .link-hint-template {
  display: inline-flex;
}
.task-process-section-header > .first-row > a .link-hint-template {
  top: calc(100% + 12px);
  min-width: 400px;
  left: -12px;
}
.task-process-section-header > .first-row > a .link-hint-template .square {
  left: 15px;
}
.task-process-section-header > .first-row > a .link-hint-template .top-line {
  height: 9px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}
.task-process-section-header > .first-row > a .link-hint-template__header {
  margin-bottom: -8px;
}
.task-process-section-header > .first-row > a .link-hint-template__content {
  display: inline-flex;
  align-items: center;
  padding-right: 12px;
  margin-top: 4px;
  white-space: nowrap;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul {
  flex: 0 1 305px;
  min-width: 305px;
  float: left;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul li {
  display: inline-block;
  list-style: none;
  float: left;
  height: 3px;
  margin-right: 1px;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul li.not-assigned:not(.in-process):not(.completed):not(.inactive):not(.approved):not(.rejected):not(.stopped-on-reject) {
  background-clip: padding-box;
  background-color: #ccc;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul li:not(.inactive).in-process {
  background-color: #d8d8d8;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul li:not(.inactive).completed,
.task-process-section-header > .first-row > a .link-hint-template__content ul li.approved {
  background: #4abc3b;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul li:not(.inactive).issued {
  background: #f9d83c;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul li:not(.inactive).upcoming,
.task-process-section-header > .first-row > a .link-hint-template__content ul li.rejected {
  background-color: #f75f5f;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul li.not-assigned.upcoming:not(.stopped-on-reject):not(.inactive) {
  background-color: #f75f5f !important;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul li:not(.inactive):not(.stopped-on-reject).upcoming.approved {
  background-color: #4abc3b !important;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul li.inactive,
.task-process-section-header > .first-row > a .link-hint-template__content ul li.stopped-on-reject,
.task-process-section-header > .first-row > a .link-hint-template__content ul li.upcoming.stopped-on-reject {
  background-color: #fff;
  border: 1px solid #d8d8d8;
}
.task-process-section-header > .first-row > a .link-hint-template__content ul li.upcoming.inactive {
  background-color: #fff;
  border: 1px solid #f75f5f;
}
.task-process-section-header > .first-row > a .link-hint-template__content span {
  margin-left: 5px;
}
.task-process-section-header > .second-row {
  text-align: right;
  white-space: nowrap;
  min-width: 250px;
}
.task-process-section-header > .second-row .top {
  color: #a0a4a6;
  font-size: 12px;
  min-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-process-section-header > .second-row .top .owner-name {
  color: #222;
}
.task-process-section-header > .second-row .dueDate {
  font-size: 12px;
  margin-top: 2px;
  color: #959595;
}
.task-process-section-header > .second-row .dueDate .red {
  color: #f75f5f;
}
@media only screen and (max-width: 1023px) {
  .task-process-section-header > .second-row {
    display: none;
  }
}
.task-process-section-header--empty {
  padding: 0 12px 0 16px;
  height: 52px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Medium', Arial;
}
.task-process-section-header--empty.bg-gray {
  background-color: #fafafa;
}
.tasks__list {
  flex: 0 1 40%;
  width: 40%;
  max-width: 40%;
  border-left: 1px #e1ebef solid;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}
.tasks__list .task-process-section-header--empty {
  padding-left: 12px;
}
.tasks__list .task-duedate-section-header {
  padding-left: 12px;
}
.tasks__list.ng-hide-add {
  transition: all 0.15s ease-out;
  max-width: 40%;
}
.tasks__list.ng-hide {
  max-width: 0;
}
.tasks__list__header {
  font-family: 'Roboto-Medium', Arial;
  height: 63px;
  padding: 0 15px 0 25px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px #e1ebef solid;
}
.tasks__list__header h3 {
  color: #222;
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
}
.tasks__list__header > div {
  display: inline-flex;
  align-items: center;
}
.tasks__list__header > div > i {
  color: #cacaca;
  cursor: pointer;
  font-size: 13px;
  margin-left: 12px;
}
.tasks__list__header > div > i:focus {
  outline: none;
}
.tasks__list__header > div > i:hover {
  color: #767676;
}
.tasks__list__empty-state {
  top: 50%;
  position: absolute;
  margin-top: -40px;
  width: 100%;
}
.tasks__list__empty-state div {
  text-align: center;
}
.tasks__list__empty-state div img {
  opacity: 0.2;
}
.tasks__list__empty-state div h5 {
  margin-top: 8px;
  font-size: 12px;
}
.tasks__list__content {
  height: 100%;
  max-height: calc(100% - (4 * 16px));
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.tasks__list__content:after {
  content: '';
  height: 70px;
  width: 100%;
  display: block;
}
.tasks__list__content react-available-tasks-list {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.tasks__list__content__item {
  margin-top: -1px;
  height: 63px;
  border: 1px #e1ebef solid;
  border-left: none;
  display: flex;
  align-items: center;
  padding: 0 11px 0 24px;
  position: relative;
}
.tasks__list__content__item:focus {
  outline: none;
}
.tasks__list__content__item:hover .process-link span.process-label span.action,
.tasks__list__content__item:hover span.created-by span.action,
.tasks__list__content__item:hover .task-name h4 span.action {
  visibility: visible !important;
}
.tasks__list__content__item.isMobile .process-link span.process-label span.action,
.tasks__list__content__item.isMobile span.created-by span.action,
.tasks__list__content__item.isMobile .task-name h4 span.action {
  visibility: visible !important;
}
.tasks__list__content__item:not(.small) .wrap-table.task-name {
  margin-top: 4px;
}
.tasks__list__content__item.small {
  height: 48px;
}
.tasks__list__content__item.hidden {
  display: none;
}
.tasks__list__content__item .icon-task_grab {
  font-size: 21px;
  color: #bbb;
  cursor: pointer;
  user-select: none;
  margin: 0;
  margin-right: 12px;
  flex: 0 1 auto;
}
.tasks__list__content__item .icon-task_grab:hover {
  color: #2e9df0;
}
.tasks__list__content__item .icon-task_grab:focus {
  outline: none;
}
.tasks__list__content__item.animated > div > .wrap-table h4:first-child span:hover {
  color: #2e9df0;
  cursor: pointer;
}
.tasks__list__content__item > div {
  font-family: 'Roboto-Regular', Arial;
  flex: 0 1 100%;
  max-width: calc(100% - 36px);
}
.tasks__list__content__item > div > .wrap-table {
  display: table;
  table-layout: fixed;
  white-space: nowrap;
  width: 100%;
}
.tasks__list__content__item > div > .wrap-table.descr {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tasks__list__content__item > div > .wrap-table h4 {
  color: #222;
  font-size: 14px;
  margin: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: middle;
}
.tasks__list__content__item > div > .wrap-table h4:focus {
  outline: none;
}
.tasks__list__content__item > div > .wrap-table span {
  clear: both;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tasks__list__content__item > div > .wrap-table.task-name h4 {
  white-space: normal;
}
.tasks__list__content__item > div > .wrap-table.task-name h4 > span:first-child {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tasks__list__content__item > div > .wrap-table.task-name h4 span {
  display: inline-block;
  vertical-align: middle;
}
.tasks__list__content__item > div > .wrap-table.task-name h4 span.action {
  visibility: hidden;
}
.tasks__list__content__item > div > .wrap-table.task-name h4 span.action span {
  color: #a3a3a3;
}
.tasks__list__content__item > div > .wrap-table.task-name h4 span.action span.assign-to-me {
  white-space: nowrap;
  color: #2e9df0;
}
.tasks__list__content__item > div > .wrap-table.task-name h4 span.action span.assign-to-me:hover {
  color: #00ccff;
  cursor: pointer;
}
.tasks__list__content__item > div > .wrap-table.task-name h4 span.action {
  font-size: 12px;
}
.tasks__list__content__item > div > .wrap-table.process-link span.process-label {
  white-space: normal;
}
.tasks__list__content__item > div > .wrap-table.process-link span.process-label span {
  display: inline-block;
  vertical-align: middle;
}
.tasks__list__content__item > div > .wrap-table.process-link span.process-label span.process-name {
  color: #a3a3a3;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tasks__list__content__item > div > .wrap-table.process-link span.process-label span.process-name:hover {
  color: #2e9df0;
}
.tasks__list__content__item > div > .wrap-table.process-link span.process-label span.action {
  visibility: hidden;
}
.tasks__list__content__item > div > .wrap-table.process-link span.process-label span.action span {
  color: #a3a3a3;
}
.tasks__list__content__item > div > .wrap-table.process-link span.process-label span.action span.assign-to-me {
  white-space: nowrap;
  color: #2e9df0;
}
.tasks__list__content__item > div > .wrap-table.process-link span.process-label span.action span.assign-to-me:hover {
  color: #00ccff;
  cursor: pointer;
}
.tasks__list__content__item > div > .wrap-table.created-by {
  white-space: normal;
}
.tasks__list__content__item > div > .wrap-table.created-by > span:first-child {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tasks__list__content__item > div > .wrap-table.created-by span {
  display: inline-block;
  vertical-align: middle;
}
.tasks__list__content__item > div > .wrap-table.created-by span.action {
  visibility: hidden;
}
.tasks__list__content__item > div > .wrap-table.created-by span.action span {
  color: #a3a3a3;
}
.tasks__list__content__item > div > .wrap-table.created-by span.action span.assign-to-me {
  white-space: nowrap;
  color: #2e9df0;
}
.tasks__list__content__item > div > .wrap-table.created-by span.action span.assign-to-me:hover {
  color: #00ccff;
  cursor: pointer;
}
.tasks__list__content__item > div > .wrap-table.descr {
  margin-top: 4px;
  width: 100%;
  color: #a3a3a3;
  font-size: 12px;
}
.tasks__list__content__item > div > .wrap-table.descr a {
  max-width: 100%;
  display: inline-block;
  cursor: pointer;
  color: #a3a3a3;
  font-size: 12px;
}
.tasks__list__content__item > div > .wrap-table.descr a:focus {
  outline: none;
}
.tasks__list__content__item > div > .wrap-table.descr a:hover {
  color: #2e9df0;
}
.tasks__list__content__item > div > .wrap-table.descr a span.process-name:hover .link-hint-template {
  visibility: visible;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template {
  top: 60px;
  max-width: 400px;
  width: calc(100% - 40px);
  left: 30px;
  display: inline-flex;
  visibility: hidden;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template .full-size-div {
  width: 100%;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template .square {
  left: 15px;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content {
  display: inline-flex;
  align-items: center;
  padding-right: 12px;
  margin-top: 4px;
  width: 100%;
  max-width: 100%;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul {
  flex: 0 1 100%;
  width: 100%;
  max-width: 320px;
  float: left;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li {
  display: inline-block;
  list-style: none;
  float: left;
  height: 3px;
  margin-right: 1px;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li.not-assigned:not(.in-process):not(.completed):not(.inactive):not(.approved):not(.rejected):not(.stopped-on-reject) {
  background-clip: padding-box;
  background-color: #ccc;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li:not(.inactive).in-process {
  background-color: #d8d8d8;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li:not(.inactive).completed,
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li.approved {
  background: #4abc3b;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li:not(.inactive).issued {
  background: #f9d83c;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li:not(.inactive).upcoming,
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li.rejected {
  background-color: #f75f5f;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li.not-assigned.upcoming:not(.stopped-on-reject):not(.inactive) {
  background-color: #f75f5f !important;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li:not(.inactive):not(.stopped-on-reject).upcoming.approved {
  background-color: #4abc3b !important;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li.inactive,
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li.stopped-on-reject,
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li.upcoming.stopped-on-reject {
  background-color: #fff;
  border: 1px solid #d8d8d8;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content ul li.upcoming.inactive {
  background-color: #fff;
  border: 1px solid #f75f5f;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__content span {
  margin-left: 4px;
  flex: 0 1 auto;
  width: auto;
  min-width: 40px;
}
.tasks__list__content__item > div > .wrap-table.descr .link-hint-template__header span {
  display: inline;
}
.tasks__main {
  padding: 0 25px 0 32px;
  flex: 0 1 100%;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  transition: all 0.15s ease;
}
.tasks__main.small > .tasks__main__content {
  max-height: calc(100% - (7 * 16px));
}
.tasks__main.with-list {
  width: 60%;
  flex: 0 1 60%;
  max-width: 60%;
  transition: all 0.15s ease;
}
.tasks__main__empty-state,
.tasks__main__error-state {
  border: 1px solid #e1ebef;
  padding: 64px 0;
  width: 100%;
  text-align: center;
}
.tasks__main__empty-state + div,
.tasks__main__error-state + div {
  display: none !important;
}
.tasks__main__empty-state > i,
.tasks__main__error-state > i {
  font-size: 64px;
  color: #d1d1d1;
}
.tasks__main__empty-state > i + h5,
.tasks__main__error-state > i + h5 {
  margin-top: 16px;
}
.tasks__main__empty-state h5,
.tasks__main__error-state h5 {
  color: #222;
  font-size: 14px;
  margin-top: 24px;
  white-space: pre-wrap;
}
.tasks__main__empty-state p,
.tasks__main__error-state p {
  margin: 8px auto 0;
  font-size: 12px;
  color: #959595;
  white-space: pre-wrap;
  max-width: 260px;
}
.tasks__main__error-state {
  border-color: #f75f5f;
}
.tasks__main__content {
  clear: both;
  font-family: 'Roboto-Regular', Arial;
  max-height: calc(100% - (4 * 16px));
  height: 100%;
  padding: 1px 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.tasks__main__content:after {
  content: '';
  height: 70px;
  width: 100%;
  display: block;
}
.tasks__main__content react-main-tasks-list {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.tasks__main__content__task {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 62px;
  max-height: 62px;
  padding-right: 12px;
  border: 1px #e1ebef solid;
  border-left-width: 4px;
  position: relative;
}
.tasks__main__content__task:focus {
  outline: none;
}
.tasks__main__content__task.hidden {
  display: none;
}
.tasks__main__content__task.small {
  height: 48px;
}
.tasks__main__content__task:not(.completed):not(.animation):not(.initial):hover {
  background: #f2fafe;
}
.tasks__main__content__task:not(.animation):hover .tasks__main__content__task__hover_actions .action,
.tasks__main__content__task:not(.animation):hover .tasks__main__content__task__hover_actions .actions-list-drop {
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.15s;
  transform: translateX(0px);
}
.tasks__main__content__task:not(.animation):hover .tasks__main__content__task__hint {
  display: none;
}
.tasks__main__content__task.calendar-opened:not(.animation) .tasks__main__content__task__hover_actions .action,
.tasks__main__content__task.calendar-opened:not(.animation) .tasks__main__content__task__hover_actions .actions-list-drop {
  transform: translateX(0px) !important;
}
.tasks__main__content__task.calendar-opened:not(.animation) .tasks__main__content__task__hint {
  display: none;
}
.tasks__main__content__task.animation {
  transition: all 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
  opacity: 0;
  max-height: 0;
  position: relative;
  text-overflow: clip;
  white-space: nowrap;
  margin-top: -1px;
}
.tasks__main__content__task.animation:not(.first) + .tasks__main__content__task {
  border-top: none !important;
}
.tasks__main__content__task.first + .tasks__main__content__task {
  border-top: none;
}
.tasks__main__content__task:not(.animation):not(.first) + .tasks__main__content__task {
  border-top: none;
}
.tasks__main__content__task.stopped {
  background: #fff;
  border: 1px #e1ebef solid;
  border-left: 3px #e1ebef solid;
}
.tasks__main__content__task.completed {
  background: #fff;
  border: 1px #e1ebef solid;
  border-left: 3px #4abc3b solid;
}
.tasks__main__content__task.completed > div:first-child,
.tasks__main__content__task.delegated > div:first-child {
  max-width: calc(100% - (8 * 12px));
  flex: 0 1 calc(100% - (8 * 12px));
}
.tasks__main__content__task:not(.adHoc) > div > div > h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tasks__main__content__task.animated:hover {
  cursor: pointer;
}
.tasks__main__content__task.animated .tasks__main__content__task__info h4:hover {
  color: #2e9df0;
  cursor: pointer;
}
.tasks__main__content__task > div:first-child > .approval-default {
  margin: 0 10px 0 11px;
  position: relative;
}
.tasks__main__content__task > div:first-child > .approval-default > .task-list-approval-tooltip {
  position: absolute;
  width: 100%;
  height: 100%;
}
.tasks__main__content__task > div {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
}
.tasks__main__content__task > div.rest {
  flex: 1 0 auto;
  justify-content: flex-end;
}
.tasks__main__content__task > div:first-child {
  max-width: calc(100% - (13 * 12px));
  flex: 0 1 calc(100% - (13 * 12px));
}
.tasks__main__content__task > div > .avatar {
  margin-left: 8px;
}
.tasks__main__content__task > div > .avatar.group {
  color: #a3a3a3;
  border: 1px dashed #9d9d9d;
}
.tasks__main__content__task > div > .avatar.group i {
  font-size: 21px;
  margin-left: 8px;
  margin-top: -1px;
  display: block;
}
.tasks__main__content__task > div > .avatar.group i::before {
  margin: 0;
}
.tasks__main__content__task__hover_actions {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  height: 100%;
  align-items: center;
  padding-left: 4px;
}
.tasks__main__content__task__hover_actions .action,
.tasks__main__content__task__hover_actions .actions-list-drop {
  transform: translateX(100px);
}
.tasks__main__content__task__hover_actions .action {
  font-size: 28px;
  color: #2e9df0;
  cursor: pointer;
  margin-right: 0.2em;
  margin-left: 0.2em;
}
.tasks__main__content__task__hover_actions .action:focus {
  outline: none;
}
.tasks__main__content__task__hover_actions .action i {
  margin: 0;
}
.tasks__main__content__task__hover_actions .action.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.tasks__main__content__task__info {
  display: inline-block;
  max-width: calc(100% - (21px + 2 * 12px + 4px));
  padding-right: 4px;
}
.tasks__main__content__task__info h4 {
  color: #222;
  font-size: 14px;
  margin: 0;
  word-wrap: break-word;
  max-height: 32px;
  overflow: hidden;
}
.tasks__main__content__task__info > span {
  color: #a3a3a3;
  text-decoration: none;
  font-size: 12px;
  position: relative;
  font-family: 'Roboto-Regular', Arial;
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tasks__main__content__task__info a {
  color: #a3a3a3;
  text-decoration: none;
  font-size: 12px;
  position: relative;
  font-family: 'Roboto-Regular', Arial;
  display: inline-flex;
  white-space: nowrap;
  max-width: 100%;
}
.tasks__main__content__task__info a > span {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.tasks__main__content__task__info a.delegated {
  cursor: text;
}
.tasks__main__content__task__info a.delegated:hover {
  color: #a3a3a3;
}
.tasks__main__content__task__info a i {
  font-size: 10px;
}
.tasks__main__content__task__info a:hover {
  color: #2e9df0;
}
.tasks__main__content__task__info a:hover .link-hint-template {
  visibility: visible;
}
.tasks__main__content__task__info a .link-hint-template {
  top: 25px;
  min-width: 400px;
  left: -16px;
  display: inline-flex;
  visibility: hidden;
}
.tasks__main__content__task__info a .link-hint-template .square {
  left: 15px;
}
.tasks__main__content__task__info a .link-hint-template .top-line {
  height: 9px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}
.tasks__main__content__task__info a .link-hint-template__content {
  display: inline-flex;
  align-items: center;
  padding-right: 12px;
  margin-top: 4px;
  white-space: nowrap;
}
.tasks__main__content__task__info a .link-hint-template__content ul {
  flex: 0 1 305px;
  min-width: 305px;
  float: left;
}
.tasks__main__content__task__info a .link-hint-template__content ul li {
  display: inline-block;
  list-style: none;
  float: left;
  height: 3px;
  margin-right: 1px;
}
.tasks__main__content__task__info a .link-hint-template__content ul li.not-assigned:not(.in-process):not(.completed):not(.inactive):not(.approved):not(.rejected):not(.stopped-on-reject) {
  background-clip: padding-box;
  background-color: #ccc;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}
.tasks__main__content__task__info a .link-hint-template__content ul li:not(.inactive).in-process {
  background-color: #d8d8d8;
}
.tasks__main__content__task__info a .link-hint-template__content ul li:not(.inactive).completed,
.tasks__main__content__task__info a .link-hint-template__content ul li.approved {
  background: #4abc3b;
}
.tasks__main__content__task__info a .link-hint-template__content ul li:not(.inactive).issued {
  background: #f9d83c;
}
.tasks__main__content__task__info a .link-hint-template__content ul li:not(.inactive).upcoming,
.tasks__main__content__task__info a .link-hint-template__content ul li.rejected {
  background-color: #f75f5f;
}
.tasks__main__content__task__info a .link-hint-template__content ul li.not-assigned.upcoming:not(.stopped-on-reject):not(.inactive) {
  background-color: #f75f5f !important;
}
.tasks__main__content__task__info a .link-hint-template__content ul li:not(.inactive):not(.stopped-on-reject).upcoming.approved {
  background-color: #4abc3b !important;
}
.tasks__main__content__task__info a .link-hint-template__content ul li.inactive,
.tasks__main__content__task__info a .link-hint-template__content ul li.stopped-on-reject,
.tasks__main__content__task__info a .link-hint-template__content ul li.upcoming.stopped-on-reject {
  background-color: #fff;
  border: 1px solid #d8d8d8;
}
.tasks__main__content__task__info a .link-hint-template__content ul li.upcoming.inactive {
  background-color: #fff;
  border: 1px solid #f75f5f;
}
.tasks__main__content__task__info a .link-hint-template__content span {
  margin-left: 5px;
  white-space: nowrap;
}
.tasks__main__content__task__hint {
  display: flex;
  justify-content: flex-end;
  font-family: 'Roboto-Regular', Arial;
  align-items: center;
}
.tasks__main__content__task__hint span {
  display: inline-table;
  margin-left: 12px;
  font-size: 12px;
  color: #959595;
  white-space: nowrap;
  min-width: 30px;
  text-align: right;
}
.tasks__main__content__task__hint span > i {
  font-size: 14px;
}
.tasks__main__content__task__hint span.warn {
  color: #f75f5f;
}
.tasks__main__content__task__hint span:first-child {
  margin-left: 0;
}
.tasks__main__content__task__hint span i {
  margin: 0 4px 0 0;
}
.tasks__main__content__task__hint .new {
  color: #fff;
  font-size: 12px;
  display: inline-block;
  float: left;
  background: #2e9df0;
  padding: 5px 10px;
  border-radius: 12px;
}
.tasks__main__content__task__hint .comments-updated {
  color: #2e9df0;
}
.tasks {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
}
@media only screen and (min-width: 1023px) {
  .tasks__main__content > header {
    display: none !important;
  }
  .tasks__list__content > header {
    display: none !important;
  }
}
@media only screen and (max-width: 1023px) {
  .tasks__main {
    padding: 0 !important;
  }
  .tasks__main.with-list {
    width: 100% !important;
    flex: 0 1 100% !important;
    max-width: 100% !important;
  }
  .tasks__main__header {
    padding: 0 16px;
  }
  .tasks__main__header > form {
    left: 16px !important;
    width: calc(100% - (2 * 16px)) !important;
  }
  .tasks__main__content {
    max-height: 100% !important;
    overflow-x: hidden;
  }
  .tasks__main__content__task:hover {
    background: #fff !important;
  }
  .tasks__main__content__task > div:first-child {
    max-width: calc(100% - (5 * 16px)) !important;
    flex: 0 1 calc(100% - (5 * 16px)) !important;
  }
  .tasks__main__content__task__hint .task-comments,
  .tasks__main__content__task__hint .task-attachments {
    display: none;
  }
  .tasks__main.empty > header {
    display: flex !important;
  }
  .tasks__list {
    padding: 0 !important;
    flex: 1 0 100% !important;
    min-width: 100% !important;
    position: absolute !important;
    background-color: #fff !important;
    left: 0;
    top: 0;
  }
  .tasks__list.ng-hide-add,
  .tasks__list.ng-hide-remove {
    transition: all 0.15s ease;
  }
  .tasks__list.ng-hide {
    transform: translate(100%, 0);
  }
  .tasks__list__header {
    padding: 0 12px !important;
  }
  .tasks__list__content {
    max-height: 100% !important;
  }
  .tasks__list > header {
    display: none !important;
  }
  .tasks__list.empty > header {
    display: flex !important;
  }
}
@media only screen and (max-width: 414px) {
  .tasks .tasks__main__header > div > .btn-switcher {
    margin-left: 16px;
  }
  .tasks .tasks__main__header > div > .btn-switcher span {
    display: none;
  }
  .tasks .tasks__main__header > div > .btn-switcher i {
    margin-right: 1px;
  }
  .tasks .tasks__main__content.small .tasks__main__header {
    height: 112px;
  }
}
.tasks__main__header {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto-Medium', Arial;
  position: relative;
}
.tasks__main__header.small {
  height: 112px;
}
.tasks__main__header.privates .selectize-control {
  display: none;
}
.tasks__main__header.privates .actors-error-container .input-hint--error {
  display: none;
}
.tasks__main__header > div {
  display: inline-flex;
  align-items: center;
}
.tasks__main__header > div h3 {
  font-weight: normal;
  color: #222;
  font-size: 14px;
}
.tasks__main__header > div button {
  margin-left: 24px;
}
.tasks__main__header > div button i {
  margin-left: 0;
  margin-right: 5px;
}
.tasks__main__header > form.ad-hoc-task {
  position: absolute;
  width: 100%;
  top: 16px;
  height: 32px;
  align-items: flex-start;
  z-index: 1;
  left: 0;
  background: #fff;
  margin: 0;
  visibility: visible;
}
.tasks__main__header > form.ad-hoc-task.ng-hide-add,
.tasks__main__header > form.ad-hoc-task.ng-hide-remove {
  transition: all linear 0.1s;
}
.tasks__main__header > form.ad-hoc-task.ng-hide {
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.01s;
  transform: scale(0.7, 1);
}
.tasks__main__header > form.ad-hoc-task.small {
  display: none;
  height: 80px;
}
.tasks__main__header > form.ad-hoc-task.small > div.first-row > button,
.tasks__main__header > form.ad-hoc-task.small > div.second-row > button {
  margin-left: 12px;
  min-width: 36px;
  width: 36px;
  flex: 0 1 36px;
  display: block;
}
.tasks__main__header > form.ad-hoc-task.small > div.first-row > button:not(:hover):not(:focus) i,
.tasks__main__header > form.ad-hoc-task.small > div.second-row > button:not(:hover):not(:focus) i {
  color: #ccc;
}
.tasks__main__header > form.ad-hoc-task.small > div.first-row > button i,
.tasks__main__header > form.ad-hoc-task.small > div.second-row > button i {
  margin: 0;
  font-size: 10px;
}
.tasks__main__header > form.ad-hoc-task.small > div.first-row > button i.icon-menu_checkmark,
.tasks__main__header > form.ad-hoc-task.small > div.second-row > button i.icon-menu_checkmark {
  margin-left: -1px;
}
.tasks__main__header > form.ad-hoc-task.small > div.first-row > button + button,
.tasks__main__header > form.ad-hoc-task.small > div.second-row > button + button {
  margin-left: 4px;
}
.tasks__main__header > form.ad-hoc-task.small > div.first-row {
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
}
.tasks__main__header > form.ad-hoc-task.small > div.first-row .input-container {
  width: calc(100% - (3 * 12px));
  flex: 0 1 calc(100% - (3 * 12px));
}
.tasks__main__header > form.ad-hoc-task.small > div.second-row {
  display: flex;
  align-items: flex-start;
  height: 32px;
  margin-top: 16px;
}
.tasks__main__header > form.ad-hoc-task.small > div.second-row .prc-date-picker {
  margin-left: 0;
  display: inline-table;
  flex: 0 0;
}
.tasks__main__header > form.ad-hoc-task.small > div.second-row .selectize-control {
  margin-left: 8px;
}
.tasks__main__header > form.ad-hoc-task.small > div.second-row > button {
  display: none;
}
.tasks__main__header > form.ad-hoc-task .input-container {
  width: calc(100% - (210px + 12px * 8));
  flex: 0 1 calc(100% - (210px + 12px * 8));
  position: relative;
}
.tasks__main__header > form.ad-hoc-task .input-container.no-select {
  width: calc(100% - (12px * 8));
  flex: 0 1 calc(100% - (12px * 8));
}
.tasks__main__header > form.ad-hoc-task .input-container > input {
  max-width: 100%;
}
.tasks__main__header > form.ad-hoc-task .input-container > input.red {
  border-color: #f75f5f !important;
}
.tasks__main__header > form.ad-hoc-task .input-container > .input-hint--error {
  color: #f75f5f;
  font-family: 'Roboto-Medium', Arial;
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 33px;
}
.tasks__main__header > form.ad-hoc-task .actors-error-container {
  position: relative;
}
.tasks__main__header > form.ad-hoc-task .actors-error-container > .input-hint--error {
  color: #f75f5f;
  font-family: 'Roboto-Medium', Arial;
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 33px;
  margin-left: 8px;
}
@media only screen and (max-width: 767px) {
  .tasks__main__header > form.ad-hoc-task .actors-error-container > .input-hint--error {
    margin-left: 0;
  }
}
.tasks__main__header > form.ad-hoc-task .prc-date-picker {
  background: #fff;
  margin: 0 0 0 8px;
}
.tasks__main__header > form.ad-hoc-task .prc-date-picker .handler {
  padding: 6px 7px;
  flex: 1 0 auto;
  border: 2px #cacaca solid;
  border-radius: 4px;
  border-width: 1px;
  cursor: pointer;
  height: 32px;
}
.tasks__main__header > form.ad-hoc-task .selectize-control {
  width: 210px;
  min-width: 210px;
  flex: 0 1 210px;
  margin-left: 8px;
}
.tasks__main__header > form.ad-hoc-task .selectize-control .selectize-dropdown .dropdown-list {
  width: 298px;
}
.tasks__main__header > form.ad-hoc-task > button {
  margin-left: 12px;
  min-width: 36px;
  width: 36px;
  flex: 0 1 36px;
  display: block;
}
.tasks__main__header > form.ad-hoc-task > button:not(:hover):not(:focus) i {
  color: #ccc;
}
.tasks__main__header > form.ad-hoc-task > button i {
  margin: 0;
  font-size: 10px;
}
.tasks__main__header > form.ad-hoc-task > button i.icon-menu_checkmark {
  margin-left: -1px;
}
.tasks__main__header > form.ad-hoc-task > button + button {
  margin-left: 4px;
}
.tasks__main__header__menu {
  float: right;
  display: inline-flex;
  align-items: center;
}
.tasks__main__header__menu > li {
  display: inline-flex;
  align-items: center;
  float: left;
  color: #222;
  font-family: 'Roboto-Regular', Arial;
  font-size: 14px;
  font-weight: normal;
  position: relative;
  outline: none;
  cursor: pointer;
  margin-left: 12px;
}
.tasks__main__header__menu > li .icon-common_arrow_down {
  font-size: 4px;
  margin-left: 6px;
}
.tasks__main__header__menu > li:hover,
.tasks__main__header__menu > li:hover > i {
  color: #2e9df0;
}
.tasks__main__header__menu > li.active,
.tasks__main__header__menu > li.active:hover,
.tasks__main__header__menu > li.active:hover > i {
  color: #222;
}
.tasks__main__header__menu > li:first-child {
  margin-left: 0;
}
.tasks__main__header__menu > li:first-child span {
  display: inline-flex;
  align-items: center;
}
.tasks__main__header__menu > li:last-child {
  border: 1px #cacaca solid;
  color: #959595;
  padding: 6px 36px 6px 12px;
  height: 32px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  margin-right: -48px;
  opacity: 1;
}
.tasks__main__header__menu > li:last-child.ng-hide-remove {
  transition: all 0.1s ease 0.2s;
}
.tasks__main__header__menu > li:last-child.ng-hide-add {
  display: none;
}
.tasks__main__header__menu > li:last-child.ng-hide {
  opacity: 0;
}
.tasks__main__header__menu > li:last-child:hover,
.tasks__main__header__menu > li:last-child.active {
  color: #2e9df0;
  border-color: #2e9df0;
}
.tasks__main__header__menu > li:last-child.active .right-triangle {
  display: block;
  height: 8px;
  width: 8px;
  background: #fff;
  border: 1px #2e9df0 solid;
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  right: -4px;
  top: 50%;
  margin-top: -4px;
  position: absolute;
}
@media only screen and (max-width: 767px) {
  .tasks__main__header:not(.privates) .ad-hoc-task {
    display: none;
  }
  .tasks__main__header:not(.privates) .ad-hoc-task.small {
    display: block !important;
  }
  .tasks__main__header.privates.small {
    height: 64px;
  }
}
@media only screen and (max-width: 414px) {
  .tasks__main__header.privates.small {
    height: 112px !important;
  }
  .tasks__main__header.privates .ad-hoc-task {
    display: none;
  }
  .tasks__main__header.privates .ad-hoc-task.small {
    display: block !important;
  }
  .tasks__main__header.privates .ad-hoc-task.small > .first-row .input-container {
    width: 100% !important;
    flex: 0 1 100% !important;
  }
  .tasks__main__header.privates .ad-hoc-task.small > .first-row > button {
    display: none;
  }
  .tasks__main__header.privates .ad-hoc-task.small > .second-row > button {
    display: inline-block;
  }
}
.undo-deletion-popup {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 5;
  background: rgba(74, 188, 59, 0.8);
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  min-width: 286px;
  color: #fff;
  cursor: default;
  right: 32px;
  top: 5px;
}
.undo-deletion-popup:focus {
  outline: none;
}
.undo-deletion-popup > .icon-common_close {
  color: #fff;
  opacity: 0.7;
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 12px;
  cursor: pointer;
}
.undo-deletion-popup > .icon-common_close:hover {
  opacity: 1;
}
.undo-deletion-popup > .icon-template_delete {
  margin-right: 12px;
  flex: 0 1 auto;
}
.undo-deletion-popup > .icon-template_delete {
  margin-right: 12px;
  flex: 0 1 auto;
}
.undo-deletion-popup div {
  flex: 0 1 auto;
  padding-right: 20px;
}
.undo-deletion-popup div header {
  color: #fff;
  font-family: 'Roboto-Bold', Arial;
  font-size: 12px;
}
.undo-deletion-popup div a {
  color: #fff;
  text-decoration: underline;
  margin-top: 4px;
  cursor: pointer;
  font-size: 12px;
}
.undo-deletion-popup div a:hover {
  text-decoration: none;
}
