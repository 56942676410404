.price-data-field {
  display: flex;
  align-items: stretch;
  .input-border;
  border-width: 1px;
  max-width: 180px;
  box-shadow: none;
  position: relative;

  & > span {
    display: none;
  }

  .form-group.error & {
    border: 1px solid @color-red;

    &.active {
      box-shadow: 0 0 0 1px @color-red;
    }
  }

  &.active {
    border: 1px solid @color-blue;
    box-shadow: 0 0 0 1px @color-blue;
  }

  &.disabled {
    .unit {
      cursor: default;
    }
  }

  input {
    width: 120px;
    border: none !important;
    box-shadow: none !important;

    &:disabled {
      background-color: @color-panel-grey !important;
      user-select: all;
    }
  }

  .unit {
    border-left: 1px solid rgb(204, 204, 204);
    background-color: @color-panel-grey;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    cursor: pointer;

    &.single {
      padding: 0 @offset-md;
      cursor: default;
      i {
        display: none;
      }
    }

    .handler {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      padding: 0 @offset-md;
      min-height: 30px;
    }

    i {
      font-size: 4px;
      margin-left: @offset-xs
    }
  }
}

.price-field-element {
  z-index: 1100;

  &.price-field-open {
    .additional-currencies {
      display: block;
    }
  }

  .additional-currencies {
    display: none;
    min-width: 90px;
    max-height: 240px;
    overflow: auto;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0,0,0,0.15);

    li {
      a {
        font-size: @font-size-sm;
        color: @color-main-copy;
        padding: 8px @offset-md 8px 25px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        min-width: 105px;

        &.active {
          padding-left: 8px;
          i {
            display: block;
          }
        }

        i {
          display: none;
          font-size: @font-size-icon;
          color: @color-green;
          margin-right: 6px;
        }

        &:hover {
          color: @color-blue;
        }
        &.active {
          i {
            color: @color-green;
          }
        }
      }
    }
  }
}
