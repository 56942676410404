@import "../../../less/variables";
@import "../../../less/mixins";

.review-actions {
  width: 2*@offset-md;
  height: 2*@offset-lg;
  position: absolute;
  right: 0;
  display: inline-block;

  .no-focus();

  & > .handler {
    padding-right: 2px;
    height: 100%;
    color: @color-white;
    text-align: center;
    border-top-right-radius: @buttons-border-radius;
    border-bottom-right-radius: @buttons-border-radius;
    background-color: rgba(0,0,0,0.2);
    transition: all .1s ease;
    cursor: pointer;

    &:hover {
      background-color: rgba(0,0,0,0.1);
    }

    i {
      font-size: 5px;
      line-height: 2*@offset-lg;
      vertical-align: middle;
    }
  }
}

.review-actions-drop {
  margin-left: -150px;
  margin-top: @offset-xs;

  &.review-actions-drop-open {
    .dropdown-menu--review-actions {
      display: block;
    }
  }

  .dropdown-menu--review-actions {
    display: none;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0,0,0,0.15);
    min-width: 175px;

    li {
      &.separated {
        border-top: 1px solid #e3e3e3;
      }

      a {
        font-size: @font-size-md;
        color: @color-main-copy;
        padding: @offset-md;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:hover {
          color: @color-blue;
        }
      }
    }
  }
}
