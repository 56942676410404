@import "../../../less/variables";
@import "../../../less/mixins";

.processes__main__header {
  .inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 @site-padding;
  height: 4*@offset-lg;
  .no-focus();

  &__nav {
    display: inline-flex;

    li {
      .native-size;

      & + li {
        margin-left: @offset-lg;
      }

      & > a {
        .inline-flex;
        font-family: @font-family-semibold;
        font-size: @font-sm-size;
        color: @site-menu-color;
        text-decoration: none;
        line-height: 18px;
        cursor: pointer;

        &.start-process {
          border: 1px @color-secondary-btn solid;
          border-radius: @buttons-border-radius;
          font-size: @font-size-md;

          i {
            margin-right: 5px;
            color: @color-secondary-copy;
          }

          &:hover {
            color: @color-blue;
            border-color: @color-blue;

            i {
              color: @color-blue;
            }
          }

          &:active {
            color: @color-white;
            background-color: @color-blue;

            i {
              color: @color-white;
            }
          }
        }
      }
    }
  }

  &__filters {
    .inline-flex;

    li {
      &.search-block {
        position: relative;

        &.opened {
          & > input {
            width: 320px;
          }
        }

        & > i {
          position: absolute;
          color: @color-secondary-copy;
          cursor: pointer;
          .no-focus();

          &.icon-header_search {
            left: 6px;
            margin-top: -1px;
            font-size: @font-size-lg;
            top: 50%;
            transform: translateY(-50%);
            transition: all .15s ease;
          }

          &.icon-common_remove {
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
          }
        }

        & > input {
          position: relative;
          z-index: 1;
          transition: all .15s ease;
          opacity: 1;
          padding: 8px 30px;
          appearance: none;
          flex: 0 1 100%;
          width: 180px;
          border: 1px @color-secondary-btn solid;
          border-radius: 4px;
          box-shadow: none !important;
          color: @color-main-copy;
          resize: none;
          outline: 0;
          cursor: pointer;
          background: transparent;

          &::-ms-clear {
            display: none;
          }

          &:focus, &.active {
            outline: none;
            border-color: @color-blue;
            border-width: 2px;
            cursor: text;

            & + .icon-header_search {
              color: @color-main-copy;
            }
          }
        }
      }

      &  + li {
        margin-left: @offset-md;
      }
    }

    .mode {
      .inline-flex;
      float: left;
      color: @site-menu-color-hov;
      font-size: @font-text-size;
      font-family: @font-family-regular;
      font-weight: normal;
      position: relative;
      outline: none;
      cursor: pointer;
      margin-right: @offset-xs;
      transition: all .2s ease;

      span {
        display: inline-flex;
        align-items: center;
      }

      &:hover,
      &:hover > i {
        color: @color-blue;
      }

      .icon-common_arrow_down {
        font-size: 4px;
        margin-left: 4px;
      }

      & > .filter-dropdown {
        width: 120px;
        top: 30px;
        right: -4px;
        left: auto;
      }
    }

    .btn-switcher + .btn-switcher {
      margin-left: @offset-xs;
    }
  }

  & > a {
    i {
      color: rgba(0, 0, 0, 0.5);
      margin-right: 6px;
    }
  }
}
