@import "../../../../less/variables";
@import "../../../../less/mixins";

.tasks__main {
  padding: 0 25px 0 @site-padding;
  flex: 0 1 100%;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  transition: all 0.15s ease;

  &.small {
    & > .tasks__main__content {
      max-height: calc(100% - (7 * @offset-lg));
    }
  }

  &.with-list {
    width: 60%;
    flex: 0 1 60%;
    max-width: 60%;
    transition: all .15s ease;
  }

  &__empty-state,
  &__error-state {
    border: 1px solid @color-pale-blue;
    padding: 4*@offset-lg 0;
    width: 100%;
    text-align: center;

    & + div {
      display: none !important;
    }

    & > i {
      font-size: 64px;
      color: #d1d1d1;

      & + h5 {
        margin-top: @offset-lg;
      }
    }

    h5 {
      color: @color-main-copy;
      font-size: @font-size-md;
      margin-top: 2*@offset-md;
      white-space: pre-wrap;
    }

    p {
      margin: @offset-sm auto 0;
      font-size: @font-size-sm;
      color: @color-secondary-copy;
      white-space: pre-wrap;
      max-width: 260px;
    }
  }

  &__error-state {
    border-color: @color-red;
  }

  &__content {
    clear: both;
    font-family: @font-family-semibold;
    max-height: calc(100% - (4 * @offset-lg));
    height: 100%;
    padding: 1px 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .google-top-scroll-shadow();
    .bottom-offset(@lists-screen-bottom-offset);

    react-main-tasks-list {
      width: 100%;
      display: table;
      table-layout: fixed;
    }

    &__task {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: @main-task-size;
      max-height: @main-task-size;
      padding-right: 12px;
      border: 1px @border-gray-light-color solid;
      border-left-width: 4px;
      position: relative;
      .no-focus();

      &.hidden {
        display: none;
      }

      &.small {
        height: 48px;
      }

      &:not(.completed):not(.animation):not(.initial):hover {
        background: #f2fafe;
      }

      &:not(.animation):hover {
        .tasks__main__content__task__hover_actions {
          .action,
          .actions-list-drop {
            transition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275) .15s;
            transform: translateX(0px);
          }
        }

        .tasks__main__content__task__hint {
          display: none;
        }
      }

      &.calendar-opened:not(.animation) {
        .tasks__main__content__task__hover_actions {
          .action,
          .actions-list-drop {
            transform: translateX(0px) !important;
          }
        }

        .tasks__main__content__task__hint {
          display: none;
        }
      }

      &.animation {
        transition: all .15s cubic-bezier(0.250, 0.250, 0.750, 0.750);
        opacity: 0;
        max-height: 0;
        position: relative;
        text-overflow: clip;
        white-space: nowrap;
        margin-top: -1px;

        &:not(.first) + .tasks__main__content__task {
          border-top: none !important;
        }
      }

      &.first + .tasks__main__content__task {
        border-top: none;
      }

      &:not(.animation):not(.first) + .tasks__main__content__task {
        border-top: none;
      }

      &.stopped {
        background: @white-color;
        border: 1px @border-gray-light-color solid;
        border-left: 3px @border-gray-light-color solid;
      }

      &.completed {
        background: @white-color;
        border: 1px @border-gray-light-color solid;
        border-left: 3px @green-color solid;
      }

      &.completed,
      &.delegated {
        & > div:first-child {
          max-width: calc(100% - (8 * @offset-md));
          flex: 0 1 calc(100% - (8 * @offset-md));
        }
      }

      &:not(.adHoc) {
        & > div > div {
          & > h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &.animated {
        &:hover {
          cursor: pointer;
        }

        .tasks__main__content__task__info {
          h4:hover {
            color: @color-blue;
            cursor: pointer;
          }
        }
      }

      & > div:first-child > .approval-default {
        margin: 0 10px 0 11px;
        position: relative;

        & > .task-list-approval-tooltip {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      & > div {
        display: flex;
        align-items: center;
        flex: 0 1 auto;

        &.rest {
          flex: 1 0 auto;
          justify-content: flex-end;
        }

        &:first-child {
          max-width: calc(100% - (13 * @offset-md));
          flex: 0 1 calc(100% - (13 * @offset-md));
        }


        & > .avatar {
          margin-left: @offset-sm;

          &.group {
            color: @color-add-grey;
            border: 1px dashed @template-create-header-color;

            i {
              font-size: @font-size-h1;
              margin-left: 8px;
              margin-top: -1px;
              display: block;

              &::before {
                margin: 0;
              }
            }
          }
        }
      }

      &__hover_actions {
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0;
        overflow: hidden;
        height: 100%;
        align-items: center;
        padding-left: @offset-xs;

        .action,
        .actions-list-drop {
          transform: translateX(100px);
        }

        .action {
          font-size: 28px;
          color: @color-blue;
          cursor: pointer;
          margin-right: .2em;
          margin-left: .2em;
          .no-focus();

          i {
            margin: 0;
          }

          &.disabled {
            color: @color-grey;
            cursor: not-allowed;
          }
        }
      }

      &__info {
        display: inline-block;
        max-width: calc(100% - (@checkbox-size + 2 * @offset-md + 4px));
        padding-right: @offset-xs;

        h4 {
          color: @site-menu-color-hov;
          font-size: @font-text-size;
          margin: 0;
          word-wrap: break-word;
          max-height: 2*@font-size-lg;
          overflow: hidden;
        }

        & > span {
          color: @main-task-link-color;
          text-decoration: none;
          font-size: @font-sm-size;
          position: relative;
          font-family: @font-family-semibold;
          display: inline-block;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a {
          color: @main-task-link-color;
          text-decoration: none;
          font-size: @font-sm-size;
          position: relative;
          font-family: @font-family-semibold;
          display: inline-flex;
          white-space: nowrap;
          max-width: 100%;

          & > span {
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
          }

          &.delegated {
            cursor: text;

            &:hover {
              color: @main-task-link-color;
            }
          }

          i {
            font-size: @font-xs-size;
          }

          &:hover {
            color: @border-blue-color;

            .link-hint-template {
              visibility: visible;
            }
          }

          .link-hint-template {
            top: 25px;
            min-width: @main-task-link-template-size;
            left: -@offset-lg;
            display: inline-flex;
            visibility: hidden;

            .square {
              left: 15px;
            }

            .top-line {
              height: 9px;
              .top-line();
            }

            &__content {
              .inline-flex;
              padding-right: 12px;
              margin-top: @offset-xs;
              white-space: nowrap;

              ul {
                flex: 0 1 (3 * (@main-task-link-template-size / 4) + 5px);
                min-width: (3 * (@main-task-link-template-size / 4) + 5px);
                float: left;

                li {
                  display: inline-block;
                  list-style: none;
                  float: left;
                  height: 3px;
                  margin-right: 1px;

                  &.not-assigned:not(.in-process):not(.completed):not(.inactive):not(.approved):not(.rejected):not(.stopped-on-reject) {
                    background-clip: padding-box;
                    background-color: @color-grey;
                    background-image: linear-gradient(-45deg, rgba(255, 255, 255, .3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, .5) 75%, transparent 75%, transparent);
                    background-size: 10px 10px;
                  }

                  &:not(.inactive).in-process {
                    background-color: @colot-assigned-grey;
                  }

                  &:not(.inactive).completed, &.approved {
                    background: @color-green;
                  }

                  &:not(.inactive).issued {
                    background: @color-yellow;
                  }

                  &:not(.inactive).upcoming, &.rejected {
                    background-color: @color-red;
                  }

                  &.not-assigned.upcoming:not(.stopped-on-reject):not(.inactive) {
                    background-color: @color-red !important;
                  }

                  &:not(.inactive):not(.stopped-on-reject).upcoming.approved {
                    background-color: @color-green !important;
                  }

                  &.inactive, &.stopped-on-reject, &.upcoming.stopped-on-reject {
                    background-color: @color-white;
                    border: 1px solid @to-do-color;
                  }

                  &.upcoming.inactive {
                    background-color: @color-white;
                    border: 1px solid @color-red;
                  }
                }
              }

              span {
                margin-left: 5px;
                white-space: nowrap;
              }
            }
          }
        }
      }

      &__hint {
        display: flex;
        justify-content: flex-end;
        font-family: @font-family-regular;
        align-items: center;

        span {
          display: inline-table;
          margin-left: @offset-md;
          font-size: @font-size-sm;
          color: @site-menu-help-color;
          white-space: nowrap;
          min-width: 30px;
          text-align: right;

          & > i {
            font-size: @font-size-md;
          }

          &.warn {
            color: @color-red;
          }

          &:first-child {
            margin-left: 0;
          }

          i {
            margin: 0 4px 0 0;
          }
        }

        .new {
          color: @white-color;
          font-size: @font-sm-size;
          display: inline-block;
          float: left;
          background: @border-blue-color;
          padding: 5px 10px;
          border-radius: 12px;
        }

        .comments-updated {
          color: @border-blue-color;
        }
      }
    }
  }
}
