@import "variables";
@import "mixins";

.btn {
  height: 4*@offset-sm;
  line-height: 4*@offset-sm;
  font-family: @font-family-regular;
  font-size: @font-size-md;
  padding: 0 @offset-md;
  border-radius: @buttons-border-radius;
  border: none;
  color: @color-white;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  transition: all .2s ease;
  white-space: nowrap;
  .no-focus();

  &[disabled='disabled'], &.disabled {
    background-color: @color-light-grey !important;
    color: @color-secondary-copy !important;

    &:hover {
      background-color: @color-light-grey !important;
      color: @color-secondary-copy !important;
    }
    cursor: not-allowed;
  }
  &.btn-loading{
    .btn-loading-mixin;
  }

  &.btn-green {
    background-color: @color-green;

    &:hover {
      background-color: @color-light-green;
    }
  }

  &.btn-default {
    color: @color-secondary-copy;
    border: 1px @color-secondary-btn solid;
    line-height: 30px;

    &:hover {
      color: @color-blue;
      border-color: @color-blue;
    }
  }

  &.btn-blue {
    background-color: @color-dark-blue;

    &:hover {
      background-color: @color-blue;
    }
  }

  &.btn-red {
    background-color: @color-red;
  }

  &.btn-link {
    background-color: transparent;
    color: @color-blue;

    &:hover {
      color: @color-dark-blue;
    }

    &:focus {
      outline: 2px solid Highlight;
    }
  }

  &.btn-inline {
    height: unset;
    line-height: unset;
  }

  &.btn-icon {
    color: @color-asphalt-grey;
    padding: 0;

    &:not(.action) {
      height: 2*@offset-sm;
      line-height: 2*@offset-sm;
    }

    &.dropdown-toggle {
      height: auto;
      line-height: unset;
    }

    &.muted {
      color: @color-add-grey;
    }

    &:hover,
    &:active {
      color: @color-blue;
    }

    i + span {
      margin-left: .2em;
      vertical-align: middle;
    }
  }
}

.btn-switcher:not(.btn) {
  font-family: @font-family-regular;
  font-size: @font-size-md;
  color: @color-secondary-copy;
  text-decoration: none;
  line-height: 20px;
  position: relative;
  border: 1px @color-secondary-btn solid;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border-radius: @buttons-border-radius;
  transition: all .2s ease;
  white-space: nowrap;
  .no-focus();

  & > i:first-child {
    margin-left: 0;
  }

  // hacks for icons
  & > .icon-process_tasks {
    font-size: 16px;
    line-height: 14px;
  }

  &[disabled='disabled'] {
    opacity: 0.4;
    cursor: not-allowed;

    &:hover {
      color: inherit;
      opacity: 0.4;
    }
  }

  &.with-focus:not(:disabled):not(:active):focus {
    .no-focus();
    color: @color-blue;
    border-color: @color-blue;

    & > i {
      color: @color-blue;
    }
  }

  &:not(:disabled):not(:active):hover {
    color: @color-blue;
    border-color: @color-blue;

    & > i {
      color: @color-blue;
    }
  }

  &:active {
    .no-focus();
    color: @color-white;
    border-color: @color-blue;
    background-color: @color-blue;

    i {
      color: inherit;
    }
  }

  &.active {
    border-color: @color-blue;
    color: @color-blue;

    &:active {
      background-color: @color-white;
      color: @color-blue;
    }
  }

  &.completed {
    &:hover {
      color: @color-green;
      border-color: @color-green;
    }

    &:active {
      color: @color-white;
      border-color: @color-green;
      background-color: @color-green;
    }

    &.active {
      border-color: @color-green;
      color: @color-green;

      &:active {
        background-color: @color-white;
        color: @color-green;
      }
    }
  }

  &.white {
    background: transparent;
    color: @color-white;
    border-color: @color-white;

    &:hover {
      background-color: @color-white;
      color: @color-blue;
    }
  }

  &.red {
    &.static {
      border-color: @color-red;
      color: @color-red;

      i {
        color: @color-red;
      }
    }
    &:not(:disabled):not(:active):hover {
      border-color: @color-red;
      color: @color-red;

      i {
        color: @color-red;
      }
    }
    &.with-focus:not(:disabled):not(:active):focus {
      .no-focus();
      border-color: @color-red;
      color: @color-red;

      i {
        color: @color-red;
      }
    }
    &:not(.with-angle):active {
      background-color: @color-red;
      color: @color-white;
      border: 1px solid @color-red;

      i {
        color: @color-white;
      }
    }
  }

  &.btn-primary-switcher {
    padding: 5px @offset-md;

    &.red.with-angle {
      border-color: @color-red;
      color: @color-red;

      .bottom-triangle {
        border-color: @color-red !important;
      }

      &:active {
        background-color: @color-white;
        color: @color-red;
      }
    }

    &.active {
      .bottom-triangle {
        display: block;
        height: @font-size-icon;
        width: @font-size-icon;
        background: @white-color;
        border: 1px @color-blue solid;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
        right: 50%;
        bottom: -5px;
        margin-right: (@font-size-icon / 2) * -1;
        position: absolute;
      }
    }
    &.btn-loading{
      .btn-loading-mixin;
    }

    .bottom-triangle {
      display: none;
    }
  }

  &.btn-secondary-switcher {
    padding: 5px @offset-lg;

    i {
      margin-left: -3px;
    }
  }
}
.btn-loading-mixin(){
  &[disabled='disabled'] {
    opacity: 1;
    background-color: @color-light-grey;
    color: @color-secondary-copy;
    padding: 5px @offset-md 5px @offset-sm;
    .pending {
      display: block;;
      font-size: @font-size-md;
      height: @font-size-md;
      line-height: @font-size-md;
      margin: 3px 0;
      i {
        margin-right: 4px;
        font-size: @font-size-md;
        height: @font-size-md;
        animation: rotate .3s linear infinite;
      }
    }
    .ready{
      display: none;
    }
  }
  .pending{
    display: none;
  }
  .ready{
    display: block;
  }

}
