@import "../../../less/variables";
@import "../../../less/mixins";

.add-holiday-form {
  display: flex;
  align-items: center;
  margin-top: @offset-lg;
  position: relative;

  &.noDate, &.serverError {
    .prc-date-picker {
      .handler {
        border-color: @color-red;
      }
    }
  }

  .prc-date-picker {
    background: #fff;
    margin-right: 0;

    .handler {
      padding: 6px 7px;
      flex: 1 0 auto;
      .input-border;
      border-width: 1px;
      cursor: pointer;
      height: 2*@offset-lg;

      &.drop-enabled {
        border-color: @color-blue;
        border-width: 2px;
      }
    }
  }

  & > input {
    width: 180px;
    margin-left: @offset-sm;

    &.error {
      border-color: @color-red;
    }
  }

  @adHoc-btn-size: 36px;
  & > button {
    margin-left: @offset-md;
    min-width: @adHoc-btn-size;
    width: @adHoc-btn-size;
    flex: 0 1 @adHoc-btn-size;
    display: block;

    &:not(:hover):not(:focus) {
      i {
        color: @color-grey;
      }
    }

    i {
      margin: 0;
      font-size: @font-size-xs;

      &.icon-menu_checkmark {
        margin-left: -1px;
      }
    }

    & + button {
      margin-left: @offset-xs;
    }
  }

  & > span.error {
    color: @color-red;
    position: absolute;
    top: 100%;
    left: 2px;
    margin-top: 3px;
  }
}
