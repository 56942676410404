@import "../../../less/variables";
@import "../../../less/mixins";

.form-group.form-group-v2 {
  position: relative;

  &.has-error {
    input, textarea {
      border-color: @color-red;
      box-shadow: none;

      &:focus, &.active {
        border-color: @color-red;
        box-shadow: 0 0 0 1px @color-red;
      }
    }

    .icon-template_create_field_required {
      color: @color-red;
    }
  }

  &.has-hint {
    margin-bottom: 48px;
  }

  @label-width: 150px;
  @status-width: 90px;

  .label,
  label {
    font-family: @font-family-medium;
    width: @label-width;
    flex: 0 0 @label-width;
    display: inline-block !important;
  }

  .field-value {
    max-width: calc(100% - @label-width);
    position: relative;
  }

  .status {
    height: 2*@offset-lg;
    margin-left: @offset-sm;
    display: inline-flex;
    align-items: center;
    color: @color-secondary-copy;
    white-space: nowrap;
    max-width: @status-width;

    i {
      margin-right: 2px;
      display: inline-block;
    }

    span {
      opacity: 1;

      & + span {
        display: none;
      }

      &.saving {
        i {
          animation: rotate .3s linear infinite;
        }
      }

      &.saved {
        color: @color-green;
        transition: all .2s linear .25s;

        &.ng-show, &.ng-hide.ng-hide-active {
          opacity: 1;
        }

        &.ng-hide, &.ng-show.ng-show-active {
          opacity: 0;
        }

        &.silent {
          transition: none;
        }
      }
    }
  }

  .hint,
  .error {
    margin-left: 2px;
    white-space: nowrap;
    font-size: @font-size-sm;
    margin-top: @offset-xs;
  }

  .hint {
    color: @color-add-grey;
  }

  .error {
    color: @color-red;
  }
}
