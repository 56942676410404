.dropdown {
  .dropdown-menu {
    display: none;
  }

  &.dropdown-opened {
    .dropdown-menu {
      display: block;
    }
  }
}

.dropdown-menu-wrapper {
  display: none;
  min-width: 90px;
  background: @color-white;
  border: 1px @input-border-color solid;
  box-shadow: 0 1px 6px rgba(0,0,0,0.15);

  ul {
    li {
      font-size: 14px;
      color: #222;
      padding: 8px;
    }
  }
}

.drop.drop-open .dropdown-menu-wrapper {
  display: block;
}

.btn-dropdown {
  & > span {
    display: flex;
    align-items: center;

    .caret {
      font-size: 4px !important;
      margin-left: 5px !important;
    }
  }
}
