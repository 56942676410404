@import "../../../less/variables";
@import "../../../less/mixins";

.tasks__main__header {
  width: 100%;
  height: 4*@offset-lg;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: @font-family-medium;
  position: relative;

  &.small {
    height: 7*@offset-lg;
  }
  &.privates {
    .selectize-control {
      display: none;
    }
    .actors-error-container .input-hint--error {
      display: none;
    }
  }

  @select-ad-hoc-size: 210px;

  & > div {
    display: inline-flex;
    align-items: center;

    h3 {
      font-weight: normal;
      color: @site-menu-color-hov;
      font-size: @font-text-size;
    }

    button {
      margin-left: 2*@offset-md;

      i {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }

  & > form {
    &.ad-hoc-task {
      position: absolute;
      width: 100%;
      top: @offset-lg;
      height: 2*@offset-lg;
      align-items: flex-start;
      z-index: 1;
      left: 0;
      background: @color-white;
      margin: 0;
      visibility: visible;

      &.ng-hide-add, &.ng-hide-remove {
        transition: all linear 0.1s;
      }
      &.ng-hide {
        opacity: 0;
        visibility: hidden;
        transition: all linear 0.01s;
        transform: scale(.7, 1);
      }

      &.small {
        display: none;
        height: 5*@offset-lg;

        & > div {
          &.first-row, &.second-row {
            @adHoc-btn-size: 36px;
            & > button {
              margin-left: @offset-md;
              min-width: @adHoc-btn-size;
              width: @adHoc-btn-size;
              flex: 0 1 @adHoc-btn-size;
              display: block;

              &:not(:hover):not(:focus) {
                i {
                  color: @color-grey;
                }
              }

              i {
                margin: 0;
                font-size: @font-size-xs;

                &.icon-menu_checkmark {
                  margin-left: -1px;
                }
              }

              & + button {
                margin-left: @offset-xs;
              }
            }
          }

          &.first-row {
            display: flex;
            align-items: center;
            height: 2*@offset-lg;
            width: 100%;

            .input-container {
              width: calc(100% - (3 * @offset-md));
              flex: 0 1 calc(100% - (3 * @offset-md));
            }
          }
          &.second-row {
            display: flex;
            align-items: flex-start;
            height: 2*@offset-lg;
            margin-top: @offset-lg;

            .prc-date-picker {
              margin-left: 0;
              display: inline-table;
              flex: 0 0;
            }
            .selectize-control {
              margin-left: @offset-sm;
            }
            & > button {
              display: none;
            }
          }
        }
      }

      .input-hint--error-mixin() {
        color: @color-red;
        font-family: @font-family-medium;
        white-space: nowrap;
        position: absolute;
        left: 0;
        top: 2*@offset-lg + 1;
      }

      .input-container {
        width: calc(100% - (@select-ad-hoc-size + @offset-md * 8));
        flex: 0 1 calc(100% - (@select-ad-hoc-size + @offset-md * 8));
        position: relative;

        &.no-select {
          width: calc(100% - (@offset-md * 8));
          flex: 0 1 calc(100% - (@offset-md * 8));
        }

        & > input {
          max-width: 100%;

          &.red {
            border-color: @color-red !important;
          }
        }

        & > .input-hint--error {
          .input-hint--error-mixin();
        }
      }
      .actors-error-container {
        position: relative;
        & > .input-hint--error{
          .input-hint--error-mixin();
          margin-left: @offset-sm;
        }
        @media only screen and (max-width: @phone-landscape-size) {
          & > .input-hint--error{
            margin-left: 0;
          }
        }
      }

      .prc-date-picker {
        background: #fff;
        margin: 0 0 0 @offset-sm;

        .handler {
          padding: 6px 7px;
          flex: 1 0 auto;
          .input-border;
          border-width: 1px;
          cursor: pointer;
          height: 2*@offset-lg;
        }
      }

      .selectize-control {
        width: @select-ad-hoc-size;
        min-width: @select-ad-hoc-size;
        flex: 0 1 @select-ad-hoc-size;
        margin-left: @offset-sm;

        .selectize-dropdown .dropdown-list {
          width: @select-ad-hoc-size + 88px;
        }
      }

      @adHoc-btn-size: 36px;
      & > button {
        margin-left: @offset-md;
        min-width: @adHoc-btn-size;
        width: @adHoc-btn-size;
        flex: 0 1 @adHoc-btn-size;
        display: block;

        &:not(:hover):not(:focus) {
          i {
            color: @color-grey;
          }
        }

        i {
          margin: 0;
          font-size: @font-size-xs;

          &.icon-menu_checkmark {
            margin-left: -1px;
          }
        }

        & + button {
          margin-left: @offset-xs;
        }
      }
    }
  }

  &__menu {
    float: right;
    .inline-flex;

    & > li {
      .inline-flex;
      float: left;
      color: @site-menu-color-hov;
      font-family: @font-family-regular;
      font-size: @font-text-size;
      font-weight: normal;
      position: relative;
      outline: none;
      cursor: pointer;
      margin-left: @offset-md;

      .icon-common_arrow_down {
        font-size: 4px;
        margin-left: 6px;
      }

      &:hover,
      &:hover > i {
        color: @color-blue;
      }

      &.active,
      &.active:hover,
      &.active:hover > i {
        color: @site-menu-color-hov;
      }

      &:first-child {
        margin-left: 0;
        span {
          display: inline-flex;
          align-items: center;
        }
      }

      &:last-child {
        border: 1px @color-secondary-btn solid;
        color: @color-secondary-copy;
        padding: 6px 3*@offset-md 6px @offset-md;
        height: 2*@offset-lg;
        border-radius: @buttons-border-radius;
        cursor: pointer;
        position: relative;
        margin-right: -48px;
        opacity: 1;

        &.ng-hide-remove {
          transition: all .1s ease .2s;
        }
        &.ng-hide-add {
          display: none;
        }
        &.ng-hide {
          opacity: 0;
        }

        &:hover,
        &.active {
          color: @border-blue-color;
          border-color: @border-blue-color;
        }

        &.active {
          .right-triangle {
            display: block;
            height: 8px;
            width: 8px;
            background: @white-color;
            border: 1px @border-blue-color solid;
            border-bottom: none;
            border-left: none;
            transform: rotate(45deg);
            right: -4px;
            top: 50%;
            margin-top: -4px;
            position: absolute;
          }
        }
      }
    }
  }

  @media only screen and (max-width: @phone-landscape-size) {
    &:not(.privates) {
      .ad-hoc-task {
        display: none;

        &.small {
          display: block !important;
        }
      }
    }
    &.privates.small {
      height: 4*@offset-lg;
    }
  }
  @media only screen and (max-width: @phone-portrait-size) {
    &.privates {
      &.small {
        height: 7*@offset-lg !important;
      }
      .ad-hoc-task {
        display: none;

        &.small {
          display: block !important;

          & > .first-row {
            .input-container {
              width: 100% !important;
              flex: 0 1 100% !important;
            }
            & > button {
              display: none;
            }
          }
          & > .second-row {
            & > button {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
