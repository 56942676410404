@import "../../../less/variables";
@import "../../../less/mixins";

.select-processes-group {
  .handler {
    font-family: @font-family-medium;
    color: @site-menu-color;
    text-decoration: none;
    position: relative;
    border: 1px @color-secondary-btn solid;
    cursor: pointer;
    background-color: transparent;
    border-radius: @buttons-border-radius;
    transition: all .2s ease;
    padding: 5px 8px;
    height: 32px;
    display: flex;
    align-items: center;

    &:hover {
      color: @color-blue;
      border-color: @color-blue;
    }

    i {
      font-size: 17px;

      &.icon-common_arrow_down {
        font-size: 4px !important;
        margin-left: 5px !important;
      }
    }
  }
}

.processes-group-element {
  z-index: 1100;

  &.processes-group-open {
    .dropdown-menu--processes-group {
      display: block;
    }
  }

  .dropdown-menu--processes-group {
    display: none;
    min-width: 90px;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0,0,0,0.15);

    li {
      &.separated {
        border-top: 1px solid #e3e3e3;
      }

      a {
        font-size: @font-size-md;
        color: @color-main-copy;
        padding: @offset-sm;
        display: flex;
        align-items: center;
        white-space: nowrap;
        min-width: 105px;

        i {
          color: @color-secondary-copy;
          font-size: 17px;
          margin-right: @offset-md;
        }

        &:hover {
          color: @color-blue;
        }
        &.active {
          i {
            color: @color-green;
          }
        }
      }
    }
  }
}
