@import "../../less/variables";
@import "../../less/mixins";

.site-error {
  margin: @offset-md 2*@offset-md;
  text-align: center;
  width: calc(100% - (4 * @offset-md));
  padding: 5*@offset-md;
  border: 1px solid @color-pale-blue;
  max-height: calc(100% - (2 * @offset-md + @auth-header-height));
  overflow: auto;

  & > img {
    margin-bottom: 2*@offset-md;
  }

  a {
    color: @color-blue;
  }

  .blocked-message {
    font-size: @font-size-lg;
    line-height: 1.2;
    max-width: 330px;
    margin: 0 auto;
  }

  .auth-footer {
    a + p {
      margin-top: 2*@offset-md;
    }
  }
}
