@import "../../../less/variables";
@import "../../../less/mixins";

.admin__main__header {
  .inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 @site-padding;
  height: 4*@offset-lg;

  &__nav {
    display: inline-flex;

    li {
      .native-size;

      & + li {
        margin-left: @offset-sm;
      }
      &.menu-item__more {
        display: none;
      }
    }

    @media only screen and (max-width: 1150px) {
      li.menu-item {
        &__business-calendar, &__billing, &__integrations {
          display: none;
        }
        &__more {
          display: list-item;
        }
      }
    }
    li div a.menu-button-more {
      @media only screen and (max-width: 750px) {
        i {
          margin-right: 0;
        }

        span {
          display: none;
        }
      }
    }
  }

  .search-block {
    position: relative;
    margin-right: 20px;

    & > i {
      position: absolute;
      color: @color-secondary-copy;
      cursor: pointer;
      .no-focus();

      &.icon-header_search {
        left: 6px;
        margin-top: -1px;
        font-size: @font-size-lg;
        top: 50%;
        transform: translateY(-50%);
        transition: all .15s ease;
      }

      &.icon-common_remove {
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
      }
    }

    & > input {
      position: relative;
      z-index: 1;
      transition: all .15s ease;
      opacity: 1;
      padding: 8px 30px;
      appearance: none;
      flex: 0 1 100%;
      width: 100%;
      border: 1px @color-secondary-btn solid;
      border-radius: 4px;
      box-shadow: none !important;
      color: @color-main-copy;
      resize: none;
      outline: 0;
      cursor: pointer;
      background: transparent;

      &::-ms-clear {
        display: none;
      }

      &:focus, &.active {
        outline: none;
        border-color: @color-blue;
        border-width: 2px;
        cursor: text;

        & + .icon-header_search {
          color: @color-main-copy;
        }
      }
    }
  }

  &__filters-container  {
    .inline-flex;
    margin-left: 8px;
  }

  &__filters {
    .inline-flex;

    // Filter active/disabled/invited
    .mode {
      .inline-flex;
      float: left;
      color: @site-menu-color-hov;
      font-size: @font-text-size;
      font-family: @font-family-medium;
      font-weight: normal;
      position: relative;
      outline: none;
      cursor: pointer;
      margin-right: 2*@offset-md;

      & > span {
        display: inline-flex;
        align-items: center;
        font-family: @font-family-regular;
        .no-focus();

        &:hover,
        &:hover > i {
          color: @color-blue;
        }
      }

      .icon-common_arrow_down {
        font-size: 4px;
        margin-left: 6px;
      }

      .processes-dropdown {
        width: 166px;
        padding: 2px 2px;
        position: absolute;;
        top: 30px;
        left: -4px;
        background: @white-color;
        box-shadow: 0 0 2px @gray-light-color;

        li {
          font-size: @font-text-size;
          color: @site-menu-color-hov;
          padding: 8px 10px 8px 25px;
          display: flex;
          align-items: center;
          cursor: pointer;

          i {
            display: none;
            font-size: 8px;
            color: @green-color;
            margin-right: 6px;
          }

          &.active {
            padding-left: 8px;
            i {
              display: block;
            }
          }
        }
      }
    }

    // Another buttons on top side
    li {
      &.add-group {
        margin-right: @offset-sm;
      }

      & > a {
        .small {
          display: none;
        }
        & > i {
          color: rgba(0, 0, 0, 0.5);
          margin-right: 6px;
        }
      }
    }

    .btn-switcher + .btn-switcher {
      margin-left: @offset-xs;
    }
  }
}

@media only screen and (max-width: 700px) {
  .admin__main__header {
    .search-block {
      margin-right: 8px;
      height: 32px;
      min-width: 36px;

      & > i.icon-common_remove {
        display: none;
      }

      & > i.icon-header_search {
        left: 9px;
      }

      input {
        width: 30px;
        border-radius: 16px;
        padding-left: 2px;
        position: absolute;
        right: 0;
      }
    }

    &.searching {
      .search-block {
        width: 100%;

        input {
          width: 100%;
          border-radius: 4px;
          padding: 8px 30px;
        }

        & > i.icon-header_search {
          left: 6px;
        }

        & > i.icon-common_remove {
          display: initial;
        }
      }

      .admin__main__header__filters-container {
        width: 100%;
      }
    }

    &__filters {
      .mode {
        margin-right: 8px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .admin__main__header {
    &__nav {
      & > li > a {
        & > i:first-child {
          margin-right: 1px;
        }

        & > span {
          display: none;
        }
      }
    }

    &.searching {
      .admin__main__header__nav {
        display: none;
      }
    }

    &__filters {
      & > li {
        &:last-child {
          a {
            span {
              display: none;

              &.invite {
                display: none;
              }
            }

            i {
              margin-right: .2em;
            }
          }
        }

        .btn-switcher {
          & > span {
            display: none;
          }

          & > i {
            margin-right: 0;
          }
        }
      }
    }
  }
}
