@import "../../../less/variables";
@import "../../../less/mixins";

.select-field-edition {
  margin-left: @offset-sm;
  position: relative;
  padding-right: 20px;

  .handler {
    font-family: @font-family-medium;
    color: @site-menu-color;
    text-decoration: none;
    position: relative;
    border: 1px @color-secondary-btn solid;
    cursor: pointer;
    background-color: transparent;
    border-radius: @buttons-border-radius;
    transition: all .2s ease;
    padding: 5px 8px;
    height: 32px;
    display: flex;
    align-items: center;

    &:hover {
      color: @color-blue;
      border-color: @color-blue;
    }

    i {
      font-size: 16px;

      &.icon-common_arrow_down {
        font-size: 4px !important;
      }
    }
  }

  .icon-lock {
    @icon-lock-size: 10px;
    position: absolute;
    top: 50%;
    margin-top: (@icon-lock-size / 2) * -1;
    right: 0;
    font-size: @icon-lock-size;
    color: @site-menu-color;
    cursor: help;
  }
}
