@import "../../../less/variables";
@import "../../../less/mixins";

@import (inline) "fixed-data-table-2/dist/fixed-data-table.css";

@data-grid-border-color: #e8e8e8;

.data-grid {
  div.public_fixedDataTable_header,
  div.public_fixedDataTableCell_main,
  div.public_Scrollbar_main,
  div.public_fixedDataTable_hasBottomBorder {
    border-color: @data-grid-border-color;
    background-color: @color-white;
    background-image: none;
  }

  &.public_fixedDataTable_main,
  div.public_fixedDataTableRow_main.public_fixedDataTable_hasBottomBorder {
    border: none;
  }

  div.public_fixedDataTable_horizontalScrollbar {
    border-top: none;
    margin-left: -1px;
  }

  div.public_fixedDataTableCell_columnResizerKnob {
    background-color: @color-blue;
  }

  div.public_Scrollbar_main {
    background: #fff;
  }

  div.public_fixedDataTable_scrollbarSpacer {
    background-color: transparent;
    background-image: none;
  }

  div.public_fixedDataTable_header {
    background-color: transparent;
    background-image: none;
    border: none;

    div.public_fixedDataTableCell_main {
      border-top: none;
    }
  }

  div.public_fixedDataTableCell_main {
    border-left: 1px solid @data-grid-border-color;

    .fixedDataTableCellLayout_columnReorderContainer {
      background: transparent;
      border-top: 1px solid @data-grid-border-color;
      border-bottom: 1px solid @data-grid-border-color;

      &:after {
        left: 8px;
        font-size: 11px;
        font-family: 'metatask-icons' !important;
        content: "\e82d" !important;
        color: @color-add-grey;
      }

      &:hover:after {
        color: @color-blue;
      }
    }

    @active-cell-border-color: @data-grid-border-color;
    &.public_fixedDataTableCell_reordering {
      border-color: @active-cell-border-color;
      .box-shadow();

      .column-header-cell {
        border-color: @active-cell-border-color;
      }

      .fixedDataTableCellLayout_columnReorderContainer {
        border: 1px solid @active-cell-border-color;
        border-right: 0;
      }
    }
  }

  div.public_fixedDataTableCell_main + div.public_fixedDataTableCell_main {
    border-left: none;
  }

  div.public_fixedDataTableRow_main {
    background: #fcfcfc !important;
  }

  div.public_fixedDataTableCell_cellContent {
    padding: 0;
  }

  .column-header-cell {
    font-size: 12px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    border-top: 1px solid @data-grid-border-color;
    border-bottom: 1px solid @data-grid-border-color;

    .column-title-icon,
    .label {
      font-family: @font-family-medium !important;
    }

    .column-title-icon {
      i {
        color: @color-add-grey;
      }

      i.icon-title_badge {
        color: #a3a3a3;
        font-size: 18px;
        margin-left: -3px;
        transform: translateX(3px);
      }
    }

    .label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }

    div + div {
      padding-left: 6px;
    }

    .actions-list-drop {
      display: inline-block;
      padding-right: 7px;

      .btn {
        height: unset;
        line-height: unset;
      }
    }
  }

  .public_fixedDataTableCell_hasReorderHandle {
    .public_fixedDataTableCell_cellContent {
      margin-left: 0;
    }

    .column-header-cell {
      padding-left: 22px;
    }
  }

  .has-invalid-values .column-value-cell {
    background-color: #fef2f2;
  }

  .column-value-cell {
    font-size: 12px;
    padding: 0;
    border-bottom: 1px solid @data-grid-border-color;
    cursor: pointer;

    &-content {
      padding: 0 6px;
      display: block;

      & > .static {
        display: block;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.required {
          color: @color-red;
        }

        &.muted {
          color: @color-add-grey;
        }

        .highlighted {
          color: sandybrown;
        }
      }

      & > .user-label {
        margin-top: 6px;
      }
    }

    &.active {
      background: #f2fafe;
    }

    &.invalid {
      span {
        color: @color-red
      }
    }

    // span + span {
    //     padding-left: 6px;
    // }
  }
}
