@import "../../../less/variables";
@import "../../../less/mixins";

.auth-form-container {
  & > .signin-google {
    text-align: center;
    position: relative;
    padding-top: 2*@offset-md;

    &.signup {
      padding-top: 2*@offset-lg;
    }

    .pending {
      cursor: progress;
    }

    & > span {
      text-transform: uppercase;
      position: absolute;
      top: -10px;
      display: block;
      border: 1px solid rgb(232, 232, 232);
      background: rgb(245, 245, 245);
      height: 22px;
      width: 30px;
      line-height: 21px;
      left: 50%;
      transform: translateX(-50%);
      vertical-align: middle;
      text-align: center;
      color: rgb(149, 149, 149);
      font-size: 11px;
      border-radius: 11px;
    }

    & > .btn {
      //width: 240px;
      height: 5*@offset-sm;
      line-height: 5*@offset-sm;
      padding: 0 3*@offset-sm;
      border-radius: 24px;
    }

    & > .btn-blue {
      border-radius: 0;
      padding: 0 @offset-md 0 0;
      display: inline-flex;

      &:before {
        content: '';
        width: 40px;
        height: 40px;
        display: inline-block;
        background: url(../../../../public/assets/icons/btn_google_dark_normal.svg) center no-repeat;
        margin-right: @offset-md;
      }

      &:active {
        &:before {
          background: url(../../../../public/assets/icons/btn_google_dark_pressed.svg) center no-repeat;
        }
      }
    }
  }

  & > .signin-microsoft {
    padding-top: 2*@offset-md;

    &.signup {
      padding-top: 2*@offset-lg;
    }

    .pending {
      cursor: progress;
    }

    & > .btn-microsoft {
      //width: 240px;
      border-radius: 0;
      padding: 0 @offset-md 0 0;
      display: flex;
      margin: 0 auto;
      border: 1px solid #8c8c8c;
      color: #5e5e5e;
      height: 41px;
      align-items: center;
      justify-content: start;

      &:before {
        content: '';
        width: 21px;
        height: 21px;
        display: block;
        background: url(../../../../public/assets/icons/ms-icon.svg) center no-repeat;
        margin-right: @offset-md;
        margin-left: @offset-md;
      }
    }
  }

  .signin-sso {
    text-align: center;
    padding-top: 2*@offset-md;

    .btn-link {
      display: inline-block;
      font-size: 12px;
    }
  }

  .auth-form:not(.ng-hide) + .signin-google,
  .auth-form:not(.ng-hide) + .signin-microsoft,
  .auth-form:not(.ng-hide) + .signin-sso {
    border-top: 1px solid @color-pale-blue;
  }
}


.auth-form {
  .input-hint--error {
    color: @color-red;
    display: inline-block;
    margin: @offset-xs 0 0 @auth-form-label-width;
  }

  .input-hint--info {
    color: @color-blue;
    font-size: @font-size-sm;
    display: inline-block;
    margin: @offset-xs 0 0 @auth-form-label-width;
  }

  & > footer {
    margin: 2*@offset-md 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input.btn {
      margin-left: @auth-form-label-width;
    }

    button.btn {
      margin-left: @auth-form-label-width + 20px;
    }

    a {
      color: @color-secondary-copy;
      font-size: @font-size-md;
    }
  }

  footer .btn-green {
    &:not(.btn-loading) .pending {
      display: none;
    }

    &.btn-loading {
      background-color: @color-green !important;
      color: @color-white !important;

      &:hover {
        background-color: @color-light-green !important;
      }
    }
  }

  .boundary {
    width: calc(100% - 120px);
    margin-left: 120px;
    padding-bottom: 2*@offset-md;
    border-bottom: 1px solid @color-pale-blue;

  }

  .fieldset {
    border: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-top: 2*@offset-md;

    &:first-child {
      margin-top: 0;
    }

    & > label,
    & > .label {
      width: @auth-form-label-width;
      min-width: @auth-form-label-width;
      font-size: @auth-form-label-size;
      font-family: @font-family-regular;
      display: inline-block;
      flex: 0 1 @auth-form-label-width;

      .icon-template_create_field_required {
        font-size: @font-size-xs;
        margin-left: @offset-xs;
        transform: translateY(-1px);
      }
    }

    & > input,
    & > textarea {
      appearance: none;
      width: 100%;
      max-width: @auth-form-text-width;
      height: @auth-form-text-height;
      flex: 0 1 100%;
      padding: 0 7px;
      font-size: @font-size-sm;
      .input-border;

      &::-ms-clear {
        display: none;
      }

      &:focus {
        outline: none;
        border-color: @color-blue;
      }
    }

    & > textarea {
      padding: 7px;
      height: 2.5*@auth-form-text-height;
    }

    &.has-error {
      & > input {
        border-color: @color-red;
      }
    }
  }

  .btn {
    height: 5*@offset-sm;
    line-height: 5*@offset-sm;
    padding: 0 3*@offset-sm;
    border-radius: 24px;
  }

  & + .btn {
    height: 5*@offset-sm;
    line-height: 5*@offset-sm;
    padding: 0 3*@offset-sm;
    border-radius: 24px;
    display: table;
    margin: 2*@offset-md auto 0 auto;
  }

  @media only screen and (max-width: 370px) {
    & > .auth-login {
      input.btn {
        margin-left: 0;
      }
    }

    & > footer:not(.auth-login) {
      justify-content: center;

      input.btn {
        margin-left: 0;
      }
    }
  }
}

.form-hint {
  width: 100%;
  padding: 5px @offset-sm;
  text-align: center;
  display: block;
  background: rgb(184, 225, 125);
  border-radius: 4px;
  color: @color-main-copy;
  margin-bottom: @offset-md;

  &.error {
    background: @color-yellow;
  }
}

.lg-form {
  & > footer input {
    margin-left: @auth-form-label-width + 20px;
  }

  .input-hint {

    &--error {
      margin: @offset-xs 0 0 @auth-form-label-width + 20px;
    }

    &--info {
      margin: @offset-xs 0 0 @auth-form-label-width + 20px;
    }
  }

  .fieldset {
    label {
      width: @auth-form-label-width + 20px;
      min-width: @auth-form-label-width + 20px;
      flex: 0 1 (@auth-form-label-width + 20px);
    }

    input {
      max-width: @auth-form-text-width - 20px;
    }
  }
}
