.copy-template-modal {
  overflow: auto;

  .modal-content {
    .modal-header {
      h3 {
        line-height: @thumbnail-size;
        padding-right: 3*@offset-md;

        .name {
          font-family: @font-family-medium;
        }

        .avatar-small {
          display: inline-block;
          margin: 0 @offset-xs;
          color: @color-asphalt-grey;
          min-width: @thumbnail-size;

          i {
            font-size: @font-size-md;
            display: block;

            &.icon-Mens,
            &.icon-Magnifi-Glass2 {
              font-size: 12px;
            }
          }

          &.default {
            background-color: @color-grey;

            i {
              line-height: @thumbnail-size;
            }
          }
        }
      }
    }
    .modal-body {
      .form-hint {
        margin-top: @offset-md;
      }

      .form-group label {
        flex: 0 1 100px;
        min-width: 100px;
        width: 100px;
      }
    }
    .modal-footer {
      margin-top: 2*@offset-md;
    }
  }

  @media only screen and (max-width: 768px) {
    .modal-dialog {
      margin-top: 20px !important;
    }
  }
}