@import "variables";

.modal {
  overflow: auto;
  .no-focus();

  &.fade {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .9);

    .modal-dialog {
      transform: scale(0.1);
      opacity: 0;
      transition: all 0.3s;
    }

    &.in .modal-dialog {
      transform: scale(1);
      opacity: 1;
    }
  }

  &-dialog {
    margin: 0 auto;
    margin-top: 90px;
    width: 100%;
    max-width: @modal-max-width;
    padding: 2*@offset-lg;
    background-color: @color-white;
    box-shadow: 0 0 8px @color-grey;
    transition: all .4s ease;
    position: relative;
    .no-focus();

    h3 {
      font-size: @font-size-lg;
      color: @color-main-copy;
    }

    p {
      font-size: @font-size-sm;
      color: @template-view-description-color;
      margin-top: @offset-md;
      line-height: @font-size-xlg;
      white-space: pre-wrap;
    }
  }

  &-header {
    .icon-common_close {
      color: #cacaca;
      position: absolute;
      font-size: @font-size-close-btn;
      top: 2*@offset-md;
      right: 2*@offset-md;
      cursor: pointer;

      &:hover {
        color: @color-dark-grey;
      }
    }
  }

  &-footer {
    margin-top: @offset-md;
    display: flex;
    align-items: center;

    button + button {
      margin-left: @offset-sm;
    }
  }
}
