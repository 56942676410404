.template-task-data-model {
  width: 100%;

  .help-text {
    color: @color-secondary-copy;
    font-size: @font-size-sm;
    line-height: @font-size-sm * 1.5;
    margin: 5px 0;
    font-family: @font-family-regular;

    a {
      color: @color-blue;
    }
  }

  &__empty {
    padding: 2*@offset-md @offset-md;
    text-align: center;
    width: 100%;
    border: 1px solid @color-pale-blue;

    h4 {
      font-size: @font-size-md;
    }

    p {
      margin-top: @offset-sm;
      font-family: @font-family-semibold;
      color: @color-add-grey;
      font-size: @font-size-sm;

      a {
        color: @color-blue;
      }
    }
  }

  &__content {
    width: 100%;

    &__section {
      margin: 0;
      border: 1px solid @color-light-grey;

      & + .template-task-data-model__content__section {
        border-top: none;
      }

      header {
        width: 100%;
        height: 2*@offset-md + 2*@offset-sm;
        line-height: 2*@offset-md + 2*@offset-sm;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        background-color: @color-panel-grey;
        padding: 0 @offset-lg 0 @offset-md;
        align-items: center;
        color: @color-main-copy;
        font-size: @font-size-lg;
        position: relative;

        &:hover {
          .header-actions {
            display: flex;
          }
        }

        & > i {
          font-size: 16px;
          color: @color-grey;
          margin-right: @offset-sm;
        }

        .header-actions {
          position: absolute;
          right: @offset-md;
          display: none;
          align-items: center;
          top: @offset-xs;
          padding-left: @offset-md;
          background-color: @color-panel-grey;

          li {
            & > a {
              font-family: @font-family-medium;
              color: @site-menu-color;
              text-decoration: none;
              position: relative;
              border: 1px @site-menu-color solid;
              cursor: pointer;
              background-color: transparent;
              border-radius: @buttons-border-radius;
              transition: all .2s ease;
              padding: @offset-xs;
              height: 2*@offset-lg;
              display: flex;
              align-items: center;
              opacity: .5;

              &:hover {
                color: @color-blue;
                border-color: @color-blue;
              }

              i {
                font-size: 16px;

                &.icon-common_arrow_down {
                  font-size: 4px !important;
                }
              }
            }

            & + li {
              margin-left: @offset-sm;
            }
          }
        }
      }

      &__fields {
        &__field {
          display: flex;
          align-items: center;
          margin: @offset-md 0;
          padding: 0 @offset-lg;

          @input-length: 110px;
          @label-width: 170px;
          @sm-label-width: 120px;

          & + .form-group {
            margin-top: 0;
          }

          & > .title {
            min-width: @label-width !important;
            width: @label-width !important;
            flex: 0 1 @label-width !important;
            font-size: @font-size-sm;
            display: flex;
            align-items: center;
            padding-right: @offset-lg;
            word-wrap: break-word;

            @media (max-width: 1130px) {
              min-width: @sm-label-width;
              width: @sm-label-width;
              flex: 0 1 @sm-label-width;
            }

            i {
              display: none;
              font-size: 8px;
              margin-left: 2px;
            }
          }

          & > .icon-hinter {
            font-size: @font-size-md;
            color: @color-grey;
            margin-left: @offset-sm;
            cursor: default;

            i {
              cursor: default;
            }
          }

          & > .spacer {
            width: 100%;
          }

          & > .form-field-link {
            flex: 0 0 auto;
            color: @color-blue;
            opacity: 0;
            //transition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275) .15s;
            //transform: translateX(200px);

            &:hover {
              color: @color-dark-blue;
            }

            @media only screen and (max-width: 1125px) {
              & {
                flex: unset;
              }
            }
          }

          &:hover  > .form-field-link {
            opacity: 1;
            //transform: translateX(0px);
          }

          &.form-group {
            & > input,
            & > .dropdown > input {
              flex: 0 1 @input-length;
              width: @input-length;
              min-width: @input-length;
              max-width: @input-length;
              cursor: default;
              color: @color-secondary-copy;

              &:focus {
                outline: none;
                border: 1px solid @input-border-color;
              }
            }

            & > .dropdown {
              position: relative;

              & > i {
                position: absolute;
                right: 8px;
                top: 14px;
                font-size: 4px !important;
                color: @color-secondary-copy;
              }
            }

            & > textarea {
              flex: 0 1 @input-length;
              width: @input-length;
              max-width: @input-length;
              min-width: @input-length;
              cursor: default;
              height: 2*@offset-lg;
              min-height: 2*@offset-lg;
              max-height: 2*@offset-lg;
              margin-bottom: 0 !important;
              resize: both;
              color: @color-secondary-copy;

              &:focus {
                outline: none;
                border: 1px solid @input-border-color;
              }
            }

            & > .date {
              position: relative;
              flex: 0 1 @input-length;
              width: @input-length;
              max-width: @input-length;
              min-width: @input-length;

              & > i {
                position: absolute;
                left: @offset-xs;
                top: 7px;
                font-size: @font-size-lg;
                color: @color-grey;
              }

              & > input {
                padding-left: 30px;
                cursor: default;
                flex: 0 1 @input-length;
                width: @input-length;
                min-width: @input-length;
                max-width: @input-length;
                color: @color-secondary-copy;

                &:focus {
                  outline: none;
                  border: 1px solid @input-border-color;
                }
              }
            }

            & > .file-data-field {
              flex: 0 1 @input-length;
              width: @input-length;
              max-width: @input-length;
              min-width: @input-length;

              .files-wrapper {
                width: @input-length;
                max-width: @input-length;
                min-width: @input-length;
              }

              .files-block {
                border-color: @color-grey;
                color: @color-grey;

                &:hover {
                  border-color: @color-grey !important;
                  cursor: default !important;
                }

                i {
                  color: inherit !important;
                }
              }

              .files-block:hover {
                border-color: inherit;
                color: @color-grey;
                cursor: default;

                i {
                  color: inherit !important;
                }
              }
            }

            &.read_only {
              & > input,
              & > .file-data-field,
              & > textarea,
              & > .dropdown > input {
                color: @color-main-copy;
                background: @color-panel-grey;
                border: none;
                font-family: @font-family-semibold;
                border-radius: 4px;

                .files-block {
                  border-radius: 4px;
                  border: none;

                  .placeholder {
                    color: @color-main-copy;
                  }

                  i {
                    display: none;
                  }
                }
              }
              & > .dropdown {
                color: @color-main-copy;
                i {
                  top: 13px;
                  color: @color-main-copy;
                }
              }
              & > .date {
                color: @color-main-copy;
                font-family: @font-family-semibold;

                input {
                  border-radius: 4px;
                  border: none;
                  color: @color-main-copy;
                  background: @color-panel-grey;
                }

                i {
                  color: @color-main-copy;
                  opacity: .9;
                  margin-top: -1px;
                }
              }
            }

            &.hidden {
              & > input,
              & > .file-data-field,
              & > textarea,
              & > .dropdown {
                opacity: 0.4;
              }
              & > label {
                opacity: 0.3;
              }
            }

            &.required > label {
              i {
                display: inline-block;
              }
            }

            & > .btn-switcher {
              padding: 5px 12px;
              margin-left: @offset-sm;
              position: relative;
              border: 1px #9d9d9d solid;

              &:active {
                color: @color-white;
                background-color: @color-green;
                i {
                  color: @color-white;
                }
              }

              &:hover {
                color: @color-secondary-copy;
                i {
                  color: inherit;
                }
              }

              &.active,
              &.active:hover {
                color: @color-green;
                border-color: @color-green;
              }

              .prerequisite-tooltip {
                display: none;
                flex: 0 1 auto;
                .native-size;
                position: absolute;
                z-index: @z-index-content;
                right: -40px;
                top: @thumbnail-size + @font-size-icon + @offset-md + 2;
                align-items: center;
                background: @color-white;
                padding: @offset-sm @offset-md;
                box-shadow: 0 2px 8px @color-secondary-copy;
                color: @color-main-copy;
                font-size: @font-size-sm;
                text-align: left;
                width: 258px;

                span {
                  max-width: 100%;
                  word-wrap: break-word;
                  white-space: normal;
                  line-height: 18px;
                  display: inline-block;
                }

                .arrow {
                  background: @color-white;
                  display: block;
                  height: 2*@font-size-icon;
                  width: 2*@font-size-icon;
                  border: 1px solid @color-light-grey;
                  border-top: none;
                  border-left: none;
                  transform: rotate(225deg);
                  top: -@font-size-icon;
                  right: 53px;
                  position: absolute;
                }
              }
              &:hover {
                .prerequisite-tooltip {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
