@import "../../../less/variables";
@import "../../../less/mixins";

.auth-header {
  background: @color-panel-grey;
  width: 100%;
  height: @auth-header-height;
  line-height: @auth-header-height;
  vertical-align: middle;
  border-bottom: 1px solid @auth-header-border-color;
  overflow: hidden;

  .logo {
    font-family: @font-family-semibold;
    font-size: @font-md-size;
    color: @color-main-copy;
    display: block;
    width: 100%;
    padding: 10px 0;
    text-align: center;

    img {
      display: inline-block;
    }
  }
}
