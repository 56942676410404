.delete-warnings {
  .modal-dialog {
    max-width: 700px;
  }
  @arrow-size: @font-size-sm;

  .modal-header {
    padding-left: @arrow-size + 20px;
    padding-right: @arrow-size + 20px;
  }

  .modal-body {
    section {
      &.task {
        margin-top: 24px;
        display: flex;
        color: @color-dark-grey;
        align-items: center;

        .arrow {
          display: table;
          transform: rotate(90deg);
          font-size: @font-size-sm;
          color: @color-grey;
          flex: 0 1 @arrow-size;
          min-width: @arrow-size;
          cursor: pointer;
          margin-top: -5px;
          .no-focus();

          &:hover {
            color: @color-dark-grey;
          }

          &:first-child {
            margin-right: @offset-sm;
          }
          &:last-child {
            margin-left: @offset-sm;
          }
        }

        .content {
          flex: 0 1 calc(100% - (2 * @arrow-size + 2 * @offset-md));
          position: relative;

          .fake-bg:first-child {
            width: 100%;
            box-shadow: 0 0 4px @color-grey;
            position: absolute;
            z-index: 2;
            height: 70%;
            top: 15%;
            background-color: @color-white;
          }
          .fake-bg:nth-child(2) {
            width: calc(100% - @offset-sm);
            margin-left: @offset-xs;
            box-shadow: 0 0 4px @color-grey;
            position: absolute;
            height: 80%;
            z-index: 3;
            top: 10%;
            background-color: @color-white;
          }

          article {
            width: calc(100% - (2 * @offset-sm));
            margin: 0 auto;
            padding: @offset-lg;
            box-shadow: 0 0 4px @color-grey;
            position: relative;
            z-index: 4;
            background-color: @color-white;

            header {
              color: @color-red;
              font-size: @font-size-sm;
              font-family: @font-family-semibold;
              text-align: center;
              width: 100%;
            }

            main {
              background: @white-color;
              border: 1px @border-gray-light-color solid;
              border-left-width: 3px;
              padding: @offset-sm @offset-md;
              margin-top: @offset-lg;

              h4 {
                font-size: @font-size-md;
              }

              p {
                margin-top: 0;
                white-space: normal;
              }
            }
          }
        }
      }

      &.counter {
        text-align: center;
        width: 100%;
        color: @color-main-copy;
        margin: @offset-lg 0;
        font-size: @font-size-sm;
        font-family: @font-family-semibold;
      }
    }

    .checkbox-group {
      margin: 2*@offset-md 0 2*@offset-md 2*@offset-md;

      & > label {
        display: inline-flex;
        align-items: center;
        font-size: @font-size-md;

        .description {
          display: inline-flex;
          align-items: center;

          i {
            color: @color-grey;
            margin-left: @offset-sm;
          }
        }
      }
    }
  }

  .modal-footer {
    padding-left: @arrow-size + 20px;
    padding-right: @arrow-size + 20px;
  }
}
