@import "../../../less/variables";
@import "../../../less/mixins";

.process-attachments-list {
  & > main {
    padding-left: 0;
    .google-top-scroll-shadow();
    .bottom-offset(@page-screen-bottom-offset);
  }

  .attachments__attachment {
    &.highlighted {
      width: 100%;
      margin-left: 0;
      padding-bottom: 0;
    }
  }
}

.comments {
  &.process-comments-list {
    max-height: calc(100% - 54px);

    & > main {
      padding-left: 0;
      .google-top-scroll-shadow();
      .bottom-offset(@page-screen-bottom-offset);
    }
  }
  &__section {
    & > header {
      color: @color-secondary-copy;
      font-size: @font-size-sm;
      line-height: @font-size-lg;

      .name {
        font-size: @font-size-md;
        font-family: @font-family-medium;
        color: @color-main-copy;

        &:hover {
          color: @color-blue;
        }
      }

      .comments-count {
        color: @color-secondary-copy;
        cursor: default;

        &:after {
          content: '';
          height: 5px;
          width: 5px;
          display: none;
          border-bottom: 1px solid @color-blue;
          border-right: 1px solid @color-blue;
          transform: rotate(45deg) translate(-2px, -2px);
          margin-left: 5px;
        }
        &.expanded {
          &:after {
            transform: rotate(225deg) translate(0) !important;
          }
        }

        &.active {
          color: @color-blue;
          cursor: pointer;
          &:after {
            display: inline-block;
          }
        }
      }
    }

    &:first-child {
      .comments__section__list__item {
        &:first-child, &:nth-child(2) {
          footer {
            position: relative;
            .mentions-wrapper {
              position: absolute;
              top: 100%;
              width: 100%;
              right: 0;

              & > ul {
                position: static;
              }
            }
          }
        }
      }
    }

    &__list {
      margin: @offset-md 0;
      padding: 0 @offset-lg 0 @offset-md;
      border-left: 2px solid @color-pale-blue;

      &__item {
        &.highlighted {
          width: 100%;
          background-color: @color-light-blue;
          margin: 2px 0 -10px -@offset-md;
          animation: spring .3s ease-in .1s;
          padding: 10px 0 10px @offset-md;
        }

        & + article {
          margin-top: @offset-md;
        }

        & > header {
          display: flex;
          width: 100%;
          .no-focus();

          & > .user {
            .avatar-small {
              margin-right: @offset-sm;
            }
          }

          & > .main {
            width: calc(100% - (4 * @offset-md));

            & > header {
              font-size: @font-size-sm;
              color: @color-secondary-copy;

              .name {
                color: @color-main-copy;
                font-size: @font-size-md;
              }

              a {
                color: @color-blue;
                display: inline-block;
              }
            }

            & > p {
              font-size: @font-size-md;
              color: @color-main-copy;
              font-family: @font-family-regular;
              line-height: @font-size-h1;
              margin-top: @offset-xs;
              word-wrap: break-word;
              white-space: pre-wrap;
              max-height: 100%;
              max-width: 99%;

              .emoji {
                vertical-align: text-top;
              }

              a {
                color: @color-blue;
                cursor: pointer;
              }

              .mention {
                display: inline-block;
                color: @color-blue;
                font-family: @font-family-semibold;
                position: relative;
                white-space: nowrap;

                &:hover {
                  .user-tooltip {
                    visibility: visible;
                    padding: @offset-sm;

                    .user-tooltip__container {
                      display: flex;
                    }
                  }
                }

                & > .user-tooltip {
                  top: 140%;
                  left: -44px;
                  display: table;
                  padding: 0;

                  .user-tooltip__container {
                    display: none;
                  }

                  .arrow {
                    left: 55px;
                  }
                }
              }
            }

            & .preview {
              margin-top: @offset-sm;
              padding: @offset-md;
              background-color: @color-panel-grey;
              min-height: 45px;
              display: flex;
              align-items: center;
              width: 100%;

              & > i {
                color: @color-blue;
                font-size: @font-size-h1;
                margin-right: @offset-md;

                &::before {
                  margin: 0;
                }
              }

              &__content {
                width: calc(100% - (@font-size-h1 + @offset-sm));
                font-family: @font-family-semibold;
                word-wrap: break-word;

                react-file-preview {
                  display: block;
                }

                a {
                  color: @color-blue;
                  font-size: @font-size-md;
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        & > footer {
          .mentions-wrapper {
            z-index: 10;
            & > ul {
              bottom: 0;
              width: 100%;
            }
          }
          .process-comments-form {
            margin: @offset-md 0 0;
            max-width: 100%;

            & > div {
              textarea {
                margin: 0;
                padding: 10px 65px @offset-sm 55px !important;
              }
              #ui-mention-highlight {
                top: 0;
                padding: 10px 65px @offset-sm 55px;
              }
            }

            #comment-send {
              padding: 0 @offset-md;
            }
          }
        }
      }

      & > header {

      }
    }
  }
}
