.yesno-data-field {
  position: relative;

  &.error {
    & > ul > li > span {
      border-color: @color-red;
    }
  }

  &.disabled {
    & > ul > li {
      cursor: default;

      & > span {
        background-color: @color-light-grey;
        border-color: @color-grey !important;
      }

      &.active > span {
        &:before {
          background-color: @color-secondary-copy;
        }
      }
    }
  }

  & > ul {
    display: inline-flex;
    align-items: center;
    .no-focus();

    li {
      padding: 8px 4px;
      width: 60px;
      text-align: center;
      color: @color-main-copy;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      .no-focus();
      transition: all .1s ease;

      & > span {
        height: 16px;
        width: 16px;
        display: inline-flex;
        background: @color-white;
        border: 1px solid @color-grey;
        border-radius: 100%;
        margin-right: @offset-sm;
        align-items: center;
        justify-content: center;
        transition: all .1s ease;

        &:before {
          height: 8px;
          width: 8px;
          opacity: 0;
          display: block;
          background-color: @color-blue;
          border-radius: 100%;
          content: '';
        }
      }

      &.active {
        & > span {
          border-color: @color-blue;

          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  & > .error {
    color: @color-red;
    position: absolute;
    top: 105%;
    margin-left: 2px;
    white-space: nowrap;
    left: 0;
  }
}