@import "../../../../less/variables";
@import "../../../../less/mixins";

.task-section__content {
  padding: 0 @site-padding;
  display: flex;
  height: calc(100% - 90px);
  @aside-width: 40%;

  & > article {
    flex: 0 1 calc(60% - (2 * @offset-md));
    min-width: calc(60% - (2 * @offset-md));
    width: calc(60% - (2 * @offset-md));
    margin-right: @offset-md;
    padding-top: @offset-lg;
    display: flex;
    flex-direction: column;
    height: 100%;
    .no-focus();

    & > header {
      display: flex;
      justify-content: space-between;
      margin-bottom: @offset-lg;
      min-height: 2*@offset-lg;

      &.with-reject-reason {
        margin-bottom: @offset-xs;
      }

      // Texts assigned to and other
      & > div {
        display: inline-flex;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;
        position: relative;

        .main {
          margin-top: 1px;
          display: inline-flex;
          align-items: center;

          & > span {
            font-family: @font-family-semibold;
            color: rgb(149,149,149);

            &.green {
              color: @color-green;
            }
            &.red {
              color: @color-red;
            }
          }

          .actor {
            margin-left: 5px;
            display: inline-flex;
            align-items: center;
            position: relative;

            .avatar-small {
              margin-right: 5px;
            }

            .badge-deleted {
              height: @font-size-md;
              line-height: @font-size-md - 1;
              width: @font-size-md;
              border-radius: 100%;
              background-color: @color-red;
              position: absolute;
              text-align: center;
              bottom: -4px;
              left: 14px;

              i {
                font-size: 8px;
                margin: 0 0 0 1px;
                color: @color-white;
              }
            }
          }
        }

        .available-list {
          display: flex;
          align-items: center;
          min-height: 30px;

          .available-label {
            white-space: nowrap;
          }

          & > span:not(:first-child) {
            flex: 1 0 auto;
          }

          .selectize-control {
            position: absolute;
            left: 88px;
            top: 0;
            width: calc(100% - 88px);
            max-width: calc(100% - 88px);
            background: @color-white;
            z-index: @z-index-content;

            .selectize-input {
              &.active + .selectize-dropdown {
                overflow: visible;
              }
            }

            .selectize-dropdown {
              width: 100%;
              overflow: hidden;

              & > .dropdown-list {
                visibility: hidden;

                &.active {
                  visibility: visible;
                }

                & > ul > li {
                  display: block;

                  .users {
                    margin-left: 26px;
                  }
                  &.role {
                    margin-left: 33px;
                  }
                }
              }
            }
          }
        }

        span, div {
          display: inline-block;
          font-size: @font-size-sm;
        }

        & > div {
          display: flex;
        }

        .available-label,
        & > div {
          color: @color-secondary-copy;
          font-family: @font-family-semibold;

          & > ul {
            display: inline-flex;
            margin-left: @offset-sm;

            &.hidden {
              visibility: hidden;

              .popup-actors {
                visibility: hidden !important;
              }
              li button {
                visibility: hidden !important;
                transition: none;
              }
            }

            li + li {
              margin-left: @offset-md;
            }
          }

          &.assignee-name {
            color: @color-main-copy;
            margin-left: @offset-xs;
          }

          .group {
            margin-left: @offset-sm;
            white-space: nowrap;
            color: @color-main-copy;
            display: flex;
            align-items: center;

            &:focus {
              outline: none;
            }

            &.editable {
              cursor: pointer;
              padding-right: 3px;

              &:hover {
                border: 1px @input-border-color solid;
                border-radius: 4px;
                padding: 2px;
                margin-left: 5px;
              }
            }

            .text {
              color: @color-main-copy;
              margin-left: @offset-xs;
            }

            .avatar-small {
              background: @color-light-grey;
              color: @color-secondary-copy;

              i.icon-assignee_group {
                display: block;
                font-size: @font-size-md;
                width: auto;
              }
            }
          }

          .actors {
            color: @color-main-copy;
            display: inline-flex;
            align-items: center;
            flex: 1 0 auto;
            width: calc(100% - 96px);
            flex-wrap: wrap;

            &:focus {
              outline: none;
            }

            & > :first-child {
              display: table !important;
            }

            &.editable {
              cursor: pointer;
              padding-right: 3px;

              &:hover:not(.blocked) {
                border: 1px @input-border-color solid;
                border-radius: 4px;
                padding: 2px;
                margin-left: 5px;

                & > :first-child {
                  margin-left: 0;
                }
              }
            }

            &.hidden {
              visibility: hidden;
            }

            .group > span:not(.text) {
              width: 24px;
              text-align: center;
            }

            & > div {
              display: table;
            }
            .user {
              white-space: nowrap;
              margin-left: @offset-sm;
              display: table;

              .text {
                color: @color-main-copy;
                margin-left: 4px;
              }

              div {
                display: inline-block;
              }
            }
          }
        }
      }

      .popup-actors {
        display: flex;
        align-items: center;
        margin-right: @offset-md;
        visibility: hidden;
        position: relative;

        & + li {
          margin-left: 0;
        }

        &.showed {
          visibility: visible;
        }
        &.hide {
          display: none;
        }

        &:hover {
          .popup-actors-list {
            visibility: visible;
          }
        }

        & > span {
          white-space: nowrap;
          background: @color-light-grey;
          height: @thumbnail-size;
          min-width: @thumbnail-size !important;
          padding: 0 @offset-xs;
          line-height: @thumbnail-size;
          border-radius: @thumbnail-border-radius;
          font-family: @font-family-semibold;
          vertical-align: middle;
          text-align: center;
          text-transform: uppercase;
          color: @color-secondary-copy;
          font-size: @font-size-sm;
          cursor: pointer;
        }
      }

      .date-block {
        color: @color-secondary-copy;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        width: auto;
        flex: 1 0 auto;
        margin-left: @offset-md;
        height: 32px;

        & > .popup-actors {
          display: none;
          top: 0;
          position: absolute;
          right: 30px;
          visibility: hidden;

          &.showed {
            visibility: visible !important;
          }
          &.hide {
            display: none !important;
          }

          &-list {
            .container > div {
              display: flex !important;
              align-items: center;
            }
          }

          div, span {
            display: inline-block;
            font-size: 12px;
          }
          i {
            font-size: inherit;
            line-height: normal;
          }
        }

        & > .comments-toggler {
          border: 1px @gray-light-color solid;
          color: @gray-light-color;
          padding: 6px 5*@offset-sm 6px @offset-sm;
          height: 2*@offset-lg;
          border-radius: @buttons-border-radius;
          cursor: pointer;
          position: relative;
          margin-right: -48px;
          display: none;
          margin-left: @offset-md;
          font-size: @font-size-md;
          .no-focus();

          i {
            font-size: @font-size-lg;
            line-height: @font-size-xlg;
          }
        }

        div:not(.prc-date-picker):not(.popup-actors),
        span:not(.prc-date-picker) {
          margin-right: @offset-xs;
          display: table;

          & > span {
            display: block;

            &.prc-date-picker {
              display: inline-flex;
            }

            &.red {
              color: @color-red;
            }
          }
        }

        .prc-date-picker {
          margin-right: 0;
          display: inline-flex;

          & > span {
            display: inline-flex;
          }

          .handler,
          .handler i {
            margin-right: 0 !important;
          }
        }

        i {
          font-size: 30px;
          line-height: 30px;

          &::before {
            margin-top: 1px;
          }
        }
      }
    }

    & > .task-reject-reason {
      margin-bottom: @offset-xs;
    }

    & > .data-model-errors {
      height: 40px;
      border: 1px solid @color-red;
      display: flex;
      align-items: center;
      padding: 0 @offset-md;
      color: @color-red;
      font-size: @font-size-sm;
      margin: @offset-sm 0;

      & > i {
        margin-right: @offset-md;
        font-size: @font-size-md;
      }
      & > a {
        margin-left: @offset-md;
        color: @color-red;
        display: inline-block;
        border: 1px solid @color-red;
        border-radius: 16px;
        padding: 2px 8px;
        white-space: nowrap;

        &:active {
          background: @color-red;
          color: @color-white;
        }
      }
    }

    & > main {
      overflow: auto;
      overflow-x: hidden;
      padding: 1px;
      width: calc(100% - 2px);
      height: calc(100% - (3 * @offset-lg));
      flex: 0 1 calc(100% - (3 * @offset-lg));
      .google-top-scroll-shadow();
      .bottom-offset(@page-screen-bottom-offset);

      &.with-error {
        height: calc(100% - (3 * @offset-lg + 48px));
        flex: 0 1 calc(100% - (3 * @offset-lg + 48px));
      }

      markdown-hint {
        visibility: hidden;

        .markdown-hint {
          padding-top: @offset-xs;
        }
      }

      & > :first-child {
        margin-top: 0;
      }

      & > .task-description-wrapper {
        width: 100%;
        font-size: @font-size-md;
        line-height: 18px;
        max-width: 100%;
        word-wrap: break-word;
        position: relative;
        padding: @offset-xs;
        .no-focus();

        &:not(.active) {
          margin-left: -5px;
        }

        .actions {
          position: absolute;
          top: 0;
          right: 8px;
          padding: 8px;
          overflow: hidden;
          white-space: nowrap;

          .btn {
            opacity: 0;
            transition: opacity 0.2s;
            color: @color-add-grey;

            &:hover {
              color: @color-blue;
            }
          }
        }

        &.editable {
          &:hover:not(.active) {
            .actions {
              .btn {
                opacity: 1;
              }
            }
          }

          &:focus {
            outline: none;
          }

          &.active {
            display: flex;
            padding-bottom: 0;
            padding-right: 0;

            textarea {
              width: calc(100% + 8px);
              position: relative;
            }
            div, p, .actions {
              display: none;
            }

            & + markdown-hint {
              visibility: visible;
            }
          }
        }

        & > .hint {
          color: @color-secondary-copy;
          margin-bottom: 3px;
          display: inline-block;
          .no-focus();
        }

        .hint + .actions {
          position: static;
          top: unset;
          right: unset;
          padding: 0 0 5px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;
        }

        & > textarea {
          font-weight: normal;
          border: 1px @input-border-color solid;
          border-radius: 4px;
          margin-top: -4px;
          margin-left: -4px;
          background: @color-white;
          width: 100%;
          z-index: 4;
          padding: @offset-xs;
          resize: none;
          position: absolute;
          line-height: 18px;
          top: 0;

          &:focus {
            cursor: text;
            outline: none;
          }
        }

        & > div {
          font-size: @font-size-md;
          line-height: 18px;
          max-width: 100%;
          .no-focus();
        }

        p {
          margin: @offset-lg 0;
        }

        a {
          color: @color-blue;

          &:hover {
            opacity: 0.8;
          }
        }

        :first-child {
          margin-top: 0;
        }

        :last-child {
          margin-bottom: 0;
        }

        & + .task-data-model {
          margin-left: -4px;
        }
      }
    }
  }

  & > aside {
    min-width: @aside-width;
    flex: 0 1 @aside-width;
    padding-top: @offset-lg;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    border-left: 1px solid @color-pale-blue;

    & > .tabs {
      padding-left: @offset-md;
    }
  }
}
