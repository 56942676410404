@import "../../../less/variables";
@import "../../../less/mixins";

.actions-list {
  &-item {
    cursor: pointer;

    i {
      color: @color-secondary-copy;

      &.icon-title_badge {
        color: #a3a3a3;
        font-size: 18px;
        margin-left: -3px;
        transform: translate(2px, 2px);
      }
    }

    .tooltip-view {
      display: inline-block;
    }

    &:hover,
    &:focus {
      outline: 0;
      color: @color-blue;

      i {
        color: @color-blue;
      }
    }
  }
}

.actions-list-drop {
  .dropdown-menu-wrapper {
    margin-top: 5px;
  }
}

// Styles for angular version
.actions-menu {
  li {
    cursor: pointer;

    i {
      color: @color-secondary-copy;
      width: 16px;
      text-align: center;
    }

    &:hover,
    &:focus {
      color: @color-blue;

      i {
        color: @color-blue;
      }
    }
  }

  .only-on-phone-screen {
    display: none;
  }

  @media only screen and (max-width: 414px) {
    .only-on-phone-screen {
      display: list-item;
    }
  }
}
