@import "../../../less/variables";
@import "../../../less/mixins";

.form-group.data-form-group {
  @label-width: 180px;
  @status-width: 90px;

  align-items: flex-start;
  margin-top: 28px;

  & > .label {
    min-width: @label-width;
    width: @label-width !important;
    flex: 0 1 @label-width !important;
    padding-right: @offset-lg;
    padding-top: @offset-sm;
    word-wrap: break-word;

    i.icon-template_create_field_required {
      font-size: 10px;
      margin-left: 4px;
      transform: translateY(-1px);
    }
  }

  & > .error {
    left: @label-width !important;
  }

  .field-value {
    @space: @label-width + @status-width;
    max-width: calc(~"100%" - @space);
    position: relative;

    .error {
      position: absolute;
      top: 100%;
    }

    &.disabled {
      input[type=text],
      textarea,
      .select-dropdown__control,
      .react-selectize__control {
        border-color: transparent;
      }

      .select-dropdown__single-value,
      .react-selectize__single-value {
        color: unset !important;
      }
    }

    &.string-single-line,
    &.string-multi-line,
    &.email,
    &.files-list {
      width: calc(100% - @label-width - @status-width);
    }

    &.string-multi-line {
      textarea {
        resize: vertical;
        min-height: 84px;
      }
    }

    &.email {
      input {
        background: transparent;
      }

      &.disabled {
        i {
          color: #222;
        }
      }
    }

    &.multi-selector {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: calc(100% - 180px - 90px);

      .field-value-checkbox {
        display: flex;
        align-items: center;
        cursor: pointer;

        label {
          cursor: pointer;
          font-family: @font-family-regular !important;
          flex: unset !important;
          width: auto !important;
        }
      }

      .field-value-checkbox + .field-value-checkbox {
        margin-top: @offset-md;
      }
    }

    //    &.radio-selector {
    //      //width: 100px;
    //    }
    //
    //    &.yes-no {
    //      display: flex;
    //    }
    //
    &.date-only-value,
    &.date-and-time {
      .date-picker-input {
        width: unset;

        input {
          padding-right: 24px;
        }
      }

      &.disabled {
        .date-picker-input {
          i.icon-task_due_date_add_small {
            color: #222;
          }

          input {
            border-color: transparent;
          }
        }
      }
    }

    .select-dropdown,
    .react-selectize {
      width: auto;
      display: inline-block;
      min-width: 172px;

      &__option {
        white-space: nowrap;
      }
    }
  }

  .price-data-field.has-focus {
    border: 1px solid @color-blue;
    box-shadow: 0 0 0 1px @color-blue;
  }

  &.has-error {
    .price-data-field {
      border-color: @color-red;

      &.has-focus {
        box-shadow: 0 0 0 1px @color-red;
      }
    }

    .select-dropdown__control {
      border-color: @color-red !important;

      &--is-focused {
        box-shadow: 0 0 0 1px @color-red !important;
      }
    }
  }
}
