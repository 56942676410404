@import "../../less/variables";
@import "../../less/mixins";

.site-helper {
  margin: @offset-md auto;
  text-align: center;
  width: calc(100% - (2 * @site-padding));
  padding: 4rem 1em;
  border: 1px solid @color-pale-blue;

  h3 {
    color: @color-main-copy;
    font-size: @font-size-lg;
    margin-top: 2*@offset-md;
  }

  a {
    color: @color-blue;
    margin-top: 2*@offset-md;
    display: inline-block;
    font-size: @font-size-sm;
  }
}
