@import "../../../../less/variables";
@import "../../../../less/mixins";

@settings-label-width: 170px;

.template__section__task-settings,
.template__section__group-details {
  padding: @offset-lg 2*@offset-md @page-screen-bottom-offset 2*@offset-lg;
  position: relative;
  min-height: 100%;

  &__tabs {
    margin-top: 0;
    margin-bottom: @offset-md * 2;

    li + li {
      margin-left: @offset-md;
    }
  }

  &--empty {
    border: 1px solid @color-pale-blue;
    height: 100%;
    width: 100%;
    position: relative;

    & > div {
      text-align: center;
      width: 100%;
      position: absolute;
      top: 50%;
      margin-top: -2*@offset-md;

      h4 {
        font-size: @font-size-md;
        font-family: @font-family-semibold;
        font-weight: normal;
      }

      p {
        color: @color-add-grey;
        font-size: @font-size-sm;
        margin-top: @offset-xs;
      }
    }
  }

  &.ng-hide-add, &.ng-hide-remove {
    transition: all linear 0.2s;
  }

  &.ng-hide {
    opacity: 0;
  }

  & > .content {
    width: 100%;

    & > div {

      .markdown-hint {
        margin-left: @settings-label-width;
      }

      & > h4 {
        color: @color-secondary-copy;
        font-size: @font-size-md;
        margin: 2*@offset-md 0;
        font-family: @font-family-regular;

        &:first-child {
          margin-top: 0;
        }
      }

      & > markdown-hint {
        & + .form-group {
          margin-top: @offset-md;
        }
      }

      & > .form-group {
        align-items: flex-start;

        &.active + markdown-hint {
          visibility: visible;
        }

        &.task-type-settings {
          label {
            padding-top: 0;
            margin-top: 0;
          }

          .g-radio-group label {
            display: inline-block;
          }

          .g-radio-group label:last-child {
            margin-left: @offset-md;
          }
        }

        & > label {
          flex: 0 1 @settings-label-width;
          min-width: @settings-label-width;
          width: @settings-label-width;
          padding-top: @offset-sm;
          display: flex;
          justify-content: space-between;

          i.icon-template_create_field_required {
            font-size: 10px;
            vertical-align: top;
          }
        }

        & > .selectize-control {
          width: calc(100% - @settings-label-width - 27px);
          max-width: 615px;
          flex: 0 1 auto;

          .selectize-dropdown .dropdown-list {
            z-index: 7;
          }
        }

        & > .actors-settings {
          margin-top: 6px;

          .icon-header_settings {
            cursor: pointer;
            color: @color-add-grey;
            margin-left: @offset-sm;
            font-size: @font-size-lg;
            min-width: 15px;
            .no-focus();

            &.active {
              color: @color-green;
            }

            &:hover {
              color: @color-blue;
            }
          }
        }

        & > .task-name-control {
          flex: 0 1 calc(100% - @settings-label-width);
          max-width: calc(100% - @settings-label-width);
        }

        .tmpl-date-picker {
          background: #fff;

          .handler {
            padding: 6px 7px;
            flex: 1 0 auto;
            .input-border;
            border-width: 1px;
            cursor: pointer;
            height: 2*@offset-lg;
            min-width: 120px;

            &.drop-enabled {
              border-color: @color-blue;
              border-width: 2px;
            }
          }
        }
      }

      & .errors {
        margin-top: 2px;
        margin-left: @settings-label-width;
      }
    }
  }
}

.template__section__group-details {
  & > .content {
    & > div {
      .form-group {
        & > label {
          flex: 0 1 @settings-label-width;
          min-width: @settings-label-width;
          width: @settings-label-width;
          padding-top: 0;
        }

        &:not(.m-start) {
          & > label {
            padding-top: @offset-sm;
          }
        }

        .g-radio-group {
          .g-radio {
            display: inline-block;
          }
        }
      }

      & .errors {
        margin-top: 2px;
        margin-left: @settings-label-width;
      }
    }
  }
}

.actors-settings-dropdown {
  padding: 8px;
  margin-top: 4px;

  h5 {
    margin-bottom: 6px;
  }

  ul li {
    padding: 6px;
    display: flex;
    align-items: center;
    .no-focus();

    .g-radio,
    .g-radio input {
      .no-focus();
    }
  }
}
