@import "../../../less/variables";
@import "../../../less/mixins";

.process-assignees-list {
  .native-size;
  float: right;
  white-space: nowrap;
  display: flex;
  clear: both;
  flex: 0 1 auto;

  li {
    display: inline-block;
    cursor: pointer;
    position: relative;
    min-width: @thumbnail-size;
    .no-focus();

    & + li {
      margin-left: 4px;
    }

    & > div:hover + .user-tooltip, & > img:hover + .user-tooltip {
      visibility: visible;
      display: block;
    }

    .user-tooltip {
      display: none;
    }

    &:nth-last-child(2) {
      .user-tooltip {
        right: -44px;

        .arrow {
          right: 2*@thumbnail-size;
          left: auto;
        }
      }
    }

    &:last-child {
      .user-tooltip {
        right: -@offset-md;

        .arrow {
          right: @offset-lg;
          left: auto;
        }
      }
    }

    &.assignees-rest {
      & > span {
        white-space: nowrap;
        background: @color-light-grey;
        height: @thumbnail-size;
        min-width: @thumbnail-size !important;
        padding: 0 @offset-xs;
        line-height: @thumbnail-size;
        border-radius: @thumbnail-border-radius;
        font-family: @font-family-semibold;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
        color: @color-secondary-copy;
        font-size: @font-size-sm;
        cursor: pointer;
        display: inline-block;

        &:hover {
          & + .popup-actors-list {
            visibility: visible;
          }
        }
      }

      .popup-actors-list {
        right: -@offset-md;
        top: 36px;
        visibility: hidden;
        font-size: 12px;
        width: auto;
        .box-shadow();

        .arrow {
          background: #fff;
          display: block;
          height: 16px;
          width: 16px;
          border: 1px solid #f6f6f6;
          border-top: none;
          border-left: none;
          -ms-transform: rotate(225deg);
          transform: rotate(225deg);
          top: -8px;
          position: absolute;
          right: @offset-lg;
        }
      }
    }
  }
}
