@import "../../../less/variables";
@import "../../../less/mixins";

.sub-form-group {
  margin-top: 2*@offset-md;
  border: 1px solid @color-pale-blue;
  border-left-width: 3px;
  display: flex;
  align-items: flex-start;
  padding: 10px 12px @offset-lg 12px;
  position: relative;

  &.inactive {
    background: @color-panel-grey;
  }
  &.completed {
    border-left-color: @color-green;
  }

  & > span > .icon-template_create_dragable,
  & > .icon-template_create_dragable {
    position: absolute;
    right: @offset-sm;
    top: 50%;
    margin-top: -15px;
    font-size: 27px;
    color: @start-btn-color;
    cursor: pointer;
    .no-focus();

    &:hover {
      color: @color-main-copy;
    }
  }

  & > .icon-common_close {
    position: absolute;
    top: 11px;
    right: 10px;
    font-size: @font-size-sm;
    color: @start-btn-color;
    cursor: pointer;

    &:hover {
      color: @color-main-copy;
    }

    &:focus {
      outline: none;
    }
  }

  & + .sub-form-group {
    margin-top: 0;
    border-top: none;
  }

  &.add-task {
    margin-top: -1px;
    align-items: center;
    cursor: pointer;
    padding: 10px 12px;
    margin-bottom: 120px;

    .content {
      color: rgb(157, 157, 157);
      font-family: @font-family-medium;
      font-size: @font-size-md;
    }

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      a {
        border-color: @color-blue;
        & > i {
          color: @color-blue;
        }
      }
      .content {
        color: @color-blue;
      }
    }

    & > a {
      margin-top: 0;

      & > i {
        padding: 0;
        margin: 0;
        font-size: 16px;
      }
    }
  }

  & > a {
    line-height: @thumbnail-big-size - 2px;
    min-height: @thumbnail-big-size;
    width: @thumbnail-big-size;
    min-width: @thumbnail-big-size;
    overflow: hidden;
    text-align: center;
    border: 1px dashed @template-create-header-color;
    border-radius: @buttons-border-radius;
    display: block;
    margin: 3px @offset-md 0 1px;
    color: @color-add-grey;

    i {
      font-size: 21px;
      padding-left: 4px;
    }
  }

  & > .avatar {
    margin: 3px @offset-md 0 1px;
  }

  input[type=text], textarea {
    width: 100%;
    display: block;
    border: none;
    background: transparent;
    color: @color-main-copy;
    font-size: @font-size-sm;
    line-height: 18px;
    margin: 7px 0 11px 0;
    resize: none;

    &.filled + .selectize-control {
      margin-top: 0;
    }

    &.filled:hover {
      color: @color-blue;
      cursor: pointer;
    }

    &.filled:focus {
      color: @color-main-copy;
      cursor: inherit;
    }

    &:focus {
      outline: none;
    }

    &:first-child {
      font-size: @font-size-md;
      margin-top: 5px;
    }
  }

  textarea {
    margin-bottom: 5px !important;
  }

  .content {
    width: 100%;
    max-width: 628px;
    display: table;
  }

  .task-footer {
    display: flex;
    align-items: flex-start;
    margin-top: @offset-md;
    position: relative;

    &.no-offset {
      .prc-date-picker {
        margin-top: 0;
      }
    }

    .prc-date-picker {
      margin-top: 2px;
    }

    .select-wrapper {
      flex: 0 1 100%;
      width: 100%;
      padding-top: 2px;
    }

  }

  .help-tooltip {
    display: none;
    height: 50px;
    width: 260px;
    top: -55px;
    left: calc(50% - 130px);
    position: absolute;

    .arrow {
      position: absolute !important;
      left: calc(50% - 11px);
      bottom: -10px;
      transform: rotate(180deg);
    }
  }
}

.sub-form-groups.as-sortable-dragging {
  .sub-form-group {
    background: @color-white;
    box-shadow: 0 2px 8px @color-secondary-copy;

    &:not(.COMPLETED):not(.FIRST).inactive {
      background: @color-panel-grey;
    }

    .help-tooltip {
      display: block;
      visibility: visible;
    }
  }
}
