@import "../../../../less/variables";
@import "../../../../less/mixins";

.column-settings {
  .form-group {
    align-items: start;

    .label {
      font-family: @font-family-medium;
      display: inline-block;
      margin-top: 8px;

      i {
        font-size: 10px;
        vertical-align: top;
      }
    }

    .field-value.full {
      width: 100%;
    }

    .date-picker-input {
      max-width: 130px;

      &.datetime-picker-input {
        min-width: 180px;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 2*@offset-md;
    margin-bottom: 8px;

    .btn + .btn {
      margin-left: 8px;
    }
  }

  .field-value {
    .react-selectize {
      min-width: 140px;
    }

    & > span.static-text {
      display: inline-block;
      line-height: 32px;
    }
  }
}
