@import "../../../less/variables";
@import "../../../less/mixins";

.select-timezone {
  min-width: 320px;

  .handler {
    text-decoration: none;
    position: relative;
    padding: 8px 7px;
    .input-border;
    border-width: 1px;
    cursor: pointer;
    transition: all .2s ease;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - @offset-lg);
    }

    &:hover {
      border-color: @color-blue;
      box-shadow: 0 0 0 1px @color-blue;
    }

    i {
      font-size: 16px;

      &.icon-common_arrow_down {
        font-size: 4px !important;
      }
    }
  }
}

.select-timezones-element {
  @media only screen and (max-width: @phone-portrait-size) {
    width: 100%;
    left: -3*@offset-lg !important;

    .dropdown-menu--timezones {
      max-width: calc(100% - @offset-lg);
      top: -1px;
    }
  }

  &.select-timezones-element-open {
    .dropdown-menu--timezones {
      display: block;
    }
  }

  .dropdown-menu--timezones {
    display: none;
    position: absolute;
    z-index: 10;
    min-width: 200px;
    max-height: 290px;
    overflow-y: auto;
    overflow-x: hidden;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
    padding: @offset-xs 0;

    li {
      a {
        font-size: @font-size-md;
        color: @color-main-copy;
        padding: @offset-sm 2*@offset-md @offset-sm @offset-md;
        display: flex;
        align-items: center;
        white-space: nowrap;
        min-width: 200px;

        i {
          visibility: hidden;
          color: @color-green;
          font-size: 8px;
          margin-right: 8px;
        }

        &:hover {
          color: @color-blue;
        }
        &.active {
          i {
            visibility: visible;
          }
        }
      }
    }
  }
}
