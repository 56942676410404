@import "variables";
@import "mixins";

ul.menu-button-more-options.dropdown-menu {
  width: auto;
  & > li {
    padding: @offset-sm @offset-lg @offset-sm @offset-sm;
    &:hover{
      background-color: transparent;
      i, span {
        color: @color-blue;
      }
      &.active {
        cursor: default;
        span{
          color: @color-main-copy;
        }
      }
    }
    &.active i{
      color: @color-light-green;
    }
    & > i {
      display: inline-block;
      font-size: @font-size-md;
      color: @color-secondary-copy;
      margin-right: @offset-xs;
    }
    & > span {
      color: @color-main-copy;
      font-size: @font-size-md;
    }
  }
}
a.menu-button-more{
  i {
    margin-right: @offset-xs;
  }
  @media only screen and (max-width: @phone-portrait-size) {
    i {
      margin-right: 0;
    }
    span {
      display: none;
    }
  }
}
