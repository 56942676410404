@import "../../../less/variables";
@import "../../../less/mixins";

.task-form-assignee {
  flex: 0 1 4*@offset-lg;
  width: 4*@offset-lg;
  min-width: 4*@offset-lg;

  &.process {
    flex: 0 1 @thumbnail-big-size + @offset-md;
    min-width: @thumbnail-big-size + @offset-md;
    width: @thumbnail-big-size + @offset-md;

    & > div, & > img {
      margin: 3px 12px 0 1px;
    }
  }

  .thumbnail {
    text-transform: uppercase;
  }

  div, img {
    height: @thumbnail-big-size;
    line-height: @thumbnail-big-size - 1;
    width: @thumbnail-big-size;
    min-width: @thumbnail-big-size;
    border-radius: @thumbnail-big-border-radius;
    margin: 0 @offset-md;
    text-align: center;
    color: @color-white;
    font-size: @font-size-md;
    overflow: hidden;

    &.group {
      background-color: @color-light-grey;
      color: @color-add-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: @thumbnail-big-border-radius;

      i {
        font-size: @font-size-sm;
        width: auto;
        display: none;

        &::before {
          margin: 0;
        }
      }
      & > .rectangle {
        display: inline-block;
        border: 2px rgb(163,163,163) solid !important;
        border-radius: 4px;
        width: 10px;
        height: 10px;
        min-width: 0;
        box-sizing: content-box;
        margin: 0;
        padding: 0;
      }

      &.approval{
        border-radius: (@thumbnail-big-size / 2);
        & > i {
          display: inline;
        }
        & > .rectangle {
          display: none;
        }
      }
    }

    &.unassigned {
      border: 1px dashed #9d9d9d;
      display: block;
      color: #a3a3a3;
      border-radius: 16px;

      i {
        font-size: 21px;
        padding-left: 4px;
      }
    }
  }
}
