@import "../../../less/variables";
@import "../../../less/mixins";

.profile-pass-form {
  min-width: 390px;
  @base-profile-pass-label-width: 150px;

  .form-group {
    &:first-child {
      margin-top: 0;
    }

    label {
      flex: 0 1 @base-profile-pass-label-width;
      min-width: @base-profile-pass-label-width;
    }
  }

  .input-hint--error {
    margin-left: @base-profile-pass-label-width;
  }

  footer {
    .btn:first-child {
      margin-left: @base-profile-pass-label-width;
    }
  }
}
