@import "../../../less/variables";
@import "../../../less/mixins";

.reports {
  &__header {
    .inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 @site-padding;
    height: 4*@offset-lg;
    font-family: @font-family-medium;
    position: relative;

    h3 {
      align-items: center;
      font-weight: normal;
      color: @site-menu-color-hov;
      font-size: @font-text-size;
    }
  }

  .reports-list {
    display: flex;
    padding: 0 (@site-padding - 12);
    flex-flow: row wrap;

    &-item {
      width: 350px;
      height: 170px;
      flex: 0 0 auto;
      border: 1px solid @color-pale-blue;
      border-radius: 8px;
      display: flex;
      margin: 0 12px 36px;

      .report-icon {
        margin: 24px;
        display: inline-block;
        width: 32px;
        height: 32px;
        flex: 0 0 auto;
      }

      &.task-performance {
        .report-icon {
          width: 38px;
          height: 38px;
          margin: 21px;
        }
      }

      .report-info {
        padding: 16px;
        padding-left: 0;
        position: relative;
        height: 100%;

        .report-name {
          font-size: 14px;
          font-family: @font-family-medium;
        }

        .report-description {
          margin-top: 12px;
          font-size: 12px;
          line-height: 16px;
          max-height: 108px;
          overflow: auto;
        }

        .report-action {
          display: flex;
          align-items: center;
          position: absolute;
          bottom: 0;
          background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.8) 20%);
          width: 100%;
          height: 0;
          border-radius: 0 0 8px 0;
          opacity: 0;
          visibility: hidden;
          overflow: hidden;
        }
      }

      &:hover {
        border-color: @color-blue;
        box-shadow: 0 0 0 1px @color-blue;

        .report-action {
          height: 60px;
          transition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275) .15s;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
