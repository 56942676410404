.file-data-field {
  display: flex;
  width: 100%;
  min-width: 240px;

  @media only screen and (max-width: 768px) {
    min-width: 0 !important;

    .files-wrapper {
      min-width: 0 !important;
    }
  }

  .error {
    & > i {
      color: @color-red;
    }
  }

  .files-wrapper {
    width: 100%;
    min-width: 240px;
    position: relative;
    .no-focus();

    .icon-task_attachment[disabled] {
      color: @color-light-grey;
      cursor: default;

      &:hover {
        border-color: inherit;
        cursor: default;
      }
    }

    .files-block {
      width: 100%;
      padding: 8px 7px;
      min-height: 30px;
      border: 1px dashed rgb(204,204,204);
      border-radius: 4px;
      color: @color-add-grey;
      outline: none;
      overflow: hidden;

      .icon-task_attachment {
        color: rgb(204,204,204);
      }

      & > div {
        display: flex;
        align-items: flex-start;

        &.upload {
          padding: 8px 7px;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
        }
      }

      &.disabled {
        background: @color-panel-grey;
        border: none;

        & + .files-error {
          display: none;
        }

        &:hover {
          border-color: inherit;
          cursor: default;

          .icon-task_attachment {
            color: @color-grey;
            cursor: default;
        }
        }
        .placeholder {
          display: none;
        }
        .files > .file {
          a {
            width: 100%;
          }
          & > .icon-common_close {
            display: none;
          }
        }
      }

      &.limited {
        background: @color-panel-grey;
        border: none;

        & + .files-error {
          display: none;
        }

        &:hover {
          border-color: inherit;
          cursor: default;

          .icon-task_attachment {
            color: @color-grey;
            cursor: default;
          }
        }
        .placeholder {
          display: none;
        }
      }

      &.error {
        border-color: @color-red !important;
      }

      &.empty:hover:not(.limited):not(.disabled) {
        border-color: @color-blue !important;
        cursor: pointer !important;

        .icon-task_attachment {
          color: @color-blue !important;
        }
      }

      &:hover:not(.notHighlitable):not(.limited):not(.disabled) {
        border-color: @color-blue;
        cursor: pointer;

        .icon-task_attachment {
          color: @color-blue;
        }
      }

      &.filled {
        .placeholder {
          display: none;
        }
        .content > i {
          &[disabled] {
            color: @color-light-grey;
            cursor: default;
          }
        }
        }

      .placeholder {
        margin-left: @offset-xs;
        color: rgb(149,149,149);
      }

      .files {
        width: calc(100% - (@offset-xs + 14px));
        display: flex;
        flex-wrap: wrap;
        padding: 2px;
        margin: -6px 0 -8px 4px;
        .no-focus();

        .file {
          display: inline-flex;
          align-items: center;
          font-size: @font-size-sm;
          font-family: @font-family-semibold;
          padding: @offset-xs @offset-sm;
          border-radius: 8px;
          background-color: @color-light-grey;
          margin: 0 @offset-xs @offset-xs 0;
          max-width: 100%;
          .no-focus();

          &.ng-leave {
            animation: none !important;
            transition: none !important;
          }

          i {
            &::before {
              margin: 0;
            }
            &.icon-attachment_document {
              font-size: @font-size-md;
              margin-right: @offset-xs;
              color: @color-blue;
            }
            &.icon-common_close {
              margin-left: @offset-xs;
              font-size: @font-size-icon;
              cursor: pointer;
              color: @color-add-grey;
              .no-focus();

              &:hover {
                color: @color-main-copy;
              }
            }
          }

          & > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;

            & + a {
              display: none;
            }
          }

          &.uploading {
            color: @color-white;
            .animated-progress-bar-stripes(@color-green);

            i.icon-attachment_document,
            i.icon-common_close {
              color: @color-white;
              .no-focus();
            }
          }
        }
      }

      &.single {
        .files {
          & > .file {
            margin-bottom: 0;
          }
        }
      }
    }

    .files-error,
    .files-hint {
      width: calc(100% - (2 * @offset-xs));
      color: @color-red;
      margin-top: @offset-xs;
      margin-left: @offset-xs;

      .limit {
        color: @color-secondary-copy;
        white-space: nowrap;
      }

      .errors {
        margin: 0;
        max-width: 100%;
      }
    }
  }
}

.error {
  .file-data-field {
    .files-block {
      border: 1px solid @color-red;
    }
  }
}
