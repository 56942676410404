.user-label {
  display: inline-flex;
  max-width: 100%;

  .user-label-icon {
    line-height: 24px;
    width: 24px;
    height: 24px;
    padding-right: 0;
    margin-top: -4px;
    margin-right: 5px;
    font-size: 12px;
    border-radius: 8px;
    color: #40484c;
    text-align: center;
    -ms-flex: 0 0 24px;
    flex: 0 0 24px;
    display: inline-block;
    overflow: hidden;

    .thumbnail {
      width: 24px;
      height: 24px;

      span {
        color: #fff;
        text-transform: uppercase;
      }

      img {
        width: 100%;
      }
    }
  }

  .user-label-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
