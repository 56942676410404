.select-default-currency {
  .handler {
    padding: 8px 7px;
    .input-border;
    border-width: 1px;
    display: inline-flex;
    align-items: center;
    box-shadow: none;
    color: @color-main-copy;
    cursor: pointer;

    i {
      font-size: 17px;

      &.icon-common_arrow_down {
        font-size: 4px !important;
        margin-left: 5px !important;
      }
    }

    &.default-currency-enabled {
      border: 1px  @color-blue solid;
      box-shadow: 0 0 0 1px @color-blue;
    }
  }
}

.default-currency-element {
  z-index: 1100;

  &.default-currency-open {
    .currencies-list {
      display: block;
    }
  }

  .currencies-list {
    display: none;
    min-width: 90px;
    max-height: 240px;
    overflow: auto;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0,0,0,0.15);

    li {
      a {
        font-size: @font-size-sm;
        color: @color-main-copy;
        padding: 8px @offset-md 8px 25px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        min-width: 105px;

        &.active {
          padding-left: 8px;
          i {
            display: block;
          }
        }

        i {
          display: none;
          font-size: @font-size-icon;
          color: @color-green;
          margin-right: 6px;
        }

        &:hover {
          color: @color-blue;
        }
        &.active {
          i {
            color: @color-green;
          }
        }
      }
    }
  }
}
