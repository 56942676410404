@import "variables";
@import "mixins";

.main-wrapper {
  .native-size;
  height: calc(100% - @site-header-size);
  width: 100%;
  position: relative;

  @media only screen and (min-width: @tablet-portrait-size) {
    &.notified {
      height: calc(100% - (@site-header-size + 40px));
    }
  }

  @media only screen and (max-width: 507px) {
    height: calc(100% - (@site-header-size * 2));
  }

  & > form {
    height: 100%;
    width: 100%;
  }
}

.top-border {
  border-top: 1px solid @color-pale-blue;
}

.no-transition {
  transition: none !important;
}

.g-default-cursor {
  cursor: default !important;
}

.color-red {
  color: @color-red;
}

.color-gray {
  color: @color-grey;
}

.loading-spinner {
  color: @color-secondary-copy;
  font-size: @font-size-sm;
  display: flex;
  white-space: nowrap;
  margin: 30px 0 -25px @offset-sm;
  height: 0;

  i {
    margin-right: @font-size-xs;
    display: inline-block;
    height: 15px;
    font-size: @font-size-md;
  }
}

.icon-common_hint {
  color: @color-information-icon;
  margin-left: @offset-sm;
  font-size: @font-size-md;
}

.hidden {
  display: none;
}

@media only screen and (max-width: @phone-landscape-size) {
  #intercom-container,
  .intercom-lightweight-app {
    display: none !important;
  }
}

body.hideIntercom #intercom-container,
body.hideIntercom .intercom-lightweight-app {
  display: none !important;
}

.saving-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,.6);

  &-body {
    background: @color-white;
    box-shadow: 0 0 8px @color-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  &-spinner {
    justify-content: center;
    font-size: @font-size-xlg;
    height: 24px;

    i {
      color: @color-add-grey;
      display: inline-block;
      margin: 0;
      font-size: 23px;
      height: 24px;
      width: 24px;
      text-align: center;
      animation: rotate .3s linear infinite;
    }
  }

  &-text {
    margin-left: 10px;
  }
}
