@import "../../../less/variables";
@import "../../../less/mixins";

.selection-map {
  @listHeight: @offset-md * 2 + 2;

  height: @listHeight;
  margin-top: -@listHeight;
  font-size: @offset-md;
  display: flex;
  justify-content: start;
  background-color: @color-pale-blue;
  flex-shrink: 0;

  &.empty {
    background-color: transparent;
  }

  &__item {
    color: @color-add-grey;
    position: relative;
    padding-left: @offset-md;
    line-height: @listHeight;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.full-text {
      overflow: visible;
    }
  }

  a {
    color: @color-blue;
    cursor: pointer;
  }

  .divider {
    display: block;
    position: absolute;
    width: @offset-md;
    text-align: center;
    top: 0;
    left: 0;
  }

  .icon-template_group_breadcrumbs_back {
    vertical-align: middle;
    color: @color-blue;
    font-size: 9px;
  }

  .icon-template_group_breadcrumbs_chevron {
    vertical-align: middle;
    color: @color-add-grey;
    font-size: 7px;
  }
}

.task-details-section {
  &__header {
    margin-top: @offset-md;
    margin-bottom: @offset-md;
    color: @color-add-grey;
    font-size: @font-size-md;
    font-family: @font-family-semibold;
  }

  & + & {
    margin-top: @offset-md * 2;
  }
}

.task-group-conditions {

  .help-text {
    color: @color-secondary-copy;
    font-size: @font-size-sm;
    line-height: @font-size-sm * 1.5;
    margin: 5px 0;
    font-family: @font-family-regular;

    a {
      color: @color-blue;
    }
  }

  .header {
    font-size: @font-size-sm;
    font-family: @font-family-bold;

    & > span {
      display: inline-block;
      min-width: 140px;
      vertical-align: top;
      padding-top: 7px;
    }
  }

  .conditions {
    .condition {
      @condition-border: 1px solid @color-pale-blue;
      position: relative;
      border-top: @condition-border;
      border-bottom: @condition-border;
      margin: @offset-sm 0 @offset-sm 50px;
      padding: @offset-sm 30px @offset-sm 0;
      display: flex;
      align-items: start;
      justify-content: start;
      flex-wrap: wrap;

      .form-field,
      .operation {
        display: inline-block;
        flex: 0 1 auto;
        margin-right: 10px;
        margin-bottom: 4px;
      }

      .expression {
        display: inline-block;
        position: relative;
        flex: 0 1 auto;

        &.form-group {
          margin-top: 0;
          width: auto;
        }

        input {
          background: transparent;
        }

        &__number {
          width: 100px;
        }

        &__string {
          width: 260px;
        }

        &__dropdown {
          max-width: 260px;

          .table-source-field {
            min-width: 200px !important;
          }
        }

        .value-container-stretcher {
          display: none;
        }
      }

      .form-group.error {
        .prc-date-picker {
          .handler {
            border-color: @color-red !important;
          }
        }

        .yesno-data-field > ul > li > span {
          border-color: @color-red;
        }

        input  {
          border-color: @color-red;

          &:focus{
            box-shadow: 0 0 0 1px @color-red;
          }
        }
      }

      .errors {
        margin-left: 0;
      }

      .rule {
        position: absolute;
        top: 13px;
        right: 100%;
        margin-right: 6px;
        text-transform: uppercase;
        font-size: 11px;
        color: rgb(149, 149, 149);
        background: rgb(245, 245, 245);
        border: 1px solid rgb(232, 232, 232);
        border-radius: 11px;
        height: 22px;
        line-height: 22px;
        padding: 0 6px;
      }

      .remove-action {
        color: rgb(149, 149, 149);
        position: absolute;
        top: 13px;
        right: 0;
        font-size: 22px;
      }
    }

    .condition + .condition {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;

      .rule,
      .remove-action {
        top: 4px;
      }
    }
  }

  .add-condition {
    margin-left: 50px;
  }

  &-operator-drop {
    display: inline-block;
    vertical-align: middle;
    z-index: 100;

    & &-target {
      min-width: 160px;
    }
  }

  &-form-field-drop {
    z-index: 100;

    &.empty &-target {
      color: @color-secondary-copy;
    }
  }

  &-operation-drop {
    z-index: 100;

    &.empty &-target {
      color: @color-secondary-copy;
    }
  }

  &-operator-drop-content,
  &-form-field-drop-content,
  &-operation-drop-content {
    display: none;
    min-width: 150px;

    .dropdown-menu {
      background: @color-white;
      .box-shadow();
      max-height: 240px;
      overflow: auto;

      &.capitalized {
        & > li {
          text-transform: capitalize;
        }
      }

      li {
        font-size: @font-size-sm;
        color: @color-main-copy;
        padding: 8px 8px 8px 25px;
        display: block;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 260px;
        .no-focus();

        &.empty-value {
          color: @color-secondary-copy;
        }

        i {
          display: none;
          font-size: @font-size-icon;
          color: @color-green;
          margin-right: 6px;
        }

        &.active {
          padding-left: 8px;

          i {
            display: inline-block;
          }
        }

        &:hover {
          color: @color-white;
          background-color: @color-green;

          i {
            color: @color-white;
          }
        }
      }
    }
  }
}

.task-group-conditions-operator-drop-open {
  .task-group-conditions-operator-drop-content {
    display: inline-block;
  }
}

.task-group-conditions-form-field-drop-open {
  .task-group-conditions-form-field-drop-content {
    display: inline-block;
  }
}

.task-group-conditions-operation-drop-open {
  .task-group-conditions-operation-drop-content {
    display: inline-block;
  }
}

.task-group-conditions-viewer {
  font-size: @font-size-sm;

  .summary {
    margin-bottom: @offset-sm;
  }

  .conditions {
    margin-left: 20px;

    li {
      list-style-type: disc;
    }

    li + li {
      margin-top: @offset-sm;
    }

    .field-label {
      font-family: @font-family-medium;
    }

    .operation {
      text-transform: lowercase;
    }
  }
}

.conditions-marker {
  position: relative;
  display: inline-block;

  &__icon {
    display: block;
    position: absolute;
    z-index: 5;
    width: 18px;
    height: 18px;
    bottom: -4px;
    right: 6px;
    background-color: @color-yellow;
    border: 1px solid #fff;
    border-radius: 50%;
    text-align: center;

    i {
      color: #000;
      display: block;
      line-height: 14px;
      font-size: 10px;
      opacity: .5;
    }
  }
}

.task-group-conditions-hint {
  display: inline-block;
  position: relative;
  font-size: @font-size-sm;
  vertical-align: middle;

  .conditions-marker__icon {
    display: inline-block;
    position: relative;
    bottom: auto;
    right: auto;
    background-color: transparent;
    border: 0;
    height: 14px;

    i {
      font-size: 12px;
      float: none !important;
      margin: 0 !important;
      opacity: 1;
      color: @color-add-grey;
    }
  }

  &-drop-tether {
    z-index: 1000;
  }

  &__trigger {
    cursor: pointer;
  }

  &__content {
    font-size: @font-size-sm;
    margin: 10px 0;
    padding: @offset-lg;
    background-color: @color-white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    position: relative;

    .summary {
      margin-bottom: @offset-sm;
    }

    .conditions {
      li + li {
        margin-top: @offset-sm;
      }

      .match-status {
        display: inline-block;
        width: @offset-lg;
        height: 20px;

        .match-found {
          color: rgb(74, 188, 59);
          font-size: 9px;
          vertical-align: middle;
          margin-top: -2px;
        }

        .match-not-found {
          color: rgb(247, 95, 95);
          font-size: 10px;
          vertical-align: middle;
          margin-top: -2px;
        }

        .bullet {
          display: inline-block;
          background: @color-dark-grey;
          border-radius: 50%;
          width: 4px;
          height: 4px;
          margin-left: 6px;
          vertical-align: middle;
        }
      }

      .field-label {
        font-family: @font-family-medium;
      }

      .operation {
        text-transform: lowercase;
      }
    }

    .arrow-top {
      display: none;
      position: absolute;
      border: 10px solid transparent;
      border-top: 0;
      border-bottom-color: white;
      top: -10px;
      left: 50%;
      margin-left: -10px;
    }

    .arrow-bottom {
      display: none;
      position: absolute;
      border: 10px solid transparent;
      border-bottom: 0;
      border-top-color: white;
      bottom: -10px;
      left: 50%;
      margin-left: -10px;
    }
  }

  &-drop-tether-element-attached-top {
    .arrow-top {
      display: block;
    }
  }

  &-drop-tether-element-attached-bottom {
    .arrow-bottom {
      display: block;
    }
  }
}

