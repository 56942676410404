.data-model-table-row-modal {
  .modal-dialog {
    max-width: 740px;
    margin-top: 60px;
    @label-width: 200px;
    @max-width: 340px;
    display: block;

    .errors-strip {
      border: 1px solid @color-red;
      background-color: @color-white;
      color: @color-red;
      margin-bottom: @offset-md;
      margin-top: -1px;
      padding-left: 0;
      display: flex;
      align-items: center;
      line-height: normal;

      & > span {
        height: 100%;
        width: 2*@offset-lg;
        margin-right: @offset-sm;
        text-align: center;
        background-color: @color-red;
        color: @color-white;
        display: inline-block;
        font-size: @font-size-lg;
        line-height: 2*@offset-lg;

        i {
          transform: translateY(-2px);
          height: 100%;
          display: inline-block;
        }
      }
    }

    .modal-header {
      padding-bottom: 20px;
      margin-right: 2*@offset-md;
      border-bottom: 1px solid rgb(225, 235, 239);

      & > h3 {
        font-size: @font-size-h1;
      }
    }

    .modal-body {
      padding: 20px 0 @offset-lg;
      max-height: 60vh;
      overflow: auto;
      .google-scroll-shadow();

      .form-group {
        display: flex;
        align-items: flex-start;
        position: relative;

        &:first-child {
          margin-top: 0;
        }

        user-field,
        dropdown-tether {
          display: block;
          max-width: @max-width;
        }

        file-data-field {
          display: block;
          width: 100%;
        }

        .content-wrapper {
          width: calc(100% - @label-width);
          max-width: @max-width;
          position: relative;

          &.files {
            width: calc(100% - @label-width);
            max-width: @max-width;
            display: flex;
            flex-wrap: wrap;
          }

          &.string,
          &.multiline {
            width: calc(100% - @label-width);
            max-width: @max-width;
          }

          .email-data-field {
            width: 100%;
          }

          .dropdown-tether {
            display: block;

            .tether-handler {
              float: unset;
              max-width: calc(100%);
            }
          }

          .multiselect-field {
            max-width: @max-width;

            label {
              max-width: 100%;
            }
          }
        }

        .actions {
          padding: 6px 8px;

          .btn {
            opacity: 0;
            transition: opacity 0.2s;
            color: @color-add-grey;
            padding: 0;

            &:hover {
              color: @color-blue;
            }
          }
        }

        &:hover {
          .actions {
            .btn {
              opacity: 1;
            }
          }
        }

        textarea + .errors-messages {
          margin-top: 0;
        }

        .hint-messages {
          padding-top: 2px;
          margin-left: 2px;
          color: @color-secondary-copy;
          white-space: nowrap;
          width: 100%;

          & + .errors-messages {
            margin-top: 0;
          }
        }

        .errors-messages {
          position: absolute;
          margin-top: 1px;
          margin-left: 2px;
          top: 100%;
          white-space: nowrap;
          color: @color-red;

          div {
            color: @color-red;
          }
        }

        .formula-error {
          display: inline-block;
          vertical-align: top;
          line-height: 30px;
        }

        & > label {
          min-width: @label-width;
          width: @label-width;
          flex: 0 1 @label-width;
          padding-right: @offset-lg;
          padding-top: @offset-sm;
          word-wrap: break-word;
        }

        input {
          width: 100%;
          background: transparent;

          &.number {
            min-width: 180px;
            width: auto;
            flex: 0 1 auto;
          }

          &:read-only {
            color: @color-main-copy;
            -webkit-text-fill-color: @color-main-copy;
            background: @color-panel-grey;
            border: none;
            font-family: @font-family-semibold;
          }
        }

        textarea {
          width: 100%;
          resize: vertical;
          min-height: 7*@offset-md;
          background-color: transparent;

          &:read-only {
            color: @color-main-copy;
            -webkit-text-fill-color: @color-main-copy;
            background: @color-panel-grey;
            border: none;
            font-family: @font-family-semibold;
          }
        }

        .input-read-only {
          background: @color-panel-grey;
          color: @color-main-copy;
          border: none;
          font-family: @font-family-semibold;
          border-radius: 4px;
          padding: 8px 7px;
          min-height: 30px;

          &.formula {
            display: inline-block;
            min-width: 180px;
          }

          a {
            color: @color-blue;

            &.full-link {
              display: none;
            }
          }
        }

        .textarea-content-wrapper {
          min-height: 7*@offset-md;
          background: @color-panel-grey;
          color: @color-main-copy;
          border: none;
          font-family: @font-family-semibold;
          width: 100%;
          height: 7*@offset-md;
          border-radius: 4px;
          word-wrap: break-word;
          display: block;
          position: relative;
          overflow: hidden;
          padding-bottom: 7px;

          .textarea-content {
            overflow-y: scroll;
            height: 100%;
            padding: 8px 7px;

            & > span {
              white-space: pre-wrap;
            }

            a {
              color: @color-blue;

              &.full-link {
                display: none;
              }
            }
          }
        }

        .icon-common_hint {
          margin-left: 8px;
          vertical-align: bottom;
        }

        .icon-template_create_field_required {
          font-size: @font-size-xs;
          margin-left: @offset-xs;
          transform: translateY(-1px);
        }

        &.error {
          input, textarea {
            border-color: @color-red;

            &:focus{
              box-shadow: 0 0 0 1px @color-red;
            }
          }
          .icon-template_create_field_required {
            color: @color-red;
          }
          .prc-date-picker {
            .handler {
              border-color: @color-red !important;
            }
          }
        }
      }
    }

    .modal-footer {
      padding: 2*@offset-md 0 0 @label-width;
      margin: 0;
      justify-content: space-between;
      display: flex;
      align-items: center;

      & > i {
        font-size: 30px;
        color: @color-secondary-copy;
        cursor: pointer;
        .no-focus();

        &:hover {
          color: @color-blue;
        }
      }
    }
  }

  @media only screen and (max-width: @phone-portrait-size) {
    .modal-dialog {
      margin-top: 15px !important;
      padding: 16px;
    }
    .modal-body {
      .form-group {
        display: block !important;

        .errors-messages {
          white-space: normal !important;
        }

        label {
          width: 100% !important;
        }

        .content-wrapper {
          width: 100% !important;
          max-width: 100% !important;
          margin-top: @offset-sm !important;
        }

        .file-data-field {
          max-width: 100% !important;
          margin-top: @offset-sm !important;
        }
      }
    }
    .modal-footer {
      padding-left: 0 !important;
      justify-content: center !important;
    }
  }
}
