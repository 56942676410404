.dropdown-tether {
  z-index: @z-index-dropdown; // Force dropdown to be on the upper level than popup windows
  display: inline-table;

  .error & > .tether-handler:not(.dropdown-tether-enabled) {
    border-color: @color-red;
  }
  .error & > .tether-handler.dropdown-tether-enabled {
    border-color: @color-red !important;
    box-shadow: 0 0 0 1px @color-red !important;
  }

  &.dropdown-tether-open &-content {
    display: block;
  }
  .tether-handler {
    display: block;
    min-width: 90px;
    justify-content: space-between;
    white-space: nowrap;
    color: @color-main-copy;
    font-size: @font-size-sm;
    position: relative;
    z-index: 2;
    border: 1px @border-button-gray-color solid;
    float: left;
    font-weight: normal;
    outline: none;
    text-overflow: ellipsis;
    text-align: left;
    max-width: 260px;
    overflow: hidden;
    cursor: pointer;
    padding: @offset-sm 20px @offset-sm @offset-sm;
    border-radius: 6px;
    background: transparent;
    min-height: 2*@offset-lg;
    .no-focus();

    &.capitalized {
      span {
        text-transform: capitalize;
      }
    }

    &:not(:disabled).dropdown-tether-enabled {
      border: 1px  @color-blue solid;
      box-shadow: 0 0 0 1px @color-blue;
    }

    &:disabled {
      background-color: @color-panel-grey;
      color: @color-main-copy;
      cursor: default;
      border-color: transparent;
    }

    .icon-common_arrow_down {
      font-size: 4px;
      margin-left: 6px;
      vertical-align: middle;
      text-align: right;
      position: absolute;
      right: 8px;
      top: 13px;
      width: 6px;
    }

    &:not(:disabled).drop-enabled {
      border: 1px  @color-blue solid;
      box-shadow: 0 0 0 1px @color-blue;
    }
  }
}

.dropdown-tether-content {
  display: none;

  .scroll-wrapper {
    position: relative;
    .ps-scrollbar-y, .ps-scrollbar-y-rail {
      width: 4px !important;
      background-color: @color-pale-blue !important;
      border-color: @color-pale-blue !important;
    }
    .ps-scrollbar-y {
      background-color: @color-pale-blue !important;
      border-color: @color-pale-blue !important;
    }
    .ps-scrollbar-y-rail {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }

  .dropdown-menu {
    min-width: 90px;
    background: @color-white;
    .box-shadow();
    max-height: 210px;
    ul.capitalized {
      & > li {
        text-transform: capitalize;
      }
    }

    li {
      font-size: @font-size-sm;
      color: @color-main-copy;
      padding: 8px 12px 8px 25px;
      display: block;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .no-focus();

      &.empty-value {
        color: @color-secondary-copy;
      }

      i {
        display: none;
        font-size: @font-size-icon;
        color: @color-green;
        margin-right: 6px;
      }

      &.active {
        padding-left: 8px;

        i {
          display: inline-block;
        }
      }

      &:hover {
        color: @color-white;
        background-color: @color-green;

        i {
          color: @color-white;
        }
      }
    }
  }
}
