@import "../../../less/variables";
@import "../../../less/mixins";

.admin__main__company {
  width: 100%;
  height: 100%;
  padding: 2*@offset-md 4*@offset-md;
  border: 1px solid @color-pale-blue;
  max-height: calc(100% - (4 * @offset-lg));
  overflow: auto;
  .no-focus();

  &__loader {
    .loading-spinner {
      justify-content: center;
      font-size: @font-size-xlg;
      height: 24px;

      i {
        margin-right: @offset-md;
        display: inline-block;
        font-size: 24px;
        height: 24px;
        animation: rotate .3s linear infinite;
      }
    }
  }

  .form {
    width: 100%;
    @company-label-width: 140px;

    & > .form-group {
      & > label {
        width: @company-label-width;
        min-width: @company-label-width;
        flex: 0 1 @company-label-width;
      }

      & > input {
        width: 100%;
        flex: 0 1 100%;
        max-width: 240px;
      }

      & > .multi-select {
        max-width: 240px;
      }
    }

    & > .input-hint--info,
    & > .input-hint--error {
      margin-left: @company-label-width;
    }

    & > footer .btn:first-child {
      margin-left: @company-label-width;
    }

    .company-logo {
      @logo-width: 120px;
      @logo-height: 40px;

      display: flex;
      align-items: center;
      position: relative;

      .logo {
        flex: 0 1 @logo-width;
        width: @logo-width;
        height: @logo-height;
        overflow: hidden;

        img {
          width: @logo-width;
          height: @logo-height;
        }
      }

      .actions {
        margin-left: @company-label-width - @logo-width;

        a {
          color: @color-blue;
          font-size: @font-size-sm;
          display: block;
          font-family: @font-family-semibold;
          cursor: pointer;
          margin-bottom: (@offset-md / 2);
        }

        span {
          display: block;
          font-size: 11px;
          color: @color-secondary-copy;
        }
      }
    }
  }
}
