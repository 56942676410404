@import "../../../less/variables";
@import "../../../less/mixins";

@import "header";
@import "main";
@import "media";
@import "task-group-conditions";

.templates {
  height: 100%;
  width: 100%;
}

.selection-map {
  li {
    display: inline-block;
  }
}
