.confirm-alt-modal,
.alert-modal {
  .modal-content {
    display: flex;

    .dialog-img {
      flex: 0 1 60px;
      min-width: 60px;
    }

    .dialog-content {
      margin-left: @offset-md;

      p + p {
        margin-top: @offset-xs;
      }

      .modal-header {
        h3 {
          font-size: 15px;
          padding-right: 2*@offset-md;
        }
      }

      .modal-body {
        ul {
          font-size: @font-size-sm;
          margin-top: @offset-xs;
          list-style: circle;
          margin-left: @offset-lg;

          li {
            list-style: circle;
          }
        }
      }

      .modal-footer {
        .btn-green {
          min-width: 90px;
        }
      }
    }
  }
}

.confirm-alt-modal {
  .modal-dialog {
    max-width: 568px;
  }

  &.expiration {
    .modal-dialog {
      max-width: 456px;
    }

    .modal-content .dialog-content {
      .modal-footer {
        .btn-green {
          text-transform: uppercase;
          min-width: 60px;
        }
      }
    }
  }

  .typed-confirmation {
    margin: 12px 0 24px;
  }
}

.typed-confirmation {
  input {
    appearance: none;
    flex: 0 1 100%;
    width: 100%;
    max-width: @input-max-width;
    padding: 8px 7px;
    .input-border;
    border-width: 1px;
    box-shadow: none;
    color: @color-main-copy;
    resize: none;
    outline: 0;
    cursor: pointer;
    font-size: @font-size-lg;

    &:focus, &.active {
      outline: none;
      border-color: @color-blue;
      border-width: 1px;
      box-shadow: 0 0 0 1px @color-blue;
      cursor: text;
    }
  }

  &.has-error {
    & > input {
      color: @color-red !important;
      border-color: @color-red !important;
      box-shadow: 0 0 0 1px @color-red !important;
    }
  }
}
