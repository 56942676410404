.scroll-shadow {
  overflow: auto;
  max-height: 100%;
}

.top-shadow {
  &:before {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.13);
    z-index: 1;
  }
}

.bottom-shadow {
  &:after {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    box-shadow: 0 -5px 8px 0 rgba(0, 0, 0, 0.13);
    z-index: 1;
  }
}