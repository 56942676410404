@import "../../../less/variables";
@import "../../../less/mixins";

.title-editable {
  position: relative;

  &.editable {
    h1 {
      padding: 1px 4px 0 4px;
      margin-left: -4px;
      font-family: @font-family-regular;

      &:focus {
        outline: none;
      }
    }
  }

  &.editable:hover {
    h1 {
      border: 1px @input-border-color solid;
      cursor: pointer;
    }
  }

  .title-editable-invisible {
    position: absolute;
    visibility: hidden;
  }

  h1 {
    color: @color-main-copy;
    font-family: @font-family-semibold;
    font-weight: normal;
    font-size: 21px;
    max-height: 52px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 4px;

    white-space: normal;
    .no-focus();
  }

  & > textarea {
    color: @color-main-copy;
    font-family: @font-family-semibold;
    font-weight: normal;
    font-size: 21px;
    max-height: 52px;
    overflow: hidden;
    resize: none;
    background: transparent;
    padding: 0;
    margin-top: 1px !important;
    width: 100%;
    outline: none;
    border: 1px solid transparent;
    cursor: text;
  }

  & > input {
    color: @color-main-copy;
    font-family: @font-family-semibold;
    font-weight: normal;
    font-size: 21px;
    max-height: 24px;
    overflow: hidden;
    resize: none;
    background: transparent;
    width: 100%;
    outline: none;
    border: 1px solid transparent;
    padding: 0;
    margin: 2px 0 6px 0;
    cursor: text;
    display: none;
  }

  @media only screen and (max-width: @phone-landscape-size) {
    h1 {
      white-space: nowrap;
    }

    & > textarea {
      display: none;
    }

    & > input {
      display: block;
    }
  }
}
