@import "../../../less/variables";
@import "../../../less/mixins";

.templates--start {
  position: relative;
  height: 100%;
  width: 100%;

  @header-height: 4*@offset-lg;

  &__header {
    height: @header-height;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    color: @color-main-copy;
    font-size: @font-size-md;
    padding: 0 @site-padding;

    h3 {
      font-size: 14px;
    }

    & > a {
      color: #cdcdcd;
      position: absolute;
      cursor: pointer;

      &.common_back {
        left: 0;
        font-size: 40px;
        line-height: 40px;
        top: @offset-md;
        width: 20px;
        display: inline-block;
        .no-focus();

        & > i {
          margin: 0;
        }

        &:hover {
          color: @color-blue;
        }
      }
    }

    .buttons-block {
      flex-grow: 2;
      margin-left: 12px;

      & > a {
        margin-left: 8px;
        .short {
          display: none;
        }

        .full {
          display: inline;
        }
      }
    }

    .search-block {
      position: relative;

      & > i {
        position: absolute;
        color: @color-secondary-copy;
        cursor: pointer;
        .no-focus();

        &.icon-header_search {
          left: 6px;
          margin-top: -1px;
          font-size: @font-size-lg;
          top: 50%;
          transform: translateY(-50%);
          transition: all .15s ease;
        }

        &.icon-common_remove {
          right: 6px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
        }
      }

      & > input {
        position: relative;
        z-index: 1;
        transition: all .15s ease;
        opacity: 1;
        padding: 8px 30px;
        appearance: none;
        flex: 0 1 100%;
        width: 160px;
        border: 1px @color-secondary-btn solid;
        border-radius: 4px;
        box-shadow: none !important;
        color: @color-main-copy;
        resize: none;
        outline: 0;
        cursor: pointer;
        background: transparent;

        &::-ms-clear {
          display: none;
        }

        &:focus, &.active {
          outline: 0;
          border-color: @color-blue;
          border-width: 2px;
          cursor: text;

          & + .icon-header_search {
            color: @color-main-copy;
          }
        }
      }
    }

    &.searching {
      .search-block {
        input {
          width: 240px;
        }
      }
    }
  }

  &__body {
    height: calc(100% - @header-height);
    padding: 0 (@site-padding - 8px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .google-top-scroll-shadow();
    .bottom-offset(@lists-screen-bottom-offset);

    .templates-total {
      color: @color-secondary-copy;
      font-size: @font-size-sm;
      display: flex;
      white-space: nowrap;
      margin: 0 0 0 @offset-sm;
      height: 0;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 18px 0 0;

    &__template {
      background-color: @color-panel-grey;
      transition: background .1s ease;
      box-shadow: 0 0 1px 1px @color-light-grey;
      height: @templates-start-height;
      width: @templates-start-width;
      padding: 2*@offset-md;
      margin: 0 @offset-sm @offset-lg;
      border-radius: 9px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      z-index: 1;
      .no-focus();

      &:hover,
      &.hover {
        background-color: @color-light-grey;
        overflow: visible;

        footer {
          opacity: 0;
        }

        & > .actions {
          visibility: visible;
          transform: translateY(-2*@offset-lg - @offset-md);
          transition: all .2s cubic-bezier(0.175, 0.885, 0.32, 1.275) .15s;
          opacity: 1;
        }

        & > .edit-actions {
          display: block;
        }
      }

      & > .actions {
        position: absolute;
        bottom: -2*@offset-lg;
        left: 0;
        height: 2*@offset-lg;
        width: 100%;
        text-align: center;
        transform: translateY(0);
        opacity: 0;
        visibility: hidden;

        .btn.disabled {
          background-color: #d8d8d8 !important;
        }
      }

      & > .edit-actions {
        position: absolute;
        top: 4px;
        right: 4px;
        display: none;

        span,
        .btn-icon {
          font-size: 28px;
          line-height: 38px;
          display: inline-block;
          vertical-align: unset;
          color: @color-blue;
          cursor: pointer;
          .no-focus();

          &.disabled {
            color: @color-grey;
            cursor: not-allowed;
          }
        }

        div[ng-transclude] {
          display: inline-block;
        }
      }

      h4 {
        margin-top: @offset-lg;
        display: block;
        max-height: 2*@font-size-md*1.2; /* Fallback for non-webkit */
        width: 100%;
        color: @color-main-copy;
        word-wrap: break-word;
        overflow: hidden;
        font-size: @font-size-md;
        font-family: @font-family-regular;
      }

      p {
        display: block;
        max-height: 60px; /* Fallback for non-webkit */
        width: 100%;
        color: @color-main-copy;
        overflow: hidden;
        font-family: @font-family-semibold;
        font-size: @font-size-sm;
        margin-top: @offset-lg;
        line-height: 15px;

        &.short {
          max-height: 30px;
        }
      }

      .avatar {
        color: @color-asphalt-grey;
        font-size: @thumbnail-big-size - 10px;

        i {
          display: block;
          line-height: @thumbnail-big-size;

          &:before {
            margin-left: -1px;
          }
        }
        &:not(.default) {
          line-height: 44px;
        }

        &.start {
          line-height: @thumbnail-big-size;
          height: @thumbnail-big-size;
          width: @thumbnail-big-size;
          min-width: @thumbnail-big-size;
          overflow: hidden;
          border: 2px dashed @color-grey;
          border-radius: @thumbnail-big-border-radius;
          margin: 0 auto;
          color: @color-grey;

          i {
            display: block;
            height: @thumbnail-big-size;
            font-size: @template-create-header-icon-choose-size;
            margin-left: 7px;
            padding-top: 9px;
          }
        }

        &.default {
          background-color: @color-grey;
        }
      }

      footer {
        position: absolute;
        font-family: @font-family-semibold;
        color: @color-secondary-copy;
        font-size: @font-size-sm;
        bottom: 2*@offset-md;
        left: 2*@offset-md;
        opacity: 1;
        white-space: nowrap;
        transition: opacity .1s ease;
        line-height: 20px;

        span {
          display: block;

          .icon-small_clock,
          .icon-error_hint {
            margin-left: -2px;
          }

          &.failed-run {
            color: @color-red
          }
        }
      }

      &.action-card {
        background-color: #fff;
        border: unset;
        box-shadow: unset;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23A8A8A8FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 9px;

        .avatar {
          margin: 35px auto 25px;
          color: #a8a8a8;

          &.rounded {
            i {
              width: 40px;
              height: 40px;
              line-height: 38px;
              font-size: 20px;
              padding-left: 1px;
              border-radius: 50%;
              border: 1px dashed #a8a8a8;
            }
          }
        }

        h4 {
          text-align: center;
          color: @color-blue;
        }

        p {
          text-align: center;
          color: #959595;
        }

        &:hover {
          background-image: unset;
          box-shadow: 0 0 1px 1px @border-blue-color inset;

          .avatar {
            color: @border-blue-color;

            &.rounded {
              i {
                border-color: @border-blue-color;
              }
            }
          }
        }
      }
    }
  }

  &__empty-state,
  &__error-state {
    border: 1px solid @color-pale-blue;
    padding: 4*@offset-lg 0;
    width: 100%;
    text-align: center;
    margin-top: 2*@offset-md;

    h5 {
      color: @color-main-copy;
      font-size: @font-size-md;
      margin-top: @offset-lg;
    }

    & > i {
      font-size: 64px;
      color: #d1d1d1;
    }

    p {
      margin-top: @offset-sm;
      font-size: @font-size-sm;
      color: @color-secondary-copy;


      a {
        color: @color-blue;
      }
    }

    @media only screen and (max-width: 1023px) {
      p span.break {
        display: block;
        margin: @offset-xs 0;
      }
    }
  }

  &__error-state {
    border-color: @color-red;
  }
}

@media only screen and (max-width: 400px){
  .templates--start__header {
    h3 {
      flex-grow: 2;
    }
    .buttons-block {
      display: none;
    }
  }

}


@media only screen and (max-width: 610px){
  .templates--start__header {
    .search-block {
      position: relative;
      height: 32px;
      width: 34px;
      flex: 0 0 34px;

      input {
        width: 30px;
        border-radius: 16px;
        padding-left: 2px;
        position: absolute;
        right: 0;
      }
    }

    &.searching {
      h3,
      .buttons-block {
        display: none;
      }

      .search-block {
        width: 100%;
        flex: 0 0 100%;

        input {
          width: 100%;
          border-radius: 4px;
          padding: 8px 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .templates--start__header {
    .buttons-block {
      & > a {
        .short {
          display: inline;
        }

        .full {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .templates--start__header.searching {
    .buttons-block {
      & > a {
        .short {
          display: inline;
        }

        .full {
          display: none;
        }
      }
    }
  }
}
