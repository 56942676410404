@import "../../less/variables";
@import "../../less/mixins";

.table-source-field {
  &__control {
    color: @color-main-copy;
    font-size: @font-size-sm;
    border: 1px @border-button-gray-color solid;
    border-radius: 6px;
    min-height: 32px !important;
    cursor: pointer !important;
    .no-focus();
    background-color: transparent !important;

    &--is-disabled {
      border: none !important;
      box-shadow: none !important;
      background-color: #f5f5f5 !important;
    }

    &--is-focused:not(&--is-disabled) {
      border-color: @color-blue !important;
      box-shadow: 0 0 0 1px @color-blue !important;
    }
  }

  &__value-container {
    padding: 3px;
    padding-right: 16px;

    .value-container-stretcher {
      white-space: nowrap;
      height: 0;
      opacity: 0;
      width: 1000px;
    }
  }

  &__placeholder {
    margin-left: 6px;
  }

  &__input {
    margin-left: 4px;

    input {
      box-shadow: unset !important;
    }
  }

  &__value-container--is-multi &__input {
    margin-left: 2px;
  }

  &__value-container--is-multi &__placeholder {
    margin-left: 4px;
  }

  &__indicators {
    align-items: flex-start;
  }

  &__clear-indicator {
    font-size: 6px;
    color: @color-white;
    background-color: @color-grey;
    border-radius: 100%;
    opacity: .8;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    margin-right: 5px;

    &:hover {
      color: @color-white !important;
      opacity: 1;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    padding: 0 !important;

    i {
      font-size: 4px;
      margin-right: 8px;
      color: @color-main-copy;
    }
  }

  .value-is-deleted {
    display: inline-flex;
    border-radius: 100%;
    background-color: @color-red;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    margin-right: 6px;
    transform: translateY(-1px);

    i {
      color: @color-white;
      font-size: 8px;
    }
  }

  &__single-value {
    font-size: 12px !important;
    line-height: 24px;
    padding: 0 !important;
    margin-left: 6px;
    max-width: calc(100% - 16px);
  }

  &__multi-value {
    background-color: #e8e8e8;
    cursor: pointer;
    padding: 0 !important;
    border-radius: 6px !important;

    &__label {
      font-size: 12px !important;
      line-height: 24px;
      padding: 0 0 0 8px !important;
    }

    &__remove {
      color: #a3a3a3;

      &:hover {
        background-color: transparent !important;
        color: #222 !important
      }
    }
  }

  &__menu {
    border-radius: 0 !important;
    margin-top: 2px !important;
    min-width: 100%;
    width: auto;
    //max-width: 250px;
  }

  &__menu-list {
    padding: 10px !important;
  }

  &__option {
    font-size: 12px !important;
    padding: 0 !important;
    white-space: nowrap;
    height: 20px;
    cursor: pointer !important;

    .spacer {
      width: 8px;
      display: inline-block;
    }

    .option-label {
      display: inline-block;
      padding: 0 8px;
      overflow: hidden;
      max-width: 100%;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-radius: 8px;

      .highlighted {
        color: sandybrown;
      }

      &.empty-value {
        color: @color-add-grey;
      }
    }

    &--is-focused {
      background-color: transparent !important;
      color: @color-main-copy !important;

      .option-label {
        background-color: #e8e8e8;
      }
    }

    &--is-selected {
      background-color: transparent !important;
      color: @color-main-copy !important;

      .option-label {
        font-family: @font-family-medium;
      }
    }
  }

  &__option + &__option {
    margin-top: 8px;
  }

  &__menu-notice {
    font-size: 12px;
    line-height: 24px;
    height: 24px;
    padding: 0 !important;
    white-space: nowrap;
  }
}

react-table-source-field {
  min-width: 150px;
}

.form-group.error {
  .table-source-field {
    &__control {
      border-color: @color-red;

      &--is-focused:not(&--is-disabled) {
        border-color: @color-red !important;
        box-shadow: 0 0 0 1px @color-red !important;
      }
    }
  }
}
