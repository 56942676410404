@import "../../less/variables";
@import "../../less/mixins";

.search-field {
  position: relative;
  margin-bottom: 6px;

  input {
    height: 30px;
    font-size: 12px;
    width: 80px;
    padding: 4px 0 4px 22px;
    border: 1px transparent solid;
    border-radius: 4px;
    transition: width 0.15s ease;

    &.filled {
      border: 1px @input-border-color solid;
      border-radius: 4px;
    }

    &:focus {
      outline: none;
      border: 2px solid @color-blue;
      padding-left: 21px;
    }

    &:focus, &.filled {
      width: 200px;
      padding-right: 22px;
    }
  }

  .search-icon,
  .clear-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.15s ease;
  }

  .search-icon {
    left: 4px;
    font-size: 14px;
    color: @color-add-grey;
  }

  .clear-button {
    right: 4px;
    color: @color-add-grey;
    font-size: 12px;

    &:hover,
    &:focus {
      color: @color-blue;
    }
  }
}
