@import "variables";
@import "mixins";

.radio {
  display: inline-block;
  height: @radio-size;
  width: @radio-size;
  min-width: @radio-size;
  overflow: hidden;
  margin-right: @offset-sm;
  position: relative;

  input {
    height: 100%;
    width: 100%;
    opacity: 0;
    border: 0;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: @z-index-content;
    cursor: pointer;

    &:hover + span {
      border-color: @color-blue;
    }

    &:disabled + span {
      background-color: @color-panel-grey;
      border-color: @color-grey;
    }

    &:focus {
      outline: none;
    }
  }

  span {
    height: 100%;
    width: 100%;
    display: inline-block;
    background: @color-white;
    border: 2px solid @color-grey;
    border-radius: 100%;

    &.checked {
      border-color: @color-blue;

      i {
        display: block;
      }
    }

    i {
      display: none;
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 100%;
      height: @radio-size - 10px;
      width: @radio-size - 10px;
      background-color: @color-blue;
    }
  }
}
