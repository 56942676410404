@import "../../../less/variables";
@import "../../../less/mixins";

.report-filters {
  background: @color-panel-grey;
  padding: 12px 12px 24px 24px;

  .filters-header {
    font-size: 11px;
    line-height: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid @color-light-grey;
    display: flex;
    justify-content: space-between;

    span {
      color: @color-secondary-copy;
      text-transform: uppercase;
    }

    .btn {
      font-size: 12px;
      padding: 0;
      margin: 0;
      height: 12px;
      line-height: 12px;
    }
  }

  .field {
    margin-bottom: 12px;

    .date-range-control {
      .date-inputs {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .DayPickerInput,
      .date-picker-input {
        width: 46%;

        input {
          padding-right: 7px;
        }
      }

      &.has-error {
        input {
          border-color: @color-red;

          &:focus {
            box-shadow: 0 0 0 1px @color-red;
          }
        }

        .error {
          color: @color-red;
          font-size: 12px;
          padding: 4px 0;
        }
      }
    }



    .field-label {
      display: block;
      line-height: 32px;
      font-size: 12px;
      color: #222222;
      font-family: @font-family-medium;
    }

    .status-list {
      display: flex;
      flex-flow: wrap;
    }

    .filter-checkbox {
      display: flex;
      align-items: center;
      font-size: 12px;
      height: 34px;
      width: 50%;

      .checkbox {
        margin: 0 8px 0 0;
      }

      label {
        line-height: 30px;
        cursor: pointer;
      }
    }
  }
}
