@import "../../less/variables";
@import "../../less/mixins";

react-file-preview {
  overflow: hidden;
  display: inline-block;
}

.transparent-image-bg(@size) {
  @bg-color: rgba(100, 100, 100, 0.4);
  background-color: rgba(200, 200, 200, .9);
  background-image:
    linear-gradient(45deg, @bg-color 25%, transparent 25%, transparent 75%, @bg-color 75%, @bg-color),
    linear-gradient(45deg, @bg-color 25%, transparent 25%, transparent 75%, @bg-color 75%, @bg-color);
  background-size: @size @size;
  background-position: 0 0, (@size/2) (@size/2);
}

.file-preview__link {
  cursor: pointer;
  color: @color-main-copy;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  .no-focus();

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.file-preview {
  height: 100%;

  @preview-header-height: 70px;
  @preview-footer-height: 70px;
  @preview-background: rgba(0, 0, 0, 0.8);

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @preview-background;
    z-index: 1100;

    div {
      outline: none;
    }
  }

  &__header {
    position: absolute;
    height: @preview-header-height;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    padding: 16px 32px;
    justify-content: space-between;
    align-items: start;
    background: linear-gradient(rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);

    div {
      outline: none;
    }

    &__icon {
      margin-right: 12px;
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 40px;
      }
    }

    &__filename {
      width: 100%;

      h1 {
        color: #fff;
        font-family: @font-family-semibold;
        font-weight: normal;
        font-size: 21px;
      }

      small {
        font-size: 12px;
        color: #959595;
      }
    }

    &__actions {
      height: 100%;
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        cursor: pointer;

        &:hover {
          color: #fff;
        }
      }

      &__rotate,
      &__zoom,
      &__download {
        color: #fff;

        i {
          font-size: 32px;
        }
      }

      &__close {
        color: #cdcdcd;
        margin-left: 32px;

        .icon-common_close {
          font-size: 16px;
        }
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.pdf {
      align-items: start;
      overflow: auto;
    }

    div {
      outline: none;
    }

    .image-viewer img {
      .transparent-image-bg(40px);
    }

    &__other {
      height: 70%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__icon {
        img {
          height: 100px;
        }
      }

      &__text {
        margin-top: 10px;
        color: #cdcdcd;
        font-size: 16px;
      }

      &__download {
        margin-top: 10px;
        font-size: 14px;
        color: @color-blue;
      }
    }
  }

  &__gallery {
    position: absolute;
    height: @preview-footer-height;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);

    &__item {
      display: inline-block;
      margin: 0 15px;
      cursor: pointer;
      opacity: .5;

      &.active,
      &:hover {
        opacity: 1;
      }

      img {
        height: 40px;

        &:not(.file-type) {
          .transparent-image-bg(10px);
        }
      }
    }
  }

  &__gallery__prev,
  &__gallery__next {
    position: absolute;
    top: 50%;
    color: #fff;
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    margin-top: -25px;

    &:hover {
      color: #fff;
    }

    i {
      display: inline-block;
      filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.7));
      font-size: 18px;
    }
  }

  &__gallery__prev {
    left: 18px;
  }

  &__gallery__next {
    right: 18px;
  }

  &__image-loading {
    i {
      color: #cdcdcd;
      font-size: 20px;
      height: 21px;
      line-height: 20px;
      animation: rotate .3s linear infinite;
    }
  }
}

.new-loading-spinner,
.new-loading-spinner:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.new-loading-spinner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid rgba(255, 255, 255, 0.8);
  border-right: 5px solid rgba(255, 255, 255, 0.8);
  border-bottom: 5px solid rgba(255, 255, 255, 0.8);
  border-left: 5px solid transparent;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 480px) {
  .file-preview {
    &__header {
      padding: 8px 16px;

      &__filename {
        h1 {
          font-size: 16px;
          word-break: break-all;
        }
      }

      &__actions {
        &__rotate,
        &__zoom {
          display: none !important;
        }

        &__close {
          margin-left: 8px;
        }
      }

    }

    &__gallery {
      justify-content: start;
      overflow: auto;
    }
  }
}
