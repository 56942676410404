@import "../variables";
@import "../mixins";

@invite-modal-width: 760px;
@remove-row-button-size: @auth-form-text-height - 4;

.invite {

  .modal-dialog {
    max-width: @invite-modal-width;
  }

  .modal-header {
    margin-bottom: 2*@offset-lg;

    h3 {
      width: 100%;
      text-align: center;
    }
  }

  .modal-footer {
    margin-top: 2*@offset-lg;
  }

  .invite-form {
    border: none;

    .form-hint {
      margin-top: -@offset-lg;
      margin-bottom: 0;
    }

    .input-hint--error {
      margin-left: 0;
    }

    .input-hint--info {
      margin-left: 0;
    }

    .fieldset {
      margin-top: 0;

      label {
        display: none;
      }

      input {
        border-width: 1px;

        &:focus {
          outline: none;
          border-width: 2px;
        }
      }
    }

    .selectize-control {
      max-width: 100%;

      input {
        border: none;
        height: 100%;
        max-width: 290px;
        .no-focus();
      }

      .selectize-dropdown .dropdown-list > ul > li header > div.empty {
        margin-left: 0;
      }
    }

    .remove-row-btn {
      padding: 0;
      margin: 0;
      line-height: 1;
      height: auto;
      font-size: @remove-row-button-size;
      color: @color-secondary-copy;
    }

    .add-row-btn {
      padding: 0;
      color: @color-blue;
    }
  }

  .invite-form-header {
    display: flex;
    margin-top: @offset-md;
    margin-right: @remove-row-button-size + (@remove-row-button-size * .4);
    padding-bottom: (@offset-md / 2);
    font-size: @auth-form-label-size;
    font-family: @font-family-regular;
  }

  .invite-form-rows {
    margin-bottom: @offset-md;
  }

  .invite-form-row {
    display: flex;
    position: relative;
    margin-right: @remove-row-button-size + (@remove-row-button-size * .4);

    & + .invite-form-row {
      margin-top: @offset-md;
    }
  }

  .invite-form-col {
    @invite-form-col-width: 28%;
    width: @invite-form-col-width;

    & + .invite-form-col {
      padding-left: @offset-md;
    }

    &__groups {
      width: 100% - (@invite-form-col-width * 2);
    }
  }

  .invite-form-row-action {
    position: absolute;
    top: 1px;
    left: 100%;
  }

  @media only screen and (max-width: @tablet-portrait-size) {
    .modal-dialog {
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: @phone-portrait-size) {
    .modal-header {
      h3 {
        text-align: left;
      }
    }

    .modal-body {
      .invite-form {
        .input-hint--error, .input-hint--info {
          margin-left: 0;
        }

        .fieldset {
          display: block;

          & > input {
            max-width: 100%;
            min-width: 100%;
          }

          label {
            display: block;
            width: 100%;
            margin-bottom: @offset-xs;
          }
        }

        .selectize-control {
          max-width: 100%;
          min-width: 100%;

          input {
            max-width: 240px;
          }
        }
      }
    }

    .invite-form-header {
      display: none;
    }

    .invite-form-row {
      display: block;

      & + .invite-form-row {
        margin-top: @offset-md * 3;
      }

      .invite-form-col {
        width: auto;

        & + .invite-form-col {
          margin-top: @offset-md;
          padding-left: 0;
        }
      }
    }

    .invite-form-row-action {
      top: @auth-form-label-size + (@offset-md / 2) + 1;
    }
  }
}
