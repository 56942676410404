.tooltip-wrapper {
  display: inline-block;
  position: relative;

  // Disabled button will not broadcast mouseEnter event to parent element
  // and we create placeholder to catch mouse events
  &.with-tooltip {
    &:hover {
      cursor: not-allowed;
    }

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.help-tooltip-wrapper {
  display: inline-table;

  &:hover {
    .help-tooltip {
      visibility: visible;
    }
  }
}

.hide-tooltip {
  visibility: hidden !important;
}

.help-tooltip {
  visibility: hidden;
  .native-size;
  position: fixed;
  z-index: 1200;
  align-items: center;
  color: @color-white;
  background: rgba(0, 0, 0, .7);
  padding: @offset-sm @offset-md;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  font-size: @font-size-sm;
  text-align: left;
  max-width: 290px;

  span {
    max-width: 100%;
    word-wrap: break-word;
    white-space: normal;
    line-height: 18px;
    display: inline-block;
    border: none;
    background: transparent;
    border-radius: 0;
    transition: none;

    ul {
      li {
        list-style: disc;
        margin: 3px 0 3px 15px;
        color: @color-white;
        font-size: @font-size-sm;
        font-family: @font-family-regular;
      }
    }
  }

  @help-tooltip-arrow-size: 11px;

  .arrow {
    border-bottom: 10px solid rgba(0, 0, 0, .7);
    display: block;
    border-right: @help-tooltip-arrow-size solid transparent;
    border-left: @help-tooltip-arrow-size solid transparent;
    width: 2*@help-tooltip-arrow-size;
    height: 10px;
    overflow: hidden;
    position: fixed;
  }

  &.disabled, &.white {
    background-color: @color-white;
    color: @color-main-copy;

    .arrow {
      border: none;
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      transform: rotate(45deg) translate(4px);
      background-color: @color-white;
      height: 16px;
      width: 16px;
    }
  }

  &.visible {
    visibility: visible;
  }

  &.tooltip-right {
    right: 8px !important;
    left: auto !important;

    & > .arrow {
      right: 46px;
      left: auto;
    }
  }
}
