@import "../../../less/variables";

.template-schedule-modal {
  max-width: 800px;

  .template-schedule-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;

    .avatar {
      display: inline-block;
      margin-right: -6px;
      font-size: 24px;
      color: #40484c;
      transform: scale(.6);

      &.default {
        background-color: @color-grey;
      }
    }
  }
}

.template-schedule-form {
  padding-top: 8px;
  @label-width: 160px;

  .form-hint.error {
    margin-bottom: 24px;
  }

  .form-group {
    align-items: start;

    &.starter {
      margin-top: 0;
    }

    & > label,
    & > .label {
      margin-top: 8px;
      min-width: @label-width;
      flex: 0 1 @label-width;
      font-family: @font-family-medium;

      .icon-template_create_field_required {
        font-size: @font-size-xs;
        margin-left: @offset-xs;
        transform: translateY(-1px);
      }
    }

    .number-input {
      width: 40px;
      flex: 0 1 40px;
    }

    .field-value-checkbox {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .checkbox {
        margin-right: 5px;
      }

      & > label {
        flex: 0 1 40px;
        min-width: unset;
        width: unset;
        text-transform: capitalize;
        font-family: @font-family-regular;
      }
    }

    .react-selectize,
    .date-picker-input,
    .read-only {
      min-width: 190px;
    }

    .read-only,
    .static {
      display: inline-block;
      line-height: 32px;
      height: 32px;
    }

    .read-only {
      padding: 0 10px;
      color: @color-main-copy;
      background: @color-panel-grey;
      border: none;
      font-family: @font-family-semibold;
      border-radius: 4px;
    }

    &.repeat-settings {

      &:not(.repeat-error) {
        .field-value {
          display: flex;
          flex-wrap: wrap;
        }
      }

      &.repeat-error {
        .select-dropdown__control {
          border-color: @color-red !important;

          &--is-focused {
            box-shadow: 0 0 0 1px @color-red !important;
          }
        }
      }
    }
  }

  .repeat-control {
    display: flex;
    align-items: center;
    height: 32px;

    & > span {
      margin: 0 5px;
    }

    &.has-error {
      input, textarea {
        border-color: @color-red;
        box-shadow: none;

        &:focus, &.active {
          border-color: @color-red;
          box-shadow: 0 0 0 1px @color-red;
        }
      }
    }
  }

  .repeat-control-block {
    width: 100%;
    margin-top: 10px;

    input[type=radio] {
      margin-right: 10px;

      & + span {
        margin-left: 0;
      }
    }

    .select-dropdown {
      margin-right: 10px;
    }

    &.disabled {
      color: @color-grey;
    }
  }

  .form-actions {
    margin-top: 48px;
    display: flex;
    align-items: center;

    .main-actions {
      flex-grow: 3;
      display: flex;
      justify-content: center;

      button + button {
        margin-left: 12px;
      }
    }

    .additional-actions {
      .btn-icon {
        color: @color-secondary-copy;
        border: 1px solid @border-button-gray-color;
        border-radius: 50%;
        width: 32px;
        height: 32px;

        &:hover {
          color: @color-blue;
          border-color: @color-blue;
        }
      }
    }
  }

  .next-run {
    .failed-run {
      margin-left: 20px;
      color: @color-red
    }
  }
}


