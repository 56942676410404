.template-section-types {
  margin: 0 @offset-md 0 @offset-xs;

  .handler {
    font-family: @font-family-medium;
    color: @site-menu-color;
    text-decoration: none;
    position: relative;
    border: 1px @site-menu-color solid;
    cursor: pointer;
    background-color: transparent;
    border-radius: @buttons-border-radius;
    transition: all .2s ease;
    padding: 5px 8px;
    height: 32px;
    display: flex;
    align-items: center;

    &:hover {
      color: @color-blue;
      border-color: @color-blue;
    }

    i {
      font-size: 16px;

      &.icon-common_arrow_down {
        font-size: 4px !important;
      }
    }
  }
}

.section-types-drop {
  background-color: @color-panel-grey;
  box-shadow: 0 2px 5px @gray-light-color;
  z-index: @z-index-over-content;
  top: @offset-sm !important;

  &.section-types-drop-open {
    .section-types-drop-content {
      display: block;
    }
  }

  .section-types-drop-content {
    display: none;
    min-width: 90px;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0,0,0,0.15);

    li {
      &.separated {
        border-top: 1px solid #e3e3e3;
      }

      a {
        font-size: @font-size-md;
        font-family: @font-family-regular;
        color: @color-main-copy;
        padding: @offset-sm;
        display: flex;
        align-items: center;
        white-space: nowrap;
        min-width: 105px;

        i {
          color: @color-secondary-copy;
          font-size: @font-size-lg;
          margin-right: @offset-md;
        }

        &:hover {
          color: @color-blue;
        }
        &.active {
          i {
            color: @color-green;
          }
        }
      }
    }
  }
}