@import "../../../less/variables";
@import "../../../less/mixins";

.admin-info-popup {
  display: inline-flex;
  align-items: center;
  .native-size;
  position: absolute;
  z-index: @z-index-content;
  background: rgba(74, 188, 59, .8);
  padding: @offset-lg;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
  min-width: 286px;
  color: @color-white;
  cursor: default;
  right: @site-padding;
  top: -52px;
  .no-focus();

  & > .icon-common_close {
    color: @color-white;
    opacity: .7;
    position: absolute;
    top: @offset-md;
    right: @offset-md;
    font-size: @font-size-sm;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  & > .icon-toaster_mail {
    margin-right: @offset-md;
    flex: 0 1 auto;
    font-size: @font-size-xlg;
  }

  div {
    flex: 0 1 auto;

    header {
      color: @color-white;
      font-family: @font-family-bold;
      font-size: @font-size-sm;
      white-space: nowrap;
      max-width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 20px;
    }

    a {
      color: @color-white;
      text-decoration: underline;
      margin-top: @offset-xs;
      cursor: pointer;
      font-size: @font-size-sm;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
