@import "../../../less/variables";
@import "../../../less/mixins";

@adaptive-modal-width: 760px;
@adaptive-modal-margin: 90px;

.adaptive-modal {
  width: 100%;
  max-width: @adaptive-modal-width;
  margin: 0 auto @adaptive-modal-margin;
  padding-top: @adaptive-modal-margin;
  transition: all .4s ease;
  .no-focus();

  .modal-content {
    position: relative;
    background-color: @color-white;
    box-shadow: 0 0 8px @color-grey;

    .modal-header {
      min-height: 50px;
      background: #fff;
      position: relative;
      padding: 2*@offset-lg;
      padding-bottom: 0;
    }

    .modal-body {
      padding: @offset-lg 2*@offset-lg 2*@offset-lg;
    }
  }

  .modal-header.fixed {
    margin: 0 auto;
    display: none;
    position: fixed;
    width: 80%;
    top: 0;
  }

  &.fixed-header {
    .modal-header.fixed {
      display: block;
      z-index: 100;
    }
  }
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}
