.form-group.has-error, .has-error {
  .selectize-control {
    & > .selectize-input, & > .selectize-input.active {
      border-color: @color-red;
    }
  }
}

.selectize-control {
  width: 100%;
  flex: 0 1 100%;
  position: relative;
  background: @color-white;

  .icon-assignee_process_starter, .icon-assignee_process_manager {
    font-size: @font-size-md !important;
  }

  .selectize-input {
    width: 100%;
    min-height: @auth-form-text-height;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .input-border;
    border-width: 1px;
    .no-focus();

    &.active {
      border-color: @color-blue;
      border-width: 2px;
      margin: -1px 0;
      z-index: @z-index-over-content;
      &.error {
        border-color: @color-red;
      }
    }
    &.error {
      border-color: @color-red;
    }

    & > ul {
      .native-size;
      background: @color-white;
      display: flex;
      flex-wrap: wrap;
      padding-right: 2*@offset-md;
      max-width: 100%;
      border-radius: 4px;

      & > li {
        line-height: 2*@offset-md;
        max-height: 2*@offset-md;
        overflow: hidden;
        display: inline-flex;
        flex: 0 1 auto;
        align-items: center;
        margin: 3px;
        padding: 0;

        &.item {
          border-radius: 8px;
          background-color: @color-light-grey;
          font-size: @font-size-sm;
          cursor: pointer;

          & > span {
            display: inline-block;
            white-space: nowrap;
            max-width: calc(100% - (5 * @offset-sm));
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 4px;
          }
        }

        .thumbnail {
          line-height: @thumbnail-size;
          height: @thumbnail-size;
          width: @thumbnail-size;
          min-width: @thumbnail-size;
          border-radius: @thumbnail-border-radius;
          text-align: center;
          text-transform: uppercase;
          color: @color-white;
          position: relative;

          & > img {
            width: 100%;
            height: 100%;
          }

          .badge-deleted {
            height: @font-size-md;
            line-height: @font-size-md - 1;
            width: @font-size-md;
            border-radius: 100%;
            background-color: @color-red;
            position: absolute;
            text-align: center;
            bottom: 0;
            right: -4px;

            i {
              font-size: 8px;
              margin: 0 0 0 1px;
              color: @color-white;
            }
          }
        }

        img {
          height: @thumbnail-size;
          width: @thumbnail-size;
          min-width: @thumbnail-size;
          border-radius: @thumbnail-border-radius;
          margin-right: 5px;
        }

        i {
          color: #a3a3a3;

          &.icon-common_close {
            font-size: @font-size-icon;
            line-height: @font-size-icon;
            margin: 0 6px 0 3px;
            .no-focus();

            &:hover {
              color: @color-main-copy;
            }
          }
        }
      }
    }

    &.active > ul li:first-child {
      margin-left: 2px;
    }

    .clear-all {
      position: absolute;
      top: 7px;
      right: 5px;
      background-color: rgb(204,204,204);
      color: @color-white;
      font-size: 6px;
      padding: 4px 3px;
      border-radius: 100%;
      cursor: pointer;
      .no-focus();
    }

    &.active .clear-all {
      right: 4px;
    }

    .select-input-ctrl {
      @height: 30px;
      display: inline-block;
      flex: 0 1 auto;
      border: none;
      box-shadow: none;
      padding: 0;
      margin: 0 6px !important;
      height: @height;
      min-height: @height;
      line-height: @height;
      font-size: @font-size-sm;
      resize: none;
      white-space: nowrap;
      overflow: hidden;
      .no-focus();
    }
  }

  .selectize-error {
    color: @color-red;
    font-family: @font-family-regular;
    font-size: @font-size-sm;
    position: absolute;
    margin-left: 2px;
  }

  .selectize-dropdown {

    .dropdown-list {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      max-height: 200px;
      overflow: auto;
      margin-top: 2px;
      padding: 10px 10px 0;
      background-color: @color-white;
      z-index: @z-index-over-content;

      .no-result {
        font-size: @font-size-sm;
        color: @color-secondary-copy;
        padding: 6px 0;
        text-align: center;

        a {
          color: @color-blue;
          cursor: pointer;
        }
      }

      &.active {
        display: block;
        box-shadow: 0 2px 8px @color-dark-grey;
      }

      & > ul {
        .native-size;
        padding-bottom: @offset-sm;

        & > li {
          font-size: @font-size-md;

          & + li {
            margin-top: @offset-sm;
          }

          span.highlighted {
            background-color: rgba(249, 216, 60, .37);
          }

          &.allUsers {
            padding-bottom: @offset-sm;
            border-bottom: 1px solid @color-pale-blue;
            margin-left: 26px;

            header > .empty {
              margin-left: 0;
            }
          }

          &.role {
            display: table;
            width: 100%;
            .no-focus();

            &.tree {
              width: calc(100% - 26px);
              margin-left: 26px;
            }

            &.last {
              padding-bottom: @offset-sm;
              border-bottom: 1px solid @color-pale-blue;
            }

            div {
              .inline-flex;
              padding-right: 8px;
              border-radius: @thumbnail-border-radius;
              cursor: pointer;
              line-height: @thumbnail-size;
              font-family: @font-family-semibold;
              .no-focus();

              &.active {
                background-color: @color-light-grey;
              }

              span:not(.text):not(.highlighted):not(.usersCount) {
                line-height: @thumbnail-size;
                width: @thumbnail-size;
                min-width: @thumbnail-size;
                border-radius: @thumbnail-border-radius;
                text-align: center;
                background-color: @color-light-grey;
                color: @color-add-grey;
                display: inline-block;
                margin-right: 8px;

                i {
                  font-size: @font-size-sm;
                  margin-top: -1px;
                  display: block;
                }
              }
            }
          }

          &.user {
            //display: table;
            .no-focus();

            div {
              .inline-flex;
              padding-right: 8px;
              border-radius: @thumbnail-border-radius;
              cursor: pointer;
              font-family: @font-family-semibold;
              .no-focus();
              max-width: 100%;

              &.active {
                background-color: @color-light-grey;
              }

              .thumbnail {
                line-height: @thumbnail-size;
                width: @thumbnail-size;
                min-width: @thumbnail-size;
                border-radius: @thumbnail-border-radius;
                color: @color-white;
                padding-right: 0;
                margin-right: 5px;
                font-size: @font-size-xs;
                text-transform: uppercase;

                span {
                  margin: 0 auto;
                }
              }

              .user-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                flex-shrink: 0;
                max-width: 85%;
              }

              .user-email {
                display: inline-block;
                color: #a3a3a3;
                font-size: 12px;
                margin-left: 5px;
                margin-top: 2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          header {
            .inline-flex;

            & > i {
              color: @color-add-grey;
              margin-right: 3px;
              cursor: pointer;
              min-width: 20px;
              .no-focus();
            }

            & > div {
              .inline-flex;
              padding-right: 8px;
              border-radius: @thumbnail-border-radius;
              cursor: pointer;
              font-family: @font-family-semibold;
              .no-focus();

              &.empty {
                margin-left: 26px;
              }

              &.active {
                background-color: @color-light-grey;
              }

              span:not(.text):not(.highlighted):not(.usersCount) {
                line-height: @thumbnail-size;
                width: @thumbnail-size;
                min-width: @thumbnail-size;
                border-radius: @thumbnail-border-radius;
                text-align: center;
                background-color: @color-light-grey;
                color: @color-add-grey;
                display: inline-block;
                margin-right: 8px;
              }

              span.usersCount {
                color: @color-add-grey;
                font-size: @font-size-sm;
              }
            }
          }

          .users {
            margin-left: 40px;

            & > li {
              //display: table;
              margin-top: 6px;
              .no-focus();

              .user {
                display: inline-flex;
                font-size: @font-size-sm;
                color: @color-main-copy;
                align-items: center;
                justify-content: flex-start;
                font-family: @font-family-semibold;
                padding-right: 8px;
                border-radius: @thumbnail-border-radius;
                cursor: pointer;
                max-width: 100%;

                &.active {
                  background-color: @color-light-grey;
                }

                .user-name {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  flex-shrink: 0;
                  max-width: 75%;
                }

                .user-email {
                  display: inline-block;
                  color: #a3a3a3;
                  font-size: 12px;
                  margin-left: 5px;
                  margin-top: 2px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .thumbnail {
                line-height: @thumbnail-size;
                width: @thumbnail-size;
                min-width: @thumbnail-size;
                border-radius: @thumbnail-border-radius;
                text-align: center;
                text-transform: uppercase;
                color: @color-white;
                margin-right: 8px;
                font-size: @font-size-xs;
              }

              img {
                height: @thumbnail-size;
                width: @thumbnail-size;
                min-width: @thumbnail-size;
                border-radius: @thumbnail-border-radius;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  .selectize-background {
    z-index: @z-index-content;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: auto;
    .no-focus();
  }

  .hint {
    margin-top: 4px;
    font-size: @font-sm-size;
    color: @color-secondary-copy;
  }
}
