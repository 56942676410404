@import "../variables";
@import "../mixins";

.assign-modal {
  .modal-body {
    main {
      .users {
        width: 100%;
        margin-top: 6px;
        padding: @offset-sm;
        border-radius: 0;
        border: 1px solid @color-grey;
        overflow: auto;
        height: 250px;
        max-height: 250px;
        position: relative;

        & > li {
          &.user {
            display: block;

            & + li {
              & > div {
                margin-top: @offset-xs;
              }
            }

            & > div {
              display: inline-flex;
              font-size: @font-size-sm;
              font-family: @font-family-semibold;
              color: @color-main-copy;
              align-items: center;
              justify-content: flex-start;
              cursor: pointer;
              border-radius: @thumbnail-border-radius;
              padding-right: @offset-sm;
              .no-focus();

              &:hover,
              &.active {
                background-color: @color-light-grey;
              }
            }

            & + .empty-state {
              display: none;
            }
          }

          &.empty-state {
            font-size: @font-size-sm;
            color: @color-secondary-copy;
            padding: 6px 0;
            text-align: center;

            a {
              color: @color-blue;
            }
          }

          .thumbnail {
            line-height: @thumbnail-size;
            width: @thumbnail-size;
            min-width: @thumbnail-size;
            border-radius: @thumbnail-border-radius;
            text-align: center;
            text-transform: uppercase;
            color: @color-white;
            margin-right: 5px;
            font-size: @font-size-xs;
          }

          img {
            height: @thumbnail-size;
            width: @thumbnail-size;
            min-width: @thumbnail-size;
            border-radius: @thumbnail-border-radius;
            margin-right: 5px;
          }

          &.last {
            padding-bottom: @offset-sm;
            margin-bottom: (@offset-sm / 2);
            border-bottom: 1px solid @color-pale-blue;
          }
        }
      }

      form {
        .icon-common_remove {
          color: rgb(204,204,204);
          font-size: @font-size-md;
          margin-left: -2*@offset-md;
          cursor: pointer;
          .no-focus();

          &:hover {
            color: @color-secondary-copy;
          }
        }
      }
    }
  }

  @media only screen and (max-width: @phone-landscape-size) {
    overflow: auto !important;

    .modal-dialog {
      margin-top: 20px !important;
    }
  }
}
