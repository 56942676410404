@import "../../../../less/variables";
@import "../../../../less/mixins";

section.errors-strip-alt {
  font-size: @font-size-sm;
  border: 1px solid @color-red;
  background-color: @color-white;
  display: flex;
  align-items: stretch;

  .offset {
    max-width: 80px;
    padding-right: @offset-sm;
    background-color: @color-red;
    vertical-align: middle;
    color: @color-white;
    padding-left: @site-padding;
    display: inline-flex;
    align-items: center;

    i {
      font-size: 22px;
    }
  }

  .content {
    color: @color-red;
    padding: @offset-md @site-padding @offset-md 2*@offset-sm;
    font-size: @font-size-sm;

    @li-line-height: 18px;

    ul {
      margin: 0;
      padding-left: @offset-lg;
      max-height: 3*@li-line-height + 2*@offset-sm;
      overflow: hidden;

      & + p {
        margin: @offset-xs;
      }

      & > li {
        line-height: @li-line-height;
        min-height: 20px;
        color: @color-red;
        list-style: disc;

        & + li {
          margin-top: @offset-xs;
        }

        a {
          color: @color-red;
          display: inline-block;
          border: 1px solid @color-red;
          border-radius: 16px;
          padding: 0 8px;

          &:active {
            background: @color-red;
            color: @color-white;
          }
        }
      }
    }

    h5 {
      color: @color-red;
      font-family: @font-family-bold;
      font-size: @font-size-sm;
    }
    p {
      color: @color-red;
      text-decoration: underline;
    }
  }

  &.ng-hide-add, &.ng-hide-remove {
    transition: all linear 0.2s;
  }
  &.ng-hide {
    opacity: 0;
  }
}

section.errors-strip {
  color: @color-white;
  font-size: @font-size-sm;
  height: 2*@offset-lg;
  line-height: 2*@offset-lg;
  padding: 0 @site-padding;
  background-color: @color-red;

  &.ng-hide-add, &.ng-hide-remove {
    transition: all linear 0.2s;
  }
  &.ng-hide {
    opacity: 0;
  }
}

section.errors {
  color: @color-red;
  font-size: @font-size-sm;
  padding: 2px 11*@offset-sm;
  position: absolute;
  top: 41px;

  &.process-start {
    padding: @offset-sm @site-padding;
  }
}

div.errors {
  font-size: @font-size-sm;
  color: @color-red;
  margin-top: -8px;
  margin-bottom: @offset-xs;

  &.main-textarea {
    margin-top: 3px;
    padding-left: @label-base-width;
  }
}
