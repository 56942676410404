.notification-bar {
  background-color: @color-yellow;
  min-height: 40px;
  width: 100%;
  padding: 0 @site-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5c427;
  position: relative;

  &.billing {
    background-color: @color-red;
    border-bottom-color: #b10909;

    p, a {
      color: @color-white;
    }
  }

  &__info {
    display: inline-flex;
    align-items: center;
    padding-left: 52px;

    img {
      position: absolute;
      left: 2*@offset-lg;
      bottom: 0;
    }

    p {
      font-size: @font-size-md;
      padding: @offset-sm 0;

      a {
        text-decoration: underline;
        color: @color-main-copy;
      }
    }
  }

  &__action {
    margin-left: @offset-sm;
  }

  @media only screen and (max-width: @phone-portrait-size) {
    padding: 0 @offset-lg;

    &__info {
      img {
        left: @offset-lg;
      }
      p {
        font-size: @font-size-sm;
      }
    }
  }
}
