@import "../../../less/variables";
@import "../../../less/mixins";

.profile__main {
  height: 100%;
  @media only screen and (max-width: @tablet-portrait-size) {
    &__header {
      padding: 0 2*@offset-md;
    }

    &__content {
      .content-wrapper {
        padding: 2*@offset-md;
      }
    }
  }

  @media only screen and (max-width: @phone-portrait-size) {
    &__header {
      padding: 0 @offset-md;

      &__nav {
        & > li > a {
          & > i:first-child {
            margin-right: 1px;
          }
          & > span {
            display: none;
          }
        }
      }
    }
    &__content {
      padding: 0;

      .content-wrapper {
        .email-group .actions {
          height: @offset-lg;
          margin-left: 0;
        }
        .profile-pass-form {
          min-width: 100%;
        }

        .notifications {
          display: block;

          .first-col, .second-col {
            width: 100%;
            flex: 0 1 100%;
          }
          .second-col {
            margin-top: @offset-lg;
          }
        }

        .photo {
          & > p {
            display: none;
          }
          & + p {
            margin-top: @offset-sm;
            display: block;
          }
        }
      }
      .form {
        .input-hint--error {
          margin: 0;
        }
        .input-hint--info {
          margin: 0;
        }

        .form-group {
          display: block;

          label {
            margin-bottom: 4px;
            width: 100%;
          }

          input {
            max-width: 100% !important;
          }

          .select-timezone {
            min-width: 100%;
          }
        }

        & > footer {
          .btn:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
