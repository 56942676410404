.date-picker-input {
  position: relative;

  i {
    position: absolute;
    top: 7px;

    &.icon-task_due_date_add_small {
      font-size: 18px;
      color: #cacaca;
      cursor: pointer;
      left: 8px;
    }

    &.icon-common_remove {
      color: #cccccc;
      font-size: 14px;
      cursor: pointer;
      right: 8px;
      top: 8px;
    }
  }

  input {
    appearance: none;
    flex: 0 1 100%;
    width: 100%;
    padding: 8px 24px 8px 7px;
    border: 1px #cacaca solid;
    border-radius: 4px;
    box-shadow: none;
    color: #222;
    resize: none;
    outline: 0;
    cursor: pointer;
  }

  .icon-task_due_date_add_small + input {
    padding-left: 32px;
  }
}
