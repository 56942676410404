/* Primary colors */
/* Font-family */
/* Secondary colors */
/* Primaty offsets */
/* Font-sizes */
/* Thumbnail */
/* Forms */
/* Input sizes */
/* Z-indexes */
/*
 * FROM /modules/home/less/variables
 */
/* Site menu */
/* Tasks main */
/* Tasks list */
/*
 * FROM /modules/auth/less/variables
 */
/*
 * FROM /modules/processes/less/variables
 */
.inline-flex {
  display: inline-flex;
  align-items: center;
}
.native-size {
  padding: 0;
  margin: 0;
}
.input-border {
  border: 2px #cacaca solid;
  border-radius: 4px;
}
section.errors-strip-alt {
  font-size: 12px;
  border: 1px solid #f75f5f;
  background-color: #fff;
  display: flex;
  align-items: stretch;
}
section.errors-strip-alt .offset {
  max-width: 80px;
  padding-right: 8px;
  background-color: #f75f5f;
  vertical-align: middle;
  color: #fff;
  padding-left: 32px;
  display: inline-flex;
  align-items: center;
}
section.errors-strip-alt .offset i {
  font-size: 22px;
}
section.errors-strip-alt .content {
  color: #f75f5f;
  padding: 12px 32px 12px 16px;
  font-size: 12px;
}
section.errors-strip-alt .content ul {
  margin: 0;
  padding-left: 16px;
  max-height: 70px;
  overflow: hidden;
}
section.errors-strip-alt .content ul + p {
  margin: 4px;
}
section.errors-strip-alt .content ul > li {
  line-height: 18px;
  min-height: 20px;
  color: #f75f5f;
  list-style: disc;
}
section.errors-strip-alt .content ul > li + li {
  margin-top: 4px;
}
section.errors-strip-alt .content ul > li a {
  color: #f75f5f;
  display: inline-block;
  border: 1px solid #f75f5f;
  border-radius: 16px;
  padding: 0 8px;
}
section.errors-strip-alt .content ul > li a:active {
  background: #f75f5f;
  color: #fff;
}
section.errors-strip-alt .content h5 {
  color: #f75f5f;
  font-family: 'Roboto-Bold', Arial;
  font-size: 12px;
}
section.errors-strip-alt .content p {
  color: #f75f5f;
  text-decoration: underline;
}
section.errors-strip-alt.ng-hide-add,
section.errors-strip-alt.ng-hide-remove {
  transition: all linear 0.2s;
}
section.errors-strip-alt.ng-hide {
  opacity: 0;
}
section.errors-strip {
  color: #fff;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 32px;
  background-color: #f75f5f;
}
section.errors-strip.ng-hide-add,
section.errors-strip.ng-hide-remove {
  transition: all linear 0.2s;
}
section.errors-strip.ng-hide {
  opacity: 0;
}
section.errors {
  color: #f75f5f;
  font-size: 12px;
  padding: 2px 88px;
  position: absolute;
  top: 41px;
}
section.errors.process-start {
  padding: 8px 32px;
}
div.errors {
  font-size: 12px;
  color: #f75f5f;
  margin-top: -8px;
  margin-bottom: 4px;
}
div.errors.main-textarea {
  margin-top: 3px;
  padding-left: 80px;
}
.template__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: #fff;
  padding: 0 32px;
  position: relative;
  border-bottom: 1px solid #e1ebef;
}
.template__header__info {
  display: flex;
  align-items: center;
  color: #9d9d9d;
  flex: 0 1 100%;
}
.template__header__info.process-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.template__header__info.process-start > label {
  color: #959595;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.template__header__info .choose-icon {
  flex: 0 1 40px;
  line-height: 40px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  overflow: hidden;
  border: 2px dashed #9d9d9d;
  border-radius: 13px;
  display: block;
  margin-right: 12px;
  cursor: pointer;
}
.template__header__info .choose-icon:hover {
  border-color: #2e9df0;
}
.template__header__info .choose-icon:hover i {
  color: #2e9df0;
}
.template__header__info .choose-icon i {
  display: block;
  height: 40px;
  font-size: 46px;
  margin-left: 6px;
  padding-top: 9px;
}
.template__header__info .choose-icon--active {
  margin: 0 12px 0 0;
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  height: 40px;
  border-radius: 13px;
  vertical-align: middle;
  cursor: pointer;
  color: #222;
  display: block;
}
.template__header__info .choose-icon--active i {
  display: block;
  text-align: center;
  margin: 0 auto;
  font-size: 24px;
}
.template__header__info .choose-icon--active i.icon-Magnifi-Glass2 {
  font-size: 22px;
}
.template__header__info .choose-icon--active i.icon-Mens {
  font-size: 26px;
}
.template__header__info .choose-icon--active i::before {
  margin: 0;
}
.template__header__info input {
  background: transparent;
  color: #222;
  font-family: 'Roboto-Regular', Arial;
  font-size: 21px;
  border: none;
  width: 100%;
  margin: -5px 10px 0 -3px;
  padding: 1px 13px 0 3px;
}
.template__header__info input:focus {
  outline: none;
}
.template__header__info input::-ms-clear {
  display: none;
}
.template__header__info input:hover {
  border: 1px #cacaca solid;
  border-radius: 4px;
  padding-left: 2px;
  cursor: pointer;
}
.template__header__info input:focus {
  cursor: inherit;
  padding-left: 3px;
}
.template__header__info input:focus:hover {
  border: none;
}
.template__header__btns {
  flex: 0 1 auto;
  white-space: nowrap;
}
.template__header__btns .btn-switcher {
  border: 1px solid #cdcdcd;
  color: #cdcdcd;
  background: transparent;
  margin-left: 10px;
}
.template__header__btns button.btn-template-edit:not(.disabled) {
  background-color: #4abc3b !important;
  color: #fff !important;
}
.template__header__btns button.btn-template-edit:not(.disabled):hover {
  background-color: #50d83e !important;
  color: #fff !important;
}
.template__header > .errors {
  color: #f75f5f;
  font-size: 12px;
  padding: 2px 0 0 52px;
  position: absolute;
  top: 41px;
  left: 34px;
}
.template__header > .errors.process-start {
  padding: 8px 32px;
}
.template__section__process-details {
  padding: 16px 24px 100px 32px;
  position: relative;
  min-height: 100%;
}
.template__section__process-details__tabs {
  margin-top: 0;
}
.template__section__process-details__tabs li + li {
  margin-left: 12px;
}
.template__section__process-details.ng-hide-add,
.template__section__process-details.ng-hide-remove {
  transition: all linear 0.2s;
}
.template__section__process-details.ng-hide {
  opacity: 0;
}
.template__section__process-details > .content {
  width: 100%;
}
.template__section__process-details > .content > div .hint {
  margin-left: 174px;
  font-size: 12px;
  color: #959595;
}
.template__section__process-details > .content > div .errors {
  margin-top: 2px;
  margin-left: 174px;
}
.template__section__process-details > .content > div > .form-group {
  align-items: flex-start;
  max-width: 770px;
}
.template__section__process-details > .content > div > .form-group:not(.m-start) > label {
  padding-top: 8px;
}
.template__section__process-details > .content > div > .form-group .g-radio-group {
  width: 100%;
}
.template__section__process-details > .content > div > .form-group .g-radio-group .g-radio {
  display: inline-block;
}
.template__section__process-details > .content > div > .form-group > label {
  flex: 0 1 170px;
  min-width: 170px;
  width: 170px;
  display: flex;
  justify-content: space-between;
}
.template__section__process-details > .content > div > .form-group > label i.icon-template_create_field_required {
  font-size: 10px;
  vertical-align: top;
}
.template__section__process-details > .content > div > .form-group > .selectize-control {
  flex: 0 1 calc(100% - 170px);
  max-width: calc(100% - 170px);
}
.template__section__process-details > .content > div > .form-group > .selectize-control .selectize-dropdown .dropdown-list {
  z-index: 7;
}
.template__section__process-details > .content > div > .form-group > .process-name-control {
  flex: 0 1 calc(100% - 170px);
  max-width: calc(100% - 170px);
}
.template__section__process-details > .content > div > .instructions-group > textarea {
  min-height: 110px;
}
.template__section__process-details > .content > div > .instructions-group.active + markdown-hint {
  visibility: visible;
}
.template__section__process-details > .content > div > .instructions-group .errors {
  margin-left: 0 !important;
}
.template__section__process-details > .content > div > .errors {
  margin-top: 2px;
  margin-left: 170px;
}
.template__section {
  padding: 16px 32px 0;
  height: calc(100% - (4 * 16px + 2px));
  max-height: calc(100% - (4 * 16px + 2px));
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.template__section.process-section > .form-group > label {
  flex: 0 1 140px;
  width: 140px;
}
.template__section.process-section .selectize-control,
.template__section.process-section textarea,
.template__section.process-section .process-name-control,
.template__section.process-section .task-name-control {
  flex: 0 1 calc(100% - 140px);
  width: calc(100% - 140px);
}
.template__section.process-section markdown-hint > .markdown-hint {
  margin-left: 140px;
}
.template__section > .form-group.m-start {
  flex: 1 0 auto;
}
.template__section > .form-group > label {
  flex: 0 1 120px;
  width: 120px;
}
.template__section > .form-group.active + markdown-hint {
  visibility: visible;
}
.template__section > .form-group .selectize-control,
.template__section > .form-group textarea,
.template__section > .form-group .process-name-control,
.template__section > .form-group .task-name-control {
  flex: 0 1 calc(100% - 120px);
  width: calc(100% - 120px);
}
.template__section > .errors {
  margin-left: 120px;
  margin-top: 2px;
}
.template__section markdown-hint {
  visibility: hidden;
}
.template__section markdown-hint .markdown-hint {
  margin-left: 120px;
}
.template__section__tabs {
  min-height: 32px;
  margin-bottom: 36px;
}
.template__section__tabs .btn-switcher + .btn-switcher {
  margin-left: 12px;
}
.template__section > main {
  max-height: calc(100% - 94px);
  height: 100%;
}
.template__section.invalid {
  height: calc(100% - (4 * 16px + 2 * 16px + 2px));
  max-height: calc(100% - (4 * 16px + 2 * 16px + 2px));
}
.template__section .template-content-wrapper {
  display: flex;
  align-items: flex-start;
  max-height: calc(100% - (2 * 16px + 2 * 12px));
  height: calc(100% - (2 * 16px + 2 * 12px));
  position: relative;
}
.template__section .template-content-wrapper.error {
  max-height: calc(100% - (2 * 16px + 90px));
}
.template__section .template-content-wrapper > aside {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.template__section .template-content-wrapper > aside.active {
  border: 1px solid #e1ebef;
}
.template__section .template-content-wrapper > main {
  padding: 0;
  margin: 0;
  max-height: 100%;
  width: 50%;
  min-width: 50%;
  flex: 0 1 50%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-y-scrolling: touch;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.template__section .template-content-wrapper > main.active {
  margin-right: -17px;
  padding-right: 16px;
  height: 100%;
  position: relative;
  z-index: 1;
  border-top: 1px solid #e1ebef;
}
.template__section .template-content-wrapper .template-data-model:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.template__section .template-content-wrapper .props-container {
  width: 100%;
  max-height: 100%;
  overflow: auto;
  border: 1px solid #e1ebef;
  padding: 0 24px;
}
.template__section .template-content-wrapper .props-container:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.template__section .template-content-wrapper .props-container .form-group > label:first-child {
  flex: 0 1 120px;
  width: 120px;
  min-width: 120px;
}
.template__section .template-content-wrapper .props-container .form-group .selectize-control {
  width: calc(100% - 120px);
  flex: 0 1 calc(100% - 120px);
}
.template__section .template-content-wrapper .props-container .hint {
  margin-left: 124px;
  font-size: 12px;
  color: #959595;
}
.template__section .template-content-wrapper .props-container .errors {
  margin-top: 2px;
  margin-left: 124px;
}
.template__section__process-start {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 12px;
  border: 1px solid #e1ebef;
  border-left-width: 4px;
  position: relative;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
}
.template__section__process-start:focus {
  outline: none;
}
.template__section__process-start.error {
  border-color: #f75f5f !important;
}
.template__section__process-start.error .square {
  border-color: #f75f5f !important;
}
.template__section__process-start.error + .template__section__tasks__task {
  border-top: 0 !important;
}
.template__section__process-start > .avatar {
  display: inline-block;
  background: #d6f7d3;
  text-align: center;
  margin-right: 12px;
}
.template__section__process-start > .avatar:after {
  display: inline-block;
  content: '';
  transform: translate(5px, 2px);
  height: 2px;
  border: 7px solid transparent;
  border-right-width: 10px;
  border-left: 11px solid #9fcf9b;
}
.template__section__process-start .square {
  height: 15px;
  width: 15px;
  right: -8px;
  z-index: 100;
  top: 50%;
  margin-top: -8px;
  transform: rotate(45deg);
  background-color: #fff;
  position: absolute;
  border-right: 1px solid #2e9df0;
  border-top: 1px solid #2e9df0;
  display: none;
}
.template__section__process-start.active {
  border-color: #2e9df0;
  border-top: 1px solid #2e9df0;
  z-index: 5;
}
.template__section__process-start.active .square {
  display: block;
}
.template__section__group-summary {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 12px;
  border: 1px solid #e1ebef;
  border-left-width: 4px;
  position: relative;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  z-index: 0;
}
.template__section__group-summary:focus {
  outline: none;
}
.template__section__group-summary.error {
  border-color: #f75f5f !important;
}
.template__section__group-summary.error .square {
  border-color: #f75f5f !important;
}
.template__section__group-summary.error + .template__section__tasks__task {
  border-top: 0 !important;
}
.template__section__group-summary .group-icon {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 12px 0 0;
  flex: 0 0 40px;
}
.template__section__group-summary .group-icon .icon-template_folder {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #d6f7d3;
  font-size: 33px;
  margin-top: -17px;
  margin-left: -20px;
  z-index: 1;
}
.template__section__group-summary .group-icon .icon-template_folder_parallel {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #9fcf9b;
  font-size: 13px;
  margin-top: -5px;
  margin-left: -7px;
  z-index: 2;
}
.template__section__group-summary .group-icon .icon-template_folder_sequence {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #9fcf9b;
  font-size: 13px;
  margin-top: -5px;
  margin-left: -7px;
  z-index: 2;
}
.template__section__group-summary .group-name {
  width: calc(100% - 80px);
  flex: 0 1 auto;
}
.template__section__group-summary .group-actions {
  flex: 0 1 auto;
}
.template__section__group-summary .group-actions .action-icn {
  color: #cacaca;
  cursor: pointer;
  font-size: 26px;
  margin-right: 0;
}
.template__section__group-summary .group-actions .action-icn:hover {
  color: #2e9df0;
}
.template__section__group-summary .group-actions .action-icn:focus {
  outline: none;
}
.template__section__group-summary .square {
  height: 15px;
  width: 15px;
  right: -8px;
  z-index: 100;
  top: 50%;
  margin-top: -8px;
  transform: rotate(45deg);
  background-color: #fff;
  position: absolute;
  border-right: 1px solid #2e9df0;
  border-top: 1px solid #2e9df0;
  display: none;
}
.template__section__group-summary.active {
  border-color: #2e9df0;
  border-top: 1px solid #2e9df0;
  z-index: 5;
}
.template__section__group-summary.active .square {
  display: block;
}
.template__section__group-summary .drop-target-content {
  display: none;
  height: 62px;
  border: 1px dashed #abccd9;
  border-radius: 4px;
  background: #f2fafe;
  flex: 1 1 auto;
  margin: 0 10px 10px 34px;
  font-size: 16px;
  color: #abccd9;
  text-align: center;
  line-height: 58px;
  overflow: hidden;
}
.template__section__group-summary.drop-target {
  flex-wrap: wrap;
  height: 138px;
}
.template__section__group-summary.drop-target .drop-target-content {
  display: block;
}
.template__section__group-summary .empty-state {
  position: absolute;
  height: 64px;
  line-height: 64px;
  left: -4px;
  right: -1px;
  top: 100%;
  margin-top: 1px;
  padding-left: 10px;
  font-size: 12px;
  text-align: center;
  color: #959595;
  background: #f5f5f5;
  z-index: 0;
  border-left: 4px solid #e1ebef;
  border-right: 1px solid #e1ebef;
}
.template__section__group-summary .as-sortable-placeholder {
  display: none !important;
}
.template__section__tasks {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 66px;
  background: #f2fafe;
  border-left: 4px solid #e1ebef;
  border-right: 1px solid #e1ebef;
  margin-top: -1px;
}
.template__section__tasks.with-drag-fix {
  padding-top: 64px;
}
.template__section__tasks.with-bottom-placeholder {
  padding-bottom: 64px;
}
.template__section__tasks.with-bottom-placeholder .as-sortable-placeholder:last-child {
  margin-bottom: -64px;
}
.template__section__tasks.as-sortable-dragging .template__section__tasks__task,
.template__section__tasks.as-sortable-dragging .template__section__tasks__group {
  box-shadow: 0 2px 8px #959595;
}
.template__section__tasks__group__dropzone {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 30px;
  right: 100px;
  z-index: 20;
  outline: 0;
}
.template__section__tasks__group__dropzone .as-sortable-placeholder {
  display: none !important;
}
.template__section__tasks__task,
.template__section__tasks__group {
  display: flex;
  align-items: center;
  height: 64px;
  transition: height 0.2s;
  background: #fff;
  position: relative;
  width: 100%;
  cursor: pointer;
}
.template__section__tasks__task:focus,
.template__section__tasks__group:focus {
  outline: none;
}
.template__section__tasks__task .drag-handler,
.template__section__tasks__group .drag-handler {
  margin-left: 8px;
  display: none;
}
.template__section__tasks__task .drag-handler:focus,
.template__section__tasks__group .drag-handler:focus {
  outline: none;
}
.template__section__tasks__task .group-icon,
.template__section__tasks__group .group-icon {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 12px;
}
.template__section__tasks__task .group-icon .icon-template_folder,
.template__section__tasks__group .group-icon .icon-template_folder {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #e1ebef;
  font-size: 33px;
  margin-top: -17px;
  margin-left: -20px;
  z-index: 1;
}
.template__section__tasks__task .group-icon .icon-template_folder_parallel,
.template__section__tasks__group .group-icon .icon-template_folder_parallel {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #88afbf;
  font-size: 13px;
  margin-top: -5px;
  margin-left: -7px;
  z-index: 2;
}
.template__section__tasks__task .group-icon .icon-template_folder_sequence,
.template__section__tasks__group .group-icon .icon-template_folder_sequence {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #88afbf;
  font-size: 13px;
  margin-top: -5px;
  margin-left: -7px;
  z-index: 2;
}
.template__section__tasks__task .items-count,
.template__section__tasks__group .items-count {
  display: inline-block;
  white-space: nowrap;
  font-size: 12px;
  color: #2e9df0;
  margin-right: 12px;
}
.template__section__tasks__task .items-count:hover,
.template__section__tasks__group .items-count:hover,
.template__section__tasks__task .items-count:focus,
.template__section__tasks__group .items-count:focus {
  cursor: pointer;
  color: #00ccff;
}
.template__section__tasks__task .action-btn,
.template__section__tasks__group .action-btn {
  margin-right: 12px;
}
.template__section__tasks__task .action-btn .action-icn,
.template__section__tasks__group .action-btn .action-icn {
  color: #cacaca;
  cursor: pointer;
  font-size: 26px;
  margin-right: 0;
}
.template__section__tasks__task .action-btn .action-icn:hover,
.template__section__tasks__group .action-btn .action-icn:hover {
  color: #2e9df0;
}
.template__section__tasks__task .action-btn .action-icn:focus,
.template__section__tasks__group .action-btn .action-icn:focus {
  outline: none;
}
.template__section__tasks__task .icon-template_create_dragable,
.template__section__tasks__group .icon-template_create_dragable {
  color: #ccc;
  cursor: pointer;
  font-size: 18px;
  margin-right: 0;
}
.template__section__tasks__task .icon-template_create_dragable:hover,
.template__section__tasks__group .icon-template_create_dragable:hover {
  color: #222;
}
.template__section__tasks__task .assignee,
.template__section__tasks__group .assignee {
  flex: 0 1 64px;
  width: 64px;
  min-width: 64px;
}
.template__section__tasks__task .assignee div,
.template__section__tasks__group .assignee div,
.template__section__tasks__task .assignee img,
.template__section__tasks__group .assignee img {
  height: 40px;
  line-height: 40px;
  width: 40px;
  border-radius: 13px;
  margin: 0 12px;
  text-align: center;
  color: #fff;
  font-size: 14px;
}
.template__section__tasks__task .assignee div.group,
.template__section__tasks__group .assignee div.group,
.template__section__tasks__task .assignee img.group,
.template__section__tasks__group .assignee img.group {
  background-color: #e8e8e8;
  color: #a3a3a3;
}
.template__section__tasks__task .assignee div.group i,
.template__section__tasks__group .assignee div.group i,
.template__section__tasks__task .assignee img.group i,
.template__section__tasks__group .assignee img.group i {
  font-size: 24px;
  width: auto;
}
.template__section__tasks__task .assignee div.group i::before,
.template__section__tasks__group .assignee div.group i::before,
.template__section__tasks__task .assignee img.group i::before,
.template__section__tasks__group .assignee img.group i::before {
  margin: 0;
}
.template__section__tasks__task .content,
.template__section__tasks__group .content {
  flex: 1 0 auto;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  transition: height 0.2s;
  width: calc(100% - 90px);
}
.template__section__tasks__task .content.due-date > .main-block,
.template__section__tasks__group .content.due-date > .main-block {
  max-width: calc(100% - 130px);
}
.template__section__tasks__task .content > .main-block,
.template__section__tasks__group .content > .main-block {
  max-width: 100%;
  flex: 0 1 100%;
  padding-right: 12px;
}
.template__section__tasks__task .content > .main-block h4,
.template__section__tasks__group .content > .main-block h4 {
  font-size: 14px;
  cursor: pointer;
  font-family: 'Roboto-Regular', Arial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #959595;
}
.template__section__tasks__task .content > .main-block h4:focus,
.template__section__tasks__group .content > .main-block h4:focus {
  outline: none;
}
.template__section__tasks__task .content > .main-block h4.filled,
.template__section__tasks__group .content > .main-block h4.filled {
  color: #222;
}
.template__section__tasks__task .content > .main-block p,
.template__section__tasks__group .content > .main-block p {
  font-size: 12px;
  cursor: pointer;
  display: table;
  margin-top: 4px;
  color: #959595;
}
.template__section__tasks__task .content > .task-actions,
.template__section__tasks__group .content > .task-actions {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  padding-right: 12px;
}
.template__section__tasks__task .content > .task-actions > span,
.template__section__tasks__group .content > .task-actions > span {
  font-size: 12px;
  color: #9c9c9c;
  margin-right: 4px;
  text-align: right;
}
.template__section__tasks__task .content > .task-actions > span span,
.template__section__tasks__group .content > .task-actions > span span {
  display: block;
  white-space: nowrap;
}
.template__section__tasks__task .content > .task-actions > span span + span,
.template__section__tasks__group .content > .task-actions > span span + span {
  margin-top: 4px;
}
.template__section__tasks__task .content > .task-actions .action-icn,
.template__section__tasks__group .content > .task-actions .action-icn {
  color: #cacaca;
  cursor: pointer;
  font-size: 26px;
  margin-right: 0;
}
.template__section__tasks__task .content > .task-actions .action-icn:hover,
.template__section__tasks__group .content > .task-actions .action-icn:hover {
  color: #2e9df0;
}
.template__section__tasks__task .content > .task-actions .action-icn:focus,
.template__section__tasks__group .content > .task-actions .action-icn:focus {
  outline: none;
}
.template__section__tasks__task .drop-target-content,
.template__section__tasks__group .drop-target-content {
  display: none;
  height: 62px;
  border: 1px dashed #abccd9;
  border-radius: 4px;
  background: #f2fafe;
  flex: 1 1 auto;
  margin: 0 10px 10px 34px;
  font-size: 16px;
  color: #abccd9;
  text-align: center;
  line-height: 58px;
  overflow: hidden;
}
.template__section__tasks__task.drop-target,
.template__section__tasks__group.drop-target {
  height: 138px;
  flex-wrap: wrap;
}
.template__section__tasks__task.drop-target .drop-target-content,
.template__section__tasks__group.drop-target .drop-target-content {
  display: block;
}
.template__section__tasks__task.drop-target .conditions-marker__icon,
.template__section__tasks__group.drop-target .conditions-marker__icon {
  display: none;
}
.template__section__tasks__task .square,
.template__section__tasks__group .square {
  height: 15px;
  width: 15px;
  right: -8px;
  z-index: 100;
  top: 50%;
  margin-top: -8px;
  transform: rotate(45deg);
  background-color: #fff;
  position: absolute;
  border-right: 1px solid #2e9df0;
  border-top: 1px solid #2e9df0;
  display: none;
}
.template__section__tasks__task.add-task-buttons,
.template__section__tasks__group.add-task-buttons {
  margin-top: -1px;
  border: 1px solid #e1ebef;
  border-left-width: 4px;
  align-items: center;
  justify-content: start;
  cursor: default;
  padding: 10px 12px;
}
.template__section__tasks__task.add-task-buttons:after,
.template__section__tasks__group.add-task-buttons:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.template__section__tasks__task.add-task-buttons.with-actions,
.template__section__tasks__group.add-task-buttons.with-actions {
  padding-left: 34px;
}
.template__section__tasks__task.add-task-buttons button,
.template__section__tasks__group.add-task-buttons button {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: left;
}
.template__section__tasks__task.add-task-buttons button:focus,
.template__section__tasks__group.add-task-buttons button:focus {
  outline: none;
}
.template__section__tasks__task.add-task-buttons button + button,
.template__section__tasks__group.add-task-buttons button + button {
  margin-left: 50px;
}
.template__section__tasks__task.add-task-buttons button .button__icon,
.template__section__tasks__group.add-task-buttons button .button__icon {
  line-height: 38px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  text-align: center;
  border: 1px dashed #9d9d9d;
  border-radius: 16px;
  display: inline-block;
  margin-right: 12px;
  color: #a3a3a3;
}
.template__section__tasks__task.add-task-buttons button .button__icon i,
.template__section__tasks__group.add-task-buttons button .button__icon i {
  font-size: 16px;
}
.template__section__tasks__task.add-task-buttons button .button__text,
.template__section__tasks__group.add-task-buttons button .button__text {
  height: 40px;
  line-height: 38px;
  vertical-align: top;
  display: inline-block;
  color: #9d9d9d;
  font-family: 'Roboto-Regular', Arial;
  font-size: 14px;
  width: auto;
}
.template__section__tasks__task.add-task-buttons button.add-approval-task .button__icon,
.template__section__tasks__group.add-task-buttons button.add-approval-task .button__icon {
  border-radius: 20px;
}
.template__section__tasks__task.add-task-buttons button.add-group,
.template__section__tasks__group.add-task-buttons button.add-group {
  position: relative;
}
.template__section__tasks__task.add-task-buttons button.add-group .button__icon,
.template__section__tasks__group.add-task-buttons button.add-group .button__icon {
  border: 0;
  border-radius: 0;
  width: 48px;
  margin-right: 9px;
}
.template__section__tasks__task.add-task-buttons button.add-group .button__icon i,
.template__section__tasks__group.add-task-buttons button.add-group .button__icon i {
  font-size: 32px;
}
.template__section__tasks__task.add-task-buttons button.add-group.disabled,
.template__section__tasks__group.add-task-buttons button.add-group.disabled {
  background-color: transparent !important;
}
.template__section__tasks__task.add-task-buttons button:not(.disabled):hover .button__icon,
.template__section__tasks__group.add-task-buttons button:not(.disabled):hover .button__icon,
.template__section__tasks__task.add-task-buttons button:not(.disabled):focus .button__icon,
.template__section__tasks__group.add-task-buttons button:not(.disabled):focus .button__icon {
  border-color: #2e9df0;
}
.template__section__tasks__task.add-task-buttons button:not(.disabled):hover .button__icon ::after,
.template__section__tasks__group.add-task-buttons button:not(.disabled):hover .button__icon ::after,
.template__section__tasks__task.add-task-buttons button:not(.disabled):focus .button__icon ::after,
.template__section__tasks__group.add-task-buttons button:not(.disabled):focus .button__icon ::after {
  border-color: #2e9df0;
}
.template__section__tasks__task.add-task-buttons button:not(.disabled):hover .button__icon i,
.template__section__tasks__group.add-task-buttons button:not(.disabled):hover .button__icon i,
.template__section__tasks__task.add-task-buttons button:not(.disabled):focus .button__icon i,
.template__section__tasks__group.add-task-buttons button:not(.disabled):focus .button__icon i {
  color: #2e9df0;
}
.template__section__tasks__task.add-task-buttons button:not(.disabled):hover .button__text,
.template__section__tasks__group.add-task-buttons button:not(.disabled):hover .button__text,
.template__section__tasks__task.add-task-buttons button:not(.disabled):focus .button__text,
.template__section__tasks__group.add-task-buttons button:not(.disabled):focus .button__text {
  color: #2e9df0;
}
.template__section__tasks__item {
  position: relative;
  border: 1px solid #e1ebef;
  border-left-width: 4px;
  margin-left: -4px;
  margin-right: -1px;
}
.template__section__tasks__item:focus {
  outline: none;
}
.template__section__tasks__item.active {
  border: 1px solid #2e9df0;
  border-left-width: 4px;
  z-index: 5;
}
.template__section__tasks__item.active .square {
  display: block;
}
.template__section__tasks__item.error {
  border: 1px solid #f75f5f;
  border-left-width: 4px;
  z-index: 4;
}
.template__section__tasks__item.error .square {
  border-color: #f75f5f !important;
}
.template__section__tasks__item.with-actions .drag-handler {
  display: block;
}
.template__section__tasks__item.with-actions .content > .main-block {
  max-width: calc(100% - 50px);
}
.template__section__tasks__item.with-actions .content.due-date > .main-block {
  max-width: calc(100% - 210px);
}
.template__section__tasks__item.with-actions .template__section__tasks__group .content > .main-block {
  max-width: calc(100% - 90px);
}
.template__section__tasks__item,
.template__section__tasks .as-sortable-placeholder {
  margin-bottom: -1px;
}
.template__section__tasks .as-sortable-placeholder {
  background-color: #f2fafe;
  border-left: 4px solid #e1ebef;
  border-right: 1px solid #e1ebef;
  margin-left: -4px;
  margin-right: -1px;
}
.template__section textarea {
  margin-bottom: 5px !important;
}
.template__section .task-footer {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
  position: relative;
}
.template__section .task-footer .task-actors-select {
  flex: 0 1 calc(100% - 140px);
  width: calc(100% - 140px);
  margin-top: 0;
  flex-direction: column;
  align-items: flex-start;
}
.template__section .task-footer .task-actors-select .selectize-control {
  width: 100%;
  flex: 0 1 100%;
}
.template__section .task-footer .task-actors-select .errors {
  margin: 0;
}
.template__section .task-footer .select-wrapper {
  flex: 0 1 100%;
  width: 100%;
  padding-top: 2px;
}
.template__section__task-settings,
.template__section__group-details {
  padding: 16px 24px 100px 32px;
  position: relative;
  min-height: 100%;
}
.template__section__task-settings__tabs,
.template__section__group-details__tabs {
  margin-top: 0;
  margin-bottom: 24px;
}
.template__section__task-settings__tabs li + li,
.template__section__group-details__tabs li + li {
  margin-left: 12px;
}
.template__section__task-settings--empty,
.template__section__group-details--empty {
  border: 1px solid #e1ebef;
  height: 100%;
  width: 100%;
  position: relative;
}
.template__section__task-settings--empty > div,
.template__section__group-details--empty > div {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  margin-top: -24px;
}
.template__section__task-settings--empty > div h4,
.template__section__group-details--empty > div h4 {
  font-size: 14px;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
}
.template__section__task-settings--empty > div p,
.template__section__group-details--empty > div p {
  color: #a3a3a3;
  font-size: 12px;
  margin-top: 4px;
}
.template__section__task-settings.ng-hide-add,
.template__section__group-details.ng-hide-add,
.template__section__task-settings.ng-hide-remove,
.template__section__group-details.ng-hide-remove {
  transition: all linear 0.2s;
}
.template__section__task-settings.ng-hide,
.template__section__group-details.ng-hide {
  opacity: 0;
}
.template__section__task-settings > .content,
.template__section__group-details > .content {
  width: 100%;
}
.template__section__task-settings > .content > div .markdown-hint,
.template__section__group-details > .content > div .markdown-hint {
  margin-left: 170px;
}
.template__section__task-settings > .content > div > h4,
.template__section__group-details > .content > div > h4 {
  color: #959595;
  font-size: 14px;
  margin: 24px 0;
  font-family: 'Roboto-Regular', Arial;
}
.template__section__task-settings > .content > div > h4:first-child,
.template__section__group-details > .content > div > h4:first-child {
  margin-top: 0;
}
.template__section__task-settings > .content > div > markdown-hint + .form-group,
.template__section__group-details > .content > div > markdown-hint + .form-group {
  margin-top: 12px;
}
.template__section__task-settings > .content > div > .form-group,
.template__section__group-details > .content > div > .form-group {
  align-items: flex-start;
}
.template__section__task-settings > .content > div > .form-group.active + markdown-hint,
.template__section__group-details > .content > div > .form-group.active + markdown-hint {
  visibility: visible;
}
.template__section__task-settings > .content > div > .form-group.task-type-settings label,
.template__section__group-details > .content > div > .form-group.task-type-settings label {
  padding-top: 0;
  margin-top: 0;
}
.template__section__task-settings > .content > div > .form-group.task-type-settings .g-radio-group label,
.template__section__group-details > .content > div > .form-group.task-type-settings .g-radio-group label {
  display: inline-block;
}
.template__section__task-settings > .content > div > .form-group.task-type-settings .g-radio-group label:last-child,
.template__section__group-details > .content > div > .form-group.task-type-settings .g-radio-group label:last-child {
  margin-left: 12px;
}
.template__section__task-settings > .content > div > .form-group > label,
.template__section__group-details > .content > div > .form-group > label {
  flex: 0 1 170px;
  min-width: 170px;
  width: 170px;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
}
.template__section__task-settings > .content > div > .form-group > label i.icon-template_create_field_required,
.template__section__group-details > .content > div > .form-group > label i.icon-template_create_field_required {
  font-size: 10px;
  vertical-align: top;
}
.template__section__task-settings > .content > div > .form-group > .selectize-control,
.template__section__group-details > .content > div > .form-group > .selectize-control {
  width: calc(100% - 170px - 27px);
  max-width: 615px;
  flex: 0 1 auto;
}
.template__section__task-settings > .content > div > .form-group > .selectize-control .selectize-dropdown .dropdown-list,
.template__section__group-details > .content > div > .form-group > .selectize-control .selectize-dropdown .dropdown-list {
  z-index: 7;
}
.template__section__task-settings > .content > div > .form-group > .actors-settings,
.template__section__group-details > .content > div > .form-group > .actors-settings {
  margin-top: 6px;
}
.template__section__task-settings > .content > div > .form-group > .actors-settings .icon-header_settings,
.template__section__group-details > .content > div > .form-group > .actors-settings .icon-header_settings {
  cursor: pointer;
  color: #a3a3a3;
  margin-left: 8px;
  font-size: 16px;
  min-width: 15px;
}
.template__section__task-settings > .content > div > .form-group > .actors-settings .icon-header_settings:focus,
.template__section__group-details > .content > div > .form-group > .actors-settings .icon-header_settings:focus {
  outline: none;
}
.template__section__task-settings > .content > div > .form-group > .actors-settings .icon-header_settings.active,
.template__section__group-details > .content > div > .form-group > .actors-settings .icon-header_settings.active {
  color: #4abc3b;
}
.template__section__task-settings > .content > div > .form-group > .actors-settings .icon-header_settings:hover,
.template__section__group-details > .content > div > .form-group > .actors-settings .icon-header_settings:hover {
  color: #2e9df0;
}
.template__section__task-settings > .content > div > .form-group > .task-name-control,
.template__section__group-details > .content > div > .form-group > .task-name-control {
  flex: 0 1 calc(100% - 170px);
  max-width: calc(100% - 170px);
}
.template__section__task-settings > .content > div > .form-group .tmpl-date-picker,
.template__section__group-details > .content > div > .form-group .tmpl-date-picker {
  background: #fff;
}
.template__section__task-settings > .content > div > .form-group .tmpl-date-picker .handler,
.template__section__group-details > .content > div > .form-group .tmpl-date-picker .handler {
  padding: 6px 7px;
  flex: 1 0 auto;
  border: 2px #cacaca solid;
  border-radius: 4px;
  border-width: 1px;
  cursor: pointer;
  height: 32px;
  min-width: 120px;
}
.template__section__task-settings > .content > div > .form-group .tmpl-date-picker .handler.drop-enabled,
.template__section__group-details > .content > div > .form-group .tmpl-date-picker .handler.drop-enabled {
  border-color: #2e9df0;
  border-width: 2px;
}
.template__section__task-settings > .content > div .errors,
.template__section__group-details > .content > div .errors {
  margin-top: 2px;
  margin-left: 170px;
}
.template__section__group-details > .content > div .form-group > label {
  flex: 0 1 170px;
  min-width: 170px;
  width: 170px;
  padding-top: 0;
}
.template__section__group-details > .content > div .form-group:not(.m-start) > label {
  padding-top: 8px;
}
.template__section__group-details > .content > div .form-group .g-radio-group .g-radio {
  display: inline-block;
}
.template__section__group-details > .content > div .errors {
  margin-top: 2px;
  margin-left: 170px;
}
.actors-settings-dropdown {
  padding: 8px;
  margin-top: 4px;
}
.actors-settings-dropdown h5 {
  margin-bottom: 6px;
}
.actors-settings-dropdown ul li {
  padding: 6px;
  display: flex;
  align-items: center;
}
.actors-settings-dropdown ul li:focus {
  outline: none;
}
.actors-settings-dropdown ul li .g-radio:focus,
.actors-settings-dropdown ul li .g-radio input:focus {
  outline: none;
}
.process__header {
  display: flex;
  background-color: #fff;
  padding: 16px 48px 16px 0;
  position: relative;
  border-bottom: 1px solid #e1ebef;
}
.process__header:focus {
  outline: none;
}
.process__header__icon {
  flex: 0 1 40px;
  line-height: 40px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  vertical-align: middle;
  text-align: center;
  border-radius: 13px;
  color: #40484c;
  margin-right: 12px;
  margin-top: 4px;
  position: relative;
}
.process__header__icon .process-rejected-icon {
  border: 1px solid #fff;
  border-radius: 8px;
  width: 17px;
  height: 17px;
  background-color: #f75f5f;
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -3px;
  bottom: -3px;
}
.process__header__icon .process-rejected-icon > i {
  color: black;
  opacity: 0.5;
  font-size: 7px;
}
.process__header__icon .process-completed-icon {
  border: 1px solid #fff;
  border-radius: 8px;
  width: 17px;
  height: 17px;
  background-color: #4abc3b;
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -3px;
  bottom: -3px;
}
.process__header__icon .process-completed-icon > i {
  color: black;
  opacity: 0.5;
  font-size: 7px;
}
.process__header__icon .process-stopped-icon {
  border: 1px solid #fff;
  border-radius: 8px;
  width: 17px;
  height: 17px;
  background-color: #f9d83c;
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -3px;
  bottom: -3px;
}
.process__header__icon .process-stopped-icon > i {
  color: black;
  opacity: 0.5;
  font-size: 7px;
}
.process__header__icon.default {
  background-color: #ccc;
}
.process__header__icon.default i {
  line-height: 40px;
}
.process__header__icon i {
  font-size: 24px;
  line-height: 40px;
}
.process__header__icon i.icon-Magnifi-Glass2 {
  font-size: 22px;
}
.process__header__icon i.icon-Mens {
  font-size: 26px;
}
.process__header__main {
  display: flex;
  align-items: flex-start;
  max-width: calc(100% - 85px);
  margin-top: 4px;
}
.process__header__main__content {
  min-width: 0;
}
.process__header__main__content > span {
  color: #959595;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.process__header__main__content > span.animated a:hover {
  cursor: pointer;
}
.process__header__main__content > span a {
  color: #959595;
  display: inline-block;
  border-bottom: 1px solid #ccc;
  cursor: default;
  height: 15px;
}
.process__header__main__content > span a.template-name {
  display: none;
  border-bottom: none;
}
.process__header__main__content > span.ad-hoc-title {
  color: #959595;
  font-size: 9px;
  text-transform: uppercase;
  font-family: 'Roboto-Regular', Arial;
  letter-spacing: 0.5px;
  display: block;
}
.process__header__main__content > .title {
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
  min-height: 31px;
}
.process__header__main__content > .title h1 {
  font-size: 21px;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  color: #222;
  width: 100%;
  clear: both;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  overflow: hidden;
}
.process__header__main__content .process-reject-reason {
  display: inline-block;
  background-color: #e1ebef;
  border-radius: 4px;
  padding: 12px;
  margin-top: 4px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.process__header__main__content .process-reject-reason > span:first-child {
  font-family: 'Roboto-Bold', Arial;
}
.process__header__main__btns {
  flex: 0 1 auto;
  min-width: 100px;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-end;
  display: inline-flex;
  position: relative;
  margin-top: 6px;
}
.process__header__main__btns .action {
  font-size: 32px;
  max-width: 32px;
  border: none;
  color: #cdcdcd;
  padding: 0;
  background: transparent;
}
.process__header__main__btns .action:focus {
  outline: none;
}
.process__header__main__btns .action > i {
  margin: 0;
}
.process__header__main__btns .action + .action {
  margin-left: 12px;
}
.process__header__main__btns .action[disabled='disabled'] {
  color: #e8e8e8 !important;
  cursor: not-allowed;
}
.process__header__main__btns .action[disabled='disabled']:hover {
  color: #e8e8e8 !important;
}
.process__header__main__btns .action:hover {
  color: #2e9df0;
}
.process__header > .icon-common_close {
  color: #cdcdcd;
  font-size: 14px;
  position: absolute;
  top: 12px;
  right: 18px;
  cursor: pointer;
}
.process__header > .icon-common_close:hover {
  color: #959595;
}
.process__header > .common_back {
  font-size: 40px;
  width: 20px;
  display: inline-block;
  color: #cdcdcd;
  right: 18px;
  cursor: pointer;
  margin-right: 12px;
}
.process__header > .common_back:focus {
  outline: none;
}
.process__header > .common_back > i {
  margin: 0;
}
.process__header > .common_back:hover {
  color: #2e9df0;
}
.process__section {
  display: flex;
  height: calc(100% - (4 * 16px));
  padding: 0 32px;
}
.process__section > .due-date-picker {
  display: none;
}
.process__section .tasks-container {
  margin-top: 16px;
  padding-right: 25px;
  flex: 0 1 60%;
  min-width: 60%;
  height: calc(100% - 16px);
}
.process__section .tasks-container:focus {
  outline: none;
}
.process__section .tasks-container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  height: 48px;
}
.process__section .tasks-container__header__btns {
  display: inline-flex;
}
.process__section .tasks-container__header__btns .btn-switcher span {
  display: inline;
  font-size: inherit;
}
.process__section .tasks-container__header__btns .btn-switcher .number {
  display: none;
}
.process__section .tasks-container__header__btns a + a {
  margin-left: 12px;
}
.process__section .tasks-container__header > div.dueDate {
  display: flex;
  align-items: center;
}
.process__section .tasks-container__header > div.dueDate .round-progress-wrapper {
  margin-left: 8px;
}
.process__section .tasks-container__header > div.dueDate > .comments-toggler {
  border: 1px #989898 solid;
  color: #989898;
  padding: 6px 40px 6px 8px;
  height: 32px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  margin-right: -32px;
  display: none;
  margin-left: 12px;
  font-size: 14px;
}
.process__section .tasks-container__header > div.dueDate > .comments-toggler:focus {
  outline: none;
}
.process__section .tasks-container__header > div.dueDate > .comments-toggler i {
  font-size: 16px;
  line-height: 18px;
}
.process__section .tasks-container__level-navigation {
  padding: 8px 0;
  line-height: 24px;
}
.process__section .tasks-container__level-navigation .close-group-btn {
  display: inline-block;
  vertical-align: top;
  width: 32px;
  height: 24px;
  margin-top: -1px;
  margin-right: 8px;
  color: #cacaca;
}
.process__section .tasks-container__level-navigation .close-group-btn i {
  font-size: 24px;
}
.process__section .tasks-container__level-navigation .close-group-btn:hover,
.process__section .tasks-container__level-navigation .close-group-btn:focus {
  color: #2e9df0;
}
.process__section .tasks-container__level-navigation .group-name {
  font-size: 16px;
  color: #222222;
  line-height: 1.2;
}
.process__section .tasks-container__tasks {
  padding: 0;
  margin: 0;
  height: calc(100% - (3 * 16px));
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.process__section .tasks-container__tasks:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.process__section .tasks-container__tasks .tasks-container__tasks__item {
  border: 1px solid #e1ebef;
  border-left-width: 4px;
}
.process__section .tasks-container__tasks .tasks-container__tasks__item.red {
  border-left-color: #f75f5f;
}
.process__section .tasks-container__tasks .tasks-container__tasks__item.green {
  border-left-color: #4abc3b;
}
.process__section .tasks-container__tasks .tasks-container__tasks__item.yellow {
  border-left-color: #f9d83c;
}
.process__section .tasks-container__tasks .tasks-container__tasks__item + .tasks-container__tasks__item {
  border-top: 0;
}
.process__section .tasks-container__tasks__task,
.process__section .tasks-container__tasks__group {
  display: flex;
  width: calc(100% - 1px);
  align-items: center;
  height: 64px;
  justify-content: space-between;
  padding-right: 16px;
}
.process__section .tasks-container__tasks__task:focus,
.process__section .tasks-container__tasks__group:focus {
  outline: none;
}
.process__section .tasks-container__tasks__task.mobile.assignee:not(.completed):not(.inactive) .avatar,
.process__section .tasks-container__tasks__group.mobile.assignee:not(.completed):not(.inactive) .avatar {
  color: transparent;
  background: transparent !important;
}
.process__section .tasks-container__tasks__task.mobile.assignee:not(.completed):not(.inactive) .avatar img,
.process__section .tasks-container__tasks__group.mobile.assignee:not(.completed):not(.inactive) .avatar img,
.process__section .tasks-container__tasks__task.mobile.assignee:not(.completed):not(.inactive) .avatar .status-icon,
.process__section .tasks-container__tasks__group.mobile.assignee:not(.completed):not(.inactive) .avatar .status-icon {
  visibility: hidden !important;
}
.process__section .tasks-container__tasks__task.mobile.assignee:not(.completed):not(.inactive) .avatar .checkbox,
.process__section .tasks-container__tasks__group.mobile.assignee:not(.completed):not(.inactive) .avatar .checkbox {
  display: block !important;
}
.process__section .tasks-container__tasks__task.mobile .content .action,
.process__section .tasks-container__tasks__group.mobile .content .action {
  display: inline-block;
}
.process__section .tasks-container__tasks__task.animated,
.process__section .tasks-container__tasks__group.animated {
  cursor: pointer;
}
.process__section .tasks-container__tasks__task.animated:hover .content,
.process__section .tasks-container__tasks__group.animated:hover .content {
  cursor: pointer;
}
.process__section .tasks-container__tasks__task.animated:hover .content .action,
.process__section .tasks-container__tasks__group.animated:hover .content .action {
  display: inline-block;
}
.process__section .tasks-container__tasks__task.animated.assignee:not(.inactive):hover .avatar .user-tooltip,
.process__section .tasks-container__tasks__group.animated.assignee:not(.inactive):hover .avatar .user-tooltip {
  visibility: hidden !important;
}
.process__section .tasks-container__tasks__task.animated.assignee:not(.completed):not(.inactive) .avatar,
.process__section .tasks-container__tasks__group.animated.assignee:not(.completed):not(.inactive) .avatar {
  color: transparent;
  background: transparent !important;
}
.process__section .tasks-container__tasks__task.animated.assignee:not(.completed):not(.inactive) .avatar img,
.process__section .tasks-container__tasks__group.animated.assignee:not(.completed):not(.inactive) .avatar img,
.process__section .tasks-container__tasks__task.animated.assignee:not(.completed):not(.inactive) .avatar .status-icon,
.process__section .tasks-container__tasks__group.animated.assignee:not(.completed):not(.inactive) .avatar .status-icon {
  visibility: hidden !important;
}
.process__section .tasks-container__tasks__task.animated.assignee:not(.completed):not(.inactive) .avatar .checkbox,
.process__section .tasks-container__tasks__group.animated.assignee:not(.completed):not(.inactive) .avatar .checkbox {
  display: block;
}
.process__section .tasks-container__tasks__task.animated.assignee:not(.completed):not(.inactive) .avatar .checkbox.isAlreadyCompleting input[type="checkbox"],
.process__section .tasks-container__tasks__group.animated.assignee:not(.completed):not(.inactive) .avatar .checkbox.isAlreadyCompleting input[type="checkbox"] {
  cursor: progress;
}
.process__section .tasks-container__tasks__task.animated.assignee:not(.completed):not(.inactive) .avatar .approval-default,
.process__section .tasks-container__tasks__group.animated.assignee:not(.completed):not(.inactive) .avatar .approval-default {
  display: flex;
}
.process__section .tasks-container__tasks__task.animated.assignee:not(.completed):not(.inactive) .avatar .approval-default.isAlreadyApproving,
.process__section .tasks-container__tasks__group.animated.assignee:not(.completed):not(.inactive) .avatar .approval-default.isAlreadyApproving {
  cursor: progress;
}
.process__section .tasks-container__tasks__task.animated:not(.inactive):hover h4,
.process__section .tasks-container__tasks__group.animated:not(.inactive):hover h4 {
  color: #2e9df0;
}
.process__section .tasks-container__tasks__task.pending,
.process__section .tasks-container__tasks__group.pending {
  background-color: #f8f8f9;
}
.process__section .tasks-container__tasks__task.pending .content h4,
.process__section .tasks-container__tasks__group.pending .content h4 {
  color: #959595;
}
.process__section .tasks-container__tasks__task.inactive,
.process__section .tasks-container__tasks__group.inactive {
  background-color: #f8f8f9;
}
.process__section .tasks-container__tasks__task.inactive .content h4,
.process__section .tasks-container__tasks__group.inactive .content h4 {
  color: #cacaca !important;
}
.process__section .tasks-container__tasks__task.inactive .content p,
.process__section .tasks-container__tasks__group.inactive .content p {
  cursor: default;
  color: #cacaca !important;
}
.process__section .tasks-container__tasks__task.inactive .content p .name,
.process__section .tasks-container__tasks__group.inactive .content p .name {
  color: #cacaca !important;
}
.process__section .tasks-container__tasks__task.inactive.skipped,
.process__section .tasks-container__tasks__group.inactive.skipped {
  background: #fff;
}
.process__section .tasks-container__tasks__task.inactive.skipped .content h4,
.process__section .tasks-container__tasks__group.inactive.skipped .content h4 {
  color: #a3a3a3 !important;
}
.process__section .tasks-container__tasks__task .group-icon,
.process__section .tasks-container__tasks__group .group-icon {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
}
.process__section .tasks-container__tasks__task .group-icon .icon-template_folder,
.process__section .tasks-container__tasks__group .group-icon .icon-template_folder,
.process__section .tasks-container__tasks__task .group-icon .icon-group_inactive,
.process__section .tasks-container__tasks__group .group-icon .icon-group_inactive {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #e1ebef;
  font-size: 33px;
  margin-top: -17px;
  margin-left: -20px;
  z-index: 1;
}
.process__section .tasks-container__tasks__task .group-icon .icon-group_inactive,
.process__section .tasks-container__tasks__group .group-icon .icon-group_inactive {
  color: #a3a3a3;
}
.process__section .tasks-container__tasks__task .group-icon .icon-template_folder_parallel,
.process__section .tasks-container__tasks__group .group-icon .icon-template_folder_parallel {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #88afbf;
  font-size: 13px;
  margin-top: -14px;
  margin-left: -7px;
  z-index: 2;
}
.process__section .tasks-container__tasks__task .group-icon .icon-template_folder_sequence,
.process__section .tasks-container__tasks__group .group-icon .icon-template_folder_sequence {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #88afbf;
  font-size: 13px;
  margin-top: -14px;
  margin-left: -7px;
  z-index: 2;
}
.process__section .tasks-container__tasks__task .group-icon .icon-process_task_inactive,
.process__section .tasks-container__tasks__group .group-icon .icon-process_task_inactive {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 16px;
  margin-top: -15px;
  margin-left: -7px;
  z-index: 2;
  color: #a3a3a3;
}
.process__section .tasks-container__tasks__task__main,
.process__section .tasks-container__tasks__group__main {
  display: flex;
  align-items: center;
  width: calc(100% - 120px);
}
.process__section .tasks-container__tasks__task__main.with-comments,
.process__section .tasks-container__tasks__group__main.with-comments {
  max-width: calc(100% - (40px + 2 * 12px + 60px)) !important;
}
.process__section .tasks-container__tasks__task__main.with-due,
.process__section .tasks-container__tasks__group__main.with-due {
  max-width: calc(100% - (40px + 2 * 12px + 60px)) !important;
}
.process__section .tasks-container__tasks__task__main.with-due.with-comments,
.process__section .tasks-container__tasks__group__main.with-due.with-comments {
  max-width: calc(100% - (40px + 2 * 12px + 140px)) !important;
}
.process__section .tasks-container__tasks__task__main.with-inactive,
.process__section .tasks-container__tasks__group__main.with-inactive {
  max-width: calc(100% - (40px + 2 * 12px + 60px)) !important;
}
.process__section .tasks-container__tasks__task__main > .avatar,
.process__section .tasks-container__tasks__group__main > .avatar {
  margin: 0 12px;
  position: relative;
}
.process__section .tasks-container__tasks__task__main > .avatar .approval-default,
.process__section .tasks-container__tasks__group__main > .avatar .approval-default {
  position: absolute;
  top: 8px;
  left: 8px;
  display: none;
}
.process__section .tasks-container__tasks__task__main > .avatar .checkbox,
.process__section .tasks-container__tasks__group__main > .avatar .checkbox {
  display: none;
  position: absolute;
  top: 5px;
  left: -2px;
  height: 26px;
}
.process__section .tasks-container__tasks__task__main > .avatar .user-tooltip,
.process__section .tasks-container__tasks__group__main > .avatar .user-tooltip {
  left: -12px;
  right: auto;
  top: 48px;
}
.process__section .tasks-container__tasks__task__main > .avatar .user-tooltip .arrow,
.process__section .tasks-container__tasks__group__main > .avatar .user-tooltip .arrow {
  left: 24px;
  right: auto;
}
.process__section .tasks-container__tasks__task__main > .avatar:hover,
.process__section .tasks-container__tasks__group__main > .avatar:hover {
  position: relative;
}
.process__section .tasks-container__tasks__task__main > .avatar:hover .user-tooltip,
.process__section .tasks-container__tasks__group__main > .avatar:hover .user-tooltip {
  visibility: visible;
}
.process__section .tasks-container__tasks__task__main > .avatar.relative,
.process__section .tasks-container__tasks__group__main > .avatar.relative {
  position: relative;
}
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon {
  position: absolute;
  right: -5px;
  bottom: -5px;
  border-radius: 100%;
  border: 1px solid #fff;
  height: 17px;
  line-height: 17px;
  width: 17px;
  text-align: center;
  z-index: 1;
}
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.completed,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.completed {
  background-color: #4abc3b;
}
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.issued,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.issued {
  background-color: #f9d83c;
}
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.rejected,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.rejected {
  background-color: #f75f5f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon > i,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon > i {
  font-size: 7px;
  color: #222;
  opacity: 0.7;
  display: block;
}
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon > i.icon-assignee_badge_issued,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon > i.icon-assignee_badge_issued {
  margin: -1px 0 0 1px;
  font-size: 8px;
  opacity: 0.9;
}
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.skipped,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.skipped,
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.cancelled,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.cancelled,
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.pending,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.pending {
  background-color: #cacaca;
}
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.skipped i,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.skipped i,
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.cancelled i,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.cancelled i,
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.pending i,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.pending i {
  color: #000;
  display: block;
  margin: 0 auto;
  line-height: 14px;
  font-size: 8px;
  opacity: 0.5;
}
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.pending,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.pending {
  background-color: #f9d83c;
}
.process__section .tasks-container__tasks__task__main > .avatar > .status-icon.pending i,
.process__section .tasks-container__tasks__group__main > .avatar > .status-icon.pending i {
  font-size: 10px;
}
.process__section .tasks-container__tasks__task__main > .avatar.avatar-unassigned,
.process__section .tasks-container__tasks__group__main > .avatar.avatar-unassigned {
  color: #fff;
  background-color: #ccc;
}
.process__section .tasks-container__tasks__task__main > .avatar.avatar-unassigned i,
.process__section .tasks-container__tasks__group__main > .avatar.avatar-unassigned i {
  font-size: 21px;
  margin-left: 8px;
  margin-top: -1px;
  display: block;
}
.process__section .tasks-container__tasks__task__main > .avatar.avatar-unassigned i.icon-process_task_inactive,
.process__section .tasks-container__tasks__group__main > .avatar.avatar-unassigned i.icon-process_task_inactive {
  margin-left: 4px;
}
.process__section .tasks-container__tasks__task__main > .avatar.avatar-unassigned i::before,
.process__section .tasks-container__tasks__group__main > .avatar.avatar-unassigned i::before {
  margin: 0;
}
.process__section .tasks-container__tasks__task__main > .avatar.group,
.process__section .tasks-container__tasks__group__main > .avatar.group {
  color: #a3a3a3;
  border: 1px dashed #9d9d9d;
}
.process__section .tasks-container__tasks__task__main > .avatar.group.inactive,
.process__section .tasks-container__tasks__group__main > .avatar.group.inactive {
  display: flex;
  align-items: center;
  justify-content: center;
}
.process__section .tasks-container__tasks__task__main > .avatar.group.inactive i.icon-process_task_inactive,
.process__section .tasks-container__tasks__group__main > .avatar.group.inactive i.icon-process_task_inactive {
  font-size: 16px;
  margin: 0;
}
.process__section .tasks-container__tasks__task__main > .avatar.group.inactive .process_task_stopped_on_reject,
.process__section .tasks-container__tasks__group__main > .avatar.group.inactive .process_task_stopped_on_reject {
  height: 12px;
  margin: 0;
}
.process__section .tasks-container__tasks__task__main > .avatar.group i,
.process__section .tasks-container__tasks__group__main > .avatar.group i {
  font-size: 21px;
  margin-left: 8px;
  margin-top: -1px;
  display: block;
}
.process__section .tasks-container__tasks__task__main > .avatar.group i.icon-process_task_inactive,
.process__section .tasks-container__tasks__group__main > .avatar.group i.icon-process_task_inactive {
  margin-left: 4px;
}
.process__section .tasks-container__tasks__task__main > .avatar.group i::before,
.process__section .tasks-container__tasks__group__main > .avatar.group i::before {
  margin: 0;
}
.process__section .tasks-container__tasks__task__main .content,
.process__section .tasks-container__tasks__group__main .content {
  width: calc(100% - (40px + 12px));
  padding-right: 16px;
}
.process__section .tasks-container__tasks__task__main .content .action,
.process__section .tasks-container__tasks__group__main .content .action {
  display: none;
  vertical-align: top;
}
.process__section .tasks-container__tasks__task__main .content h4,
.process__section .tasks-container__tasks__group__main .content h4 {
  font-size: 14px;
  font-family: 'Roboto-Regular', Arial;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.process__section .tasks-container__tasks__task__main .content p,
.process__section .tasks-container__tasks__group__main .content p {
  font-size: 12px;
  display: block;
  margin-top: 4px;
  color: #a3a3a3;
  font-family: 'Roboto-Regular', Arial;
  max-width: 100%;
}
.process__section .tasks-container__tasks__task__main .content p .first-part,
.process__section .tasks-container__tasks__group__main .content p .first-part {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}
.process__section .tasks-container__tasks__task__main .content p .first-part:last-child .dot,
.process__section .tasks-container__tasks__group__main .content p .first-part:last-child .dot {
  display: none;
}
.process__section .tasks-container__tasks__task__main .content p span.name,
.process__section .tasks-container__tasks__group__main .content p span.name {
  color: #222;
}
.process__section .tasks-container__tasks__task__main .content p span.completed,
.process__section .tasks-container__tasks__group__main .content p span.completed,
.process__section .tasks-container__tasks__task__main .content p span.approved,
.process__section .tasks-container__tasks__group__main .content p span.approved {
  color: #4abc3b;
}
.process__section .tasks-container__tasks__task__main .content p span.rejected,
.process__section .tasks-container__tasks__group__main .content p span.rejected {
  color: #f75f5f;
}
.process__section .tasks-container__tasks__task__main .content p span.pending-label,
.process__section .tasks-container__tasks__group__main .content p span.pending-label {
  color: #d3b93e;
}
.process__section .tasks-container__tasks__task__main .content p span.cancelled-label,
.process__section .tasks-container__tasks__group__main .content p span.cancelled-label {
  color: #959595;
}
.process__section .tasks-container__tasks__task__main .content p a,
.process__section .tasks-container__tasks__group__main .content p a {
  color: #2e9df0;
}
.process__section .tasks-container__tasks__task__main .content p a:hover,
.process__section .tasks-container__tasks__group__main .content p a:hover {
  color: #00ccff;
}
.process__section .tasks-container__tasks__task__main .content p a.isAlreadyAssigning:hover,
.process__section .tasks-container__tasks__group__main .content p a.isAlreadyAssigning:hover {
  cursor: progress;
}
.process__section .tasks-container__tasks__task__hint,
.process__section .tasks-container__tasks__group__hint {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 auto;
  max-width: 220px;
}
.process__section .tasks-container__tasks__task__hint span,
.process__section .tasks-container__tasks__group__hint span {
  display: inline-table;
  margin-left: 12px;
  font-size: 12px;
  color: #959595;
  white-space: nowrap;
}
.process__section .tasks-container__tasks__task__hint span.process-task-list-due-date,
.process__section .tasks-container__tasks__group__hint span.process-task-list-due-date {
  margin-left: 0;
}
.process__section .tasks-container__tasks__task__hint span.process-task-list-due-date span,
.process__section .tasks-container__tasks__group__hint span.process-task-list-due-date span {
  margin-left: 0;
}
.process__section .tasks-container__tasks__task__hint span.warn,
.process__section .tasks-container__tasks__group__hint span.warn {
  color: #f75f5f;
}
.process__section .tasks-container__tasks__task__hint span:first-child,
.process__section .tasks-container__tasks__group__hint span:first-child {
  margin-left: 0;
}
.process__section .tasks-container__tasks__task__hint span i,
.process__section .tasks-container__tasks__group__hint span i {
  float: left;
  margin: 3px 4px 0 0;
}
.process__section .tasks-container__tasks__task__hint .comments-updated,
.process__section .tasks-container__tasks__group__hint .comments-updated {
  color: #2e9df0;
}
.process__section .tasks-container__tasks__task__hint .items-count:hover,
.process__section .tasks-container__tasks__group__hint .items-count:hover,
.process__section .tasks-container__tasks__task__hint .items-count:focus,
.process__section .tasks-container__tasks__group__hint .items-count:focus {
  color: #2e9df0;
}
.process__section .tasks-container__tasks__task:not(.animated):not(.inactive) {
  cursor: not-allowed;
}
.process__section .tasks-container__tasks__group {
  cursor: pointer;
}
.process__section .tasks-container__tasks__group:not(.inactive):hover h4 {
  color: #2e9df0;
}
.process__section .tasks-container > .data-container {
  height: calc(100% - (16px + 33px));
  padding-right: 2px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.process__section .tasks-container > .data-container:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.process__section .tasks-container > .data-container > .properties {
  padding: 16px;
  border: 1px solid #e1ebef;
}
.process__section .tasks-container > .data-container > .properties:focus {
  outline: none;
}
.process__section .tasks-container > .data-container > .properties [markdown-content] {
  font-size: 12px;
}
.process__section .tasks-container > .data-container > .properties markdown-hint .markdown-hint {
  padding-top: 4px;
}
.process__section .tasks-container > .data-container > .properties > label {
  margin-top: 16px;
}
.process__section .tasks-container > .data-container > .properties > label:first-child {
  margin-top: 0;
}
.process__section .tasks-container > .data-container > .properties > label {
  font-family: 'Roboto-Medium', Arial;
  margin-bottom: 4px;
  display: block;
  font-size: 14px;
}
.process__section .tasks-container > .data-container > .properties > .empty {
  color: #959595;
  font-size: 12px;
}
.process__section .tasks-container > .data-container > .properties > .empty:focus,
.process__section .tasks-container > .data-container > .properties > .descr:focus,
.process__section .tasks-container > .data-container > .properties > .view-managers:focus {
  outline: none;
}
.process__section .tasks-container > .data-container > .properties > .descr {
  padding: 1px 9px;
}
.process__section .tasks-container > .data-container > .properties > .empty {
  padding: 9px 9px;
}
.process__section .tasks-container > .data-container > .properties > .view-managers,
.process__section .tasks-container > .data-container > .properties > .view-starters {
  padding: 1px 1px 5px 5px;
}
.process__section .tasks-container > .data-container > .properties > .view-managers.editable:hover,
.process__section .tasks-container > .data-container > .properties > .descr.editable:hover,
.process__section .tasks-container > .data-container > .properties > .empty.editable:hover {
  border: 1px solid #cacaca;
  border-radius: 4px;
}
.process__section .tasks-container > .data-container > .properties > .descr.editable:hover {
  padding: 0 8px;
}
.process__section .tasks-container > .data-container > .properties > .empty.editable:hover {
  padding: 8px 8px;
}
.process__section .tasks-container > .data-container > .properties > .descr > p:first-child {
  margin-top: 6px;
}
.process__section .tasks-container > .data-container > .properties > .view-managers.editable:hover {
  padding: 0 0 4px 4px;
  cursor: pointer;
}
.process__section .tasks-container > .data-container > .properties .update-description,
.process__section .tasks-container > .data-container > .properties .update-managers {
  margin-top: 0;
}
.process__section .tasks-container > .data-container > .empty-form {
  padding: 24px 12px;
  text-align: center;
  width: 100%;
  border: 1px solid #e1ebef;
}
.process__section .tasks-container > .data-container > .empty-form h4 {
  font-size: 14px;
}
.process__section .tasks-container > .data-container > .empty-form p {
  margin-top: 8px;
  font-family: 'Roboto-Regular', Arial;
  color: #a3a3a3;
  font-size: 12px;
}
.process__section .tasks-container > .data-container > .template-view-data-model {
  margin-top: 0;
}
.process__section .info {
  width: 40%;
  min-width: 40%;
  flex: 0 1 40%;
  height: 100%;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
  overflow: hidden;
  padding-top: 16px;
}
.process__section .info > .icon-common_close {
  color: #cdcdcd;
  font-size: 14px;
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  display: none;
}
.process__section .info > .icon-common_close:hover {
  color: #959595;
}
.process__section .info > aside {
  height: 100%;
}
.process__section .info__history {
  height: calc(100% - (2 * 16px + 2 * 12px));
  margin-top: 24px;
}
.process__section .info__history > main {
  height: 100%;
  overflow: auto;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.process__section .info__history > main:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.process__section .info__history > main > ul {
  padding-bottom: 16px;
}
.process__section .info__history > main > ul > li {
  padding-left: 16px;
  position: relative;
  color: #a3a3a3;
  font-size: 12px;
}
.process__section .info__history > main > ul > li.red:before {
  background-color: #f75f5f;
}
.process__section .info__history > main > ul > li.green:before {
  background-color: #4abc3b;
}
.process__section .info__history > main > ul > li.yellow:before {
  background-color: #f9d83c;
}
.process__section .info__history > main > ul > li:before {
  position: absolute;
  top: 26px;
  left: 0;
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: #e8e8e8;
}
.process__section .info__history > main > ul > li .date {
  color: #222;
  display: block;
  margin-bottom: 8px;
}
.process__section .info__history > main > ul > li + li {
  margin-top: 18px;
}
.task-section__content {
  padding: 0 32px;
  display: flex;
  height: calc(100% - 90px);
}
.task-section__content > article {
  flex: 0 1 calc(60% - (2 * 12px));
  min-width: calc(60% - (2 * 12px));
  width: calc(60% - (2 * 12px));
  margin-right: 12px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.task-section__content > article:focus {
  outline: none;
}
.task-section__content > article > header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 32px;
}
.task-section__content > article > header.with-reject-reason {
  margin-bottom: 4px;
}
.task-section__content > article > header > div {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.task-section__content > article > header > div .main {
  margin-top: 1px;
  display: inline-flex;
  align-items: center;
}
.task-section__content > article > header > div .main > span {
  font-family: 'Roboto-Regular', Arial;
  color: #959595;
}
.task-section__content > article > header > div .main > span.green {
  color: #4abc3b;
}
.task-section__content > article > header > div .main > span.red {
  color: #f75f5f;
}
.task-section__content > article > header > div .main .actor {
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.task-section__content > article > header > div .main .actor .avatar-small {
  margin-right: 5px;
}
.task-section__content > article > header > div .main .actor .badge-deleted {
  height: 14px;
  line-height: 13px;
  width: 14px;
  border-radius: 100%;
  background-color: #f75f5f;
  position: absolute;
  text-align: center;
  bottom: -4px;
  left: 14px;
}
.task-section__content > article > header > div .main .actor .badge-deleted i {
  font-size: 8px;
  margin: 0 0 0 1px;
  color: #fff;
}
.task-section__content > article > header > div .available-list {
  display: flex;
  align-items: center;
  min-height: 30px;
}
.task-section__content > article > header > div .available-list .available-label {
  white-space: nowrap;
}
.task-section__content > article > header > div .available-list > span:not(:first-child) {
  flex: 1 0 auto;
}
.task-section__content > article > header > div .available-list .selectize-control {
  position: absolute;
  left: 88px;
  top: 0;
  width: calc(100% - 88px);
  max-width: calc(100% - 88px);
  background: #fff;
  z-index: 5;
}
.task-section__content > article > header > div .available-list .selectize-control .selectize-input.active + .selectize-dropdown {
  overflow: visible;
}
.task-section__content > article > header > div .available-list .selectize-control .selectize-dropdown {
  width: 100%;
  overflow: hidden;
}
.task-section__content > article > header > div .available-list .selectize-control .selectize-dropdown > .dropdown-list {
  visibility: hidden;
}
.task-section__content > article > header > div .available-list .selectize-control .selectize-dropdown > .dropdown-list.active {
  visibility: visible;
}
.task-section__content > article > header > div .available-list .selectize-control .selectize-dropdown > .dropdown-list > ul > li {
  display: block;
}
.task-section__content > article > header > div .available-list .selectize-control .selectize-dropdown > .dropdown-list > ul > li .users {
  margin-left: 26px;
}
.task-section__content > article > header > div .available-list .selectize-control .selectize-dropdown > .dropdown-list > ul > li.role {
  margin-left: 33px;
}
.task-section__content > article > header > div span,
.task-section__content > article > header > div div {
  display: inline-block;
  font-size: 12px;
}
.task-section__content > article > header > div > div {
  display: flex;
}
.task-section__content > article > header > div .available-label,
.task-section__content > article > header > div > div {
  color: #959595;
  font-family: 'Roboto-Regular', Arial;
}
.task-section__content > article > header > div .available-label > ul,
.task-section__content > article > header > div > div > ul {
  display: inline-flex;
  margin-left: 8px;
}
.task-section__content > article > header > div .available-label > ul.hidden,
.task-section__content > article > header > div > div > ul.hidden {
  visibility: hidden;
}
.task-section__content > article > header > div .available-label > ul.hidden .popup-actors,
.task-section__content > article > header > div > div > ul.hidden .popup-actors {
  visibility: hidden !important;
}
.task-section__content > article > header > div .available-label > ul.hidden li button,
.task-section__content > article > header > div > div > ul.hidden li button {
  visibility: hidden !important;
  transition: none;
}
.task-section__content > article > header > div .available-label > ul li + li,
.task-section__content > article > header > div > div > ul li + li {
  margin-left: 12px;
}
.task-section__content > article > header > div .available-label.assignee-name,
.task-section__content > article > header > div > div.assignee-name {
  color: #222;
  margin-left: 4px;
}
.task-section__content > article > header > div .available-label .group,
.task-section__content > article > header > div > div .group {
  margin-left: 8px;
  white-space: nowrap;
  color: #222;
  display: flex;
  align-items: center;
}
.task-section__content > article > header > div .available-label .group:focus,
.task-section__content > article > header > div > div .group:focus {
  outline: none;
}
.task-section__content > article > header > div .available-label .group.editable,
.task-section__content > article > header > div > div .group.editable {
  cursor: pointer;
  padding-right: 3px;
}
.task-section__content > article > header > div .available-label .group.editable:hover,
.task-section__content > article > header > div > div .group.editable:hover {
  border: 1px #cacaca solid;
  border-radius: 4px;
  padding: 2px;
  margin-left: 5px;
}
.task-section__content > article > header > div .available-label .group .text,
.task-section__content > article > header > div > div .group .text {
  color: #222;
  margin-left: 4px;
}
.task-section__content > article > header > div .available-label .group .avatar-small,
.task-section__content > article > header > div > div .group .avatar-small {
  background: #e8e8e8;
  color: #959595;
}
.task-section__content > article > header > div .available-label .group .avatar-small i.icon-assignee_group,
.task-section__content > article > header > div > div .group .avatar-small i.icon-assignee_group {
  display: block;
  font-size: 14px;
  width: auto;
}
.task-section__content > article > header > div .available-label .actors,
.task-section__content > article > header > div > div .actors {
  color: #222;
  display: inline-flex;
  align-items: center;
  flex: 1 0 auto;
  width: calc(100% - 96px);
  flex-wrap: wrap;
}
.task-section__content > article > header > div .available-label .actors:focus,
.task-section__content > article > header > div > div .actors:focus {
  outline: none;
}
.task-section__content > article > header > div .available-label .actors > :first-child,
.task-section__content > article > header > div > div .actors > :first-child {
  display: table !important;
}
.task-section__content > article > header > div .available-label .actors.editable,
.task-section__content > article > header > div > div .actors.editable {
  cursor: pointer;
  padding-right: 3px;
}
.task-section__content > article > header > div .available-label .actors.editable:hover:not(.blocked),
.task-section__content > article > header > div > div .actors.editable:hover:not(.blocked) {
  border: 1px #cacaca solid;
  border-radius: 4px;
  padding: 2px;
  margin-left: 5px;
}
.task-section__content > article > header > div .available-label .actors.editable:hover:not(.blocked) > :first-child,
.task-section__content > article > header > div > div .actors.editable:hover:not(.blocked) > :first-child {
  margin-left: 0;
}
.task-section__content > article > header > div .available-label .actors.hidden,
.task-section__content > article > header > div > div .actors.hidden {
  visibility: hidden;
}
.task-section__content > article > header > div .available-label .actors .group > span:not(.text),
.task-section__content > article > header > div > div .actors .group > span:not(.text) {
  width: 24px;
  text-align: center;
}
.task-section__content > article > header > div .available-label .actors > div,
.task-section__content > article > header > div > div .actors > div {
  display: table;
}
.task-section__content > article > header > div .available-label .actors .user,
.task-section__content > article > header > div > div .actors .user {
  white-space: nowrap;
  margin-left: 8px;
  display: table;
}
.task-section__content > article > header > div .available-label .actors .user .text,
.task-section__content > article > header > div > div .actors .user .text {
  color: #222;
  margin-left: 4px;
}
.task-section__content > article > header > div .available-label .actors .user div,
.task-section__content > article > header > div > div .actors .user div {
  display: inline-block;
}
.task-section__content > article > header .popup-actors {
  display: flex;
  align-items: center;
  margin-right: 12px;
  visibility: hidden;
  position: relative;
}
.task-section__content > article > header .popup-actors + li {
  margin-left: 0;
}
.task-section__content > article > header .popup-actors.showed {
  visibility: visible;
}
.task-section__content > article > header .popup-actors.hide {
  display: none;
}
.task-section__content > article > header .popup-actors:hover .popup-actors-list {
  visibility: visible;
}
.task-section__content > article > header .popup-actors > span {
  white-space: nowrap;
  background: #e8e8e8;
  height: 24px;
  min-width: 24px !important;
  padding: 0 4px;
  line-height: 24px;
  border-radius: 8px;
  font-family: 'Roboto-Regular', Arial;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  color: #959595;
  font-size: 12px;
  cursor: pointer;
}
.task-section__content > article > header .date-block {
  color: #959595;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  width: auto;
  flex: 1 0 auto;
  margin-left: 12px;
  height: 32px;
}
.task-section__content > article > header .date-block > .popup-actors {
  display: none;
  top: 0;
  position: absolute;
  right: 30px;
  visibility: hidden;
}
.task-section__content > article > header .date-block > .popup-actors.showed {
  visibility: visible !important;
}
.task-section__content > article > header .date-block > .popup-actors.hide {
  display: none !important;
}
.task-section__content > article > header .date-block > .popup-actors-list .container > div {
  display: flex !important;
  align-items: center;
}
.task-section__content > article > header .date-block > .popup-actors div,
.task-section__content > article > header .date-block > .popup-actors span {
  display: inline-block;
  font-size: 12px;
}
.task-section__content > article > header .date-block > .popup-actors i {
  font-size: inherit;
  line-height: normal;
}
.task-section__content > article > header .date-block > .comments-toggler {
  border: 1px #989898 solid;
  color: #989898;
  padding: 6px 40px 6px 8px;
  height: 32px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  margin-right: -48px;
  display: none;
  margin-left: 12px;
  font-size: 14px;
}
.task-section__content > article > header .date-block > .comments-toggler:focus {
  outline: none;
}
.task-section__content > article > header .date-block > .comments-toggler i {
  font-size: 16px;
  line-height: 18px;
}
.task-section__content > article > header .date-block div:not(.prc-date-picker):not(.popup-actors),
.task-section__content > article > header .date-block span:not(.prc-date-picker) {
  margin-right: 4px;
  display: table;
}
.task-section__content > article > header .date-block div:not(.prc-date-picker):not(.popup-actors) > span,
.task-section__content > article > header .date-block span:not(.prc-date-picker) > span {
  display: block;
}
.task-section__content > article > header .date-block div:not(.prc-date-picker):not(.popup-actors) > span.prc-date-picker,
.task-section__content > article > header .date-block span:not(.prc-date-picker) > span.prc-date-picker {
  display: inline-flex;
}
.task-section__content > article > header .date-block div:not(.prc-date-picker):not(.popup-actors) > span.red,
.task-section__content > article > header .date-block span:not(.prc-date-picker) > span.red {
  color: #f75f5f;
}
.task-section__content > article > header .date-block .prc-date-picker {
  margin-right: 0;
  display: inline-flex;
}
.task-section__content > article > header .date-block .prc-date-picker > span {
  display: inline-flex;
}
.task-section__content > article > header .date-block .prc-date-picker .handler,
.task-section__content > article > header .date-block .prc-date-picker .handler i {
  margin-right: 0 !important;
}
.task-section__content > article > header .date-block i {
  font-size: 30px;
  line-height: 30px;
}
.task-section__content > article > header .date-block i::before {
  margin-top: 1px;
}
.task-section__content > article > .task-reject-reason {
  margin-bottom: 4px;
}
.task-section__content > article > .data-model-errors {
  height: 40px;
  border: 1px solid #f75f5f;
  display: flex;
  align-items: center;
  padding: 0 12px;
  color: #f75f5f;
  font-size: 12px;
  margin: 8px 0;
}
.task-section__content > article > .data-model-errors > i {
  margin-right: 12px;
  font-size: 14px;
}
.task-section__content > article > .data-model-errors > a {
  margin-left: 12px;
  color: #f75f5f;
  display: inline-block;
  border: 1px solid #f75f5f;
  border-radius: 16px;
  padding: 2px 8px;
  white-space: nowrap;
}
.task-section__content > article > .data-model-errors > a:active {
  background: #f75f5f;
  color: #fff;
}
.task-section__content > article > main {
  overflow: auto;
  overflow-x: hidden;
  padding: 1px;
  width: calc(100% - 2px);
  height: calc(100% - (3 * 16px));
  flex: 0 1 calc(100% - (3 * 16px));
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.task-section__content > article > main:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.task-section__content > article > main.with-error {
  height: calc(100% - (3 * 16px + 48px));
  flex: 0 1 calc(100% - (3 * 16px + 48px));
}
.task-section__content > article > main markdown-hint {
  visibility: hidden;
}
.task-section__content > article > main markdown-hint .markdown-hint {
  padding-top: 4px;
}
.task-section__content > article > main > :first-child {
  margin-top: 0;
}
.task-section__content > article > main > .task-description-wrapper {
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  max-width: 100%;
  word-wrap: break-word;
  position: relative;
  padding: 4px;
}
.task-section__content > article > main > .task-description-wrapper:focus {
  outline: none;
}
.task-section__content > article > main > .task-description-wrapper:not(.active) {
  margin-left: -5px;
}
.task-section__content > article > main > .task-description-wrapper .actions {
  position: absolute;
  top: 0;
  right: 8px;
  padding: 8px;
  overflow: hidden;
  white-space: nowrap;
}
.task-section__content > article > main > .task-description-wrapper .actions .btn {
  opacity: 0;
  transition: opacity 0.2s;
  color: #a3a3a3;
}
.task-section__content > article > main > .task-description-wrapper .actions .btn:hover {
  color: #2e9df0;
}
.task-section__content > article > main > .task-description-wrapper.editable:hover:not(.active) .actions .btn {
  opacity: 1;
}
.task-section__content > article > main > .task-description-wrapper.editable:focus {
  outline: none;
}
.task-section__content > article > main > .task-description-wrapper.editable.active {
  display: flex;
  padding-bottom: 0;
  padding-right: 0;
}
.task-section__content > article > main > .task-description-wrapper.editable.active textarea {
  width: calc(100% + 8px);
  position: relative;
}
.task-section__content > article > main > .task-description-wrapper.editable.active div,
.task-section__content > article > main > .task-description-wrapper.editable.active p,
.task-section__content > article > main > .task-description-wrapper.editable.active .actions {
  display: none;
}
.task-section__content > article > main > .task-description-wrapper.editable.active + markdown-hint {
  visibility: visible;
}
.task-section__content > article > main > .task-description-wrapper > .hint {
  color: #959595;
  margin-bottom: 3px;
  display: inline-block;
}
.task-section__content > article > main > .task-description-wrapper > .hint:focus {
  outline: none;
}
.task-section__content > article > main > .task-description-wrapper .hint + .actions {
  position: static;
  top: unset;
  right: unset;
  padding: 0 0 5px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.task-section__content > article > main > .task-description-wrapper > textarea {
  font-weight: normal;
  border: 1px #cacaca solid;
  border-radius: 4px;
  margin-top: -4px;
  margin-left: -4px;
  background: #fff;
  width: 100%;
  z-index: 4;
  padding: 4px;
  resize: none;
  position: absolute;
  line-height: 18px;
  top: 0;
}
.task-section__content > article > main > .task-description-wrapper > textarea:focus {
  cursor: text;
  outline: none;
}
.task-section__content > article > main > .task-description-wrapper > div {
  font-size: 14px;
  line-height: 18px;
  max-width: 100%;
}
.task-section__content > article > main > .task-description-wrapper > div:focus {
  outline: none;
}
.task-section__content > article > main > .task-description-wrapper p {
  margin: 16px 0;
}
.task-section__content > article > main > .task-description-wrapper a {
  color: #2e9df0;
}
.task-section__content > article > main > .task-description-wrapper a:hover {
  opacity: 0.8;
}
.task-section__content > article > main > .task-description-wrapper :first-child {
  margin-top: 0;
}
.task-section__content > article > main > .task-description-wrapper :last-child {
  margin-bottom: 0;
}
.task-section__content > article > main > .task-description-wrapper + .task-data-model {
  margin-left: -4px;
}
.task-section__content > aside {
  min-width: 40%;
  flex: 0 1 40%;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  border-left: 1px solid #e1ebef;
}
.task-section__content > aside > .tabs {
  padding-left: 12px;
}
.task-section {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
.task-section__header {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 90px;
  background-color: #fff;
  padding: 0 64px 0 32px;
  position: relative;
  border-bottom: 1px solid #e1ebef;
}
.task-section__header > .icon-common_close {
  color: #cdcdcd;
  font-size: 14px;
  position: absolute;
  top: 12px;
  right: 18px;
  cursor: pointer;
}
.task-section__header > .icon-common_close:hover {
  color: #959595;
}
.task-section__header > .common_back {
  left: 0;
  font-size: 40px;
  top: 20px;
  width: 20px;
  display: inline-block;
  color: #cdcdcd;
  position: absolute;
  right: 18px;
  cursor: pointer;
}
.task-section__header > .common_back:focus {
  outline: none;
}
.task-section__header > .common_back > i {
  margin: 0;
}
.task-section__header > .common_back:hover {
  color: #2e9df0;
}
.task-section__header .navigation {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 21px;
  margin-right: 12px;
}
.task-section__header .navigation.approval > a {
  line-height: 32px;
}
.task-section__header .navigation > a {
  color: #e8e8e8;
  font-size: 10px;
  display: block;
  width: 20px;
  line-height: 35px;
  cursor: pointer;
}
.task-section__header .navigation > a[disabled] {
  user-select: none;
  cursor: default;
  opacity: 0;
}
.task-section__header .navigation > a[disabled]:hover {
  color: #e8e8e8;
}
.task-section__header .navigation > a:hover {
  color: #959595;
}
.task-section__header .navigation span {
  display: block;
  background: transparent;
}
.task-section__header .title {
  flex: 1 1;
  min-width: 0;
  padding-top: 13px;
}
.task-section__header .title > a,
.task-section__header .title > span {
  color: #959595;
  font-size: 12px;
  display: inline-block;
  min-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.task-section__header .title > a.hidden,
.task-section__header .title > span.hidden {
  visibility: hidden;
}
.task-section__header .title > .title-input {
  position: relative;
}
.task-section__header .title > .title-input.editable h1 {
  padding: 1px 4px 0 4px;
  margin-left: -4px;
  font-family: 'Roboto-Regular', Arial;
}
.task-section__header .title > .title-input.editable h1:focus {
  outline: none;
}
.task-section__header .title > .title-input.editable:hover h1 {
  border: 1px #cacaca solid;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 3px 0 3px;
}
.task-section__header .title > .title-input h1 {
  color: #222;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  font-size: 21px;
  max-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-section__header .title > .title-input h1:focus {
  outline: none;
}
.task-section__header .title > .title-input > textarea {
  color: #222;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  font-size: 21px;
  max-height: 52px;
  overflow: hidden;
  resize: none;
  position: absolute;
  top: 1px;
  left: -4px;
  background: transparent;
  padding: 0 4px;
  width: 100%;
  outline: none;
  border: none;
  cursor: text;
}
.task-section__header .title > .title-input > input {
  color: #222;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  font-size: 21px;
  max-height: 24px;
  overflow: hidden;
  resize: none;
  position: absolute;
  top: 2px;
  left: -4px;
  background: transparent;
  padding: 0 4px;
  width: 100%;
  outline: none;
  border: none;
  cursor: text;
  display: none;
}
.task-section__header .btns {
  flex: 0 1 auto;
  min-width: 160px;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-end;
  display: inline-flex;
  position: relative;
  padding-left: 8px;
}
.task-section__header .btns > span .btn + .btn {
  display: none;
}
.task-section__header .btns > span > .btn.ng-leave,
.task-section__header .btns > span > .btns-approve-reject.ng-leave > .btn {
  transition: none;
}
.task-section__header .btns > span:not(.completed) .btn-simple-reopen {
  display: none !important;
}
.task-section__header .btns > span.completed .btn-simple-complete {
  display: none !important;
}
.task-section__header .btns .btns-approve-reject {
  display: flex;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval {
  display: flex !important;
  align-items: center;
  padding: 0 12px 0 0;
  cursor: pointer;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval > .addon {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval > .addon i {
  font-size: 8px;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval > .addon .spinner {
  display: none;
  font-size: 15px;
  animation: rotate 0.3s linear infinite;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval > .addon.animated {
  background: transparent;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval > .addon.animated i {
  display: none;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval > .addon.animated .spinner {
  display: block;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval.btn-approve {
  background-color: #50BE41 !important;
  color: #fff !important;
  margin-right: 8px;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval.btn-approve i {
  color: #50BE41 !important;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval.btn-reject {
  background-color: #f75f5f !important;
  color: #fff !important;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval.btn-reject i {
  color: #f75f5f !important;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval.disabled {
  cursor: not-allowed;
  background: #e8e8e8 !important;
  color: #959595 !important;
}
.task-section__header .btns .btns-approve-reject .btn.btn-approval.disabled i {
  color: #959595 !important;
}
.task-section__header .btns .review-force-actions .dropdown .dropdown-toggle i {
  font-size: 5px;
  line-height: 32px;
  vertical-align: middle;
}
.task-section__header .btns .review-force-actions .dropdown .dropdown-menu {
  background: #fff;
  border: 1px #cacaca solid;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 10;
  right: 0;
  margin-top: 4px;
  min-width: 140px;
}
.task-section__header .btns .review-force-actions .dropdown .dropdown-menu a {
  font-size: 14px;
  color: #222;
  padding: 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.task-section__header .btns .review-force-actions .dropdown .dropdown-menu a:hover {
  color: #2e9df0;
}
.task-section__header .btns .action {
  font-size: 32px;
  flex: 1 0 32px;
  max-width: 32px;
  border: none;
  color: #cdcdcd;
  padding: 0;
  background: transparent;
  margin-left: 12px;
}
.task-section__header .btns .action:focus {
  outline: none;
}
.task-section__header .btns .action > i {
  margin: 0;
}
.task-section__header .btns .action[disabled='disabled'] {
  color: #e8e8e8 !important;
  cursor: not-allowed;
}
.task-section__header .btns .action[disabled='disabled']:hover {
  color: #e8e8e8 !important;
}
.task-section__header .btns .action:hover {
  color: #2e9df0;
}
.task-section__header .btns > span {
  position: relative;
}
.task-section__header .btns > span.behalf {
  display: inline-table;
}
.task-section__header .btns > span.behalf .btn-green {
  padding-right: 30px;
}
.task-section__header .btns > span.with-review-actions .btns-approve-reject .btn-green {
  padding-right: 30px;
}
.task-section__header .btns .btn:not(.btn-green):not(.btn-icon) {
  font-size: 14px;
  color: #9d9d9d;
  text-decoration: none;
  line-height: 30px;
  position: relative;
  border: 1px #cacaca solid;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border-radius: 16px;
  transition: all 0.2s ease;
  white-space: nowrap;
}
.task-section__header .btns .btn:not(.btn-green):not(.btn-icon):focus {
  outline: none;
}
.task-section__header .btns .btn:not(.btn-green):not(.btn-icon).ellipsis i {
  font-size: 18px;
}
.task-section__header .btns .btn:not(.btn-green):not(.btn-icon).disabled {
  cursor: not-allowed;
}
.task-section__header .btns .btn:not(.btn-green):not(.btn-icon)[disabled='disabled'] {
  opacity: 0.4;
  cursor: not-allowed !important;
  border-color: #cacaca !important;
}
.task-section__header .btns .btn:not(.btn-green):not(.btn-icon)[disabled='disabled']:hover {
  color: inherit;
  border-color: inherit;
  opacity: 0.4;
}
.task-section__header .btns .btn:not(.btn-green):not(.btn-icon):not(:disabled):not(.disabled):not(:active):hover {
  color: #2e9df0;
  border-color: #2e9df0;
}
.task-section__header .btns .btn:not(.btn-green):not(.btn-icon):not(:disabled):not(.disabled):not(:active):hover > i {
  color: #2e9df0;
}
.task-section {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
@media only screen and (max-width: 1023px) {
  .task-section {
    overflow-y: auto;
  }
  .task-section__header {
    padding: 0 16px 0 32px !important;
  }
  .task-section__header .title {
    flex: 1 1;
    min-width: 0;
  }
  .task-section__header__close {
    display: none !important;
  }
  .task-section__content {
    padding: 0 16px;
    height: calc(100% - 90px);
    position: relative;
  }
  .task-section__content > article {
    width: 100%;
    flex: 0 1 100%;
    max-width: 100%;
    margin-right: 0;
  }
  .task-section__content > article .date-block .comments-toggler {
    display: inline-block !important;
  }
  .task-section__content > article > main {
    height: auto;
  }
  .task-section__content > aside {
    flex: 1 0 100%;
    min-width: 100%;
    width: 100%;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: 2;
    padding-right: 12px;
    min-height: 100%;
  }
  .task-section__content > aside .comments {
    min-height: calc(100% - 64px) !important;
  }
  .task-section__content > aside.ng-hide-add,
  .task-section__content > aside.ng-hide-remove {
    transition: all 0.15s ease;
  }
  .task-section__content > aside.ng-hide {
    transform: translate(100%, 0);
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .task-section__header {
    flex-wrap: wrap;
    justify-content: normal;
    height: 115px;
  }
  .task-section__header .btns {
    flex: 0 0 100%;
    margin-top: -50px;
    justify-content: space-between;
    padding-left: 33px;
  }
}
@media only screen and (max-width: 414px) {
  .task-section__header {
    height: 74px !important;
  }
  .task-section__header .common_back {
    top: 10px;
  }
  .task-section__header .navigation > a {
    line-height: 24px !important;
  }
  .task-section__header .btns {
    min-width: 40px !important;
  }
  .task-section__header .btns > span {
    display: none !important;
  }
  .task-section__header .title {
    flex: 1 1;
    min-width: 0;
    padding-top: 8px !important;
  }
  .task-section__header .title .title-input {
    white-space: nowrap;
  }
  .task-section__header .title .title-input > textarea {
    display: none;
  }
  .task-section__header .title .title-input > input {
    display: block;
  }
  .task-section__content {
    padding: 0 12px 0 16px;
    min-height: calc(100% - 70px);
  }
  .task-section__content.inactive > article > header > div.date-block {
    padding-top: 0;
  }
  .task-section__content.inactive > article > header > div.date-block > .due-data {
    top: 0;
  }
  .task-section__content > article > header {
    min-height: 75px;
  }
  .task-section__content > article > header > div.date-block {
    padding-top: 41px;
    position: relative;
    margin-left: 0;
    display: block;
  }
  .task-section__content > article > header > div.date-block > .due-data {
    position: absolute;
    right: 32px;
    top: 41px;
  }
  .task-section__content > article > header > div.date-block > .popup-actors {
    display: inline-flex;
  }
  .task-section__content > article > header > div.date-block .comments-toggler {
    position: absolute;
    top: -4px;
    right: 8px;
    z-index: 1;
  }
  .task-section__content > article > header > div > div {
    display: block;
  }
  .task-section__content > article > header > div > div + div {
    margin-right: 0;
  }
  .task-section__content > article > header > div > div > ul {
    margin-top: 16px;
    margin-left: 0;
    display: flex;
  }
  .task-section__content > article > header > div > div > ul > .popup-actors {
    display: none;
  }
}
@media only screen and (max-width: 340px) {
  .task-section .unassign-button {
    display: none;
  }
}
.template__header--single {
  display: flex;
  align-items: center;
  height: 64px;
  background-color: #fff;
  padding: 0 64px 0 32px;
  position: relative;
  border-bottom: 1px solid #e1ebef;
}
.template__header--single__icon {
  flex: 0 1 40px;
  line-height: 40px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  vertical-align: middle;
  text-align: center;
  border-radius: 13px;
  color: #40484c;
  margin-right: 12px;
  overflow: hidden;
}
.template__header--single__icon.default {
  background-color: #ccc;
}
.template__header--single__icon.default i {
  line-height: 40px;
}
.template__header--single__icon i {
  font-size: 24px;
  line-height: 40px;
}
.template__header--single__icon i.icon-Mens {
  font-size: 26px;
}
.template__header--single__icon i.icon-Magnifi-Glass2 {
  font-size: 22px;
}
.template__header--single__main {
  display: flex;
  align-items: center;
}
.template__header--single__main__content > span {
  color: #959595;
  font-size: 9px;
  text-transform: uppercase;
  font-family: 'Roboto-Regular', Arial;
  letter-spacing: 0.5px;
  display: block;
}
.template__header--single__main__content > .title {
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
}
.template__header--single__main__content > .title h1 {
  font-size: 21px;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  color: #222;
  width: 100%;
  clear: both;
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
}
.template__header--single__main__btns {
  flex: 0 1 auto;
  min-width: 230px;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-end;
  display: inline-flex;
  position: relative;
  padding-left: 8px;
}
.template__header--single__main__btns > .btn {
  flex: 0 1 auto;
}
.template__header--single__main__btns .action {
  font-size: 32px;
  max-width: 32px;
  min-width: 32px;
  border: none;
  color: #cdcdcd;
  padding: 0;
  background: transparent;
  margin-left: 8px;
  cursor: pointer;
}
.template__header--single__main__btns .action:focus {
  outline: none;
}
.template__header--single__main__btns .action > i {
  margin: 0;
}
.template__header--single__main__btns .action[disabled='disabled'] {
  color: #e8e8e8 !important;
  cursor: not-allowed;
}
.template__header--single__main__btns .action[disabled='disabled']:hover {
  color: #e8e8e8 !important;
}
.template__header--single__main__btns .action:hover,
.template__header--single__main__btns .action.active {
  color: #2e9df0;
}
.template__header--single > a {
  color: #cdcdcd;
  font-size: 13px;
  position: absolute;
  top: 12px;
  right: 18px;
  cursor: pointer;
}
.template__header--single > a:hover {
  color: #959595;
}
.template__header--single > a.common_back {
  left: 0;
  font-size: 40px;
  top: 8px;
  width: 20px;
  display: inline-block;
}
.template__header--single > a.common_back:focus {
  outline: none;
}
.template__header--single > a.common_back > i {
  margin: 0;
}
.template__header--single > a.common_back:hover {
  color: #2e9df0;
}
.template__section--single__process-details {
  padding: 16px 24px 100px 32px;
  position: relative;
  min-height: 100%;
  border: 1px solid #e1ebef;
  line-height: normal;
}
.template__section--single__process-details:focus {
  outline: none;
}
.template__section--single__process-details__tabs {
  margin: 0 0 24px 0;
}
.template__section--single__process-details__tabs li + li {
  margin-left: 12px;
}
.template__section--single__process-details .template-task-data-model__content__section.hidden {
  display: none;
}
.template__section--single__process-details .template-task-data-model__content__section__fields__field.hidden {
  display: none;
}
.template__section--single__process-details .template-task-data-model__content__section__fields__field:first-child.hidden {
  display: block;
  visibility: hidden;
  height: 0;
  margin: 12px 0 0;
}
.template__section--single__process-details.ng-hide-add,
.template__section--single__process-details.ng-hide-remove {
  transition: all linear 0.2s;
}
.template__section--single__process-details.ng-hide {
  opacity: 0;
}
.template__section--single__process-details > .content {
  width: 100%;
}
.template__section--single__process-details > .content > div > div {
  display: flex;
}
.template__section--single__process-details > .content > div > div.offset > label {
  padding-top: 8px;
}
.template__section--single__process-details > .content > div > div.execution > span {
  font-size: 12px;
}
.template__section--single__process-details > .content > div > div.execution > span > i:first-child {
  font-size: 14px;
}
.template__section--single__process-details > .content > div > div.instructions {
  display: block;
}
.template__section--single__process-details > .content > div > div.instructions label {
  line-height: 21px;
}
.template__section--single__process-details > .content > div > div > label {
  font-family: 'Roboto-Medium', Arial;
  font-size: 12px;
  flex: 0 1 150px;
  min-width: 150px;
  width: 150px;
}
.template__section--single__process-details > .content > div > div > .empty {
  font-size: 12px;
}
.template__section--single__process-details > .content > div > div > .empty .icon-task_due_date_add_small {
  font-size: 16px;
}
.template__section--single__process-details > .content > div > div + div {
  margin-top: 24px;
}
.template__section--single__process-details > .content > div > .instructions > .empty {
  font-size: 12px;
  line-height: 21px;
}
.template__section--single__process-details > .content > div > .instructions [markdown-content] {
  margin-top: -8px;
  font-size: 12px;
}
.template__section--single {
  height: calc(100% - (4 * 16px));
  padding: 0 32px;
}
.template__section--single__header {
  display: flex;
  margin-top: 16px;
}
.template__section--single__header > div {
  width: 50%;
}
.template__section--single__header .template__section__tabs {
  flex: 0 1 50%;
  margin-right: -17px;
}
.template__section--single__header__due-date {
  color: #222;
  font-size: 12px;
  font-family: 'Roboto-Regular', Arial;
}
.template__section--single__header__info {
  display: flex;
  align-items: center;
  padding-left: 32px;
  height: 32px;
}
.template__section--single__header__info .avatar-wrapper {
  margin-right: 6px;
  cursor: pointer;
  position: relative;
  min-width: 24px;
}
.template__section--single__header__info .avatar-wrapper .user-tooltip {
  left: 1px;
  right: auto;
}
.template__section--single__header__info .avatar-wrapper .user-tooltip .arrow {
  left: 5px;
  right: auto;
}
.template__section--single__header__info .avatar-wrapper:hover .user-tooltip {
  visibility: visible;
}
.template__section--single__header__info .main {
  font-family: 'Roboto-Regular', Arial;
  font-size: 12px;
  color: #a0a4a6;
}
.template__section--single__header__info .main span {
  color: #222;
}
.template__section--single__columns {
  display: flex;
  height: 100%;
}
.template__section--single .tasks-container {
  flex: 0 1 50%;
  min-width: 50%;
  width: 50%;
  height: 100%;
  position: relative;
  z-index: 5;
  margin-right: -17px;
}
.template__section--single .tasks-container > .data-container {
  height: calc(100% - (16px + 33px));
  padding-right: 2px;
  overflow: auto;
}
.template__section--single .tasks-container > .data-container:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.template__section--single .tasks-container > .data-container > .properties {
  padding: 16px;
  border: 1px solid #e1ebef;
}
.template__section--single .tasks-container > .data-container > .properties:focus {
  outline: none;
}
.template__section--single .tasks-container > .data-container > .properties > .short-description > p:not(.empty) {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 12px;
  line-height: 18px;
}
.template__section--single .tasks-container > .data-container > .properties > div > label {
  font-family: 'Roboto-Medium', Arial;
  margin-bottom: 4px;
  display: block;
  font-size: 14px;
}
.template__section--single .tasks-container > .data-container > .properties > div > .empty {
  font-size: 12px;
}
.template__section--single .tasks-container > .data-container > .properties > div + div {
  margin-top: 24px;
}
.template__section--single .tasks-container > .data-container > .empty-form {
  padding: 24px 12px;
  text-align: center;
  width: 100%;
  border: 1px solid #e1ebef;
}
.template__section--single .tasks-container > .data-container > .empty-form h4 {
  font-size: 14px;
}
.template__section--single .tasks-container > .data-container > .empty-form p {
  margin-top: 8px;
  font-family: 'Roboto-Regular', Arial;
  color: #a3a3a3;
  font-size: 12px;
}
.template__section--single .tasks-container > .data-container > .template-view-data-model {
  margin-top: 0;
}
.template__section--single .tasks-container--wrapper {
  border-top: 1px solid #e1ebef;
  height: 100%;
}
.template__section--single .tasks-container__process-start > .avatar {
  display: inline-block;
  background: #d6f7d3;
  text-align: center;
  margin-right: 12px;
}
.template__section--single .tasks-container__process-start > .avatar:after {
  display: inline-block;
  content: '';
  transform: translate(5px, 2px);
  height: 2px;
  border: 7px solid transparent;
  border-right-width: 10px;
  border-left: 11px solid #9fcf9b;
}
.template__section--single .tasks-container__group-summary .group-icon {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 12px 0 0;
}
.template__section--single .tasks-container__group-summary .group-icon .icon-template_folder {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #d6f7d3;
  font-size: 33px;
  margin-top: -17px;
  margin-left: -20px;
  z-index: 1;
}
.template__section--single .tasks-container__group-summary .group-icon .icon-template_folder_parallel {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #9fcf9b;
  font-size: 13px;
  margin-top: -5px;
  margin-left: -7px;
  z-index: 2;
}
.template__section--single .tasks-container__group-summary .group-icon .icon-template_folder_sequence {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #9fcf9b;
  font-size: 13px;
  margin-top: -5px;
  margin-left: -7px;
  z-index: 2;
}
.template__section--single .tasks-container__process-start,
.template__section--single .tasks-container__group-summary {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 12px;
  border: 1px solid #e1ebef;
  border-left-width: 4px;
  position: relative;
  width: calc(100% - 16px);
  cursor: pointer;
  font-size: 14px;
}
.template__section--single .tasks-container__process-start:focus,
.template__section--single .tasks-container__group-summary:focus {
  outline: none;
}
.template__section--single .tasks-container__process-start .square,
.template__section--single .tasks-container__group-summary .square {
  height: 15px;
  width: 15px;
  right: -8px;
  z-index: 100;
  top: 50%;
  margin-top: -8px;
  transform: rotate(45deg);
  background-color: #fff;
  position: absolute;
  border-right: 1px solid #2e9df0;
  border-top: 1px solid #2e9df0;
  display: none;
}
.template__section--single .tasks-container__process-start.active,
.template__section--single .tasks-container__group-summary.active {
  border-color: #2e9df0;
  border-top: 1px solid #2e9df0;
  z-index: 5;
}
.template__section--single .tasks-container__process-start.active .square,
.template__section--single .tasks-container__group-summary.active .square {
  display: block;
}
.template__section--single .tasks-container__tasks {
  padding: 0;
  margin: 0;
  height: calc(100% - (16px + 33px));
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.template__section--single .tasks-container__tasks:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.template__section--single .tasks-container__tasks__item {
  width: calc(100% - 16px);
  border: 1px solid #e1ebef;
  border-left-width: 4px;
  position: relative;
}
.template__section--single .tasks-container__tasks__item:focus {
  outline: none;
}
.template__section--single .tasks-container__tasks__item:hover {
  cursor: pointer;
}
.template__section--single .tasks-container__tasks__item.active {
  border-color: #2e9df0;
}
.template__section--single .tasks-container__tasks__item.active .square {
  display: block;
}
.template__section--single .tasks-container__tasks__item:not(.active) {
  border-top: 0;
  padding-top: 1px;
}
.template__section--single .tasks-container__tasks__task,
.template__section--single .tasks-container__tasks__group {
  display: flex;
  align-items: center;
  height: 64px;
}
.template__section--single .tasks-container__tasks__task:focus,
.template__section--single .tasks-container__tasks__group:focus {
  outline: none;
}
.template__section--single .tasks-container__tasks__task .content,
.template__section--single .tasks-container__tasks__group .content {
  width: calc(100% - (40px + 2 * 12px));
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
}
.template__section--single .tasks-container__tasks__task .content > div,
.template__section--single .tasks-container__tasks__group .content > div {
  margin-right: 20px;
  overflow: hidden;
}
.template__section--single .tasks-container__tasks__task .content > div h4,
.template__section--single .tasks-container__tasks__group .content > div h4 {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.template__section--single .tasks-container__tasks__task .content > div p,
.template__section--single .tasks-container__tasks__group .content > div p {
  font-size: 12px;
  display: table;
  margin-top: 4px;
}
.template__section--single .tasks-container__tasks__task .content > span,
.template__section--single .tasks-container__tasks__group .content > span {
  font-size: 12px;
  color: #9c9c9c;
  text-align: right;
  white-space: nowrap;
}
.template__section--single .tasks-container__tasks__task .content .items-count,
.template__section--single .tasks-container__tasks__group .content .items-count {
  display: inline-block;
  white-space: nowrap;
  font-size: 12px;
  color: #2e9df0;
  margin-right: 4px;
}
.template__section--single .tasks-container__tasks__task .content .items-count:hover,
.template__section--single .tasks-container__tasks__group .content .items-count:hover,
.template__section--single .tasks-container__tasks__task .content .items-count:focus,
.template__section--single .tasks-container__tasks__group .content .items-count:focus {
  cursor: pointer;
  color: #00ccff;
}
.template__section--single .tasks-container__tasks__task .group-icon,
.template__section--single .tasks-container__tasks__group .group-icon {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 12px;
}
.template__section--single .tasks-container__tasks__task .group-icon .icon-template_folder,
.template__section--single .tasks-container__tasks__group .group-icon .icon-template_folder {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #e1ebef;
  font-size: 33px;
  margin-top: -17px;
  margin-left: -20px;
  z-index: 1;
}
.template__section--single .tasks-container__tasks__task .group-icon .icon-template_folder_parallel,
.template__section--single .tasks-container__tasks__group .group-icon .icon-template_folder_parallel {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #88afbf;
  font-size: 13px;
  margin-top: -5px;
  margin-left: -7px;
  z-index: 2;
}
.template__section--single .tasks-container__tasks__task .group-icon .icon-template_folder_sequence,
.template__section--single .tasks-container__tasks__group .group-icon .icon-template_folder_sequence {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #88afbf;
  font-size: 13px;
  margin-top: -5px;
  margin-left: -7px;
  z-index: 2;
}
.template__section--single .tasks-container__tasks__task .square,
.template__section--single .tasks-container__tasks__group .square {
  height: 15px;
  width: 15px;
  right: -8px;
  z-index: 100;
  top: 50%;
  margin-top: -8px;
  transform: rotate(45deg);
  background-color: #fff;
  position: absolute;
  border-right: 1px solid #2e9df0;
  border-top: 1px solid #2e9df0;
  display: none;
}
.template__section--single .info {
  width: 50%;
  flex: 0 1 50%;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
  margin-left: -2px;
}
.template__section--single .info > .bottom-offset {
  width: 100%;
  height: 16px;
}
.template__section--single aside {
  height: calc(100% - (4 * 16px + 1px));
  width: 100%;
  overflow: auto;
  word-wrap: break-word;
  line-height: 18px;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.template__section--single aside div[markdown-content] {
  overflow: auto;
}
.template__section--single__task-settings,
.template__section--single__group-details {
  padding: 16px 24px 100px 32px;
  position: relative;
  min-height: 100%;
  border: 1px solid #e1ebef;
  line-height: normal;
}
.template__section--single__task-settings:focus,
.template__section--single__group-details:focus {
  outline: none;
}
.template__section--single__task-settings__tabs,
.template__section--single__group-details__tabs {
  margin-top: 0;
}
.template__section--single__task-settings__tabs li + li,
.template__section--single__group-details__tabs li + li {
  margin-left: 12px;
}
.template__section--single__task-settings .template-task-data-model__content__section.hidden,
.template__section--single__group-details .template-task-data-model__content__section.hidden {
  display: none;
}
.template__section--single__task-settings .template-task-data-model__content__section__fields__field.hidden,
.template__section--single__group-details .template-task-data-model__content__section__fields__field.hidden {
  display: none;
}
.template__section--single__task-settings .template-task-data-model__content__section__fields__field:first-child.hidden,
.template__section--single__group-details .template-task-data-model__content__section__fields__field:first-child.hidden {
  display: block;
  visibility: hidden;
  height: 0;
  margin: 12px 0 0;
}
.template__section--single__task-settings > .content,
.template__section--single__group-details > .content {
  width: 100%;
}
.template__section--single__task-settings > .content > div > h4,
.template__section--single__group-details > .content > div > h4 {
  color: #959595;
  font-size: 14px;
  margin: 24px 0;
  font-family: 'Roboto-Regular', Arial;
}
.template__section--single__task-settings > .content > div > h4:first-child,
.template__section--single__group-details > .content > div > h4:first-child {
  margin-top: 0;
}
.template__section--single__task-settings > .content > .form-group,
.template__section--single__group-details > .content > .form-group {
  align-items: flex-start;
}
.template__section--single__task-settings > .content .form-group .value,
.template__section--single__group-details > .content .form-group .value {
  word-break: break-all;
}
.template__section--single__task-settings > .content .form-group > label:first-child,
.template__section--single__group-details > .content .form-group > label:first-child {
  flex: 0 1 150px;
  min-width: 150px;
  width: 150px;
  font-family: 'Roboto-Medium', Arial;
}
.template__section--single__task-settings > .content .form-group.type-group .task-view-type-icon,
.template__section--single__group-details > .content .form-group.type-group .task-view-type-icon {
  margin-right: 8px;
}
.template__section--single__task-settings > .content .form-group.type-group .task-view-approval,
.template__section--single__group-details > .content .form-group.type-group .task-view-approval {
  display: none;
  align-items: center;
}
.template__section--single__task-settings > .content .form-group.type-group .task-view-simple,
.template__section--single__group-details > .content .form-group.type-group .task-view-simple {
  display: flex;
  align-items: center;
}
.template__section--single__task-settings > .content .form-group.type-group .task-view-is-approval .task-view-approval,
.template__section--single__group-details > .content .form-group.type-group .task-view-is-approval .task-view-approval {
  display: flex;
}
.template__section--single__task-settings > .content .form-group.type-group .task-view-is-approval .task-view-simple,
.template__section--single__group-details > .content .form-group.type-group .task-view-is-approval .task-view-simple {
  display: none;
}
.template__section--single__task-settings > .content .form-group.date-group > .value,
.template__section--single__group-details > .content .form-group.date-group > .value {
  margin-top: -2px;
}
.template__section--single__task-settings > .content .form-group.date-group > .value .icon-task_due_date_add_small,
.template__section--single__group-details > .content .form-group.date-group > .value .icon-task_due_date_add_small {
  font-size: 16px;
}
.template__section--single__task-settings > .content .form-group.description-group,
.template__section--single__group-details > .content .form-group.description-group {
  display: grid;
  grid-template-columns: 150px auto;
}
.template__section--single__task-settings > .content .form-group.description-group [markdown-content],
.template__section--single__group-details > .content .form-group.description-group [markdown-content] {
  font-size: 12px;
}
.template__section--single__task-settings > .content .form-group.description-group > label,
.template__section--single__group-details > .content .form-group.description-group > label {
  width: unset;
  min-width: unset;
  flex: unset;
  align-self: start;
  line-height: 28px;
}
.template__section--single__task-settings > .content .form-group.description-group > .value,
.template__section--single__group-details > .content .form-group.description-group > .value {
  align-self: start;
}
.template__section--single__task-settings > .content .form-group.description-group > .value :first-child,
.template__section--single__group-details > .content .form-group.description-group > .value :first-child {
  margin-top: 0;
}
.template__section--single__task-settings > .content .form-group.actors-group > label,
.template__section--single__group-details > .content .form-group.actors-group > label {
  padding-top: 2px;
}
.template__section--single__task-settings > .content .form-group.actors-group > .value,
.template__section--single__group-details > .content .form-group.actors-group > .value {
  width: 100%;
}
.template__section--single__task-settings > .content .form-group.actors-settings,
.template__section--single__group-details > .content .form-group.actors-settings {
  margin-top: 20px;
}
.template__section--single__task-settings > .content .form-group .tmpl-date-picker,
.template__section--single__group-details > .content .form-group .tmpl-date-picker {
  background: #fff;
}
.template__section--single__task-settings > .content .form-group .tmpl-date-picker .handler,
.template__section--single__group-details > .content .form-group .tmpl-date-picker .handler {
  padding: 6px 7px;
  flex: 1 0 auto;
  border: 2px #cacaca solid;
  border-radius: 4px;
  border-width: 1px;
  cursor: pointer;
  height: 32px;
  min-width: 120px;
}
.template__section--single__task-settings > .content .form-group .tmpl-date-picker .handler.drop-enabled,
.template__section--single__group-details > .content .form-group .tmpl-date-picker .handler.drop-enabled {
  border-color: #2e9df0;
  border-width: 2px;
}
.actors-field {
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 100%;
}
.actors-field .group .avatar-small {
  background: #e8e8e8;
  color: #959595;
}
.actors-field .group .avatar-small .icon-assignee_group,
.actors-field .group .avatar-small .icon-assignee_process_starter,
.actors-field .group .avatar-small .icon-assignee_process_manager {
  display: block;
  font-size: 14px;
  width: auto;
}
.actors-field .group > span:not(.text) {
  width: 24px;
  text-align: center;
}
.actors-field .user {
  display: table;
}
.actors-field .user div {
  display: inline-block;
}
.actors-field > div {
  display: table;
  margin-right: 8px;
  margin-top: 4px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.actors-field > div .text {
  color: #222;
}
.actors-field > div span {
  display: inline-block;
  font-size: 12px;
}
.actors-field > div div + .text,
.actors-field > div span + .text,
.actors-field > div img + .text {
  margin-left: 4px;
}
.complete-on-behalf {
  width: 24px;
  height: 32px;
  position: absolute;
  right: 0;
  display: inline-block;
}
.complete-on-behalf:focus {
  outline: none;
}
.complete-on-behalf > .handler {
  padding-right: 2px;
  height: 100%;
  color: #fff;
  text-align: center;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease;
  cursor: pointer;
}
.complete-on-behalf > .handler:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.complete-on-behalf > .handler i {
  font-size: 5px;
  line-height: 32px;
  vertical-align: middle;
}
.behalf-drop {
  margin-left: -150px;
  margin-top: 4px;
}
.behalf-drop.behalf-drop-open .dropdown-menu--complete-on-behalf {
  display: block;
}
.behalf-drop .dropdown-menu--complete-on-behalf {
  display: none;
  background: #fff;
  border: 1px #cacaca solid;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}
.behalf-drop .dropdown-menu--complete-on-behalf li.separated {
  border-top: 1px solid #e3e3e3;
}
.behalf-drop .dropdown-menu--complete-on-behalf li a {
  font-size: 14px;
  color: #222;
  padding: 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.behalf-drop .dropdown-menu--complete-on-behalf li a:hover {
  color: #2e9df0;
}
.processes__main__content {
  margin: 0;
  height: 100%;
  max-height: calc(100% - (4 * 16px));
  padding: 1px 12px 1px 32px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.processes__main__content:after {
  content: '';
  height: 70px;
  width: 100%;
  display: block;
}
.processes__main__content .processes-list-title {
  font-size: 14px;
  padding: 24px 0 8px 16px;
}
.processes__main__content .processes-list-title a {
  color: #2e9df0;
  display: inline-block;
  margin-left: 16px;
}
.processes__main__content .processes-list-title a:hover,
.processes__main__content .processes-list-title a:focus {
  cursor: pointer;
  color: #00ccff;
}
.processes__main__content react-processes-list {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.processes__main__content__empty-state,
.processes__main__content__error-state {
  border: 1px solid #e1ebef;
  padding: 64px 0;
  width: 100%;
  text-align: center;
}
.processes__main__content__empty-state > i,
.processes__main__content__error-state > i {
  font-size: 64px;
  color: #d1d1d1;
}
.processes__main__content__empty-state > i.small,
.processes__main__content__error-state > i.small {
  font-size: 40px;
}
.processes__main__content__empty-state > i + h5,
.processes__main__content__error-state > i + h5 {
  margin-top: 16px;
}
.processes__main__content__empty-state h5,
.processes__main__content__error-state h5 {
  color: #222;
  font-size: 14px;
  margin-top: 24px;
}
.processes__main__content__empty-state p,
.processes__main__content__error-state p {
  margin-top: 8px;
  font-size: 12px;
  color: #959595;
}
.processes__main__content__empty-state p a,
.processes__main__content__error-state p a {
  color: #2e9df0;
  cursor: pointer;
}
.processes__main__content__error-state {
  border-color: #f75f5f;
}
.processes__main__content .animated .processes-header > a:hover {
  cursor: pointer;
  color: #2e9df0;
}
.processes__main__content .processes-header {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.processes__main__content .processes-header.bg-gray {
  background-color: #fafafa;
}
.processes__main__content .processes-header > span {
  font-size: 14px;
  font-family: 'Roboto-Medium', Arial;
}
.processes__main__content .processes-header > span.processes-count {
  font-size: 12px;
  font-family: 'Roboto-Regular', Arial;
  color: #959595;
  margin-left: 8px;
  margin-top: 1px;
  display: inline-block;
}
.processes__main__content .processes-header > a {
  font-size: 14px;
  font-family: 'Roboto-Medium', Arial;
  cursor: default;
}
.processes__main__content .processes-header > .avatar-small {
  margin-right: 12px;
  color: #40484c;
  min-width: 24px;
}
.processes__main__content .processes-header > .avatar-small i {
  font-size: 14px;
  display: block;
}
.processes__main__content .processes-header > .avatar-small i.icon-Mens,
.processes__main__content .processes-header > .avatar-small i.icon-Magnifi-Glass2 {
  font-size: 12px;
}
.processes__main__content .processes-header > .avatar-small.default {
  background-color: #ccc;
}
.processes__main__content__process {
  border: 1px solid #e1ebef;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 12px;
  cursor: pointer;
}
.processes__main__content__process:focus {
  outline: none;
}
.processes__main__content__process + div {
  margin-top: -1px;
}
.processes__main__content__process__loading {
  width: 100%;
  flex: 0 1 100%;
  display: inline-flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  cursor: default;
}
.processes__main__content__process__loading .main {
  width: calc(100% - (2 * 24px + 2 * 4px + 16px));
  flex: 0 1 calc(100% - (2 * 24px + 2 * 4px + 16px));
  display: inline-flex;
  align-items: center;
  height: 100%;
}
.processes__main__content__process__loading .main .thumbnail {
  border: 1px solid #e1ebef;
  height: 40px;
  width: 40px;
  min-width: 40px;
  flex: 0 1 40px;
  border-radius: 13px;
}
.processes__main__content__process__loading .main .text {
  border: 1px solid #e1ebef;
  width: calc(100% - (40px + 16px));
  max-width: 500px;
  flex: 0 1 calc(100% - (40px + 16px));
  height: 5px;
  border-radius: 5px;
  margin: 24px 0 0 16px;
}
.processes__main__content__process__loading .assignees {
  width: 52px;
  flex: 52px;
  display: inline-flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}
.processes__main__content__process__loading .assignees > .assignee {
  border: 1px solid #e1ebef;
  height: 24px;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  flex: 0 1 40px;
  border-radius: 8px;
  margin-left: 8px;
}
.processes__main__content__process__details {
  flex: 0 1 100%;
  max-width: calc(100% - 220px);
  display: inline-flex;
  align-items: center;
}
.processes__main__content__process__details__process_avatar {
  position: relative;
  margin-right: 12px;
}
.processes__main__content__process__details__process_avatar .avatar-reject {
  border: 1px solid #fff;
  border-radius: 8px;
  width: 17px;
  height: 17px;
  background-color: #f75f5f;
  position: absolute;
  right: 3px;
  bottom: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.processes__main__content__process__details__process_avatar .avatar-reject > i {
  color: black;
  opacity: 0.5;
  font-size: 7px;
}
.processes__main__content__process__details__process_avatar .avatar-completed {
  border: 1px solid #fff;
  border-radius: 8px;
  width: 17px;
  height: 17px;
  background-color: #4abc3b;
  position: absolute;
  right: 3px;
  bottom: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.processes__main__content__process__details__process_avatar .avatar-completed > i {
  color: black;
  opacity: 0.5;
  font-size: 7px;
}
.processes__main__content__process__details__process_avatar .avatar-stopped {
  border: 1px solid #fff;
  border-radius: 8px;
  width: 17px;
  height: 17px;
  background-color: #f9d83c;
  position: absolute;
  right: 3px;
  bottom: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.processes__main__content__process__details__process_avatar .avatar-stopped > i {
  color: black;
  opacity: 0.5;
  font-size: 7px;
}
.processes__main__content__process__details .details-wrapper {
  width: calc(100% - (12px + 40px));
  margin-top: -2px;
}
.processes__main__content__process__details .details-wrapper h4 {
  margin-bottom: -8px;
  width: 100%;
}
.processes__main__content__process__details .details-wrapper h4:hover a {
  color: #2e9df0;
}
.processes__main__content__process__details .details-wrapper h4 a {
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
  color: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-family: 'Roboto-Regular', Arial;
}
.processes__main__content__process__details .details-wrapper h4 a:focus {
  outline: none;
}
.processes__main__content__process__details .details-wrapper > div {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.processes__main__content__process__details .details-wrapper > div .tasks {
  padding: 0;
  margin: 0;
  height: 5px;
  margin-right: 12px;
  max-width: 500px;
}
.processes__main__content__process__details .details-wrapper > div .tasks li {
  display: inline-block;
  height: 100%;
  cursor: pointer;
  position: relative;
}
.processes__main__content__process__details .details-wrapper > div .tasks li:focus {
  outline: none;
}
.processes__main__content__process__details .details-wrapper > div .tasks li .process-task-tooltip {
  display: none;
}
.processes__main__content__process__details .details-wrapper > div .tasks li:hover .process-task-tooltip {
  visibility: visible;
  display: table;
}
.processes__main__content__process__details .details-wrapper > div .tasks li.not-assigned:not(.in-process):not(.completed):not(.inactive):not(.approved):not(.rejected):not(.stopped-on-reject) {
  background-clip: padding-box;
  background-color: #ccc;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}
.processes__main__content__process__details .details-wrapper > div .tasks li:not(.inactive).in-process {
  background-color: #d8d8d8;
}
.processes__main__content__process__details .details-wrapper > div .tasks li:not(.inactive).completed,
.processes__main__content__process__details .details-wrapper > div .tasks li.approved {
  background: #4abc3b;
}
.processes__main__content__process__details .details-wrapper > div .tasks li:not(.inactive).issued {
  background: #f9d83c;
}
.processes__main__content__process__details .details-wrapper > div .tasks li:not(.inactive).upcoming,
.processes__main__content__process__details .details-wrapper > div .tasks li.rejected {
  background-color: #f75f5f;
}
.processes__main__content__process__details .details-wrapper > div .tasks li.not-assigned.upcoming:not(.stopped-on-reject):not(.inactive) {
  background-color: #f75f5f !important;
}
.processes__main__content__process__details .details-wrapper > div .tasks li:not(.inactive):not(.stopped-on-reject).upcoming.approved {
  background-color: #4abc3b !important;
}
.processes__main__content__process__details .details-wrapper > div .tasks li.inactive,
.processes__main__content__process__details .details-wrapper > div .tasks li.stopped-on-reject,
.processes__main__content__process__details .details-wrapper > div .tasks li.upcoming.stopped-on-reject {
  background-color: #fff;
  border: 1px solid #d8d8d8;
}
.processes__main__content__process__details .details-wrapper > div .tasks li.upcoming.inactive {
  background-color: #fff;
  border: 1px solid #f75f5f;
}
.processes__main__content__process__details .details-wrapper > div .tasks li + li {
  margin-left: 1px;
}
.processes__main__content__process__details .details-wrapper > div .tasks li:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.processes__main__content__process__details .details-wrapper > div .tasks li:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.processes__main__content__process__details .details-wrapper > div > span {
  color: #959595;
  font-size: 12px;
  white-space: nowrap;
}
.processes__main__content__process__details .details-wrapper > div > span + span {
  margin-left: 15px;
}
.processes__main__content__process__details .details-wrapper > div > span.attachments {
  color: #2e9df0;
  font-size: 14px;
}
.processes__main__content__process__details .details-wrapper > p {
  color: #959595;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 2px;
}
.processes__main__content__process__details .details-wrapper > p .red {
  color: #f75f5f;
}
.processes__main__content__process__details .details-wrapper > p .green {
  color: #4abc3b;
}
.processes__main__content__process__details .details-wrapper > p .yellow {
  color: #D4AB00;
}
.processes__main__content__process__assignees {
  flex: 1 0 auto;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
}
.processes__main__content__process__assignees.completed {
  display: block;
}
.processes__main__content__process__assignees .status {
  color: #959595;
  font-size: 12px;
  padding: 3px 5px 3px 4px;
  float: right;
  border-radius: 10px;
  white-space: nowrap;
  text-align: right;
  flex: 1 0 auto;
}
.processes__main__content__process__assignees .status + ul {
  margin-left: 4px;
}
.processes__main__content__process__assignees .status span {
  color: #959595;
}
.processes__main__content__process__assignees .status .dueDate {
  display: block;
  text-align: right;
}
.processes__main__content__process__assignees .status .dueDate span {
  color: #222;
}
.processes__main__content__process__assignees .status .dueDate span.red {
  color: #f75f5f;
}
.processes__main__content__process__assignees .status.stopped {
  background-color: #f9d83c;
  color: rgba(0, 0, 0, 0.5);
}
.processes__main__content__process__assignees .status.stopped i {
  background-color: #222;
  color: #222;
  opacity: 0.5;
  height: 8px;
  width: 8px;
  font-size: 8px;
  margin: 3px 2px 0 3px;
}
.processes__main__content__process__assignees .status i {
  font-size: 8px;
  opacity: 0.8;
  float: left;
  margin-top: 3px;
}
.processes__main__content__process__assignees .status i.icon-common_close,
.processes__main__content__process__assignees .status i.icon-menu_checkmark {
  margin: 2px 4px 0 0;
}
.processes__main__content__process__assignees .status i.icon-menu_checkmark {
  color: #4abc3b;
}
.processes__main__content__process__assignees .status i.icon-common_close {
  opacity: 1;
  color: #f75f5f;
}
.drop-element {
  z-index: 1100;
}
.drop-element.drop-open .dropdown-menu--field-type,
.drop-element.drop-open .dropdown-menu--field-edition {
  display: block;
}
.drop-element .dropdown-menu--field-edition {
  display: none;
  min-width: 90px;
  background: #fff;
  border: 1px #cacaca solid;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}
.drop-element .dropdown-menu--field-edition li.separated {
  border-top: 1px solid #e3e3e3;
}
.drop-element .dropdown-menu--field-edition li a {
  font-size: 14px;
  font-family: 'Roboto-Regular', Arial;
  color: #222;
  padding: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 105px;
}
.drop-element .dropdown-menu--field-edition li a i {
  color: #959595;
  font-size: 16px;
  margin-right: 12px;
}
.drop-element .dropdown-menu--field-edition li a:hover {
  color: #2e9df0;
}
.drop-element .dropdown-menu--field-edition li a.active i {
  color: #4abc3b;
}
.drop-element .dropdown-menu--field-type {
  display: none;
  min-width: 105px;
  background: #fff;
  border: 1px #cacaca solid;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  padding: 5px;
}
.drop-element .dropdown-menu--field-type li.separated {
  border-top: 1px solid #e3e3e3;
}
.drop-element .dropdown-menu--field-type li a {
  font-size: 12px;
  color: #222;
  padding: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.drop-element .dropdown-menu--field-type li a:hover {
  color: #2e9df0;
}
.processes__main__header {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px;
  height: 64px;
}
.processes__main__header:focus {
  outline: none;
}
.processes__main__header__nav {
  display: inline-flex;
}
.processes__main__header__nav li {
  padding: 0;
  margin: 0;
}
.processes__main__header__nav li + li {
  margin-left: 16px;
}
.processes__main__header__nav li > a {
  display: inline-flex;
  align-items: center;
  font-family: 'Roboto-Regular', Arial;
  font-size: 12px;
  color: #9d9d9d;
  text-decoration: none;
  line-height: 18px;
  cursor: pointer;
}
.processes__main__header__nav li > a.start-process {
  border: 1px #cacaca solid;
  border-radius: 16px;
  font-size: 14px;
}
.processes__main__header__nav li > a.start-process i {
  margin-right: 5px;
  color: #959595;
}
.processes__main__header__nav li > a.start-process:hover {
  color: #2e9df0;
  border-color: #2e9df0;
}
.processes__main__header__nav li > a.start-process:hover i {
  color: #2e9df0;
}
.processes__main__header__nav li > a.start-process:active {
  color: #fff;
  background-color: #2e9df0;
}
.processes__main__header__nav li > a.start-process:active i {
  color: #fff;
}
.processes__main__header__filters {
  display: inline-flex;
  align-items: center;
}
.processes__main__header__filters li.search-block {
  position: relative;
}
.processes__main__header__filters li.search-block.opened > input {
  width: 320px;
}
.processes__main__header__filters li.search-block > i {
  position: absolute;
  color: #959595;
  cursor: pointer;
}
.processes__main__header__filters li.search-block > i:focus {
  outline: none;
}
.processes__main__header__filters li.search-block > i.icon-header_search {
  left: 6px;
  margin-top: -1px;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.15s ease;
}
.processes__main__header__filters li.search-block > i.icon-common_remove {
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.processes__main__header__filters li.search-block > input {
  position: relative;
  z-index: 1;
  transition: all 0.15s ease;
  opacity: 1;
  padding: 8px 30px;
  appearance: none;
  flex: 0 1 100%;
  width: 180px;
  border: 1px #cacaca solid;
  border-radius: 4px;
  box-shadow: none !important;
  color: #222;
  resize: none;
  outline: 0;
  cursor: pointer;
  background: transparent;
}
.processes__main__header__filters li.search-block > input::-ms-clear {
  display: none;
}
.processes__main__header__filters li.search-block > input:focus,
.processes__main__header__filters li.search-block > input.active {
  outline: none;
  border-color: #2e9df0;
  border-width: 2px;
  cursor: text;
}
.processes__main__header__filters li.search-block > input:focus + .icon-header_search,
.processes__main__header__filters li.search-block > input.active + .icon-header_search {
  color: #222;
}
.processes__main__header__filters li + li {
  margin-left: 12px;
}
.processes__main__header__filters .mode {
  display: inline-flex;
  align-items: center;
  float: left;
  color: #222;
  font-size: 14px;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  position: relative;
  outline: none;
  cursor: pointer;
  margin-right: 4px;
  transition: all 0.2s ease;
}
.processes__main__header__filters .mode span {
  display: inline-flex;
  align-items: center;
}
.processes__main__header__filters .mode:hover,
.processes__main__header__filters .mode:hover > i {
  color: #2e9df0;
}
.processes__main__header__filters .mode .icon-common_arrow_down {
  font-size: 4px;
  margin-left: 4px;
}
.processes__main__header__filters .mode > .filter-dropdown {
  width: 120px;
  top: 30px;
  right: -4px;
  left: auto;
}
.processes__main__header__filters .btn-switcher + .btn-switcher {
  margin-left: 4px;
}
.processes__main__header > a i {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 6px;
}
.icons-select {
  position: absolute;
  z-index: 6;
  left: 32px;
  top: 69px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 10px #959595;
  background: #fff;
}
.icons-select .arrow {
  background: #fff;
  display: block;
  height: 16px;
  width: 16px;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-left: none;
  transform: rotate(225deg);
  left: 13px;
  top: -8px;
  position: absolute;
}
.icons-select__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.icons-select__background:focus {
  outline: none;
}
.icons-select__list {
  padding: 16px 8px 0;
  z-index: 6;
  position: relative;
}
.icons-select__list h3 {
  padding: 0;
  margin: 0;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  font-size: 12px;
  margin: 0 0 0 4px;
}
.icons-select__list .icons {
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.icons-select__list .icons li {
  margin: 8px 4px 0 4px;
  width: 45px;
  min-width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 15px;
  vertical-align: middle;
  cursor: pointer;
}
.icons-select__list .icons li:focus {
  outline: none;
}
.icons-select__list .icons li i {
  display: table;
  margin: 0 auto;
  font-size: 24px;
  line-height: 45px;
}
.icons-select__list .icons li i.icon-Mens {
  font-size: 26px;
}
.icons-select__list .icons li i.icon-Magnifi-Glass2 {
  font-size: 22px;
}
.icons-select__list .icons li i::before {
  margin: 0;
}
.icons-select__colors {
  padding: 12px 16px 16px;
  background-color: #f5f5f5;
  z-index: 6;
  position: relative;
}
.icons-select__colors h3 {
  padding: 0;
  margin: 0;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  color: #222;
  font-size: 12px;
}
.icons-select__colors .colors {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
}
.icons-select__colors .colors li {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
}
.icons-select__colors .colors li:after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid transparent;
  transition: border 0.15s ease;
}
.icons-select__colors .colors li:hover:after,
.icons-select__colors .colors li.selected:after {
  border-color: inherit;
}
.icons-select__colors .colors li:focus {
  outline: none;
}
@media only screen and (max-width: 1023px) {
  .templates__main__template:hover {
    background: inherit !important;
  }
  .templates__main__template:hover h4 {
    color: inherit !important;
  }
  .processes__main__header {
    padding: 0 12px 0 16px;
  }
  .processes__main__content {
    padding: 1px 0;
  }
  .processes__main__content .processes-header > a:hover {
    color: inherit;
  }
  .processes__main__content .processes-header > span.processes-count {
    display: none;
  }
  .processes__main__content__process__details .details-wrapper > div > span {
    display: none;
  }
  .processes__main__content__process__assignees .process-assignees-list {
    display: none;
  }
  .process__header {
    padding-right: 16px !important;
  }
  .process__header > .icon-common_close {
    display: none;
  }
  .process__header__main__btns {
    min-width: 50px !important;
  }
  .process__header__main__content {
    width: calc(100% - 50px) !important;
  }
  .process__header__main__content span:first-child .template-name-link {
    display: none;
  }
  .process__header__main__content span:first-child .template-name {
    display: inline;
  }
  .process__section {
    padding: 0 !important;
  }
  .process__section .tasks-container {
    padding: 16px 0 0 !important;
    margin: 0 !important;
    height: 100% !important;
    overflow: auto;
    position: relative;
    width: 100%;
    flex: 0 1 100% !important;
    max-width: 100%;
    overflow-x: hidden;
  }
  .process__section .tasks-container__header {
    padding-left: 16px !important;
  }
  .process__section .tasks-container__header > .dueDate > .comments-toggler {
    display: inline-block !important;
  }
  .process__section .tasks-container__tasks {
    overflow-y: visible !important;
  }
  .process__section .tasks-container__tasks__task,
  .process__section .tasks-container__tasks__group {
    border-left: none !important;
    border-right: none !important;
    width: 100% !important;
  }
  .process__section .tasks-container__tasks__task .content .action,
  .process__section .tasks-container__tasks__group .content .action {
    display: block !important;
  }
  .process__section .tasks-container__tasks__task .content .action span,
  .process__section .tasks-container__tasks__group .content .action span {
    display: none;
  }
  .process__section .tasks-container__tasks__task__main > .avatar,
  .process__section .tasks-container__tasks__group__main > .avatar {
    margin-left: 16px !important;
  }
  .process__section .info {
    flex: 1 0 100% !important;
    min-width: 100% !important;
    padding: 16px;
    position: absolute;
    background-color: #fff !important;
    left: 0;
    top: 64px;
    z-index: 2;
    padding-right: 12px;
    height: calc(100vh - (54px + 64px)) !important;
  }
  .process__section .info.ng-hide-add,
  .process__section .info.ng-hide-remove {
    transition: all 0.15s ease;
  }
  .process__section .info.ng-hide {
    transform: translate(100%, 0);
  }
  .process__section .info > .icon-common_close {
    display: block !important;
  }
}
@media only screen and (max-width: 767px) {
  .processes__main__header__filters li.search-block.opened > input {
    width: 180px;
  }
}
@media only screen and (max-width: 414px) {
  .processes__main__header__nav > li > .start-process span {
    display: none;
  }
  .processes__main__header__nav > li > .start-process i {
    margin-right: 0 !important;
  }
  .processes__main__header.searching {
    justify-content: flex-end;
  }
  .processes__main__header.searching .processes__main__header__nav {
    display: none;
  }
  .processes__main__header.searching .processes__main__header__filters {
    width: 100%;
    justify-content: flex-end;
  }
  .processes__main__header__filters li.search-block {
    border: 1px #cacaca solid;
    cursor: pointer;
    border-radius: 16px;
    height: 32px;
    margin-left: 8px;
    min-width: 36px;
    position: relative;
    transition: all 0.15s ease;
  }
  .processes__main__header__filters li.search-block.opened {
    border: none;
    border-radius: 0;
    width: 100%;
  }
  .processes__main__header__filters li.search-block.opened > input {
    width: 100%;
    opacity: 1;
    padding: 8px 30px;
  }
  .processes__main__header__filters li.search-block.opened > i {
    z-index: 1;
  }
  .processes__main__header__filters li.search-block > input {
    width: 36px;
    opacity: 0;
    padding-left: 4px;
    position: absolute;
    right: 0;
    background-color: #fff;
  }
  .processes__main__header__filters li.search-block + li {
    margin-left: 8px;
  }
  .processes__main__content__process__details {
    max-width: 100%;
  }
  .processes__main__content__process__details .details-wrapper > div .tasks {
    max-width: 100%;
    margin-right: 0;
  }
  .processes__main__content__process__assignees {
    display: none !important;
  }
  .process__header__main__content .process-reject-reason {
    white-space: nowrap;
  }
  .process__header__main__btns .btn-process-remove {
    display: none;
  }
  .process__section .tasks-container__header__btns .btn-switcher span {
    display: none !important;
  }
  .process__section .tasks-container__header__btns .btn-switcher .number {
    display: inline-block !important;
  }
  .process__section .tasks-container__header > .dueDate .round-progress-wrapper {
    display: none;
  }
}
@media only screen and (max-width: 868px) {
  .processes__main__content__process__details {
    max-width: unset;
    width: 100%;
  }
  .processes__main__content__process__details .details-wrapper {
    width: 100%;
    overflow: hidden;
  }
  .processes__main__content__process__assignees {
    display: none !important;
  }
}
.no-permission-section {
  border: 1px solid #e1ebef;
  padding: 64px 0;
  margin: 16px auto;
  width: calc(100% - (4 * 16px));
  text-align: center;
}
.no-permission-section h5 {
  color: #222;
  font-size: 14px;
  margin-top: 24px;
  white-space: pre-wrap;
}
.no-permission-section p {
  margin-top: 8px;
  font-size: 12px;
  color: #959595;
  white-space: pre-wrap;
}
.no-permission-section a {
  color: #2e9df0;
  margin-top: 8px;
  font-size: 12px;
  font-family: 'Roboto-Regular', Arial;
}
.not-found-section {
  border: 1px solid #e1ebef;
  padding: 64px 0;
  margin: 16px auto;
  width: calc(100% - (4 * 16px));
  text-align: center;
}
.not-found-section h5 {
  color: #222;
  font-size: 14px;
  margin-top: 24px;
  white-space: pre-wrap;
}
.not-found-section p {
  margin-top: 8px;
  font-size: 12px;
  color: #959595;
  white-space: pre-wrap;
}
.not-found-section a {
  color: #2e9df0;
  margin-top: 8px;
  font-size: 12px;
  font-family: 'Roboto-Regular', Arial;
}
.print-only {
  display: none;
}
@media print {
  .print-only {
    display: block;
  }
  .print-section {
    page-break-after: always;
  }
  .print-section .print-section-title {
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 10px;
  }
  .help-tooltip {
    display: none;
  }
  .site-header,
  .notification-bar,
  .phone-screen-navigation {
    display: none !important;
  }
  .main-wrapper {
    height: 100% !important;
  }
  .process-view-data-model__section > header .main-info i,
  .task-data-model__section > header .main-info i,
  .process-view-data-model section > header .main-info i,
  .task-data-model section > header .main-info i {
    display: none;
  }
  .process-view-data-model__section > header .main-info span,
  .task-data-model__section > header .main-info span,
  .process-view-data-model section > header .main-info span,
  .task-data-model section > header .main-info span {
    color: #222;
    font-size: 16px;
  }
  .process-view-data-model__section > header .actions-info,
  .task-data-model__section > header .actions-info,
  .process-view-data-model section > header .actions-info,
  .task-data-model section > header .actions-info {
    display: none;
  }
  .process-view-data-model__section label i,
  .task-data-model__section label i,
  .process-view-data-model section label i,
  .task-data-model section label i {
    display: none;
  }
  .process-view-data-model__section__fields__field,
  .task-data-model__section__fields__field,
  .process-view-data-model section__fields__field,
  .task-data-model section__fields__field,
  .process-view-data-model__section main,
  .task-data-model__section main,
  .process-view-data-model section main,
  .task-data-model section main {
    margin: 7px 0;
  }
  .process-view-data-model__section__fields__field > .form-group,
  .task-data-model__section__fields__field > .form-group,
  .process-view-data-model section__fields__field > .form-group,
  .task-data-model section__fields__field > .form-group,
  .process-view-data-model__section main > .form-group,
  .task-data-model__section main > .form-group,
  .process-view-data-model section main > .form-group,
  .task-data-model section main > .form-group {
    margin: 7px 0;
  }
  .process-view-data-model__section__fields__field .hint-messages,
  .task-data-model__section__fields__field .hint-messages,
  .process-view-data-model section__fields__field .hint-messages,
  .task-data-model section__fields__field .hint-messages,
  .process-view-data-model__section main .hint-messages,
  .task-data-model__section main .hint-messages,
  .process-view-data-model section main .hint-messages,
  .task-data-model section main .hint-messages,
  .process-view-data-model__section__fields__field .error-hint,
  .task-data-model__section__fields__field .error-hint,
  .process-view-data-model section__fields__field .error-hint,
  .task-data-model section__fields__field .error-hint,
  .process-view-data-model__section main .error-hint,
  .task-data-model__section main .error-hint,
  .process-view-data-model section main .error-hint,
  .task-data-model section main .error-hint {
    display: none;
  }
  .process-view-data-model__section__fields__field .yesno-data-field .error,
  .task-data-model__section__fields__field .yesno-data-field .error,
  .process-view-data-model section__fields__field .yesno-data-field .error,
  .task-data-model section__fields__field .yesno-data-field .error,
  .process-view-data-model__section main .yesno-data-field .error,
  .task-data-model__section main .yesno-data-field .error,
  .process-view-data-model section main .yesno-data-field .error,
  .task-data-model section main .yesno-data-field .error,
  .process-view-data-model__section__fields__field .yesno-data-field.disabled .error,
  .task-data-model__section__fields__field .yesno-data-field.disabled .error,
  .process-view-data-model section__fields__field .yesno-data-field.disabled .error,
  .task-data-model section__fields__field .yesno-data-field.disabled .error,
  .process-view-data-model__section main .yesno-data-field.disabled .error,
  .task-data-model__section main .yesno-data-field.disabled .error,
  .process-view-data-model section main .yesno-data-field.disabled .error,
  .task-data-model section main .yesno-data-field.disabled .error {
    display: none;
  }
  .process-view-data-model__section__fields__field .yesno-data-field ul li,
  .task-data-model__section__fields__field .yesno-data-field ul li,
  .process-view-data-model section__fields__field .yesno-data-field ul li,
  .task-data-model section__fields__field .yesno-data-field ul li,
  .process-view-data-model__section main .yesno-data-field ul li,
  .task-data-model__section main .yesno-data-field ul li,
  .process-view-data-model section main .yesno-data-field ul li,
  .task-data-model section main .yesno-data-field ul li,
  .process-view-data-model__section__fields__field .yesno-data-field.disabled ul li,
  .task-data-model__section__fields__field .yesno-data-field.disabled ul li,
  .process-view-data-model section__fields__field .yesno-data-field.disabled ul li,
  .task-data-model section__fields__field .yesno-data-field.disabled ul li,
  .process-view-data-model__section main .yesno-data-field.disabled ul li,
  .task-data-model__section main .yesno-data-field.disabled ul li,
  .process-view-data-model section main .yesno-data-field.disabled ul li,
  .task-data-model section main .yesno-data-field.disabled ul li {
    border: none !important;
    box-shadow: none !important;
    background: transparent !important;
    width: auto !important;
    padding: 8px 7px;
  }
  .process-view-data-model__section__fields__field .yesno-data-field ul li > .helper,
  .task-data-model__section__fields__field .yesno-data-field ul li > .helper,
  .process-view-data-model section__fields__field .yesno-data-field ul li > .helper,
  .task-data-model section__fields__field .yesno-data-field ul li > .helper,
  .process-view-data-model__section main .yesno-data-field ul li > .helper,
  .task-data-model__section main .yesno-data-field ul li > .helper,
  .process-view-data-model section main .yesno-data-field ul li > .helper,
  .task-data-model section main .yesno-data-field ul li > .helper,
  .process-view-data-model__section__fields__field .yesno-data-field.disabled ul li > .helper,
  .task-data-model__section__fields__field .yesno-data-field.disabled ul li > .helper,
  .process-view-data-model section__fields__field .yesno-data-field.disabled ul li > .helper,
  .task-data-model section__fields__field .yesno-data-field.disabled ul li > .helper,
  .process-view-data-model__section main .yesno-data-field.disabled ul li > .helper,
  .task-data-model__section main .yesno-data-field.disabled ul li > .helper,
  .process-view-data-model section main .yesno-data-field.disabled ul li > .helper,
  .task-data-model section main .yesno-data-field.disabled ul li > .helper {
    display: none !important;
  }
  .process-view-data-model__section__fields__field .yesno-data-field ul li:not(.active),
  .task-data-model__section__fields__field .yesno-data-field ul li:not(.active),
  .process-view-data-model section__fields__field .yesno-data-field ul li:not(.active),
  .task-data-model section__fields__field .yesno-data-field ul li:not(.active),
  .process-view-data-model__section main .yesno-data-field ul li:not(.active),
  .task-data-model__section main .yesno-data-field ul li:not(.active),
  .process-view-data-model section main .yesno-data-field ul li:not(.active),
  .task-data-model section main .yesno-data-field ul li:not(.active),
  .process-view-data-model__section__fields__field .yesno-data-field.disabled ul li:not(.active),
  .task-data-model__section__fields__field .yesno-data-field.disabled ul li:not(.active),
  .process-view-data-model section__fields__field .yesno-data-field.disabled ul li:not(.active),
  .task-data-model section__fields__field .yesno-data-field.disabled ul li:not(.active),
  .process-view-data-model__section main .yesno-data-field.disabled ul li:not(.active),
  .task-data-model__section main .yesno-data-field.disabled ul li:not(.active),
  .process-view-data-model section main .yesno-data-field.disabled ul li:not(.active),
  .task-data-model section main .yesno-data-field.disabled ul li:not(.active) {
    display: none !important;
  }
  .process-view-data-model__section__fields__field .email-data-field,
  .task-data-model__section__fields__field .email-data-field,
  .process-view-data-model section__fields__field .email-data-field,
  .task-data-model section__fields__field .email-data-field,
  .process-view-data-model__section main .email-data-field,
  .task-data-model__section main .email-data-field,
  .process-view-data-model section main .email-data-field,
  .task-data-model section main .email-data-field,
  .process-view-data-model__section__fields__field .email-data-field--disabled,
  .task-data-model__section__fields__field .email-data-field--disabled,
  .process-view-data-model section__fields__field .email-data-field--disabled,
  .task-data-model section__fields__field .email-data-field--disabled,
  .process-view-data-model__section main .email-data-field--disabled,
  .task-data-model__section main .email-data-field--disabled,
  .process-view-data-model section main .email-data-field--disabled,
  .task-data-model section main .email-data-field--disabled {
    background: transparent;
  }
  .process-view-data-model__section__fields__field .email-data-field > input,
  .task-data-model__section__fields__field .email-data-field > input,
  .process-view-data-model section__fields__field .email-data-field > input,
  .task-data-model section__fields__field .email-data-field > input,
  .process-view-data-model__section main .email-data-field > input,
  .task-data-model__section main .email-data-field > input,
  .process-view-data-model section main .email-data-field > input,
  .task-data-model section main .email-data-field > input,
  .process-view-data-model__section__fields__field .email-data-field--disabled > input,
  .task-data-model__section__fields__field .email-data-field--disabled > input,
  .process-view-data-model section__fields__field .email-data-field--disabled > input,
  .task-data-model section__fields__field .email-data-field--disabled > input,
  .process-view-data-model__section main .email-data-field--disabled > input,
  .task-data-model__section main .email-data-field--disabled > input,
  .process-view-data-model section main .email-data-field--disabled > input,
  .task-data-model section main .email-data-field--disabled > input,
  .process-view-data-model__section__fields__field .email-data-field a,
  .task-data-model__section__fields__field .email-data-field a,
  .process-view-data-model section__fields__field .email-data-field a,
  .task-data-model section__fields__field .email-data-field a,
  .process-view-data-model__section main .email-data-field a,
  .task-data-model__section main .email-data-field a,
  .process-view-data-model section main .email-data-field a,
  .task-data-model section main .email-data-field a,
  .process-view-data-model__section__fields__field .email-data-field--disabled a,
  .task-data-model__section__fields__field .email-data-field--disabled a,
  .process-view-data-model section__fields__field .email-data-field--disabled a,
  .task-data-model section__fields__field .email-data-field--disabled a,
  .process-view-data-model__section main .email-data-field--disabled a,
  .task-data-model__section main .email-data-field--disabled a,
  .process-view-data-model section main .email-data-field--disabled a,
  .task-data-model section main .email-data-field--disabled a {
    padding-left: 0 !important;
  }
  .process-view-data-model__section__fields__field .email-data-field > i,
  .task-data-model__section__fields__field .email-data-field > i,
  .process-view-data-model section__fields__field .email-data-field > i,
  .task-data-model section__fields__field .email-data-field > i,
  .process-view-data-model__section main .email-data-field > i,
  .task-data-model__section main .email-data-field > i,
  .process-view-data-model section main .email-data-field > i,
  .task-data-model section main .email-data-field > i,
  .process-view-data-model__section__fields__field .email-data-field--disabled > i,
  .task-data-model__section__fields__field .email-data-field--disabled > i,
  .process-view-data-model section__fields__field .email-data-field--disabled > i,
  .task-data-model section__fields__field .email-data-field--disabled > i,
  .process-view-data-model__section main .email-data-field--disabled > i,
  .task-data-model__section main .email-data-field--disabled > i,
  .process-view-data-model section main .email-data-field--disabled > i,
  .task-data-model section main .email-data-field--disabled > i {
    display: none !important;
  }
  .process-view-data-model__section__fields__field .multiselect-field,
  .task-data-model__section__fields__field .multiselect-field,
  .process-view-data-model section__fields__field .multiselect-field,
  .task-data-model section__fields__field .multiselect-field,
  .process-view-data-model__section main .multiselect-field,
  .task-data-model__section main .multiselect-field,
  .process-view-data-model section main .multiselect-field,
  .task-data-model section main .multiselect-field {
    padding: 0 7px;
    flex-direction: row;
  }
  .process-view-data-model__section__fields__field .multiselect-field > label,
  .task-data-model__section__fields__field .multiselect-field > label,
  .process-view-data-model section__fields__field .multiselect-field > label,
  .task-data-model section__fields__field .multiselect-field > label,
  .process-view-data-model__section main .multiselect-field > label,
  .task-data-model__section main .multiselect-field > label,
  .process-view-data-model section main .multiselect-field > label,
  .task-data-model section main .multiselect-field > label {
    margin-top: 0 !important;
  }
  .process-view-data-model__section__fields__field .multiselect-field > label:after,
  .task-data-model__section__fields__field .multiselect-field > label:after,
  .process-view-data-model section__fields__field .multiselect-field > label:after,
  .task-data-model section__fields__field .multiselect-field > label:after,
  .process-view-data-model__section main .multiselect-field > label:after,
  .task-data-model__section main .multiselect-field > label:after,
  .process-view-data-model section main .multiselect-field > label:after,
  .task-data-model section main .multiselect-field > label:after {
    content: ',';
    margin-left: 2px;
  }
  .process-view-data-model__section__fields__field .multiselect-field > label:last-child:after,
  .task-data-model__section__fields__field .multiselect-field > label:last-child:after,
  .process-view-data-model section__fields__field .multiselect-field > label:last-child:after,
  .task-data-model section__fields__field .multiselect-field > label:last-child:after,
  .process-view-data-model__section main .multiselect-field > label:last-child:after,
  .task-data-model__section main .multiselect-field > label:last-child:after,
  .process-view-data-model section main .multiselect-field > label:last-child:after,
  .task-data-model section main .multiselect-field > label:last-child:after {
    opacity: 0;
  }
  .process-view-data-model__section__fields__field .multiselect-field > label:not(.checked),
  .task-data-model__section__fields__field .multiselect-field > label:not(.checked),
  .process-view-data-model section__fields__field .multiselect-field > label:not(.checked),
  .task-data-model section__fields__field .multiselect-field > label:not(.checked),
  .process-view-data-model__section main .multiselect-field > label:not(.checked),
  .task-data-model__section main .multiselect-field > label:not(.checked),
  .process-view-data-model section main .multiselect-field > label:not(.checked),
  .task-data-model section main .multiselect-field > label:not(.checked) {
    display: none;
  }
  .process-view-data-model__section__fields__field .price-data-field,
  .task-data-model__section__fields__field .price-data-field,
  .process-view-data-model section__fields__field .price-data-field,
  .task-data-model section__fields__field .price-data-field,
  .process-view-data-model__section main .price-data-field,
  .task-data-model__section main .price-data-field,
  .process-view-data-model section main .price-data-field,
  .task-data-model section main .price-data-field {
    border: none !important;
    padding-top: 6px;
  }
  .process-view-data-model__section__fields__field .price-data-field.empty,
  .task-data-model__section__fields__field .price-data-field.empty,
  .process-view-data-model section__fields__field .price-data-field.empty,
  .task-data-model section__fields__field .price-data-field.empty,
  .process-view-data-model__section main .price-data-field.empty,
  .task-data-model__section main .price-data-field.empty,
  .process-view-data-model section main .price-data-field.empty,
  .task-data-model section main .price-data-field.empty {
    display: none !important;
  }
  .process-view-data-model__section__fields__field .price-data-field > span,
  .task-data-model__section__fields__field .price-data-field > span,
  .process-view-data-model section__fields__field .price-data-field > span,
  .task-data-model section__fields__field .price-data-field > span,
  .process-view-data-model__section main .price-data-field > span,
  .task-data-model__section main .price-data-field > span,
  .process-view-data-model section main .price-data-field > span,
  .task-data-model section main .price-data-field > span {
    display: inline;
    padding-left: 7px;
    white-space: nowrap;
  }
  .process-view-data-model__section__fields__field .price-data-field input,
  .task-data-model__section__fields__field .price-data-field input,
  .process-view-data-model section__fields__field .price-data-field input,
  .task-data-model section__fields__field .price-data-field input,
  .process-view-data-model__section main .price-data-field input,
  .task-data-model__section main .price-data-field input,
  .process-view-data-model section main .price-data-field input,
  .task-data-model section main .price-data-field input {
    display: none !important;
  }
  .process-view-data-model__section__fields__field .price-data-field .unit,
  .task-data-model__section__fields__field .price-data-field .unit,
  .process-view-data-model section__fields__field .price-data-field .unit,
  .task-data-model section__fields__field .price-data-field .unit,
  .process-view-data-model__section main .price-data-field .unit,
  .task-data-model__section main .price-data-field .unit,
  .process-view-data-model section main .price-data-field .unit,
  .task-data-model section main .price-data-field .unit {
    background: none !important;
    border: none !important;
    flex: 0 1 100% !important;
  }
  .process-view-data-model__section__fields__field .price-data-field .unit .handler,
  .task-data-model__section__fields__field .price-data-field .unit .handler,
  .process-view-data-model section__fields__field .price-data-field .unit .handler,
  .task-data-model section__fields__field .price-data-field .unit .handler,
  .process-view-data-model__section main .price-data-field .unit .handler,
  .task-data-model__section main .price-data-field .unit .handler,
  .process-view-data-model section main .price-data-field .unit .handler,
  .task-data-model section main .price-data-field .unit .handler {
    min-height: 0 !important;
  }
  .process-view-data-model__section__fields__field .price-data-field .unit i,
  .task-data-model__section__fields__field .price-data-field .unit i,
  .process-view-data-model section__fields__field .price-data-field .unit i,
  .task-data-model section__fields__field .price-data-field .unit i,
  .process-view-data-model__section main .price-data-field .unit i,
  .task-data-model__section main .price-data-field .unit i,
  .process-view-data-model section main .price-data-field .unit i,
  .task-data-model section main .price-data-field .unit i {
    display: none;
  }
  .process-view-data-model__section__fields__field .errors-messages,
  .task-data-model__section__fields__field .errors-messages,
  .process-view-data-model section__fields__field .errors-messages,
  .task-data-model section__fields__field .errors-messages,
  .process-view-data-model__section main .errors-messages,
  .task-data-model__section main .errors-messages,
  .process-view-data-model section main .errors-messages,
  .task-data-model section main .errors-messages {
    display: none;
  }
  .process-view-data-model__section__fields__field .content-wrapper,
  .task-data-model__section__fields__field .content-wrapper,
  .process-view-data-model section__fields__field .content-wrapper,
  .task-data-model section__fields__field .content-wrapper,
  .process-view-data-model__section main .content-wrapper,
  .task-data-model__section main .content-wrapper,
  .process-view-data-model section main .content-wrapper,
  .task-data-model section main .content-wrapper,
  .process-view-data-model__section__fields__field .td-content-wrapper,
  .task-data-model__section__fields__field .td-content-wrapper,
  .process-view-data-model section__fields__field .td-content-wrapper,
  .task-data-model section__fields__field .td-content-wrapper,
  .process-view-data-model__section main .td-content-wrapper,
  .task-data-model__section main .td-content-wrapper,
  .process-view-data-model section main .td-content-wrapper,
  .task-data-model section main .td-content-wrapper,
  .process-view-data-model__section__fields__field .files-wrapper,
  .task-data-model__section__fields__field .files-wrapper,
  .process-view-data-model section__fields__field .files-wrapper,
  .task-data-model section__fields__field .files-wrapper,
  .process-view-data-model__section main .files-wrapper,
  .task-data-model__section main .files-wrapper,
  .process-view-data-model section main .files-wrapper,
  .task-data-model section main .files-wrapper {
    max-width: calc(100% - 180px) !important;
    width: calc(100% - 180px) !important;
    flex: 0 1 calc(100% - 180px) !important;
  }
  .process-view-data-model__section__fields__field .content-wrapper > textarea,
  .task-data-model__section__fields__field .content-wrapper > textarea,
  .process-view-data-model section__fields__field .content-wrapper > textarea,
  .task-data-model section__fields__field .content-wrapper > textarea,
  .process-view-data-model__section main .content-wrapper > textarea,
  .task-data-model__section main .content-wrapper > textarea,
  .process-view-data-model section main .content-wrapper > textarea,
  .task-data-model section main .content-wrapper > textarea,
  .process-view-data-model__section__fields__field .td-content-wrapper > textarea,
  .task-data-model__section__fields__field .td-content-wrapper > textarea,
  .process-view-data-model section__fields__field .td-content-wrapper > textarea,
  .task-data-model section__fields__field .td-content-wrapper > textarea,
  .process-view-data-model__section main .td-content-wrapper > textarea,
  .task-data-model__section main .td-content-wrapper > textarea,
  .process-view-data-model section main .td-content-wrapper > textarea,
  .task-data-model section main .td-content-wrapper > textarea,
  .process-view-data-model__section__fields__field .files-wrapper > textarea,
  .task-data-model__section__fields__field .files-wrapper > textarea,
  .process-view-data-model section__fields__field .files-wrapper > textarea,
  .task-data-model section__fields__field .files-wrapper > textarea,
  .process-view-data-model__section main .files-wrapper > textarea,
  .task-data-model__section main .files-wrapper > textarea,
  .process-view-data-model section main .files-wrapper > textarea,
  .task-data-model section main .files-wrapper > textarea {
    display: none;
  }
  .process-view-data-model__section__fields__field .content-wrapper .textarea-content-wrapper,
  .task-data-model__section__fields__field .content-wrapper .textarea-content-wrapper,
  .process-view-data-model section__fields__field .content-wrapper .textarea-content-wrapper,
  .task-data-model section__fields__field .content-wrapper .textarea-content-wrapper,
  .process-view-data-model__section main .content-wrapper .textarea-content-wrapper,
  .task-data-model__section main .content-wrapper .textarea-content-wrapper,
  .process-view-data-model section main .content-wrapper .textarea-content-wrapper,
  .task-data-model section main .content-wrapper .textarea-content-wrapper,
  .process-view-data-model__section__fields__field .td-content-wrapper .textarea-content-wrapper,
  .task-data-model__section__fields__field .td-content-wrapper .textarea-content-wrapper,
  .process-view-data-model section__fields__field .td-content-wrapper .textarea-content-wrapper,
  .task-data-model section__fields__field .td-content-wrapper .textarea-content-wrapper,
  .process-view-data-model__section main .td-content-wrapper .textarea-content-wrapper,
  .task-data-model__section main .td-content-wrapper .textarea-content-wrapper,
  .process-view-data-model section main .td-content-wrapper .textarea-content-wrapper,
  .task-data-model section main .td-content-wrapper .textarea-content-wrapper,
  .process-view-data-model__section__fields__field .files-wrapper .textarea-content-wrapper,
  .task-data-model__section__fields__field .files-wrapper .textarea-content-wrapper,
  .process-view-data-model section__fields__field .files-wrapper .textarea-content-wrapper,
  .task-data-model section__fields__field .files-wrapper .textarea-content-wrapper,
  .process-view-data-model__section main .files-wrapper .textarea-content-wrapper,
  .task-data-model__section main .files-wrapper .textarea-content-wrapper,
  .process-view-data-model section main .files-wrapper .textarea-content-wrapper,
  .task-data-model section main .files-wrapper .textarea-content-wrapper,
  .process-view-data-model__section__fields__field .content-wrapper .textarea-content-wrapper.hidden,
  .task-data-model__section__fields__field .content-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model section__fields__field .content-wrapper .textarea-content-wrapper.hidden,
  .task-data-model section__fields__field .content-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model__section main .content-wrapper .textarea-content-wrapper.hidden,
  .task-data-model__section main .content-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model section main .content-wrapper .textarea-content-wrapper.hidden,
  .task-data-model section main .content-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model__section__fields__field .td-content-wrapper .textarea-content-wrapper.hidden,
  .task-data-model__section__fields__field .td-content-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model section__fields__field .td-content-wrapper .textarea-content-wrapper.hidden,
  .task-data-model section__fields__field .td-content-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model__section main .td-content-wrapper .textarea-content-wrapper.hidden,
  .task-data-model__section main .td-content-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model section main .td-content-wrapper .textarea-content-wrapper.hidden,
  .task-data-model section main .td-content-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model__section__fields__field .files-wrapper .textarea-content-wrapper.hidden,
  .task-data-model__section__fields__field .files-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model section__fields__field .files-wrapper .textarea-content-wrapper.hidden,
  .task-data-model section__fields__field .files-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model__section main .files-wrapper .textarea-content-wrapper.hidden,
  .task-data-model__section main .files-wrapper .textarea-content-wrapper.hidden,
  .process-view-data-model section main .files-wrapper .textarea-content-wrapper.hidden,
  .task-data-model section main .files-wrapper .textarea-content-wrapper.hidden {
    display: block;
  }
  .process-view-data-model__section__fields__field textarea,
  .task-data-model__section__fields__field textarea,
  .process-view-data-model section__fields__field textarea,
  .task-data-model section__fields__field textarea,
  .process-view-data-model__section main textarea,
  .task-data-model__section main textarea,
  .process-view-data-model section main textarea,
  .task-data-model section main textarea {
    height: auto;
    display: table;
    width: 100%;
    overflow: hidden;
  }
  .process-view-data-model__section__fields__field input,
  .task-data-model__section__fields__field input,
  .process-view-data-model section__fields__field input,
  .task-data-model section__fields__field input,
  .process-view-data-model__section main input,
  .task-data-model__section main input,
  .process-view-data-model section main input,
  .task-data-model section main input,
  .process-view-data-model__section__fields__field textarea,
  .task-data-model__section__fields__field textarea,
  .process-view-data-model section__fields__field textarea,
  .task-data-model section__fields__field textarea,
  .process-view-data-model__section main textarea,
  .task-data-model__section main textarea,
  .process-view-data-model section main textarea,
  .task-data-model section main textarea,
  .process-view-data-model__section__fields__field .tether-handler,
  .task-data-model__section__fields__field .tether-handler,
  .process-view-data-model section__fields__field .tether-handler,
  .task-data-model section__fields__field .tether-handler,
  .process-view-data-model__section main .tether-handler,
  .task-data-model__section main .tether-handler,
  .process-view-data-model section main .tether-handler,
  .task-data-model section main .tether-handler {
    background: none !important;
    border: none !important;
  }
  .process-view-data-model__section__fields__field input span,
  .task-data-model__section__fields__field input span,
  .process-view-data-model section__fields__field input span,
  .task-data-model section__fields__field input span,
  .process-view-data-model__section main input span,
  .task-data-model__section main input span,
  .process-view-data-model section main input span,
  .task-data-model section main input span,
  .process-view-data-model__section__fields__field textarea span,
  .task-data-model__section__fields__field textarea span,
  .process-view-data-model section__fields__field textarea span,
  .task-data-model section__fields__field textarea span,
  .process-view-data-model__section main textarea span,
  .task-data-model__section main textarea span,
  .process-view-data-model section main textarea span,
  .task-data-model section main textarea span,
  .process-view-data-model__section__fields__field .tether-handler span,
  .task-data-model__section__fields__field .tether-handler span,
  .process-view-data-model section__fields__field .tether-handler span,
  .task-data-model section__fields__field .tether-handler span,
  .process-view-data-model__section main .tether-handler span,
  .task-data-model__section main .tether-handler span,
  .process-view-data-model section main .tether-handler span,
  .task-data-model section main .tether-handler span {
    color: #222;
  }
  .process-view-data-model__section__fields__field input i,
  .task-data-model__section__fields__field input i,
  .process-view-data-model section__fields__field input i,
  .task-data-model section__fields__field input i,
  .process-view-data-model__section main input i,
  .task-data-model__section main input i,
  .process-view-data-model section main input i,
  .task-data-model section main input i,
  .process-view-data-model__section__fields__field textarea i,
  .task-data-model__section__fields__field textarea i,
  .process-view-data-model section__fields__field textarea i,
  .task-data-model section__fields__field textarea i,
  .process-view-data-model__section main textarea i,
  .task-data-model__section main textarea i,
  .process-view-data-model section main textarea i,
  .task-data-model section main textarea i,
  .process-view-data-model__section__fields__field .tether-handler i,
  .task-data-model__section__fields__field .tether-handler i,
  .process-view-data-model section__fields__field .tether-handler i,
  .task-data-model section__fields__field .tether-handler i,
  .process-view-data-model__section main .tether-handler i,
  .task-data-model__section main .tether-handler i,
  .process-view-data-model section main .tether-handler i,
  .task-data-model section main .tether-handler i {
    display: none;
  }
  .process-view-data-model__section__fields__field .single-select .single-select-header,
  .task-data-model__section__fields__field .single-select .single-select-header,
  .process-view-data-model section__fields__field .single-select .single-select-header,
  .task-data-model section__fields__field .single-select .single-select-header,
  .process-view-data-model__section main .single-select .single-select-header,
  .task-data-model__section main .single-select .single-select-header,
  .process-view-data-model section main .single-select .single-select-header,
  .task-data-model section main .single-select .single-select-header {
    background: none !important;
  }
  .process-view-data-model__section__fields__field .single-select .single-select-header .icon,
  .task-data-model__section__fields__field .single-select .single-select-header .icon,
  .process-view-data-model section__fields__field .single-select .single-select-header .icon,
  .task-data-model section__fields__field .single-select .single-select-header .icon,
  .process-view-data-model__section main .single-select .single-select-header .icon,
  .task-data-model__section main .single-select .single-select-header .icon,
  .process-view-data-model section main .single-select .single-select-header .icon,
  .task-data-model section main .single-select .single-select-header .icon,
  .process-view-data-model__section__fields__field .single-select .single-select-header .badge-deleted,
  .task-data-model__section__fields__field .single-select .single-select-header .badge-deleted,
  .process-view-data-model section__fields__field .single-select .single-select-header .badge-deleted,
  .task-data-model section__fields__field .single-select .single-select-header .badge-deleted,
  .process-view-data-model__section main .single-select .single-select-header .badge-deleted,
  .task-data-model__section main .single-select .single-select-header .badge-deleted,
  .process-view-data-model section main .single-select .single-select-header .badge-deleted,
  .task-data-model section main .single-select .single-select-header .badge-deleted {
    display: none !important;
  }
  .process-view-data-model__section__fields__field .single-select .single-select-header .control,
  .task-data-model__section__fields__field .single-select .single-select-header .control,
  .process-view-data-model section__fields__field .single-select .single-select-header .control,
  .task-data-model section__fields__field .single-select .single-select-header .control,
  .process-view-data-model__section main .single-select .single-select-header .control,
  .task-data-model__section main .single-select .single-select-header .control,
  .process-view-data-model section main .single-select .single-select-header .control,
  .task-data-model section main .single-select .single-select-header .control {
    left: 8px !important;
  }
  .process-view-data-model__section__fields__field .checkbox > span,
  .task-data-model__section__fields__field .checkbox > span,
  .process-view-data-model section__fields__field .checkbox > span,
  .task-data-model section__fields__field .checkbox > span,
  .process-view-data-model__section main .checkbox > span,
  .task-data-model__section main .checkbox > span,
  .process-view-data-model section main .checkbox > span,
  .task-data-model section main .checkbox > span {
    display: none;
  }
  .process-view-data-model__section__fields__field .prc-date-picker.date-time-picker .handler,
  .task-data-model__section__fields__field .prc-date-picker.date-time-picker .handler,
  .process-view-data-model section__fields__field .prc-date-picker.date-time-picker .handler,
  .task-data-model section__fields__field .prc-date-picker.date-time-picker .handler,
  .process-view-data-model__section main .prc-date-picker.date-time-picker .handler,
  .task-data-model__section main .prc-date-picker.date-time-picker .handler,
  .process-view-data-model section main .prc-date-picker.date-time-picker .handler,
  .task-data-model section main .prc-date-picker.date-time-picker .handler {
    background: none;
    border: none;
  }
  .process-view-data-model__section__fields__field .prc-date-picker.date-time-picker .handler div,
  .task-data-model__section__fields__field .prc-date-picker.date-time-picker .handler div,
  .process-view-data-model section__fields__field .prc-date-picker.date-time-picker .handler div,
  .task-data-model section__fields__field .prc-date-picker.date-time-picker .handler div,
  .process-view-data-model__section main .prc-date-picker.date-time-picker .handler div,
  .task-data-model__section main .prc-date-picker.date-time-picker .handler div,
  .process-view-data-model section main .prc-date-picker.date-time-picker .handler div,
  .task-data-model section main .prc-date-picker.date-time-picker .handler div {
    color: #222;
  }
  .process-view-data-model__section__fields__field .prc-date-picker.date-time-picker .handler i,
  .task-data-model__section__fields__field .prc-date-picker.date-time-picker .handler i,
  .process-view-data-model section__fields__field .prc-date-picker.date-time-picker .handler i,
  .task-data-model section__fields__field .prc-date-picker.date-time-picker .handler i,
  .process-view-data-model__section main .prc-date-picker.date-time-picker .handler i,
  .task-data-model__section main .prc-date-picker.date-time-picker .handler i,
  .process-view-data-model section main .prc-date-picker.date-time-picker .handler i,
  .task-data-model section main .prc-date-picker.date-time-picker .handler i {
    display: none;
  }
  .process-view-data-model__section__fields__field .textarea-content-wrapper,
  .task-data-model__section__fields__field .textarea-content-wrapper,
  .process-view-data-model section__fields__field .textarea-content-wrapper,
  .task-data-model section__fields__field .textarea-content-wrapper,
  .process-view-data-model__section main .textarea-content-wrapper,
  .task-data-model__section main .textarea-content-wrapper,
  .process-view-data-model section main .textarea-content-wrapper,
  .task-data-model section main .textarea-content-wrapper {
    height: auto !important;
    min-height: 32px !important;
    background: none !important;
  }
  .process-view-data-model__section__fields__field .textarea-content-wrapper .rg-bottom,
  .task-data-model__section__fields__field .textarea-content-wrapper .rg-bottom,
  .process-view-data-model section__fields__field .textarea-content-wrapper .rg-bottom,
  .task-data-model section__fields__field .textarea-content-wrapper .rg-bottom,
  .process-view-data-model__section main .textarea-content-wrapper .rg-bottom,
  .task-data-model__section main .textarea-content-wrapper .rg-bottom,
  .process-view-data-model section main .textarea-content-wrapper .rg-bottom,
  .task-data-model section main .textarea-content-wrapper .rg-bottom {
    display: none !important;
  }
  .process-view-data-model__section__fields__field .textarea-content-wrapper .textarea-content,
  .task-data-model__section__fields__field .textarea-content-wrapper .textarea-content,
  .process-view-data-model section__fields__field .textarea-content-wrapper .textarea-content,
  .task-data-model section__fields__field .textarea-content-wrapper .textarea-content,
  .process-view-data-model__section main .textarea-content-wrapper .textarea-content,
  .task-data-model__section main .textarea-content-wrapper .textarea-content,
  .process-view-data-model section main .textarea-content-wrapper .textarea-content,
  .task-data-model section main .textarea-content-wrapper .textarea-content {
    overflow-y: visible !important;
  }
  .process-view-data-model__section__fields__field .textarea-content-wrapper .textarea-content .link,
  .task-data-model__section__fields__field .textarea-content-wrapper .textarea-content .link,
  .process-view-data-model section__fields__field .textarea-content-wrapper .textarea-content .link,
  .task-data-model section__fields__field .textarea-content-wrapper .textarea-content .link,
  .process-view-data-model__section main .textarea-content-wrapper .textarea-content .link,
  .task-data-model__section main .textarea-content-wrapper .textarea-content .link,
  .process-view-data-model section main .textarea-content-wrapper .textarea-content .link,
  .task-data-model section main .textarea-content-wrapper .textarea-content .link {
    display: none;
  }
  .process-view-data-model__section__fields__field .textarea-content-wrapper .textarea-content .full-link,
  .task-data-model__section__fields__field .textarea-content-wrapper .textarea-content .full-link,
  .process-view-data-model section__fields__field .textarea-content-wrapper .textarea-content .full-link,
  .task-data-model section__fields__field .textarea-content-wrapper .textarea-content .full-link,
  .process-view-data-model__section main .textarea-content-wrapper .textarea-content .full-link,
  .task-data-model__section main .textarea-content-wrapper .textarea-content .full-link,
  .process-view-data-model section main .textarea-content-wrapper .textarea-content .full-link,
  .task-data-model section main .textarea-content-wrapper .textarea-content .full-link {
    display: inline !important;
  }
  .process-view-data-model__section__fields__field .file-data-field .files-error,
  .task-data-model__section__fields__field .file-data-field .files-error,
  .process-view-data-model section__fields__field .file-data-field .files-error,
  .task-data-model section__fields__field .file-data-field .files-error,
  .process-view-data-model__section main .file-data-field .files-error,
  .task-data-model__section main .file-data-field .files-error,
  .process-view-data-model section main .file-data-field .files-error,
  .task-data-model section main .file-data-field .files-error {
    display: none;
  }
  .process-view-data-model__section__fields__field .file-data-field .files-wrapper,
  .task-data-model__section__fields__field .file-data-field .files-wrapper,
  .process-view-data-model section__fields__field .file-data-field .files-wrapper,
  .task-data-model section__fields__field .file-data-field .files-wrapper,
  .process-view-data-model__section main .file-data-field .files-wrapper,
  .task-data-model__section main .file-data-field .files-wrapper,
  .process-view-data-model section main .file-data-field .files-wrapper,
  .task-data-model section main .file-data-field .files-wrapper,
  .process-view-data-model__section__fields__field .file-data-field .files-block,
  .task-data-model__section__fields__field .file-data-field .files-block,
  .process-view-data-model section__fields__field .file-data-field .files-block,
  .task-data-model section__fields__field .file-data-field .files-block,
  .process-view-data-model__section main .file-data-field .files-block,
  .task-data-model__section main .file-data-field .files-block,
  .process-view-data-model section main .file-data-field .files-block,
  .task-data-model section main .file-data-field .files-block {
    background: none !important;
    border: none !important;
  }
  .process-view-data-model__section__fields__field .file-data-field .files-wrapper.empty,
  .task-data-model__section__fields__field .file-data-field .files-wrapper.empty,
  .process-view-data-model section__fields__field .file-data-field .files-wrapper.empty,
  .task-data-model section__fields__field .file-data-field .files-wrapper.empty,
  .process-view-data-model__section main .file-data-field .files-wrapper.empty,
  .task-data-model__section main .file-data-field .files-wrapper.empty,
  .process-view-data-model section main .file-data-field .files-wrapper.empty,
  .task-data-model section main .file-data-field .files-wrapper.empty,
  .process-view-data-model__section__fields__field .file-data-field .files-block.empty,
  .task-data-model__section__fields__field .file-data-field .files-block.empty,
  .process-view-data-model section__fields__field .file-data-field .files-block.empty,
  .task-data-model section__fields__field .file-data-field .files-block.empty,
  .process-view-data-model__section main .file-data-field .files-block.empty,
  .task-data-model__section main .file-data-field .files-block.empty,
  .process-view-data-model section main .file-data-field .files-block.empty,
  .task-data-model section main .file-data-field .files-block.empty {
    display: none !important;
  }
  .process-view-data-model__section__fields__field .file-data-field .files-wrapper .file,
  .task-data-model__section__fields__field .file-data-field .files-wrapper .file,
  .process-view-data-model section__fields__field .file-data-field .files-wrapper .file,
  .task-data-model section__fields__field .file-data-field .files-wrapper .file,
  .process-view-data-model__section main .file-data-field .files-wrapper .file,
  .task-data-model__section main .file-data-field .files-wrapper .file,
  .process-view-data-model section main .file-data-field .files-wrapper .file,
  .task-data-model section main .file-data-field .files-wrapper .file,
  .process-view-data-model__section__fields__field .file-data-field .files-block .file,
  .task-data-model__section__fields__field .file-data-field .files-block .file,
  .process-view-data-model section__fields__field .file-data-field .files-block .file,
  .task-data-model section__fields__field .file-data-field .files-block .file,
  .process-view-data-model__section main .file-data-field .files-block .file,
  .task-data-model__section main .file-data-field .files-block .file,
  .process-view-data-model section main .file-data-field .files-block .file,
  .task-data-model section main .file-data-field .files-block .file {
    background: none !important;
  }
  .process-view-data-model__section__fields__field .file-data-field .files-wrapper .file > a,
  .task-data-model__section__fields__field .file-data-field .files-wrapper .file > a,
  .process-view-data-model section__fields__field .file-data-field .files-wrapper .file > a,
  .task-data-model section__fields__field .file-data-field .files-wrapper .file > a,
  .process-view-data-model__section main .file-data-field .files-wrapper .file > a,
  .task-data-model__section main .file-data-field .files-wrapper .file > a,
  .process-view-data-model section main .file-data-field .files-wrapper .file > a,
  .task-data-model section main .file-data-field .files-wrapper .file > a,
  .process-view-data-model__section__fields__field .file-data-field .files-block .file > a,
  .task-data-model__section__fields__field .file-data-field .files-block .file > a,
  .process-view-data-model section__fields__field .file-data-field .files-block .file > a,
  .task-data-model section__fields__field .file-data-field .files-block .file > a,
  .process-view-data-model__section main .file-data-field .files-block .file > a,
  .task-data-model__section main .file-data-field .files-block .file > a,
  .process-view-data-model section main .file-data-field .files-block .file > a,
  .task-data-model section main .file-data-field .files-block .file > a {
    width: 100% !important;
  }
  .process-view-data-model__section__fields__field .file-data-field .files-wrapper .file span,
  .task-data-model__section__fields__field .file-data-field .files-wrapper .file span,
  .process-view-data-model section__fields__field .file-data-field .files-wrapper .file span,
  .task-data-model section__fields__field .file-data-field .files-wrapper .file span,
  .process-view-data-model__section main .file-data-field .files-wrapper .file span,
  .task-data-model__section main .file-data-field .files-wrapper .file span,
  .process-view-data-model section main .file-data-field .files-wrapper .file span,
  .task-data-model section main .file-data-field .files-wrapper .file span,
  .process-view-data-model__section__fields__field .file-data-field .files-block .file span,
  .task-data-model__section__fields__field .file-data-field .files-block .file span,
  .process-view-data-model section__fields__field .file-data-field .files-block .file span,
  .task-data-model section__fields__field .file-data-field .files-block .file span,
  .process-view-data-model__section main .file-data-field .files-block .file span,
  .task-data-model__section main .file-data-field .files-block .file span,
  .process-view-data-model section main .file-data-field .files-block .file span,
  .task-data-model section main .file-data-field .files-block .file span {
    max-width: 100% !important;
  }
  .process-view-data-model__section__fields__field .file-data-field .files-wrapper .file i,
  .task-data-model__section__fields__field .file-data-field .files-wrapper .file i,
  .process-view-data-model section__fields__field .file-data-field .files-wrapper .file i,
  .task-data-model section__fields__field .file-data-field .files-wrapper .file i,
  .process-view-data-model__section main .file-data-field .files-wrapper .file i,
  .task-data-model__section main .file-data-field .files-wrapper .file i,
  .process-view-data-model section main .file-data-field .files-wrapper .file i,
  .task-data-model section main .file-data-field .files-wrapper .file i,
  .process-view-data-model__section__fields__field .file-data-field .files-block .file i,
  .task-data-model__section__fields__field .file-data-field .files-block .file i,
  .process-view-data-model section__fields__field .file-data-field .files-block .file i,
  .task-data-model section__fields__field .file-data-field .files-block .file i,
  .process-view-data-model__section main .file-data-field .files-block .file i,
  .task-data-model__section main .file-data-field .files-block .file i,
  .process-view-data-model section main .file-data-field .files-block .file i,
  .task-data-model section main .file-data-field .files-block .file i {
    display: none !important;
  }
  .data-model-table > .empty-table-content p a {
    display: none !important;
  }
  .data-model-table > table thead tr th i {
    display: none !important;
  }
  .data-model-table > table tbody tr.total-row {
    background: none !important;
  }
  .data-model-table > table tbody tr .actions_wrapper {
    display: none !important;
  }
  .data-model-table > table tbody tr td .content .multiline-span {
    height: auto !important;
    overflow: visible;
  }
  .data-model-table > table tbody tr td .content .multiline-span .link {
    display: none;
  }
  .data-model-table > table tbody tr td .content .multiline-span .full-link {
    display: inline !important;
  }
  .data-model-table > table tbody tr td .content .multiline-span > .more-btn {
    display: none !important;
  }
  .data-model-table > table tbody tr td .content .files .file {
    background: none !important;
    max-width: 100% !important;
  }
  .task-section {
    overflow: visible !important;
  }
  .task-section__header {
    padding: 0 16px !important;
  }
  .task-section__header > .title {
    flex: 0 1 100% !important;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    padding-top: 0 !important;
  }
  .task-section__header > .title > a {
    margin-top: 4px;
  }
  .task-section__header .navigation,
  .task-section__header .common_back,
  .task-section__header .btns,
  .task-section__header .icon-common_close {
    display: none !important;
  }
  .task-section__content {
    padding: 0 16px !important;
  }
  .task-section__content > aside {
    display: none !important;
  }
  .task-section__content > article {
    width: 100% !important;
    flex: 0 1 100% !important;
    margin-right: 0 !important;
  }
  .task-section__content > article .data-model-errors {
    display: none !important;
  }
  .task-section__content > article > header .prc-date-picker {
    display: none !important;
  }
  .task-section__content > article > header > div > div > .main .avatar-small {
    display: none !important;
  }
  .task-section__content > article > header > div > div > ul {
    display: none !important;
  }
  .task-section__content > article > main {
    overflow: visible !important;
  }
  .task-section__content > article > main .data-model-errors {
    display: none !important;
  }
  .task-section__content > article > main > .task-description-wrapper .hint {
    display: none;
  }
  .task-section__content > article > main markdown-hint {
    display: none;
  }
  body:not(.modal-open) > div[ui-view] {
    overflow: visible;
  }
}
.process-assignees-list {
  padding: 0;
  margin: 0;
  float: right;
  white-space: nowrap;
  display: flex;
  clear: both;
  flex: 0 1 auto;
}
.process-assignees-list li {
  display: inline-block;
  cursor: pointer;
  position: relative;
  min-width: 24px;
}
.process-assignees-list li:focus {
  outline: none;
}
.process-assignees-list li + li {
  margin-left: 4px;
}
.process-assignees-list li > div:hover + .user-tooltip,
.process-assignees-list li > img:hover + .user-tooltip {
  visibility: visible;
  display: block;
}
.process-assignees-list li .user-tooltip {
  display: none;
}
.process-assignees-list li:nth-last-child(2) .user-tooltip {
  right: -44px;
}
.process-assignees-list li:nth-last-child(2) .user-tooltip .arrow {
  right: 48px;
  left: auto;
}
.process-assignees-list li:last-child .user-tooltip {
  right: -12px;
}
.process-assignees-list li:last-child .user-tooltip .arrow {
  right: 16px;
  left: auto;
}
.process-assignees-list li.assignees-rest > span {
  white-space: nowrap;
  background: #e8e8e8;
  height: 24px;
  min-width: 24px !important;
  padding: 0 4px;
  line-height: 24px;
  border-radius: 8px;
  font-family: 'Roboto-Regular', Arial;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  color: #959595;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
}
.process-assignees-list li.assignees-rest > span:hover + .popup-actors-list {
  visibility: visible;
}
.process-assignees-list li.assignees-rest .popup-actors-list {
  right: -12px;
  top: 36px;
  visibility: hidden;
  font-size: 12px;
  width: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.process-assignees-list li.assignees-rest .popup-actors-list .arrow {
  background: #fff;
  display: block;
  height: 16px;
  width: 16px;
  border: 1px solid #f6f6f6;
  border-top: none;
  border-left: none;
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  top: -8px;
  position: absolute;
  right: 16px;
}
.process-attachments-list > main {
  padding-left: 0;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.process-attachments-list > main:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.process-attachments-list .attachments__attachment.highlighted {
  width: 100%;
  margin-left: 0;
  padding-bottom: 0;
}
.comments.process-comments-list {
  max-height: calc(100% - 54px);
}
.comments.process-comments-list > main {
  padding-left: 0;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.comments.process-comments-list > main:after {
  content: '';
  height: 100px;
  width: 100%;
  display: block;
}
.comments__section > header {
  color: #959595;
  font-size: 12px;
  line-height: 16px;
}
.comments__section > header .name {
  font-size: 14px;
  font-family: 'Roboto-Medium', Arial;
  color: #222;
}
.comments__section > header .name:hover {
  color: #2e9df0;
}
.comments__section > header .comments-count {
  color: #959595;
  cursor: default;
}
.comments__section > header .comments-count:after {
  content: '';
  height: 5px;
  width: 5px;
  display: none;
  border-bottom: 1px solid #2e9df0;
  border-right: 1px solid #2e9df0;
  transform: rotate(45deg) translate(-2px, -2px);
  margin-left: 5px;
}
.comments__section > header .comments-count.expanded:after {
  transform: rotate(225deg) translate(0) !important;
}
.comments__section > header .comments-count.active {
  color: #2e9df0;
  cursor: pointer;
}
.comments__section > header .comments-count.active:after {
  display: inline-block;
}
.comments__section:first-child .comments__section__list__item:first-child footer,
.comments__section:first-child .comments__section__list__item:nth-child(2) footer {
  position: relative;
}
.comments__section:first-child .comments__section__list__item:first-child footer .mentions-wrapper,
.comments__section:first-child .comments__section__list__item:nth-child(2) footer .mentions-wrapper {
  position: absolute;
  top: 100%;
  width: 100%;
  right: 0;
}
.comments__section:first-child .comments__section__list__item:first-child footer .mentions-wrapper > ul,
.comments__section:first-child .comments__section__list__item:nth-child(2) footer .mentions-wrapper > ul {
  position: static;
}
.comments__section__list {
  margin: 12px 0;
  padding: 0 16px 0 12px;
  border-left: 2px solid #e1ebef;
}
.comments__section__list__item.highlighted {
  width: 100%;
  background-color: #f2fafe;
  margin: 2px 0 -10px -12px;
  animation: spring 0.3s ease-in 0.1s;
  padding: 10px 0 10px 12px;
}
.comments__section__list__item + article {
  margin-top: 12px;
}
.comments__section__list__item > header {
  display: flex;
  width: 100%;
}
.comments__section__list__item > header:focus {
  outline: none;
}
.comments__section__list__item > header > .user .avatar-small {
  margin-right: 8px;
}
.comments__section__list__item > header > .main {
  width: calc(100% - (4 * 12px));
}
.comments__section__list__item > header > .main > header {
  font-size: 12px;
  color: #959595;
}
.comments__section__list__item > header > .main > header .name {
  color: #222;
  font-size: 14px;
}
.comments__section__list__item > header > .main > header a {
  color: #2e9df0;
  display: inline-block;
}
.comments__section__list__item > header > .main > p {
  font-size: 14px;
  color: #222;
  font-family: 'Roboto-Regular', Arial;
  line-height: 21px;
  margin-top: 4px;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-height: 100%;
  max-width: 99%;
}
.comments__section__list__item > header > .main > p .emoji {
  vertical-align: text-top;
}
.comments__section__list__item > header > .main > p a {
  color: #2e9df0;
  cursor: pointer;
}
.comments__section__list__item > header > .main > p .mention {
  display: inline-block;
  color: #2e9df0;
  font-family: 'Roboto-Regular', Arial;
  position: relative;
  white-space: nowrap;
}
.comments__section__list__item > header > .main > p .mention:hover .user-tooltip {
  visibility: visible;
  padding: 8px;
}
.comments__section__list__item > header > .main > p .mention:hover .user-tooltip .user-tooltip__container {
  display: flex;
}
.comments__section__list__item > header > .main > p .mention > .user-tooltip {
  top: 140%;
  left: -44px;
  display: table;
  padding: 0;
}
.comments__section__list__item > header > .main > p .mention > .user-tooltip .user-tooltip__container {
  display: none;
}
.comments__section__list__item > header > .main > p .mention > .user-tooltip .arrow {
  left: 55px;
}
.comments__section__list__item > header > .main .preview {
  margin-top: 8px;
  padding: 12px;
  background-color: #f5f5f5;
  min-height: 45px;
  display: flex;
  align-items: center;
  width: 100%;
}
.comments__section__list__item > header > .main .preview > i {
  color: #2e9df0;
  font-size: 21px;
  margin-right: 12px;
}
.comments__section__list__item > header > .main .preview > i::before {
  margin: 0;
}
.comments__section__list__item > header > .main .preview__content {
  width: calc(100% - (21px + 8px));
  font-family: 'Roboto-Regular', Arial;
  word-wrap: break-word;
}
.comments__section__list__item > header > .main .preview__content react-file-preview {
  display: block;
}
.comments__section__list__item > header > .main .preview__content a {
  color: #2e9df0;
  font-size: 14px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.comments__section__list__item > footer .mentions-wrapper {
  z-index: 10;
}
.comments__section__list__item > footer .mentions-wrapper > ul {
  bottom: 0;
  width: 100%;
}
.comments__section__list__item > footer .process-comments-form {
  margin: 12px 0 0;
  max-width: 100%;
}
.comments__section__list__item > footer .process-comments-form > div textarea {
  margin: 0;
  padding: 10px 65px 8px 55px !important;
}
.comments__section__list__item > footer .process-comments-form > div #ui-mention-highlight {
  top: 0;
  padding: 10px 65px 8px 55px;
}
.comments__section__list__item > footer .process-comments-form #comment-send {
  padding: 0 12px;
}
.process-date-picker {
  display: flex;
  align-items: center;
  margin: 0;
}
.process-date-picker.hasPermission {
  cursor: pointer;
}
.process-date-picker:focus {
  outline: none;
}
.process-date-picker.hasPermission:hover span.date-str {
  color: #2e9df0;
}
.process-date-picker .round-progress-wrapper {
  margin-left: 8px;
}
.process-date-picker span {
  display: block;
  font-size: 12px;
  text-align: right;
}
.process-date-picker span.grey {
  color: #959595;
}
.process-date-picker span.red {
  color: #f75f5f;
}
.process-date-picker span.bold {
  font-family: 'Roboto-Regular', Arial;
}
.process-date-picker > span.handler {
  transition: none;
}
@media only screen and (max-width: 414px) {
  .process-date-picker .round-progress-wrapper {
    display: none;
  }
}
.process-picker-content {
  right: -100px !important;
}
.process-task-list-due-date > div > span.formatted-date.hasPermission:hover {
  color: #2e9df0;
  cursor: pointer;
  transition: all 0.15s ease;
}
.process-task-list-due-date > div > span.red {
  color: #f75f5f;
}
.process-task-list-due-date:focus {
  outline: none;
}
.process-task-list-due-date .icon-task_due_date_add_small:hover {
  color: #2e9df0;
  cursor: pointer;
  transition: all 0.15s ease;
}
.process-task-list-due-date > .date-string,
.process-task-list-due-date > span.date-icon {
  margin-left: 12px !important;
}
.process-task-tooltip {
  display: table;
  flex: 0 1 auto;
  visibility: hidden;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 5;
  top: 20px;
  align-items: center;
  background: #fff;
  padding: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.process-task-tooltip__container {
  display: flex;
  align-items: center;
}
.process-task-tooltip__container .avatar-small.group {
  color: #a3a3a3;
  border: 1px dashed #9d9d9d;
}
.process-task-tooltip__container .avatar-small.group i {
  font-size: 14px;
  margin-left: 2px;
  margin-top: -1px;
  display: block;
}
.process-task-tooltip__container > div {
  display: inline-block;
}
.process-task-tooltip__container > div > span {
  display: table;
  font-family: 'Roboto-Regular', Arial;
  white-space: nowrap;
  margin-left: 6px;
}
.process-task-tooltip__container > div > span.name {
  font-size: 12px;
  color: #222;
}
.process-task-tooltip__container > div > span.info-line {
  font-size: 10px;
  color: #a3a3a3;
}
.process-task-tooltip__container > div > span.info-line i {
  font-style: normal;
  color: #222;
}
.process-task-tooltip__container > div > span.info-line .red {
  color: #f75f5f;
}
.process-task-tooltip .arrow {
  background: #fff;
  display: block;
  height: 12px;
  width: 12px;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-left: none;
  transform: rotate(225deg);
  left: 12px;
  top: -6px;
  position: absolute;
}
.processes__main {
  height: 100%;
  width: 100%;
}
.select-field-edition {
  margin-left: 8px;
  position: relative;
  padding-right: 20px;
}
.select-field-edition .handler {
  font-family: 'Roboto-Medium', Arial;
  color: #9d9d9d;
  text-decoration: none;
  position: relative;
  border: 1px #cacaca solid;
  cursor: pointer;
  background-color: transparent;
  border-radius: 16px;
  transition: all 0.2s ease;
  padding: 5px 8px;
  height: 32px;
  display: flex;
  align-items: center;
}
.select-field-edition .handler:hover {
  color: #2e9df0;
  border-color: #2e9df0;
}
.select-field-edition .handler i {
  font-size: 16px;
}
.select-field-edition .handler i.icon-common_arrow_down {
  font-size: 4px !important;
}
.select-field-edition .icon-lock {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 0;
  font-size: 10px;
  color: #9d9d9d;
  cursor: help;
}
.select-field-type {
  margin-left: 12px;
  min-width: 105px;
  max-width: 133px;
}
.select-field-type header {
  padding: 8px 24px 8px 7px;
  border: 2px #cacaca solid;
  border-radius: 4px;
  height: 32px;
  background: #fff;
  border-width: 1px;
  color: #222;
  resize: none;
  outline: 0;
  cursor: pointer;
  min-width: 105px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-field-type header i {
  position: absolute;
  right: 8px;
  visibility: visible;
  color: #222;
  font-size: 4px;
  top: 13px;
}
.select-field-type__options {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.select-field-type__options .icn {
  width: 24px;
}
.select-field-type__options .icn.icon-task_field_multiline,
.select-field-type__options .icn.icon-task_field_dropdown,
.select-field-type__options .icn.icon-task_field_yesno,
.select-field-type__options .icn.icon-task_field_money,
.select-field-type__options .icn.icon-toaster_mail {
  font-size: 10px;
}
.select-field-type__options .icn.icon-task_field_number {
  font-size: 9px;
}
.select-field-type__options .icn.icon-company_users,
.select-field-type__options .icn.icon-task_field_multiselect,
.select-field-type__options .icn.icon-task_attachment {
  font-size: 13px;
}
.drop-element.drop-open .select-field-type__options {
  display: grid;
}
.select-processes-group .handler {
  font-family: 'Roboto-Medium', Arial;
  color: #9d9d9d;
  text-decoration: none;
  position: relative;
  border: 1px #cacaca solid;
  cursor: pointer;
  background-color: transparent;
  border-radius: 16px;
  transition: all 0.2s ease;
  padding: 5px 8px;
  height: 32px;
  display: flex;
  align-items: center;
}
.select-processes-group .handler:hover {
  color: #2e9df0;
  border-color: #2e9df0;
}
.select-processes-group .handler i {
  font-size: 17px;
}
.select-processes-group .handler i.icon-common_arrow_down {
  font-size: 4px !important;
  margin-left: 5px !important;
}
.processes-group-element {
  z-index: 1100;
}
.processes-group-element.processes-group-open .dropdown-menu--processes-group {
  display: block;
}
.processes-group-element .dropdown-menu--processes-group {
  display: none;
  min-width: 90px;
  background: #fff;
  border: 1px #cacaca solid;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}
.processes-group-element .dropdown-menu--processes-group li.separated {
  border-top: 1px solid #e3e3e3;
}
.processes-group-element .dropdown-menu--processes-group li a {
  font-size: 14px;
  color: #222;
  padding: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 105px;
}
.processes-group-element .dropdown-menu--processes-group li a i {
  color: #959595;
  font-size: 17px;
  margin-right: 12px;
}
.processes-group-element .dropdown-menu--processes-group li a:hover {
  color: #2e9df0;
}
.processes-group-element .dropdown-menu--processes-group li a.active i {
  color: #4abc3b;
}
.sub-form-group {
  margin-top: 24px;
  border: 1px solid #e1ebef;
  border-left-width: 3px;
  display: flex;
  align-items: flex-start;
  padding: 10px 12px 16px 12px;
  position: relative;
}
.sub-form-group.inactive {
  background: #f5f5f5;
}
.sub-form-group.completed {
  border-left-color: #4abc3b;
}
.sub-form-group > span > .icon-template_create_dragable,
.sub-form-group > .icon-template_create_dragable {
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -15px;
  font-size: 27px;
  color: #cacaca;
  cursor: pointer;
}
.sub-form-group > span > .icon-template_create_dragable:focus,
.sub-form-group > .icon-template_create_dragable:focus {
  outline: none;
}
.sub-form-group > span > .icon-template_create_dragable:hover,
.sub-form-group > .icon-template_create_dragable:hover {
  color: #222;
}
.sub-form-group > .icon-common_close {
  position: absolute;
  top: 11px;
  right: 10px;
  font-size: 12px;
  color: #cacaca;
  cursor: pointer;
}
.sub-form-group > .icon-common_close:hover {
  color: #222;
}
.sub-form-group > .icon-common_close:focus {
  outline: none;
}
.sub-form-group + .sub-form-group {
  margin-top: 0;
  border-top: none;
}
.sub-form-group.add-task {
  margin-top: -1px;
  align-items: center;
  cursor: pointer;
  padding: 10px 12px;
  margin-bottom: 120px;
}
.sub-form-group.add-task .content {
  color: #9d9d9d;
  font-family: 'Roboto-Medium', Arial;
  font-size: 14px;
}
.sub-form-group.add-task:focus {
  outline: none;
}
.sub-form-group.add-task:hover a,
.sub-form-group.add-task:focus a {
  border-color: #2e9df0;
}
.sub-form-group.add-task:hover a > i,
.sub-form-group.add-task:focus a > i {
  color: #2e9df0;
}
.sub-form-group.add-task:hover .content,
.sub-form-group.add-task:focus .content {
  color: #2e9df0;
}
.sub-form-group.add-task > a {
  margin-top: 0;
}
.sub-form-group.add-task > a > i {
  padding: 0;
  margin: 0;
  font-size: 16px;
}
.sub-form-group > a {
  line-height: 38px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  overflow: hidden;
  text-align: center;
  border: 1px dashed #9d9d9d;
  border-radius: 16px;
  display: block;
  margin: 3px 12px 0 1px;
  color: #a3a3a3;
}
.sub-form-group > a i {
  font-size: 21px;
  padding-left: 4px;
}
.sub-form-group > .avatar {
  margin: 3px 12px 0 1px;
}
.sub-form-group input[type=text],
.sub-form-group textarea {
  width: 100%;
  display: block;
  border: none;
  background: transparent;
  color: #222;
  font-size: 12px;
  line-height: 18px;
  margin: 7px 0 11px 0;
  resize: none;
}
.sub-form-group input[type=text].filled + .selectize-control,
.sub-form-group textarea.filled + .selectize-control {
  margin-top: 0;
}
.sub-form-group input[type=text].filled:hover,
.sub-form-group textarea.filled:hover {
  color: #2e9df0;
  cursor: pointer;
}
.sub-form-group input[type=text].filled:focus,
.sub-form-group textarea.filled:focus {
  color: #222;
  cursor: inherit;
}
.sub-form-group input[type=text]:focus,
.sub-form-group textarea:focus {
  outline: none;
}
.sub-form-group input[type=text]:first-child,
.sub-form-group textarea:first-child {
  font-size: 14px;
  margin-top: 5px;
}
.sub-form-group textarea {
  margin-bottom: 5px !important;
}
.sub-form-group .content {
  width: 100%;
  max-width: 628px;
  display: table;
}
.sub-form-group .task-footer {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
  position: relative;
}
.sub-form-group .task-footer.no-offset .prc-date-picker {
  margin-top: 0;
}
.sub-form-group .task-footer .prc-date-picker {
  margin-top: 2px;
}
.sub-form-group .task-footer .select-wrapper {
  flex: 0 1 100%;
  width: 100%;
  padding-top: 2px;
}
.sub-form-group .help-tooltip {
  display: none;
  height: 50px;
  width: 260px;
  top: -55px;
  left: calc(50% - 130px);
  position: absolute;
}
.sub-form-group .help-tooltip .arrow {
  position: absolute !important;
  left: calc(50% - 11px);
  bottom: -10px;
  transform: rotate(180deg);
}
.sub-form-groups.as-sortable-dragging .sub-form-group {
  background: #fff;
  box-shadow: 0 2px 8px #959595;
}
.sub-form-groups.as-sortable-dragging .sub-form-group:not(.COMPLETED):not(.FIRST).inactive {
  background: #f5f5f5;
}
.sub-form-groups.as-sortable-dragging .sub-form-group .help-tooltip {
  display: block;
  visibility: visible;
}
.task-form-assignee {
  flex: 0 1 64px;
  width: 64px;
  min-width: 64px;
}
.task-form-assignee.process {
  flex: 0 1 52px;
  min-width: 52px;
  width: 52px;
}
.task-form-assignee.process > div,
.task-form-assignee.process > img {
  margin: 3px 12px 0 1px;
}
.task-form-assignee .thumbnail {
  text-transform: uppercase;
}
.task-form-assignee div,
.task-form-assignee img {
  height: 40px;
  line-height: 39px;
  width: 40px;
  min-width: 40px;
  border-radius: 13px;
  margin: 0 12px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  overflow: hidden;
}
.task-form-assignee div.group,
.task-form-assignee img.group {
  background-color: #e8e8e8;
  color: #a3a3a3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
}
.task-form-assignee div.group i,
.task-form-assignee img.group i {
  font-size: 12px;
  width: auto;
  display: none;
}
.task-form-assignee div.group i::before,
.task-form-assignee img.group i::before {
  margin: 0;
}
.task-form-assignee div.group > .rectangle,
.task-form-assignee img.group > .rectangle {
  display: inline-block;
  border: 2px #a3a3a3 solid !important;
  border-radius: 4px;
  width: 10px;
  height: 10px;
  min-width: 0;
  box-sizing: content-box;
  margin: 0;
  padding: 0;
}
.task-form-assignee div.group.approval,
.task-form-assignee img.group.approval {
  border-radius: 20px;
}
.task-form-assignee div.group.approval > i,
.task-form-assignee img.group.approval > i {
  display: inline;
}
.task-form-assignee div.group.approval > .rectangle,
.task-form-assignee img.group.approval > .rectangle {
  display: none;
}
.task-form-assignee div.unassigned,
.task-form-assignee img.unassigned {
  border: 1px dashed #9d9d9d;
  display: block;
  color: #a3a3a3;
  border-radius: 16px;
}
.task-form-assignee div.unassigned i,
.task-form-assignee img.unassigned i {
  font-size: 21px;
  padding-left: 4px;
}
.review-actions {
  width: 24px;
  height: 32px;
  position: absolute;
  right: 0;
  display: inline-block;
}
.review-actions:focus {
  outline: none;
}
.review-actions > .handler {
  padding-right: 2px;
  height: 100%;
  color: #fff;
  text-align: center;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease;
  cursor: pointer;
}
.review-actions > .handler:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.review-actions > .handler i {
  font-size: 5px;
  line-height: 32px;
  vertical-align: middle;
}
.review-actions-drop {
  margin-left: -150px;
  margin-top: 4px;
}
.review-actions-drop.review-actions-drop-open .dropdown-menu--review-actions {
  display: block;
}
.review-actions-drop .dropdown-menu--review-actions {
  display: none;
  background: #fff;
  border: 1px #cacaca solid;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  min-width: 175px;
}
.review-actions-drop .dropdown-menu--review-actions li.separated {
  border-top: 1px solid #e3e3e3;
}
.review-actions-drop .dropdown-menu--review-actions li a {
  font-size: 14px;
  color: #222;
  padding: 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.review-actions-drop .dropdown-menu--review-actions li a:hover {
  color: #2e9df0;
}
.templates--start {
  position: relative;
  height: 100%;
  width: 100%;
}
.templates--start__header {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  color: #222;
  font-size: 14px;
  padding: 0 32px;
}
.templates--start__header h3 {
  font-size: 14px;
}
.templates--start__header > a {
  color: #cdcdcd;
  position: absolute;
  cursor: pointer;
}
.templates--start__header > a.common_back {
  left: 0;
  font-size: 40px;
  line-height: 40px;
  top: 12px;
  width: 20px;
  display: inline-block;
}
.templates--start__header > a.common_back:focus {
  outline: none;
}
.templates--start__header > a.common_back > i {
  margin: 0;
}
.templates--start__header > a.common_back:hover {
  color: #2e9df0;
}
.templates--start__header .buttons-block {
  flex-grow: 2;
  margin-left: 12px;
}
.templates--start__header .buttons-block > a {
  margin-left: 8px;
}
.templates--start__header .buttons-block > a .short {
  display: none;
}
.templates--start__header .buttons-block > a .full {
  display: inline;
}
.templates--start__header .search-block {
  position: relative;
}
.templates--start__header .search-block > i {
  position: absolute;
  color: #959595;
  cursor: pointer;
}
.templates--start__header .search-block > i:focus {
  outline: none;
}
.templates--start__header .search-block > i.icon-header_search {
  left: 6px;
  margin-top: -1px;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.15s ease;
}
.templates--start__header .search-block > i.icon-common_remove {
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.templates--start__header .search-block > input {
  position: relative;
  z-index: 1;
  transition: all 0.15s ease;
  opacity: 1;
  padding: 8px 30px;
  appearance: none;
  flex: 0 1 100%;
  width: 160px;
  border: 1px #cacaca solid;
  border-radius: 4px;
  box-shadow: none !important;
  color: #222;
  resize: none;
  outline: 0;
  cursor: pointer;
  background: transparent;
}
.templates--start__header .search-block > input::-ms-clear {
  display: none;
}
.templates--start__header .search-block > input:focus,
.templates--start__header .search-block > input.active {
  outline: 0;
  border-color: #2e9df0;
  border-width: 2px;
  cursor: text;
}
.templates--start__header .search-block > input:focus + .icon-header_search,
.templates--start__header .search-block > input.active + .icon-header_search {
  color: #222;
}
.templates--start__header.searching .search-block input {
  width: 240px;
}
.templates--start__body {
  height: calc(100% - 64px);
  padding: 0 24px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.templates--start__body:after {
  content: '';
  height: 70px;
  width: 100%;
  display: block;
}
.templates--start__body .templates-total {
  color: #959595;
  font-size: 12px;
  display: flex;
  white-space: nowrap;
  margin: 0 0 0 8px;
  height: 0;
}
.templates--start__content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 18px 0 0;
}
.templates--start__content__template {
  background-color: #f5f5f5;
  transition: background 0.1s ease;
  box-shadow: 0 0 1px 1px #e8e8e8;
  height: 240px;
  width: 225px;
  padding: 24px;
  margin: 0 8px 16px;
  border-radius: 9px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.templates--start__content__template:focus {
  outline: none;
}
.templates--start__content__template:hover,
.templates--start__content__template.hover {
  background-color: #e8e8e8;
  overflow: visible;
}
.templates--start__content__template:hover footer,
.templates--start__content__template.hover footer {
  opacity: 0;
}
.templates--start__content__template:hover > .actions,
.templates--start__content__template.hover > .actions {
  visibility: visible;
  transform: translateY(-44px);
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.15s;
  opacity: 1;
}
.templates--start__content__template:hover > .edit-actions,
.templates--start__content__template.hover > .edit-actions {
  display: block;
}
.templates--start__content__template > .actions {
  position: absolute;
  bottom: -32px;
  left: 0;
  height: 32px;
  width: 100%;
  text-align: center;
  transform: translateY(0);
  opacity: 0;
  visibility: hidden;
}
.templates--start__content__template > .actions .btn.disabled {
  background-color: #d8d8d8 !important;
}
.templates--start__content__template > .edit-actions {
  position: absolute;
  top: 4px;
  right: 4px;
  display: none;
}
.templates--start__content__template > .edit-actions span,
.templates--start__content__template > .edit-actions .btn-icon {
  font-size: 28px;
  line-height: 38px;
  display: inline-block;
  vertical-align: unset;
  color: #2e9df0;
  cursor: pointer;
}
.templates--start__content__template > .edit-actions span:focus,
.templates--start__content__template > .edit-actions .btn-icon:focus {
  outline: none;
}
.templates--start__content__template > .edit-actions span.disabled,
.templates--start__content__template > .edit-actions .btn-icon.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.templates--start__content__template > .edit-actions div[ng-transclude] {
  display: inline-block;
}
.templates--start__content__template h4 {
  margin-top: 16px;
  display: block;
  max-height: 33.6px;
  /* Fallback for non-webkit */
  width: 100%;
  color: #222;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 14px;
  font-family: 'Roboto-Regular', Arial;
}
.templates--start__content__template p {
  display: block;
  max-height: 60px;
  /* Fallback for non-webkit */
  width: 100%;
  color: #222;
  overflow: hidden;
  font-family: 'Roboto-Regular', Arial;
  font-size: 12px;
  margin-top: 16px;
  line-height: 15px;
}
.templates--start__content__template p.short {
  max-height: 30px;
}
.templates--start__content__template .avatar {
  color: #40484c;
  font-size: 30px;
}
.templates--start__content__template .avatar i {
  display: block;
  line-height: 40px;
}
.templates--start__content__template .avatar i:before {
  margin-left: -1px;
}
.templates--start__content__template .avatar:not(.default) {
  line-height: 44px;
}
.templates--start__content__template .avatar.start {
  line-height: 40px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  overflow: hidden;
  border: 2px dashed #ccc;
  border-radius: 13px;
  margin: 0 auto;
  color: #ccc;
}
.templates--start__content__template .avatar.start i {
  display: block;
  height: 40px;
  font-size: 46px;
  margin-left: 7px;
  padding-top: 9px;
}
.templates--start__content__template .avatar.default {
  background-color: #ccc;
}
.templates--start__content__template footer {
  position: absolute;
  font-family: 'Roboto-Regular', Arial;
  color: #959595;
  font-size: 12px;
  bottom: 24px;
  left: 24px;
  opacity: 1;
  white-space: nowrap;
  transition: opacity 0.1s ease;
  line-height: 20px;
}
.templates--start__content__template footer span {
  display: block;
}
.templates--start__content__template footer span .icon-small_clock,
.templates--start__content__template footer span .icon-error_hint {
  margin-left: -2px;
}
.templates--start__content__template footer span.failed-run {
  color: #f75f5f;
}
.templates--start__content__template.action-card {
  background-color: #fff;
  border: unset;
  box-shadow: unset;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23A8A8A8FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 9px;
}
.templates--start__content__template.action-card .avatar {
  margin: 35px auto 25px;
  color: #a8a8a8;
}
.templates--start__content__template.action-card .avatar.rounded i {
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 20px;
  padding-left: 1px;
  border-radius: 50%;
  border: 1px dashed #a8a8a8;
}
.templates--start__content__template.action-card h4 {
  text-align: center;
  color: #2e9df0;
}
.templates--start__content__template.action-card p {
  text-align: center;
  color: #959595;
}
.templates--start__content__template.action-card:hover {
  background-image: unset;
  box-shadow: 0 0 1px 1px #2e9df0 inset;
}
.templates--start__content__template.action-card:hover .avatar {
  color: #2e9df0;
}
.templates--start__content__template.action-card:hover .avatar.rounded i {
  border-color: #2e9df0;
}
.templates--start__empty-state,
.templates--start__error-state {
  border: 1px solid #e1ebef;
  padding: 64px 0;
  width: 100%;
  text-align: center;
  margin-top: 24px;
}
.templates--start__empty-state h5,
.templates--start__error-state h5 {
  color: #222;
  font-size: 14px;
  margin-top: 16px;
}
.templates--start__empty-state > i,
.templates--start__error-state > i {
  font-size: 64px;
  color: #d1d1d1;
}
.templates--start__empty-state p,
.templates--start__error-state p {
  margin-top: 8px;
  font-size: 12px;
  color: #959595;
}
.templates--start__empty-state p a,
.templates--start__error-state p a {
  color: #2e9df0;
}
@media only screen and (max-width: 1023px) {
  .templates--start__empty-state p span.break,
  .templates--start__error-state p span.break {
    display: block;
    margin: 4px 0;
  }
}
.templates--start__error-state {
  border-color: #f75f5f;
}
@media only screen and (max-width: 400px) {
  .templates--start__header h3 {
    flex-grow: 2;
  }
  .templates--start__header .buttons-block {
    display: none;
  }
}
@media only screen and (max-width: 610px) {
  .templates--start__header .search-block {
    position: relative;
    height: 32px;
    width: 34px;
    flex: 0 0 34px;
  }
  .templates--start__header .search-block input {
    width: 30px;
    border-radius: 16px;
    padding-left: 2px;
    position: absolute;
    right: 0;
  }
  .templates--start__header.searching h3,
  .templates--start__header.searching .buttons-block {
    display: none;
  }
  .templates--start__header.searching .search-block {
    width: 100%;
    flex: 0 0 100%;
  }
  .templates--start__header.searching .search-block input {
    width: 100%;
    border-radius: 4px;
    padding: 8px 30px;
  }
}
@media only screen and (max-width: 680px) {
  .templates--start__header .buttons-block > a .short {
    display: inline;
  }
  .templates--start__header .buttons-block > a .full {
    display: none;
  }
}
@media only screen and (max-width: 720px) {
  .templates--start__header.searching .buttons-block > a .short {
    display: inline;
  }
  .templates--start__header.searching .buttons-block > a .full {
    display: none;
  }
}
.title-editable {
  position: relative;
}
.title-editable.editable h1 {
  padding: 1px 4px 0 4px;
  margin-left: -4px;
  font-family: 'Roboto-Regular', Arial;
}
.title-editable.editable h1:focus {
  outline: none;
}
.title-editable.editable:hover h1 {
  border: 1px #cacaca solid;
  cursor: pointer;
}
.title-editable .title-editable-invisible {
  position: absolute;
  visibility: hidden;
}
.title-editable h1 {
  color: #222;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  font-size: 21px;
  max-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 4px;
  white-space: normal;
}
.title-editable h1:focus {
  outline: none;
}
.title-editable > textarea {
  color: #222;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  font-size: 21px;
  max-height: 52px;
  overflow: hidden;
  resize: none;
  background: transparent;
  padding: 0;
  margin-top: 1px !important;
  width: 100%;
  outline: none;
  border: 1px solid transparent;
  cursor: text;
}
.title-editable > input {
  color: #222;
  font-family: 'Roboto-Regular', Arial;
  font-weight: normal;
  font-size: 21px;
  max-height: 24px;
  overflow: hidden;
  resize: none;
  background: transparent;
  width: 100%;
  outline: none;
  border: 1px solid transparent;
  padding: 0;
  margin: 2px 0 6px 0;
  cursor: text;
  display: none;
}
@media only screen and (max-width: 767px) {
  .title-editable h1 {
    white-space: nowrap;
  }
  .title-editable > textarea {
    display: none;
  }
  .title-editable > input {
    display: block;
  }
}
