.data-model-table {
  width: 100%;
  border: 1px solid rgb(232, 232, 232);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  .no-focus();

  &.no-scroll {
    overflow: hidden;
    border: 1px solid rgb(232, 232, 232);

    & > table {
      border-bottom: 1px solid rgb(232, 232, 232);
    }
  }

  &.full-view {
    max-height: 70vh;
    height: 70vh;
    border: none;

    & > table {
      margin: 0 auto;
      min-width: 80%;
      border: 1px solid rgb(232, 232, 232);

      thead tr {
        border-left: 0;
        border-right: 0;
      }

      tbody tr {
        cursor: default;

        td {
          .content {
            .file {
              max-width: 100%;
            }

            .full-link {
              display: none;
            }
          }
        }

        .content > .multiline-span {
          &.short {
            height: 72px;
          }
        }

        &:hover {
          background-color: transparent;
          .more-btn {
            background-color: @color-white;
          }
        }
      }
    }

    & > .empty-table-content {
      max-width: 100%;
    }
  }

  & > table {
    width: auto;
    table-layout: fixed;
    border-collapse: collapse;

    &.short {
      min-width: 100%;
    }

    thead {
      tr {
        th {
          padding: @offset-md;
          vertical-align: middle;
          color: @color-main-copy;
          font-size: @font-size-sm;
          font-family: @font-family-medium;
          font-weight: normal;
          text-align: left;
          word-wrap: break-word;

          i {
            font-size: 6px;
            transform: translateY(-2px);

            &.icon-template_table_readonly {
              font-size: 8px;
              color: rgb(149, 149, 149);
            }
          }

          &:first-child {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
          }

          & + th {
            border-left: 1px solid rgb(245, 245, 245);
          }

          &.STRING_SINGLE_LINE,
          &.STRING_MULTI_LINE,
          &.EMAIL,
          &.FILES_LIST {
            width: 200px;
            min-width: 200px;
          }
          &.DATE_ONLY_VALUE,
          &.USER_FIELD {
            width: 120px;
            min-width: 120px;
          }
          &.DATE_AND_TIME,
          &.MULTI_SELECTOR,
          &.RADIO_SELECTOR {
            width: 150px;
            min-width: 150px;
          }
          &.NUMERIC_VALUE {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
            text-align: right;
          }
          &.FORMULA {
            text-align: right;
          }
          &.MONEY {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
            text-align: right;
          }
          &.YES_NO {
            width: 90px;
            min-width: 90px;
            text-align: center;
          }

          & > span {
            max-width: calc(100% - @offset-lg);
            display: inline-block;
          }
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
        .no-focus();

        & + tr {
          border-top: 1px solid rgb(245, 245, 245);
        }

        &:hover {
          background-color: @color-light-blue;

          .more-btn {
            background-color: @color-light-blue;
          }
        }

        &.readOnly {
          cursor: default;

          &:hover {
            background-color: transparent;
          }
        }

        &:first-child {
          border-top: 1px solid rgb(232, 232, 232);
        }

        &.error {
          border: 1px solid @color-red;
          border-left-width: 2px;
          border-top: 1px solid @color-red !important;

          & + .total-row {
            td {
              border-top: none;
            }
          }
        }

        &.total-row {
          cursor: default;
          background-color: rgb(245, 245, 245);
          border: 1px solid #f5f5f5;

          &:hover {
            background-color: rgb(245, 245, 245) !important;
          }

          td {
            height: 2*@offset-lg;
            border-left: none !important;
            border-top: 1px solid rgb(232, 232, 232);
            padding: 0 @offset-lg;
            line-height: 2*@offset-lg;
          }

          .content {
            .total-row-label {
              color: @color-secondary-copy;
              text-transform: capitalize;
            }
          }
        }

        .more-btn {
          background-color: @color-white;
        }

        .actions_wrapper {
          display: none;
          position: absolute;
          z-index: 1;
          left: 0;
          top: calc(50% - (@offset-lg + 3px));
          background: linear-gradient( 180deg, rgba(242,250,254,0.94902) 72%, rgba(242,250,254,0.47) 92%, rgba(242,250,254,0) 100%);
          width: 82px;
          font-size: 30px;
          border-radius: 20px;
          color: @color-blue;
          text-align: right;
          padding: 1px 10px 1px 0;
          transition: transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275) .15s;

          &.animate {
            transform: translateX(-80px);
          }
          &.show {
            display: block;
          }

          & > i {
            margin: 0;
            .no-focus();
          }
        }

        td {
          font-size: @font-size-sm;
          padding: @offset-lg;
          overflow-wrap: break-word;
          word-wrap: break-word;
          height: 40px;
          vertical-align: top;

          &.FILES_LIST:not(.error) {
            padding: @offset-md;
          }

          &.STRING_SINGLE_LINE,
          &.STRING_MULTI_LINE,
          &.EMAIL,
          &.FILES_LIST {
            width: 200px;
            max-width: 200px;
          }
          &.DATE_ONLY_VALUE {
            width: 120px;
            max-width: 120px;
          }
          &.DATE_AND_TIME,
          &.MULTI_SELECTOR,
          &.RADIO_SELECTOR {
            width: 150px;
            max-width: 150px;
          }
          &.NUMERIC_VALUE {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
            text-align: right;
          }
          &.FORMULA {
            text-align: right;
          }
          &.MONEY {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
            text-align: right;
          }
          &.YES_NO {
            width: 90px;
            min-width: 90px;
            text-align: center;
            text-transform: capitalize;
          }

          & + td {
            border-left: 1px solid rgb(245, 245, 245);
          }

          &.first {
            height: 100%;
            padding: @offset-lg @offset-xs;
            position: relative;
            text-align: center;
            color: @color-secondary-copy;
          }

          .content {
            color: @color-main-copy;
            word-wrap: break-word;

            .full-link {
              display: none;
            }

            & > .multiline-span {
              display: block;
              position: relative;

              &.invisible {
                visibility: hidden;
              }

              & > span {
                white-space: pre-wrap;
              }

              .more-btn {
                display: none;
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 0 @offset-xs 0 @offset-sm;

                i {
                  font-size: 5px;
                  transform: translateY(-2px);
                }
              }
              .less-btn {
                display: none;
              }

              &.short {
                overflow: hidden;
                height: 30px;

                .more-btn {
                  display: inline-block;
                }
              }
              &.full {
                .less-btn {
                  display: inline-block;
                }
              }
            }

            a {
              color: @color-blue;
            }

            .file {
              display: inline-flex;
              align-items: center;
              font-size: @font-size-sm;
              font-family: @font-family-semibold;
              padding: @offset-xs @offset-sm;
              border-radius: 8px;
              background-color: @color-light-grey;
              margin: 0 @offset-xs @offset-xs 0;
              max-width: calc(100% - (2 * @offset-md));
              color: @color-main-copy;
              .no-focus();

              .file-preview__link span {
                color: @color-main-copy;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              i {
                font-size: @font-size-md;
                margin-right: @offset-xs;
                color: @color-blue;
              }
            }
          }
        }
      }
    }
  }

  & > .empty-table-content {
    width: 100%;
    text-align: center;
    border-top: none;

    p {
      white-space: unset;
      font-size: @font-size-sm;
      padding: 60px @offset-md;
      overflow-wrap: break-word;
      word-wrap: break-word;
      color: @color-secondary-copy;

      a {
        color: @color-blue;
      }
    }
  }
}
