.prc-date-picker {
  position: relative;
  display: inline-table;
  margin-right: 2*@offset-md;
  flex: 1 0 auto;

  & > input {
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 0;
    left: 0;
  }

  .handler {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: @color-main-copy;
    font-size: @font-size-sm;
    transition: all .15s ease;
    cursor: default;
    position: relative;
    z-index: 2;
    .no-focus();

    &.tmplDeadline {
      border: 1px #cacaca solid;
      border-radius: 4px;
      height: 32px;
      padding: 6px 7px;

      & > .icon-task_due_date_add_small {
        margin-left: 0;
        margin-right: 6px;
      }
    }

    & > div {
      color: @color-main-copy;
    }

    & > span {
      display: inline-table;
      color: @color-secondary-copy;
    }

    .red {
      color: @color-red !important;
    }

    &.drop-enabled:not(.adHoc-handler) {
      .icon-task_due_date_add,
      .icon-task_due_date_add_small,
      span {
        color: @color-blue;
      }
    }

    &.date-alt-picker-drop-enabled:not(.adHoc-handler) {
      border-color: @color-blue;
      border-width: 2px;
    }

    &:not(.adHoc-handler):hover {
      .icon-task_due_date_add,
      .icon-task_due_date_add_small,
      span {
        color: @color-blue;
      }
    }

    .icon-task_due_date_add_small {
      font-size: 18px;
      color: @color-secondary-btn;
      margin-left: @offset-sm;
      transition: all .15s ease;
      cursor: pointer;

      &::before {
        margin: 0;
      }

    }

    .icon-task_due_date_add {
      font-size: 30px;
      color: @color-secondary-btn;
      margin-right: @offset-sm;
      transition: all .15s ease;
      cursor: pointer;
      min-width: 30px;

      &::before {
        margin: 0;
      }
    }

    .icon-common_remove {
      color: rgb(204,204,204);
      font-size: @font-size-md;
      margin-left: @offset-xs;
      cursor: pointer;
      .no-focus();

      &:hover {
        color: @color-secondary-copy;
      }
    }
  }

  &.date-time-picker {
    flex: 0 1 auto;
    min-width: 130px;

    .handler {
      padding: 6px 7px;
      flex: 1 0 auto;
      border: 1px #cacaca solid;
      border-radius: 4px;
      cursor: pointer;
      height: 32px;

      &.readOnly {
        color: #222;
        -webkit-text-fill-color: #222;
        background: #f5f5f5;
        border: none;
        font-family: @font-family-semibold;
        cursor: default;
        user-select: all;
      }

      &:hover:not(.readOnly) {
        .icon-task_due_date_add_small,
        span {
          color: @color-secondary-btn !important;
        }
      }
      &.drop-enabled:not(.adHoc-handler) span {
        color: @color-secondary-btn !important;
      }

      &.drop-enabled{
        border-color: @color-blue;
        border-width: 2px;

        .icon-task_due_date_add_small {
          color: @color-secondary-btn !important;
        }
      }

      .icon-task_due_date_add_small {
        margin-left: 0;
        margin-right: @offset-sm;
      }
    }
  }
}

.drop-element {
  &.drop-open {
    .prc-picker-content {
      display: block;

      &.date-time-picker-content {
        position: relative;
        display: block;
      }
    }
  }

  .prc-picker-content {
    position: absolute;
    left: -@offset-md;
    background: @color-white;
    padding: @offset-lg;
    .box-shadow();
    display: none;
    z-index: 10;
    margin-top: @offset-md;
    font-size: @font-size-md;
    max-width: 300px;
    max-height: 470px;
    .no-focus();

    & > div {
      .no-focus();
    }

    &.with-holidays {
      position: relative;
    }

	&.adHoc {
      left: -90px;
    }
    &.start {
      position: relative;
      left: 0;

      @media only screen and (max-width: @phone-portrait-size) {
        margin-left: -130px;
      }
    }

    &.noLeft {
      left: 0 !important;
    }

    &:not(.date-time-picker-content).empty {
      span.active {
        border: none;
        border-radius: 100%;
        background: transparent;
        color: inherit;

        &:hover {
          border: 1px solid @color-blue;
          border-radius: 100%;
          background: transparent;
          color: @color-blue;
          height: 36px;
          line-height: 35px;
        }
      }
    }

    &.task-date-picker-content {
      left: auto;
      right: -40px;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;

      li {
        display: block;
        padding: 5px @offset-sm;
        background: @color-light-grey;
        margin: @offset-xs;
        border-radius: 8px;
        cursor: pointer;
        transition: all .15s ease;
        .no-focus();

        &:hover {
          background: @color-grey;
        }
      }
    }

    .clear-date {
      margin-top: @offset-md;
      text-align: center;
    }

    table {
      border-collapse: separate;

      th {
        color: @color-main-copy;
        font-weight: normal;
        font-family: @font-family-semibold;
      }
      thead {
        tr:first-child {
          th:first-child,
          th:last-child {
            position: relative;
            .no-focus();

            &:hover {
              background: transparent;
            }

            &::after {
              position: absolute;
              font-family: "metatask-icons";
              font-style: normal;
              font-weight: normal;
              speak: none;
              display: inline-block;
              text-decoration: inherit;
              width: 1em;
              margin-right: .2em;
              text-align: center;
              font-variant: normal;
              text-transform: none;
              line-height: 1em;
              margin-left: .2em;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              background: @color-white;
              color: rgb(163,163,163);
            }
          }

          th:first-child {
            &::after {
              left: 5px;
              content: '\e81b';
              transform: rotate(180deg);
            }
          }
          th:last-child {
            &::after {
              right: 5px;
              content: '\e81b';
            }
          }
        }
        tr:last-child {
          th {
            padding-top: 8px;
            padding-bottom: 6px;
          }
        }
      }

      tbody {
        td, th {
          padding: 1px 0;
          position: relative;

          &.left {
            span.disabled-min,
            span.disabled-max,
            span.disabled-min:hover,
            span.disabled-max:hover {
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
            }
          }

          &.right {
            span.disabled-min,
            span.disabled-max,
            span.disabled-min:hover,
            span.disabled-max:hover {
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }

          span.disabled-min,
          span.disabled-max,
          span.disabled-min:hover,
          span.disabled-max:hover {
            background: @color-panel-grey !important;
            color: @color-grey !important;
            text-shadow: none !important;
            border: none !important;
            cursor: not-allowed !important;
            border-radius: 0 !important;
            height: 36px !important;
            line-height: 35px !important;
          }

          span.disabled-min:hover + .tooltip,
          span.disabled-max:hover + .tooltip {
            display: block;
          }

          .tooltip {
            display: none;
            white-space: nowrap;
            position: absolute;
            top: 50%;
            left: 95%;
            padding: 10px;
            color: @color-white;
            font-size: @font-size-sm;
            background-color: rgba(0,0,0,0.7);
            z-index: 10;
          }

          span,
          span.now {
            display: inline-block;
            height: 36px;
            width: 36px;
            line-height: 35px;
            background: transparent;
            border: 1px solid transparent;
            .no-focus();

            &:active {
              border-radius: 100%;
              background: @color-blue;
              color: @color-white;
              text-shadow: none;
            }
            &:hover,
            &.active {
              border-color: @color-blue;
              border-radius: 100%;
              background: transparent;
              color: @color-blue;
              text-shadow: none;
            }
            &.now {
              background: @color-light-grey;
              border-color: @color-dark-grey;
              color: @color-main-copy;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
}

