@import "../../../../less/variables";
@import "../../../../less/mixins";

.task-section {
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  @media only screen and (max-width: @tablet-portrait-size) {
    overflow-y: auto;

    &__header {
      padding: 0 @offset-lg 0 2*@offset-lg !important;
      .title {
        flex: 1 1;
        min-width: 0;
      }
      &__close{
        display: none !important;
      }
    }

    &__content {
      padding: 0 @offset-lg;
      height: calc(100% - 90px);
      position: relative;

      & > article {
        width: 100%;
        flex: 0 1 100%;
        max-width: 100%;
        margin-right: 0;

        .date-block {
          .comments-toggler {
            display: inline-block !important;
          }
        }

        & > main {
          height: auto;
        }
      }
      & > aside {
        flex: 1 0 100%;
        min-width: 100%;
        width: 100%;
        position: absolute;
        background-color: @color-white;
        left: 0;
        top: 0;
        z-index: 2;
        padding-right: @offset-md;
        min-height: 100%;

        .comments {
          min-height: calc(100% - 64px) !important;
        }

        &.ng-hide-add, &.ng-hide-remove {
          transition: all 0.15s ease;
        }
        &.ng-hide {
          transform: translate(100%, 0);
        }
      }
    }
  }
  @media only screen and (min-width: @phone-portrait-size) and (max-width: @phone-landscape-size) {
    &__header {
      flex-wrap: wrap;
      justify-content: normal;
      height: 115px;

      .btns {
        flex: 0 0 100%;
        margin-top: -50px;
        justify-content: space-between;
        padding-left: 33px;
      }
    }
  }
  @media only screen and (max-width: @phone-portrait-size) {
    &__header {
      height: 74px !important;

      .common_back {
        top: 10px;
      }

      .navigation > a {
        line-height: 24px !important;
      }

      .btns {
        min-width: 40px !important;
        & > span {
          display: none !important;
        }
      }
      .title {
        flex: 1 1;
        min-width: 0;
        padding-top: @offset-sm !important;

        .title-input {
          white-space: nowrap;

          & > textarea {
            display: none;
          }
          & > input {
            display: block;
          }
        }
      }
    }

    &__content {
      padding: 0 @offset-md 0 @offset-lg;
      min-height: calc(100% - 70px);

      &.inactive {
        & > article {
          & > header {
            & > div {
              &.date-block {
                padding-top: 0;

                & > .due-data {
                  top: 0;
                }
              }
            }
          }
        }
      }

      & > article {
        & > header {
          min-height: 75px;

          & > div {
            &.date-block {
              padding-top: 41px;
              position: relative;
              margin-left: 0;
              display: block;

              & > .due-data {
                position: absolute;
                right: 2*@offset-lg;
                top: 41px;
              }

              & > .popup-actors {
                display: inline-flex;
              }

              .comments-toggler {
                position: absolute;
                top: -@offset-xs;
                right: @offset-sm;
                z-index: 1;
              }
            }

            & > div {
              display: block;

              & + div {
                margin-right: 0;
              }

              & > ul {
                margin-top: @offset-lg;
                margin-left: 0;
                display: flex;

                & > .popup-actors {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 340px) {
    .unassign-button {
      display: none;
    }
  }
}
