@import "../../../../less/variables";
@import "../../../../less/mixins";

.template__section--single__process-details {
  padding: @offset-lg 2*@offset-md @page-screen-bottom-offset 2*@offset-lg;
  position: relative;
  min-height: 100%;
  border: 1px solid @color-pale-blue;
  line-height: normal;
  .no-focus();

  &__tabs {
    margin: 0 0 2*@offset-md 0;
    li + li {
      margin-left: @offset-md;
    }
  }

  .template-task-data-model__content__section {
    &.hidden {
      display: none;
    }

    &__fields__field {
      &.hidden {
        display: none;
      }

      &:first-child.hidden {
        display: block;
        visibility: hidden;
        height: 0;
        margin: @offset-md 0 0;
      }
    }
  }

  &.ng-hide-add, &.ng-hide-remove {
    transition: all linear 0.2s;
  }
  &.ng-hide {
    opacity: 0;
  }

  & > .content {
    width: 100%;
    @settings-label-width: 150px;
    & > div {

      & > div {
        display: flex;

        &.offset {
          & > label {
            padding-top: @offset-sm;
          }
        }
        &.execution {
          & > span {
            font-size: @font-size-sm;

            & > i:first-child {
              font-size: @font-size-md;
            }
          }
        }
        &.instructions {
          display: block;

          label {
            line-height: 21px;
          }
        }

        & > label {
          font-family: @font-family-medium;
          font-size: @font-size-sm;
          flex: 0 1 @settings-label-width;
          min-width: @settings-label-width;
          width: @settings-label-width;
        }
        & > .empty {
          font-size: @font-size-sm;

          .icon-task_due_date_add_small {
            font-size: @font-size-lg;
          }
        }

        & + div {
          margin-top: 2*@offset-md;
        }
      }

      & > .instructions {
        & > .empty {
          font-size: @font-size-sm;
          line-height: 21px;
        }
        [markdown-content] {
          margin-top: -8px;
          font-size: @font-size-sm;
        }
      }
    }
  }
}
