@import "../../less/variables";
@import "../../less/mixins";

.user-field {
  .single-select-header {
    min-width: 200px;
  }

  .icon {
    .thumbnail {
      height: 24px;
      width: 24px;
      min-width: 24px;
      line-height: 24px;
      border-radius: 8px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      position: relative;

      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.user-field-dropdown {
  .item-icon {
    .thumbnail {
      height: 24px;
      width: 24px;
      min-width: 24px;
      line-height: 24px;
      border-radius: 8px;
      text-align: center;
      color: #fff;
      position: relative;
      text-transform: uppercase;

      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
