.popup-actors-list {
  display: table;
  flex: 0 1 auto;
  visibility: hidden;
  .native-size;
  position: absolute;
  z-index: @z-index-content;
  right: -96px;
  top: @thumbnail-size + @font-size-icon + @offset-sm;
  align-items: center;
  background: @color-white;
  padding: @offset-sm;
  box-shadow: 0 2px 8px @color-secondary-copy;
  width: 210px;

  .container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    & > div {
      display: flex !important;
      align-items: center;
      color: @color-main-copy;

      & + div {
        margin-top: @offset-sm;
      }

      .text {
        color: @color-main-copy;
        margin-left: @offset-xs;
      }

      .avatar-small {
        background: @color-light-grey;
        color: @color-white;

        i {
          &.icon-assignee_process_owner,
          &.icon-assignee_group {
            color: @color-secondary-copy;
          }
        }
      }
    }
  }

  .arrow {
    background: @color-white;
    display: block;
    height: 2*@font-size-icon;
    width: 2*@font-size-icon;
    border: 1px solid @color-light-grey;
    border-top: none;
    border-left: none;
    transform: rotate(225deg);
    top: -@font-size-icon;
    right: 100px;
    position: absolute;
  }

  span {
    display: table;
  }
}