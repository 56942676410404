@import "../variables";
@import "../mixins";

.tmpl-gallery-modal {
  @categories-width: 240px;

  &.popup {
    .modal-header, .modal-header h3 {
      text-align: left;
    }
    .modal-dialog {
      position: fixed;
      top: 4*@offset-lg;
      left: 4%;
      width: 100%;
      max-width: 92%;
      height: calc(95% - (4 * @offset-lg));
      background: @color-white;
      margin: 0;
      padding: 0;
      overflow: auto;
      display: block;
      box-shadow: 0 0 8px @color-grey;
    }
  }

  p {
    white-space: normal;
  }

  .modal-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: @color-white;
    box-shadow: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    display: block;

    .modal-content {
      height: 100%;
      padding: 2*@offset-md;
    }
  }

  .modal-header {
    text-align: center;

    h3 {
      font-size: @font-size-h1;
      color: @color-main-copy;
    }

    img {
      margin-left: @offset-sm;
      margin-top: -3px;
      vertical-align: middle;
    }

    p {
      font-size: @font-size-sm;
      margin-top: @offset-xs;
      color: @color-secondary-copy;

      &.select {
        margin-left: @categories-width + 2*@offset-md;
        text-align: left;
        white-space: normal;
      }
    }
  }

  .modal-body {

    &.select {
      height: calc(100% - (4 * @offset-lg));
    }

    & > .gif {
      margin: 2*@offset-lg auto;
      text-align: center;
      max-height: 304px;
    }

    & > .animation-card {
      width: 224px;
      height: 290px;
      background-color: @color-panel-grey;
      border: 1px solid @color-light-grey;
      border-radius: 10px;
      margin: 2*@offset-lg auto;
      padding: 20px;

      & > .avatar {
        background-color: #f3c376;
        margin: 0 auto;
        color: @color-main-copy;
        font-size: 24px;

        i {
          margin-top: 2px;
        }
      }

      & > h4 {
        margin-top: @offset-lg;
        font-family: @font-family-medium;
        font-size: @font-size-md;
      }

      & > p {
        margin-top: @offset-sm;
        font-size: @font-size-sm;
        color: @color-secondary-copy;
        line-height: @font-size-sm;
      }

      & > ul {
        padding: 0;
        margin: 2*@offset-md 0 0;
        width: 100%;

        & > li {
          display: flex;
          width: 100%;
          padding: @offset-md;
          align-items: center;
          background-color: @color-white;
          border-radius: 6px;
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
          height: 40px;
          overflow: hidden;
          font-size: @font-size-sm;

          & > .fake-checkbox {
            height: 18px;
            width: 18px;
            display: block;
            border: 2px solid #cacaca;
            border-radius: 4px;
            margin-right: @offset-sm;
          }

          & + li {
            margin-top: 2px;
          }

          &:last-child {
            filter: blur(2px);
            margin-left: 50%;
            margin-top: 3px;
          }
        }
      }
    }

    & > .tmpl-gallery {
      padding: @offset-md 0;
      display: flex;
      justify-content: space-between;
      height: 100%;

      & > .categories {
        width: @categories-width;
        flex: 0 1 @categories-width;
        max-height: 100%;
        margin: 0;
        padding: 0;

        & > li {
          cursor: pointer;
          height: 62px;
          padding: 0 4*@offset-md 0 2*@offset-md;
          position: relative;
          border: 1px solid @color-pale-blue;
          display: flex;
          align-items: center;
          .no-focus();

          &.active {
            border-color: @color-blue;

            .square {
              visibility: visible;
            }
          }

          .counter {
            position: absolute;
            right: 20px;
            top: 18px;
            color: @color-white;
            background-color: @color-green;
            border-radius: 100%;
            font-size: @font-size-sm;
            font-family: @font-family-bold;
            height: 2*@offset-md;
            width: 2*@offset-md;
            text-align: center;
            line-height: 2*@offset-md;
          }

          .square {
            height: 15px;
            width: 15px;
            right: -8px;
            z-index: 100;
            top: 50%;
            margin-top: -8px;
            transform: rotate(45deg);
            background-color: @color-white;
            position: absolute;
            border-right: 1px solid @color-blue;
            border-top: 1px solid @color-blue;
            cursor: pointer;
            visibility: hidden;
          }

          & + li:not(.active) {
            border-top: none;
          }
        }
      }

      & > .templates {
        width: calc(100% - (@categories-width + @offset-lg));
        flex: 0 1 calc(100% - (@categories-width + @offset-lg));
        height: 100%;
        position: relative;

        .tmpl-category {
          margin-top: @offset-sm;
          & > h5 {
            color: @color-main-copy;
            font-size: @font-size-lg;
            margin-bottom: @offset-lg;
          }
          & > article {
            margin-left: 0;
          }
        }

        & > div {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }

        & > footer {
          width: 100%;
          padding-left: @offset-sm;
          padding-top: @offset-md;
          display: flex;
          align-items: center;

          button + button {
            margin-left: @offset-md;
          }

          p {
            margin: 0 0 0 @offset-lg;
            color: @color-dark-grey;
          }
        }

        article {
          background-color: @color-panel-grey;
          position: relative;
          height: @templates-start-height;
          width: @templates-start-width;
          padding: 2*@offset-md;
          margin: 0 @offset-sm @offset-lg;
          border-radius: 9px;
          cursor: pointer;
          border: 1px solid @color-light-grey;
          .no-focus();

          &:hover {
            background-color: @color-light-grey;
          }

          &.selected {
            border: 2px solid @color-green;

            & > .selected {
              display: block;
            }
          }

          h4 {
            color: @color-main-copy;
            font-size: @font-size-md;
            font-family: @font-family-medium;
            margin-top: @offset-lg;
            word-wrap: break-word;
            overflow: hidden;
            display: block;
            max-height: 2*@font-size-md * 1.2;
            width: 100%;
          }

          p {
            color: @color-main-copy;
            font-size: @font-size-sm;
            margin-top: @offset-lg;
            line-height: 15px;
            display: block;
            max-height: 60px;
            word-wrap: break-word;
            overflow: hidden;
            width: 100%;
          }

          .avatar {
            margin: 0 auto;
            color: @color-asphalt-grey;
            font-size: @thumbnail-big-size - 10px;

            i {
              display: block;
              line-height: @thumbnail-big-size;
            }
            &:not(.default) {
              line-height: 44px;
            }

            &.default {
              background-color: @color-grey;
            }
          }

          footer {
            position: absolute;
            color: @color-secondary-copy;
            font-size: @font-size-sm;
            bottom: 2*@offset-md;
            left: 2*@offset-md;
          }

          & > .selected {
            position: absolute;
            right: @offset-md;
            bottom: @offset-md;
            height: 32px;
            width: 32px;
            border-radius: 100%;
            background-color: @color-green;
            text-align: center;
            line-height: 30px;
            display: none;

            i {
              color: @color-white;
              font-size: @font-size-xs;
            }
          }
        }
      }
    }
  }

  .modal-footer {
    display: block;
    text-align: center;

    & > p {
      color: #9b9b9b;
      font-size: @font-size-md;
      line-height: @font-size-md;

      & + .btn {
        margin-top: 2*@offset-lg;
      }
    }

    .btn {
      height: 40px;
      border-radius: 20px;
      min-width: 100px;
    }
  }

  @media only screen and (max-width: @phone-landscape-size) {
    .modal-dialog {
      top: @offset-sm !important;
      bottom: @offset-sm !important;
      height: auto !important;
    }
  }

  @media only screen and (max-width: @phone-portrait-size) {
    .modal-dialog {
      .modal-body {
        max-height: calc(100% - 90px);
        .tmpl-gallery {
          & > .categories {
            display: none;
          }
          & > .templates {
            width: 100%;
            flex: 100%;
          }
        }
      }
    }
  }
}
