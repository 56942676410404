@import "../../../less/variables";
@import "../../../less/mixins";

.print-only {
  display: none;
}

@media print {
  .print-only {
    display: block;
  }
  .print-section {
    page-break-after: always;

    .print-section-title {
      font-size: 18px;
      line-height: 30px;
        padding-bottom: 10px;
    }
  }

  .help-tooltip {
      display: none;
  }

  .site-header, .notification-bar, .phone-screen-navigation {
    display: none !important;
  }
  .main-wrapper {
    height: 100% !important;
  }
  .process-view-data-model, .task-data-model {
    &__section, section {
      & > header {
        .main-info {
          i {
            display: none;
          }
          span {
            color: @color-main-copy;
            font-size: @font-size-lg;
          }
        }
        .actions-info {
          display: none;
        }
      }
      label i {
        display: none;
      }
      &__fields__field, main {
        margin: 7px 0;
        & > .form-group {
          margin: 7px 0;
        }
        .hint-messages, .error-hint {
          display: none;
        }
        .yesno-data-field, .yesno-data-field.disabled {
          .error {
            display: none;
          }
          ul {
            li {
              border: none !important;
              box-shadow: none !important;
              background: transparent !important;
              width: auto !important;
              padding: 8px 7px;

              & > .helper {
                display: none !important;
              }

              &:not(.active) {
                display: none !important;
              }
            }
          }
        }
        .email-data-field, .email-data-field--disabled {
          background: transparent;

          & > input, a {
            padding-left: 0 !important;
          }

          & > i {
            display: none !important;
          }
        }
        .multiselect-field {
          padding: 0 7px;
          flex-direction: row;

          & > label {
            margin-top: 0 !important;
            &:after {
              content: ',';
              margin-left: 2px;
            }
            &:last-child {
              &:after {
                opacity: 0;
              }
            }

            &:not(.checked) {
              display: none;
            }
          }
        }
        .price-data-field {
          border: none !important;
          padding-top: 6px;
          &.empty {
            display: none !important;
          }
          & > span {
            display: inline;
            padding-left: 7px;
            white-space: nowrap;
          }
          input {
            display: none !important;
          }
          .unit {
            background: none !important;
            border: none !important;
            flex: 0 1 100% !important;
            .handler {
              min-height: 0 !important;
            }
            i {
              display: none;
            }
          }
        }
        .errors-messages {
          display: none;
        }
        .content-wrapper, .td-content-wrapper, .files-wrapper {
          max-width: calc(100% - 180px) !important;
          width: calc(100% - 180px) !important;
          flex: 0 1 calc(100% - 180px) !important;

          & > textarea {
            display: none;
          }
          .textarea-content-wrapper, .textarea-content-wrapper.hidden {
            display: block;
          }
        }
        textarea {
          height: auto;
          display: table;
          width: 100%;
          overflow: hidden;
        }
        input, textarea, .tether-handler {
          background: none !important;
          border: none !important;

          span {
            color: @color-main-copy;
          }

          i {
            display: none;
          }
        }
        .single-select .single-select-header {
          background: none !important;

          .icon,
          .badge-deleted {
            display: none !important;
          }
          .control {
            left: 8px !important;
          }
         }
        .checkbox > span {
          display: none;
        }
        .prc-date-picker.date-time-picker .handler {
          background: none;
          border: none;
          div {
            color: @color-main-copy;
          }
          i {
            display: none;
          }
        }
        .textarea-content-wrapper {
          height: auto !important;
          min-height: 2*@offset-lg !important;
          background: none !important;

          .rg-bottom {
            display: none !important;
          }

          .textarea-content {
            overflow-y: visible !important;

            .link {
              display: none;
            }
            .full-link {
              display: inline !important;
            }
          }
        }
        .file-data-field {
          .files-error {
            display: none;
          }
          .files-wrapper, .files-block {
            background: none !important;
            border: none !important;

            &.empty {
              display: none !important;
            }

            .file {
              background: none !important;
              & > a {
                width: 100% !important;
              }
              span {
                max-width: 100% !important;
              }

              i {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
  .data-model-table {
    & > .empty-table-content {
      p a {
        display: none !important;
      }
    }
    & > table {
      thead tr {
        th {
          i {
            display: none !important;
          }
        }
      }
      tbody tr {
        &.total-row {
          background: none !important;
        }
        .actions_wrapper {
          display: none !important;
        }
        td .content {
          .multiline-span {
            height: auto !important;
            overflow: visible;
            .link {
              display: none;
            }
            .full-link {
              display: inline !important;
            }

            & > .more-btn {
              display: none !important;
            }
          }
          .files {
            .file {
              background: none !important;
              max-width: 100% !important;
            }
          }
        }
      }
    }
  }

  //.process {
  //  &__header {
  //    padding: 0 @offset-lg !important;
  //    &__icon {
  //      display: none;
  //    }
  //    &__main {
  //      &__btns {
  //        display: none !important;
  //      }
  //      &__content {
  //        display: flex;
  //        align-items: flex-start;
  //        flex-direction: column-reverse;
  //        justify-content: center;
  //
  //        & > span {
  //          color: @color-grey !important;
  //          & > a {
  //            border-bottom: none !important;
  //            color: @color-grey !important;
  //          }
  //        }
  //      }
  //    }
  //    .icon-common_close, .common_back {
  //      display: none !important;
  //    }
  //  }
  //  &__section {
  //    padding: 0 @offset-lg !important;
  //    height: auto !important;
  //
  //    .info {
  //      display: none !important;
  //    }
  //    main {
  //      flex: 0 1 100% !important;
  //      padding-right: 0 !important;
  //    }
  //  }
  //}
  .task-section {
    overflow: visible !important;
    &__header {
      padding: 0 @offset-lg !important;

      & > .title {
        flex: 0 1 100% !important;
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        padding-top: 0 !important;

        & > a {
          margin-top: @offset-xs;
        }
      }
      .navigation, .common_back, .btns, .icon-common_close {
        display: none !important;
      }
    }
    &__content {
      padding: 0 @offset-lg !important;
      & > aside {
        display: none !important;
      }
      & > article {
        width: 100% !important;
        flex: 0 1 100% !important;
        margin-right: 0 !important;

        .data-model-errors {
          display: none !important;
        }

        & > header {
          .prc-date-picker {
            display: none !important;
          }

          & > div {
            & > div {
              & > .main {
                .avatar-small {
                  display: none !important;
                }
              }
              & > ul {
                display: none !important;
              }
            }
          }
        }

        & > main {
          overflow: visible !important;

          .data-model-errors {
            display: none !important;
          }

          & > .task-description-wrapper {
            .hint {
              display: none;
            }
          }
          markdown-hint {
            display: none;
          }
        }
      }
    }
  }
  body:not(.modal-open) > div[ui-view] {
    overflow: visible;
  }
}
