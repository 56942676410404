@import "../../../less/variables";
@import "../../../less/mixins";

.process-task-list-due-date{
  & > div > span.formatted-date.hasPermission:hover{
    color: @color-blue;
    cursor: pointer;
    transition: all .15s ease;
  }
  & > div > span.red{
    color: @color-red;
  }
  .no-focus();
  .icon-task_due_date_add_small:hover{
    color: @color-blue;
    cursor: pointer;
    transition: all .15s ease;
  }

  & > .date-string, & > span.date-icon {
    margin-left: @offset-md !important;
  }
}
