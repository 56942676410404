@import "../../../less/variables";
@import "../../../less/mixins";

.process-task-tooltip {
  display: table;
  flex: 0 1 auto;
  visibility: hidden;
  .native-size;
  position: absolute;
  z-index: @z-index-content;
  top: 20px;
  align-items: center;
  background: @color-white;
  padding: @offset-sm;
  .box-shadow();

  &__container {
    display: flex;
    align-items: center;

    .avatar-small.group {
      color: #a3a3a3;
      border: 1px dashed #9d9d9d;

      i {
        font-size: @font-size-md;
        margin-left: 2px;
        margin-top: -1px;
        display: block;
      }
    }

    & > div {
      display: inline-block;

      & > span {
        display: table;
        font-family: @font-family-semibold;
        white-space: nowrap;
        margin-left: 6px;

        &.name {
          font-size: @font-size-sm;
          color: @color-main-copy;
        }

        &.info-line {
          font-size: @font-size-xs;
          color: @color-add-grey;

          i {
            font-style: normal;
            color: @color-main-copy;
          }

          .red {
            color: @color-red;
          }
        }
      }
    }
  }

  .arrow {
    background: @color-white;
    display: block;
    height: 12px;
    width: 12px;
    border: 1px solid @color-light-grey;
    border-top: none;
    border-left: none;
    transform: rotate(225deg);
    left: @offset-md;
    top: -6px;
    position: absolute;
  }
}
