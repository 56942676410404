@import "../../../less/variables";
@import "../../../less/mixins";

.admin-billing {
  width: 100%;
  padding: 45px;
  border: 1px solid @color-pale-blue;
  height: 100%;
  max-height: calc(100% - (4 * @offset-lg));
  overflow: auto;

  & > .form-hint {
    margin: -@offset-md 0 2*@offset-md;
  }

  &__loader {
    .loading-spinner {
      justify-content: center;
      font-size: @font-size-xlg;
      height: 24px;

      i {
        margin-right: @offset-md;
        display: inline-block;
        font-size: 24px;
        height: 24px;
        animation: rotate .3s linear infinite;
      }
    }
  }

  &__trial {
    text-align: center;
    padding: 45px 0;
    .btn-cancel-account {
        display: block;
        margin: 24px auto 0;
    }


    &.warn {
      .round-progress {
        path {
          stroke: @color-red !important;
        }
      }
    }

    p {
      margin-top: 2*@offset-md;

      &.hint {
        color: @color-secondary-copy;
        margin-top: @offset-sm;
        font-size: @font-size-sm;
      }
    }

    & > .form-hint {
      margin: @offset-lg auto -@offset-sm;
      max-width: 360px;

      & > h5 {
        font-family: @font-family-medium;
        color: @color-main-copy;
        font-size: @font-size-md;
      }
      & > p {
        font-size: @font-size-sm;
        margin-top: @offset-sm;

        a {
          text-decoration: underline;
          color: @color-main-copy;
        }
      }
    }

    & > button {
      margin-top: 2*@offset-md;
    }
  }
  &__paid {
    &__header {
      display: flex;

      &__left, &__right {
        width: 50%;
        flex: 0 1 50%;
        display: inline-flex;
        font-size: @font-size-md;

        .error-hint {
          background-color: #f9d83c;
          border-radius: 6px;
          padding: @offset-md;
          margin: @offset-md 0 0;

          & > h5 {
            font-family: @font-family-medium;
            color: @color-main-copy;
            font-size: @font-size-md;
          }
          & > p {
            font-size: @font-size-sm;
            margin-top: @offset-sm;

            a {
              text-decoration: underline;
              color: @color-main-copy;
            }
          }
        }

        & > div {
          margin-left: 2*@offset-md;

          & > button {
            margin-top: @offset-lg;
          }

          &.notSet {
            ul > li {
              color: @color-grey;
              & > span {
                color: @color-secondary-copy;
              }
            }
          }

          ul {
            .native-size;
            list-style: none;

            li {
              color: @color-secondary-copy;

              & + li {
                margin-top: @offset-sm;
              }

              &.error {
                color: @color-red;
              }

              & > span.field-label {
                color: @color-main-copy;
                display: inline-block;
                width: 100px;
              }
            }
          }
        }
      }
    }
    &__payments {
      .empty-state {
        text-align: center;
        margin-top: 3*@offset-md;

        & > i {
          font-size: 50px;
          color: rgb(209, 209, 209)
        }
        & > h5 {
          font-size: @font-size-md;
          margin-top: 2*@offset-md;
          color: @color-main-copy;
        }
        & > p {
          color: @color-add-grey;
          font-size: @font-size-sm;
          margin: @offset-sm auto 0;
          max-width: 300px;
        }
      }

      .payments-list {
        margin-top: 2*@offset-lg;

        & > h3 {
          text-align: center;
          font-size: @font-size-lg;
        }

        &__table {
          border: 1px solid @color-light-grey;
          margin-top: 2*@offset-md;

          span {
            display: block;
            font-size: @font-size-sm;

            i {
              color: @color-secondary-copy;
              font-style: normal;
              width: @offset-lg;
              display: inline-block;
            }
            a {
              color: @color-blue;
              white-space: nowrap;
            }

            &:first-child {
              width: 160px;
              flex: 0 1 160px;
            }
            &:nth-child(2) {
              width: 125px;
              flex: 0 1 125px;
            }
            &:nth-child(2) {
              width: 125px;
              flex: 0 1 125px;
            }
            &:nth-child(3) {
              width: 90px;
              flex: 0 1 90px;
            }
            &:nth-child(4) {
              &.isNotYetPaid{
                color: #f75f5f;
              }
              width: 80px;
              flex: 0 1 80px;
            }

          }
          header {
            height: 40px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid @color-light-grey;

            span {
              font-family: @font-family-medium;
              &:first-child {
                width: 176px;
                flex: 0 1 176px;
                padding-left: @offset-lg * 2;
              }
            }
          }
          &__row {
            display: flex;
            align-items: center;
            height: 42px;
            margin: 0 @offset-lg;

            & + .payments-list__table__row {
              border-top: 1px solid @color-panel-grey;
            }

          }
        }
      }
    }
  }

  @media only screen and (max-width: @phone-landscape-size) {
    padding: 2*@offset-md;
    .admin-billing__paid {
      &__header {
        &__right, &__left {
          & > img {
            display: none;
          }
        }
      }
      &__payments {
        .empty-state {
          & > i {
            display: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width: @phone-portrait-size) {
    .admin-billing__paid {
      &__header {
        display: block;

        &__right, &__left {
          width: 100%;

          & > img {
            display: inline-block;
          }
        }
        &__right {
          margin-top: 2*@offset-lg;

          &.early-users {
            display: none;
          }
        }
      }
      &__payments {
        .payments-list {
          &__table {
            border-left: none;
            border-right: none;

            & > header {
              display: none;
            }
            &__row {
              & > span:nth-child(2) {
                display: none;
              }
              & > span:nth-child(3) {
                padding-left: @offset-sm;
                width: 60px;
              }
              & > span:nth-child(4) {
                display: none;
              }
            }
          }
        }
        .empty-state {
          & > i {
            display: none;
          }
        }
      }
    }
  }
}

.chargbee-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);

  #chargbee-container {
    text-align: center;
    margin: 2*@offset-md auto;
    padding: @offset-lg @offset-lg @offset-lg 270px;
    overflow-y: auto;
    overflow-x: hidden;
    background: @color-white;
    max-height: calc(100% - (4 * @offset-md));
    width: 540px;
    border-radius: 6px;
    -webkit-overflow-scrolling: touch;

    iframe {
      border: none;
      border-radius: 4px;
      width: 768px;
      transform: translateX(-50%);
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
    }

    @media only screen and (max-width: @phone-landscape-size) {
      width: 340px;
      padding-left: 170px;
      iframe {
        width: 300px;
      }
    }
  }
}

.strike {
  color: @black-color;
  text-decoration: line-through;

  & > span {
    color: @color-secondary-copy;
  }
}
