@import "../../../../less/variables";
@import "../../../../less/mixins";

.task-section {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.task-section__header {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: @task-section-header-size;
  background-color: @color-white;
  padding: 0 4*@offset-lg 0 @site-padding;
  position: relative;
  border-bottom: 1px solid @color-pale-blue;

  & > .icon-common_close {
    color: #cdcdcd;
    font-size: @font-size-md;
    position: absolute;
    top: @offset-md;
    right: 18px;
    cursor: pointer;

    &:hover {
      color: @color-secondary-copy;
    }
  }

  & > .common_back {
    left: 0;
    font-size: 40px;
    top: 20px;
    width: 20px;
    display: inline-block;
    color: #cdcdcd;
    position: absolute;
    right: 18px;
    cursor: pointer;
    .no-focus();

    & > i {
      margin: 0;
    }

    &:hover {
      color: @color-blue;
    }
  }

  .navigation {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: @checkbox-size;
    margin-right: @offset-md;

    &.approval > a {
      line-height: 32px;
    }


    & > a {
      color: @color-light-grey;
      font-size: @font-size-xs;
      display: block;
      width: 20px;
      line-height: 35px;
      cursor: pointer;

      &[disabled] {
        user-select: none;
        cursor: default;
        opacity: 0;

        &:hover {
          color: @color-light-grey;
        }
      }

      &:hover {
        color: @color-secondary-copy;
      }
    }

    span {
      display: block;
      background: transparent;
    }
  }

  .title {
    flex: 1 1;
    min-width: 0;
    padding-top: 13px;

    & > a, & > span {
      color: @color-secondary-copy;
      font-size: @font-size-sm;
      display: inline-block;
      min-height: @font-size-md;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;

      &.hidden {
        visibility: hidden;
      }
    }

    & > .title-input {
      position: relative;

      &.editable {
        h1 {
          padding: 1px 4px 0 4px;
          margin-left: -4px;
          font-family: @font-family-regular;

          &:focus {
            outline: none;
          }
        }
      }

      &.editable:hover {
        h1 {
          border: 1px @input-border-color solid;
          border-radius: 4px;
          cursor: pointer;
          padding: 0 3px 0 3px;
        }
      }

      h1 {
        color: @color-main-copy;
        font-family: @font-family-semibold;
        font-weight: normal;
        font-size: 21px;
        max-height: 52px;
        overflow: hidden;
        text-overflow: ellipsis;
        .no-focus();
      }

      & > textarea {
        color: @color-main-copy;
        font-family: @font-family-semibold;
        font-weight: normal;
        font-size: 21px;
        max-height: 52px;
        overflow: hidden;
        resize: none;
        position: absolute;
        top: 1px;
        left: -4px;
        background: transparent;
        padding: 0 @offset-xs;
        width: 100%;
        outline: none;
        border: none;
        cursor: text;
      }

      & > input {
        color: @color-main-copy;
        font-family: @font-family-semibold;
        font-weight: normal;
        font-size: 21px;
        max-height: 24px;
        overflow: hidden;
        resize: none;
        position: absolute;
        top: 2px;
        left: -4px;
        background: transparent;
        padding: 0 @offset-xs;
        width: 100%;
        outline: none;
        border: none;
        cursor: text;
        display: none;
      }
    }
  }

  .btns {
    flex: 0 1 auto;
    min-width: 160px;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-end;
    display: inline-flex;
    position: relative;
    padding-left: @offset-sm;

    & > span {
      .btn + .btn {
        display: none;
      }
      & > .btn.ng-leave, & > .btns-approve-reject.ng-leave > .btn{
        transition: none;
      }

      &:not(.completed) {
        .btn-simple-reopen {
          display: none !important;
        }
      }

      &.completed {
        .btn-simple-complete {
          display: none !important;
        }
      }
    }
    .btns-approve-reject {
      display: flex;

      .btn.btn-approval {
        display: flex !important;
        align-items: center;
        padding: 0 @offset-md 0 0;
        cursor: pointer;

        & > .addon {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          margin: 0 @offset-sm;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: @color-white;
          i {
            font-size: 8px;
          }

          .spinner {
            display: none;
            font-size: 15px;
            animation: rotate .3s linear infinite;
          }

          &.animated {
            background: transparent;
            i {
              display: none;
            }
            .spinner {
              display: block;
            }
          }
        }

        &.btn-approve {
          background-color: #50BE41 !important;
          color: @color-white !important;
          margin-right: @offset-sm;
          i {
            color: #50BE41 !important;
          }
        }
        &.btn-reject {
          background-color: @color-red !important;
          color: @color-white !important;
          i {
            color: @color-red !important;
          }
        }
        &.disabled {
          cursor: not-allowed;
          background: @color-light-grey !important;
          color: @color-secondary-copy !important;
          i {
            color: @color-secondary-copy !important;
          }
        }
      }
    }

    .review-force-actions {
      .dropdown {

        .dropdown-toggle {
          i {
            font-size: 5px;
            line-height: 2*@offset-lg;
            vertical-align: middle;
          }
        }

        .dropdown-menu {
          background: @color-white;
          border: 1px @input-border-color solid;
          box-shadow: 0 1px 6px rgba(0,0,0,0.15);

          position: absolute;
          z-index: 10;
          right: 0;
          margin-top: 4px;
          min-width: 140px;

          a {
            font-size: @font-size-md;
            color: @color-main-copy;
            padding: @offset-md;
            display: flex;
            align-items: center;
            white-space: nowrap;

            &:hover {
              color: @color-blue;
            }
          }
        }
      }
    }

    .action {
      font-size: 32px;
      flex: 1 0 32px;
      max-width: 32px;
      border: none;
      color: #cdcdcd;
      padding: 0;
      background: transparent;
      margin-left: @offset-md;
      .no-focus();

      & > i {
        margin: 0;
      }

      &[disabled='disabled'] {
        color: @color-light-grey !important;

        &:hover {
          color: @color-light-grey !important;
        }
        cursor: not-allowed;
      }

      &:hover {
        color: @color-blue;
      }
    }

    & > span {
      position: relative;

      &.behalf {
        display: inline-table;

        .btn-green {
          padding-right: 30px;
        }
      }

      &.with-review-actions {
        .btns-approve-reject {
          .btn-green {
            padding-right: 30px;
          }
        }
      }
    }

    .btn:not(.btn-green):not(.btn-icon) {
      font-size: @font-size-md;
      color: @site-menu-color;
      text-decoration: none;
      line-height: 30px;
      position: relative;
      border: 1px @color-secondary-btn solid;
      display: inline-block;
      cursor: pointer;
      background-color: transparent;
      border-radius: @buttons-border-radius;
      transition: all .2s ease;
      white-space: nowrap;
      .no-focus();

      &.ellipsis {
        i {
          font-size: 18px;
        }
      }
      &.disabled {
        cursor: not-allowed;
      }

      &[disabled='disabled'] {
        opacity: 0.4;
        cursor: not-allowed !important;
        border-color: #cacaca !important;

        &:hover {
          color: inherit;
          border-color: inherit;
          opacity: 0.4;
        }
      }

      &:not(:disabled):not(.disabled):not(:active):hover {
        color: @color-blue;
        border-color: @color-blue;

        & > i {
          color: @color-blue;
        }
      }
    }
  }
}
