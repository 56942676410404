@import "../../../less/variables";
@import "../../../less/mixins";

.actors-selector {
  &__control {
    color: @color-main-copy;
    font-size: @font-size-sm;
    border: 1px transparent solid;
    border-radius: 6px;
    min-height: 34px !important;
    cursor: pointer !important;
    .no-focus();
  }

  &__indicators {
    align-items: flex-start;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    opacity: 0;
    padding: 0 !important;

    i {
      font-size: 4px;
      margin-right: 8px;
      color: @color-main-copy;
    }
  }

  &__value-container {
    padding: 2px !important;
  }

  &__single-value {
    font-size: 12px !important;
    line-height: 24px;
    padding: 0 0 0 8px !important;
    display: inline-flex;
  }

  &__multi-value {
    background-color: transparent;
    cursor: pointer;
    padding: 0 !important;
    border-radius: 6px !important;

    &__label {
      font-size: 12px !important;
      line-height: 24px;
      padding: 0 0 0 8px !important;
      display: inline-flex;
    }

    &__remove {
      color: #a3a3a3;

      &:hover {
        background-color: transparent !important;
        color: #222 !important
      }
    }
  }

  &__multi-value__remove {
    visibility: hidden;
  }

  &__single-value,
  &__multi-value {
    .value-icn {
      line-height: 24px;
      width: 24px;
      padding-right: 0;
      margin-left: -8px;
      margin-right: 5px;
      font-size: 12px;
      border-radius: 8px;
      color: #40484c;
      text-align: center;
      -ms-flex: 0 0 24px;
      flex: 0 0 24px;
      display: inline-block;
      overflow: hidden;
      background-color: #e8e8e8;
    }
  }

  &__placeholder {
    margin-left: 6px !important;
  }

  &__input {
    margin-left: 4px;
  }

  &__menu {
    border-radius: 0 !important;
    margin-top: 2px !important;
    z-index: 1050 !important;
  }

  &__menu-list {
    padding: 10px !important;
  }

  &__option {
    font-size: 12px !important;
    padding: 0 !important;
    white-space: nowrap;
    height: 24px;
    cursor: pointer !important;

    & > span {
      display: inline-flex;
      padding-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      line-height: 24px;
    }

    .group-item {
      margin-left: 24px;
    }

    .expand-icn {
      color: @color-add-grey;
    }

    .spacer {
      width: 8px;
      display: inline-block;
    }

    .option-icn {
      line-height: 24px;
      width: 24px;
      padding-right: 0;
      margin-left: 0;
      margin-right: 5px;
      font-size: 12px;
      border-radius: 8px;
      color: #40484c;
      text-align: center;
      flex: 0 0 24px;
      display: inline-block;
      overflow: hidden;
      background-color: #e8e8e8;
    }

    &--is-focused,
    &--is-selected {
      background-color: transparent !important;
      color: @color-main-copy !important;

      & > .option-label {
        background-color: #e8e8e8;
        border-radius: 6px !important;
      }
    }
  }

  &__option + &__option {
    margin-top: 8px;
  }

  &__menu-notice {
    font-size: 12px;
    line-height: 24px;
    height: 24px;
    padding: 0 !important;
  }

  &__control--is-focused {
    border-color: @color-blue !important;
    box-shadow: 0 0 0 1px @color-blue !important;

    .actors-selector__dropdown-indicator {
      opacity: 1;
    }

    .actors-selector__multi-value {
      background-color: #e8e8e8;
    }

    .actors-selector__multi-value__remove {
      visibility: visible;
    }
  }

  &__control:hover {
    .actors-selector__dropdown-indicator {
      opacity: 1 !important;
    }
  }
}

.value-icn,
.option-icn {
  .thumbnail {
    width: 24px;
    height: 24px;
    background-color: #e8e8e8;

    span.initials {
      color: #fff;
      text-transform: uppercase;
    }

    span.special-icon {
      color: #a3a3a3;
    }

    img {
      width: 100%;
    }
  }
}
