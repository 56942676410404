@import "../../../less/variables";
@import "../../../less/mixins";

.templates__header {
  .inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 @site-padding;
  height: 4*@offset-lg;
  font-family: @font-family-medium;
  position: relative;

  & > div {
    display: inline-flex;
    align-items: center;

    h3 {
      font-weight: normal;
      color: @site-menu-color-hov;
      font-size: @font-text-size;

      & + a {
        margin-left: 2*@offset-md;
      }
    }

    a {
      & + a {
        margin-left: @offset-md;
      }

      i {
        margin-left: 0;
      }

      .short {
        display: none;
      }
    }
  }

  .search-block {
    position: relative;
    margin-left: 8px;
    transition: all .15s ease;

    & > i {
      position: absolute;
      color: @color-secondary-copy;
      cursor: pointer;
      .no-focus();

      &.icon-header_search {
        left: 6px;
        margin-top: -1px;
        font-size: @font-size-lg;
        top: 50%;
        transform: translateY(-50%);
        transition: all .15s ease;
      }

      &.icon-common_remove {
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
      }
    }

    & > input {
      position: relative;
      z-index: 1;
      transition: all .15s ease;
      opacity: 1;
      padding: 8px 30px;
      appearance: none;
      flex: 0 1 100%;
      width: 160px;
      border: 1px @color-secondary-btn solid;
      border-radius: 4px;
      box-shadow: none !important;
      color: @color-main-copy;
      resize: none;
      outline: 0;
      cursor: pointer;
      background: transparent;

      &::-ms-clear {
        display: none;
      }

      &:focus, &.active {
        outline: none;
        border-color: @color-blue;
        border-width: 2px;
        cursor: text;

        & + .icon-header_search {
          color: @color-main-copy;
        }
      }
    }
  }

  &.searching {
    .search-block {
      input {
        width: 320px;
      }
    }
  }
}
