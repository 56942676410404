@import "../../../less/variables";
@import "../../../less/mixins";

.admin__main__calendar {
  @calendar-label-width: 180px;

  width: 100%;
  height: 100%;
  padding: 2*@offset-md 4*@offset-md 0 4*@offset-md;
  border: 1px solid @color-pale-blue;
  max-height: calc(100% - (4 * @offset-lg));
  overflow: auto;
  .no-focus();

  &__loader {
    .loading-spinner {
      justify-content: center;
      font-size: @font-size-xlg;
      height: 24px;

      i {
        margin-right: @offset-md;
        display: inline-block;
        font-size: 24px;
        height: 24px;
        animation: rotate .3s linear infinite;
      }
    }
  }

  & > .form-group {
    &.m-start {
      & > label {
        margin-top: 2px;
      }
    }

    &.holidays .years-list {
      margin-bottom: 100px;
    }

    & > label {
      width: @calendar-label-width;
      min-width: @calendar-label-width;
      flex: 0 1 @calendar-label-width;
      font-family: @font-family-medium;
    }

    & > input {
      width: 100%;
      flex: 0 1 100%;
      max-width: 240px;
    }

    &.firstDay {
      & > .status {
        margin-left: @offset-lg;
        height: @offset-lg;
      }
    }

    & > .status {
      display: inline-flex;
      align-items: center;
      color: @color-secondary-copy;
      white-space: nowrap;
      height: 20px;
      opacity: 1;

      &.ng-show, &.ng-hide.ng-hide-active {
        opacity: 1;
      }
      &.ng-hide, &.ng-show.ng-show-active {
        opacity: 0;
      }

      i {
        margin-right: 4px;
        display: inline-block;
      }

      & + span {
        display: none;
      }

      &.saving {
        i {
          animation: rotate .3s linear infinite;

          &.icon-task_field_save_checkmark {
            display: none;
          }
        }
      }

      &.saved {
        color: @color-green;
        transition: all .2s linear .25s;

        .icon-task_field_save_ring {
          display: none;
        }
      }
    }

    .dropdown-tether .tether-handler {
      width: 130px;
    }

    .weekdays-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: -20px;

      .checkbox {
        display: inline-flex;
        width: auto;
        white-space: nowrap;
        align-items: center;
        margin: 20px 20px 0 0;

        & > span {
          margin-right: @offset-sm;
        }
      }
    }

    .years-list {
      .year-block {
        h3 {
          font-family: @font-family-medium;
          font-size: @font-size-md;

          & > .blue {
            color: @color-blue;
            font-size: @font-size-sm;
            margin-left: @offset-sm;
          }
        }
        & > ul {
          list-style: none;
          margin-top: @offset-lg;
          li {
            font-size: @font-size-sm;

            & + li {
              margin-top: @offset-md;
            }

            .red {
              color: @color-red;
              margin-right: @offset-xs;
            }

            i {
              font-size: 10px;
              color: @color-add-grey;
              margin-left: @offset-sm;
              cursor: pointer;
              .no-focus();
            }
          }
        }
        & > p {
          font-size: @font-size-sm;
          margin-top: @offset-lg;
          color: @color-secondary-copy;
        }
        & > .blue {
          font-family: @font-family-medium;
          color: @color-blue;
          font-size: @font-size-sm;
          margin-top: @offset-lg;
          display: block;
        }

        & + .year-block {
          margin-top: 2*@offset-md;
        }
      }
    }
  }

  @media only screen and (max-width: @phone-portrait-size) {
    padding: 0 @offset-lg 100px @offset-lg ;

    .form-group {
      display: block;

      &.weekDays {
        & > .status {
          margin-top: @offset-sm;
        }
      }
      &.firstDay {
        & > .status {
          transform: translateY(-10px);
        }
      }

      & > label {
        margin-bottom: @offset-md;
      }
    }
  }
}

