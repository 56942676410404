.start-process-modal {
  overflow: auto;
  display: flex !important;
  flex-direction: column;
  justify-content: center;

  @process-start-modal-width: 750px;

  .modal-dialog {
    max-width: @process-start-modal-width + (4 * @offset-md);
    margin-top: 0;
    padding: 2*@offset-md;

    .process-start-description {
      font-size: 12px;
       white-space:normal;
      //white-space: pre-wrap;
    }
  }
  .modal-content {
    max-width: @process-start-modal-width;

    .modal-header {
      h3 {
        line-height: @thumbnail-size;
        padding-right: 3*@offset-md;

        .name {
          font-family: @font-family-medium;
        }

        .avatar-small {
          display: inline-block;
          margin: 0 @offset-xs;
          color: @color-asphalt-grey;
          min-width: @thumbnail-size;

          i {
            font-size: @font-size-md;
            display: block;

            &.icon-Mens,
            &.icon-Magnifi-Glass2 {
              font-size: 12px;
            }
          }

          &.default {
            background-color: @color-grey;

            i {
              line-height: @thumbnail-size;
            }
          }
        }
      }
    }
    .modal-body {
      .form-hint {
        margin-top: @offset-md;
      }
      .input-hint--error,
      .input-hint--info {
        margin-left: 110px;
      }

      .form-group {
        &.has-error {
          .prc-date-picker {
            .tmplDeadline {
              border-color: @color-red !important;
              border-width: 2px !important;
            }
          }
        }
        & > label {
          @label-with: 110px;
          flex: 0 1 @label-with;
          min-width: @label-with;
          width: @label-with;
        }
      }

      .task-data-model {
        .form-group {
          & > label {
            @label-with: 200px;
            flex: 0 1 @label-with;
            min-width: @label-with;
            width: @label-with;
          }
        }
      }

      .process-name-field {
        min-height: 32px;
        word-break: break-all;

        input {
          margin-right: 24px;
        }
      }

      .prc-date-picker {
        display: inline-block;
        flex: none;
      }

      .process-start-form {
        padding-top: 2*@offset-md;
        @data-form-height: 300px;

        .form-group {
          margin-top: 0;
          margin-bottom: 2*@offset-md;
        }

        .scroll-container {
          background-color: white;
          margin-top: -1px;
          padding-top: 1px;
          max-height: 0;
          overflow-y: auto;
          .google-scroll-shadow();
          transition: max-height .5s;

          &.ready {
            max-height: 350px;
            padding-bottom: 2*@offset-md;
          }
        }

        & > footer {
          justify-content: center;
          margin-bottom: 0;
          margin-top:@offset-md;

          & > .btn {
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            padding: 0 12px;
            border-radius: 16px;
            margin-left: 0 !important;
            margin-right: @offset-sm;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .modal-dialog {
      margin-top: 20px !important;

      .file-data-field {
        min-width: 140px !important;
      }
    }
  }
}

@media only screen and (max-width: @phone-landscape-size) {
  .start-process-modal {
    display: flex !important;
    align-items: center;
    top: 54px !important;
    height: calc(100% - 54px) !important;
    background-color: transparent;
    width: 100%;
    .modal-dialog{
      margin-top: 0 !important;;
      width: 100%;
      max-width: 100%;
      height: 100%;
      display: block;
      box-shadow: none;
      overflow: scroll;
      position: relative;
      padding: 0;

      .modal-header {
        width: 100%;
        padding: @offset-lg 2*@offset-md;
        background-color: @color-white;
        border-bottom: 1px solid @color-grey;
        i {
          right: 24px;
          top: 16px;
        }
      }

      .modal-body{
        padding: 0 24px 24px 24px;

        .scroll-container {
          max-height: none !important;
        }
        .scroll-wrapper {
          height: auto !important;
        }
        footer {
          justify-content: flex-start !important;
        }
      }
    }
  }
}
