@import "variables";
@import "mixins";

.site-navigation {
  li {
    a {
      display: block;
      font-family: @font-family-semibold;
      font-size: @font-sm-size;
      color: @site-menu-color;
      text-decoration: none;
      line-height: 18px;
      cursor: pointer;
      .no-focus();
      text-transform: uppercase;

      img {
        display: block;
      }

      &:hover, &.active {
        color: @site-menu-color-hov;
      }
    }

    a.start-process {
      .inline-flex;
      font-family: @font-family-semibold;
      color: @site-menu-color;
      text-decoration: none;
      line-height: 18px;
      cursor: pointer;
      text-transform: none;

      border: 1px @color-secondary-btn solid;
      border-radius: @buttons-border-radius;
      font-size: @font-size-md;

      i {
        margin-right: 5px;
        color: @color-secondary-copy;
      }

      &:hover {
        color: @color-blue;
        border-color: @color-blue;

        i {
          color: @color-blue;
        }
      }

      &:active {
        color: @color-white;
        background-color: @color-blue;

        i {
          color: @color-white;
        }
      }
    }
  }
}


.site-header {
  background: @gray-color;
  width: 100%;
  height: @site-header-size;
  padding: 0 @site-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px @border-gray-color solid;

  .site-navigation {
    .inline-flex;
    float: left;

    li {
      float: left;
      margin-right: 25px;
    }
  }

  .only-on-phone-screen {
    display: none;
  }

  .settings {
    min-width: 125px;
    text-align: right;

    .user-info {
      display: flex;
      align-items: center;
      font-family: @font-family-semibold;
      color: @color-secondary-copy;
      font-size: @font-size-sm;
      cursor: pointer;

      &:hover {
        .name {
          color: @color-blue;
        }
      }

      .avatar {
        margin-right: @offset-md;
      }

      main {
        span {
          display: flex;
          align-items: center;
          color: @color-secondary-copy;
          margin-top: @offset-xs;

          &.name {
            white-space: nowrap;
            color: @color-main-copy;
            transition: all .2s ease;

            i {
              font-size: 4px;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 650px) {
    padding: 0 @offset-lg;

    .settings {
      min-width: 40px;
    }

    .header-user-block .user-info {
      & > main {
        display: none;
      }
      .avatar {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 360px) {
    & > ul > li:last-child {
      display: none;
    }
  }
}

.header-user {
  background-color: @color-panel-grey;
  box-shadow: 0 2px 5px @gray-light-color;
  z-index: @z-index-over-content;
  top: @offset-sm !important;

  &.header-user-open {
    .header-user-content {
      display: block;
    }
  }

  .header-user-content {
    display: none;
  }

  li {
    white-space: nowrap;

    a {
      padding: @offset-md @offset-lg;
      font-size: @font-size-sm;
      font-family: @font-family-semibold;
      cursor: pointer;
      color: inherit;
      display: block;

      &:hover {
        background-color: @color-light-grey;
      }
    }
  }
}

.phone-screen-navigation {
  display: none;
  background: @gray-color;
  width: 100%;
  height: @site-header-size;
  padding: 0 @site-padding;
  border-top: 1px @border-gray-color solid;
  position: absolute;
  bottom: 0;

  .site-navigation {
    height: @site-header-size;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 507px) {
  .phone-screen-navigation {
    display: block;
  }

  .site-header {
    .hide-on-phone-screen {
      display: none;
    }

    .only-on-phone-screen {
      display: block;
    }
  }
}
