@import "../../less/variables";
@import "../../less/mixins";

.onboarding-page {
  text-align: center;
  padding-top: 50px;

  header {
    font-size: 30px;
    color: @color-main-copy;

    & > img {
      transform: translateY(4px);
    }

    & > p {
      font-size: @font-size-lg;
      margin-top: @offset-md;
    }
  }
  main {
    margin: 40px 0;
  }
  footer {
    .btn {
      min-width: 90px;
    }
  }
}
