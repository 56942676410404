@import "../../../../less/variables";
@import "../../../../less/mixins";

.template__section__process-details {
  padding: @offset-lg 2*@offset-md @page-screen-bottom-offset 2*@offset-lg;
  position: relative;
  min-height: 100%;

  &__tabs {
    margin-top: 0;
    li + li {
      margin-left: @offset-md;
    }
  }

  &.ng-hide-add, &.ng-hide-remove {
    transition: all linear 0.2s;
  }
  &.ng-hide {
    opacity: 0;
  }

  & > .content {
    width: 100%;
    @settings-label-width: 170px;

    & > div {

      .hint {
        margin-left: @settings-label-width + @offset-xs;
        font-size: @font-size-sm;
        color: @color-secondary-copy;
      }

      .errors {
        margin-top: 2px;
        margin-left: @settings-label-width + @offset-xs;
      }

      & > .form-group {
        align-items: flex-start;
        max-width: 770px;

        &:not(.m-start) {
          & > label {
            padding-top: @offset-sm;
          }
        }

        .g-radio-group {
          width: 100%;

          .g-radio {
            display: inline-block;
          }
        }

        & > label {
          flex: 0 1 @settings-label-width;
          min-width: @settings-label-width;
          width: @settings-label-width;
          display: flex;
          justify-content: space-between;

          i.icon-template_create_field_required {
            font-size: 10px;
            vertical-align: top;
          }
        }

        & > .selectize-control {
          flex: 0 1 calc(100% - @settings-label-width);
          max-width: calc(100% - @settings-label-width);

          .selectize-dropdown .dropdown-list {
            z-index: 7;
          }
        }

        & > .process-name-control {
          flex: 0 1 calc(100% - @settings-label-width);
          max-width: calc(100% - @settings-label-width);
        }
      }

      & > .instructions-group {
        & > textarea {
          min-height: 110px;
        }

        &.active + markdown-hint {
          visibility: visible;
        }

        .errors {
          margin-left: 0 !important;
        }
      }
      & > .errors {
        margin-top: 2px;
        margin-left: @settings-label-width;
      }
    }
  }
}
