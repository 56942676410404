.multiselect-field {
  margin-top: @offset-xs;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 100px;

  label {
    display: inline-flex;
    align-items: center;
    height: auto;
    margin-left: 0;
    width: auto;
    flex: 0 1 auto;
    min-width: 50px;
    max-width: 340px;
    font-family: @font-family-regular;

    &.error {
      & > input + span {
        border-color: @color-red !important;
        border-width: 1px !important;
      }

      & > span {
        border-color: @color-red;
        border-width: 1px;
      }
    }

    & + label {
      margin-top: @offset-md;
    }

    & > .checkbox-control {
      width: @checkbox-size;
      min-width: @checkbox-size;
      margin-right: @offset-sm;
      height: @checkbox-size;
      position: relative;

      & > i {
        top: 3px;
        left: -1px;
        position: unset;
      }
    }

    & > span:not(.checkbox-control) {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .error-hint {
    color: @color-red;
    margin-top: @offset-md;
    font-size: @font-size-sm;
  }
}

.multiselect-control {
  .error-hint {
    color: @color-red;
    margin-left: 2px;
    font-size: @font-size-sm;
  }
}
