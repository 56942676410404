.task-data-model {
  margin-top: @offset-lg;
  width: 100%;

  @label-width: 200px;
  @status-width: 90px;
  @min-width: 240px;
  @max-width: 615px;

  section {
    margin-bottom: @offset-sm;

    main {
      padding-top: 1px;
    }

    &.isForm {
      & > header {
        padding: 0 @offset-sm;

        &.closed {
          margin: 0;
        }
      }
    }

    header {
      display: flex;
      padding: 0 0 0 @offset-sm;
      height: 2*@offset-lg;
      cursor: pointer;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: @offset-xs;
      .no-focus();

      &.closed {
        border: 1px solid @color-light-grey;
        border-radius: 6px;

        &.error {
          border-color: @color-red;

          .main-info {
            & > i, & > span {
              color: @color-red;
            }
          }
        }
      }

      & > .main-info {
        display: inline-flex;
        align-items: center;
        height: 100%;

        i {
          color: @color-add-grey;
          margin-right: @offset-sm;
        }

        span {
          color: @color-add-grey;
          font-size: @font-size-md;
          font-family: @font-family-semibold;
        }
      }

      & > .actions-info {
        display: inline-flex;
        align-items: center;
        height: 100%;

        & > a {
          i {
            margin-right: 5px;
          }
        }

        & > i {
          font-size: 30px;
          color: @color-secondary-btn;
          transition: all .15s ease;
          .no-focus();

          &:hover {
            color: @color-blue;
          }
        }
      }
    }

    main {
      .form-group {
        display: flex;
        align-items: flex-start;
        position: relative;
        margin: 2*@offset-md 0;
        padding: 0 @offset-md 0 3*@offset-md;
        min-height: 30px;

        &:first-child {
          margin-top: @offset-md;
        }

        file-data-field,
        user-field,
        dropdown-tether {
          display: block;
          max-width: 615px;
        }

        file-data-field {
          width: calc(100% - (@label-width + @status-width));
        }

        .content-wrapper {
          max-width: calc(100% - (@label-width + @status-width));
          position: relative;

          &.string,
          &.multiline,
          &.files {
            width: calc(100% - (@label-width + @status-width));
            max-width: 615px;
          }

          .textarea-content-wrapper,
          .input-read-only {
            max-width: 615px;
          }

          .input-read-only.formula {
            min-width: 180px;
          }

          &.money {
            min-width: 180px;
          }

          .dropdown-tether {
            display: block;

            .tether-handler {
              float: unset;
              max-width: calc(100%);
            }
          }

          .multiselect-field {
            max-width: 615px;

            label {
              max-width: 100%;
            }
          }

          .hidden {
            display: none;
          }

          .prc-date-picker {
            margin-right: 0;
          }
        }

        .formula-error {
          padding: 8px;
          i.icon-common_hint {
            margin-left: 0;
          }
        }

        .actions {
          padding: 8px;
          overflow: hidden;
          width: 60px;

          .btn {
            opacity: 0;
            transition: opacity 0.2s;
            color: @color-add-grey;
            padding: 0;

            &:hover {
              color: @color-blue;
            }
          }
        }

        &:hover {
          .actions {
            .btn {
              opacity: 1;
            }
          }
        }

        textarea + .errors-messages {
          margin-top: 0;
        }

        .hint-messages {
          padding-top: 2px;
          margin-left: 2px;
          color: @color-secondary-copy;
          white-space: nowrap;
          width: 60px;

          & + .errors-messages {
            margin-top: 0;
          }
        }

        .errors-messages {
          position: absolute;
          margin-top: 1px;
          margin-left: 2px;
          top: 100%;
          white-space: nowrap;

          div {
            color: @color-red;
          }
        }

        & > label {
          min-width: @label-width;
          width: @label-width;
          flex: 0 1 @label-width;
          padding-right: @offset-lg;
          padding-top: @offset-sm;
          word-wrap: break-word;

          .actions {
            .btn {
              margin: 0 4px;
            }
          }
        }

        .status {
          height: 2*@offset-lg;
          margin-left: @offset-sm;
          display: inline-flex;
          align-items: center;
          color: @color-secondary-copy;
          white-space: nowrap;
          max-width: @status-width;

          i {
            margin-right: 2px;
            display: inline-block;
          }

          span {
            opacity: 1;

            & + span {
              display: none;
            }

            &.saving {
              i {
                animation: rotate .3s linear infinite;
              }
            }

            &.saved {
              color: @color-green;
              transition: all .2s linear .25s;

              &.ng-show, &.ng-hide.ng-hide-active {
                opacity: 1;
              }

              &.ng-hide, &.ng-show.ng-show-active {
                opacity: 0;
              }

              &.silent {
                transition: none;
              }
            }
          }
        }

        input {
          width: 100%;
          background: transparent;

          &.number {
            min-width: 180px;
            width: auto;
            flex: 0 1 auto;
          }

          &:read-only {
            color: @color-main-copy;
            -webkit-text-fill-color: @color-main-copy;
            background: @color-panel-grey;
            border: none;
            font-family: @font-family-semibold;
          }
        }

        textarea {
          width: 100%;
          resize: vertical;
          min-height: 7*@offset-md;
          background-color: transparent;

          &:read-only {
            color: @color-main-copy;
            -webkit-text-fill-color: @color-main-copy;
            background: @color-panel-grey;
            border: none;
            font-family: @font-family-semibold;
          }
        }

        .input-read-only {
          background: @color-panel-grey;
          color: @color-main-copy;
          border: none;
          font-family: @font-family-semibold;
          border-radius: 4px;
          padding: 8px 7px;
          min-height: 30px;

          a {
            color: @color-blue;

            &.full-link {
              display: none;
            }
          }
        }

        .textarea-content-wrapper {
          min-height: 7*@offset-md;
          background: @color-panel-grey;
          color: @color-main-copy;
          border: none;
          font-family: @font-family-semibold;
          width: 100%;
          height: 7*@offset-md;
          border-radius: 4px;
          word-wrap: break-word;
          display: block;
          position: relative;
          overflow: hidden;
          padding-bottom: 7px;

          .textarea-content {
            overflow-y: scroll;
            height: 100%;
            padding: 8px 7px;
            user-select: all;

            & > span {
              white-space: pre-wrap;
            }

            a {
              color: @color-blue;

              &.full-link {
                display: none;
              }
            }
          }
        }

        .icon-common_hint {
          margin-left: 8px;
          vertical-align: bottom;
        }

        .icon-template_create_field_required {
          font-size: @font-size-xs;
          margin-left: @offset-xs;
          transform: translateY(-1px);
        }

        &.error {
          input, textarea {
            border-color: @color-red;
            box-shadow: none;

            &:focus, &.active {
              border-color: @color-red;
              box-shadow: 0 0 0 1px @color-red;
            }
          }

          .icon-template_create_field_required {
            color: @color-red;
          }

          .prc-date-picker {
            .handler {
              border-color: @color-red !important;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 868px) {
    section.isForm main {
      .form-group {
        display: block;

        label,
        & > .label {
          width: 100%;
        }

        .actions {
          display: inline-block;
          padding: 0;
          vertical-align: bottom;
          width: 20px;

          .btn {
            opacity: 1 !important;
          }
        }

        .content-wrapper {
          width: 100% !important;
          max-width: 100%;
          margin-top: @offset-sm;
        }

        file-data-field {
          width: 100% !important;
          margin-top: @offset-sm;
        }
      }
    }

    section main {
      .form-group {
        .status {
          margin-left: 0 !important;
          margin-top: @offset-lg;
          height: auto !important;
        }
      }
    }
  }
}

.process-start-form {
  .task-data-model {
    main {
      .form-group:not(.files_list) {
        .actions {
          .btn {
            display: none;
          }
        }
      }
    }
  }
}
