@import "../../less/variables";
@import "../../less/mixins";

.expression-component {
  & > .help-text {
    color: @site-menu-color;
    font-size: 12px;
    padding-top: 4px;
  }

  & > .error {
    color: @color-red;
    font-size: 12px;
    padding-top: 4px;
  }
}

.expression-view {
  word-break: break-all;

  .avatar-small {
    background: @color-light-grey;
    color: @color-secondary-copy;
    display: inline-block;

    .icon-assignee_process_starter {
      display: block;
      font-size: @font-size-md;
      width: auto;
    }
  }
}
