/* Primary colors */
/* Font-family */
/* Secondary colors */
/* Primaty offsets */
/* Font-sizes */
/* Thumbnail */
/* Forms */
/* Input sizes */
/* Z-indexes */
/*
 * FROM /modules/home/less/variables
 */
/* Site menu */
/* Tasks main */
/* Tasks list */
/*
 * FROM /modules/auth/less/variables
 */
/*
 * FROM /modules/processes/less/variables
 */
.inline-flex {
  display: inline-flex;
  align-items: center;
}
.native-size {
  padding: 0;
  margin: 0;
}
.input-border {
  border: 2px #cacaca solid;
  border-radius: 4px;
}
.add-holiday-form {
  display: flex;
  align-items: center;
  margin-top: 16px;
  position: relative;
}
.add-holiday-form.noDate .prc-date-picker .handler,
.add-holiday-form.serverError .prc-date-picker .handler {
  border-color: #f75f5f;
}
.add-holiday-form .prc-date-picker {
  background: #fff;
  margin-right: 0;
}
.add-holiday-form .prc-date-picker .handler {
  padding: 6px 7px;
  flex: 1 0 auto;
  border: 2px #cacaca solid;
  border-radius: 4px;
  border-width: 1px;
  cursor: pointer;
  height: 32px;
}
.add-holiday-form .prc-date-picker .handler.drop-enabled {
  border-color: #2e9df0;
  border-width: 2px;
}
.add-holiday-form > input {
  width: 180px;
  margin-left: 8px;
}
.add-holiday-form > input.error {
  border-color: #f75f5f;
}
.add-holiday-form > button {
  margin-left: 12px;
  min-width: 36px;
  width: 36px;
  flex: 0 1 36px;
  display: block;
}
.add-holiday-form > button:not(:hover):not(:focus) i {
  color: #ccc;
}
.add-holiday-form > button i {
  margin: 0;
  font-size: 10px;
}
.add-holiday-form > button i.icon-menu_checkmark {
  margin-left: -1px;
}
.add-holiday-form > button + button {
  margin-left: 4px;
}
.add-holiday-form > span.error {
  color: #f75f5f;
  position: absolute;
  top: 100%;
  left: 2px;
  margin-top: 3px;
}
.admin-info-popup {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 5;
  background: rgba(74, 188, 59, 0.8);
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  min-width: 286px;
  color: #fff;
  cursor: default;
  right: 32px;
  top: -52px;
}
.admin-info-popup:focus {
  outline: none;
}
.admin-info-popup > .icon-common_close {
  color: #fff;
  opacity: 0.7;
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 12px;
  cursor: pointer;
}
.admin-info-popup > .icon-common_close:hover {
  opacity: 1;
}
.admin-info-popup > .icon-toaster_mail {
  margin-right: 12px;
  flex: 0 1 auto;
  font-size: 18px;
}
.admin-info-popup div {
  flex: 0 1 auto;
}
.admin-info-popup div header {
  color: #fff;
  font-family: 'Roboto-Bold', Arial;
  font-size: 12px;
  white-space: nowrap;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}
.admin-info-popup div a {
  color: #fff;
  text-decoration: underline;
  margin-top: 4px;
  cursor: pointer;
  font-size: 12px;
}
.admin-info-popup div a:hover {
  text-decoration: none;
}
.admin-billing {
  width: 100%;
  padding: 45px;
  border: 1px solid #e1ebef;
  height: 100%;
  max-height: calc(100% - (4 * 16px));
  overflow: auto;
}
.admin-billing > .form-hint {
  margin: -12px 0 24px;
}
.admin-billing__loader .loading-spinner {
  justify-content: center;
  font-size: 18px;
  height: 24px;
}
.admin-billing__loader .loading-spinner i {
  margin-right: 12px;
  display: inline-block;
  font-size: 24px;
  height: 24px;
  animation: rotate 0.3s linear infinite;
}
.admin-billing__trial {
  text-align: center;
  padding: 45px 0;
}
.admin-billing__trial .btn-cancel-account {
  display: block;
  margin: 24px auto 0;
}
.admin-billing__trial.warn .round-progress path {
  stroke: #f75f5f !important;
}
.admin-billing__trial p {
  margin-top: 24px;
}
.admin-billing__trial p.hint {
  color: #959595;
  margin-top: 8px;
  font-size: 12px;
}
.admin-billing__trial > .form-hint {
  margin: 16px auto -8px;
  max-width: 360px;
}
.admin-billing__trial > .form-hint > h5 {
  font-family: 'Roboto-Medium', Arial;
  color: #222;
  font-size: 14px;
}
.admin-billing__trial > .form-hint > p {
  font-size: 12px;
  margin-top: 8px;
}
.admin-billing__trial > .form-hint > p a {
  text-decoration: underline;
  color: #222;
}
.admin-billing__trial > button {
  margin-top: 24px;
}
.admin-billing__paid__header {
  display: flex;
}
.admin-billing__paid__header__left,
.admin-billing__paid__header__right {
  width: 50%;
  flex: 0 1 50%;
  display: inline-flex;
  font-size: 14px;
}
.admin-billing__paid__header__left .error-hint,
.admin-billing__paid__header__right .error-hint {
  background-color: #f9d83c;
  border-radius: 6px;
  padding: 12px;
  margin: 12px 0 0;
}
.admin-billing__paid__header__left .error-hint > h5,
.admin-billing__paid__header__right .error-hint > h5 {
  font-family: 'Roboto-Medium', Arial;
  color: #222;
  font-size: 14px;
}
.admin-billing__paid__header__left .error-hint > p,
.admin-billing__paid__header__right .error-hint > p {
  font-size: 12px;
  margin-top: 8px;
}
.admin-billing__paid__header__left .error-hint > p a,
.admin-billing__paid__header__right .error-hint > p a {
  text-decoration: underline;
  color: #222;
}
.admin-billing__paid__header__left > div,
.admin-billing__paid__header__right > div {
  margin-left: 24px;
}
.admin-billing__paid__header__left > div > button,
.admin-billing__paid__header__right > div > button {
  margin-top: 16px;
}
.admin-billing__paid__header__left > div.notSet ul > li,
.admin-billing__paid__header__right > div.notSet ul > li {
  color: #ccc;
}
.admin-billing__paid__header__left > div.notSet ul > li > span,
.admin-billing__paid__header__right > div.notSet ul > li > span {
  color: #959595;
}
.admin-billing__paid__header__left > div ul,
.admin-billing__paid__header__right > div ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.admin-billing__paid__header__left > div ul li,
.admin-billing__paid__header__right > div ul li {
  color: #959595;
}
.admin-billing__paid__header__left > div ul li + li,
.admin-billing__paid__header__right > div ul li + li {
  margin-top: 8px;
}
.admin-billing__paid__header__left > div ul li.error,
.admin-billing__paid__header__right > div ul li.error {
  color: #f75f5f;
}
.admin-billing__paid__header__left > div ul li > span.field-label,
.admin-billing__paid__header__right > div ul li > span.field-label {
  color: #222;
  display: inline-block;
  width: 100px;
}
.admin-billing__paid__payments .empty-state {
  text-align: center;
  margin-top: 36px;
}
.admin-billing__paid__payments .empty-state > i {
  font-size: 50px;
  color: #d1d1d1;
}
.admin-billing__paid__payments .empty-state > h5 {
  font-size: 14px;
  margin-top: 24px;
  color: #222;
}
.admin-billing__paid__payments .empty-state > p {
  color: #a3a3a3;
  font-size: 12px;
  margin: 8px auto 0;
  max-width: 300px;
}
.admin-billing__paid__payments .payments-list {
  margin-top: 32px;
}
.admin-billing__paid__payments .payments-list > h3 {
  text-align: center;
  font-size: 16px;
}
.admin-billing__paid__payments .payments-list__table {
  border: 1px solid #e8e8e8;
  margin-top: 24px;
}
.admin-billing__paid__payments .payments-list__table span {
  display: block;
  font-size: 12px;
}
.admin-billing__paid__payments .payments-list__table span i {
  color: #959595;
  font-style: normal;
  width: 16px;
  display: inline-block;
}
.admin-billing__paid__payments .payments-list__table span a {
  color: #2e9df0;
  white-space: nowrap;
}
.admin-billing__paid__payments .payments-list__table span:first-child {
  width: 160px;
  flex: 0 1 160px;
}
.admin-billing__paid__payments .payments-list__table span:nth-child(2) {
  width: 125px;
  flex: 0 1 125px;
}
.admin-billing__paid__payments .payments-list__table span:nth-child(2) {
  width: 125px;
  flex: 0 1 125px;
}
.admin-billing__paid__payments .payments-list__table span:nth-child(3) {
  width: 90px;
  flex: 0 1 90px;
}
.admin-billing__paid__payments .payments-list__table span:nth-child(4) {
  width: 80px;
  flex: 0 1 80px;
}
.admin-billing__paid__payments .payments-list__table span:nth-child(4).isNotYetPaid {
  color: #f75f5f;
}
.admin-billing__paid__payments .payments-list__table header {
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}
.admin-billing__paid__payments .payments-list__table header span {
  font-family: 'Roboto-Medium', Arial;
}
.admin-billing__paid__payments .payments-list__table header span:first-child {
  width: 176px;
  flex: 0 1 176px;
  padding-left: 32px;
}
.admin-billing__paid__payments .payments-list__table__row {
  display: flex;
  align-items: center;
  height: 42px;
  margin: 0 16px;
}
.admin-billing__paid__payments .payments-list__table__row + .payments-list__table__row {
  border-top: 1px solid #f5f5f5;
}
@media only screen and (max-width: 767px) {
  .admin-billing {
    padding: 24px;
  }
  .admin-billing .admin-billing__paid__header__right > img,
  .admin-billing .admin-billing__paid__header__left > img {
    display: none;
  }
  .admin-billing .admin-billing__paid__payments .empty-state > i {
    display: none;
  }
}
@media only screen and (max-width: 414px) {
  .admin-billing .admin-billing__paid__header {
    display: block;
  }
  .admin-billing .admin-billing__paid__header__right,
  .admin-billing .admin-billing__paid__header__left {
    width: 100%;
  }
  .admin-billing .admin-billing__paid__header__right > img,
  .admin-billing .admin-billing__paid__header__left > img {
    display: inline-block;
  }
  .admin-billing .admin-billing__paid__header__right {
    margin-top: 32px;
  }
  .admin-billing .admin-billing__paid__header__right.early-users {
    display: none;
  }
  .admin-billing .admin-billing__paid__payments .payments-list__table {
    border-left: none;
    border-right: none;
  }
  .admin-billing .admin-billing__paid__payments .payments-list__table > header {
    display: none;
  }
  .admin-billing .admin-billing__paid__payments .payments-list__table__row > span:nth-child(2) {
    display: none;
  }
  .admin-billing .admin-billing__paid__payments .payments-list__table__row > span:nth-child(3) {
    padding-left: 8px;
    width: 60px;
  }
  .admin-billing .admin-billing__paid__payments .payments-list__table__row > span:nth-child(4) {
    display: none;
  }
  .admin-billing .admin-billing__paid__payments .empty-state > i {
    display: none;
  }
}
.chargbee-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.chargbee-backdrop #chargbee-container {
  text-align: center;
  margin: 24px auto;
  padding: 16px 16px 16px 270px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  max-height: calc(100% - (4 * 12px));
  width: 540px;
  border-radius: 6px;
  -webkit-overflow-scrolling: touch;
}
.chargbee-backdrop #chargbee-container iframe {
  border: none;
  border-radius: 4px;
  width: 768px;
  transform: translateX(-50%);
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 767px) {
  .chargbee-backdrop #chargbee-container {
    width: 340px;
    padding-left: 170px;
  }
  .chargbee-backdrop #chargbee-container iframe {
    width: 300px;
  }
}
.strike {
  color: #000;
  text-decoration: line-through;
}
.strike > span {
  color: #959595;
}
.admin__main__calendar {
  width: 100%;
  height: 100%;
  padding: 24px 48px 0 48px;
  border: 1px solid #e1ebef;
  max-height: calc(100% - (4 * 16px));
  overflow: auto;
}
.admin__main__calendar:focus {
  outline: none;
}
.admin__main__calendar__loader .loading-spinner {
  justify-content: center;
  font-size: 18px;
  height: 24px;
}
.admin__main__calendar__loader .loading-spinner i {
  margin-right: 12px;
  display: inline-block;
  font-size: 24px;
  height: 24px;
  animation: rotate 0.3s linear infinite;
}
.admin__main__calendar > .form-group.m-start > label {
  margin-top: 2px;
}
.admin__main__calendar > .form-group.holidays .years-list {
  margin-bottom: 100px;
}
.admin__main__calendar > .form-group > label {
  width: 180px;
  min-width: 180px;
  flex: 0 1 180px;
  font-family: 'Roboto-Medium', Arial;
}
.admin__main__calendar > .form-group > input {
  width: 100%;
  flex: 0 1 100%;
  max-width: 240px;
}
.admin__main__calendar > .form-group.firstDay > .status {
  margin-left: 16px;
  height: 16px;
}
.admin__main__calendar > .form-group > .status {
  display: inline-flex;
  align-items: center;
  color: #959595;
  white-space: nowrap;
  height: 20px;
  opacity: 1;
}
.admin__main__calendar > .form-group > .status.ng-show,
.admin__main__calendar > .form-group > .status.ng-hide.ng-hide-active {
  opacity: 1;
}
.admin__main__calendar > .form-group > .status.ng-hide,
.admin__main__calendar > .form-group > .status.ng-show.ng-show-active {
  opacity: 0;
}
.admin__main__calendar > .form-group > .status i {
  margin-right: 4px;
  display: inline-block;
}
.admin__main__calendar > .form-group > .status + span {
  display: none;
}
.admin__main__calendar > .form-group > .status.saving i {
  animation: rotate 0.3s linear infinite;
}
.admin__main__calendar > .form-group > .status.saving i.icon-task_field_save_checkmark {
  display: none;
}
.admin__main__calendar > .form-group > .status.saved {
  color: #4abc3b;
  transition: all 0.2s linear 0.25s;
}
.admin__main__calendar > .form-group > .status.saved .icon-task_field_save_ring {
  display: none;
}
.admin__main__calendar > .form-group .dropdown-tether .tether-handler {
  width: 130px;
}
.admin__main__calendar > .form-group .weekdays-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -20px;
}
.admin__main__calendar > .form-group .weekdays-list .checkbox {
  display: inline-flex;
  width: auto;
  white-space: nowrap;
  align-items: center;
  margin: 20px 20px 0 0;
}
.admin__main__calendar > .form-group .weekdays-list .checkbox > span {
  margin-right: 8px;
}
.admin__main__calendar > .form-group .years-list .year-block h3 {
  font-family: 'Roboto-Medium', Arial;
  font-size: 14px;
}
.admin__main__calendar > .form-group .years-list .year-block h3 > .blue {
  color: #2e9df0;
  font-size: 12px;
  margin-left: 8px;
}
.admin__main__calendar > .form-group .years-list .year-block > ul {
  list-style: none;
  margin-top: 16px;
}
.admin__main__calendar > .form-group .years-list .year-block > ul li {
  font-size: 12px;
}
.admin__main__calendar > .form-group .years-list .year-block > ul li + li {
  margin-top: 12px;
}
.admin__main__calendar > .form-group .years-list .year-block > ul li .red {
  color: #f75f5f;
  margin-right: 4px;
}
.admin__main__calendar > .form-group .years-list .year-block > ul li i {
  font-size: 10px;
  color: #a3a3a3;
  margin-left: 8px;
  cursor: pointer;
}
.admin__main__calendar > .form-group .years-list .year-block > ul li i:focus {
  outline: none;
}
.admin__main__calendar > .form-group .years-list .year-block > p {
  font-size: 12px;
  margin-top: 16px;
  color: #959595;
}
.admin__main__calendar > .form-group .years-list .year-block > .blue {
  font-family: 'Roboto-Medium', Arial;
  color: #2e9df0;
  font-size: 12px;
  margin-top: 16px;
  display: block;
}
.admin__main__calendar > .form-group .years-list .year-block + .year-block {
  margin-top: 24px;
}
@media only screen and (max-width: 414px) {
  .admin__main__calendar {
    padding: 0 16px 100px 16px;
  }
  .admin__main__calendar .form-group {
    display: block;
  }
  .admin__main__calendar .form-group.weekDays > .status {
    margin-top: 8px;
  }
  .admin__main__calendar .form-group.firstDay > .status {
    transform: translateY(-10px);
  }
  .admin__main__calendar .form-group > label {
    margin-bottom: 12px;
  }
}
.admin__main__company {
  width: 100%;
  height: 100%;
  padding: 24px 48px;
  border: 1px solid #e1ebef;
  max-height: calc(100% - (4 * 16px));
  overflow: auto;
}
.admin__main__company:focus {
  outline: none;
}
.admin__main__company__loader .loading-spinner {
  justify-content: center;
  font-size: 18px;
  height: 24px;
}
.admin__main__company__loader .loading-spinner i {
  margin-right: 12px;
  display: inline-block;
  font-size: 24px;
  height: 24px;
  animation: rotate 0.3s linear infinite;
}
.admin__main__company .form {
  width: 100%;
}
.admin__main__company .form > .form-group > label {
  width: 140px;
  min-width: 140px;
  flex: 0 1 140px;
}
.admin__main__company .form > .form-group > input {
  width: 100%;
  flex: 0 1 100%;
  max-width: 240px;
}
.admin__main__company .form > .form-group > .multi-select {
  max-width: 240px;
}
.admin__main__company .form > .input-hint--info,
.admin__main__company .form > .input-hint--error {
  margin-left: 140px;
}
.admin__main__company .form > footer .btn:first-child {
  margin-left: 140px;
}
.admin__main__company .form .company-logo {
  display: flex;
  align-items: center;
  position: relative;
}
.admin__main__company .form .company-logo .logo {
  flex: 0 1 120px;
  width: 120px;
  height: 40px;
  overflow: hidden;
}
.admin__main__company .form .company-logo .logo img {
  width: 120px;
  height: 40px;
}
.admin__main__company .form .company-logo .actions {
  margin-left: 20px;
}
.admin__main__company .form .company-logo .actions a {
  color: #2e9df0;
  font-size: 12px;
  display: block;
  font-family: 'Roboto-Regular', Arial;
  cursor: pointer;
  margin-bottom: 6px;
}
.admin__main__company .form .company-logo .actions span {
  display: block;
  font-size: 11px;
  color: #959595;
}
.admin__main > div {
  display: flex;
  height: 100%;
  padding: 1px 32px;
}
.admin__main__content {
  height: 100%;
  max-height: calc(100% - (4 * 16px));
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-y-scrolling: touch;
  flex: 0 1 100%;
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}
.admin__main__content:after {
  content: '';
  height: 70px;
  width: 100%;
  display: block;
}
.admin__main__content.active {
  margin-right: -17px;
}
.admin__main__content__user,
.admin__main__content__group,
.admin__main__content__channel {
  border: 1px solid #e1ebef;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 9px;
  position: relative;
  cursor: pointer;
  width: calc(100% - 16px);
}
.admin__main__content__user:focus,
.admin__main__content__group:focus,
.admin__main__content__channel:focus {
  outline: none;
}
.admin__main__content__user:hover h4,
.admin__main__content__group:hover h4,
.admin__main__content__channel:hover h4,
.admin__main__content__user:hover h5,
.admin__main__content__group:hover h5,
.admin__main__content__channel:hover h5 {
  color: #2e9df0;
  cursor: pointer;
}
.admin__main__content__user.active,
.admin__main__content__group.active,
.admin__main__content__channel.active {
  border-color: #2e9df0;
  border-right: 1px solid #2e9df0;
  z-index: 5;
}
.admin__main__content__user.active .square,
.admin__main__content__group.active .square,
.admin__main__content__channel.active .square {
  opacity: 1;
}
.admin__main__content__user + .admin__main__content__user,
.admin__main__content__user + .admin__main__content__group,
.admin__main__content__user + .admin__main__content__channel,
.admin__main__content__group + .admin__main__content__user,
.admin__main__content__group + .admin__main__content__group,
.admin__main__content__group + .admin__main__content__channel,
.admin__main__content__channel + .admin__main__content__user,
.admin__main__content__channel + .admin__main__content__group,
.admin__main__content__channel + .admin__main__content__channel {
  margin-top: -1px;
}
.admin__main__content__user__details,
.admin__main__content__group__details,
.admin__main__content__channel__details {
  flex: 0 1 100%;
  display: flex;
  cursor: pointer;
  position: relative;
}
.admin__main__content__user__details > .invited-badge,
.admin__main__content__group__details > .invited-badge,
.admin__main__content__channel__details > .invited-badge {
  background-color: #f9d83c;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid #fff;
  line-height: 15px;
  position: absolute;
  left: 27px;
  top: 27px;
  text-align: center;
}
.admin__main__content__user__details > .invited-badge i,
.admin__main__content__group__details > .invited-badge i,
.admin__main__content__channel__details > .invited-badge i {
  font-size: 8px;
  vertical-align: middle;
  display: inline-block;
  margin-left: 1px;
  color: #000;
  opacity: 0.5;
}
.admin__main__content__user__details .avatar,
.admin__main__content__group__details .avatar,
.admin__main__content__channel__details .avatar {
  margin-right: 12px;
}
.admin__main__content__user__details .avatar.group,
.admin__main__content__group__details .avatar.group,
.admin__main__content__channel__details .avatar.group {
  background-color: #e8e8e8;
  color: #a3a3a3;
}
.admin__main__content__user__details .avatar.group i,
.admin__main__content__group__details .avatar.group i,
.admin__main__content__channel__details .avatar.group i {
  font-size: 24px;
}
.admin__main__content__user__details .avatar.group i::before,
.admin__main__content__group__details .avatar.group i::before,
.admin__main__content__channel__details .avatar.group i::before {
  margin: 0;
}
.admin__main__content__user__details .details-wrapper,
.admin__main__content__group__details .details-wrapper,
.admin__main__content__channel__details .details-wrapper {
  width: 100%;
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  cursor: pointer;
}
.admin__main__content__user__details .details-wrapper h4,
.admin__main__content__group__details .details-wrapper h4,
.admin__main__content__channel__details .details-wrapper h4 {
  font-size: 14px;
  transition: all 0.2s ease;
  font-family: 'Roboto-Regular', Arial;
}
.admin__main__content__user__details .details-wrapper h5,
.admin__main__content__group__details .details-wrapper h5,
.admin__main__content__channel__details .details-wrapper h5 {
  font-family: 'Roboto-Medium', Arial;
  font-weight: normal;
  font-size: 14px;
  transition: all 0.15s ease;
}
.admin__main__content__user__details .details-wrapper > p,
.admin__main__content__group__details .details-wrapper > p,
.admin__main__content__channel__details .details-wrapper > p {
  color: #959595;
  font-size: 12px;
  margin-top: 6px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
}
.admin__main__content__user__invite,
.admin__main__content__group__invite,
.admin__main__content__channel__invite {
  color: #959595;
  font-size: 12px;
  white-space: nowrap;
}
.admin__main__content__user__delete,
.admin__main__content__group__delete,
.admin__main__content__channel__delete {
  color: #ccc;
  font-size: 34px;
  cursor: pointer;
}
.admin__main__content__user__delete:focus,
.admin__main__content__group__delete:focus,
.admin__main__content__channel__delete:focus {
  outline: none;
}
.admin__main__content__user__delete:hover,
.admin__main__content__group__delete:hover,
.admin__main__content__channel__delete:hover {
  color: #2e9df0;
}
.admin__main__content__user .square,
.admin__main__content__group .square,
.admin__main__content__channel .square {
  height: 15px;
  width: 15px;
  right: -8px;
  z-index: 100;
  top: 50%;
  margin-top: -8px;
  transform: rotate(45deg);
  background-color: #fff;
  position: absolute;
  border-right: 1px solid #2e9df0;
  border-top: 1px solid #2e9df0;
  opacity: 0;
  cursor: pointer;
}
.admin__main__content__user + .admin__main__content__empty-state,
.admin__main__content__group + .admin__main__content__empty-state,
.admin__main__content__channel + .admin__main__content__empty-state,
.admin__main__content__user + .admin__main__content__error-state,
.admin__main__content__group + .admin__main__content__error-state,
.admin__main__content__channel + .admin__main__content__error-state {
  border-top: none;
}
.admin__main__content__channel .channel-icon {
  background: #cacaca;
  color: #fff;
  font-size: 20px;
}
.admin__main__content .add-another-btn {
  margin: 10px;
}
.admin__main__content__empty-state,
.admin__main__content__error-state {
  border: 1px solid #e1ebef;
  padding: 64px 0;
  width: calc(100% - 16px);
  text-align: center;
}
.admin__main__content__empty-state i,
.admin__main__content__error-state i {
  font-size: 64px;
  color: #d1d1d1;
}
.admin__main__content__empty-state h5,
.admin__main__content__error-state h5 {
  color: #222;
  font-size: 14px;
  margin: 24px auto 0;
  max-width: 250px;
}
.admin__main__content__empty-state p,
.admin__main__content__error-state p {
  color: #a3a3a3;
  font-size: 12px;
  margin: 8px auto 0;
  max-width: 250px;
}
.admin__main__content__empty-state p.not-found,
.admin__main__content__error-state p.not-found {
  max-width: initial;
}
.admin__main__content__empty-state p a,
.admin__main__content__error-state p a {
  color: #2e9df0;
}
.admin__main__content__empty-state .add-to-slack-btn,
.admin__main__content__error-state .add-to-slack-btn {
  display: inline-block;
  margin: 50px auto 0;
}
.admin__main__content__empty-state .add-to-slack-btn img,
.admin__main__content__error-state .add-to-slack-btn img {
  width: 200px;
  height: auto;
}
.admin__main__content__empty-state .loading-spinner,
.admin__main__content__error-state .loading-spinner {
  display: inline-block;
  max-width: 200px;
  text-align: center;
  margin: 50px auto 0;
  font-size: 16px;
  height: 63px;
  padding-top: 24px;
  color: #959595;
}
.admin__main__content__empty-state .loading-spinner i,
.admin__main__content__error-state .loading-spinner i {
  font-size: 16px;
  height: 18px;
  width: 16px;
  animation: rotate 0.3s linear infinite;
}
.admin__main__content__empty-state .start-using-slack,
.admin__main__content__error-state .start-using-slack {
  font-size: 14px;
  color: #222;
}
.admin__main__content__error-state {
  border-color: #f75f5f;
}
.admin__main__header {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px;
  height: 64px;
}
.admin__main__header__nav {
  display: inline-flex;
}
.admin__main__header__nav li {
  padding: 0;
  margin: 0;
}
.admin__main__header__nav li + li {
  margin-left: 8px;
}
.admin__main__header__nav li.menu-item__more {
  display: none;
}
@media only screen and (max-width: 1150px) {
  .admin__main__header__nav li.menu-item__business-calendar,
  .admin__main__header__nav li.menu-item__billing,
  .admin__main__header__nav li.menu-item__integrations {
    display: none;
  }
  .admin__main__header__nav li.menu-item__more {
    display: list-item;
  }
}
@media only screen and (max-width: 750px) {
  .admin__main__header__nav li div a.menu-button-more i {
    margin-right: 0;
  }
  .admin__main__header__nav li div a.menu-button-more span {
    display: none;
  }
}
.admin__main__header .search-block {
  position: relative;
  margin-right: 20px;
}
.admin__main__header .search-block > i {
  position: absolute;
  color: #959595;
  cursor: pointer;
}
.admin__main__header .search-block > i:focus {
  outline: none;
}
.admin__main__header .search-block > i.icon-header_search {
  left: 6px;
  margin-top: -1px;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.15s ease;
}
.admin__main__header .search-block > i.icon-common_remove {
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.admin__main__header .search-block > input {
  position: relative;
  z-index: 1;
  transition: all 0.15s ease;
  opacity: 1;
  padding: 8px 30px;
  appearance: none;
  flex: 0 1 100%;
  width: 100%;
  border: 1px #cacaca solid;
  border-radius: 4px;
  box-shadow: none !important;
  color: #222;
  resize: none;
  outline: 0;
  cursor: pointer;
  background: transparent;
}
.admin__main__header .search-block > input::-ms-clear {
  display: none;
}
.admin__main__header .search-block > input:focus,
.admin__main__header .search-block > input.active {
  outline: none;
  border-color: #2e9df0;
  border-width: 2px;
  cursor: text;
}
.admin__main__header .search-block > input:focus + .icon-header_search,
.admin__main__header .search-block > input.active + .icon-header_search {
  color: #222;
}
.admin__main__header__filters-container {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
}
.admin__main__header__filters {
  display: inline-flex;
  align-items: center;
}
.admin__main__header__filters .mode {
  display: inline-flex;
  align-items: center;
  float: left;
  color: #222;
  font-size: 14px;
  font-family: 'Roboto-Medium', Arial;
  font-weight: normal;
  position: relative;
  outline: none;
  cursor: pointer;
  margin-right: 24px;
}
.admin__main__header__filters .mode > span {
  display: inline-flex;
  align-items: center;
  font-family: 'Roboto-Regular', Arial;
}
.admin__main__header__filters .mode > span:focus {
  outline: none;
}
.admin__main__header__filters .mode > span:hover,
.admin__main__header__filters .mode > span:hover > i {
  color: #2e9df0;
}
.admin__main__header__filters .mode .icon-common_arrow_down {
  font-size: 4px;
  margin-left: 6px;
}
.admin__main__header__filters .mode .processes-dropdown {
  width: 166px;
  padding: 2px 2px;
  position: absolute;
  top: 30px;
  left: -4px;
  background: #fff;
  box-shadow: 0 0 2px #989898;
}
.admin__main__header__filters .mode .processes-dropdown li {
  font-size: 14px;
  color: #222;
  padding: 8px 10px 8px 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.admin__main__header__filters .mode .processes-dropdown li i {
  display: none;
  font-size: 8px;
  color: #4abc3b;
  margin-right: 6px;
}
.admin__main__header__filters .mode .processes-dropdown li.active {
  padding-left: 8px;
}
.admin__main__header__filters .mode .processes-dropdown li.active i {
  display: block;
}
.admin__main__header__filters li.add-group {
  margin-right: 8px;
}
.admin__main__header__filters li > a .small {
  display: none;
}
.admin__main__header__filters li > a > i {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 6px;
}
.admin__main__header__filters .btn-switcher + .btn-switcher {
  margin-left: 4px;
}
@media only screen and (max-width: 700px) {
  .admin__main__header .search-block {
    margin-right: 8px;
    height: 32px;
    min-width: 36px;
  }
  .admin__main__header .search-block > i.icon-common_remove {
    display: none;
  }
  .admin__main__header .search-block > i.icon-header_search {
    left: 9px;
  }
  .admin__main__header .search-block input {
    width: 30px;
    border-radius: 16px;
    padding-left: 2px;
    position: absolute;
    right: 0;
  }
  .admin__main__header.searching .search-block {
    width: 100%;
  }
  .admin__main__header.searching .search-block input {
    width: 100%;
    border-radius: 4px;
    padding: 8px 30px;
  }
  .admin__main__header.searching .search-block > i.icon-header_search {
    left: 6px;
  }
  .admin__main__header.searching .search-block > i.icon-common_remove {
    display: initial;
  }
  .admin__main__header.searching .admin__main__header__filters-container {
    width: 100%;
  }
  .admin__main__header__filters .mode {
    margin-right: 8px;
  }
}
@media only screen and (max-width: 600px) {
  .admin__main__header__nav > li > a > i:first-child {
    margin-right: 1px;
  }
  .admin__main__header__nav > li > a > span {
    display: none;
  }
  .admin__main__header.searching .admin__main__header__nav {
    display: none;
  }
  .admin__main__header__filters > li:last-child a span {
    display: none;
  }
  .admin__main__header__filters > li:last-child a span.invite {
    display: none;
  }
  .admin__main__header__filters > li:last-child a i {
    margin-right: 0.2em;
  }
  .admin__main__header__filters > li .btn-switcher > span {
    display: none;
  }
  .admin__main__header__filters > li .btn-switcher > i {
    margin-right: 0;
  }
}
.admin__main {
  height: 100%;
}
@media only screen and (max-width: 1023px) {
  .admin__main__header {
    padding: 0 24px;
  }
  .admin__main__header__filters > li a > .small {
    display: inline;
  }
  .admin__main__header__filters > li a > span:not(.small) {
    display: none;
  }
  .admin__main__content {
    padding: 0;
  }
  .admin__main__settings {
    padding-left: 24px !important;
    overflow: hidden;
  }
  .admin__main__settings__header > .photo {
    display: none;
  }
  .admin__main__settings__header > main {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .admin__main > div {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .admin__main__header {
    padding: 0 12px;
  }
  .admin__main__content__user,
  .admin__main__content__group,
  .admin__main__content__channel {
    width: 100%;
    padding: 0 12px;
    border-left: none;
    border-right: none;
  }
  .admin__main__content__user .square,
  .admin__main__content__group .square,
  .admin__main__content__channel .square {
    display: none;
  }
  .admin__main__content__empty-state,
  .admin__main__content__error-state {
    width: 100%;
    border-left: none;
    border-right: none;
  }
  .admin__main__settings {
    padding: 16px !important;
    flex: 1 0 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    position: absolute !important;
    background-color: #fff !important;
    left: 0;
    top: 64px;
    z-index: 6;
    opacity: 1 !important;
  }
  .admin__main__settings.ng-hide-add,
  .admin__main__settings.ng-hide-remove {
    transition: all 0.15s ease !important;
  }
  .admin__main__settings.ng-hide {
    transform: translate(100%, 0);
  }
  .admin__main > div {
    padding: 0;
  }
}
@media only screen and (max-width: 586px) {
  .admin__main__content__user__invite .invited-on {
    display: none;
  }
  .admin__main__company {
    padding: 0 24px 24px;
  }
  .admin__main__company .form .form-hint {
    margin-top: 16px;
  }
  .admin__main__company .form .input-hint--error {
    margin-left: 0;
  }
  .admin__main__company .form .form-group {
    display: block;
  }
  .admin__main__company .form .form-group label {
    margin-bottom: 4px;
    width: 100%;
  }
  .admin__main__company .form .form-group input {
    max-width: 100% !important;
  }
  .admin__main__company .form .form-group .select-timezone {
    min-width: 100%;
  }
  .admin__main__company .form > footer .btn:first-child {
    margin-left: 0;
  }
}
.admin-select-filter {
  z-index: 1100;
}
.admin-select-filter.admin-select-filter-open .dropdown-menu--filter-mode {
  display: block;
}
.admin-select-filter .dropdown-menu--filter-mode {
  display: none;
  min-width: 90px;
  background: #fff;
  border: 1px #cacaca solid;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}
.admin-select-filter .dropdown-menu--filter-mode li.separated {
  border-top: 1px solid #e3e3e3;
}
.admin-select-filter .dropdown-menu--filter-mode li a {
  font-size: 14px;
  color: #222;
  padding: 8px 12px 8px 25px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 105px;
}
.admin-select-filter .dropdown-menu--filter-mode li a.active {
  padding-left: 8px;
}
.admin-select-filter .dropdown-menu--filter-mode li a.active i {
  display: block;
}
.admin-select-filter .dropdown-menu--filter-mode li a i {
  display: none;
  font-size: 8px;
  color: #4abc3b;
  margin-right: 6px;
}
.admin-select-filter .dropdown-menu--filter-mode li a:hover {
  color: #2e9df0;
}
.admin-select-filter .dropdown-menu--filter-mode li a.active i {
  color: #4abc3b;
}
.admin__main__settings {
  height: 100%;
  max-height: calc(100% - (4 * 16px));
  overflow-y: auto;
  flex: 0 1 auto;
  min-width: 50%;
  width: 50%;
  padding: 24px 24px 24px 40px;
  border: 1px solid #e1ebef;
  position: relative;
}
.admin__main__settings:focus {
  outline: none;
}
.admin__main__settings.ng-hide-add,
.admin__main__settings.ng-hide-remove {
  transition: all linear 0.2s;
}
.admin__main__settings.ng-hide {
  opacity: 0;
}
.admin__main__settings > .icon-common_close {
  color: #cacaca;
  cursor: pointer;
  font-size: 13px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
}
.admin__main__settings > .icon-common_close:hover {
  color: #767676;
}
.admin__main__settings .selectize-control .selectize-dropdown .dropdown-list > ul > li header > div.empty {
  margin-left: 2px;
}
.admin__main__settings__header {
  display: flex;
  align-items: center;
  position: relative;
  font-family: 'Roboto-Regular', Arial;
}
.admin__main__settings__header .photo {
  flex: 0 1 80px;
  width: 80px;
  min-width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 16px;
  background-color: #e8e8e8;
  color: #fff;
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
}
.admin__main__settings__header .photo.group-photo {
  color: #a3a3a3;
  font-size: 32px;
}
.admin__main__settings__header .photo.channel-icon {
  background: #cacaca;
  color: #fff;
  font-size: 40px;
}
.admin__main__settings__header > main {
  color: #222;
  margin-left: 16px;
  font-family: 'Roboto-Medium', Arial;
  font-size: 21px;
  width: calc(100% - (80px + 16px));
}
.admin__main__settings__header > main > .email {
  color: #222;
  font-family: 'Roboto-Medium', Arial;
  font-size: 21px;
  max-width: calc(100% - (2 * 12px));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.admin__main__settings__header > main > header input {
  appearance: none;
  flex: 0 1 100%;
  width: calc(100% - (80px + 12px + 20px));
  max-width: 320px;
  padding: 4px 7px;
  font-size: 16px;
  border: 2px #cacaca solid;
  border-radius: 4px;
  border-width: 1px;
  color: #222;
  resize: none;
  outline: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
}
.admin__main__settings__header > main > header input.has-error,
.admin__main__settings__header > main > header input.has-error:focus {
  border-color: #f75f5f;
}
.admin__main__settings__header > main > header input:focus {
  outline: none;
  border-color: #2e9df0;
  border-width: 2px;
}
.admin__main__settings__header > main > header > label.invisible {
  visibility: hidden;
}
.admin__main__settings__header > main > header span {
  display: block;
  margin-top: 6px;
  margin-bottom: -12px;
  font-size: 12px;
}
.admin__main__settings__header > main > header span + span {
  margin-top: 10px;
}
.admin__main__settings__header > main > header span.input-hint--info {
  color: #2e9df0;
}
.admin__main__settings__header > main > header span.input-hint--error {
  color: #f75f5f;
}
.admin__main__settings__header > main > span {
  font-size: 12px;
  color: #959595;
  display: block;
  font-family: 'Roboto-Regular', Arial;
  margin-top: 4px;
}
.admin__main__settings__header > main > ul {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.admin__main__settings__header > main > ul.group-actions {
  margin-top: 16px;
}
.admin__main__settings__header > main > ul li + li {
  margin-left: 8px;
}
.admin__main__settings__info {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: #959595;
  margin: 24px 0 0;
  font-family: 'Roboto-Regular', Arial;
}
.admin__main__settings__info > .ava-wrapper {
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}
.admin__main__settings__info > .ava-wrapper .user-tooltip {
  visibility: visible;
  display: none;
  left: -20px;
}
.admin__main__settings__info > .ava-wrapper .user-tooltip .arrow {
  left: 24px;
  top: -9px;
}
.admin__main__settings__info > .ava-wrapper .user-tooltip span {
  font-family: 'Roboto-Regular', Arial;
  font-size: 12px;
  color: #222;
}
.admin__main__settings__info > .ava-wrapper:hover .user-tooltip {
  display: table;
}
.admin__main__settings__info > span {
  color: #222;
}
.admin__main__settings__info .invite-label {
  color: #959595;
}
.admin__main__settings__info .invite-label .invited-by-name {
  color: #222;
}
.admin__main__settings__info .invite-link-expired {
  display: inline-block;
  padding: 2px 0;
  line-height: 1;
  font-size: 12px;
  vertical-align: middle;
  color: #959595;
}
.admin__main__settings__info .btn-invite-link {
  margin-left: -4px;
  padding: 2px 4px;
  line-height: 1;
  height: auto;
  font-size: 12px;
}
.admin__main__settings > .manage-roles {
  color: #959595;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: 'Roboto-Regular', Arial;
  margin-top: 24px;
}
.admin__main__settings > .manage-roles a {
  color: #2e9df0;
  margin-left: 8px;
  font-family: 'Roboto-Regular', Arial;
}
.admin__main__settings > .manage-roles > span {
  color: #222;
  position: relative;
}
.admin__main__settings > .manage-roles > span:hover .tooltip-info {
  visibility: visible;
}
.admin__main__settings > .manage-roles > span .tooltip-info {
  position: absolute;
  display: table;
  color: #959595;
  visibility: hidden;
  padding: 0;
  margin: 0;
  z-index: 5;
  left: -40px;
  top: 25px;
  width: 230px;
  align-items: center;
  background: #fff;
  padding: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.admin__main__settings > .manage-roles > span .tooltip-info:after {
  background: #fff;
  display: block;
  height: 16px;
  width: 16px;
  border: 1px solid #f6f6f6;
  border-top: none;
  border-left: none;
  transform: rotate(225deg);
  top: -8px;
  left: 60px;
  position: absolute;
  content: '';
}
.admin__main__settings__groups,
.admin__main__settings__users {
  margin: 24px 0 0;
}
.admin__main__settings__groups .errors,
.admin__main__settings__users .errors {
  margin-top: 0;
}
.admin__main__settings__groups > header,
.admin__main__settings__users > header {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: #959595;
  font-family: 'Roboto-Regular', Arial;
}
.admin__main__settings__groups > header .icon-task_field_save_ring,
.admin__main__settings__users > header .icon-task_field_save_ring {
  animation: rotate 0.3s linear infinite;
}
.admin__main__settings__groups > header a,
.admin__main__settings__users > header a {
  color: #2e9df0;
  margin-left: 8px;
  font-family: 'Roboto-Regular', Arial;
}
.admin__main__settings__groups > header a.disabledLink,
.admin__main__settings__users > header a.disabledLink {
  color: #D3D3D3;
  cursor: not-allowed;
}
.admin__main__settings__groups > header .text,
.admin__main__settings__users > header .text {
  color: #222;
}
.admin__main__settings__groups > .group,
.admin__main__settings__users > .group,
.admin__main__settings__groups > .user,
.admin__main__settings__users > .user {
  display: flex;
  align-items: center;
  padding-right: 8px;
  border-radius: 8px;
  cursor: default;
  font-family: 'Roboto-Regular', Arial;
  margin-top: 8px;
  font-size: 12px;
}
.admin__main__settings__groups > .group:first-child,
.admin__main__settings__users > .group:first-child,
.admin__main__settings__groups > .user:first-child,
.admin__main__settings__users > .user:first-child {
  margin-top: 12px;
}
.admin__main__settings__groups > .group .avatar-small,
.admin__main__settings__users > .group .avatar-small,
.admin__main__settings__groups > .user .avatar-small,
.admin__main__settings__users > .user .avatar-small {
  margin-right: 4px;
}
.admin__main__settings__groups > .group span:not(.text),
.admin__main__settings__users > .group span:not(.text),
.admin__main__settings__groups > .user span:not(.text),
.admin__main__settings__users > .user span:not(.text) {
  line-height: 24px;
  width: 24px;
  border-radius: 8px;
  text-align: center;
  background-color: #e8e8e8;
  color: #a3a3a3;
  display: inline-block;
  margin-right: 4px;
  cursor: default;
  font-family: 'Roboto-Regular', Arial;
}
.admin__main__settings__channel .notifications {
  font-size: 12px;
  margin: 20px 0;
}
.admin__main__settings__channel .notifications header {
  color: #959595;
  margin: 16px 0;
}
.admin__main__settings__channel .notifications header .status .saving i {
  animation: rotate 0.3s linear infinite;
}
.admin__main__settings__channel .notifications header .status .saved {
  color: #4abc3b;
}
.admin__main__settings__channel .notifications .notification label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.admin__main__settings__channel .notifications .notification label .checkbox {
  margin-left: 0;
}
.user-in-group {
  display: flex;
}
.user-in-group .mobile-layout {
  flex-direction: column;
}
.user-in-group .mobile-layout .group-users {
  margin-right: 0 !important;
}
.user-in-group .mobile-layout .all-users {
  margin: 16px 0 64px 0 !important;
}
@media (min-width: 767px) and (max-width: 1023px) {
  .user-in-group {
    flex-direction: column;
  }
  .user-in-group .group-users {
    margin-right: 0 !important;
  }
  .user-in-group .all-users {
    margin: 16px 0 64px 0 !important;
  }
}
@media (max-width: 414px) {
  .user-in-group {
    flex-direction: column;
  }
  .user-in-group .group-users {
    margin-right: 0 !important;
  }
  .user-in-group .all-users {
    margin: 16px 0 64px 0 !important;
  }
}
.user-in-group > div {
  width: 100%;
}
.user-in-group > div .scroll-wrapper {
  position: relative;
}
.user-in-group > div .scroll-wrapper .ps-scrollbar-y,
.user-in-group > div .scroll-wrapper .ps-scrollbar-y-rail {
  width: 4px;
  background-color: #e1ebef;
  border-color: #e1ebef;
}
.user-in-group > div .scroll-wrapper .ps-scrollbar-y {
  background-color: #e1ebef;
  border-color: #e1ebef;
}
.user-in-group > div .scroll-wrapper .ps-scrollbar-y-rail {
  background-color: transparent;
  border-color: transparent;
}
.user-in-group > div .user-info {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  padding-right: 8px;
  cursor: pointer;
  font-size: 14px;
  word-break: break-all;
  user-select: none;
}
.user-in-group > div .user-info .thumbnail {
  line-height: 24px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 8px;
  text-align: center;
  text-transform: uppercase;
  margin-right: 4px;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-in-group > div .user-info .thumbnail span {
  font-size: 10px;
}
.user-in-group > div .user-info:focus {
  outline: none;
}
.user-in-group .title {
  padding-bottom: 4px;
  font-family: 'Roboto-Medium', Arial;
  font-size: 12px;
}
.user-in-group ul {
  padding: 0 8px;
}
.user-in-group ul li {
  margin: 8px 0;
}
.user-in-group .empty {
  display: flex;
  align-items: center;
}
.user-in-group .empty > p {
  width: 100%;
  text-align: center;
  padding: 0 32px;
  color: #959595;
  font-size: 12px;
  font-family: 'Roboto-Regular', Arial;
  user-select: none;
}
.user-in-group .empty.empty-group {
  border: 1px solid #cacaca;
  border-radius: 4px;
  height: 230px;
}
.user-in-group .empty.empty-users {
  height: 100%;
}
.user-in-group .group-users {
  margin-right: 4px;
}
.user-in-group .group-users .title {
  display: flex;
}
.user-in-group .group-users .title > h4,
.user-in-group .group-users .title > a {
  flex: 1;
  font-family: 'Roboto-Medium', Arial;
}
.user-in-group .group-users .title .clear-all {
  text-align: right;
  font-family: 'Roboto-Regular', Arial;
  color: #2e9df0;
}
.user-in-group .group-users .scroll-wrapper {
  border: 1px solid #cacaca;
  border-radius: 4px;
  height: 230px;
}
.user-in-group .group-users .scroll-wrapper ul {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.user-in-group .group-users .scroll-wrapper ul li:hover {
  background-color: #e8e8e8;
  border-radius: 8px;
  transition: all 0.2s ease 0.1s;
}
.user-in-group .group-users .scroll-wrapper ul li > div {
  display: flex;
  align-items: center;
}
.user-in-group .group-users .scroll-wrapper ul li > div > div {
  flex: 1;
}
.user-in-group .group-users .scroll-wrapper ul li > div .remove-from-group {
  text-align: right;
  font-size: 8px;
  color: #a3a3a3;
  padding-right: 8px;
}
.user-in-group .all-users {
  margin-left: 4px;
}
.user-in-group .all-users .list {
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 4px;
  height: 230px;
  position: relative;
  overflow: hidden;
}
.user-in-group .all-users .list.searchFocus {
  border: 1px solid #2e9df0;
  box-shadow: 0 0 0 1px #2e9df0;
}
.user-in-group .all-users .list .search {
  position: absolute;
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 1;
  border-bottom: 1px solid #cacaca;
}
.user-in-group .all-users .list .search .search-input {
  width: 100%;
  border: none;
  padding: 4px;
  height: 32px;
  background: transparent;
}
.user-in-group .all-users .list .search .search-input:focus {
  outline: none;
}
.user-in-group .all-users .list .search .icon-header_search {
  margin: 0 4px 0 8px;
}
.user-in-group .all-users .list .scroll-wrapper {
  overflow: hidden;
  height: calc(100% - 32px);
  position: relative;
  margin-top: 32px;
}
.user-in-group .all-users .list .scroll-wrapper ul {
  padding: 0 8px;
}
.user-in-group .all-users .list .scroll-wrapper ul li > div {
  display: inline-block;
}
.user-in-group .all-users .list .scroll-wrapper ul li > div.isActive .user-info {
  background-color: #e8e8e8;
}
.user-in-group .all-users .list .scroll-wrapper ul li > div .user-info span.highlighted {
  color: #F9D846;
}
.user-in-group .all-users .list .scroll-wrapper ul.mouseHoverHighlightActive li > div.isActive .user-info {
  background-color: transparent;
}
.user-in-group .all-users .list .scroll-wrapper ul.mouseHoverHighlightActive li > div:hover .user-info {
  background-color: #e8e8e8;
  transition: all 0.2s ease 0.1s;
}
.integrations {
  width: 100%;
  height: 100%;
  padding: 48px;
  border: 1px solid #e1ebef;
  max-height: calc(100% - (4 * 16px));
  overflow: auto;
}
.integrations__api {
  margin-bottom: 2em;
}
.integrations__api .content {
  border: 1px solid #DADFE2;
  border-radius: 3px;
  padding: 1em;
  margin-bottom: 1em;
  min-height: 150px;
}
.integrations__api .content .form-group {
  margin: 0;
  align-items: normal;
}
.integrations__api .content .form-group label,
.integrations__api .content .form-group .label {
  min-width: 170px;
  flex: 0 1 170px;
  padding-right: 16px;
  padding-top: 8px;
  word-wrap: break-word;
  font-family: 'Roboto-Medium', Arial;
}
.integrations__api .content .form-group .input-read-only {
  background: #f5f5f5;
  color: #222;
  border: none;
  font-family: 'Roboto-Regular', Arial;
  border-radius: 4px;
  padding: 8px 7px;
  min-height: 30px;
  word-break: break-all;
}
.integrations__api .content .form-group .info {
  font-size: 12px;
  color: #959595;
  padding: 8px;
}
.integrations__api .content .form-group .actions {
  padding: 6px 8px;
}
.integrations__api .content .form-group .actions .btn {
  opacity: 0;
  transition: opacity 0.2s;
  color: #a3a3a3;
}
.integrations__api .content .form-group .actions .btn:hover {
  color: #2e9df0;
}
.integrations__api .content .form-group .actions__mobile {
  vertical-align: bottom;
}
.integrations__api .content .form-group .actions__mobile .btn {
  color: #a3a3a3;
}
.integrations__api .content .form-group .actions__mobile .btn:hover {
  color: #2e9df0;
}
.integrations__api .content .form-group:hover .actions .btn {
  opacity: 1;
}
.integrations__api .content .form-actions {
  margin-top: 16px;
  margin-left: 170px;
}
.integrations__api .content .form-actions button + button {
  margin-left: 10px;
}
.integrations__api .content .empty-state {
  text-align: center;
  padding-top: 40px;
}
.integrations__api .content .empty-state p {
  font-size: 14px;
  color: #959595;
  padding-bottom: 8px;
}
.integrations__api .header,
.integrations__zapier .header {
  padding: 0 12px 12px;
  position: relative;
}
.integrations__api .header .logo,
.integrations__zapier .header .logo {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 0;
  left: 12px;
  vertical-align: top;
}
.integrations__api .header .title,
.integrations__zapier .header .title {
  font-size: 18px;
  margin-left: 58px;
  vertical-align: top;
  width: 100%;
}
.integrations__api .header .summary,
.integrations__zapier .header .summary {
  font-size: 14px;
  margin-top: 6px;
  margin-left: 58px;
  vertical-align: top;
  color: #959595;
}
@media all and (max-width: 414px) {
  .integrations__api .header .logo,
  .integrations__zapier .header .logo {
    position: static;
    float: left;
    display: inline-block;
    margin-right: 12px;
  }
  .integrations__api .header .summary,
  .integrations__zapier .header .summary {
    margin-left: 0;
  }
}
@media all and (max-width: 414px) {
  .integrations__api .logo {
    margin-bottom: 15px;
  }
  .integrations__api .form-group {
    flex-wrap: wrap;
  }
  .integrations__api .form-group label,
  .integrations__api .form-group .label {
    width: 100%;
    padding-bottom: 8px;
  }
  .integrations__api .form-actions {
    margin-left: 0 !important;
  }
}
