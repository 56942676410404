@import "../../../less/variables.less";

@media print {
  body.with-process-print-view * {
    visibility: hidden;
  }

  .with-process-print-view .process-print-view, .with-process-print-view .process-print-view * {
    visibility: visible;
  }

  .process-print-view {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.process-print-view {
  h1 {
    font-size: 26px;
    word-break: break-all;
  }

  .section {
    margin-top: 10px;
    margin-bottom: 20px;

    h2 {
      break-after: avoid;
      margin-bottom: 10px;
      border-bottom: 1px solid black;
    }

    h2, h4 {
      small {
        color: #999;
      }
    }

    h3 {
      font-family: @font-family-medium;
    }
  }

  .status {
    .status-field {
      display: flex;
      line-height: 20px;
      font-size: 14px;
      margin-bottom: 4px;

      label {
        font-family: @font-family-medium;
        flex: 0 0 170px;
      }
    }
  }

  .actors {
    .group, .user {
      display: inline-flex;
      line-height: 20px;
      margin-right: 5px;
      height: 20px;
      align-items: center;
    }

    .group {
      border: 1px solid #ddd;
      border-radius: 4px;
      padding-right: 4px;

      &.muted {
        border-color: transparent;
        color: #999;
      }
    }

    .avatar-small {
      color: #a3a3a3;
      width: 20px !important;
      height: 20px !important;
      min-width: unset !important;
      line-height: 20px;
      background: transparent;
    }
  }

  ul, ol {
    margin: 0;
    padding: 5px 0 5px 30px;

    .item {
      position: relative;
      margin-bottom: 5px;
      font-size: 14px;

      .item-icon {
        position: absolute;
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        left: -25px;

        .rectangle {
          display: inline-block;
          border: 2px #a3a3a3 solid !important;
          border-radius: 4px;
          width: 10px;
          height: 10px;
          min-width: 0;
          box-sizing: content-box;
          margin: 0;
          padding: 0;
        }

        .icon-menu_checkmark {
          font-size: 10px;
          color: #a3a3a3;
        }

        .icon-template_folder {
          font-size: 10px;
          color: #a3a3a3;
        }
      }

      .item-title {
        font-size: 18px;

        &.skipped {
          text-decoration: line-through;
        }
      }

      .status-field {
        margin: 2px 0;

        label {
          font-family: @font-family-medium;
          padding-right: 10px;
        }
      }
    }
  }

  .data-form {
    .fields {
      .field {
        display: flex;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 4px;

        label {
          font-family: @font-family-medium;
          flex: 0 0 170px;
        }

        .value {
          padding-left: 10px;
          //border-bottom: 1px solid #aaa;
          .muted {
            color: #999;
          }
        }
      }
    }

    .table-row {
      margin-bottom: 20px;
    }

    .data-form-section {
      margin-bottom: 20px;

      .value {
        display: flex;

        .file {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .comments {
    .comment {
      margin: 12px 0;
      padding: 0 16px 0 12px;
      border-left: 2px solid #e1ebef;

      header {
        font-size: 14px;
        margin-bottom: 4px;

        .user {
          font-family: @font-family-medium;
        }

        .date {
          font-size: 12px;
          color: #959595;
        }
      }

      p {
        margin-bottom: 4px;

        .emoji {
          font-size: 12px;
        }
      }

      .files {
        span {
          margin-left: 4px;

          i {
            font-size: 12px;
          }
        }
      }
    }
  }

  .history {
    .log {
      break-inside: avoid;
      font-size: 14px;

      td {
        line-height: 20px;
        padding: 2px 0;
      }

      .icon {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        font-size: 8px;
      }

      .datetime {
        font-family: @font-family-medium;
        white-space: nowrap;
        min-width: 170px;
      }

      .description {
      }
    }
  }
}
