.toaster-popup {
  display: inline-flex;
  align-items: center;
  .native-size;
  position: absolute;
  z-index: @z-index-content;
  background: rgba(74, 188, 59, .8);
  padding: @offset-lg 2*@offset-lg @offset-lg @offset-lg;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
  min-width: 286px;
  color: @color-white;
  cursor: default;
  right: @site-padding;
  top: @offset-xs;
  .no-focus();

  & > i {
    color: @color-white;
    .no-focus();

    &.icon-common_close {
      opacity: .7;
      position: absolute;
      top: @offset-md;
      right: @offset-md;
      font-size: @font-size-sm;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    &.custom {
      margin-right: @offset-md;
      flex: 0 1 auto;
      font-size: 22px;
    }
  }

  div {
    flex: 0 1 auto;

    header {
      color: @color-white;
      font-family: @font-family-bold;
      font-size: @font-size-sm;
      max-width: 280px;
      padding-right: 20px;
    }

    a {
      color: @color-white;
      text-decoration: underline;
      margin-top: @offset-xs;
      cursor: pointer;
      font-size: @font-size-sm;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
