@import "../../../../less/variables";
@import "../../../../less/mixins";

.template__section {
  padding: @offset-lg @site-padding 0;
  height: calc(100% - (4 * @offset-lg + 2px));
  max-height: calc(100% - (4 * @offset-lg + 2px));
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @process-section-form-group-label: 140px;

  &.process-section {
    & > .form-group > label {
      flex: 0 1 @process-section-form-group-label;
      width: @process-section-form-group-label;
    }
    .selectize-control, textarea, .process-name-control, .task-name-control {
      flex: 0 1 calc(100% - @process-section-form-group-label);
      width: calc(100% - @process-section-form-group-label);
    }
    markdown-hint > .markdown-hint {
      margin-left: @process-section-form-group-label;
    }
  }

  & > .form-group {
    &.m-start {
      flex: 1 0 auto;
    }
    & > label {
      flex: 0 1 120px;
      width: 120px;
    }
    &.active + markdown-hint {
      visibility: visible;
    }
    .selectize-control, textarea, .process-name-control, .task-name-control {
      flex: 0 1 calc(100% - 120px);
      width: calc(100% - 120px);
    }
  }

  & > .errors {
    margin-left: 120px;
    margin-top: 2px;
  }

  markdown-hint {
    visibility: hidden;

    .markdown-hint {
      margin-left: 120px;
    }
  }

  &__tabs {
    min-height: 2*@offset-lg;
    margin-bottom: @offset-md * 3;

    .btn-switcher + .btn-switcher {
      margin-left: @offset-md;
    }
  }

  & > main {
    max-height: calc(100% - 94px);
    height: 100%;
  }

  &.invalid {
    height: calc(100% - (4 * @offset-lg + 2 * @offset-lg + 2px));
    max-height: calc(100% - (4 * @offset-lg + 2 * @offset-lg + 2px));
  }

  .template-content-wrapper {
    display: flex;
    align-items: flex-start;
    max-height: calc(100% - (2 * @offset-lg + 2 * @offset-md));
    height: calc(100% - (2 * @offset-lg + 2 * @offset-md));
    position: relative;

    &.error {
      max-height: calc(100% - (2 * @offset-lg + 90px));
    }

    & > aside {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      .google-top-scroll-shadow();

      &.active {
        border: 1px solid @color-pale-blue;
      }
    }

    & > main {
      .native-size;
      max-height: 100%;
      width: 50%;
      min-width: 50%;
      flex: 0 1 50%;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-y-scrolling: touch;
      .google-top-scroll-shadow();

      &.active {
        margin-right: -(@offset-lg + 1px);
        padding-right: @offset-lg;
        height: 100%;
        position: relative;
        z-index: 1;
        border-top: 1px solid @color-pale-blue;
      }
    }

    .template-data-model {
      .bottom-offset(@page-screen-bottom-offset);
    }

    .props-container {
      width: 100%;
      max-height: 100%;
      overflow: auto;
      border: 1px solid @color-pale-blue;
      padding: 0 2*@offset-md;
      .bottom-offset(@page-screen-bottom-offset);
      @template-edit-label-width: 120px;

      .form-group {
        & > label:first-child {
          flex: 0 1 @template-edit-label-width;
          width: @template-edit-label-width;
          min-width: @template-edit-label-width;
        }
        .selectize-control {
          width: calc(100% - @template-edit-label-width);
          flex: 0 1 calc(100% - @template-edit-label-width);
        }
      }

      .hint {
        margin-left: @template-edit-label-width + @offset-xs;
        font-size: @font-size-sm;
        color: @color-secondary-copy;
      }

      .errors {
        margin-top: 2px;
        margin-left: @template-edit-label-width + @offset-xs;
      }
    }
  }

  &__process-start {
    display: flex;
    align-items: center;
    height: 4*@offset-lg;
    padding: 0 @offset-md;
    border: 1px solid @color-pale-blue;
    border-left-width: 4px;
    position: relative;
    width: 100%;
    cursor: pointer;
    font-size: @font-size-md;
    .no-focus();

    &.error {
      border-color: @color-red !important;
      .square {
        border-color: @color-red !important;
      }
      & + .template__section__tasks__task {
        border-top: 0 !important;
      }
    }

    & > .avatar {
      display: inline-block;
      background: #d6f7d3;
      text-align: center;
      margin-right: @offset-md;

      &:after {
        display: inline-block;
        content: '';
        transform: translate(5px, 2px);
        height: 2px;
        border: 7px solid transparent;
        border-right-width: 10px;
        border-left: 11px solid rgb(159, 207, 155);
      }
    }

    .square {
      height: 15px;
      width: 15px;
      right: -8px;
      z-index: 100;
      top: 50%;
      margin-top: -8px;
      transform: rotate(45deg);
      background-color: @color-white;
      position: absolute;
      border-right: 1px solid @color-blue;
      border-top: 1px solid @color-blue;
      display: none;
    }

    &.active {
      border-color: @color-blue;
      border-top: 1px solid @color-blue;
      z-index: 5;

      .square {
        display: block;
      }
    }
  }

  &__group-summary {
    display: flex;
    align-items: center;
    height: 4*@offset-lg;
    padding: 0 @offset-md;
    border: 1px solid @color-pale-blue;
    border-left-width: 4px;
    position: relative;
    width: 100%;
    cursor: pointer;
    font-size: @font-size-md;
    .no-focus();
    z-index: 0;

    &.error {
      border-color: @color-red !important;
      .square {
        border-color: @color-red !important;
      }
      & + .template__section__tasks__task {
        border-top: 0 !important;
      }
    }

    @group-icon-size: 40px;

    .group-icon {
      display: block;
      width: @group-icon-size;
      height: @group-icon-size;
      position: relative;
      margin: 0 12px 0 0;
      flex: 0 0 @group-icon-size;

      .icon-template_folder {
        position: absolute;
        top: 50%;
        left: 50%;
        color: rgb(214, 247, 211);
        font-size: 33px;
        margin-top: -17px;
        margin-left: -20px;
        z-index: 1;
      }

      .icon-template_folder_parallel {
        position: absolute;
        top: 50%;
        left: 50%;
        color: rgb(159, 207, 155);
        font-size: 13px;
        margin-top: -5px;
        margin-left: -7px;
        z-index: 2;
      }

      .icon-template_folder_sequence {
        position: absolute;
        top: 50%;
        left: 50%;
        color: rgb(159, 207, 155);
        font-size: 13px;
        margin-top: -5px;
        margin-left: -7px;
        z-index: 2;
      }
    }

    .group-name {
      width: calc(100% - 80px);
      flex: 0 1 auto;
    }

    .group-actions {
      flex: 0 1 auto;
      .action-icn {
        color: @start-btn-color;
        cursor: pointer;
        font-size: 26px;
        margin-right: 0;

        &:hover {
          color: @color-blue;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .square {
      height: 15px;
      width: 15px;
      right: -8px;
      z-index: 100;
      top: 50%;
      margin-top: -8px;
      transform: rotate(45deg);
      background-color: @color-white;
      position: absolute;
      border-right: 1px solid @color-blue;
      border-top: 1px solid @color-blue;
      display: none;
    }

    &.active {
      border-color: @color-blue;
      border-top: 1px solid @color-blue;
      z-index: 5;

      .square {
        display: block;
      }
    }

    .drop-target-content {
      display: none;
      height: 62px;
      border: 1px dashed #abccd9;
      border-radius: 4px;
      background: @color-light-blue;
      flex: 1 1 auto;
      margin: 0 10px 10px (@offset-lg * 2)+2;
      font-size: 16px;
      color: #abccd9;
      text-align: center;
      line-height: 58px;
      overflow: hidden;
    }

    &.drop-target {
      flex-wrap: wrap;
      height: 138px;

      .drop-target-content {
        display: block;
      }
    }

    .empty-state {
      position: absolute;
      height: 4 * @offset-lg;
      line-height: 4 * @offset-lg;
      left: -4px;
      right: -1px;
      top: 100%;
      margin-top: 1px;
      padding-left: 10px;
      font-size: 12px;
      text-align: center;
      color: #959595;
      background: #f5f5f5;
      z-index: 0;
      border-left: 4px solid @color-pale-blue;
      border-right: 1px solid @color-pale-blue;
    }

    .as-sortable-placeholder {
      display: none !important;
    }
  }

  &__tasks {
    .native-size;
    width: 100%;
    min-height: (4 * @offset-lg) + 2;
    background: @color-light-blue;
    border-left: 4px solid @color-pale-blue;
    border-right: 1px solid @color-pale-blue;
    margin-top: -1px;

    &.with-drag-fix {
      padding-top: 4 * @offset-lg;
    }

    &.with-bottom-placeholder {
      padding-bottom: 4 * @offset-lg;

      .as-sortable-placeholder:last-child {
        margin-bottom: -(4 * @offset-lg);
      }
    }

    &.as-sortable-dragging {
      .template__section__tasks__task,
      .template__section__tasks__group {
        box-shadow: 0 2px 8px @color-secondary-copy;
      }
    }

    &__group__dropzone {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: 30px;
      right: 100px;
      z-index: 20;
      outline: 0;

      .as-sortable-placeholder {
        display:none !important;
      }
    }

    &__task,
    &__group {
      display: flex;
      align-items: center;
      height: 4*@offset-lg;
      transition: height .2s;
      background: @color-white;
      position: relative;
      width: 100%;
      cursor: pointer;
      .no-focus();

      .drag-handler {
        margin-left: @offset-sm;
        .no-focus();
        display: none;
      }

      .group-icon {
        @group-icon-size: 40px;
        display: block;
        width: @group-icon-size;
        height: @group-icon-size;
        position: relative;
        margin: 0 12px;

        .icon-template_folder {
          position: absolute;
          top: 50%;
          left: 50%;
          color: rgb(225, 235, 239);
          font-size: 33px;
          margin-top: -17px;
          margin-left: -20px;
          z-index: 1;
        }

        .icon-template_folder_parallel {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #88afbf;
          font-size: 13px;
          margin-top: -5px;
          margin-left: -7px;
          z-index: 2;
        }

        .icon-template_folder_sequence {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #88afbf;
          font-size: 13px;
          margin-top: -5px;
          margin-left: -7px;
          z-index: 2;
        }
      }

      .items-count {
        display: inline-block;
        white-space: nowrap;
        font-size: 12px;
        color: @color-blue;
        margin-right: 12px;

        &:hover, &:focus {
          cursor: pointer;
          color: #00ccff;
        }
      }

      .action-btn {
        margin-right: 12px;

        .action-icn {
          color: @start-btn-color;
          cursor: pointer;
          font-size: 26px;
          margin-right: 0;

          &:hover {
            color: @color-blue;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .icon-template_create_dragable {
        color: @color-grey;
        cursor: pointer;
        font-size: 18px;
        margin-right: 0;

        &:hover {
          color: @color-main-copy;
        }
      }

      .assignee {
        flex: 0 1 4*@offset-lg;
        width: 4*@offset-lg;
        min-width: 4*@offset-lg;

        div, img {
          height: @thumbnail-big-size;
          line-height: @thumbnail-big-size;
          width: @thumbnail-big-size;
          border-radius: @thumbnail-big-border-radius;
          margin: 0 @offset-md;
          text-align: center;
          color: @color-white;
          font-size: @font-size-md;

          &.group {
            background-color: @color-light-grey;
            color: @color-add-grey;

            i {
              font-size: @template-group-icon-size;
              width: auto;

              &::before {
                margin: 0;
              }
            }
          }
        }
      }

      .content {
        flex: 1 0 auto;
        height: 3 * @offset-lg;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        transition: height .2s;
        width: calc(100% - 90px);

        &.due-date {
          & > .main-block {
            max-width: calc(100% - 130px);
          }
        }

        & > .main-block {
          max-width: 100%;
          flex: 0 1 100%;
          padding-right: @offset-md;

          h4 {
            font-size: @font-size-md;
            cursor: pointer;
            font-family: @font-family-regular;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            color: @color-secondary-copy;
            .no-focus();

            &.filled {
              color: @color-main-copy;
            }
          }

          p {
            font-size: @font-size-sm;
            cursor: pointer;
            display: table;
            margin-top: @offset-xs;
            color: @color-secondary-copy;
          }
        }

        & > .task-actions {
          display: flex;
          align-items: center;
          flex: 0 1 auto;
          padding-right: @offset-md;

          & > span {
            font-size: @font-size-sm;
            color: #9c9c9c;
            margin-right: @offset-xs;
            text-align: right;

            span {
              display: block;
              white-space: nowrap;

              & + span {
                margin-top: @offset-xs;
              }
            }
          }

          & .action-icn {
            color: @start-btn-color;
            cursor: pointer;
            font-size: 26px;
            margin-right: 0;

            &:hover {
              color: @color-blue;
            }

            &:focus {
              outline: none;
            }
          }
        }
      }

      .drop-target-content {
        display: none;
        height: 62px;
        border: 1px dashed #abccd9;
        border-radius: 4px;
        background: @color-light-blue;
        flex: 1 1 auto;
        margin: 0 10px 10px (@offset-lg * 2)+2;
        font-size: 16px;
        color: #abccd9;
        text-align: center;
        line-height: 58px;
        overflow: hidden;
      }

      &.drop-target {
        height: 138px;
        flex-wrap: wrap;

        .drop-target-content {
          display: block;
        }

        .conditions-marker__icon {
          display: none;
        }
      }

      .square {
        height: 15px;
        width: 15px;
        right: -8px;
        z-index: 100;
        top: 50%;
        margin-top: -8px;
        transform: rotate(45deg);
        background-color: @color-white;
        position: absolute;
        border-right: 1px solid @color-blue;
        border-top: 1px solid @color-blue;
        display: none;
      }

      &.add-task-buttons {
        margin-top: -1px;
        border: 1px solid @color-pale-blue;
        border-left-width: 4px;
        align-items: center;
        justify-content: start;
        cursor: default;
        padding: 10px 12px;
        .bottom-offset(@page-screen-bottom-offset);

        &.with-actions {
          padding-left: 34px;
        }

        button {
          display: inline-block;
          height: @thumbnail-big-size;
          line-height: @thumbnail-big-size;
          padding: 0;
          text-align: left;
          .no-focus();

          & + button {
            margin-left: 50px;
          }

          .button__icon {
            line-height: @thumbnail-big-size - 2px;
            height: @thumbnail-big-size;
            width: @thumbnail-big-size;
            overflow: hidden;
            text-align: center;
            border: 1px dashed @template-create-header-color;
            border-radius: @buttons-border-radius;
            display: inline-block;
            margin-right: @offset-md;
            color: @color-add-grey;

            i {
              font-size: @font-size-lg;
            }
          }

          .button__text {
            height: @thumbnail-big-size;
            line-height: @thumbnail-big-size - 2px;
            vertical-align: top;
            display: inline-block;
            color: rgb(157, 157, 157);
            font-family: @font-family-regular;
            font-size: @font-size-md;
            width: auto;
          }

          &.add-approval-task {
            .button__icon {
              border-radius: (@thumbnail-big-size / 2);
            }
          }

          &.add-group {
            position: relative;
            .button__icon {
              border: 0;
              border-radius: 0;
              width: @thumbnail-big-size * 1.2;
              margin-right: @offset-md * .75;

              i {
                font-size: @thumbnail-big-size * .8;
              }
            }

            &.disabled {
              background-color: transparent !important;
            }
          }

          &:not(.disabled):hover,
          &:not(.disabled):focus {
            .button__icon {
              border-color: @color-blue;
              ::after {
                border-color: @color-blue;
              }

              i {
                color: @color-blue;
              }
            }
            .button__text {
              color: @color-blue
            }
          }
        }
      }
    }


    &__item {
      position: relative;
      .no-focus();
      border: 1px solid @color-pale-blue;
      border-left-width: 4px;
      margin-left: -4px;
      margin-right: -1px;

      &.active {
        border: 1px solid @color-blue;
        border-left-width: 4px;
        z-index: 5;

        .square {
          display: block;
        }
      }

      &.error {
        border: 1px solid @color-red;
        border-left-width: 4px;
        z-index: 4;

        .square {
          border-color: @color-red !important;
        }
      }

      &.with-actions {
        .drag-handler {
          display: block;
        }

        .content {
          & > .main-block {
            max-width: calc(100% - 50px);
          }

          &.due-date {
            & > .main-block {
              max-width: calc(100% - 210px);
            }
          }
        }

        .template__section__tasks__group {
          .content {
            & > .main-block {
              max-width: calc(100% - 90px);
            }
          }
        }
      }
    }

    &__item,
    .as-sortable-placeholder {
      margin-bottom: -1px;
    }

    .as-sortable-placeholder {
      background-color: @color-light-blue;
      border-left: 4px solid @color-pale-blue;
      border-right: 1px solid @color-pale-blue;
      margin-left: -4px;
      margin-right: -1px;
    }
  }

  textarea {
    margin-bottom: 5px !important;
  }

  .task-footer {
    display: flex;
    align-items: flex-start;
    margin-top: @offset-md;
    position: relative;

    .task-actors-select {
      flex: 0 1 calc(100% - 140px);
      width: calc(100% - 140px);
      margin-top: 0;
      flex-direction: column;
      align-items: flex-start;


      .selectize-control {
        width: 100%;
        flex: 0 1 100%;
      }
      .errors {
        margin: 0;
      }
    }

    .select-wrapper {
      flex: 0 1 100%;
      width: 100%;
      padding-top: 2px;
    }
  }
}
