@import "../../../less/variables";
@import "../../../less/mixins";

.entity-icon {
  height: @thumbnail-big-size;
  width: @thumbnail-big-size;
  line-height: @thumbnail-big-size;
  border-radius: @thumbnail-big-border-radius;
  font-size: @font-size-lg;
  font-family: @font-family-semibold;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  margin-right: 6px;
  color: @color-white;
  display: inline-block;

  i {
    font-size: 24px;
    display: block;
    width: auto;
    line-height: @thumbnail-big-size;
    color: @color-asphalt-grey;
  }
}

.entity-icon-dropdown {
  .entity-icon {
    cursor: pointer;

    &:hover,
    &:focus {
      &.default {
        border-color: @color-blue;

        .icon-template_create_icon_dummy {
          color: @color-blue;
        }
      }
    }
  }

  &.readonly {
    .entity-icon {
      cursor: default;
    }
  }

  .dropdown-menu-wrapper {
    margin-top: @font-size-icon;
  }

  .icons-selector {
    width: 100%;
    max-width: @icons-select-width + 10px;

    .arrow {
      background: @color-white;
      display: block;
      height: 2*@font-size-icon;
      width: 2*@font-size-icon;
      border: 1px solid @color-light-grey;
      border-top: none;
      border-left: none;
      transform: rotate(225deg);
      left: (@icons-select-icon-size/2) - 7px;
      top: 0;
      position: absolute;
    }

    &-icons {
      padding: @offset-lg 0 0 @offset-sm;
      z-index: @z-index-over-content;
      position: relative;

      h3 {
        .native-size;
        font-family: @font-family-semibold;
        font-weight: normal;
        font-size: @font-size-sm;
        margin: 0 0 0 @offset-xs;
      }

      ul {
        .native-size;
        width: 100%;
        max-height: @icons-select-list-height;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-bottom: @offset-sm;

        li {
          padding: 0;
          margin-top: @offset-sm;
          cursor: pointer;

          &:focus {
            outline: none;
          }

          .entity-icon {
            font-size: 24px;
            width: @icons-select-icon-size + 5px;
            height: @icons-select-icon-size + 5px;
            line-height: @icons-select-icon-size + 5px;

            i {
              line-height: @icons-select-icon-size + 5px;
            }

            &.icon-Mens {
              font-size: 26px;
            }

            &.icon-Magnifi-Glass2 {
              font-size: 22px;
            }

            &::before {
              margin: 0;
            }
          }
        }
      }
    }

    &-colors {
      padding: @offset-md @offset-lg @offset-lg;
      background-color: @color-panel-grey;
      z-index: @z-index-over-content;
      position: relative;

      h3 {
        .native-size;
        font-family: @font-family-semibold;
        font-weight: normal;
        color: @color-main-copy;
        font-size: @font-size-sm;
      }

      ul {
        .native-size;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-top: @offset-sm;

        li {
          height: @icons-select-color-size;
          width: @icons-select-color-size;
          border-radius: 100%;
          cursor: pointer;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            width: @icons-select-color-size + 4px;
            height: @icons-select-color-size + 4px;
            border-radius: 100%;
            border: 1px solid transparent;
            transition: border .15s ease;
          }

          &:hover,
          &.selected {
            &:after {
              border-color: inherit;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
