.data-model-field-settings {
  @label-width: 180px;
  .modal-body {
    h4 {
      margin-top: 2*@offset-md;
      font-size: @font-size-md;
    }

    main {
      .form-group {
        position: relative;
        label {
          width: @label-width;
          flex: 0 1 @label-width;
          display: inline-block;
        }
        input {
          flex: 0 1 60px;
          //width: 60px;

          &.full-width {
            flex: unset;
            width: calc(100% - @label-width);
          }
        }
        .field-help-text {
          position: absolute;
          left: @label-width;
          top: 36px;
          color: @color-secondary-copy;
        }
        .email-data-field {
          position: relative;
          //flex: none;

          & > i {
            z-index: 2;
          }

          & > input {
            flex: unset;
          }
        }
        .dropdown-tether {
          .tether-handler {
            &.muted {
              color: @color-add-grey;
            }
          }
        }
      }

      &.money {
        .form-group {
          input[type=number],
          input[type=text] {
            flex: 0 1 180px;
          }
        }

        .price-data-field {
          width: 180px;
        }
      }

      .form-group.error {
        input, textarea {
          border-color: @color-red;
          box-shadow: none;

          &:focus, &.active {
            border-color: @color-red;
            box-shadow: 0 0 0 1px @color-red;
          }
        }
      }

      .form-group__horizontal {
        display: block;
        label {
          display: block;
          width: 100%;
          margin-bottom: 8px;
        }
        input {
          width: 100%;
        }
      }

      & .error-group {
        white-space: nowrap;
        color: @color-red;
        font-size: @font-size-sm;
        margin-top: @offset-xs;
      }

      &.yes-no {
        .form-group {
          label {
            flex: 0 1 90px;
            width: 90px;
            min-width: 90px;
          }
        }
      }

      &.money {
        .form-group {
          label {
            flex: 0 1 120px;
            width: 120px;
            min-width: 120px;
            display: flex;
            align-items: center;

            & > span {
              display: inline-block;
              width: 80px;
            }

            & > .icon-common_hint {
              margin-left: 10px;
            }
          }
          .multi-select > .multi-select-options-list {
            max-height: 150px;
          }
          &.checkbox-wrapper {
            label {
              flex: 0 1 auto;
              width: auto;
              display: inline-flex;
              align-items: center;
              min-height: 21px;
              margin: 0;
              .no-focus();

              span {
                height: 21px;
                width: 21px;
                margin-right: @offset-sm;
              }

              input {
                display: none;
              }
            }
          }
        }
      }

      &.number {
        .form-group {
          label {
            @this-label-width: 120px;
            flex: 0 1 @this-label-width;
            width: @this-label-width;
            min-width: @this-label-width;
          }

          input {
            flex: 0 1 180px;
            width: 180px;
          }

          &.checkbox-wrapper {
            label {
              flex: 0 1 auto;
              width: auto;
              display: inline-flex;
              align-items: center;
              min-height: 21px;
              margin: 0;
              .no-focus();

              span {
                height: 21px;
                width: 21px;
                margin-right: @offset-sm;
              }

              input {
                display: none;
              }
            }
          }
        }
      }

      &.number, &.formula {
        .form-group {
          &.precision {
            input {
              flex: 0 1 45px;
              width: 45px;
            }

            span {
              color: @color-secondary-copy;
              margin-left: @offset-sm;
            }
          }
        }
      }

      &.select {
        .form-group {
          flex-wrap: wrap;
          align-items: flex-start;

          @sm-label-width: 140px;

          label {
            height: 32px;
            flex: 0 1 @sm-label-width;
            width: @sm-label-width;
            min-width: @sm-label-width;
            display: inline-flex;
            align-items: center;

            & > span {
              display: inline-block;
              width: @sm-label-width - 40;
            }

            & > .icon-common_hint {
              margin-left: 10px;
            }
          }

          input {
            &.full-width {
              width: calc(100% - @sm-label-width);
            }
          }

          .control-wrapper {
            width: 100%;
            max-width: calc(100% - @sm-label-width);
          }

          .textarea-wrapper {
            position: relative;
            flex: 0 1 100%;
            width: 100%;
            min-height: 100px;
            max-height: 100px;
            overflow: auto;
            .input-border;
            border-width: 1px;
            border-radius: 4px;
            cursor: pointer;
            box-shadow: none !important;
            padding-bottom: @offset-sm;

            &.active {
              border-color: @color-blue;
              border-width: 1px;
              box-shadow: 0 0 0 1px @color-blue !important;
            }

            textarea {
              color: @color-main-copy;
              border: 0;
              white-space: pre;
              word-wrap: normal;
              line-height: 14px;
              font-size: 12px;
              min-height: 84px;
              min-width: 100%;
              appearance: none;
              outline: 0;
              display: block;
              z-index: 1;
              position: relative;
              background: transparent;
              box-sizing: border-box;
              width: auto;
              max-width: 10000px;
              flex: 0 1 auto;
              padding-bottom: 0;

              &:focus {
                box-shadow: none;
                outline: none;
                cursor: text;
              }
            }

            .content {
              color: @color-main-copy;
              white-space: pre;
              line-height: 14px;
              font-size: 12px;
              min-height: 84px;
              appearance: none;
              outline: 0;
              display: block;
              z-index: 1;
              position: relative;
              background: transparent;
              padding: 8px 7px;

              & > div {
                white-space: nowrap;
              }

              &:focus {
                outline: none;
                cursor: text;
              }
            }

            .content-bg {
              color: transparent;
              white-space: pre-wrap;
              line-height: 14px;
              font-size: 12px;
              min-height: 84px;
              appearance: none;
              outline: 0;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 4px;
              padding: 8px 7px;
              min-width: 100%;

              .invalid {
                color: @color-red;
                text-decoration: underline;
                white-space: nowrap;
              }
            }

            .placeholder {
              top: 8px;
              left: 8px;
              position: absolute;
              color: @color-secondary-copy;
            }
          }
        }

        .hint {
          white-space: nowrap;
          color: @color-secondary-copy;
          top: 100%;
          left: 0;
          margin-top: @offset-xs;
        }

        .error {
          color: @color-red;
          top: 100%;
          left: 0;
          margin-top: @offset-xs;
        }

        .source-table-data {
          margin-top: 24px;
          overflow: scroll;
          min-height: 100px;
          max-height: 100px;
          padding: 8px 7px;
          background-color: @color-panel-grey;
          color: @color-add-grey;
          line-height: 14px;
          font-size: 12px;
          .input-border;
          border-width: 1px;
          border-radius: 4px;
        }

        .source-type-selector {
          dropdown-tether {
            height: 32px;
          }
          .dropdown-tether {
            .tether-handler {
              min-width: 150px;
            }
          }
        }
      }

      &.user {
        .user-field-settings,
        react-user-select {
          flex: 0 1 260px;
        }
      }
    }

    .field-description {
      .form-group {
        flex-wrap: wrap;

        label {
          margin-bottom: @offset-sm;
        }
      }
    }

    .formula {
      .form-group {
        flex-wrap: wrap;

        label {
          margin-bottom: @offset-sm;
        }

        expression {
          width: 100%;
        }
      }
    }
  }
  .modal-footer {
    margin-top: 2*@offset-md;
  }
}
