@import "../../../less/variables";
@import "../../../less/mixins";

.dropzone {
  padding: 8px 7px;
  min-height: 30px;
  border: 1px dashed rgb(204, 204, 204);
  border-radius: 4px;
  color: @color-add-grey;
  outline: none;
  overflow: hidden;
  display: flex;
  min-width: 240px;

  .icon-task_attachment {
    color: rgb(204, 204, 204);
  }

  .placeholder {
    margin-left: 4px;
  }

  ul {
    width: calc(100% - (@offset-xs + 14px));
    display: flex;
    flex-wrap: wrap;
    //padding: 2px;
    margin: -4px 0 -8px 4px;
    .no-focus();

    li {
      display: inline-flex;
      align-items: center;
      font-size: @font-size-sm;
      font-family: @font-family-semibold;
      padding: @offset-xs @offset-sm;
      border-radius: 8px;
      background-color: @color-light-grey;
      margin: 0 @offset-xs @offset-xs 0;
      max-width: 100%;
      .no-focus();

      a, span {
        //max-width: 100px;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      i {
        &::before {
          margin: 0;
        }

        &.icon-attachment_document {
          font-size: @font-size-md;
          margin-right: @offset-xs;
          color: @color-blue;
        }

        &.icon-common_close {
          margin-left: @offset-xs;
          font-size: @font-size-icon;
          cursor: pointer;
          color: @color-add-grey;
          .no-focus();

          &:hover {
            color: @color-main-copy;
          }
        }
      }

      &.uploading {
        color: @color-white;
        .animated-progress-bar-stripes(@color-green);

        i.icon-attachment_document,
        i.icon-common_close {
          color: @color-white;
          .no-focus();
        }
      }
    }
  }

  &.limited {
    background: @color-panel-grey;
    border: none;

    //& + .files-error {
    //  display: none;
    //}

    &:hover {
      border-color: inherit;
      cursor: default;

      .icon-task_attachment {
        color: @color-grey;
        cursor: default;
      }
    }

    .placeholder {
      display: none;
    }
  }

  &.active {
    &.accept {
      border-color: @color-blue;

      .icon-task_attachment {
        color: @color-blue;
      }
    }
  }

  &:hover:not(.limited):not(.disabled):not(.active) {
    border-color: @color-blue;
    cursor: pointer;

    .icon-task_attachment {
      color: @color-blue;
    }
  }
}

.dropzone-hint {
  font-size: 12px;
  color: @color-secondary-copy;
  white-space: nowrap;
  margin-top: @offset-xs;
  margin-left: @offset-xs;
}
