@import "variables";
@import "mixins";

.resizable {
  position: relative;
  overflow-x: hidden;
}
.resizable.no-transition {
  transition: none !important;
}

.rg-right, .rg-left, .rg-top, .rg-bottom {
  display: block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  position: absolute;
  z-index: 1;
  user-select: none;
  background: transparent;
}
.rg-right span, .rg-left span, .rg-top span, .rg-bottom span {
  position: absolute;
  box-sizing: border-box;
  display: block;
  border: 1px solid @color-secondary-copy;
}

.rg-right span, .rg-left span {
  border-width: 0 1px;
  top: 50%;
  margin: -10px 0 0 3.5px;
  height: 20px;
  width: 7px;
}

.rg-top span, .rg-bottom span {
  border-width: 1px 0;
  right: -2px;
  margin: 3.5px 0 0 -10px;
  width: 14px;
  transform: rotate(-45deg);
  height: 5px;
  bottom: 2px;
}

.rg-top {
  cursor: row-resize;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: -14px;
}

.rg-right {
  cursor: col-resize;
  height: 100%;
  right: 0;
  top: 0;
  margin-right: -14px;
}

.rg-bottom {
  cursor: pointer;
  width: 100%;
  bottom: 0;
  left: 0;
}

.rg-left {
  cursor: col-resize;
  height: 100%;
  left: 0;
  top: 0;
  margin-left: -14px;
}
