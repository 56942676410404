@import "variables";
@import "mixins";

.form-group {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2*@offset-md;
  font-size: @font-size-sm;

  &.m-start {
    align-items: flex-start;

    label {
      margin-top: @offset-sm;
    }

    & > label {
      display: block;
    }
  }

  &.has-error {
    & > input {
      border-color: @color-red !important;
      box-shadow: 0 0 0 1px @color-red !important;
    }
  }

  label {
    flex: 0 1 @label-base-width;
    min-width: @label-base-width;
    display: block;
  }

  input[type=text],
  input[type=time],
  input[type=tel],
  input[type=email],
  input[type=password],
  input[type=number],
  textarea {
    appearance: none;
    flex: 0 1 100%;
    width: 100%;
    max-width: @input-max-width;
    padding: 8px 7px;
    .input-border;
    border-width: 1px;
    box-shadow: none;
    color: @color-main-copy;
    resize: none;
    outline: 0;
    cursor: pointer;

    &:disabled {
      background-color: @color-panel-grey;
      cursor: default;
      user-select: all;
    }
    &:read-only {
      user-select: all;
    }

    &::-ms-clear {
      display: none;
    }

    &:focus, &.active  {
      outline: none;
      border-color: @color-blue;
      border-width: 1px;
      box-shadow: 0 0 0 1px @color-blue ;
      cursor: text;

      &:disabled,&:read-only {
        box-shadow: none ;
      }
    }
  }

  .selectize-control {
    max-width: @input-max-width;
  }

  textarea {
    min-height: @textarea-min-height;
  }
}

input[type="time"]::-webkit-clear-button {
  display: none;
}
input[type="time"]::-ms-clear {
  display: none;
}

ng-form {
  &:focus {
    outline: none;
  }
}

.form {
  &:focus {
    outline: none;
  }
  & > footer {
    margin: 2*@offset-md 0 0;
    display: flex;
    align-items: center;

    .btn:first-child {
      margin-left: @label-base-width;
    }
  }

  .input-hint--error {
    color: @color-red;
    display: inline-block;
    margin: @offset-xs 0 0 @label-base-width;
  }

  .input-hint--info {
    color: @color-blue;
    font-size: @font-size-sm;
    display: inline-block;
    margin: @offset-xs 0 0 @label-base-width;
  }
}

// MBPM-2219: Hide contacts button inside input (Mac OS Safari)
::-webkit-contacts-auto-fill-button, ::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}
