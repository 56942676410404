.reject-reason-modal {
  .modal-body {
    .form-group {
      margin-top: @offset-lg;
      textarea {
        min-height: 100px;
      }
    }
    .errors.main-textarea.reject-reason-modal-errors{
      text-align: left;
      padding-left: @offset-xs;
    }
  }
  .modal-footer {
    margin-top: @offset-md * 2;
  }
}