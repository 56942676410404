@import "../../../less/variables";
@import "../../../less/mixins";

.select-filter-mode {
  .handler {
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: @font-size-md;

    &:hover {
      color: @color-blue;
      border-color: @color-blue;
    }

    i {
      font-size: 16px;

      &.icon-common_arrow_down {
        font-size: 4px !important;
        margin-left: 5px !important;
      }
    }
  }
}

.drop-element {
  z-index: 1100;

  &.drop-open {
    .dropdown-menu--filter-mode {
      display: block;
    }
  }

  .dropdown-menu--filter-mode {
    display: none;
    min-width: 90px;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0,0,0,0.15);

    li {
      &.separated {
        border-top: 1px solid #e3e3e3;
      }

      a {
        font-size: @font-size-md;
        color: @color-main-copy;
        padding: 8px @offset-md 8px 25px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        min-width: 105px;

        &.active {
          padding-left: 8px;

          i {
            display: block;
          }
        }

        i {
          display: none;
          font-size: @font-size-icon;
          color: @color-green;
          margin-right: 6px;
        }

        &:hover {
          color: @color-blue;
        }
        &.active {
          i {
            color: @color-green;
          }
        }
      }
    }
  }
}
