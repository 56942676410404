@import "../variables";
@import "../mixins";


.link-hint-template {
  display: none;
  font-family: @font-family-semibold;
  z-index: 5;
  position: absolute;
  background: @white-color;
  align-items: center;
  height: @task-link-hint-size;
  padding: 0 8px;
  .box-shadow();

  .avatar-small {
    margin: 0 @offset-xs;
  }

  .square {
    height: @font-sm-size;
    width: @font-sm-size;
    .box-shadow();
    position: absolute;
    top: (@font-sm-size / 2) * -1;
    right: calc(50% - 5px);
    transform: rotate(45deg);
    background: @white-color;
  }

  .top-line {
    height: 9px;
    .top-line();
  }

  &__header {
    font-size: @font-sm-size;
    color: @site-menu-color-hov;
    margin-bottom: -3px;
    font-family: @font-family-semibold;

    span {
      font-size: @font-xs-size;
      color: @main-task-link-color;
      white-space: nowrap;
    }
    & > span {
      margin-left: 3px;
    }
  }

  &__content {
    .inline-flex;
    padding-right: 12px;

    span {
      flex: 0 1 100%;
      display: table;
      font-size: @font-xs-size;
      color: @main-task-link-color;
    }
  }
}
