@import "../../../../less/variables";
@import "../../../../less/mixins";

.tasks__list {
  flex: 0 1 @tasks-list-size;
  width: @tasks-list-size;
  max-width: @tasks-list-size;
  border-left: 1px @tasks-list-border-color solid;
  height: 100%;
  position: relative;
  overflow-x: hidden;

  .task-process-section-header--empty {
    padding-left: @offset-md;
  }

  .task-duedate-section-header {
    padding-left: @offset-md;
  }

  &.ng-hide-add {
    transition: all .15s ease-out;
    max-width: @tasks-list-size;
  }
  &.ng-hide {
    max-width: 0;
  }

  &__header {
    font-family: @font-family-medium;
    height: 4*@offset-lg - 1px;
    padding: 0 15px 0 25px;
    .inline-flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px @tasks-list-border-color solid;

    h3 {
      color: @site-menu-color-hov;
      font-size: @font-text-size;
      font-weight: normal;
      white-space: nowrap;
    }

    & > div {
      display: inline-flex;
      align-items: center;

      & > i {
        color: @border-button-gray-color;
        cursor: pointer;
        font-size: @font-size-close-btn;
        margin-left: @offset-md;
        .no-focus();

        &:hover {
          color: @color-dark-grey;
        }
      }
    }
  }

  &__empty-state {
    top: 50%;
    position: absolute;
    margin-top: -40px;
    width: 100%;

    div {
      text-align: center;

      img {
        opacity: .2;
      }

      h5 {
        margin-top: @offset-sm;
        font-size: @font-size-sm;
      }
    }
  }

  &__content {
    height: 100%;
    max-height: calc(100% - (4 * @offset-lg));
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .google-top-scroll-shadow();
    .bottom-offset(@lists-screen-bottom-offset);

    react-available-tasks-list {
      width: 100%;
      display: table;
      table-layout: fixed;
    }

    &__item {
      margin-top: -1px;
      height: @main-task-size + 1px;
      border: 1px @tasks-list-border-color solid;
      border-left: none;
      display: flex;
      align-items: center;
      padding: 0 11px 0 @offset-md * 2;
      position: relative;
      .no-focus();

      .assignToMeVisibleMixin() {
        .process-link span.process-label span.action,
        span.created-by span.action,
        .task-name h4 span.action{
          visibility: visible !important;
        }
      }
      &:hover {
        .assignToMeVisibleMixin();
      }

      &.isMobile{
        .assignToMeVisibleMixin();

      }
      &:not(.small) .wrap-table.task-name{
        margin-top: @offset-xs;
      }

      &.small {
        height: 48px;
      }

      &.hidden {
        display: none;
      }

      .icon-task_grab {
        font-size: @checkbox-size;
        color: #bbb;
        cursor: pointer;
        user-select: none;
        margin: 0;
        margin-right: @offset-md;
        flex: 0 1 auto;

        &:hover {
          color: @color-blue;
        }
        &:focus {
          outline: none;
        }
      }

      &.animated {
        & > div > .wrap-table {
          h4:first-child span:hover {
            color: @color-blue;
            cursor: pointer;
          }
        }
      }

      & > div {
        font-family: @font-family-semibold;
        flex: 0 1 100%;
        max-width: calc(100% - 36px);

        & > .wrap-table {
          display: table;
          table-layout: fixed;
          white-space: nowrap;
          width: 100%;

          &.descr {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          h4 {
            color: @site-menu-color-hov;
            font-size: @font-text-size;
            margin: 0;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            vertical-align: middle;
            .no-focus();
          }

          span {
            clear: both;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .assignToMeMixin() {
            display: inline-block;
            vertical-align: middle;
            &.action {
              visibility: hidden;
              span {
                color: @color-add-grey;
              }
              span.assign-to-me {
                white-space: nowrap;
                color: @color-blue;
                &:hover {
                  color: #00ccff;
                  cursor: pointer;
                }
              }
            }
          }
          .wrapAssignToMeMixin() {
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          &.task-name h4 {
            white-space: normal;
            & > span:first-child {
              .wrapAssignToMeMixin();
            }
            span {
              .assignToMeMixin();
              &.action {
                font-size: @font-size-sm;
              }
            }
          }
          &.process-link span.process-label{
              white-space: normal;
              span {
                &.process-name {
                  color: @color-add-grey;
                  .wrapAssignToMeMixin();
                  &:hover {
                    color: @color-blue;
                  }
                }
                .assignToMeMixin();
              }
          }
          &.created-by {
            white-space: normal;
            & > span:first-child {
              .wrapAssignToMeMixin();
            }
            span{
              .assignToMeMixin();
            }
          }

          &.descr {
            margin-top: @offset-xs;
            width: 100%;
            color: #a3a3a3;
            font-size: @font-sm-size;

            a {
              max-width: 100%;
              display: inline-block;
              cursor: pointer;
              color: #a3a3a3;
              font-size: @font-sm-size;

              &:focus {
                outline: none;
              }

              &:hover {
                color: @border-blue-color;

              }

              span.process-name:hover .link-hint-template {
                visibility: visible;
              }
            }

            .link-hint-template {
              top: 60px;
              max-width: 400px;
              width: calc(100% - 40px);
              left: 30px;
              display: inline-flex;
              visibility: hidden;

              .full-size-div {
                width: 100%;
              }

              .square {
                left: 15px;
              }

              &__content {
                .inline-flex;
                padding-right: 12px;
                margin-top: @offset-xs;
                width: 100%;
                max-width: 100%;

                ul {
                  flex: 0 1 100%;
                  width: 100%;
                  max-width: 320px;
                  float: left;

                  li {
                    display: inline-block;
                    list-style: none;
                    float: left;
                    height: 3px;
                    margin-right: 1px;

                    &.not-assigned:not(.in-process):not(.completed):not(.inactive):not(.approved):not(.rejected):not(.stopped-on-reject) {
                      background-clip: padding-box;
                      background-color: @color-grey;
                      background-image: linear-gradient(-45deg, rgba(255, 255, 255, .3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, .5) 75%, transparent 75%, transparent);
                      background-size: 10px 10px;
                    }
                    &:not(.inactive).in-process {
                      background-color: @colot-assigned-grey;
                    }
                    &:not(.inactive).completed, &.approved {
                      background: @color-green;
                    }
                    &:not(.inactive).issued {
                      background: @color-yellow;
                    }
                    &:not(.inactive).upcoming, &.rejected {
                      background-color: @color-red;
                    }
                    &.not-assigned.upcoming:not(.stopped-on-reject):not(.inactive) {
                      background-color: @color-red !important;
                    }
                    &:not(.inactive):not(.stopped-on-reject).upcoming.approved {
                      background-color: @color-green !important;
                    }
                    &.inactive, &.stopped-on-reject, &.upcoming.stopped-on-reject {
                      background-color: @color-white;
                      border: 1px solid @to-do-color;
                    }
                    &.upcoming.inactive {
                      background-color: @color-white;
                      border: 1px solid @color-red;
                    }
                  }
                }

                span {
                  margin-left: @offset-xs;
                  flex: 0 1 auto;
                  width: auto;
                  min-width: 40px;
                }
              }

              &__header {
                span {
                  display: inline;
                }
              }
            }
          }
        }
      }
    }
  }
}
