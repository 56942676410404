@import "../../../less/variables";
@import "../../../less/mixins";

.admin__main {
  height: 100%;

  @media only screen and (max-width: @tablet-portrait-size) {
    &__header {
      padding: 0 2*@offset-md;

      &__filters {
        & > li {
          a {
            & > .small {
              display: inline;
            }
            & > span:not(.small) {
              display: none;
            }
          }
        }
      }
    }
    &__content {
      padding: 0;
    }
    &__settings {
      padding-left: 2*@offset-md !important;
      overflow: hidden;

      &__header {
        & > .photo {
          display: none;
        }
        & > main {
          margin-left: 0 !important;
          width: 100% !important;
        }
      }
    }
    & > div {
      padding-left: 2*@offset-md;
      padding-right: 2*@offset-md;
    }
  }

  @media only screen and (max-width: @phone-landscape-size) {
    &__header {
      padding: 0 @offset-md;
    }
    &__content {
      &__user, &__group, &__channel {
        width: 100%;
        padding: 0 @offset-md;
        border-left: none;
        border-right: none;

        .square {
          display: none;
        }
      }

      &__empty-state, &__error-state {
        width: 100%;
        border-left: none;
        border-right: none;
      }
    }
    &__settings {
      padding: @offset-lg !important;
      flex: 1 0 100% !important;
      min-width: 100% !important;
      width: 100% !important;
      position: absolute !important;
      background-color: @color-white !important;
      left: 0;
      top: 64px;
      z-index: 6;
      opacity: 1 !important;

      &.ng-hide-add, &.ng-hide-remove {
        transition: all 0.15s ease !important;
      }
      &.ng-hide {
        transform: translate(100%, 0);
      }
    }

    & > div {
      padding: 0;
    }
  }

  @media only screen and (max-width: 586px) {
    &__content {
      &__user {
        &__invite {
          .invited-on {
            display: none;
          }
        }
      }
    }

    &__company {
      padding: 0 2*@offset-md 2*@offset-md;

      .form {
        .form-hint {
          margin-top: @offset-lg;
        }

        .input-hint--error {
          margin-left: 0;
        }

        .form-group {
          display: block;

          label {
            margin-bottom: 4px;
            width: 100%;
          }

          input {
            max-width: 100% !important;
          }

          .select-timezone {
            min-width: 100%;
          }
        }

        & > footer {
          .btn:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
