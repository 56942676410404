.tmpl-date-picker {
  position: relative;
  display: inline-table;
  margin-right: 2*@offset-md;
  flex: 1 0 auto;

  .handler {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: @color-secondary-copy;
    font-size: @font-size-sm;
    transition: all .15s ease;
    cursor: pointer;

    &:not(.edited):hover,
    &:not(.edited).drop-enabled {
      .icon-task_due_date_add,
      span {
        color: @color-blue;
      }
    }
    .icon-task_due_date_add {
      display: inline-block;
    }
    .icon-task_due_date_add_small {
      display: none;
    }

    &.edited {
      .icon-task_due_date_add {
        display: none;
      }
      .icon-task_due_date_add_small {
        display: inline-block;
      }
    }

    &.defined {
      span {
        color: @color-main-copy;
      }
    }

    .icon-task_due_date_add {
      font-size: 28px;
      color: @color-secondary-btn;
      margin-right: @offset-sm;
      transition: all .15s ease;
      cursor: pointer;
      margin-left: 0;

      &::before {
        margin: 0;
      }
    }

    .icon-task_due_date_add_small {
      font-size: @font-size-lg;
      margin-top: -1px;
      color: @color-secondary-btn;
      margin-right: @offset-sm;
      transition: all .15s ease;
      cursor: pointer;
      margin-left: 0;

      &::before {
        margin: 0;
      }
    }

    .icon-common_remove {
      color: @color-grey;
      font-size: @font-size-md;
      margin-left: @offset-sm;
      cursor: pointer;
      .no-focus();

      &:hover {
        color: @color-secondary-copy;
      }
    }
  }
}

.drop-element {
  &.drop-open {
    .tmpl-picker-content {
      display: block;
    }
  }

  .tmpl-picker-content {
    position: absolute;
    left: -@offset-md;
    background: @color-white;
    padding: @offset-lg;
    box-shadow: 0 2px 8px @color-secondary-copy;
    display: none;
    z-index: 10;
    margin-top: @offset-md;
    .no-focus();

    p {
      width: 100%;
      text-align: center;
      font-family: @font-family-medium;
      color: @color-main-copy;
      font-size: @font-size-sm;

      &.hint {
        color: @color-secondary-copy;
        font-family: @font-family-regular;
        line-height: @font-size-lg;
        max-width: 200px;
        margin: @offset-lg auto 0;
      }
    }

    .form-group {
      margin: @offset-md 0 0;
      white-space: normal;

      & + p {
        margin-top: @offset-md;
      }

      input {
        min-width: 60px;
        max-width: 60px;
        width: 100%;
        margin-right: @offset-sm;
        min-height: 36px;
        font-size: @font-size-lg;
        padding: 5px 7px;
      }

      ul {
        display: flex;
        align-items: center;
        white-space: nowrap;

        li {
          padding: 10px @offset-lg;
          font-size: @font-size-sm;
          font-family: @font-family-medium;
          color: @color-main-copy;
          border: 1px solid @color-grey;
          position: relative;
          z-index: 5;
          cursor: pointer;
          .no-focus();

          &.active {
            border-color: @color-blue;
            color: @color-blue;
            position: relative;
            z-index: 10;
          }

          &:first-child {
            border-bottom-left-radius: 7px;
            border-top-left-radius: 7px;
            margin-right: -1px;
          }

          &:last-child {
            border-bottom-right-radius: 7px;
            border-top-right-radius: 7px;
            margin-left: -1px;
          }
        }
      }
    }

    .arrow {
      background: @color-white;
      display: block;
      height: 2*@font-size-icon;
      width: 2*@font-size-icon;
      border: 1px solid @color-light-grey;
      border-top: none;
      border-left: none;
      transform: rotate(225deg);
      top: -@font-size-icon - 1;
      left: @offset-md + 7;
      position: absolute;
    }
  }
}