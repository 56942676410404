@import "../../../less/variables";
@import "../../../less/mixins";

.processes__main__content {
  margin: 0;
  height: 100%;
  max-height: calc(100% - (4 * @offset-lg));
  padding: 1px 12px 1px @site-padding;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  .google-top-scroll-shadow();
  .bottom-offset(@lists-screen-bottom-offset);

  .processes-list-title {
    font-size: 14px;
    padding: @offset-lg*1.5 0 @offset-lg*.5 @offset-lg;

    a {
      color: @color-blue;
      display: inline-block;
      margin-left: @offset-lg;

      &:hover, &:focus {
        cursor: pointer;
        color: #00ccff;
      }
    }
  }

  react-processes-list {
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  &__empty-state,
  &__error-state {
    border: 1px solid @color-pale-blue;
    padding: 4*@offset-lg 0;
    width: 100%;
    text-align: center;

    & > i {
      font-size: 64px;
      color: #d1d1d1;

      &.small {
        font-size: 40px;
      }

      & + h5 {
        margin-top: @offset-lg;
      }
    }

    h5 {
      color: @color-main-copy;
      font-size: @font-size-md;
      margin-top: 2*@offset-md;
    }

    p {
      margin-top: @offset-sm;
      font-size: @font-size-sm;
      color: @color-secondary-copy;

      a {
        color: @color-blue;
        cursor: pointer;
      }
    }
  }

  &__error-state {
    border-color: @color-red;
  }

  .animated {
    .processes-header {
      & > a:hover {
        cursor: pointer;
        color: @border-blue-color;
      }
    }
  }

  .processes-header {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: @offset-lg;

    &.bg-gray {
      background-color: @color-head-bg-grey;
    }

    & > span {
      font-size: @font-size-md;
      font-family: @font-family-medium;

      &.processes-count {
        font-size: @font-size-sm;
        font-family: @font-family-regular;
        color: @color-secondary-copy;
        margin-left: @offset-sm;
        margin-top: 1px;
        display: inline-block;
      }
    }

    & > a {
      font-size: @font-size-md;
      font-family: @font-family-medium;
      cursor: default;
    }

    & > .avatar-small {
      margin-right: @offset-md;
      color: @color-asphalt-grey;
      min-width: @thumbnail-size;

      i {
        font-size: @font-size-md;
        display: block;

        &.icon-Mens,
        &.icon-Magnifi-Glass2 {
          font-size: 12px;
        }
      }

      &.default {
        background-color: @color-grey;
      }
    }
  }

  &__process {
    border: 1px solid @color-pale-blue;
    height: @process-size;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 @offset-md;
    cursor: pointer;
    .no-focus();

    & + div {
      margin-top: -1px;
    }

    &__loading {
      width: 100%;
      flex: 0 1 100%;
      .inline-flex;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      cursor: default;

      .main {
        width: calc(100% - (2 * @thumbnail-size + 2 * @offset-xs + @offset-lg));
        flex: 0 1 calc(100% - (2 * @thumbnail-size + 2 * @offset-xs + @offset-lg));
        .inline-flex;
        align-items: center;
        height: 100%;

        .thumbnail {
          border: 1px solid @color-pale-blue;
          height: @thumbnail-big-size;
          width: @thumbnail-big-size;
          min-width: @thumbnail-big-size;
          flex: 0 1 @thumbnail-big-size;
          border-radius: @thumbnail-big-border-radius;
        }

        .text {
          border: 1px solid @color-pale-blue;
          width: calc(100% - (@thumbnail-big-size + @offset-lg));
          max-width: 500px;
          flex: 0 1 calc(100% - (@thumbnail-big-size + @offset-lg));
          height: 5px;
          border-radius: 5px;
          margin: 24px 0 0 @offset-lg;
        }
      }

      .assignees {
        width: 2*@thumbnail-size + @offset-xs;
        flex: 2*@thumbnail-size + @offset-xs;
        .inline-flex;
        align-items: center;
        height: 100%;
        justify-content: flex-end;

        & > .assignee {
          border: 1px solid @color-pale-blue;
          height: @thumbnail-size;
          width: @thumbnail-size;
          min-width: @thumbnail-size;
          max-width: @thumbnail-size;
          flex: 0 1 @thumbnail-big-size;
          border-radius: @thumbnail-border-radius;
          margin-left: 2*@offset-xs;
        }
      }
    }

    &__details {
      flex: 0 1 100%;
      max-width: calc(100% - 220px);
      .inline-flex;

      &__process_avatar {
        position: relative;
        margin-right: @offset-md;
        .avatar-reject {
          .reject-icon-on-avatar();
        }
        .avatar-completed {
          .completed-icon-on-avatar();
        }
        .avatar-stopped {
          .stopped-icon-on-avatar();
        }
      }

      .details-wrapper {
        width: calc(100% - (@offset-md + @thumbnail-big-size));
        margin-top: -2px;

        h4 {
          margin-bottom: -8px;
          width: 100%;

          &:hover {
            a {
              color: @color-blue;
            }
          }

          a {
            width: 100%;
            font-size: @font-size-md;
            line-height: 30px;
            cursor: pointer;
            color: inherit;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            font-family: @font-family-regular;
            .no-focus();
          }
        }

        & > div {
          .inline-flex;
          width: 100%;

          .tasks {
            .native-size;
            height: 5px;
            margin-right: 12px;
            max-width: @max-details-width;

            li {
              display: inline-block;
              height: 100%;
              cursor: pointer;
              position: relative;

              &:focus {
                outline: none;
              }
              .process-task-tooltip {
                display: none;
              }

              &:hover .process-task-tooltip {
                visibility: visible;
                display: table;
              }

              &.not-assigned:not(.in-process):not(.completed):not(.inactive):not(.approved):not(.rejected):not(.stopped-on-reject) {
                background-clip: padding-box;
                background-color: @color-grey;
                background-image: linear-gradient(-45deg, rgba(255, 255, 255, .3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, .5) 75%, transparent 75%, transparent);
                background-size: 10px 10px;
              }
              &:not(.inactive).in-process {
                background-color: @colot-assigned-grey;
              }
              &:not(.inactive).completed, &.approved {
                background: @color-green;
              }
              &:not(.inactive).issued {
                background: @color-yellow;
              }
              &:not(.inactive).upcoming, &.rejected {
                background-color: @color-red;
              }
              &.not-assigned.upcoming:not(.stopped-on-reject):not(.inactive) {
                background-color: @color-red !important;
              }
              &:not(.inactive):not(.stopped-on-reject).upcoming.approved {
                background-color: @color-green !important;
              }
              &.inactive, &.stopped-on-reject, &.upcoming.stopped-on-reject {
                background-color: @color-white;
                border: 1px solid @to-do-color;
              }
              &.upcoming.inactive {
                background-color: @color-white;
                border: 1px solid @color-red;
              }

              & + li {
                margin-left: 1px;
              }

              &:first-child {
                border-bottom-left-radius: @border-task-radius;
                border-top-left-radius: @border-task-radius;
              }

              &:last-child {
                border-bottom-right-radius: @border-task-radius;
                border-top-right-radius: @border-task-radius;
              }
            }
          }

          & > span {
            color: @color-secondary-copy;
            font-size: @font-size-sm;
            white-space: nowrap;

            & + span {
              margin-left: 15px;
            }

            &.attachments {
              color: @color-blue;
              font-size: @font-size-md;
            }
          }
        }

        & > p {
          color: @color-secondary-copy;
          font-size: @font-size-sm;
          margin-top: @offset-sm;
          margin-bottom: 2px;

          .red {
            color: @color-red
          }

          .green {
            color: @color-green;
          }

          .yellow {
            color: #D4AB00;
          }
        }
      }
    }

    &__assignees {
      flex: 1 0 auto;
      display: inline-flex;
      align-items: center;
      flex-direction: row;

      &.completed {
        display: block;
      }

      .status {
        color: @color-secondary-copy;
        font-size: @font-size-sm;
        padding: 3px 5px 3px 4px;
        float: right;
        border-radius: 10px;
        white-space: nowrap;
        text-align: right;
        flex: 1 0 auto;

        & + ul {
          margin-left: @offset-xs;
        }

        span {
          color: @color-secondary-copy;
        }

        .dueDate {
          display: block;
          text-align: right;

          span {
            color: @color-main-copy;

            &.red {
              color: @color-red;
            }
          }
        }

        &.stopped {
          background-color: @color-yellow;
          color: rgba(0, 0, 0, 0.5);

          i {
            background-color: @color-main-copy;
            color: @color-main-copy;
            opacity: 0.5;
            height: @font-size-icon;
            width: @font-size-icon;
            font-size: @font-size-icon;
            margin: 3px 2px 0 3px;
          }
        }

        i {
          font-size: @font-size-icon;
          opacity: .8;
          float: left;
          margin-top: 3px;

          &.icon-common_close, &.icon-menu_checkmark {
            margin: 2px @offset-xs 0 0;
          }

          &.icon-menu_checkmark {
            color: @color-green;
          }
          &.icon-common_close {
            opacity: 1;
            color: @color-red;
          }
        }
      }
    }
  }
}
