@import "../../../less/variables";
@import "../../../less/mixins";

.user-in-group {
  @size-list: 230px;
  @search-height: @offset-lg * 2;
  display: flex;
  .mobile-layout {
    flex-direction: column;
    .group-users {
      margin-right: 0 !important;
    }
    .all-users {
      margin: @offset-lg 0 @offset-lg * 4 0 !important;
    }
  }
  @media (min-width: @phone-landscape-size) and (max-width: @tablet-portrait-size) {
    .mobile-layout
  }
  @media (max-width: @phone-portrait-size) {
    .mobile-layout
  }
  & > div{
    width: 100%;

    .scroll-wrapper {
      .default-scroll-wrapper();
    }
    .user-info {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      border-radius: @offset-sm;
      padding-right: @offset-sm;
      cursor: pointer;
      font-size: 14px;
      word-break: break-all;
      user-select: none;
      .thumbnail {
        line-height: @thumbnail-size;
        height: @thumbnail-size;
        width: @thumbnail-size;
        min-width: @thumbnail-size;
        border-radius: @thumbnail-border-radius;
        text-align: center;
        text-transform: uppercase;
        margin-right: @offset-xs;
        color: @color-white;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 10px;
        }
      }
    }
    .user-info:focus {
      outline: none;
    }
  }
  .title {
    padding-bottom: @offset-xs;
    font-family: @font-family-medium;
    font-size: @font-size-sm;
  }
  ul {
    padding: 0 @offset-sm;
    li {
      margin: @offset-sm 0;
    }
  }
  .empty{
    display: flex;
    align-items: center;
    & > p{
      width: 100%;
      text-align: center;
      padding: 0 @offset-lg * 2;
      color: @color-secondary-copy;
      font-size: @font-size-sm;
      font-family: @font-family-regular;
      user-select: none;
    }
    &.empty-group{
      border: 1px solid @input-border-color;
      border-radius: 4px;
      height: @size-list;
    }
    &.empty-users{
      height: 100%;
    }
  }
  .group-users {
    margin-right: @offset-xs;
    .title{
      display: flex;

      & > h4, & > a{
        flex: 1;
        font-family: @font-family-medium;
      }
      .clear-all{
        text-align: right;
        font-family: @font-family-regular;
        color: @color-blue;
      }
    }
    .scroll-wrapper {
      border: 1px solid @input-border-color;
      border-radius: 4px;
      height: @size-list;
      ul {
        width: 100%;
        overflow: hidden;
        position: relative;
        li {
          &:hover {
            background-color: @color-light-grey;
            border-radius: 8px;
            transition: all .2s ease 0.1s;
          }
          & > div {
            display: flex;
            align-items: center;
            & > div {
              flex: 1;
            }
            .remove-from-group {
              text-align: right;
              font-size: 8px;
              color: @color-add-grey;
              padding-right: @offset-sm;

            }
          }
        }

      }
    }



  }
  .all-users {
    margin-left: @offset-xs;
    .list {
      &.searchFocus{
        border: 1px solid @color-blue;
        box-shadow: 0 0 0 1px @color-blue;
      }
      width: 100%;
      border: 1px solid @input-border-color;
      border-radius: 4px;
      height: @size-list;
      position: relative;
      overflow: hidden;
      .search {
        position: absolute;
        width: 100%;
        min-height: @search-height;
        display: flex;
        align-items: center;
        background-color: white;
        z-index: 1;
        border-bottom: 1px solid @input-border-color;
        .search-input {
          width: 100%;
          border: none;
          padding: @offset-xs;
          height: @search-height;
          background: transparent;
          &:focus {
            outline: none;
          }
        }
        .icon-header_search{
          margin: 0 @offset-xs 0 @offset-sm;
        }
      }
      .scroll-wrapper{
        overflow: hidden;
        height: calc(100% - @search-height);
        position: relative;
        margin-top: @search-height;
        ul {
          padding: 0 @offset-sm;
          li {
            & > div {
              display: inline-block;
              &.isActive .user-info{
                background-color: @color-light-grey;
              }
              .user-info span.highlighted{
                color: #F9D846;
              }
            }
          }
        }
        ul.mouseHoverHighlightActive li{
          & > div.isActive .user-info{
            background-color: transparent;
          }
          & > div:hover .user-info{
              background-color: @color-light-grey;
              transition: all .2s ease .1s;
          }
        }
      }
    }
  }
}
