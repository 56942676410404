@import "../../../less/variables";
@import "../../../less/mixins";

@media only screen and (max-width: @tablet-portrait-size) {
  .templates__main {
    &__template {
      &:hover {
        background: inherit !important;

        h4 {
          color: inherit !important;
        }
      }
    }
  }
  .processes__main {
    &__header {
      padding: 0 @offset-md 0 @offset-lg;
    }
    &__content {
      padding: 1px 0;

      .processes-header {
        & > a:hover {
          color: inherit;
        }
        & > span.processes-count {
          display: none;
        }
      }
      &__process {
        &__details {
          .details-wrapper > div {
            & > span {
              display: none;
            }
          }
        }
        &__assignees {
          .process-assignees-list {
            display: none;
          }
        }
      }
    }
  }
  .process {
    &__header {
      padding-right: @offset-lg !important;

      & > .icon-common_close {
        display: none;
      }
      &__main {
        &__btns {
          min-width: 50px !important;

        }
        &__content {
          width: calc(100% - 50px) !important;

          span:first-child {
            .template-name-link {
              display: none;
            }
            .template-name {
              display: inline;
            }
          }
        }
      }
    }
    &__section {
      padding: 0 !important;

      .tasks-container {
        padding: @offset-lg 0 0 !important;
        margin: 0 !important;
        height: 100% !important;
        overflow: auto;
        position: relative;
        width: 100%;
        flex: 0 1 100% !important;
        max-width: 100%;
        overflow-x: hidden;

        &__header {
          padding-left: @offset-lg !important;

          & > .dueDate {
            & > .comments-toggler {
              display: inline-block !important;
            }
          }
        }

        &__tasks {
          overflow-y: visible !important;

          &__task,
          &__group {
            border-left: none !important;
            border-right: none !important;
            width: 100% !important;

            .content {
              .action {
                display: block !important;
                span {
                  display: none;
                }
              }
            }

            &__main {
              & > .avatar {
                margin-left: @offset-lg !important;
              }
            }
          }
        }
      }

      .info {
        flex: 1 0 100% !important;
        min-width: 100% !important;
        padding: @offset-lg;
        position: absolute;
        background-color: @color-white !important;
        left: 0;
        top: 64px;
        z-index: 2;
        padding-right: @offset-md;
        height: calc(100vh - (@site-header-size + 64px)) !important;

        &.ng-hide-add, &.ng-hide-remove {
          transition: all 0.15s ease;
        }
        &.ng-hide {
          transform: translate(100%, 0);
        }

        & > .icon-common_close {
          display: block !important;
        }
      }
    }
  }
}

@media only screen and (max-width: @phone-landscape-size) {
  .processes__main {
    &__header {
      &__filters {
        li.search-block {
          &.opened {
            & > input {
              width: 180px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: @phone-portrait-size) {
  .processes__main {
    &__header {
      &__nav {
        & > li > .start-process {
          span {
            display: none;
          }
          i {
            margin-right: 0 !important;
          }
        }
      }
      &.searching {
        justify-content: flex-end;

        .processes__main__header__nav {
          display: none;
        }

        .processes__main__header__filters {
          width: 100%;
          justify-content: flex-end;
        }
      }
      &__filters {
        li.search-block {
          border: 1px @color-secondary-btn solid;
          cursor: pointer;
          border-radius: 16px;
          height: 2*@offset-lg;
          margin-left: @offset-sm;
          min-width: 36px;
          position: relative;
          transition: all .15s ease;

          &.opened {
            border: none;
            border-radius: 0;
            width: 100%;

            & > input {
              width: 100%;
              opacity: 1;
              padding: 8px 30px;
            }
            & > i {
              z-index: 1;
            }
          }

          & > input {
            width: 36px;
            opacity: 0;
            padding-left: @offset-xs;
            position: absolute;
            right: 0;
            background-color: @color-white;
          }

          & + li {
            margin-left: @offset-sm;
          }
        }
      }
    }
    &__content {
      &__process {
        &__details {
          max-width: 100%;

          .details-wrapper > div {
            .tasks {
              max-width: 100%;
              margin-right: 0;
            }
          }
        }
        &__assignees {
          display: none !important;
        }
      }
    }
  }
  .process {
    &__header__main {
      &__content .process-reject-reason {
        white-space: nowrap;
      }
      &__btns .btn-process-remove{
        display:none;
      }
    }
    &__section {
      .tasks-container {
        &__header {
          &__btns {
            .btn-switcher {
              span {
                display: none !important;
              }
              .number {
                display: inline-block !important;
              }
            }
          }
          & > .dueDate {
            .round-progress-wrapper {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 868px) {
  .processes__main {
    &__content {
      &__process {
        &__details {
          max-width: unset;
          width: 100%;

          .details-wrapper {
            width: 100%;
            overflow: hidden;
          }
        }
        &__assignees {
          display: none !important;
        }
      }
    }
  }
}
