.ui-mention-container {
  position: relative;

  [ui-mention] {
    min-height: 100px;
    background: none;
    position: relative;
    z-index: 2;
    color: transparent;
    background: transparent;
    caret-color: @color-main-copy;
  }

  #ui-mention-highlight {
    white-space: pre-wrap;
    position: absolute;
    top: 4px;
    bottom: 0;
    border-radius: 5px;
    left: 0;
    right: 0;
    padding: 10px 90px 8px 55px;
    z-index: 1;
    font-size: @font-size-md;

    @media only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (orientation: landscape) {
      left: 3px;
    }

    span {
      border-radius: 2px;
      background-color: #fff;
      color: @color-blue;
    }
  }
}
