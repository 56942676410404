@import "../../../less/variables";
@import "../../../less/mixins";

.auth-footer {
  margin-top: @offset-lg * 2;
  font-size: @font-sm-size;

  p {
    color: #a3a3a3;
    text-align: center;
  }

  a {
    display: table;
    margin: 2px auto 0 auto;
    color: @color-blue;
  }
}
