@import "variables";

.approval-default{
  @green-light: #50BE41;
  @green-dark: #378C90;
  @red-light: #F75F5F;
  @red-dark: #A64040;
  border: 2px #cacaca solid;
  border-radius: 12px;
  width: 20px;
  height: 20px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  .no-focus();

  &:hover:not(.approved):not(.rejected):not(.disabled):not(.approval-default-disabled) {
    border-color: @green-light;
    cursor: pointer;
    i {
      color: @green-light;
    }
  }
  i {
    color: #cacaca;
    font-size: @font-size-icon;
    &.icon-common_close {
      display: none;
    }
  }
  &.approved {
    border: 2px @green-dark solid;
    background-color: @green-light;
    i {
      color: @color-white;
      &.icon-menu_checkmark {
        display: inline;
      }
      &.icon-common_close {
        display: none;
      }
    }
  }
  &.rejected {
    border: 2px @red-dark solid;
    background-color: @red-light;
    i {
      color: @color-white;
      &.icon-menu_checkmark {
        display: none;
      }
      &.icon-common_close {
        display: inline;
      }
    }
  }
  &.stopped-on-reject, &.approval-default-inactive {
    border-style: dashed;
    background-color: @gray-color;
  }
  &:not(.approved):not(.rejected):not(.stopped-on-reject).disabled,
  &:not(.approved):not(.rejected):not(.stopped-on-reject).approval-default-disabled {
    background-color: @gray-color;
    cursor: not-allowed;
  }
}
.reject-reason-description{
  display: inline-block;
  background-color: @color-pale-blue;
  border-radius: 4px;
  padding: @offset-sm;
  margin-top: @offset-xs;
  font-size: @font-size-sm;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  & > span:first-child{
    font-family: @font-family-bold;
  }
}
