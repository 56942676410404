@import "../../../less/variables";
@import "../../../less/mixins";

.task-duedate-section-header {
  padding: @offset-md @offset-md @offset-md @offset-lg;
  font-size: @font-size-md;
  font-family: @font-family-medium;

  & > span {
    color: #a0a4a6;
    font-size: @font-size-sm;
    font-family: @font-family-regular;
  }

  &.bg-gray {
    background-color: @color-head-bg-grey;
  }

  &.bg-red {
    background-color: #fff7f7 !important;
  }
}
