@import "variables";
@import "mixins";

.checkbox {
  display: inline-block;
  height: @checkbox-size + 1;
  min-height: @checkbox-size;
  width: @checkbox-size;
  min-width: @checkbox-size;
  overflow: hidden;
  margin: 0 12px;
  position: relative;
  .no-focus();

  .help-tooltip-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: @checkbox-size;
    display: block;
  }

  input {
    height: 100%;
    width: @checkbox-size;
    opacity: 0;
    border: 0;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;

      & + .checkbox-control {
        background-color: @color-panel-grey;
        border-color: @color-grey;
      }

      &:hover + .checkbox-control {
        border-color: @color-grey;
      }
    }

    &.inactive + .checkbox-control {
      border-style: dashed;
    }

    &:not(.no-hover):not(.disabled):hover + .checkbox-control {
      border-color: @color-green;
    }

    &:not(.no-hover):disabled + .checkbox-control {
      background-color: @color-panel-grey !important;
      border-color: @color-grey !important;
    }

    &:focus {
      outline: none;
    }
  }

  .checkbox-control {
    height: @checkbox-size;
    width: @checkbox-size;
    display: inline-block;
    background: @color-white;
    border: 2px solid @color-grey;
    border-radius: 5px;
    transition: all .15s ease;
    text-align: center;
    position: relative;

    &.checked {
      border-color: @color-green;

      i {
        display: block;
        color: @color-green;
      }
    }

    i {
      display: none;
      font-size: 11px;
      line-height: 18px;
      margin: 0 !important;
      position: absolute;
      top: 0;
      left: 2px;
    }
  }
}

.g-radio {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: @font-size-sm;
  line-height: @font-size-md;

  & > input {
    position: absolute;
    width: 16px;
    height: 100%;
    line-height: normal;
    opacity: 0;

    &[type="radio"] + .helper {
      border-radius: 50%;
    }

    &:checked {
      & + .helper {
        border-color: @color-blue;

        &:after {
          background: @color-blue;
        }
      }
    }

    &:disabled {
      & + .helper {
        background: @color-panel-grey !important;
        border-color: #dfe3e9 !important;
        cursor: not-allowed;
      }
      &:checked + .helper:after {
        background: @color-grey !important;
      }
    }
  }

  & > .helper {
    vertical-align: middle;
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #ced0da;
    transition: all .15s ease;
    margin-right: @offset-sm;
    padding: 3px;

    &:after {
      content: '';
      border-radius: 100%;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: transparent;
      transition: all .15s ease;
    }
  }

  & > i {
    vertical-align: middle;
  }
}
