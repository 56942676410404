@import "../../../less/variables";
@import "../../../less/mixins";

.drop-element {
  z-index: 1100;

  &.drop-open {
    .dropdown-menu--field-type,
    .dropdown-menu--field-edition {
      display: block;
    }
  }

  .dropdown-menu--field-edition {
    display: none;
    min-width: 90px;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0,0,0,0.15);

    li {
      &.separated {
        border-top: 1px solid #e3e3e3;
      }

      a {
        font-size: @font-size-md;
        font-family: @font-family-regular;
        color: @color-main-copy;
        padding: @offset-sm;
        display: flex;
        align-items: center;
        white-space: nowrap;
        min-width: 105px;

        i {
          color: @color-secondary-copy;
          font-size: @font-size-lg;
          margin-right: @offset-md;
        }

        &:hover {
          color: @color-blue;
        }
        &.active {
          i {
            color: @color-green;
          }
        }
      }
    }
  }

  .dropdown-menu--field-type {
    display: none;
    min-width: 105px;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0,0,0,0.15);
    padding: 5px;

    li {
      &.separated {
        border-top: 1px solid #e3e3e3;
      }

      a {
        font-size: @font-size-sm;
        color: @color-main-copy;
        padding: @offset-sm;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:hover {
          color: @color-blue;
        }
      }
    }
  }
}
