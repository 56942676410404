@import (inline) "normalize.css/normalize.css";
@import (inline) "ng-sortable/dist/ng-sortable.css";

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../public/assets/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../../public/assets/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../public/assets/roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here node_modules/bootstrap/less/variables.less
 */

* {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: inherit;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

body > div[ui-view] {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

.icon-Magnifi-Glass2 {
  font-size: 22px;
}
.icon-Mens {
  font-size: 26px;
}

@import "typography";
@import "keyframes";
@import "base";

@import "buttons";
@import "approval";
@import "avatars";
@import "checkbox";
@import "forms";
@import "modal";
@import "radio";
@import "site-header";
@import "menu-button-more";
@import "angular-resizable";

// Modals
@import "./modals/assign-modal";
@import "./modals/confirm-alt";
@import "./modals/copy-template";
@import "./modals/crop-image";
@import "./modals/data-model-field-settings";
@import "./modals/data-model-table-full-view";
@import "./modals/data-model-table-row";
@import "./modals/delete-warnings";
@import "./modals/invite";
@import "./modals/password";
@import "./modals/reject-reason";
@import "./modals/start-process";
@import "./modals/tmpl-gallery";

// Components
@import "./components/angular-datepicker";
@import "./components/comments";
@import "./components/data-model-table";
@import "./components/date-alt-picker-drop";
@import "./components/dropdown";
@import "./components/dropdown-tether";
@import "./components/email-data-field";
@import "./components/file-data-field";
@import "./components/filter-dropdown";
@import "./components/help-tooltip";
@import "./components/link-hint-template";
@import "./components/markdown-hint";
@import "./components/mentions-wrapper";
@import "./components/multi-select-control";
@import "./components/multiselect-field";
@import "./components/notification-bar";
@import "./components/popup-actors-list";
@import "./components/prc-date-picker";
@import "./components/price-data-field";
@import "./components/process-view-data-model";
@import "./components/scroll-shadow";
@import "./components/select-default-currency";
@import "./components/selectize";
@import "./components/task-data-model";
@import "./components/template-data-model";
@import "./components/template-section-types";
@import "./components/template-task-data-model";
@import "./components/template-view-data-model";
@import "./components/time-input";
@import "./components/tmpl-date-picker";
@import "./components/tmpl-task-due-date";
@import "./components/toaster-popup";
@import "./components/ui-mention-container";
@import "./components/user-tooltip";
@import "./components/yesno-data-field";
