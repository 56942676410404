@import "../../../less/variables";
@import "../../../less/mixins";

.record-form {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: @offset-lg;
    padding-right: @offset-lg;

    .title {
      span {
        word-break: break-word;
      }

      small,
      .no-value {
        color: @color-add-grey;
      }
    }

    .actions {
      .btn.btn-icon {
        font-size: 30px;
        transition: none;
        margin-left: 8px;

        &:not(:hover):not(:focus) {
          color: #cdcdcd;
        }

        i {
          margin: 0;
        }
      }
    }
  }

  .modal-body.modal-body {
    padding-bottom: 50px;
  }
}
