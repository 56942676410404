@import "../../../less/variables";
@import "../../../less/mixins";

.expression-options {
  position: relative;
  font-size: 14px;

  .dropdown-list {
    display: none;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    background: @color-white;
    box-shadow: 0 2px 8px @color-dark-grey;

    max-height: 200px;
    overflow: auto;
    margin-top: 2px;
    padding: 10px;
  }

  &.active .dropdown-list {
    display: block;
  }

  .group {
    & + .group {
      margin-top: 8px;
    }

    .group-label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      i {
        color: #a3a3a3;
        margin-right: 3px;
        cursor: pointer;
        min-width: 20px;
      }

      span {
        display: inline-flex;
        align-items: center;
        padding: 0 8px;
        border-radius: 8px;
        cursor: pointer;
        line-height: 24px;
        color: @color-add-grey;
      }

      &:focus {
        outline: 0;
      }
    }

    &.active {
      .group-label {
        span {

        }
      }
    }
  }

  .group-items {
    margin-left: 26px;
  }

  .group-item {
    margin-top: 6px;

    .group-item-label {
      display: inline-block;
      padding: 0 8px;
      border-radius: 8px;
      cursor: pointer;
      line-height: 24px;

      &:focus {
        outline: 0;
      }
    }

    &.active {
      .group-item-label {
        background-color: @color-light-grey;
      }
    }
  }
}
