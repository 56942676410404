@import "../variables";
@import "../mixins";

@import (inline) 'cropperjs/dist/cropper.css';

// Override default cropper styles:
.cropper-line {
  background-color: @color-blue;
}

// Crop dialog styles
.crop-image-modal {
  @media only screen and (max-width: @phone-portrait-size) {
    .modal-dialog {
      margin-top: 20px;
    }
    .modal-content .modal-footer .btns .saving {
      display: block;
      margin-left: 0 !important;
      margin-top: @offset-sm;
    }
  }
  .modal-dialog {
    max-width: 600px;
    padding: 2*@offset-lg 0;
  }
  .modal-content {
    .modal-header {
      padding: 0 2*@offset-lg;

      h3 {
        font-size: 15px;
        padding-right: 2*@offset-md;
      }
    }

    .modal-body {
      margin: 2em 0;
      height: 300px;
      overflow: hidden;
      background-color: @color-dark-grey;

      .cropArea {
        height: 300px;
        max-height: 300px;
        width: 100%;
        text-align: center;

        img {
          max-width: 100%;
          max-height: inherit;
          vertical-align: middle;
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2*@offset-lg;

      .btns .saving {
        font-size: @font-size-md;

        .icon-task_field_save_ring {
          animation: rotate .3s linear infinite;
        }
      }

      & > .cropped-preview {
        width: @thumbnail-big-size;
        height: @thumbnail-big-size;
        border-radius: @thumbnail-big-border-radius;
        display: block;
        overflow: hidden;

        &__company-logo {
          width: @thumbnail-big-size * @company-logo-ratio;
          border-radius: 0;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
