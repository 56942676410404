@import "variables";
@import "mixins";

h1, form label {
  font-family: @font-family-medium;
  color: @color-main-copy;
  font-weight: normal;
  line-height: normal;
}

h2, h3, p, label, div {
  font-family: @font-family-regular;
  color: @color-main-copy;
  word-wrap: break-word;
  font-weight: normal;
}

h4, ul, ul li {
  font-family: @font-family-regular;
  color: @color-main-copy;
  font-weight: normal;
  line-height: normal;
}

h5 {
  font-family: @font-family-regular;
  color: @color-secondary-copy;
  font-weight: normal;
}

input {
  font-family: @font-family-regular;
  font-size: @font-size-sm;
  color: @color-main-copy;
}

footer {
  font-family: @font-family-semibold;
}

b, strong {
  font-weight: normal;
  font-family: @font-family-bold;
}

a {
  text-decoration: none;
  cursor: pointer;
  .no-focus();
}

.icon-common_close {
  .no-focus();
}

.form-hint, .input-hint--error {
  font-size: @font-size-sm;
  font-family: @font-family-semibold;
}

div[markdown-content] {
  color: @color-main-copy;
  font-size: @font-size-md;
  line-height: @font-size-h1;

  h1 {
    font-family: @font-family-regular;
    font-size: @font-size-h1;
    margin: 2*@offset-md 0;
  }

  h2 {
    font-family: @font-family-regular;
    font-size: @font-size-lg;
    margin: 2*@offset-md 0;
  }

  h3 {
    font-family: @font-family-regular;
    font-size: @font-size-md;
    margin: 2*@offset-md 0;
  }

  p {
    margin: @offset-sm 0;
  }

  hr {
    border: none;
    height: 1px;
    line-height: 1px;
    color: @color-pale-blue;
    background-color: @color-pale-blue;
    margin: @offset-md 0;
  }

  blockquote {
    padding: @offset-sm @offset-md;
    border-left: 2px solid @color-pale-blue;
    margin: @offset-sm 0;

    p {
      margin: 0;
    }
  }

  ul {
    margin-left: 20px;

    li {
      list-style: disc;

      & + li {
        margin-top: @offset-md;
      }

      ul {
        margin-top: @offset-sm;
      }
    }
  }

  ol {
    margin-left: 20px;

    li {
      list-style: decimal;

      & + li {
        margin-top: @offset-md;
      }

      ol {
        margin-top: @offset-sm;

        li {
          list-style-type: lower-alpha;
        }
      }
    }
  }

  img {
    max-width: 100%;
  }

  .video-embed {
    position: relative;
    padding-bottom: 50%;
    //padding-top: 20px;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
