@import "../../../less/variables";
@import "../../../less/mixins";

@import "tasks/list";
@import "tasks/main";

.tasks {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  position: relative;

  @media only screen and (min-width: @tablet-portrait-size) {
    &__main {
      &__content {
        & > header {
          display: none !important;
        }
      }
    }
    &__list {
      &__content {
        & > header {
          display: none !important;
        }
      }
    }
  }

  @media only screen and (max-width: @tablet-portrait-size) {
    &__main {
      padding: 0 !important;

      &.with-list {
        width: 100% !important;
        flex: 0 1 100% !important;
        max-width: 100% !important;
      }

      &__header {
        padding: 0 @offset-lg;

        & > form {
          left: @offset-lg !important;
          width: calc(100% - (2 * @offset-lg)) !important;
        }
      }

      &__content {
        max-height: 100% !important;
        overflow-x: hidden;

        &__task {
          &:hover {
            background: @color-white !important;
          }
          & > div:first-child {
            max-width: calc(100% - (5 * @offset-lg)) !important;
            flex: 0 1 calc(100% - (5 * @offset-lg)) !important;
          }
          &__hint {
            .task-comments, .task-attachments {
              display: none;
            }
          }
        }
      }

      &.empty {
        & > header {
          display: flex !important;
        }
      }
    }
    &__list {
      padding: 0 !important;
      flex: 1 0 100% !important;
      min-width: 100% !important;
      position: absolute !important;
      background-color: @color-white !important;
      left: 0;
      top: 0;

      &.ng-hide-add, &.ng-hide-remove {
        transition: all 0.15s ease;
      }
      &.ng-hide {
        transform: translate(100%, 0);
      }

      &__header {
        padding: 0 @offset-md !important;
      }

      &__content {
        max-height: 100% !important;
      }

      & > header {
        display: none !important;
      }

      &.empty {
        & > header {
          display: flex !important;
        }
      }
    }
  }

  @media only screen and (max-width: @phone-portrait-size) {
    .tasks__main__header {
      & > div > .btn-switcher {
        margin-left: @offset-lg;

        span {
          display: none;
        }
        i {
          margin-right: 1px;
        }
      }
    }
    .tasks__main__content.small {
      .tasks__main__header {
        height: 112px;
      }
    }
  }
}
