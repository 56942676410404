/* Primary colors */
/* Font-family */
/* Secondary colors */
/* Primaty offsets */
/* Font-sizes */
/* Thumbnail */
/* Forms */
/* Input sizes */
/* Z-indexes */
/*
 * FROM /modules/home/less/variables
 */
/* Site menu */
/* Tasks main */
/* Tasks list */
/*
 * FROM /modules/auth/less/variables
 */
/*
 * FROM /modules/processes/less/variables
 */
.inline-flex {
  display: inline-flex;
  align-items: center;
}
.native-size {
  padding: 0;
  margin: 0;
}
.input-border {
  border: 2px #cacaca solid;
  border-radius: 4px;
}
.profile__main__content {
  margin: 0;
  padding: 0 32px;
  height: 100%;
  max-height: calc(100% - (4 * 16px));
  overflow: auto;
}
.profile__main__content .flex-wrapper {
  display: flex;
  height: calc(100%);
  padding-bottom: 1px;
}
.profile__main__content .flex-wrapper .admin__main__content,
.profile__main__content .flex-wrapper .admin__main__settings {
  max-height: calc(100%);
}
.profile__main__content .content-wrapper {
  padding: 45px;
  width: 100%;
  border: 1px solid #e1ebef;
}
.profile__main__content .content-wrapper > p {
  font-size: 12px;
  color: #4f4f4f;
}
.profile__main__content .content-wrapper > p > span {
  color: #222;
  font-family: 'Roboto-Bold', Arial;
}
.profile__main__content .content-wrapper > p a {
  color: #2e9df0;
  font-family: 'Roboto-Regular', Arial;
}
.profile__main__content .content-wrapper > p .status {
  color: #959595;
  font-family: 'Roboto-Regular', Arial;
}
.profile__main__content .content-wrapper > p .status .saving i {
  animation: rotate 0.3s linear infinite;
}
.profile__main__content .content-wrapper > p .status .saved {
  color: #4abc3b;
}
.profile__main__content .content-wrapper .show-help-tooltips {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.profile__main__content .content-wrapper .show-help-tooltips:focus {
  outline: none;
}
.profile__main__content .content-wrapper .show-help-tooltips .checkbox {
  margin: 0 8px 0 0;
}
.profile__main__content .content-wrapper .notifications {
  display: flex;
  align-items: flex-start;
  margin: 24px 0;
}
.profile__main__content .content-wrapper .notifications .first-col,
.profile__main__content .content-wrapper .notifications .second-col {
  width: 50%;
  flex: 0 1 50%;
}
.profile__main__content .content-wrapper .notifications .first-col.full,
.profile__main__content .content-wrapper .notifications .second-col.full {
  width: 100%;
  flex: 0 1 100%;
}
.profile__main__content .content-wrapper .notifications .first-col label,
.profile__main__content .content-wrapper .notifications .second-col label {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: 'Roboto-Regular', Arial;
}
.profile__main__content .content-wrapper .notifications .first-col label + label,
.profile__main__content .content-wrapper .notifications .second-col label + label {
  margin-top: 16px;
}
.profile__main__content .content-wrapper .notifications .first-col label .checkbox,
.profile__main__content .content-wrapper .notifications .second-col label .checkbox {
  margin-left: 0;
}
.profile__main__content .content-wrapper .photo {
  display: flex;
  align-items: center;
  position: relative;
}
.profile__main__content .content-wrapper .photo + p {
  display: none;
  font-size: 11px;
  color: #959595;
}
.profile__main__content .content-wrapper .photo .avatar-preview {
  flex: 0 1 80px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 16px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-family: 'Roboto-Regular', Arial;
}
.profile__main__content .content-wrapper .photo .avatar-preview img {
  width: 100%;
  height: 100%;
}
.profile__main__content .content-wrapper .photo .actions {
  margin-left: 16px;
}
.profile__main__content .content-wrapper .photo .actions a {
  color: #2e9df0;
  font-size: 12px;
  display: block;
  font-family: 'Roboto-Regular', Arial;
  cursor: pointer;
}
.profile__main__content .content-wrapper .photo .actions a + a {
  margin-top: 12px;
}
.profile__main__content .content-wrapper .photo > p {
  font-size: 11px;
  color: #959595;
  position: absolute;
  left: 96px;
  bottom: 0;
}
.profile__main__content .content-wrapper .email-group .actions {
  display: inline-flex;
  align-items: center;
  height: 32px;
  margin-left: 8px;
}
.profile__main__content .content-wrapper .email-group .actions span {
  font-family: 'Roboto-Regular', Arial;
}
.profile__main__content .content-wrapper .email-group .actions span.red {
  color: #f75f5f;
}
.profile__main__content .content-wrapper .email-group .actions a {
  font-family: 'Roboto-Regular', Arial;
  color: #2e9df0;
}
.profile__main__content .form .form-group.text-only {
  align-items: flex-start;
}
.profile__main__content .form .form-group label {
  width: 100px;
  flex: 0 1 100px;
}
.profile__main__content .form .form-group input {
  max-width: 240px;
}
.profile__main {
  height: 100%;
}
@media only screen and (max-width: 1023px) {
  .profile__main__header {
    padding: 0 24px;
  }
  .profile__main__content .content-wrapper {
    padding: 24px;
  }
}
@media only screen and (max-width: 414px) {
  .profile__main__header {
    padding: 0 12px;
  }
  .profile__main__header__nav > li > a > i:first-child {
    margin-right: 1px;
  }
  .profile__main__header__nav > li > a > span {
    display: none;
  }
  .profile__main__content {
    padding: 0;
  }
  .profile__main__content .content-wrapper .email-group .actions {
    height: 16px;
    margin-left: 0;
  }
  .profile__main__content .content-wrapper .profile-pass-form {
    min-width: 100%;
  }
  .profile__main__content .content-wrapper .notifications {
    display: block;
  }
  .profile__main__content .content-wrapper .notifications .first-col,
  .profile__main__content .content-wrapper .notifications .second-col {
    width: 100%;
    flex: 0 1 100%;
  }
  .profile__main__content .content-wrapper .notifications .second-col {
    margin-top: 16px;
  }
  .profile__main__content .content-wrapper .photo > p {
    display: none;
  }
  .profile__main__content .content-wrapper .photo + p {
    margin-top: 8px;
    display: block;
  }
  .profile__main__content .form .input-hint--error {
    margin: 0;
  }
  .profile__main__content .form .input-hint--info {
    margin: 0;
  }
  .profile__main__content .form .form-group {
    display: block;
  }
  .profile__main__content .form .form-group label {
    margin-bottom: 4px;
    width: 100%;
  }
  .profile__main__content .form .form-group input {
    max-width: 100% !important;
  }
  .profile__main__content .form .form-group .select-timezone {
    min-width: 100%;
  }
  .profile__main__content .form > footer .btn:first-child {
    margin-left: 0;
  }
}
.profile-info-popup {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 5;
  background: rgba(74, 188, 59, 0.8);
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  min-width: 286px;
  color: #fff;
  cursor: default;
  right: 32px;
  top: 8px;
}
.profile-info-popup:focus {
  outline: none;
}
.profile-info-popup > .icon-common_close {
  color: #fff;
  opacity: 0.7;
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 12px;
  cursor: pointer;
}
.profile-info-popup > .icon-common_close:hover {
  opacity: 1;
}
.profile-info-popup > .icon-toaster_mail {
  margin-right: 12px;
  flex: 0 1 auto;
}
.profile-info-popup div {
  flex: 0 1 auto;
  color: #fff;
  font-size: 12px;
}
.profile-info-popup div header {
  color: #fff;
  font-family: 'Roboto-Bold', Arial;
  font-size: 12px;
  white-space: nowrap;
  max-width: 280px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}
.profile__main__header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 0 32px;
  height: 64px;
}
.profile__main__header__nav {
  display: inline-flex;
}
.profile__main__header__nav li {
  padding: 0;
  margin: 0;
}
.profile__main__header__nav li + li {
  margin-left: 8px;
}
.select-timezone {
  min-width: 320px;
}
.select-timezone .handler {
  text-decoration: none;
  position: relative;
  padding: 8px 7px;
  border: 2px #cacaca solid;
  border-radius: 4px;
  border-width: 1px;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-timezone .handler span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 16px);
}
.select-timezone .handler:hover {
  border-color: #2e9df0;
  box-shadow: 0 0 0 1px #2e9df0;
}
.select-timezone .handler i {
  font-size: 16px;
}
.select-timezone .handler i.icon-common_arrow_down {
  font-size: 4px !important;
}
@media only screen and (max-width: 414px) {
  .select-timezones-element {
    width: 100%;
    left: -48px !important;
  }
  .select-timezones-element .dropdown-menu--timezones {
    max-width: calc(100% - 16px);
    top: -1px;
  }
}
.select-timezones-element.select-timezones-element-open .dropdown-menu--timezones {
  display: block;
}
.select-timezones-element .dropdown-menu--timezones {
  display: none;
  position: absolute;
  z-index: 10;
  min-width: 200px;
  max-height: 290px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  border: 1px #cacaca solid;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  padding: 4px 0;
}
.select-timezones-element .dropdown-menu--timezones li a {
  font-size: 14px;
  color: #222;
  padding: 8px 24px 8px 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 200px;
}
.select-timezones-element .dropdown-menu--timezones li a i {
  visibility: hidden;
  color: #4abc3b;
  font-size: 8px;
  margin-right: 8px;
}
.select-timezones-element .dropdown-menu--timezones li a:hover {
  color: #2e9df0;
}
.select-timezones-element .dropdown-menu--timezones li a.active i {
  visibility: visible;
}
.profile-pass-form {
  min-width: 390px;
}
.profile-pass-form .form-group:first-child {
  margin-top: 0;
}
.profile-pass-form .form-group label {
  flex: 0 1 150px;
  min-width: 150px;
}
.profile-pass-form .input-hint--error {
  margin-left: 150px;
}
.profile-pass-form footer .btn:first-child {
  margin-left: 150px;
}
