@import "../../../../less/variables";
@import "../../../../less/mixins";

.process__header {
  display: flex;
  background-color: @color-white;
  padding: @offset-lg 4*@offset-md @offset-lg 0;
  position: relative;
  border-bottom: 1px solid @color-pale-blue;
  .no-focus();

  &__icon {
    flex: 0 1 @thumbnail-big-size;
    line-height: @thumbnail-big-size;
    height: @thumbnail-big-size;
    width: @thumbnail-big-size;
    min-width: @thumbnail-big-size;
    max-width: @thumbnail-big-size;
    vertical-align: middle;
    text-align: center;
    border-radius: @thumbnail-big-border-radius;
    color: @color-asphalt-grey;
    margin-right: @offset-md;
    margin-top: @offset-xs;
    position: relative;

    .process-rejected-icon {
      position: absolute;
      .reject-icon-on-avatar();
      right: -3px;
      bottom: -3px;
    }

    .process-completed-icon {
      position: absolute;
      .completed-icon-on-avatar();
      right: -3px;
      bottom: -3px;
    }

    .process-stopped-icon {
      position: absolute;
      .stopped-icon-on-avatar();
      right: -3px;
      bottom: -3px;
    }

    &.default {
      background-color: @color-grey;

      i {
        line-height: @thumbnail-big-size;
      }
    }

    i {
      font-size: @thumbnail-size;
      line-height: @thumbnail-big-size;

      &.icon-Magnifi-Glass2 {
        font-size: 22px;
      }

      &.icon-Mens {
        font-size: 26px;
      }
    }
  }

  &__main {
    display: flex;
    align-items: flex-start;
    max-width: calc(100% - 85px);
    margin-top: @offset-xs;

    &__content {
      min-width: 0;
      & > span {
        color: @color-secondary-copy;
        font-size: @font-size-sm;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        &.animated {
          a:hover {
            cursor: pointer;
          }
        }

        a {
          color: @color-secondary-copy;
          display: inline-block;
          border-bottom: 1px solid @color-grey;
          cursor: default;
          height: 15px;

          &.template-name {
            display: none;
            border-bottom: none;
          }
        }

        &.ad-hoc-title {
          color: @color-secondary-copy;
          font-size: @font-size-xs - 1px;
          text-transform: uppercase;
          font-family: @font-family-semibold;
          letter-spacing: 0.5px;
          display: block;
        }
      }

      & > .title {
        display: table;
        table-layout: fixed;
        width: 100%;
        white-space: nowrap;
        min-height: 31px;

        h1 {
          font-size: @font-size-h1;
          font-family: @font-family-semibold;
          font-weight: normal;
          color: @color-main-copy;
          width: 100%;
          clear: both;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-break: break-all;
          overflow: hidden;
        }
      }

      .process-reject-reason {
        display: inline-block;
        background-color: @color-pale-blue;
        border-radius: 4px;
        padding: @offset-md;
        margin-top: @offset-xs;
        font-size: @font-size-sm;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        & > span:first-child{
          font-family: @font-family-bold;
        }
      }

    }

    &__btns {
      flex: 0 1 auto;
      min-width: 100px;
      white-space: nowrap;
      align-items: center;
      justify-content: flex-end;
      display: inline-flex;
      position: relative;
      margin-top: 6px;

      .action {
        font-size: 32px;
        max-width: 32px;
        border: none;
        color: #cdcdcd;
        padding: 0;
        background: transparent;
        .no-focus();

        & > i {
          margin: 0;
        }

        & + .action {
          margin-left: @offset-md;
        }

        &[disabled='disabled'] {
          color: @color-light-grey !important;

          &:hover {
            color: @color-light-grey !important;
          }
          cursor: not-allowed;
        }

        &:hover {
          color: @color-blue;
        }
      }
    }
  }

  & > .icon-common_close {
    color: #cdcdcd;
    font-size: @font-size-md;
    position: absolute;
    top: @offset-md;
    right: 18px;
    cursor: pointer;

    &:hover {
      color: @color-secondary-copy;
    }
  }

  & > .common_back {
    font-size: 40px;
    width: 20px;
    display: inline-block;
    color: #cdcdcd;
    right: 18px;
    cursor: pointer;
    margin-right: @offset-md;

    .no-focus();

    & > i {
      margin: 0;
    }

    &:hover {
      color: @color-blue;
    }
  }
}
