@import "../../../../less/variables";
@import "../../../../less/mixins";

.template__header--single {
  display: flex;
  align-items: center;
  height: 4*@offset-lg;
  background-color: @color-white;
  padding: 0 4*@offset-lg 0 @site-padding;
  position: relative;
  border-bottom: 1px solid @color-pale-blue;

  &__icon {
    flex: 0 1 @thumbnail-big-size;
    line-height: @thumbnail-big-size;
    height: @thumbnail-big-size;
    width: @thumbnail-big-size;
    min-width: @thumbnail-big-size;
    max-width: @thumbnail-big-size;
    vertical-align: middle;
    text-align: center;
    border-radius: @thumbnail-big-border-radius;
    color: @color-asphalt-grey;
    margin-right: @offset-md;
    overflow: hidden;

    &.default {
      background-color: @color-grey;

      i {
        line-height: @thumbnail-big-size;
      }
    }

    i {
      font-size: @thumbnail-size;
      line-height: @thumbnail-big-size;

      &.icon-Mens {
        font-size: 26px;
      }

      &.icon-Magnifi-Glass2 {
        font-size: 22px;
      }
    }
  }

  &__main {
    display: flex;
    align-items: center;

    &__content {
      & > span {
        color: @color-secondary-copy;
        font-size: @font-size-xs - 1px;
        text-transform: uppercase;
        font-family: @font-family-semibold;
        letter-spacing: 0.5px;
        display: block;
      }

      & > .title {
        display: table;
        table-layout: fixed;
        width: 100%;
        white-space: nowrap;

        h1 {
          font-size: @font-size-h1;
          font-family: @font-family-semibold;
          font-weight: normal;
          color: @color-main-copy;
          width: 100%;
          clear: both;
          display: table-cell;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__btns {
      flex: 0 1 auto;
      min-width: 230px;
      white-space: nowrap;
      align-items: center;
      justify-content: flex-end;
      display: inline-flex;
      position: relative;
      padding-left: @offset-sm;

      & > .btn {
        flex: 0 1 auto;
      }

      .action {
        font-size: 2*@offset-lg;
        max-width: 2*@offset-lg;
        min-width: 2*@offset-lg;
        border: none;
        color: #cdcdcd;
        padding: 0;
        background: transparent;
        margin-left: @offset-sm;
        cursor: pointer;
        .no-focus();

        & > i {
          margin: 0;
        }

        &[disabled='disabled'] {
          color: @color-light-grey !important;

          &:hover {
            color: @color-light-grey !important;
          }
          cursor: not-allowed;
        }

        &:hover, &.active {
          color: @color-blue;
        }
      }
    }
  }

  & > a {
    color: #cdcdcd;
    font-size: 13px;
    position: absolute;
    top: @offset-md;
    right: 18px;
    cursor: pointer;

    &:hover {
      color: @color-secondary-copy;
    }

    &.common_back {
      left: 0;
      font-size: 40px;
      top: @offset-sm;
      width: 20px;
      display: inline-block;
      .no-focus();

      & > i {
        margin: 0;
      }

      &:hover {
        color: @color-blue;
      }
    }
  }
}
