.comments-tabs {
  button + button {
    margin-left: @offset-sm;
  }

  & > .btn-switcher {
    & > .number {
      display: none;
    }
  }

  & > .icon-common_close {
    color: #cdcdcd;
    font-size: @font-size-md;
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;

    &:hover {
      color: @color-secondary-copy;
    }
  }

  @media only screen and (min-width: @tablet-portrait-size) {
    & > .icon-common_close {
      display: none;
    }
  }

  @media only screen and (max-width: @phone-portrait-size) {
    & > .btn-switcher {
      & > .number {
        display: inline-block;
      }
      & > span {
        display: none;
      }
      & > i:first-child {
        margin-left: 2px;
      }
    }
  }
}

.comments, .attachments {
  max-height: calc(100% - (2 * @offset-lg + @offset-lg + 20px));

  & > main {
    .google-scroll-shadow();
  }
  @media only screen and (max-width: @tablet-portrait-size) {
    .form-group .files.filled {
      padding-right: 85px !important;
    }
  }

  grammarly-btn {
    position: absolute;
    opacity: 0;
  }
}

.comments,
.attachments,
.history {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &.hidden {
    position: absolute;
    visibility: hidden;
    width: 100%;
    right: -200%;
  }

  &__header {
    display: flex;
    flex: 0 1 auto;
    width: 100%;
    align-items: center;

    span {
      color: @color-secondary-copy;
      font-size: @font-size-sm;
      flex: 0 1 auto;
      white-space: nowrap;
      margin-right: @offset-sm;
    }

    .line {
      height: 1px;
      background-color: @color-pale-blue;
      flex: 0 1 100%;
    }
  }

  & > main {
    flex: 0 1 100%;
    width: 100%;
    padding-left: 12px;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
  }

  &__empty-state {
    position: absolute;
    top: 50%;
    margin-top: -35px;
    text-align: center;
    width: calc(100% - @offset-md);
    opacity: 1;

    &.ng-hide-remove {
      transition: all .2s linear .1s;
    }
    &.ng-hide {
      opacity: 0;
    }

    img {
      opacity: .2;
    }
  }

  & .revision {
    text-align: center;
    font-size: 12px;
    color: @color-add-grey;
    border-bottom: 1px dashed @color-grey;
    height: 8px;

    span {
      padding: 0 4px;
      line-height: 1;
      display: inline-block;
      background: #fff;
    }
  }

  &__comment {
    display: flex;
    align-items: flex-start;
    padding: @offset-md 0;
    position: relative;
    width: 100%;
    transition: all .2s ease;

    &.deleted {
      animation: none;
      transition: none;
    }

    &.highlighted {
      width: calc(100% + 12px);
      background-color: @color-light-blue;
      padding-left: @offset-md;
      margin-left: -@offset-md;
      animation: spring .3s ease-in .1s;
      padding-bottom: 10px;
    }

    &:first-child {
      margin-top: 0;
    }

    & > i.icon-common_close {
      position: absolute;
      top: 12px;
      right: 10px;
      color: @color-grey;
      cursor: pointer;
      opacity: 0;
      transition: all .15s ease;
      font-size: @font-size-xs;
      .no-focus();
    }

    &:hover > i.icon-common_close {
      opacity: 1;
    }

    .user {
      margin-right: @offset-md;

      .avatar {
        margin: 0;
      }
    }

    aside {
      width: calc(100% - (@thumbnail-big-size + @offset-md));

      & > header {
        display: flex;
        align-items: baseline;
        font-size: @font-size-md;
        color: @color-main-copy;
        font-family: @font-family-semibold;

        span {
          color: @color-add-grey;
          font-size: @font-size-sm;
          font-family: @font-family-semibold;
        }
      }

      & > p {
        font-size: @font-size-md;
        color: @color-main-copy;
        font-family: @font-family-regular;
        line-height: @font-size-h1;
        margin-top: @offset-xs;
        word-wrap: break-word;
        white-space: pre-wrap;
        max-height: 100%;
        max-width: 99%;

        .emoji {
          vertical-align: text-top;
        }

        a {
          color: @color-blue;
          cursor: pointer;
        }

        .mention {
          display: inline-block;
          color: @color-blue;
          font-family: @font-family-semibold;
          position: relative;
          white-space: nowrap;

          &.top-align {
            & > .user-tooltip {
              top: auto;
              bottom: 140%;

              .arrow {
                bottom: -8px;
                top: auto;
                transform: rotate(45deg);
              }
            }
          }

          &:hover {
            .user-tooltip {
              visibility: visible;
              padding: @offset-sm;

              .user-tooltip__container {
                display: flex;
              }
            }
          }

          & > .user-tooltip {
            top: 140%;
            left: -50px;
            display: table;
            padding: 0;

            .user-tooltip__container {
              display: none;
            }

            .arrow {
              left: 55px;
            }
          }
        }
      }

      & .preview {
        margin-top: @offset-sm;
        padding: @offset-md;
        background-color: @color-panel-grey;
        min-height: 45px;
        display: flex;
        align-items: center;
        width: 100%;

        & > i {
          color: @color-blue;
          font-size: @font-size-h1;
          margin-right: @offset-md;

          &::before {
            margin: 0;
          }
        }

        &__content {
          display: flex;
          flex-direction: column;
          width: calc(100% - (@font-size-h1 + @offset-sm));
          font-family: @font-family-semibold;
          word-wrap: break-word;

          &__header {
            color: @color-blue;
            font-size: @font-size-md;
            display: block;

            .file-preview__link {
              color: @color-blue;
            }
          }

          &__info {
            display: table;
            table-layout: fixed;
            white-space: nowrap;

            span {
              display: table-cell;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-top: @offset-xs;
              font-size: @font-size-sm;
              color: @color-secondary-copy;
            }
          }
        }
      }
    }
  }

  .comments-notification-wrapper {
    position: relative;
    height: 1px;
  }

  .comments-notification {
    padding: @offset-sm;
    height: 2*@offset-md + 2*@offset-sm;
    color: #fff;
    font-size: @font-size-sm;
    font-family: @font-family-semibold;
    background: rgba(0,0,0,0.8);
    position: absolute;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    margin: -2*@offset-lg auto 0;
    right: calc(50% - 150px);
    bottom: @offset-md;
    border-radius: 8px;
    animation: spring .3s ease-in .1s;
    cursor: pointer;
    .no-focus();

    &.hidden {
      opacity: 0;
      transition: all .3s ease;
    }

    .avatars {
      display: flex;
      align-items: center;

      & > div {
        & + div {
          margin-left: @offset-xs;
        }

        .avatar-small {
          opacity: 1;
          &.ng-hide-add, &.ng-hide-remove {
            transition: all linear 0.2s;
          }
          &.ng-hide {
            opacity: 0;
          }
        }
      }
    }
  }

  .form-group {
    @color-btn-hover: #00ccff;
    flex: 1 0 auto;
    display: block;
    position: relative;
    border: 1px solid #cacaca;
    border-radius: 4px;
    width: 100%;
    max-width: calc(100% - @offset-md);
    margin-left: @offset-md;

    & > .wrapper {
      max-height: 200px;
      overflow: auto;
    }

    &.active {
      border-color: @color-blue;
      &.error {
        border-color: @color-red;
      }
    }

    & > div {
      width: 100%;
      max-width: 100%;
      .native-size;
      display: block;

      &.empty {
        @media (max-width: 1135px) {
          textarea {
            padding-left: 50px;
            padding-top: 15px;
            font-size: 11px;
          }
        }
      }

      textarea {
        max-width: 100%;
        min-height: 36px;
        font-size: @font-size-md;
        padding: 10px 90px @offset-sm 55px;
        margin: @offset-xs 0;
        border: none;
        border-width: 0 !important;
        box-shadow: none !important;
      }
    }

    .files {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0 4*@offset-lg + @offset-sm 0 55px;
      position: relative;
      z-index: 2;
      .no-focus();

      &.filled {
        margin-bottom: @offset-xs;
      }

      .file {
        display: inline-flex;
        align-items: center;
        font-size: @font-size-sm;
        font-family: @font-family-semibold;
        padding: @offset-xs @offset-sm;
        border-radius: 8px;
        background-color: @color-light-grey;
        margin: 0 @offset-xs @offset-xs 0;
        max-width: 100%;

        i {
          &::before {
            margin: 0;
          }
          &.icon-attachment_document {
            font-size: @font-size-md;
            margin-right: @offset-xs;
            color: @color-blue;
          }
          &.icon-common_close {
            margin-left: @offset-xs;
            font-size: @font-size-icon;
            cursor: pointer;
            color: @color-add-grey;
            .no-focus();

            &:hover {
              color: @color-main-copy;
            }
          }
        }

        & > span {
          max-width: calc(100% - (3 * @offset-md - @offset-sm));
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.ng-leave {
          animation: none !important;
          transition: none !important;
        }
        &.uploading {
          color: @color-white;
          .animated-progress-bar-stripes(@color-green);

          i.icon-attachment_document,
          i.icon-common_close {
            color: @color-white;
            .no-focus();
          }
        }
      }
    }

    &.active #attach{
      border-right: 1px solid @color-blue;
      color: @color-blue;

      &:hover {
        color: @color-btn-hover;
      }
    }

    #attach {
      border: none;
      position: absolute;
      left: 0;
      top: 0;
      border-right: 1px solid #cacaca;
      height: 100%;
      background-color: transparent;
      padding: 0 10px;
      font-size: @font-size-md;
      color: #cacaca;
      z-index: 3;
      border-radius: 0;

      & > span {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        height: 100%;
        width: 40px;
      }

      &:hover {
        color: @color-blue;
      }

      &:active {
        background-color: @color-blue;
        color: @color-white;
      }
    }

    &.active #comment-send{
      border-left: 1px solid @color-blue;
      background-color: @color-blue;
      color: @color-white;
      &:hover {
        background-color: @color-btn-hover;
        border-left: 1px solid  @color-btn-hover;
        color: @color-white;
      }
    }

    #comment-send {
      border: none;
      position: absolute;
      right: 0;
      top: 0;
      border-left: 1px solid #cacaca;
      height: 100%;
      background-color: transparent;
      padding: 0 20px;
      font-size: @font-size-md;
      color: #cacaca;
      z-index: 3;
      border-radius: 0;

      &:hover {
        color: @color-blue;
      }

      &:active {
        background-color: @color-blue;
        color: @color-white;
      }
    }
  }

  .errors {
    flex: 0 1 auto;
    font-size: @font-size-sm;
    color: @color-red;
    margin-top: 3px;
    margin-left: @offset-md;
  }
}

.attachments {
  & > main {
    overflow-x: hidden;
  }
  &__attachment {
    height: 4*@offset-lg;
    display: flex;
    align-items: center;
    border: 1px solid @color-pale-blue;
    padding: 0 @offset-lg 0 @offset-md;
    max-width: 100%;
    position: relative;
    width: 100%;
    transition: all .2s ease;

    &.deleted {
      animation: none;
      transition: none;
    }

    &.highlighted {
      width: calc(100% + 12px);
      background-color: @color-light-blue;
      padding-left: @offset-md;
      margin-left: -@offset-md;
      animation: spring .3s ease-in .1s;
      padding-bottom: 10px;
    }

    & + .attachments__attachment {
      border-top: 0;
    }

    .icon-wrapper {
      width: @thumbnail-big-size;
      height: @thumbnail-big-size;
      min-width: @thumbnail-big-size;
      line-height: @thumbnail-big-size;
      border-radius: @thumbnail-big-border-radius;
      background: @color-panel-grey;
      flex: 0 1 @thumbnail-big-size;
      text-align: center;
      margin: 0 @offset-md;

      & > i {
        color: @color-blue;
        font-size: @font-size-h1;

        &::before {
          margin: 0;
        }
      }
    }

    .content {
      font-family: @font-family-semibold;
      max-width: calc(100% - (@thumbnail-big-size + 2 * @offset-md));
      flex: 0 1 100%;

      &__header {
        font-size: @font-size-md;
        color: @color-main-copy;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
        max-width: 100%;

        .file-preview__link:hover,
        .file-preview__link:focus {
          color: @color-blue;
        }
      }

      &__info {
        font-size: @font-size-md;
        color: @color-main-copy;
        margin-top: @offset-xs;
        display: table;
        table-layout: fixed;

        span {
          display: table-cell;
          overflow: hidden;
          max-width: 100%;
          text-overflow: ellipsis;
          margin-top: @offset-xs;
          font-size: @font-size-sm;
          color: @color-secondary-copy;
          white-space: nowrap;
          word-wrap: break-word;
        }
      }
    }

    .date {
      font-size: @font-size-sm;
      color: @color-secondary-copy;
      font-family: @font-family-semibold;
      white-space: nowrap;
      overflow: hidden;
      flex: 1 0 auto;
      margin-left: @offset-md;
      position: absolute;
      line-height: @offset-lg * 2;
      z-index: 1;
      visibility: hidden;
      right: @offset-lg + @offset-sm + @thumbnail-size;
      top: 30px;
    }

    .avatar-small {
      margin-left: @offset-sm;
      right: @offset-lg;
      position: absolute;
      z-index: 1;
      margin-top: 32px;
      visibility: hidden;
      top: 0;

      &:hover + .user-tooltip {
        visibility: visible;
      }
    }

    .user-tooltip {
      right: 4px;
      top: 56px;

      &.top {
        top: auto !important;
        bottom: 150% !important;

        .arrow {
          top: auto !important;
          bottom: -@font-size-icon;
          transform: rotate(45deg);
        }
      }

      .arrow {
        right: 16px;
        border-color: #f0f0f0;
      }

      span {
        font-size: 12px !important;
        color: @color-main-copy !important;
      }
    }
  }
}

.attachments__empty-state {
  margin-top: -70px;
}

.attachments {
  max-height: calc(100% - (2 * @offset-lg));

  & > main {
    .google-top-scroll-shadow();
    .bottom-offset(@page-screen-bottom-offset);
  }
}

.history {
  max-height: calc(100% - (2 * @offset-lg + 20px));

  & > main {
    .google-top-scroll-shadow();
    .bottom-offset(@page-screen-bottom-offset);

    & > ul {

      & > li {
        padding-left: 2*@offset-sm;
        position: relative;
        color: @color-add-grey;
        font-size: @font-size-sm;

        &.red {
          &:before {
            background-color: @color-red;
          }
        }
        &.green {
          &:before {
            background-color: @color-green;
          }
        }
        &.yellow {
          &:before {
            background-color: @color-yellow;
          }
        }

        &:before {
          position: absolute;
          top: 2*@offset-md + 3;
          left: 0;
          content: '';
          width: 7px;
          height: 7px;
          border-radius: 100%;
          background-color: @color-light-grey;
        }

        .date {
          color: @color-main-copy;
          display: block;
          margin-bottom: @offset-sm;
        }

        & + li {
          margin-top: @offset-lg + 2;
        }
      }
    }
  }
}
