.mentions-wrapper {
  position: relative;

  & > ul {
    .native-size;
    padding: @offset-md;
    position: absolute;
    right: 0;
    z-index: 10;
    max-height: 200px;
    overflow: auto;
    background-color: @color-white;
    box-shadow: 0 2px 8px @color-dark-grey;
    width: calc(100% - @offset-md);
    bottom: -@offset-lg;

    & > li {
      font-size: @font-size-md;

      & > p {
        color: @color-secondary-copy;
        font-size: @font-size-sm;
      }

      & + li {
        margin-top: @offset-sm;
      }

      &.active {
        & > a {
          background-color: @color-light-grey;
        }
      }

      & > a {
        .inline-flex;
        padding-right: 8px;
        border-radius: @thumbnail-border-radius;
        cursor: pointer;
        font-family: @font-family-semibold;
        white-space: nowrap;

        .thumbnail {
          line-height: @thumbnail-size;
          width: @thumbnail-size;
          min-width: @thumbnail-size;
          border-radius: @thumbnail-border-radius;
          color: @color-white;
          padding-right: 0;
          margin-right: 5px;
          font-size: @font-size-xs;
          text-align: center;

          span {
            margin: 0 auto;
          }
        }

        .highlighted {
          text-decoration: underline;
        }

        & > span:not(.name) {
          color: @color-secondary-copy;
          margin-left: @offset-xs;
          font-size: @font-size-sm;
          margin-top: 2px;
        }
      }
    }
  }
}
