@import "../../less/variables";
@import "../../less/mixins";

.select-dropdown {
  min-width: 90px;

  &__control {
    color: @color-main-copy;
    font-size: @font-size-sm;
    border: 1px @border-button-gray-color solid;
    border-radius: 6px;
    min-height: 2*@offset-lg !important;
    cursor: pointer !important;
    .no-focus();

    &--is-focused {
      border-color: @color-blue !important;
      box-shadow: 0 0 0 1px @color-blue !important;
    }
  }

  &__indicator-separator {
    display: none !important;
  }

  &__dropdown-indicator {
    padding: 0 !important;

    i {
      font-size: 4px;
      margin-right: 8px;
      color: @color-main-copy;
    }
  }

  &__menu {
    width: unset !important;
    border-radius: 0 !important;
    margin-top: 2px !important;
    min-width: 100%;
    max-width: 260px;
  }

  &__menu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__option {
    font-size: 12px !important;
    padding: 8px !important;
    cursor: pointer !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    i {
      display: inline-block;
      font-size: 8px;
      color: @color-green;
      margin-right: 6px;
      visibility: hidden;
    }

    &--is-selected {
      color: @color-main-copy;
      background-color: transparent;

      i {
        visibility: visible;
      }
    }

    &.empty-value {
      color: @color-secondary-copy;
    }

    &--is-focused {
      background-color: @color-green !important;
      color: @color-white !important;

      i {
        color: @color-white;
      }
    }
  }
}
