.integrations {
  width: 100%;
  height: 100%;
  padding: 4*@offset-md;
  border: 1px solid @color-pale-blue;
  max-height: calc(100% - (4 * @offset-lg));
  overflow: auto;

  &__api {
    margin-bottom: 2em;

    .content {
      border: 1px solid #DADFE2;
      border-radius: 3px;
      padding: 1em;
      margin-bottom: 1em;
      min-height: 150px;

      @label-width: 170px;

      .form-group {
        margin: 0;
        align-items: normal;

        label,
        .label {
          min-width: @label-width;
          flex: 0 1 @label-width;
          padding-right: @offset-lg;
          padding-top: @offset-sm;
          word-wrap: break-word;
          font-family: @font-family-medium;
        }

        .input-read-only {
          background: @color-panel-grey;
          color: @color-main-copy;
          border: none;
          font-family: @font-family-semibold;
          border-radius: 4px;
          padding: 8px 7px;
          min-height: 30px;
          word-break: break-all;
        }

        .info {
          font-size: 12px;
          color: @color-secondary-copy;
          padding: 8px;
        }

        .actions {
          padding: 6px 8px;

          .btn {
            opacity: 0;
            transition: opacity 0.2s;
            color: @color-add-grey;

            &:hover {
              color: @color-blue;
            }
          }
        }

        .actions__mobile {
          vertical-align: bottom;

          .btn {
            color: @color-add-grey;

            &:hover {
              color: @color-blue;
            }
          }
        }

        &:hover {
          .actions {
            .btn {
              opacity: 1;
            }
          }
        }
      }

      .form-actions {
        margin-top: @offset-lg;
        margin-left: @label-width;

        button + button {
          margin-left: 10px;
        }
      }

      .empty-state {
        text-align: center;
        padding-top: 40px;

        p {
          font-size: 14px;
          color: @color-secondary-copy;
          padding-bottom: 8px;
        }
      }
    }
  }

  &__api,
  &__zapier {
    .header {
      padding: 0 @offset-md @offset-md;
      position: relative;

      @logo-size: 46px;

      .logo {
        position: absolute;
        width: @logo-size;
        height: @logo-size;
        top: 0;
        left: @offset-md;
        vertical-align: top;
      }

      .title {
        font-size: 18px;
        margin-left: @logo-size + @offset-md;
        vertical-align: top;
        width: 100%;
      }

      .summary {
        font-size: 14px;
        margin-top: 6px;
        margin-left: @logo-size + @offset-md;
        vertical-align: top;
        color: @color-secondary-copy;
      }

      @media all and (max-width: @phone-portrait-size) {
        .logo {
          position: static;
          float: left;
          display: inline-block;
          margin-right: @offset-md;
        }

        .summary {
          margin-left: 0;
        }
      }
    }
  }
}

@media all and (max-width: @phone-portrait-size) {
  .integrations {
    &__api {
      .logo {
        margin-bottom: 15px;
      }
      .form-group {
        flex-wrap: wrap;

        label,
        .label {
          width: 100%;
          padding-bottom: @offset-sm;
        }
      }

      .form-actions {
        margin-left: 0 !important;
      }
    }
  }
}
