@import "../../../less/variables";
@import "../../../less/mixins";

.templates__main {
  margin: 0;
  padding: 1px @site-padding;
  height: 100%;
  max-height: calc(100% - (4 * @offset-lg));
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  .google-top-scroll-shadow();
  .bottom-offset(@lists-screen-bottom-offset);

  &__empty-state,
  &__error-state {
    border: 1px solid @color-pale-blue;
    padding: 4*@offset-lg 0;
    width: 100%;
    text-align: center;

    h5 {
      color: @color-main-copy;
      font-size: @font-size-md;
      margin-top: @offset-lg;
    }

    & > i {
      font-size: 64px;
      color: #d1d1d1;
    }

    p {
      margin-top: @offset-sm;
      font-size: @font-size-sm;
      color: @color-secondary-copy;

      .break {
        display: block;
        margin-bottom: @offset-xs;
      }

      a {
        color: @color-blue;
      }
    }
  }

  &__error-state {
    border-color: @color-red;
  }

  &__template {
    border: 1px solid @color-pale-blue;
    height: 4 * @offset-lg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 @offset-lg 0 9px;
    overflow: hidden;
    position: relative;
    width: 100%;

    &.isNotDesktop {
      padding: 0 0 0 9px;
    }

    & + & {
      border-top: none;
    }

    &:hover {
      background: #f2fafe;
    }

    &:hover,
    &.with-dropdown {
      .templates__main__template__hover_actions {
        transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275) .15s;
        transform: translateX(0px);

        button, span {
          display: block;
        }
      }
    }
    &.animated:hover {
      cursor: pointer;
      h4 {
        color: @color-blue;
      }
    }
    &__touch_actions {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      padding: 0 ;
      button{
        margin: @offset-md @offset-lg;
      }
    }
    &__hover_actions {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100%;
      padding: 0 @offset-xs;
      display: flex;
      align-items: center;
      transform: translateX(245px);

      button {
        display: none;
        margin-right: 6px;
      }

      span,
      .btn-icon {
        font-size: 28px;
        color: @color-blue;
        cursor: pointer;
        display: none;
        .no-focus();

        &.disabled {
          color: @color-grey;
          cursor: not-allowed;
        }
      }
    }

    &.isNotDesktop .templates__main__template__details{
      width: calc(100% - 87px);
      flex: 0 1 calc(100% - 87px);
    }

    &__details {
      flex: 0 1 calc(100% - (@start-btn-size + 10px));
      max-width: 100%;
      display: flex;

      &:focus {
        outline: none;
      }

      .thumbnail {
        flex: 0 1 @thumbnail-big-size;
        line-height: @thumbnail-big-size;
        height: @thumbnail-big-size;
        width: @thumbnail-big-size;
        min-width: @thumbnail-big-size;
        max-width: @thumbnail-big-size;
        vertical-align: middle;
        text-align: center;
        border-radius: @thumbnail-big-border-radius;
        color: @color-asphalt-grey;
        margin-right: @offset-sm;
        overflow: hidden;

        &:not(.default) {
          line-height: 44px;
        }

        &.default {
          background-color: @color-grey;
        }

        i {
          font-size: @thumbnail-size;
          display: block;
          vertical-align: middle;
          width: auto;
          line-height: @thumbnail-big-size;

          &.icon-Mens {
            font-size: 26px;
          }

          &.icon-Magnifi-Glass2 {
            font-size: 22px;
          }
        }
      }

      .details-wrapper {
        max-width: calc(100% - (@offset-md + @thumbnail-big-size));
        flex: 0 1 calc(100% - (@offset-md + @thumbnail-big-size));
        margin-top: 2px;

        h4 {
          font-size: @font-size-md;
          font-family: @font-family-regular;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          .due-date-interval {
            color: @color-secondary-copy;
            font-family: @font-family-regular;
            font-size: @font-size-sm;
          }
        }

        & > p {
          color: @color-secondary-copy;
          font-size: @font-size-sm;
          margin-top: @offset-sm - 2px;
        }
      }
    }

    &__start {
      flex: 0 1 auto;
      color: @start-btn-color;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:hover {
        i > .path1:before {
          color: @color-light-green;
        }
      }

      i {
        margin-right: 2px;
        font-size: @start-btn-size;

        .path1:before {
          color: @color-green;
          transition: all .15s ease;
        }

        &::before {
          height: 100%;
          margin: 0;
        }
      }
    }
  }
}
