.multi-select {
  width: 100%;
  flex: 0 1 100%;
  position: relative;
  .no-focus();

  &.is-disabled {
    background-color: #f5f5f5;
  }

  &.is-opened .handler {
    border-width: 1px;
    border-color: @color-blue;
    box-shadow: 0 0 0 1px @color-blue;
    z-index: @z-index-over-content;
  }

  &.is-invalid .handler {
    border-width: 1px;
    border-color: @color-red;
  }

  &.is-opened.is-invalid .handler {
    box-shadow: 0 0 0 1px @color-red;
  }

  .handler {
    position: relative;
    .input-border;
    .no-focus();
    border-width: 1px;
    min-height: @auth-form-text-height;
    padding-right: 2*@offset-md;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;

    .clear-all {
      position: absolute;
      top: 7px;
      right: 5px;
      background-color: rgb(204, 204, 204);
      color: @color-white;
      font-size: 6px;
      padding: 4px 3px;
      border-radius: 100%;
      cursor: pointer;
      .no-focus();
    }

    & > ul {
      .native-size;
      background: @color-white;
      display: inline-block;
      max-width: 100%;
      border-radius: 4px;

      & > li.input {
        input {
          border: none;
          font-size: @font-size-sm !important;
          line-height: @font-size-md !important;
          padding: 0;
          margin: 0 !important;
          height: 2*@offset-md;
          max-width: 100%;
          box-shadow: none !important;
          .no-focus();
        }
      }

      & > li {
        line-height: 2*@offset-md;
        max-height: 2*@offset-md;
        overflow: hidden;
        align-items: center;
        margin: 3px 3px 0;
        display: inline-block;
        font-size: @font-size-sm;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:not(.input) {
          padding: 0 @offset-sm;
          border-radius: 8px;
          background-color: @color-light-grey;
          cursor: pointer;
        }

        i {
          color: #a3a3a3;

          &.icon-common_close {
            font-size: @font-size-icon;
            line-height: @font-size-icon;
            margin: 0 -2px 0 3px;
            transform: translateY(-1px);
            .no-focus();

            &:hover {
              color: @color-main-copy;
            }
          }
        }
      }
    }
  }

  &.is-opened {
    .multi-select-options-list {
      display: block;
    }
  }

  .multi-select-options-list {
    display: none;
    width: 100%;
    margin-top: 4px;
    min-width: 90px;
    max-height: 200px;
    overflow: auto;
    padding: 6px 10px @offset-xs;
    background-color: @color-white;
    box-shadow: 0 2px 8px @color-dark-grey !important;
    z-index: @z-index-content;
    position: absolute;
    top: 100%;

    .no-result {
      font-size: @font-size-sm;
      color: @color-secondary-copy;
      padding: 6px 0 @offset-lg;
      text-align: center;
    }

    & > ul {
      .native-size;

      & > li {
        font-size: @font-size-md;

        & + li {
          margin-top: @offset-xs;
        }

        & > a {
          border-radius: @thumbnail-border-radius;
          cursor: pointer;
          line-height: @thumbnail-size;
          font-family: @font-family-semibold;
          white-space: nowrap;
          font-size: @font-size-sm;
          color: @color-secondary-copy;
          padding: @offset-xs @offset-sm;
          .no-focus();

          & > span {
            color: @color-main-copy;
          }

          &.hovered {
            background-color: @color-light-grey;
          }
        }

        span.highlighted {
          color: @color-yellow;
        }
      }
    }
  }
}
