@import "../../../less/variables";
@import "../../../less/mixins";

@import (inline) "fixed-data-table-2/dist/fixed-data-table.css";

.report {
  height: 100%;

  @report-header-height: 90px;

  &-header {
    position: relative;
    height: @report-header-height;
    padding: 25px 34px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid @color-pale-blue;

    .report-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      flex: 0 0 40px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .report-title {
      width: 100%;
      margin-left: 12px;
      display: flex;
      align-items: center;

      .title h1 {
        font-size: 21px;
        line-height: 20px;
        color: #222222;
        font-family: @font-family-regular;
      }
    }

    .report-actions {
      white-space: nowrap;
      margin-right: 20px;
      display: flex;
      align-items: center;

      button {
        margin-left: 12px;
      }
    }

    .btn-close,
    .btn-back {
      color: #cdcdcd;
      font-size: 13px;
      position: absolute;
      height: 13px;
      line-height: 13px;
      padding: 0;
      top: 13px;
      right: 13px;
      cursor: pointer;

      &:hover {
        color: @color-secondary-copy;
      }
    }

    .btn-back {
      height: 45px;
      line-height: 45px;
      left: 0;
      font-size: 40px;
      top: 50%;
      margin-top: -22px;
      width: 20px;
      display: inline-block;

      i {
        margin: 0;
      }

      &:hover {
        color: @color-blue;
      }
    }
  }

  &-layout {
    display: flex;
    height: calc(100% - @report-header-height);
  }

  &-filters {
    width: 256px;
    min-height: 100%;
    overflow: auto;
  }

  &-content {
    width: calc(100% - 256px);
    min-height: 100%;
    padding: 12px 20px 0 15px;

    @report-table-border-color: #e8e8e8;

    .report-data-container {
      height: 100%;

      div.report-column-header {
        background: #fff;
      }

      div.public_fixedDataTable_main,
      div.public_fixedDataTable_header,
      div.public_fixedDataTableCell_main,
      div.public_Scrollbar_main,
      div.public_fixedDataTable_hasBottomBorder {
        border-color: @report-table-border-color;
      }

      div.public_fixedDataTable_horizontalScrollbar {
        border-top: 1px solid @report-table-border-color;
        margin-left: -1px;
      }

      div.public_fixedDataTableCell_columnResizerKnob {
        background-color: @color-blue;
      }

      div.public_Scrollbar_main {
        background: #fff;
      }

      div.public_fixedDataTable_scrollbarSpacer,
      div.public_fixedDataTable_header {
        background-color: transparent;
        background-image: none;
      }

      div.public_fixedDataTableRow_main {
        background: #fff !important;
      }

      div.public_fixedDataTableCell_cellContent {
        padding: 0;
      }
    }

    .report-data-count {
      font-size: 12px;
      color: @color-secondary-copy;
      padding-bottom: @offset-sm;
      line-height: 12px;
    }

    .report-column-header {
      font-size: 12px;
      background: @color-white;

      &-content {
        padding: 6px;
      }

      div {
        font-family: @font-family-medium !important;
      }
    }

    .report-data-cell {
      font-size: 12px;
      background: @color-white;
      border-bottom: 1px solid @report-table-border-color;
      cursor: pointer;

      &.no-wrap {
        white-space: nowrap;
      }

      &.active {
        background: #f2fafe;
      }

      &-value {
        max-width: calc(100%);
        padding: 6px;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
          color: @color-blue;
        }

        .capitalized {
          text-transform: capitalize;
        }

        .file-preview__link {
          display: inline-block;
          align-items: center;
          font-size: @font-size-sm;
          font-family: @font-family-semibold;
          padding: @offset-xs @offset-sm;
          border-radius: 8px;
          background-color: @color-light-grey;
          margin: 0 @offset-xs @offset-xs 0;
          max-width: calc(100% - (2 * @offset-md));
          color: @color-main-copy;
          .no-focus();

          & > span {
            display: inline-block;
            vertical-align: middle;
            line-height: 12px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .more-files-count {
          color: @color-blue;
        }
      }
    }

    .report-states {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 70%;

      .report-state {
        &__icon {
          color: #cccccc;
          font-size: 52px;
          margin-bottom: 24px;

          &.icon-task_field_save_ring {
            width: 49px;
            height: 49px;
            line-height: 46px;
            text-align: center;
            animation: rotate .3s linear infinite;
          }
        }

        &__header {
          font-size: 14px;
          margin-bottom: 8px;
        }

        &__summary {
          color: #959595;
          font-size: 12px;
        }
      }
    }
  }
}

.columns-dropdown {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .column-name {
      display: inline-block;
      margin-right: @offset-sm;
      width: 100%;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
    }

    .btn-icon {
      margin-left: 10px;
      color: @color-secondary-copy;

      .icon-lock {
        color: @color-blue;
      }
    }
  }
}

.export-dropdown {
  a:hover,
  a:focus {
    color: @color-blue;
  }

  i {
    color: @color-secondary-copy;
  }
}

.report-record-modal {
  @report-record-modal-width: 750px;

  &.modal-dialog {
    max-width: @report-record-modal-width + (4 * @offset-md);
    margin-bottom: 100px;

    .modal-header {
      padding-bottom: 20px;
      margin-right: 24px;
      border-bottom: 1px solid #e1ebef;

      h3 {
        line-height: @thumbnail-size;
        padding-right: 3*@offset-md;
        font-size: 21px;
      }
    }

    .modal-content {
      max-width: @report-record-modal-width;
    }

    .modal-body {
      margin-top: @offset-md;

      .form-group {
        @label-width: 170px;
        align-items: flex-start;

        & > label {
          min-width: @label-width;
          width: @label-width;
          flex: 0 1 @label-width;
          padding-right: @offset-lg;
          padding-top: @offset-sm;
          word-wrap: break-word;
          font-family: @font-family-medium;
        }

        .field-value {
          padding: @offset-sm;
          background: #f5f5f5;
          border-radius: 4px;
          min-height: 30px;
          min-width: 166px;

          &.process_title {
            background: transparent;
            padding-left: 2px;

            a {
              color: @color-main-copy;
            }

            a:hover,
            a:focus {
              color: @color-blue;
            }
          }

          i {
            margin-right: @offset-sm;
            width: 18px;
            text-align: center;
          }

          a {
            color: @color-blue;

            &.full-link {
              display: none;
            }
          }

          &.date_only_value,
          &.date_and_time {
            & > i {
              font-size: 18px;
            }
          }

          &.files_list {
            & > i {
              color: #ccc;
            }
          }

          &.radio_selector {
            display: flex;
            padding-right: 2px;
            justify-content: space-between;

            & > span {
              max-width: 138px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            & > i {
              font-size: 4px;
              vertical-align: top;
              margin-top: 6px;
              margin-right: 0;
            }
          }

          &.multi_selector {
            padding: 0;
            background: transparent;

            .checkbox {
              vertical-align: middle;
              margin-right: 8px;
              margin-left: 0;

              .checked {
                background-color: #f5f5f5 !important;
                border-color: #ccc !important;
              }
            }

            label + label {
              margin-top: 12px;
            }
          }

          &.money {
            display: flex;
            align-items: stretch;
            border: 1px #cacaca solid;
            border-radius: 4px;
            width: 180px;
            position: relative;

            .amount {
              margin-right: 30px;
            }

            .unit {
              border-left: 1px solid #cccccc;
              background-color: #f5f5f5;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              display: flex;
              flex: 1 0 auto;
              align-items: center;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              padding: 0 12px;
            }
          }

          &.yes_no {
            background: transparent;
            padding: 0;

            .helper {
              background-color: #e8e8e8;
              border-color: #ccc;

              &:before {
                background-color: #959595;
              }
            }
          }

          &.status {
            background: transparent;
            text-transform: capitalize;
            padding-left: 2px;
          }
        }

        .file {
          display: inline-flex;
          align-items: center;
          font-size: @font-size-sm;
          font-family: @font-family-semibold;
          padding: @offset-xs @offset-sm;
          border-radius: 8px;
          background-color: @color-light-grey;
          margin: 0 @offset-xs @offset-xs 0;
          max-width: calc(100% - (2 * @offset-md));
          .no-focus();

          & > i {
            margin-right: @offset-xs;
            color: @color-blue;
          }

          & > .file-preview__link {
            color: @color-main-copy;
          }

          & > span {
            color: @color-main-copy;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .actions {
          opacity: 0;
          padding: 4px 8px;

          .btn {
            color: @color-add-grey;

            &:hover,
            &:focus {
              color: @color-blue;
            }
          }
        }

        &:hover .actions {
          opacity: 1;
        }
      }
    }
  }
}

.status-formatter {
  text-transform: capitalize;

  &.active,
  &.assigned {
    color: #2e9df0;
  }

  &.completed,
  &.approved {
    color: #4abc3b;
  }

  &.not_assigned,
  &.inactive_review {
    color: #959595;
  }

  &.stopped {
    color: #D4AB00;
  }

  &.rejected {
    color: #f75f5f;
  }
}
