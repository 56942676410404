.template-data-model {
  width: 100%;

  &.filled {
    max-width: 640px;
  }

  .help-text {
    color: @color-secondary-copy;
    font-size: @font-size-sm;
    line-height: @font-size-sm * 1.5;
    padding: 5px 0;
    font-family: @font-family-regular;
    margin-top: -(@font-size-sm * 1.5 + 5);

    a {
      color: @color-blue;
    }
  }

  &__empty-form {
    padding: 2*@offset-md @offset-md;
    text-align: center;
    width: 100%;
    border: 1px solid @color-pale-blue;

    h4 {
      font-size: @font-size-md;
    }

    p {
      margin-top: @offset-sm;
      font-family: @font-family-semibold;
      color: @color-add-grey;
      font-size: @font-size-sm;
    }
    .btn-switcher {
      margin-top: @offset-md;
    }
  }

  &__content {
    width: 100%;

    &__section {
      margin: 0;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid @color-light-grey;

      & + .template-data-model__content__section {
        border-top: none;
      }

      & > header {
        width: 100%;
        min-height: 2*@offset-md + 2*@offset-sm;
        background-color: @color-panel-grey;
        padding: 0 @offset-sm 0 @offset-md;

        div {
          height: 2*@offset-md + 2*@offset-sm;
          display: flex;
          align-items: center;
          color: @color-add-grey;
          white-space: nowrap;

          & > span {
            margin-right: @offset-sm;
            cursor: pointer;
            visibility: hidden;

            &.visible {
              visibility: visible;
            }
          }

          i {
            cursor: pointer;
            .no-focus();

            &.icon-template_create_dragable {
              font-size: 18px;
              color: @color-grey;
              margin: 0;
            }
            &.action-icn {
              font-size: 26px;
              display: none;

              &:hover {
                color: @color-blue;
              }
            }
          }
        }

        &:hover {
          .action-icn {
            display: block;
          }
        }

        input {
          border: none;
          font-size: @font-size-lg;
          background: transparent;
          width: calc(100% - (10 * @offset-md + @offset-sm));
          cursor: pointer;

          &:focus {
            outline: none;
            cursor: text;
          }
        }

        .errors {
          font-size: @font-size-sm;
          color: @color-red;
          margin-top: -@offset-sm;
          display: block;
          margin-left: 2*@offset-md;
          padding-bottom: @offset-xs;
          font-family: @font-family-semibold;
        }
      }

      .fields-header {
        padding: @offset-sm 30px;
        display: flex;

        label {
          font-size: @font-size-sm;
          font-family: @font-family-regular;
          margin-left: @offset-sm;
          min-width: 155px;

          &:first-child {
            min-width: 140px;
            max-width: 340px;
            flex: 0 1 100%;
          }
        }
      }

      & > .btn-switcher {
        margin: @offset-md @offset-lg;
      }
    }
  }

  &__add-new {
    width: 100%;
    height: 2*@offset-md + 2*@offset-sm;
    white-space: nowrap;
    border: 1px solid @color-light-grey;
    display: flex;
    align-items: center;
    padding: 0 @offset-sm 0 @offset-md;
    cursor: pointer;
    color: @color-add-grey;
    font-size: @font-size-md;
    font-family: @font-family-semibold;
    margin-top: -1px;
    .no-focus();

    i {
      margin-right: @offset-xs;
      font-size: 18px;
    }

    &:hover {
      color: @color-blue;
      border-color: @color-blue;
    }
  }
}

.tmpl-data-model-fields {
  &__field {
    margin-top: 0;
    padding: 0 @offset-sm 0 @offset-md;
    position: relative;
    display: block;

    &.error {
      &.form-group {
        input {
          border-color: @color-red;

          &:focus {
            box-shadow: 0 0 0 1px @color-red;
          }
        }
      }
      .error-field-name {
        display: block;
      }
    }

    & + .tmpl-data-model-fields__field {
      margin-top: @offset-md;
    }

    & > .error-field-name {
      color: red;
      margin: @offset-xs 0 0 2*@offset-md+3;
    }

    &.form-group {
      & input {
        min-width: 138px;
        max-width: 340px;
        flex: 0 1 100%;
      }

      & .field-label-container {
        min-width: 138px;
        max-width: 340px;
        flex: 0 1 100%;
        position: relative;

        input {
          width: 100%;
          min-width: unset;
          max-width: unset;
          flex: unset;
          z-index: 1;
          position: relative;
          background: transparent;
        }

        .formula-hint {
          position: absolute;
          left: 8px;
          right: 8px;
          top: 9px;
          color: #999;
          overflow: hidden;
          white-space: pre;
          text-overflow: ellipsis;
          overflow-wrap: normal;
          z-index: 0;
        }
      }
    }

    & > div {
      display: flex;
      align-items: center;
      padding-right: 3*@offset-lg;
      position: relative;

      &:hover {
        .icon-group_delete,
        .icon-template_duplicate {
          display: block;
        }
      }

      .drag-handler {
        margin-right: @offset-sm;
        .no-focus();
        visibility: hidden;

        &.visible {
          visibility: visible;
        }
      }

      i {
        cursor: pointer;
        color: @color-add-grey;
        .no-focus();

        &.icon-template_create_dragable {
          font-size: 18px;
          color: @color-grey;
          min-width: @offset-md;
        }
        &.icon-template_duplicate {
          font-size: 26px;
          position: absolute;
          right: 26px + (@offset-sm * 2);
          display: none;
          top: 2px;

          &:hover {
            color: @color-blue;
          }
        }
        &.icon-group_delete {
          font-size: 26px;
          position: absolute;
          right: @offset-sm;
          display: none;
          top: 2px;

          &:hover {
            color: @color-blue;
          }
        }
        &.icon-assignee_badge_issued {
          color: @color-red;
          top: 10px;
          position: absolute;
        }
        &.icon-header_settings {
          margin-left: @offset-sm;
          font-size: @font-size-lg;
          min-width: 15px;

          &.active {
            color: @color-green;
          }
          &.error {
            color: @color-red;
          }
          &:hover:not(.error) {
            color: @color-blue;
          }
        }
      }
    }
  }
}

.as-sortable-dragging {
  .template-data-model__content__section {
    background: @color-white;
    box-shadow: 0 2px 8px @color-secondary-copy;
  }
}
