@import "../variables";
@import "../mixins";

.user-tooltip {
  display: table;
  flex: 0 1 auto;
  visibility: hidden;
  .native-size;
  position: absolute;
  z-index: @z-index-content;
  right: -50px;
  top: @thumbnail-size + @font-size-icon + @offset-xs;
  align-items: center;
  background: @color-white;
  padding: @offset-sm;
  .box-shadow();

  &__container {
    display: flex;
    align-items: center;

    .avatar {
      position: relative;
      z-index: 1;
    }

    div {
      display: inline-block;
      white-space: nowrap;
      position: relative;
      z-index: 10;
    }
  }

  .arrow {
    background: @color-white;
    display: block;
    height: 2*@font-size-icon;
    width: 2*@font-size-icon;
    border: 1px solid #f6f6f6;
    border-top: none;
    border-left: none;
    transform: rotate(225deg);
    top: -@font-size-icon;
    right: 54px;
    position: absolute;
  }

  span {
    display: table;
    white-space: nowrap;
    text-transform: none !important;
    line-height: 16px;
  }

  span:last-child {
    font-size: @font-size-xs;
    color: @main-task-link-color;
    margin-top: (@offset-xs / 2);
  }

  span:first-child {
    font-family: @font-family-semibold;
    font-size: @font-size-sm;
    color: @color-main-copy;
  }
}
