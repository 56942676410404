.date-alt-picker-drop {
  z-index: 1060;

  &.date-alt-picker-drop-open {
    .prc-picker-content.date-time-picker-content {
      display: block;
    }
  }

  .prc-picker-content.date-time-picker-content {
    position: relative;
    display: none;
    background: @color-white;
    padding: @offset-lg;
    .box-shadow();
    margin-top: @offset-md;
    font-size: @font-size-md;
    max-width: 300px;
    .no-focus();

    & > div {
      .no-focus();
    }

    .time {
      label {
        display: inline-flex;
        margin-left: @offset-md;
      }

      .icon-common_forms_required {
        font-size: 8px;
        margin-left: 4px;
        margin-top: 2px;
        display: inline-block;
      }
    }

    table {
      border-collapse: separate;

      th {
        color: @color-main-copy;
        font-weight: normal;
        font-family: @font-family-semibold;
      }
      thead {
        tr:first-child {
          th:first-child,
          th:last-child {
            position: relative;
            .no-focus();

            &:hover {
              background: transparent;
            }

            &::after {
              position: absolute;
              font-family: "metatask-icons";
              font-style: normal;
              font-weight: normal;
              speak: none;
              display: inline-block;
              text-decoration: inherit;
              width: 1em;
              margin-right: .2em;
              text-align: center;
              font-variant: normal;
              text-transform: none;
              line-height: 1em;
              margin-left: .2em;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              background: @color-white;
              color: rgb(163,163,163);
            }
          }

          th:first-child {
            &::after {
              left: 5px;
              content: '\e81b';
              transform: rotate(180deg);
            }
          }
          th:last-child {
            &::after {
              right: 5px;
              content: '\e81b';
            }
          }
        }
        tr:last-child {
          th {
            padding-top: 8px;
            padding-bottom: 6px;
            max-width: 36px;
            white-space: nowrap;
          }
        }
      }

      tbody {
        td, th {
          padding: 1px 0;
          position: relative;

          .tooltip {
            display: none;
            white-space: nowrap;
            position: absolute;
            top: 50%;
            left: 95%;
            padding: 10px;
            color: @color-white;
            font-size: @font-size-sm;
            background-color: rgba(0,0,0,0.7);
            z-index: 10;
          }

          &.left {
            span.disabled-min,
            span.disabled-max,
            span.disabled-min:hover,
            span.disabled-max:hover {
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
            }
          }

          &.right {
            span.disabled-min,
            span.disabled-max,
            span.disabled-min:hover,
            span.disabled-max:hover {
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }

          span.disabled-min,
          span.disabled-max,
          span.disabled-min:hover,
          span.disabled-max:hover {
            background: @color-panel-grey !important;
            color: @color-grey !important;
            text-shadow: none !important;
            border: none !important;
            cursor: not-allowed !important;
            border-radius: 0 !important;
            height: 36px !important;
            line-height: 35px !important;
          }

          span.disabled-min:hover + .tooltip,
          span.disabled-max:hover + .tooltip {
            display: block;
          }

          span,
          span.now {
            display: inline-block;
            height: 36px;
            width: 36px;
            line-height: 35px;
            background: transparent;
            border: 1px solid transparent;
            .no-focus();

            &:active {
              border-radius: 100%;
              background: @color-blue;
              color: @color-white;
              text-shadow: none;
            }
            &:hover,
            &.active {
              border-color: @color-blue;
              border-radius: 100%;
              background: transparent;
              color: @color-blue;
              text-shadow: none;
            }
            &.now {
              background: @color-light-grey;
              border-color: @color-dark-grey;
              color: @color-main-copy;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
}