@import "../../../less/variables";
@import "../../../less/mixins";

.form-wrapper {
  max-height: 100%;
  padding-bottom: @offset-lg;
  overflow: auto;
}

.form-container {
  width: 100%;
  max-width: @login-container-width;
  margin: @header-offset auto 0 auto;
  padding: 2*@offset-lg;
  box-shadow: 0 0 15px @color-grey;

  & > h1 {
    font-size: 21px;
    width: 100%;
    text-align: center;
    margin-bottom: 2*@offset-lg;
    font-family: @font-family-regular;

    & > span {
      font-size: 14px;
      display: block;
      text-align: center;
      margin-top: @offset-xs;
    }
  }

  & > p {
    margin-bottom: 2*@offset-md;
    font-size: @font-sm-size;
    line-height: @font-sm-size + @offset-xs;

    a {
      color: @color-blue;
      font-family: @font-family-semibold;
    }
  }

  #resetForm {
    border-bottom: none;

    footer {
      margin-bottom: 0;
    }
  }
}
