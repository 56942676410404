@import "../../../less/variables";
@import "../../../less/mixins";

@keyframes cursor-blink {
  0% {
    border-color: #222;
  }
  50% {
    border-color: transparent;
  }
}

.expression-input {
  position: relative;
  border: 1px solid @input-border-color;
  min-height: 32px;
  border-radius: 4px;

  .text-view {
    margin-right: 32px;
    min-height: 30px;
    line-height: 24px;
    position: relative;
    z-index: 1;
    padding: 3px 6px;
    border-right: 1px solid @input-border-color;
    border-radius: 4px 0 0 4px;
    user-select: none;
  }

  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 32px;
    min-height: 30px;
    line-height: 24px;
    padding: 3px 6px;
    color: @site-menu-color;
    white-space: nowrap;
    overflow: hidden;
    right: 0;
  }

  &.has-value {
    .text-view {
      background: @color-white;
    }
  }

  .node {
    display: inline-block;
    position: relative;

    padding-left: 6px;
    margin-left: -6px;

    & + .node {
      padding-left: 3px;
      margin-left: -3px;

      &.last-child {
        padding-left: 0;
      }
    }

    .cursor {
      position: absolute;
      border-left: 1px solid transparent;
      top: 5px;
      height: 14px;
    }

    .char {
      display: inline-block;
      padding: 4px 0;
      line-height: 1;
    }

    .replacement {
      display: inline-block;
      padding: 4px 8px;
      line-height: 1;
      border-radius: 6px;
      background-color: #e8e8e8
    }

    &.selected {
      .char {
        background-color: #accef7;
      }

      .replacement {
        background-color: #3399ff;
      }
    }
  }

  input {
    position: absolute;
    z-index: 0;
    display: block;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .show-replacements-button {
    display: block;
    position: absolute;
    z-index: 2;
    height: 32px;
    width: 32px;
    top: 50%;
    right: 1px;
    margin-top: -16px;
    line-height: 32px;
    text-align: center;
    padding: 0;
    background: transparent;
    border: 0;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  &.focus {
    border: 2px solid @color-blue;

    .text-view {
      padding: 2px 5px;
      min-height: 28px;
      margin-right: 31px;
      border-right: 2px solid @color-blue;
    }

    .placeholder {
      padding: 2px 5px;
      min-height: 28px;
      margin-right: 31px;
    }

    .node.has-cursor .cursor {
      animation: cursor-blink .5s step-end infinite alternate;
    }
    .show-replacements-button {
      right: 0;
    }
  }

  &.error {
    border: 2px solid @color-red;

    .text-view {
      padding: 2px 5px;
      min-height: 28px;
      margin-right: 31px;
      border-right: 2px solid @color-red;
    }

    .placeholder {
      padding: 2px 5px;
      min-height: 28px;
      margin-right: 31px;
    }

    .show-replacements-button {
      right: 0;
    }
  }

  &.disabled {
    border: 1px solid #cacaca;
    background-color: @gray-color;

    .text-view {
      display: none;
    }
  }
}
