@import "variables";
@import "mixins";

.avatar {
  height: @thumbnail-big-size;
  width: @thumbnail-big-size;
  max-width: @thumbnail-big-size !important;
  min-width: @thumbnail-big-size !important;
  line-height: @thumbnail-big-size;
  border-radius: @thumbnail-big-border-radius;
  font-size: @font-size-lg;
  font-family: @font-family-semibold;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  margin-right: 6px;
  color: @color-white;
}

.avatar-small {
  height: @thumbnail-size;
  width: @thumbnail-size;
  max-width: @thumbnail-size !important;
  min-width: @thumbnail-size !important;
  line-height: @thumbnail-size;
  border-radius: @thumbnail-border-radius;
  font-size: @font-size-xs;
  font-family: @font-family-bold;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  color: @color-white;
  overflow: hidden;
}
