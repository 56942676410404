@import "../../../less/variables";
@import "../../../less/mixins";

.no-permission-section {
  border: 1px solid @color-pale-blue;
  padding: 4*@offset-lg 0;
  margin: @offset-lg auto;
  width: calc(100% - (4 * @offset-lg));
  text-align: center;

  h5 {
    color: @color-main-copy;
    font-size: @font-size-md;
    margin-top: 2*@offset-md;
    white-space: pre-wrap;
  }

  p {
    margin-top: @offset-sm;
    font-size: @font-size-sm;
    color: @color-secondary-copy;
    white-space: pre-wrap;
  }

  a {
    color: @color-blue;
    margin-top: @offset-sm;
    font-size: @font-size-sm;
    font-family: @font-family-semibold;
  }
}
