@import "../../../less/variables";
@import "../../../less/mixins";

@media only screen and (max-width: @tablet-portrait-size) {
  .templates {
    &__main {
      padding: 0 !important;
    }
  }
}

@media only screen and (max-width: @phone-portrait-size) {
  .templates__header {
    div {
      & > a {
        .short {
          display: inline;
        }

        .full {
          display: none;
        }
      }

      h3 + a {
        margin-left: @offset-md;
      }
    }
  }
}


@media only screen and (max-width: 850px) {
  .templates__header.searching {
    .search-block {
      input {
        width: 240px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .templates__header.searching {
    .search-block {
      input {
        width: 160px;
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  .templates__header {
    padding: 0 @offset-lg !important;

    div {
      a {
        span {
          display: none !important;
        }
      }
    }

    .search-block {
      position: relative;
      height: 32px;
      width: 34px;
      flex: 0 0 34px;

      input {
        width: 30px;
        border-radius: 16px;
        padding-left: 2px;
        position: absolute;
        right: 0;
      }
    }

    &.searching {
      .templates__header__main-section {
        display: none;
      }

      .search-block {
        width: 100%;
        flex: 0 0 100%;
        margin: 0;

        input {
          width: 100%;
          border-radius: 4px;
          padding: 8px 30px;
        }
      }
    }
  }
}
