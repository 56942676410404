@import "../../less/variables";
@import "../../less/mixins";

.react-selectize {
  &__control {
    color: @color-main-copy;
    font-size: @font-size-sm;
    border: 1px @border-button-gray-color solid;
    border-radius: 6px;
    min-height: 34px !important;
    cursor: pointer !important;
    .no-focus();

    &--is-focused {
      border-color: @color-blue !important;
      box-shadow: 0 0 0 1px @color-blue !important;
    }
  }

  &__indicators {
    align-items: flex-start;
  }

  &__clear-indicator {
    font-size: 8px;
    color: @color-secondary-copy !important;
    opacity: .8;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    padding: 0 !important;

    i {
      font-size: 4px;
      margin-right: 8px;
      color: @color-main-copy;
    }
  }

  &__value-container {
    padding: 2px !important;
  }

  &__single-value {
    font-size: 12px !important;
    line-height: 24px;
    padding: 0 !important;
    display: inline-flex;
  }

  &__multi-value {
    background-color: #e8e8e8;
    cursor: pointer;
    padding: 0 !important;
    border-radius: 6px !important;

    &__label {
      font-size: 12px !important;
      line-height: 24px;
      padding: 0 0 0 8px !important;
      display: inline-flex;
    }

    &__remove {
      color: #a3a3a3;

      &:hover {
        background-color: transparent !important;
        color: #222 !important
      }
    }
  }

  &__single-value,
  &__multi-value {
    .value-icn,
    .option-icn {
      line-height: 24px;
      width: 24px;
      padding-right: 0;
      font-size: 12px;
      border-radius: 8px;
      color: @color-asphalt-grey;
      text-align: center;
      flex: 0 0 24px;
      display: inline-block;
      overflow: hidden;
      margin-right: 4px;
    }

    .value-label {
      padding-left: 4px;
    }
  }

  &__multi-value {
    .value-icn,
    .option-icn {
      margin-left: -8px;
      margin-right: 5px;
    }
  }

  &__placeholder {
    margin-left: 6px !important;

    .value-icn {
      display: inline-block;
      width: 24px;
      margin-left: -4px;
      text-align: center;
    }
  }

  &__input {
    margin-left: 4px;

    input {
      box-shadow: unset !important;
    }
  }

  &__menu {
    border-radius: 0 !important;
    margin-top: 2px !important;
    min-width: 100%;
    width: auto;
    max-width: 250px;
  }

  &__menu-list {
    padding: 10px !important;
  }

  &__option {
    font-size: 12px !important;
    padding: 0 !important;
    white-space: nowrap;
    height: 24px;
    cursor: pointer !important;

    & > span {
      display: inline-flex;
      padding-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      line-height: 24px;
    }

    .spacer {
      width: 8px;
      display: inline-block;
    }

    .option-icn {
      line-height: 24px;
      width: 24px;
      padding-right: 0;
      margin: 0;
      font-size: 12px;
      border-radius: 8px;
      color: @color-asphalt-grey;
      text-align: center;
      flex: 0 0 24px;
      display: inline-block;
      overflow: hidden;
      background-color: #e8e8e8;
      margin-right: 4px;
    }

    .option-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-radius: 8px;

      .highlighted {
        color: sandybrown;
      }
    }

    &--is-focused,
    &--is-selected {
      background-color: transparent !important;
      color: @color-main-copy !important;

      .option-label {
        background-color: #e8e8e8;
      }
    }
  }

  &__option + &__option {
    margin-top: 8px;
  }

  &__menu-notice {
    font-size: 12px;
    line-height: 24px;
    height: 24px;
    padding: 0 !important;
    white-space: nowrap;
  }
}

.value-icn,
.option-icn {
  .thumbnail {
    width: 24px;
    height: 24px;

    span {
      color: #fff;
      text-transform: uppercase;
    }

    img {
      width: 100%;
    }

    .badge-deleted {
      height: @font-size-md;
      line-height: @font-size-md - 1;
      width: @font-size-md;
      border-radius: 100%;
      background-color: @color-red;
      position: absolute;
      text-align: center;
      bottom: 0;
      left: 16px;
      z-index: 2;

      i {
        font-size: 8px;
        margin: 0 0 0 1px;
        color: @color-white;
      }
    }
  }
}

.has-error {
  .react-selectize {
    &__control {
      border: 1px @color-red solid;

      &--is-focused {
        border-color: @color-red !important;
        box-shadow: 0 0 0 1px @color-red !important;
      }
    }
  }
}
