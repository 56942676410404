@import "../../../less/variables";
@import "../../../less/mixins";

.task-process-section-header {
  padding: 0 @offset-md;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.bg-gray {
    background-color: @color-head-bg-grey;
  }

  & > .first-row {
    display: inline-flex;
    align-items: center;
    flex: 1 1 auto;

    & > .avatar-small {
      margin-right: @offset-md;
      color: @color-asphalt-grey;
      min-width: @thumbnail-size;

      i {
        font-size: @font-size-md;
        display: block;

        &.icon-Mens,
        &.icon-Magnifi-Glass2 {
          font-size: 12px;
        }
      }

      &.default {
        background-color: @color-grey;
      }
    }

    & > a {
      color: @color-main-copy;
      text-decoration: none;
      font-size: @font-size-md;
      position: relative;
      font-family: @font-family-semibold;
      display: inline-flex;
      max-width: 100%;

      & > span.process-label > a{
        color: @color-main-copy;
      }

      & > span {
        font-family: @font-family-medium;
      }

      &:hover {
        color: @border-blue-color;

        .link-hint-template {
          display: inline-flex;
        }
      }

      .link-hint-template {
        top: calc(100% + 12px);
        min-width: @main-task-link-template-size;
        left: -@offset-md;

        .square {
          left: 15px;
        }

        .top-line {
          height: 9px;
          .top-line();
        }
        &__header {
          margin-bottom: -@offset-sm;
        }

        &__content {
          .inline-flex;
          padding-right: 12px;
          margin-top: @offset-xs;
          white-space: nowrap;

          ul {
            flex: 0 1 (3*@main-task-link-template-size/4 + 5px);
            min-width: (3*@main-task-link-template-size/4 + 5px);
            float: left;

            li {
              display: inline-block;
              list-style: none;
              float: left;
              height: 3px;
              margin-right: 1px;

              &.not-assigned:not(.in-process):not(.completed):not(.inactive):not(.approved):not(.rejected):not(.stopped-on-reject) {
                background-clip: padding-box;
                background-color: @color-grey;
                background-image: linear-gradient(-45deg, rgba(255, 255, 255, .3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, .5) 75%, transparent 75%, transparent);
                background-size: 10px 10px;
              }
              &:not(.inactive).in-process {
                background-color: @colot-assigned-grey;
              }
              &:not(.inactive).completed, &.approved {
                background: @color-green;
              }
              &:not(.inactive).issued {
                background: @color-yellow;
              }
              &:not(.inactive).upcoming, &.rejected {
                background-color: @color-red;
              }
              &.not-assigned.upcoming:not(.stopped-on-reject):not(.inactive) {
                background-color: @color-red !important;
              }
              &:not(.inactive):not(.stopped-on-reject).upcoming.approved {
                background-color: @color-green !important;
              }
              &.inactive, &.stopped-on-reject, &.upcoming.stopped-on-reject {
                background-color: @color-white;
                border: 1px solid @to-do-color;
              }
              &.upcoming.inactive {
                background-color: @color-white;
                border: 1px solid @color-red;
              }
            }
          }

          span {
            margin-left: 5px;
          }
        }
      }
    }
  }

  & > .second-row {
    text-align: right;
    white-space: nowrap;
    min-width: 250px;

    .top {
      color: #a0a4a6;
      font-size: @font-size-sm;
      min-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      .owner-name {
        color: @color-main-copy;
      }
    }
    .dueDate {
      font-size: @font-size-sm;
      margin-top: 2px;
      color: @color-secondary-copy;

      .red {
        color: @color-red;
      }
    }
  }

  @media only screen and (max-width: @tablet-portrait-size) {
    & > .second-row {
      display: none;
    }
  }
}

.task-process-section-header--empty {
  padding: 0 @offset-md 0 @offset-lg;
  height: 52px;
  display: flex;
  align-items: center;
  font-size: @font-size-md;
  font-family: @font-family-medium;

  &.bg-gray {
    background-color: @color-head-bg-grey;
  }
}
