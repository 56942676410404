@import "../../../less/variables";
@import "../../../less/mixins";

@import (inline) "react-day-picker/lib/style.css";

.date-picker-container {
  background: @color-white;
  .box-shadow();
  .no-focus();

  padding-bottom: 1em;
}

.DayPicker {
  font-size: 14px;

  &-Day {
    padding: 0;
    border-radius: 0;
    background: transparent !important;
    .no-focus();

    span {
      display: inline-block;
      height: 36px;
      width: 36px;
      line-height: 35px;
      border: 1px solid transparent;
      border-radius: 50%;
    }

    &--weekends {
      color: @color-red;
    }

    &--today {
      span {
        border-color: #767676;
        background: #e8e8e8;
        color: #222;
      }
    }

    &--selected {
      span {
        border-color: #2e9df0;
        background: transparent;
        color: #2e9df0;
      }
    }
  }
}

.date-picker-container {
  .form-group.time {
    margin: 0 2em;

    label {
      i {
        font-size: 8px;
        margin-left: 4px;
        margin-top: 2px;
        display: inline-block;
        vertical-align: text-top;
      }
    }
  }
}

