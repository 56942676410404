@import "../../../less/variables";
@import "../../../less/mixins";

.admin__main__settings {
  height: 100%;
  max-height: calc(100% - (4 * @offset-lg));
  overflow-y: auto;
  flex: 0 1 auto;
  min-width: 50%;
  width: 50%;
  padding: 2*@offset-md 2*@offset-md 2*@offset-md 2*@offset-md + @offset-lg;
  border: 1px solid @color-pale-blue;
  position: relative;
  .no-focus();

  &.ng-hide-add, &.ng-hide-remove {
    transition: all linear 0.2s;
  }
  &.ng-hide {
    opacity: 0;
  }

  & > .icon-common_close {
    color: @border-button-gray-color;
    cursor: pointer;
    font-size: @font-size-close-btn;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: @z-index-content;

    &:hover {
      color: @color-dark-grey;
    }
  }

  .selectize-control .selectize-dropdown .dropdown-list > ul > li header > div.empty {
    margin-left: 2px;
  }

  @profile-photo-size: 80px;

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    font-family: @font-family-semibold;

    .photo {
      flex: 0 1 @profile-photo-size;
      width: @profile-photo-size;
      min-width: @profile-photo-size;
      height: @profile-photo-size;
      line-height: @profile-photo-size;
      border-radius: @buttons-border-radius;
      background-color: @color-light-grey;
      color: @color-white;
      text-align: center;
      font-size: 30px;
      text-transform: uppercase;

      &.group-photo {
        color: @color-add-grey;
        font-size: 32px;
      }

      &.channel-icon {
        background: #cacaca;
        color: #fff;
        font-size: 40px;
      }
    }

    & > main {
      color: @color-main-copy;
      margin-left: @offset-lg;
      font-family: @font-family-medium;
      font-size: 21px;
      width: calc(100% - (@profile-photo-size + @offset-lg));

      & > .email {
        color: @color-main-copy;
        font-family: @font-family-medium;
        font-size: 21px;
        max-width: calc(100% - (2 * @offset-md));
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & > header {
        input {
          appearance: none;
          flex: 0 1 100%;
          width: calc(100% - (@profile-photo-size + @offset-md + 20px));
          max-width: 320px;
          padding: 4px 7px;
          font-size: @font-size-lg;
          .input-border;
          border-width: 1px;
          color: @color-main-copy;
          resize: none;
          outline: 0;
          cursor: pointer;
          position: absolute;
          top: 0;

          &.has-error,
          &.has-error:focus {
            border-color: @color-red;
          }

          &:focus {
            outline: none;
            border-color: @color-blue;
            border-width: 2px;
          }
        }

        & > label {
          &.invisible {
            visibility: hidden;
          }
        }

        span {
          display: block;
          margin-top: 6px;
          margin-bottom: -12px;
          font-size: 12px;

          & + span {
            margin-top: 10px;
          }

          &.input-hint--info {
            color: @color-blue;
          }
          &.input-hint--error {
            color: @color-red;
          }
        }
      }

      & > span {
        font-size: @font-size-sm;
        color: @color-secondary-copy;
        display: block;
        font-family: @font-family-semibold;
        margin-top: @offset-xs;
      }

      & > ul {
        display: flex;
        align-items: center;
        margin-top: @offset-sm;

        &.group-actions {
          margin-top: @offset-lg;
        }

        li + li {
          margin-left: @offset-sm;
        }
      }
    }
  }

  &__info {
    display: inline-flex;
    align-items: center;
    font-size: @font-size-sm;
    color: @color-secondary-copy;
    margin: 24px 0 0;
    font-family: @font-family-semibold;

    & > .ava-wrapper {
      margin-right: @offset-sm;
      position: relative;
      cursor: pointer;

      .user-tooltip {
        visibility: visible;
        display: none;
        left: -20px;

        .arrow {
          left: 24px;
          top: -9px;
        }

        span {
          font-family: @font-family-semibold;
          font-size: @font-size-sm;
          color: @color-main-copy;
        }
      }

      &:hover {
        .user-tooltip {
          display: table;
        }
      }
    }

    & > span {
      color: @color-main-copy;
    }

    .invite-label {
      color: @color-secondary-copy;

      .invited-by-name {
        color: @color-main-copy;
      }
    }

    .invite-link-expired {
      display: inline-block;
      padding: 2px 0;
      line-height: 1;
      font-size: 12px;
      vertical-align: middle;
      color: @color-secondary-copy;
    }

    .btn-invite-link {
      margin-left: -4px;
      padding: 2px 4px;
      line-height: 1;
      height: auto;
      font-size: @font-sm-size;
    }
  }

  & > .manage-roles {
    color: @color-secondary-copy;
    display: flex;
    align-items: center;
    font-size: @font-size-sm;
    font-family: @font-family-semibold;
    margin-top: 24px;

    a {
      color: @color-blue;
      margin-left: @offset-sm;
      font-family: @font-family-semibold;
    }

    & > span {
      color: @color-main-copy;
      position: relative;

      &:hover {
        .tooltip-info {
          visibility: visible;
        }
      }

      .tooltip-info {
        position: absolute;
        display: table;
        color: @color-secondary-copy;
        visibility: hidden;
        .native-size;
        z-index: @z-index-content;
        left: -40px;
        top: 25px;
        width: 230px;
        align-items: center;
        background: @color-white;
        padding: @offset-sm;
        .box-shadow();

        &:after {
          background: @color-white;
          display: block;
          height: 2*@font-size-icon;
          width: 2*@font-size-icon;
          border: 1px solid #f6f6f6;
          border-top: none;
          border-left: none;
          transform: rotate(225deg);
          top: -@font-size-icon;
          left: 60px;
          position: absolute;
          content: '';
        }
      }
    }
  }

  &__groups,
  &__users {
    margin: 24px 0 0;
    & .errors {
      margin-top: 0;
    }

    & > header {
      display: inline-flex;
      align-items: center;
      font-size: @font-size-sm;
      color: @color-secondary-copy;
      font-family: @font-family-semibold;

      .icon-task_field_save_ring {
        animation: rotate .3s linear infinite;
      }
      a {
        color: @color-blue;
        margin-left: @offset-sm;
        font-family: @font-family-semibold;
        &.disabledLink {
          color: #D3D3D3;
          cursor: not-allowed;
        }
      }

      .text {
        color: @color-main-copy;
      }
    }

    & > .group,
    & > .user {
      display: flex;
      align-items: center;
      padding-right: 8px;
      border-radius: @thumbnail-border-radius;
      cursor: default;
      font-family: @font-family-semibold;
      margin-top: @offset-sm;
      font-size: @font-size-sm;

      &:first-child {
        margin-top: @offset-md;
      }

      .avatar-small {
        margin-right: @offset-xs;
      }

      span:not(.text) {
        line-height: @thumbnail-size;
        width: @thumbnail-size;
        border-radius: @thumbnail-border-radius;
        text-align: center;
        background-color: @color-light-grey;
        color: @color-add-grey;
        display: inline-block;
        margin-right: @offset-xs;
        cursor: default;
        font-family: @font-family-semibold;
      }
    }
  }

  &__channel {
    .notifications {
      font-size: 12px;
      margin: 20px 0;

      header {
        color: #959595;
        margin: 16px 0;

        .status {
          .saving {
            i {
              animation: rotate .3s linear infinite;
            }
          }
          .saved {
            color: @color-green;
          }
        }
      }

      .notification {
        label {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .checkbox {
            margin-left: 0;
          }
        }
      }
    }
  }
}
