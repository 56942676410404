.image-viewer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;

  & > div {
    overflow: hidden;
  }

  img {
    width: 100%;
    transform-origin: top left;
  }

  &.rotate90 {
    img {
      transform: rotate(90deg) translateY(-100%);
    }
  }

  &.rotate180 {
    img {
      transform: rotate(180deg) translate(-100%, -100%);
    }
  }

  &.rotate270 {
    img {
      transform: rotate(270deg) translateX(-100%);
    }
  }

  .new-loading-spinner {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
  }
}
