@import "../../../less/variables";
@import "../../../less/mixins";

.icons-select {
  position: absolute;
  z-index: @z-index-over-content;
  left: @site-padding;
  top: 4*@offset-lg + 5px;
  width: 100%;
  max-width: @icons-select-width + 10px;
  box-shadow: 0 2px 10px @color-secondary-copy;
  background: @color-white;

  .arrow {
    background: @color-white;
    display: block;
    height: 2*@font-size-icon;
    width: 2*@font-size-icon;
    border: 1px solid @color-light-grey;
    border-top: none;
    border-left: none;
    transform: rotate(225deg);
    left: ((@icons-select-icon-size / 2) - 7px);
    top: @font-size-icon * -1;
    position: absolute;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: @z-index-content;

    &:focus {
      outline: none;
    }
  }

  &__list {
    padding: @offset-lg @offset-sm 0;
    z-index: @z-index-over-content;
    position: relative;

    h3 {
      .native-size;
      font-family: @font-family-semibold;
      font-weight: normal;
      font-size: @font-size-sm;
      margin: 0 0 0 @offset-xs;
    }

    .icons {
      .native-size;
      width: 100%;
      max-height: @icons-select-list-height;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      li {
        margin: @offset-sm @offset-xs 0 @offset-xs;
        width: @icons-select-icon-size + 5px;
        min-width: @icons-select-icon-size + 5px;
        height: @icons-select-icon-size + 5px;
        line-height: @icons-select-icon-size + 5px;
        border-radius: @icons-select-icon-border-radius;
        vertical-align: middle;
        cursor: pointer;

        &:focus {
          outline: none;
        }

        i {
          display: table;
          margin: 0 auto;
          font-size: 24px;
          line-height: @icons-select-icon-size + 5px;

          &.icon-Mens {
            font-size: 26px;
          }

          &.icon-Magnifi-Glass2 {
            font-size: 22px;
          }

          &::before {
            margin: 0;
          }
        }
      }
    }
  }

  &__colors {
    padding: @offset-md @offset-lg @offset-lg;
    background-color: @color-panel-grey;
    z-index: @z-index-over-content;
    position: relative;

    h3 {
      .native-size;
      font-family: @font-family-semibold;
      font-weight: normal;
      color: @color-main-copy;
      font-size: @font-size-sm;
    }

    .colors {
      .native-size;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-top: @offset-sm;

      li {
        height: @icons-select-color-size;
        width: @icons-select-color-size;
        border-radius: 100%;
        cursor: pointer;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          width: @icons-select-color-size + 4px;
          height: @icons-select-color-size + 4px;
          border-radius: 100%;
          border: 1px solid transparent;
          transition: border .15s ease;
        }

        &:hover,
        &.selected {
          &:after {
            border-color: inherit;
          }
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
