@import "../../../less/variables";
@import "../../../less/mixins";

.actors-field {
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 100%;

  .group {
    .avatar-small {
      background: #e8e8e8;
      color: #959595;

      .icon-assignee_group, .icon-assignee_process_starter, .icon-assignee_process_manager {
        display: block;
        font-size: @font-size-md;
        width: auto;
      }
    }
    & > span:not(.text) {
      width: 24px;
      text-align: center;
    }
  }
  .user {
    display: table;

    div {
      display: inline-block;
    }
  }

  & > div {
    display: table;
    margin-right: @offset-sm;
    margin-top: @offset-xs;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    .text {
      color: @color-main-copy;
    }

    span {
      display: inline-block;
      font-size: @font-size-sm;
    }

    div + .text, span + .text, img + .text {
      margin-left: @offset-xs;
    }
  }
}
