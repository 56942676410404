@import "../../less/variables";
@import "../../less/mixins";

.user-field-settings {
  .single-select {
    .icon {
      color: @color-add-grey;
    }

    &.has-value {
      .single-select-header {
        .icon {
          background: @color-light-grey;
          border-radius: @thumbnail-border-radius 0 0 @thumbnail-border-radius;
        }

        .value {
          background: @color-light-grey;
          border-radius: 0 @thumbnail-border-radius @thumbnail-border-radius 0;
          margin-left: -4px;
          padding-left: 4px;
          padding-right: 6px;
        }
      }

      &.opened {
        .single-select-header {
          .icon,
          .value {
            background: transparent;
          }
        }
      }
    }

    .control {
      input {
        flex: none !important;
        width: 100% !important;
      }
    }
  }
}
