.filter-dropdown {
  position: absolute;
  z-index: @z-index-content;
  top: 30px;
  left: -4px;
  background: @color-white;
  .box-shadow();

  li {
    a {
      font-size: @font-size-md;
      color: @color-main-copy;
      padding: 8px @offset-md 8px 25px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      .no-focus();

      &.active {
        padding-left: 8px;

        i {
          display: block;
        }
      }

      i {
        display: none;
        font-size: @font-size-icon;
        color: @color-green !important;
        margin-right: 6px;
      }

      &:hover {
        color: @color-white;
        background-color: @color-green;

        i {
          color: @color-white;
        }
      }
    }
  }
}