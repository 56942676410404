@import "../../../less/variables";
@import "../../../less/mixins";

.datatables {
  height: 100%;

  @header-height: 4*@offset-lg;

  &__header {
    .inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 @site-padding;
    height: @header-height;
    font-family: @font-family-medium;
    position: relative;

    &__item {
      .inline-flex;
      align-items: center;
      font-weight: normal;
      font-size: @font-text-size;
    }

    h3 {
      align-items: center;
      font-weight: normal;
      color: @site-menu-color-hov;
      font-size: @font-text-size;
      margin-right: 24px;
    }
  }

  .tables-list-scroller {
    overflow: scroll;
    height: calc(100% - @header-height);
    .google-scroll-shadow();
  }

  .tables-list {
    display: flex;
    padding: 6px (@site-padding - 12);
    flex-flow: row wrap;

    &__item {
      width: 250px;
      height: 150px;
      flex: 0 0 auto;
      border: 1px solid @color-pale-blue;
      border-radius: 8px;
      display: flex;
      margin: 0 12px 24px;

      .table-icon {
        margin: 16px;
        display: inline-block;
        width: 40px;
        height: 40px;
        flex: 0 0 auto;
      }

      .table-info {
        padding: 16px;
        padding-left: 0;
        position: relative;
        height: 100%;

        .table-name {
          font-size: 14px;
          font-family: @font-family-medium;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .table-description {
          margin-top: 6px;
          font-size: 12px;
          line-height: 16px;
          max-height: 66px;
          color: @color-secondary-copy;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &:hover {
        border-color: @color-blue;
        box-shadow: 0 0 0 1px @color-blue;
      }
    }

    .loading-spinner {
      width: 100%;
      margin: -20px 0 0 20px !important;
    }
  }

  &__empty-state {
    border: 1px solid @color-pale-blue;
    padding: 4*@offset-lg 0;
    margin: 0 32px;
    text-align: center;

    i {
      font-size: 64px;
      color: #d1d1d1;
    }

    h5 {
      color: @color-main-copy;
      font-size: @font-size-md;
      margin: 2*@offset-md auto 0;
      max-width: 250px;
    }

    p {
      color: @color-add-grey;
      font-size: @font-size-sm;
      margin: @offset-sm auto 0;
      max-width: 250px;

      a {
        color: @color-blue;
      }
    }
  }
}
