.password-modal {
  @label-width: 150px;

  h3 {
    text-align: center;
  }

  .password-form {
    .form-hint {
      margin-top: @offset-md;
      margin-bottom: -@offset-md;
    }

    label {
      flex: 0 1 @label-width;
      min-width: @label-width;
    }

    .input-hint--error {
      margin-left: @label-width;
    }
  }

  .modal-footer {
    margin-top: 2*@offset-md;
    padding-left: @label-width;
  }
}
