@import "../../../less/variables";
@import "../../../less/mixins";

.profile__main__header {
  .inline-flex;
  width: 100%;
  padding: 0 @site-padding;
  height: 4*@offset-lg;

  &__nav {
    display: inline-flex;

    li {
      .native-size;

      & + li {
        margin-left: @offset-sm;
      }
    }
  }
}
