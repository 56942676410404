@import "../../../../less/variables";
@import "../../../../less/mixins";

.team-creation-screen {
  .left-screen {
    flex: 0 1 50%;

    .screen {
      max-width: 400px;
      margin: 0 auto;

      header {
        margin: 2*@offset-lg auto;
        width: 150px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        background-color: @color-light-grey;

        a {
          color: @color-secondary-copy;
          font-size: @font-size-md;
        }
      }

      form {
        h1 {
          text-align: center;
          margin: 0 0 3*@offset-md 0;
        }

        p {
          font-size: @font-size-sm;
        }

        .teams {
          margin: 2*@offset-md 0;
          max-height: calc(100vh - 480px);
          overflow: auto;

          &__team {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid @color-pale-blue;
            padding: @offset-lg;

            & + .teams__team {
              border-top: none;
            }

            main {
              flex: 0 1 calc(100% - 80px);

              p {
                font-family: @font-family-semibold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.name {
                  color: @color-main-copy;
                  font-size: @font-size-md;
                }
                &.members {
                  margin-top: @offset-xs;
                  color: @color-add-grey;
                }
              }
            }

            aside {
              flex: 0 1 auto;
              white-space: nowrap;
            }
          }
        } // END: .teams

        .fieldset.emails {
          align-items: flex-start;

          label {
            margin-top: @offset-md;
          }
        }

        footer {
          margin-bottom: @offset-md;

          &.justify-end {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button + button {
              margin-left: @offset-sm;
              margin-right: 0;
            }
          }

          .btn {
            margin: 0 auto;
          }
        }
      }

      .steps {
        display: table;
        margin: 0 auto;

        ul {
          display: inline-flex;
          align-items: center;
        }

        li {
          height: @offset-sm;
          width: @offset-lg;
          background-color: @color-light-grey;
          border-radius: 5px;
          display: inline-block;

          &.completed {
            background-color: @color-green;
          }

          & + li {
            margin-left: @offset-xs;
          }
        }
      }
    }
  }
}
