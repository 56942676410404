/* Primary colors */
/* Font-family */
/* Secondary colors */
/* Primaty offsets */
/* Font-sizes */
/* Thumbnail */
/* Forms */
/* Input sizes */
/* Z-indexes */
/*
 * FROM /modules/home/less/variables
 */
/* Site menu */
/* Tasks main */
/* Tasks list */
/*
 * FROM /modules/auth/less/variables
 */
/*
 * FROM /modules/processes/less/variables
 */
.inline-flex {
  display: inline-flex;
  align-items: center;
}
.native-size {
  padding: 0;
  margin: 0;
}
.input-border {
  border: 2px #cacaca solid;
  border-radius: 4px;
}
.auth-footer {
  margin-top: 32px;
  font-size: 12px;
}
.auth-footer p {
  color: #a3a3a3;
  text-align: center;
}
.auth-footer a {
  display: table;
  margin: 2px auto 0 auto;
  color: #2e9df0;
}
.auth-header {
  background: #f5f5f5;
  width: 100%;
  height: 56px;
  line-height: 56px;
  vertical-align: middle;
  border-bottom: 1px solid #c9c9c9;
  overflow: hidden;
}
.auth-header .logo {
  font-family: 'Roboto-Regular', Arial;
  font-size: 16px;
  color: #222;
  display: block;
  width: 100%;
  padding: 10px 0;
  text-align: center;
}
.auth-header .logo img {
  display: inline-block;
}
.form-wrapper {
  max-height: 100%;
  padding-bottom: 16px;
  overflow: auto;
}
.form-container {
  width: 100%;
  max-width: 465px;
  margin: 60px auto 0 auto;
  padding: 32px;
  box-shadow: 0 0 15px #ccc;
}
.form-container > h1 {
  font-size: 21px;
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
  font-family: 'Roboto-Regular', Arial;
}
.form-container > h1 > span {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 4px;
}
.form-container > p {
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 16px;
}
.form-container > p a {
  color: #2e9df0;
  font-family: 'Roboto-Regular', Arial;
}
.form-container #resetForm {
  border-bottom: none;
}
.form-container #resetForm footer {
  margin-bottom: 0;
}
.auth-form-container > .signin-google {
  text-align: center;
  position: relative;
  padding-top: 24px;
}
.auth-form-container > .signin-google.signup {
  padding-top: 32px;
}
.auth-form-container > .signin-google .pending {
  cursor: progress;
}
.auth-form-container > .signin-google > span {
  text-transform: uppercase;
  position: absolute;
  top: -10px;
  display: block;
  border: 1px solid #e8e8e8;
  background: #f5f5f5;
  height: 22px;
  width: 30px;
  line-height: 21px;
  left: 50%;
  transform: translateX(-50%);
  vertical-align: middle;
  text-align: center;
  color: #959595;
  font-size: 11px;
  border-radius: 11px;
}
.auth-form-container > .signin-google > .btn {
  height: 40px;
  line-height: 40px;
  padding: 0 24px;
  border-radius: 24px;
}
.auth-form-container > .signin-google > .btn-blue {
  border-radius: 0;
  padding: 0 12px 0 0;
  display: inline-flex;
}
.auth-form-container > .signin-google > .btn-blue:before {
  content: '';
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url(../../../../public/assets/icons/btn_google_dark_normal.svg) center no-repeat;
  margin-right: 12px;
}
.auth-form-container > .signin-google > .btn-blue:active:before {
  background: url(../../../../public/assets/icons/btn_google_dark_pressed.svg) center no-repeat;
}
.auth-form-container > .signin-microsoft {
  padding-top: 24px;
}
.auth-form-container > .signin-microsoft.signup {
  padding-top: 32px;
}
.auth-form-container > .signin-microsoft .pending {
  cursor: progress;
}
.auth-form-container > .signin-microsoft > .btn-microsoft {
  border-radius: 0;
  padding: 0 12px 0 0;
  display: flex;
  margin: 0 auto;
  border: 1px solid #8c8c8c;
  color: #5e5e5e;
  height: 41px;
  align-items: center;
  justify-content: start;
}
.auth-form-container > .signin-microsoft > .btn-microsoft:before {
  content: '';
  width: 21px;
  height: 21px;
  display: block;
  background: url(../../../../public/assets/icons/ms-icon.svg) center no-repeat;
  margin-right: 12px;
  margin-left: 12px;
}
.auth-form-container .signin-sso {
  text-align: center;
  padding-top: 24px;
}
.auth-form-container .signin-sso .btn-link {
  display: inline-block;
  font-size: 12px;
}
.auth-form-container .auth-form:not(.ng-hide) + .signin-google,
.auth-form-container .auth-form:not(.ng-hide) + .signin-microsoft,
.auth-form-container .auth-form:not(.ng-hide) + .signin-sso {
  border-top: 1px solid #e1ebef;
}
.auth-form .input-hint--error {
  color: #f75f5f;
  display: inline-block;
  margin: 4px 0 0 100px;
}
.auth-form .input-hint--info {
  color: #2e9df0;
  font-size: 12px;
  display: inline-block;
  margin: 4px 0 0 100px;
}
.auth-form > footer {
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.auth-form > footer input.btn {
  margin-left: 100px;
}
.auth-form > footer button.btn {
  margin-left: 120px;
}
.auth-form > footer a {
  color: #959595;
  font-size: 14px;
}
.auth-form footer .btn-green:not(.btn-loading) .pending {
  display: none;
}
.auth-form footer .btn-green.btn-loading {
  background-color: #4abc3b !important;
  color: #fff !important;
}
.auth-form footer .btn-green.btn-loading:hover {
  background-color: #50d83e !important;
}
.auth-form .boundary {
  width: calc(100% - 120px);
  margin-left: 120px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e1ebef;
}
.auth-form .fieldset {
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.auth-form .fieldset:first-child {
  margin-top: 0;
}
.auth-form .fieldset > label,
.auth-form .fieldset > .label {
  width: 100px;
  min-width: 100px;
  font-size: 12px;
  font-family: 'Roboto-Regular', Arial;
  display: inline-block;
  flex: 0 1 100px;
}
.auth-form .fieldset > label .icon-template_create_field_required,
.auth-form .fieldset > .label .icon-template_create_field_required {
  font-size: 10px;
  margin-left: 4px;
  transform: translateY(-1px);
}
.auth-form .fieldset > input,
.auth-form .fieldset > textarea {
  appearance: none;
  width: 100%;
  max-width: 300px;
  height: 32px;
  flex: 0 1 100%;
  padding: 0 7px;
  font-size: 12px;
  border: 2px #cacaca solid;
  border-radius: 4px;
}
.auth-form .fieldset > input::-ms-clear,
.auth-form .fieldset > textarea::-ms-clear {
  display: none;
}
.auth-form .fieldset > input:focus,
.auth-form .fieldset > textarea:focus {
  outline: none;
  border-color: #2e9df0;
}
.auth-form .fieldset > textarea {
  padding: 7px;
  height: 80px;
}
.auth-form .fieldset.has-error > input {
  border-color: #f75f5f;
}
.auth-form .btn {
  height: 40px;
  line-height: 40px;
  padding: 0 24px;
  border-radius: 24px;
}
.auth-form + .btn {
  height: 40px;
  line-height: 40px;
  padding: 0 24px;
  border-radius: 24px;
  display: table;
  margin: 24px auto 0 auto;
}
@media only screen and (max-width: 370px) {
  .auth-form > .auth-login input.btn {
    margin-left: 0;
  }
  .auth-form > footer:not(.auth-login) {
    justify-content: center;
  }
  .auth-form > footer:not(.auth-login) input.btn {
    margin-left: 0;
  }
}
.form-hint {
  width: 100%;
  padding: 5px 8px;
  text-align: center;
  display: block;
  background: #b8e17d;
  border-radius: 4px;
  color: #222;
  margin-bottom: 12px;
}
.form-hint.error {
  background: #f9d83c;
}
.lg-form > footer input {
  margin-left: 120px;
}
.lg-form .input-hint--error {
  margin: 4px 0 0 120px;
}
.lg-form .input-hint--info {
  margin: 4px 0 0 120px;
}
.lg-form .fieldset label {
  width: 120px;
  min-width: 120px;
  flex: 0 1 120px;
}
.lg-form .fieldset input {
  max-width: 280px;
}
.subdomain-input {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 32px;
  padding: 0 7px;
  font-size: 12px;
  cursor: default;
  color: #959595;
  border: 2px #cacaca solid;
  border-radius: 4px;
}
.subdomain-input:focus {
  outline: none;
}
.subdomain-input.active {
  border-color: #2e9df0;
}
.subdomain-input input {
  border: none;
  background: transparent;
  font-family: 'Roboto-Regular', Arial;
  appearance: caret;
}
.subdomain-input input:focus {
  outline: none;
}
.team-creation-screen {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
}
.team-creation-screen .left-screen {
  flex: 0 1 50%;
}
.team-creation-screen .left-screen .screen {
  max-width: 400px;
  margin: 0 auto;
}
.team-creation-screen .left-screen .screen header {
  margin: 32px auto;
  width: 150px;
  text-align: center;
  line-height: 50px;
  height: 50px;
  background-color: #e8e8e8;
}
.team-creation-screen .left-screen .screen header a {
  color: #959595;
  font-size: 14px;
}
.team-creation-screen .left-screen .screen form h1 {
  text-align: center;
  margin: 0 0 36px 0;
}
.team-creation-screen .left-screen .screen form p {
  font-size: 12px;
}
.team-creation-screen .left-screen .screen form .teams {
  margin: 24px 0;
  max-height: calc(100vh - 480px);
  overflow: auto;
}
.team-creation-screen .left-screen .screen form .teams__team {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e1ebef;
  padding: 16px;
}
.team-creation-screen .left-screen .screen form .teams__team + .teams__team {
  border-top: none;
}
.team-creation-screen .left-screen .screen form .teams__team main {
  flex: 0 1 calc(100% - 80px);
}
.team-creation-screen .left-screen .screen form .teams__team main p {
  font-family: 'Roboto-Regular', Arial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.team-creation-screen .left-screen .screen form .teams__team main p.name {
  color: #222;
  font-size: 14px;
}
.team-creation-screen .left-screen .screen form .teams__team main p.members {
  margin-top: 4px;
  color: #a3a3a3;
}
.team-creation-screen .left-screen .screen form .teams__team aside {
  flex: 0 1 auto;
  white-space: nowrap;
}
.team-creation-screen .left-screen .screen form .fieldset.emails {
  align-items: flex-start;
}
.team-creation-screen .left-screen .screen form .fieldset.emails label {
  margin-top: 12px;
}
.team-creation-screen .left-screen .screen form footer {
  margin-bottom: 12px;
}
.team-creation-screen .left-screen .screen form footer.justify-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.team-creation-screen .left-screen .screen form footer.justify-end button + button {
  margin-left: 8px;
  margin-right: 0;
}
.team-creation-screen .left-screen .screen form footer .btn {
  margin: 0 auto;
}
.team-creation-screen .left-screen .screen .steps {
  display: table;
  margin: 0 auto;
}
.team-creation-screen .left-screen .screen .steps ul {
  display: inline-flex;
  align-items: center;
}
.team-creation-screen .left-screen .screen .steps li {
  height: 8px;
  width: 16px;
  background-color: #e8e8e8;
  border-radius: 5px;
  display: inline-block;
}
.team-creation-screen .left-screen .screen .steps li.completed {
  background-color: #4abc3b;
}
.team-creation-screen .left-screen .screen .steps li + li {
  margin-left: 4px;
}
.team-creation-screen .right-screen {
  flex: 0 1 50%;
}
.team-creation-screen .right-screen.step1,
.team-creation-screen .right-screen.step5 {
  background-color: #80c0e5;
}
.team-creation-screen .right-screen.step2 {
  background-color: #79dfbc;
}
.team-creation-screen .right-screen.step3 {
  background-color: #f3c376;
}
.team-creation-screen .right-screen.step4 {
  background-color: #c0a4ee;
}
.team-creation-screen .right-screen.step6 {
  background-color: #e5dc4e;
}
.team-creation-screen .right-screen .screen {
  width: 100%;
  max-width: 310px;
  margin: 0 auto;
}
.team-creation-screen .right-screen .screen header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0;
}
.team-creation-screen .right-screen .screen header a {
  color: #fff;
  font-size: 12px;
  font-family: 'Roboto-Regular', Arial;
}
.team-creation-screen .right-screen .screen header .btn-switcher {
  font-size: 14px;
}
