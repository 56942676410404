@import "../../../../less/variables";
@import "../../../../less/mixins";

.process__section {
  display: flex;
  height: calc(100% - (4 * @offset-lg));
  padding: 0 @site-padding;

  & > .due-date-picker {
    display: none;
  }

  .tasks-container {
    margin-top: @offset-lg;
    padding-right: 25px;
    flex: 0 1 60%;
    min-width: 60%;
    height: calc(100% - @offset-lg);
    .no-focus();

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: @offset-lg;
      height: 3*@offset-lg;

      &__btns {
        display: inline-flex;

        .btn-switcher {
          span {
            display: inline;
            font-size: inherit;
          }
          .number {
            display: none;
          }
        }

        a + a {
          margin-left: @offset-md;
        }
      }

      & > div {
        &.dueDate {
          display: flex;
          align-items: center;

          .round-progress-wrapper {
            margin-left: @offset-sm;
          }

          & > .comments-toggler {
            border: 1px @gray-light-color solid;
            color: @gray-light-color;
            padding: 6px 5*@offset-sm 6px @offset-sm;
            height: 2*@offset-lg;
            border-radius: @buttons-border-radius;
            cursor: pointer;
            position: relative;
            margin-right: -32px;
            display: none;
            margin-left: @offset-md;
            font-size: @font-size-md;
            .no-focus();

            i {
              font-size: @font-size-lg;
              line-height: @font-size-xlg;
            }
          }
        }
      }
    }

    &__level-navigation {
      padding: @offset-sm 0;
      line-height: 24px;

      .close-group-btn {
        display: inline-block;
        vertical-align: top;
        width: 32px;
        height: 24px;
        margin-top: -1px;
        margin-right: @offset-sm;
        color: rgb(202, 202, 202);

        i {
          font-size: 24px;
        }

        &:hover,
        &:focus {
          color: @color-blue;
        }
      }

      .group-name {
        font-size: @font-size-lg;
        color: rgb(34, 34, 34);
        line-height: 1.2;
      }
    }

    &__tasks {
      .native-size;
      height: calc(100% - (3 * @offset-lg));
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      .google-top-scroll-shadow();
      .bottom-offset(@page-screen-bottom-offset);

      .tasks-container__tasks__item {
        border: 1px solid @color-pale-blue;
        border-left-width: 4px;

        &.red {
          border-left-color: @color-red;
        }
        &.green {
          border-left-color: @color-green;
        }
        &.yellow {
          border-left-color: @color-yellow;
        }
      }

      .tasks-container__tasks__item + .tasks-container__tasks__item {
        border-top: 0;
      }

      &__task,
      &__group {
        display: flex;
        width: calc(100% - 1px);
        align-items: center;
        height: 4*@offset-lg;
        justify-content: space-between;
        padding-right: @offset-lg;
        .no-focus();

        &.mobile {
          &.assignee:not(.completed):not(.inactive) {
            .avatar {
              color: transparent;
              background: transparent !important;

              img, .status-icon {
                visibility: hidden !important;
              }
              .checkbox {
                display: block !important;
              }
            }
          }

          .content {
            .action {
              display: inline-block;
            }
          }
        }

        &.animated {
          cursor: pointer;

          &:hover {
            .content {
              cursor: pointer;

              .action {
                display: inline-block;
              }
            }
          }

          &.assignee:not(.inactive):hover {
            .avatar {
              .user-tooltip {
                visibility: hidden !important;
              }
            }
          }
          &.assignee:not(.completed):not(.inactive) {
            .avatar {
              color: transparent;
              background: transparent !important;

              img, .status-icon {
                visibility: hidden !important;
              }
              .checkbox {
                display: block;
                &.isAlreadyCompleting input[type="checkbox"]{
                  cursor: progress;
                }
              }
              .approval-default {
                display: flex;
                &.isAlreadyApproving{
                  cursor: progress;
                }
              }
            }
          }

          &:not(.inactive):hover {
            h4 {
              color: @color-blue;
            }
          }
        }

        &.pending {
          background-color: #f8f8f9;

          .content {
            h4 {
              color: #959595;
            }
          }
        }

        &.inactive {
          background-color: #f8f8f9;

          .content {
            h4 {
              color: #cacaca !important;
            }
            p {
              cursor: default;
              color: #cacaca !important;
              .name {
                color: #cacaca !important;
              }
            }
          }
        }
        &.inactive.skipped {
          background: @color-white;

          .content {
            h4 {
              color: #a3a3a3  !important;
            }
          }
        }

        .group-icon {
          @group-icon-size: 40px;
          display: block;
          width: @group-icon-size;
          height: @group-icon-size;
          position: relative;

          .icon-template_folder,
          .icon-group_inactive {
            position: absolute;
            top: 50%;
            left: 50%;
            color: rgb(225, 235, 239);
            font-size: 33px;
            margin-top: -17px;
            margin-left: -20px;
            z-index: 1;
          }

          .icon-group_inactive {
            color: @color-add-grey;
          }

          .icon-template_folder_parallel {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #88afbf;
            font-size: 13px;
            margin-top: -14px;
            margin-left: -7px;
            z-index: 2;
          }

          .icon-template_folder_sequence {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #88afbf;
            font-size: 13px;
            margin-top: -14px;
            margin-left: -7px;
            z-index: 2;
          }

          .icon-process_task_inactive {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 16px;
            margin-top: -15px;
            margin-left: -7px;
            z-index: 2;
            color: @color-add-grey;
          }
        }

        &__main {
          display: flex;
          align-items: center;
          width: calc(100% - 120px);

          &.with-comments {
            max-width: calc(100% - (@thumbnail-big-size + 2 * @offset-md + 60px)) !important;
          }

          &.with-due {
            max-width: calc(100% - (@thumbnail-big-size + 2 * @offset-md + 60px)) !important;

            &.with-comments {
              max-width: calc(100% - (@thumbnail-big-size + 2 * @offset-md + 140px)) !important;
            }
          }
          &.with-inactive {
            max-width: calc(100% - (@thumbnail-big-size + 2 * @offset-md + 60px)) !important;
          }

          & > .avatar {
            margin: 0 @offset-md;
            position: relative;

            .approval-default{
              position: absolute;
              top: @offset-sm;
              left: @offset-sm;
              display: none;
            }

            .checkbox {
              display: none;
              position: absolute;
              top: 5px;
              left: -2px;
              height: 26px;
            }

            .user-tooltip {
              left: -12px;
              right: auto;
              top: 48px;
              .arrow {
                left: 24px;
                right: auto;
              }
            }

            &:hover {
              position: relative;

              .user-tooltip {
                visibility: visible;
              }
            }

            &.relative {
              position: relative;
            }

            & > .status-icon {
              position: absolute;
              right: -5px;
              bottom: -5px;
              border-radius: 100%;
              border: 1px solid @color-white;
              height: 17px;
              line-height: 17px;
              width: 17px;
              text-align: center;
              z-index: 1;

              &.completed {
                background-color: @color-green;
              }
              &.issued {
                background-color: @color-yellow;
              }
              &.rejected {
                background-color: @color-red;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              & > i {
                font-size: 7px;
                color: @color-main-copy;
                opacity: .7;
                display: block;

                &.icon-assignee_badge_issued {
                  margin: -1px 0 0 1px;
                  font-size: 8px;
                  opacity: .9;
                }
              }

              &.skipped,
              &.cancelled,
              &.pending {
                background-color: #cacaca;

                i {
                  color: #000;
                  display: block;
                  margin: 0 auto;
                  line-height: 14px;
                  font-size: 8px;
                  opacity: .5;
                }
              }

              &.pending {
                background-color: @color-yellow;

                i {
                  font-size: 10px;
                }
              }
            }

            &.avatar-unassigned {
              color: @color-white;
              background-color: #ccc;

              i {
                font-size: @font-size-h1;
                margin-left: 8px;
                margin-top: -1px;
                display: block;

                &.icon-process_task_inactive {
                  margin-left: @offset-xs;
                }

                &::before {
                  margin: 0;
                }
              }
            }

            &.group {
              color: @color-add-grey;
              border: 1px dashed @template-create-header-color;

              &.inactive{
                display: flex;
                align-items: center;
                justify-content: center;
                i.icon-process_task_inactive {
                  font-size: 16px;
                  margin: 0;
                }
                .process_task_stopped_on_reject{
                  height: 12px;
                  margin: 0;
                }
              }

              i {
                font-size: @font-size-h1;
                margin-left: 8px;
                margin-top: -1px;
                display: block;

                &.icon-process_task_inactive {
                  margin-left: @offset-xs;
                }

                &::before {
                  margin: 0;
                }
              }
            }
          }

          .content {
            width: calc(100% - (@thumbnail-big-size + @offset-md));
            padding-right: @offset-lg;

            .action {
              display: none;
              vertical-align: top;
            }

            h4 {
              font-size: @font-size-md;
              font-family: @font-family-regular;
              white-space: nowrap;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            p {
              font-size: @font-size-sm;
              display: block;
              margin-top: @offset-xs;
              color: @color-add-grey;
              font-family: @font-family-semibold;
              max-width: 100%;

              .first-part {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                display: inline-block;

                &:last-child {
                  .dot {
                    display: none;
                  }
                }
              }

              span {
                &.name {
                  color: @color-main-copy;
                }

                &.completed, &.approved {
                  color: @color-green;
                }
                &.rejected {
                  color: @color-red;
                }
                &.pending-label {
                  color: #d3b93e;
                }
                &.cancelled-label {
                  color: #959595;
                }
              }

              a {
                color: @color-blue;

                &:hover {
                  color: #00ccff;
                }
                &.isAlreadyAssigning:hover{
                  cursor: progress;
                }
              }
            }
          }
        } // END: div for left block

        &__hint {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex: 1 0 auto;
          max-width: 220px;

          span {
            display: inline-table;
            margin-left: @offset-md;
            font-size: @font-size-sm;
            color: @site-menu-help-color;
            white-space: nowrap;

            &.process-task-list-due-date {
              margin-left: 0;
              span {
                margin-left: 0;
              }
            }

            &.warn {
              color: @color-red;
            }

            &:first-child {
              margin-left: 0;
            }

            i {
              float: left;
              margin: 3px 4px 0 0;
            }
          }

          .comments-updated {
            color: @border-blue-color;
          }

          .items-count {
            &:hover, &:focus {
              color: @color-blue;
            }
          }
        }
      }

      &__task:not(.animated):not(.inactive) {
        cursor: not-allowed;
      }

      &__group {
        cursor: pointer;

        &:not(.inactive):hover {
          h4 {
            color: @color-blue;
          }
        }
      }
    }

    & > .data-container {
      height: calc(100% - (@offset-lg + 33px));
      padding-right: 2px;
      .bottom-offset(@page-screen-bottom-offset);
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      & > .properties {
        padding: @offset-lg;
        border: 1px solid @color-pale-blue;
        .no-focus();

        [markdown-content] {
          font-size: @font-size-sm;
        }
        markdown-hint .markdown-hint {
          padding-top: @offset-xs;
        }

        & > label {
          margin-top: @offset-lg;
        }
        & > label:first-child {
          margin-top: 0;
        }

        & > label {
          font-family: @font-family-medium;
          margin-bottom: @offset-xs;
          display: block;
          font-size: @font-size-md;
        }
        & > .empty {
          color: @color-secondary-copy;
          font-size: @font-size-sm;
        }
        & > .empty, & > .descr, & > .view-managers {
          .no-focus();
        }
        & > .descr {
          padding: 1px 9px;
        }
        & > .empty {
          padding: 9px 9px;
        }
        & > .view-managers, & > .view-starters {
          padding: 1px 1px 5px 5px;
        }
        & > .view-managers.editable:hover, & > .descr.editable:hover,  & > .empty.editable:hover {
          border: 1px solid @input-border-color;
          border-radius: 4px;
        }
        & > .descr.editable:hover {
          padding: 0 8px;
        }
        & > .empty.editable:hover {
          padding: 8px 8px;
        }
        & > .descr > p:first-child {
          margin-top: 6px;
        }
        & > .view-managers.editable:hover {
          padding: 0 0 4px 4px;
          cursor: pointer
        }
        .update-description, .update-managers{
          margin-top: 0;
        }
      }

      & > .empty-form {
        padding: 2*@offset-md @offset-md;
        text-align: center;
        width: 100%;
        border: 1px solid @color-pale-blue;

        h4 {
          font-size: @font-size-md;
        }

        p {
          margin-top: @offset-sm;
          font-family: @font-family-semibold;
          color: @color-add-grey;
          font-size: @font-size-sm;
        }
      }

      & > .template-view-data-model {
        margin-top: 0;
      }
    }
  }

  .info {
    width: 40%;
    min-width: 40%;
    flex: 0 1 40%;
    height: 100%;
    .google-top-scroll-shadow();
    overflow: hidden;
    padding-top: @offset-lg;

    & > .icon-common_close {
      color: #cdcdcd;
      font-size: @font-size-md;
      position: absolute;
      top: 18px;
      right: 18px;
      cursor: pointer;
      display: none;

      &:hover {
        color: @color-secondary-copy;
      }
    }

    & > aside {
      height: 100%;
    }

    &__history {
      height: calc(100% - (2 * @offset-lg + 2 * @offset-md));
      margin-top: 2*@offset-md;

      & > main {
        height: 100%;
        overflow: auto;
        .google-top-scroll-shadow();
        .bottom-offset(@page-screen-bottom-offset);

        & > ul {
          padding-bottom: @offset-lg;

          & > li {
            padding-left: 2*@offset-sm;
            position: relative;
            color: @color-add-grey;
            font-size: @font-size-sm;

            &.red {
              &:before {
                background-color: @color-red;
              }
            }
            &.green {
              &:before {
                background-color: @color-green;
              }
            }
            &.yellow {
              &:before {
                background-color: @color-yellow;
              }
            }

            &:before {
              position: absolute;
              top: 2*@offset-md + 2;
              left: 0;
              content: '';
              width: 7px;
              height: 7px;
              border-radius: 100%;
              background-color: @color-light-grey;
            }

            .date {
              color: @color-main-copy;
              display: block;
              margin-bottom: @offset-sm;
            }

            & + li {
              margin-top: @offset-lg + 2;
            }
          }
        }
      }
    }
  }
}
