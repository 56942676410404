@import "../../less/variables";
@import "../../less/mixins";

.single-select {
  position: relative;

  &-header {
    min-width: 120px;
    height: 32px;
    position: relative;
    border: 1px #cacaca solid;
    border-radius: 6px;
    outline: 0;

    .icon {
      position: absolute;
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 22px;
      text-align: center;
      top: 3px;
      left: 3px;

      [class^="icon-"] {
        @icon-font-size: 14px;
        font-size: @icon-font-size;
      }

      .entity-icon {
        height: 24px;
        width: 24px;
        line-height: 24px;
        border-radius: 8px !important;

        i {
          font-size: 14px;
          top: 1px;
          left: 1px;
        }
      }
    }

    .badge-deleted {
      height: @font-size-md;
      line-height: @font-size-md - 1;
      width: @font-size-md;
      border-radius: 100%;
      background-color: @color-red;
      position: absolute;
      text-align: center;
      bottom: 2px;
      left: 16px;
      z-index: 2;

      i {
        font-size: 8px;
        margin: 0 0 0 1px;
        color: @color-white;
      }
    }

    .control {
      height: 32px;
      margin-top: 3px;
      margin-left: 30px;
      margin-right: 26px;

      .value {
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      input {
        position: relative;
        opacity: 0;
        border: none !important;
        box-shadow: none !important;
        z-index: 1;
        margin: 0;
        padding: 0;
        line-height: 26px;
        background: transparent;
        width: 100% !important;
      }
    }

    .action {
      .clear {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 7px;
        right: 5px;
        background-color: #ccc;
        color: #fff;
        font-size: 6px;
        border-radius: 100%;
        cursor: pointer;
        border: 0;
        padding: 0;

        i {
          margin: 0;
          line-height: 7px;
        }
      }

      .caret {
        position: absolute;
        top: 14px;
        right: 8px;
        font-size: 4px;
        cursor: pointer;
      }
    }
  }

  &.focused {
    .single-select-header {
      border: 1px solid @color-blue;
      box-shadow: 0 0 0 1px @color-blue;
    }
  }

  &.opened {
    .control {
      .value {
        visibility: hidden;
        margin-top: -25px;
      }
      input {
        opacity: 1;
      }
    }

    .badge-deleted {
      display: none;
    }
  }

  &.empty {
    .single-select-header {
      .icon {
        color: @color-add-grey;
      }
      .value {
        color: @color-add-grey;
      }
    }
  }

  &.readonly {
    .single-select-header {
      background: @color-panel-grey;
      border-color: transparent;

      .value {
        color: @color-main-copy;
      }
    }

    &.empty {
      .single-select-header {
        .value {
          color: @color-add-grey;
        }
      }
    }
  }
}

.single-select-drop-tether {
  z-index: @z-index-dropdown;
}

.single-select-dropdown {
  min-width: 120px;
  overflow: hidden;
  margin-top: 2px;
  background-color: @color-white;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);

  .dropdown-list {
    position: relative;
    overflow: auto;
    max-height: 200px;

    ul {
      .native-size;
      padding: 0 (@offset-sm / 2);

      & > li {
        cursor: pointer;
        font-size: @font-size-md;
        padding-bottom: (@offset-sm / 2);
        padding-top: (@offset-sm / 2);

        &.selected {
          font-family: @font-family-medium;
          font-weight: normal !important;
        }

        span.highlighted {
          color: sandybrown;
        }

        .item-label {
          border-radius: @thumbnail-border-radius;
          display: inline-flex;
          align-items: center;
          max-width: calc(100%);
        }

        .item-icon {
          line-height: @thumbnail-size;
          width: @thumbnail-size;
          min-width: @thumbnail-size;
          text-align: center;
          background-color: @color-light-grey;
          color: @color-add-grey;
          margin-right: -4px;
          border-radius: @thumbnail-border-radius;
          display: inline-block;
          font-size: 10px;

          .entity-icon {
            height: 24px;
            width: 24px;
            line-height: 24px;
            border-radius: 8px !important;

            i {
              font-size: 14px;
              line-height: unset;
            }
          }
        }

        &.active {
          .item-label {
            background-color: @color-light-grey;
          }
        }

        .item-text {
          font-size: 12px;
          word-break: break-word;
          line-height: 24px;
          padding: 0 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.no-results {
          @offset: (@offset-sm / 2);
          @margin: @offset * -1;

          margin: 0 @margin;
          padding: 6px @offset;
          font-size: @font-size-sm;
          color: @color-secondary-copy;
          text-align: center;

          a {
            color: @color-blue;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.form-group.error {
  .single-select-header {
    border: 1px solid @color-red;
    box-shadow: none;
  }

  .single-select.focused {
    .single-select-header {
      box-shadow: 0 0 0 1px @color-red;
    }
  }
}
