@import "../../../less/variables";
@import "../../../less/mixins";

.process-date-picker {
  display: flex;
  align-items: center;
  margin: 0;
  &.hasPermission {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &.hasPermission:hover span.date-str{
    color: @color-blue;
  }
  .round-progress-wrapper {
    margin-left: @offset-sm;
  }
  span {
    display: block;
    font-size: @font-size-sm;
    text-align: right;

    &.grey {
      color: @color-secondary-copy;
    }
    &.red {
      color: @color-red;
    }
    &.bold {
      font-family: @font-family-semibold;
    }
  }
  & > span.handler{
    transition: none;
  }
  @media only screen and (max-width: @phone-portrait-size) {
    .round-progress-wrapper {
      display: none;
    }
  }
}

.process-picker-content {
  right: -100px !important;
}
