.column-value-cell {
  .file-preview__link {
    display: inline-flex;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #e8e8e8;
    margin-right: 4px;
    max-width: 100%;
  }

  .file-preview__link.has-more-files {
    max-width: 80%;
  }

  .more-files {
    color: #2e9df0;
  }
}
