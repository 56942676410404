@import "../../../less/variables";
@import "../../../less/mixins";

.auth-footer {
  .error {
    margin-top: 10px;
    color: @color-red;
  }

  a.btn {
    color: #fff;
    display: inline-block;
  }
}
