@import "../../../less/variables";
@import "../../../less/mixins";

.admin__main {
  & > div {
    display: flex;
    height: 100%;
    padding: 1px @site-padding;
  }

  &__content {
    height: 100%;
    max-height: calc(100% - (4 * @offset-lg));
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-y-scrolling: touch;
    flex: 0 1 100%;
    .google-top-scroll-shadow();
    .bottom-offset(@lists-screen-bottom-offset);

    &.active {
      margin-right: -@offset-lg - 1px;
    }

    &__user,
    &__group,
    &__channel {
      border: 1px solid @color-pale-blue;
      height: 4 * @offset-lg;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 @offset-lg 0 9px;
      position: relative;
      cursor: pointer;
      width: calc(100% - @offset-lg);
      .no-focus();

      &:hover {
        h4, h5 {
          color: @color-blue;
          cursor: pointer;
        }
      }

      &.active {
        border-color: @color-blue;
        border-right: 1px solid @color-blue;
        z-index: 5;

        .square {
          opacity: 1;
        }
      }

      & + & {
        margin-top: -1px;
      }

      &__details {
        flex: 0 1 100%;
        display: flex;
        cursor: pointer;
        position: relative;

        & > .invited-badge {
          background-color: @color-yellow;
          width: 18px;
          height: 18px;
          border-radius: 100%;
          border: 1px solid @color-white;
          line-height: 15px;
          position: absolute;
          left: @thumbnail-size + 3;
          top: @thumbnail-size + 3;
          text-align: center;

          i {
            font-size: 8px;
            vertical-align: middle;
            display: inline-block;
            margin-left: 1px;
            color: #000;
            opacity: .5;
          }
        }

        .avatar {
          margin-right: @offset-md;

          &.group {
            background-color: @color-light-grey;
            color: @color-add-grey;

            i {
              font-size: @template-group-icon-size;

              &::before {
                margin: 0;
              }
            }
          }
        }

        .details-wrapper {
          width: 100%;
          flex: 0 1 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 2px;
          cursor: pointer;

          h4 {
            font-size: @font-size-md;
            transition: all .2s ease;
            font-family: @font-family-regular;
          }

          h5 {
            font-family: @font-family-medium;
            font-weight: normal;
            font-size: @font-size-md;
            transition: all .15s ease;
          }

          & > p {
            color: @color-secondary-copy;
            font-size: @font-size-sm;
            margin-top: @offset-sm - 2px;
            -webkit-line-clamp: 1;
            overflow: hidden;
            display: -webkit-box;
          }
        }
      }

      &__invite {
        color: @color-secondary-copy;
        font-size: @font-size-sm;
        white-space: nowrap;
      }

      &__delete {
        color: @color-grey;
        font-size: 34px;
        cursor: pointer;
        .no-focus();

        &:hover {
          color: @color-blue;
        }
      }

      .square {
        height: 15px;
        width: 15px;
        right: -8px;
        z-index: 100;
        top: 50%;
        margin-top: -8px;
        transform: rotate(45deg);
        background-color: @color-white;
        position: absolute;
        border-right: 1px solid @color-blue;
        border-top: 1px solid @color-blue;
        opacity: 0;
        cursor: pointer;
      }

      & + .admin__main__content__empty-state,
      & + .admin__main__content__error-state {
        border-top: none;
      }
    }

    &__channel {
      .channel-icon {
        background: #cacaca;
        color: #fff;
        font-size: 20px;
      }
    }

    .add-another-btn {
      margin: 10px;
    }

    &__empty-state,
    &__error-state {
      border: 1px solid @color-pale-blue;
      padding: 4*@offset-lg 0;
      width: calc(100% - @offset-lg);
      text-align: center;

      i {
        font-size: 64px;
        color: #d1d1d1;
      }

      h5 {
        color: @color-main-copy;
        font-size: @font-size-md;
        margin: 2*@offset-md auto 0;
        max-width: 250px;
      }

      p {
        color: @color-add-grey;
        font-size: @font-size-sm;
        margin: @offset-sm auto 0;
        max-width: 250px;

        &.not-found {
          max-width: initial;
        }

        a {
          color: @color-blue;
        }
      }


      .add-to-slack-btn {
        display: inline-block;
        margin: 50px auto 0;

        img {
          width: 200px;
          height: auto;
        }
      }

      .loading-spinner {
        display: inline-block;
        max-width: 200px;
        text-align: center;
        margin: 50px auto 0;
        font-size: 16px;
        height: 63px;
        padding-top: 24px;
        color: #959595;

        i {
          font-size: 16px;
          height: 18px;
          width: 16px;
          animation: rotate .3s linear infinite;
        }
      }

      .start-using-slack {
        font-size: 14px;
        color: @color-main-copy;
      }
    }

    &__error-state {
      border-color: @color-red;
    }
  }
}
