@import "../../../../less/variables";
@import "../../../../less/mixins";

.template__section--single {
  height: calc(100% - (4 * @offset-lg));
  padding: 0 @site-padding;

  &__header {
    display: flex;
    margin-top: @offset-lg;

    & > div {
      width: 50%;
    }

    .template__section__tabs {
      flex: 0 1 50%;
      margin-right: -17px;
    }

    &__due-date {
      color: @color-main-copy;
      font-size: @font-size-sm;
      font-family: @font-family-semibold;
    }

    &__info {
      display: flex;
      align-items: center;
      padding-left: 32px;
      height: 2*@offset-lg;

      .avatar-wrapper {
        margin-right: 6px;
        cursor: pointer;
        position: relative;
        min-width: @thumbnail-size;

        .user-tooltip {
          left: 1px;
          right: auto;

          .arrow {
            left: 5px;
            right: auto;
          }
        }

        &:hover {
          .user-tooltip {
            visibility: visible;
          }
        }
      }

      .main {
        font-family: @font-family-semibold;
        font-size: @font-size-sm;
        color: @template-view-header-span-color;

        span {
          color: @color-main-copy;
        }
      }
    }
  }

  &__columns {
    display: flex;
    height: 100%;
  }

  .tasks-container {
    flex: 0 1 50%;
    min-width: 50%;
    width: 50%;
    height: 100%;
    position: relative;
    z-index: 5;
    margin-right: -17px;

    & > .data-container {
      height: calc(100% - (@offset-lg + 33px));
      padding-right: 2px;
      .bottom-offset(@page-screen-bottom-offset);
      overflow: auto;

      & > .properties {
        padding: @offset-lg;
        border: 1px solid @color-pale-blue;
        .no-focus();



        & > .short-description {
          & > p:not(.empty) {
            white-space: pre-wrap;
            word-wrap: break-word;
            font-size: @font-size-sm;
            line-height: 18px;
          }
        }

        & > div {
          & > label {
            font-family: @font-family-medium;
            margin-bottom: @offset-xs;
            display: block;
            font-size: @font-size-md;
          }
          & > .empty {
            font-size: @font-size-sm;
          }

          & + div {
            margin-top: 2*@offset-md;
          }
        }
      }

      & > .empty-form {
        padding: 2*@offset-md @offset-md;
        text-align: center;
        width: 100%;
        border: 1px solid @color-pale-blue;

        h4 {
          font-size: @font-size-md;
        }

        p {
          margin-top: @offset-sm;
          font-family: @font-family-semibold;
          color: @color-add-grey;
          font-size: @font-size-sm;
        }
      }

      & > .template-view-data-model {
        margin-top: 0;
      }
    }

    &--wrapper {
      border-top: 1px solid @color-pale-blue;
      height: 100%;
    }

    &__process-start {
      & > .avatar {
        display: inline-block;
        background: #d6f7d3;
        text-align: center;
        margin-right: @offset-md;

        &:after {
          display: inline-block;
          content: '';
          transform: translate(5px, 2px);
          height: 2px;
          border: 7px solid transparent;
          border-right-width: 10px;
          border-left: 11px solid rgb(159, 207, 155);
        }
      }
    }

    &__group-summary {
      .group-icon {
        @group-icon-size: 40px;
        display: block;
        width: @group-icon-size;
        height: @group-icon-size;
        position: relative;
        margin: 0 12px 0 0;

        .icon-template_folder {
          position: absolute;
          top: 50%;
          left: 50%;
          color: rgb(214, 247, 211);
          font-size: 33px;
          margin-top: -17px;
          margin-left: -20px;
          z-index: 1;
        }

        .icon-template_folder_parallel {
          position: absolute;
          top: 50%;
          left: 50%;
          color: rgb(159, 207, 155);
          font-size: 13px;
          margin-top: -5px;
          margin-left: -7px;
          z-index: 2;
        }

        .icon-template_folder_sequence {
          position: absolute;
          top: 50%;
          left: 50%;
          color: rgb(159, 207, 155);
          font-size: 13px;
          margin-top: -5px;
          margin-left: -7px;
          z-index: 2;
        }
      }
    }

    &__process-start,
    &__group-summary {
      display: flex;
      align-items: center;
      height: 4*@offset-lg;
      padding: 0 @offset-md;
      border: 1px solid @color-pale-blue;
      border-left-width: 4px;
      position: relative;
      width: calc(100% - @offset-lg);
      cursor: pointer;
      font-size: @font-size-md;
      .no-focus();

      .square {
        height: 15px;
        width: 15px;
        right: -8px;
        z-index: 100;
        top: 50%;
        margin-top: -8px;
        transform: rotate(45deg);
        background-color: @color-white;
        position: absolute;
        border-right: 1px solid @color-blue;
        border-top: 1px solid @color-blue;
        display: none;
      }

      &.active {
        border-color: @color-blue;
        border-top: 1px solid @color-blue;
        z-index: 5;

        .square {
          display: block;
        }
      }
    }

    &__tasks {
      .native-size;
      height: calc(100% - (@offset-lg + 33px));
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      .google-top-scroll-shadow();
      .bottom-offset(@page-screen-bottom-offset);

      &__item {
        width: calc(100% - @offset-lg);
        border: 1px solid @color-pale-blue;
        border-left-width: 4px;
        position: relative;
        .no-focus();

        &:hover {
          cursor: pointer;
        }

        &.active {
          border-color: @color-blue;

          .square {
            display: block;
          }
        }

        &:not(.active) {
          border-top: 0;
          padding-top: 1px;
        }
      }

      &__task,
      &__group {
        display: flex;
        align-items: center;
        height: 4*@offset-lg;
        .no-focus();

        .content {
          width: calc(100% - (@thumbnail-big-size + 2 * @offset-md));
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: @offset-lg;

          & > div {
            margin-right: 20px;
            overflow: hidden;

            h4 {
              font-size: 14px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            p {
              font-size: @font-size-sm;
              display: table;
              margin-top: @offset-xs;
            }
          }

          & > span {
            font-size: @font-size-sm;
            color: #9c9c9c;
            text-align: right;
            white-space: nowrap;
          }

          .items-count {
            display: inline-block;
            white-space: nowrap;
            font-size: 12px;
            color: @color-blue;
            margin-right: 4px;

            &:hover, &:focus {
              cursor: pointer;
              color: #00ccff;
            }
          }
        }

        .group-icon {
          @group-icon-size: 40px;
          display: block;
          width: @group-icon-size;
          height: @group-icon-size;
          position: relative;
          margin: 0 12px;

          .icon-template_folder {
            position: absolute;
            top: 50%;
            left: 50%;
            color: rgb(225, 235, 239);
            font-size: 33px;
            margin-top: -17px;
            margin-left: -20px;
            z-index: 1;
          }

          .icon-template_folder_parallel {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #88afbf;
            font-size: 13px;
            margin-top: -5px;
            margin-left: -7px;
            z-index: 2;
          }

          .icon-template_folder_sequence {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #88afbf;
            font-size: 13px;
            margin-top: -5px;
            margin-left: -7px;
            z-index: 2;
          }
        }

        .square {
          height: 15px;
          width: 15px;
          right: -8px;
          z-index: 100;
          top: 50%;
          margin-top: -8px;
          transform: rotate(45deg);
          background-color: @color-white;
          position: absolute;
          border-right: 1px solid @color-blue;
          border-top: 1px solid @color-blue;
          display: none;
        }
      }
    }
  }

  .info {
    width: 50%;
    flex: 0 1 50%;
    height: 100%;
    overflow-y: auto;
    .google-top-scroll-shadow();
    margin-left: -2px;

    & > .bottom-offset {
      width: 100%;
      height: @offset-lg;
    }

    & > header {

    }


  }

  aside {
    height: calc(100% - (4 * @offset-lg + 1px));
    width: 100%;
    overflow: auto;
    word-wrap: break-word;
    line-height: 18px;
    .google-top-scroll-shadow();

    div[markdown-content] {
      overflow: auto;
      //max-height: calc(100% - (2 * @offset-lg + 28px));
    }
  }
}
