.data-model-table-full-view {
  @media only screen and (max-width: 768px) {
    .modal-dialog {
      margin-top: 20px !important;
    }
  }
  .modal-dialog {
    max-width: 100%;
    width: 98%;
    margin-top: 60px;
    table-layout: fixed;
    padding-left: 0;
    padding-right: 0;

    .modal-header {
      text-align: center;
      margin-bottom: 2*@offset-lg;
    }

    .data-model-table {
      .empty-table-content {
        border: 1px solid rgb(232, 232, 232);
        border-top: none;
        margin: 0 auto;
        width: 100%;
        max-width: 100%;

        p {
          font-size: @font-size-md;
          white-space: normal;
          padding: 70px @offset-md;
          margin: 0;
        }
      }
    }
  }
}