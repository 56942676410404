.tmpl-task-due-date {
  width: 200px;

  & > tether-drop {
    display: table;
    width: 100%;

    .dropdown-tether {
      width: 100%;

      & > .tether-handler {
        width: 100%;
      }
    }
  }

  & > .form-group {
    margin-top: @offset-sm;
    @input-width: 56px;
    align-items: flex-start;

    & > input {
      width: @input-width;
      min-width: @input-width;
      flex: 0 1 @input-width;
    }
    & > tether-drop {
      margin-left: @offset-xs;
      width: calc(100% - @input-width);

      & > .dropdown-tether {
        width: 100%;

        .tether-handler {
          width: 100%;
        }
      }
    }
  }

  & > .error {
    color: @color-red;
    white-space: nowrap;
  }
}

.tmpl-calculations-drop, .tmpl-units-drop {
  z-index: @z-index-over-content;

  &.tmpl-calculations-drop-open {
    .tmpl-calculations-drop-content {
      display: inline-block;
    }
  }
  &.tmpl-units-drop-open {
    .tmpl-units-drop-content {
      display: inline-block;
    }
  }

  .tmpl-calculations-drop-content {
    min-width: 200px;
    max-width: 270px;
  }
  .tmpl-units-drop-content {
    width: 140px;
  }

  .tmpl-units-drop-content,
  .tmpl-calculations-drop-content {
    display: none;

    .dropdown-menu {
      background: @color-white;
      .box-shadow();
      max-height: 210px;
      overflow: auto;

      &.capitalized {
        & > li {
          text-transform: capitalize;
        }
      }

      li {
        font-size: @font-size-sm;
        color: @color-main-copy;
        padding: 8px 8px 8px 25px;
        display: block;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 260px;
        .no-focus();

        &.empty-value {
          color: @color-secondary-copy;
        }

        i {
          display: none;
          font-size: @font-size-icon;
          color: @color-green;
          margin-right: 6px;
        }

        &.active {
          padding-left: 8px;

          i {
            display: inline-block;
          }
        }

        &:hover {
          color: @color-white;
          background-color: @color-green;

          i {
            color: @color-white;
          }
        }
      }
    }
  }
}
