@import "../../../less/variables";
@import "../../../less/mixins";

.profile__main__content {
  margin: 0;
  padding: 0 @site-padding;
  height: 100%;
  max-height: calc(100% - (4 * @offset-lg));
  overflow: auto;

  .flex-wrapper {
    display: flex;
    height: calc(100%);
    padding-bottom: 1px;

    .admin__main__content,
    .admin__main__settings {
      max-height: calc(100%);
    }
  }

  @profile-photo-size: 80px;

  .content-wrapper {
    padding: 45px;
    width: 100%;
    border: 1px solid @color-pale-blue;

    & > p {
      font-size: @font-size-sm;
      color: #4f4f4f;

      & > span {
        color: @color-main-copy;
        font-family: @font-family-bold;
      }

      a {
        color: @color-blue;
        font-family: @font-family-semibold;
      }

      .status {
        color: #959595;
        font-family: @font-family-regular;

        .saving {
          i {
            animation: rotate .3s linear infinite;
          }
        }

        .saved {
          color: @color-green;
        }
      }
    }

    .show-help-tooltips {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      .no-focus();

      .checkbox {
        margin: 0 @offset-sm 0 0;
      }
    }

    .notifications {
      display: flex;
      align-items: flex-start;
      margin: 2*@offset-md 0;

      .first-col,
      .second-col {
        width: 50%;
        flex: 0 1 50%;

        &.full {
          width: 100%;
          flex: 0 1 100%;
        }

        label {
          display: flex;
          align-items: center;
          font-size: @font-size-sm;
          font-family: @font-family-regular;

          & + label {
            margin-top: @offset-lg;
          }

          .checkbox {
            margin-left: 0;
          }
        }
      }
    }

    .photo {
      display: flex;
      align-items: center;
      position: relative;

      & + p {
        display: none;
        font-size: 11px;
        color: @color-secondary-copy;
      }

      .avatar-preview {
        flex: 0 1 @profile-photo-size;
        width: @profile-photo-size;
        height: @profile-photo-size;
        line-height: @profile-photo-size;
        border-radius: @buttons-border-radius;
        overflow: hidden;
        color: @color-white;
        text-align: center;
        font-size: 30px;
        font-family: @font-family-regular;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .actions {
        margin-left: @offset-lg;

        a {
          color: @color-blue;
          font-size: @font-size-sm;
          display: block;
          font-family: @font-family-semibold;
          cursor: pointer;

          & + a {
            margin-top: @offset-md;
          }
        }
      }

      & > p {
        font-size: 11px;
        color: @color-secondary-copy;
        position: absolute;
        left: @profile-photo-size + @offset-lg;
        bottom: 0;
      }
    }

    .email-group {
      .actions {
        display: inline-flex;
        align-items: center;
        height: 2*@offset-lg;
        margin-left: @offset-sm;

        span {
          font-family: @font-family-semibold;

          &.red {
            color: @color-red;
          }
        }

        a {
          font-family: @font-family-semibold;
          color: @color-blue;
        }
      }
    }
  }

  .form {
    .form-group {
      &.text-only {
        align-items: flex-start;
      }

      label {
        width: 100px;
        flex: 0 1 100px;
      }

      input {
        max-width: 240px;
      }
    }
  }
}
