@import "../../../less/variables";
@import "../../../less/mixins";

.datatable {
  height: 100%;
  display: flex;
  flex-direction: column;

  @table-header-height: 90px;
  @table-border-color: rgb(232,232,232);

  &-header {
    position: relative;
    min-height: @table-header-height;
    padding: 25px 34px 0;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .datatable-title {
      width: 100%;
      min-height: 40px;
      margin: 0 6px;
      margin-top: -4px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .title {
        position: relative;
        width: 100%;

        &.editable {}

        h1 {
          font-size: 21px;
          line-height: 26px;
          color: #222222;
          font-family: @font-family-regular;
        }

        &.empty {
          h1 {
            color: #a3a3a3 !important;
          }
        }

        label {
          position: relative;
          top: 1px;
          left: 1px;
        }

        input {
          background: transparent;
          color: #222;
          font-family: @font-family-regular;
          font-size: 21px;
          border: 1px transparent solid;
          border-radius: 4px;
          width: 100%;
          margin-left: -3px;
          padding: 1px 13px 0 3px;
          transform: translateY(-10000px);

          & + label {
            cursor: pointer;
            position: absolute;
            width: calc(100%);
            display: flex;
            align-items: center;

            h1 {
              overflow: hidden;
              display: inline-block;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .icon-template_create_field_editable {
              font-size: 12px;
              vertical-align: middle;
              color: #a3a3a3;
              display: none;
              width: 20px;
            }

            &:hover {
              .icon-template_create_field_editable {
                display: inline-block;
              }
            }
          }

          &:focus {
            transform: translateY(0);
            outline: none;
            cursor: default;
            border-color: @input-border-color;

            & + label {
              display: none;
            }
          }
        }

        &.invalid {
          input {
            transform: translateY(0);
            border-color: @color-red;
            box-shadow: none;

            &:focus {
              box-shadow: 0 0 0 1px @color-red;
            }
          }

          label {
            display: none;
          }
        }

        .error {
          display: block;
          font-size: 12px;
          color: @color-red;
          margin-left: 2px;
          line-height: 12px;
        }
      }

      .description {
        position: relative;
        margin-top: -2px;
        min-height: 50px;

        p {
          color: #a3a3a3;
          font-size: 12px;
          line-height: 18px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        label {
          position: relative;
          top: 7px;
          left: 2px;
        }

        textarea {
          font-weight: normal;
          font-size: 12px;
          border: 1px solid transparent;
          border-radius: 4px;
          margin-left: -3px;
          background: @color-white;
          width: 100%;
          padding: @offset-xs;
          resize: none;
          line-height: 18px;
          transform: translateY(-10000px);

          & + label {
            cursor: pointer;
            position: absolute;
            top: 5px;
            left: 2px;
            right: 8px;
            display: flex;

            .icon-template_create_field_editable {
              opacity: 0;
              color: #a3a3a3;
              font-size: 12px;
              line-height: 18px;
            }

            &:hover {
              .icon-template_create_field_editable {
                opacity: 1;
              }
            }
          }

          &:focus {
            transform: translateY(0);
            cursor: default;
            outline: none;
            border-color: @input-border-color;

            & + label {
              display: none;
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .btn.btn-icon {
        font-size: 30px;
        transition: none;
        margin-left: 8px;

        &:not(:hover) {
          color: #cdcdcd;
        }

        i {
          margin: 0;
        }
      }

      .search-field {
        position: relative;
        top: -4px;
      }
    }

    .btn-close,
    .btn-back {
      color: #cdcdcd;
      font-size: 13px;
      position: absolute;
      height: 13px;
      line-height: 13px;
      padding: 0;
      top: 13px;
      right: 13px;
      cursor: pointer;

      &:hover {
        color: @color-secondary-copy;
      }
    }

    .btn-back {
      height: 45px;
      line-height: 45px;
      left: 0;
      font-size: 40px;
      top: 44px;
      margin-top: -22px;
      width: 20px;
      display: inline-block;

      i {
        margin: 0;
      }

      &:hover {
        color: @color-blue;
      }
    }
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    margin: 0 20px;
  }

  &-grid {
    display: flex;
    flex: 1 1 auto;
    margin: 0 20px 15px;

    .public_fixedDataTable_main {
      border-color: @table-border-color;
      background-color: #fcfcfc;
    }

    .columns-grid,
    .records-grid {
      .cell {
        font-size: 12px;
        padding: 6px;
        padding-top: 12px;
        display: flex;
        border-left: 1px solid @table-border-color;
        border-bottom: 1px solid @table-border-color;

        &__header {
          border-top: 1px solid @table-border-color;
          font-family: @font-family-medium;
          display: flex;
          justify-content: space-between;

          i {
            color: @color-add-grey;
          }

          .actions-list-drop {
            .btn {
              height: unset;
              line-height: unset;
            }
          }
        }
      }
    }
  }

  .data-states {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 250px;

    .data-state {
      &__icon {
        color: #cccccc;
        font-size: 52px;
        margin-bottom: 24px;

        &.icon-task_field_save_ring {
          width: 49px;
          height: 49px;
          line-height: 46px;
          text-align: center;
          animation: rotate .3s linear infinite;
        }
      }

      &__header {
        font-size: 14px;
        margin-bottom: 8px;
      }

      &__summary {
        color: #959595;
        font-size: 12px;
      }
    }
  }
}

.permissions-dialog {
  @permissions-dialog-width: 500px;

  &.modal-dialog {
    max-width: @permissions-dialog-width + (4 * @offset-md);
    margin-bottom: 100px;

    .modal-content {
      max-width: @permissions-dialog-width;
    }

    .modal-header {
      padding-bottom: 10px;
      margin-right: 24px;

      h3 {
        line-height: @thumbnail-size;
        padding-right: 3*@offset-md;
        font-size: 16px;
      }
    }

    .modal-body {
      margin-top: @offset-md;

      .section {
        margin-bottom: 20px;

        h3 {
          font-family: @font-family-medium;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 4px;
        }
      }
    }
  }
}
