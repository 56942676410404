.markdown-hint {
  .no-focus();
  .toolbar {
    color: #9c9c9c;
    font-size: @font-size-sm;
    white-space: nowrap;
    .no-focus();

    .m-logo {
      color: @color-secondary-copy;

      &:hover {
        color: @color-blue;
      }
    }

    .all-options {
      color: inherit;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    span + a, a + span, span + span {
      margin-left: @offset-sm;
    }
  }
}
.mdh-item {
  .no-focus();
}

.drop-element {
  &.drop-open {
    .dropdown-menu--markdown-hint {
      display: block;
    }
  }

  .dropdown-menu--markdown-hint {
    display: none;
    padding: @offset-md;
    min-width: 240px;
    width: 240px;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0,0,0,0.15);
    margin-top: @offset-sm;

    p {
      font-size: @font-size-sm;

      &.announcement {
        color: #9c9c9c;

        a {
          color: #9c9c9c;
          text-decoration: underline;
          display: inline;
        }
      }

      & + p {
        margin-top: @offset-lg;
      }
    }
  }

  .dropdown-menu--field-type {
    display: none;
    min-width: 105px;
    background: @color-white;
    border: 1px @input-border-color solid;
    box-shadow: 0 1px 6px rgba(0,0,0,0.15);

    li {
      &.separated {
        border-top: 1px solid #e3e3e3;
      }

      a {
        font-size: @font-size-sm;
        color: @color-main-copy;
        padding: @offset-sm;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:hover {
          color: @color-blue;
        }
      }
    }
  }
}