@import "../../../../less/variables";
@import "../../../../less/mixins";

.template__section--single__task-settings,
.template__section--single__group-details {
  padding: @offset-lg 2*@offset-md @page-screen-bottom-offset 2*@offset-lg;
  position: relative;
  min-height: 100%;
  border: 1px solid @color-pale-blue;
  line-height: normal;
  .no-focus();

  &__tabs{
    margin-top: 0;
    li + li {
      margin-left: @offset-md;
    }
  }

  .template-task-data-model__content__section {
    &.hidden {
      display: none;
    }

    &__fields__field {
      &.hidden {
        display: none;
      }

      &:first-child.hidden {
        display: block;
        visibility: hidden;
        height: 0;
        margin: @offset-md 0 0;
      }
    }
  }

  & > .content {
    width: 100%;

    @settings-label-width: 150px;
    & > div {
      & > h4 {
        color: @color-secondary-copy;
        font-size: @font-size-md;
        margin: 2*@offset-md 0;
        font-family: @font-family-regular;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    & > .form-group {
      align-items: flex-start;
    }

    .form-group {
      .value {
        word-break: break-all;
      }

      & > label:first-child {
        flex: 0 1 @settings-label-width;
        min-width: @settings-label-width;
        width: @settings-label-width;
        font-family: @font-family-medium;
      }

      &.type-group {
        .task-view-type-icon{
          margin-right: @offset-sm;
        }
        .task-view-approval {
          display: none;
          align-items: center;
        }
        .task-view-simple {
          display: flex;
          align-items: center;
        }
        .task-view-is-approval {
          .task-view-approval {
            display: flex;
          }
          .task-view-simple {
            display: none;
          }
        }

      }

      &.date-group {
        & > .value {
          margin-top: -2px;

          .icon-task_due_date_add_small {
            font-size: @font-size-lg;
          }
        }
      }

      &.description-group {
        display: grid;
        grid-template-columns: @settings-label-width auto;

        [markdown-content] {
          font-size: @font-size-sm;
        }

        & > label {
          width: unset;
          min-width: unset;
          flex: unset;
          align-self: start;
          line-height: 28px;
        }

        & > .value {
          align-self: start;

          :first-child {
            margin-top: 0;
          }
        }
      }

      &.actors-group {
        & > label {
          padding-top: 2px;
        }

        & > .value {
          width: 100%;
        }
      }

      &.actors-settings {
        margin-top: 20px;
      }

      .tmpl-date-picker {
        background: #fff;

        .handler {
          padding: 6px 7px;
          flex: 1 0 auto;
          .input-border;
          border-width: 1px;
          cursor: pointer;
          height: 2*@offset-lg;
          min-width: 120px;

          &.drop-enabled {
            border-color: @color-blue;
            border-width: 2px;
          }
        }
      }
    }
  }
}
