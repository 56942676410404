@keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 43px 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes spring {
  0% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}

.ng-slide {
  max-height: 100%;
  opacity: 1;
  overflow: hidden;

  &.ng-hide-add, &.ng-hide-remove {
    transition: all 0.3s linear;
  }
  &.ng-hide, &.ng-show.ng-show-active {
    max-height: 0;
    opacity: 0;
  }
  &.ng-show, &.ng-hide.ng-hide-active {
    max-height: 100%;
    opacity: 1;
  }
}