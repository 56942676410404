.email-data-field {
  display: flex;
  align-items: center;

  & > input {
    padding-left: 2*@offset-lg !important;
    position: relative;
    z-index: 1;
  }

  & > i {
    position: absolute;
    left: @offset-sm;
    color: @color-secondary-copy;
    top: 50%;
    transform: translateY(-50%);
  }
}
.email-data-field--disabled {
  flex: 0 1 100%;
  width: 100%;
  max-width: @input-max-width;
  padding: 8px 7px;
  .input-border;
  border: none;
  height: 30px;
  box-shadow: none !important;
  color: @color-main-copy;
  resize: none;
  outline: 0;
  cursor: pointer;
  background-color: @color-panel-grey;
  position: relative;

  & > i {
    position: absolute;
    left: @offset-sm;
    color: @color-main-copy;
    top: 50%;
    transform: translateY(-50%);
  }

  a {
    color: @color-blue;
    cursor: pointer;
    padding-left: 2*@offset-lg;
  }
}
