@import "variables";

.inline-flex {
  display: inline-flex;
  align-items: center;
}

.native-size {
  padding: 0;
  margin: 0;
}

.input-border {
  border: 2px @input-border-color solid;
  border-radius: 4px;
}

.no-focus() {
  &:focus {
    outline: none;
  }
}

.top-line() {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: @white-color;
}

.bottom-offset(@offset) {
  &:after {
    content: '';
    height: @offset;
    width: 100%;
    display: block;
  }
}

.box-shadow() {
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}

.google-scroll-shadow() {
  background:
          linear-gradient(white 30%, hsla(0, 0%, 100%, 0)),
          linear-gradient(hsla(0, 0%, 100%, 0) 10px, white 70%) bottom,
          radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%),
          radial-gradient(at bottom, rgba(0, 0, 0, 0.2), transparent 70%) bottom;
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 30px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;
}

.google-top-scroll-shadow() {
  background:
          linear-gradient(white 30%, hsla(0, 0%, 100%, 0)),
          radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%);
  background-repeat: no-repeat;
  background-size: 100% 30px, 100% 10px;
  background-attachment: local, scroll;
}

.animated-progress-bar-stripes(@color) {
  background-clip: padding-box;
  background-color: @color;
  background-image: linear-gradient(-45deg, rgba(0, 0, 0, .2) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%, rgba(0, 0, 0, .2) 75%, transparent 75%, transparent);
  background-size: 43px 43px;
  animation: progress-bar-stripes 1s linear infinite;
}

.reject-icon-on-avatar(){
    border: 1px solid @color-white;
    border-radius: (@offset-lg / 2);
    width: 17px;
    height: 17px;
    background-color: @color-red;
    position: absolute;
    right: 3px;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > i {
      color: black;
      opacity: 0.5;
      font-size: 7px;
    }
}

.completed-icon-on-avatar(){
  border: 1px solid @color-white;
  border-radius: (@offset-lg / 2);
  width: 17px;
  height: 17px;
  background-color: @color-green;
  position: absolute;
  right: 3px;
  bottom: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > i {
    color: black;
    opacity: 0.5;
    font-size: 7px;
  }
}

.stopped-icon-on-avatar(){
  border: 1px solid @color-white;
  border-radius: (@offset-lg / 2);
  width: 17px;
  height: 17px;
  background-color: @color-yellow;
  position: absolute;
  right: 3px;
  bottom: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > i {
    color: black;
    opacity: 0.5;
    font-size: 7px;
  }
}

.default-scroll-wrapper() {
  position: relative;
  .ps-scrollbar-y, .ps-scrollbar-y-rail {
    width: 4px;
    background-color: @color-pale-blue;
    border-color: @color-pale-blue;
  }
  .ps-scrollbar-y{
    background-color: @color-pale-blue;
    border-color: @color-pale-blue;
  }
  .ps-scrollbar-y-rail{
    background-color: transparent;
    border-color: transparent;
  }
}
