@import "../../../less/variables";
@import "../../../less/mixins";

.data-type-selector__header {
  padding: @offset-sm 2*@offset-md @offset-sm 7px;
  .input-border;
  height: 2*@offset-lg;
  background: @color-white;
  border-width: 1px;
  color: @color-main-copy;
  resize: none;
  outline: 0;
  cursor: pointer;
  min-width: 140px;
  position: relative;
  text-align: left;

  .caret {
    position: absolute;
    right: @offset-sm;
    visibility: visible;
    color: @color-main-copy;
    font-size: 4px;
    top: 13px;
  }
}

.data-type-selector__options {
  columns: 3;

  li {
    cursor: pointer;

    a {
      font-size: 12px;

      &:hover,
      &:focus {
        color: @color-blue;
      }
    }
  }

  .icn {
    width: 24px;

    &.icon-task_field_multiline,
    &.icon-task_field_dropdown,
    &.icon-task_field_yesno,
    &.icon-task_field_money,
    &.icon-toaster_mail {
      font-size: 10px;
    }

    &.icon-task_field_number {
      font-size: 9px;
    }

    &.icon-company_users,
    &.icon-task_field_multiselect,
    &.icon-task_attachment {
      font-size: 13px;
    }
  }
}
