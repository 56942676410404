.template-view-data-model {
  max-width: 100%;
  margin-top: 2*@offset-md;
  flex: 0 1 100%;
  .no-focus();

  &__section {
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid @color-light-grey;

    & + .template-view-data-model__section {
      border-top: none;
    }

    header {
      width: 100%;
      height: 2*@offset-md + 2*@offset-sm;
      background-color: @color-panel-grey;
      display: flex;
      padding: 0 @offset-md;
      align-items: center;
      font-size: @font-size-lg;
      font-family: @font-family-medium;
      color: @color-main-copy;
      margin-top: 0 !important;

      span {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        font-family: @font-family-regular;

        & > i {
          font-size: 16px;
          color: @color-grey;
          margin-right: @offset-sm;
        }
      }
    }

    &__fields {
      @label-width: 200px;

      &__field {
        display: flex;
        margin: 2*@offset-md 0;
        padding: 0 @offset-md;
        align-items: flex-start;

        &:last-child {
          margin-bottom: 2*@offset-md;
        }

        &.file-container {
          label {
            padding-top: 5px;
          }
        }

        label {
          font-size: @font-size-sm;
          font-family: @font-family-medium;
          width: @label-width;
          min-width: @label-width;
          flex: 0 1 @label-width;
          padding-right: @offset-lg;
        }

        .icon-common_hint {
          margin-left: 8px;
          vertical-align: bottom;
        }

        .content {
          color: @color-secondary-copy;
          word-wrap: break-word;
          min-height: 16px;
          flex: 0 1 calc(100% - @label-width);
          width: calc(100% - @label-width);
          max-width: calc(100% - @label-width);

          .icon-task_due_date_add_small,
          .icon-task_field_multiselect {
            font-size: 15px;
            margin-top: -3px;
          }

          .icon-common_arrow_down {
            font-size: 5px;
            line-height: 10px;
            vertical-align: middle;
          }

          &.filled {
            color: @color-main-copy;
            font-family: @font-family-regular;

            a {
              color: @color-blue;
            }

            & > .multiline-span {
              display: block;
              position: relative;

              &.invisible {
                visibility: hidden;
              }

              & > span {
                white-space: pre-wrap;
              }

              .more-btn {
                display: none;
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: @color-white;
                padding: 0 @offset-xs 0 @offset-sm;
              }
              .less-btn {
                display: none;
              }

              &.short {
                overflow: hidden;
                height: 75px;

                .more-btn {
                  display: inline-block;
                }
              }
              &.full {
                .less-btn {
                  display: inline-block;
                }
              }
            }

            .icon-task_attachment {
              color: @color-grey;
              margin-bottom: @offset-xs;
            }

            .file {
              display: inline-flex;
              align-items: center;
              font-size: @font-size-sm;
              font-family: @font-family-semibold;
              padding: @offset-xs @offset-sm @offset-xs @offset-xs;
              border-radius: 8px;
              background-color: @color-light-grey;
              margin: 0 @offset-xs @offset-xs 0;
              max-width: calc(100% - (2 * @offset-md));
              color: @color-main-copy;
              .no-focus();

              & > span {
                max-width: calc(100% - @offset-lg);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              i {
                font-size: @font-size-md;
                margin-right: @offset-xs;
                color: @color-blue;
              }
            }
          }
        }
      }
    }
  }
}
