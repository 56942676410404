@import "../../../less/variables";
@import "../../../less/mixins";

.subdomain-input {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: @auth-form-text-width;
  height: @auth-form-text-height;
  padding: 0 7px;
  font-size: @font-size-sm;
  cursor: default;
  color: @color-secondary-copy;
  .input-border;
  .no-focus();

  &.active {
    border-color: @color-blue;
  }

  input {
    border: none;
    background: transparent;
    font-family: @font-family-semibold;
    appearance: caret;
    .no-focus();
  }
}
