@import "../../../less/variables";
@import "../../../less/mixins";

.options-list-editor {
  position: relative;
  flex: 0 1 100%;
  width: 100%;
  min-height: 100px;
  max-height: 100px;
  overflow: auto;
  .input-border;
  border-width: 1px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: none !important;
  padding-bottom: @offset-sm;

  &.active {
    border-color: @color-blue;
    border-width: 1px;
    box-shadow: 0 0 0 1px @color-blue !important;
  }

  .placeholder {
    top: 8px;
    left: 8px;
    position: absolute;
    color: @color-secondary-copy;
  }

  .content-editor {
    display: block;
    position: relative;
    z-index: 1;

    color: @color-main-copy;
    border: 0;
    white-space: pre;
    word-wrap: normal;
    line-height: 14px;
    font-size: 12px;
    min-height: 84px;
    min-width: 100%;
    appearance: none;
    outline: 0;
    overflow: hidden;

    background: transparent;
    box-sizing: border-box;
    width: auto;
    max-width: 10000px;
    flex: 0 1 auto;
    padding-bottom: 0;

    box-shadow: none !important;
    outline: none !important;
    cursor: text;
  }

  .content-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    color: transparent;
    white-space: pre-wrap;
    line-height: 14px;
    font-size: 12px;
    min-height: 84px;
    appearance: none;
    outline: 0;
    display: block;
    border-radius: 4px;
    padding: 8px 7px;
    min-width: 100%;

    .invalid {
      color: @color-red;
      text-decoration: underline;
      white-space: nowrap;
    }
  }
}

.has-error {
  .options-list-editor {
    border-color: @color-red !important;

    &.active {
      border-width: 1px;
      box-shadow: 0 0 0 1px @color-red !important;
    }
  }
}
