.time-input {
  position: relative;
  box-shadow: none !important;
  color: @color-main-copy;
  align-items: center;
  width: 70px;

  & > input {
    width: 100%;
  }
}