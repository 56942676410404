@import "../../../less/variables";
@import "../../../less/mixins";

.select-field-type {
  @select-width: 105px;

  margin-left: @offset-md;
  min-width: @select-width;
  max-width: 133px;

  header {
    padding: @offset-sm 2*@offset-md @offset-sm 7px;
    .input-border;
    height: 2*@offset-lg;
    background: @color-white;
    border-width: 1px;
    color: @color-main-copy;
    resize: none;
    outline: 0;
    cursor: pointer;
    min-width: @select-width;
    position: relative;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    i {
      position: absolute;
      right: @offset-sm;
      visibility: visible;
      color: @color-main-copy;
      font-size: 4px;
      top: 13px;
    }
  }
}

.select-field-type__options {
  display: grid;
  grid-template-columns: 33% 33% 33%;

  .icn {
    width: 24px;

    &.icon-task_field_multiline,
    &.icon-task_field_dropdown,
    &.icon-task_field_yesno,
    &.icon-task_field_money,
    &.icon-toaster_mail {
      font-size: 10px;
    }

    &.icon-task_field_number {
      font-size: 9px;
    }

    &.icon-company_users,
    &.icon-task_field_multiselect,
    &.icon-task_attachment {
      font-size: 13px;
    }
  }
}

.drop-element.drop-open .select-field-type__options {
  display: grid;
}
