.pdf-viewer {
  padding: 70px 0;

  .new-loading-spinner {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
  }

  &__page {
    position: relative;
    width: 100%;
    height: 100%;

    .new-loading-spinner {
      border-color: rgba(150, 150, 150, .8);
      border-left-color: transparent;
    }
  }
}
